//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const UserTypes = {
    CLEAR_AFTER_LOGIN_ROUTE:           'User/CLEAR_AFTER_LOGIN_ROUTE',
    CREATE_USER:                       'User/CREATE_USER',
    CREATE_USER_FAILED:                'User/CREATE_USER_FAILED',
    CREATE_USER_SUCCESS:               'User/CREATE_USER_SUCCESS',
    EDIT_OWN_USER_DATA:                'User/EDIT_OWN_USER_DATA',
    EDIT_OWN_USER_PASSWORD:            'User/EDIT_OWN_USER_PASSWORD',
    FETCH_USER:                        'User/FETCH_USER',
    FETCH_USER_FAILED:                 'User/FETCH_USER_FAILED',
    FETCH_USER_SUCCESS:                'User/FETCH_USER_SUCCESS',
    LOAD_NONE_SESSION_DEPENDING_DATA:  'User/LOAD_NONE_SESSION_DEPENDING_DATA',
    LOAD_SESSION_DEPENDING_DATA:       'User/LOAD_SESSION_DEPENDING_DATA',
    LOGIN:                             'User/LOGIN',
    LOGIN_FAILED:                      'User/LOGIN_FAILED',
    LOGIN_SUCCEEDED:                   'User/LOGIN_SUCCEEDED',
    LOGOUT:                            'User/LOGOUT',
    REGISTER_COMPANY:                  'User/REGISTER_COMPANY',
    REGISTER_OPEN:                     'User/REGISTER_OPEN',
    REGISTER_VIDEO_PRODUCTION:         'User/REGISTER_VIDEO_PRODUCTION',
    REGISTER_VIDEO_PRODUCTION_SUCCESS: 'User/REGISTER_VIDEO_PRODUCTION_SUCCESS',
    RESET_PASSWORD:                    'User/RESET_PASSWORD',
    RESET_PASSWORD_FAILED:             'User/RESET_PASSWORD_FAILED',
    RESET_PASSWORD_SUCCESS:            'User/RESET_PASSWORD_SUCCESS',
    SET_AFTER_LOGIN_ROUTE:             'User/SET_AFTER_LOGIN_ROUTE',
    SET_E_MAIL_ADDRESS:                'User/SET_E_MAIL_ADDRESS',
    SET_NEW_PASSWORD:                  'User/SET_NEW_PASSWORD',
    SET_NEW_PASSWORD_FAILED:           'User/SET_NEW_PASSWORD_FAILED',
    SET_NEW_PASSWORD_SUCCESS:          'User/SET_NEW_PASSWORD_SUCCESS',
    SET_OWN_EDIT_USER_AS_USER:         'User/SET_OWN_EDIT_USER_AS_USER',
    SET_PASSWORD:                      'User/SET_PASSWORD',
    TEMPORARY_SET_OWN_USER_DATA:       'User/TEMPORARY_SET_OWN_USER_DATA',
    TEMPORARY_SET_OWN_USER_PASSWORD:   'User/TEMPORARY_SET_OWN_USER_PASSWORD',
    UPDATE_USER:                       'User/UPDATE_USER',
    UPDATE_USER_FAILED:                'User/UPDATE_USER_FAILED',
    UPDATE_USER_PASSWORD:              'User/UPDATE_USER_PASSWORD',
    UPDATE_USER_SUCCESS:               'User/UPDATE_USER_SUCCESS',
    VERIFY_REGISTRATION:               'User/UPDATE_USER_SUCCESS',
    VERIFY_REGISTRATION_FAILED:        'User/VERIFY_REGISTRATION_FAILED',
    VERIFY_REGISTRATION_SUCCEEDED:     'User/VERIFY_REGISTRATION_SUCCEEDED',
};

const createUser = makeActionCreator(
    UserTypes.CREATE_USER,
);

const createUserFailed = makeActionCreator(
    UserTypes.CREATE_USER_FAILED,
);

const createUserSuccess = makeActionCreator(
    UserTypes.CREATE_USER_SUCCESS,
    {
        user: null,
    },
);

const editOwnUserData = makeActionCreator(
    UserTypes.EDIT_OWN_USER_DATA,
);

const editOwnUserPassword = makeActionCreator(
    UserTypes.EDIT_OWN_USER_PASSWORD,
);

const fetchUser = makeActionCreator(
    UserTypes.FETCH_USER,
    {
        userId: null,
    },
);

const fetchUserFailed = makeActionCreator(
    UserTypes.FETCH_USER_FAILED,
);

const fetchUserSuccess = makeActionCreator(
    UserTypes.FETCH_USER_SUCCESS,
    {
        id:        null,
        iri:       null,
        firstname: null,
        name:      null,
        email:     null,
    },
);

const loadNoneSessionDependingData = makeActionCreator(
    UserTypes.LOAD_NONE_SESSION_DEPENDING_DATA,
);

const loadSessionDependingData = makeActionCreator(
    UserTypes.LOAD_SESSION_DEPENDING_DATA,
);

const login = makeActionCreator(
    UserTypes.LOGIN,
    {
        redirect:      null,
        suppressError: null,
    },
);

const loginFailed = makeActionCreator(
    UserTypes.LOGIN_FAILED,
    {
        suppressError: null,
    },
);

const loginSucceeded = makeActionCreator(
    UserTypes.LOGIN_SUCCEEDED, {
        company:   null,
        id:        null,
        iri:       null,
        firstname: null,
        name:      null,
        token:     null,
        redirect:  null,
    },
);

const logout = makeActionCreator(
    UserTypes.LOGOUT,
    {
        changeRoute: true,
    },
);

const registerOpen = makeActionCreator(
    UserTypes.REGISTER_OPEN,
);

const registerCompany = makeActionCreator(
    UserTypes.REGISTER_COMPANY,
);

const registerVideoProduction = makeActionCreator(
    UserTypes.REGISTER_VIDEO_PRODUCTION,
);

const registerVideoProductionSuccess = makeActionCreator(
    UserTypes.REGISTER_VIDEO_PRODUCTION_SUCCESS,
);

const resetPassword = makeActionCreator(
    UserTypes.RESET_PASSWORD,
    {
        email: null,
    },
);

const resetPasswordFailed = makeActionCreator(
    UserTypes.RESET_PASSWORD_FAILED,
);

const resetPasswordSuccess = makeActionCreator(
    UserTypes.RESET_PASSWORD_SUCCESS,
);

const setEMailAddress = makeActionCreator(
    UserTypes.SET_E_MAIL_ADDRESS,
    {
        eMailAddress: null,
    },
);

const setNewPassword = makeActionCreator(
    UserTypes.SET_NEW_PASSWORD,
    {
        password: null,
        token:    null,
    },
);

const setNewPasswordFailed = makeActionCreator(
    UserTypes.SET_NEW_PASSWORD_FAILED,
);

const setNewPasswordSuccess = makeActionCreator(
    UserTypes.SET_NEW_PASSWORD_SUCCESS,
);

const setOwnEditUserAsUser = makeActionCreator(
    UserTypes.SET_OWN_EDIT_USER_AS_USER,
);

const setPassword = makeActionCreator(
    UserTypes.SET_PASSWORD,
    {
        password: null,
    },
);

const temporarySetOwnProfileData = makeActionCreator(
    UserTypes.TEMPORARY_SET_OWN_USER_DATA,
    {
        data: null,
    },
);

const setAfterLoginRoute = makeActionCreator(
    UserTypes.SET_AFTER_LOGIN_ROUTE,
    {
        afterLoginRoute: null,
    },
);

const clearAfterLoginRoute = makeActionCreator(
    UserTypes.CLEAR_AFTER_LOGIN_ROUTE,
);

const temporarySetOwnProfilePassword = makeActionCreator(
    UserTypes.TEMPORARY_SET_OWN_USER_PASSWORD,
    {
        password: null,
    },
);

const updateUser = makeActionCreator(
    UserTypes.UPDATE_USER,
    {
        data: null,
    },
);

const updateUserFailed = makeActionCreator(
    UserTypes.UPDATE_USER_FAILED,
);

const updateUserPassword = makeActionCreator(
    UserTypes.UPDATE_USER_PASSWORD,
    {
        oldPassword: null,
        newPassword: null,
    },
);

const updateUserSuccess = makeActionCreator(
    UserTypes.UPDATE_USER_SUCCESS,
    {
        id:        null,
        iri:       null,
        firstname: null,
        name:      null,
        email:     null,
    },
);

const verifyRegistration = makeActionCreator(
    UserTypes.VERIFY_REGISTRATION,
    {
        confirmationToken: null,
    },
);

const verifyRegistrationFailed = makeActionCreator(
    UserTypes.VERIFY_REGISTRATION_FAILED,
);

const verifyRegistrationSucceeded = makeActionCreator(
    UserTypes.VERIFY_REGISTRATION_SUCCEEDED,
);

export const UserActions = {
    clearAfterLoginRoute,
    createUser,
    createUserFailed,
    createUserSuccess,
    editOwnUserData,
    editOwnUserPassword,
    fetchUser,
    fetchUserFailed,
    fetchUserSuccess,
    loadNoneSessionDependingData,
    loadSessionDependingData,
    login,
    loginFailed,
    loginSucceeded,
    logout,
    registerCompany,
    registerOpen,
    registerVideoProduction,
    registerVideoProductionSuccess,
    resetPassword,
    resetPasswordFailed,
    resetPasswordSuccess,
    setAfterLoginRoute,
    setEMailAddress,
    setNewPassword,
    setNewPasswordFailed,
    setNewPasswordSuccess,
    setOwnEditUserAsUser,
    setPassword,
    temporarySetOwnProfileData,
    temporarySetOwnProfilePassword,
    updateUser,
    updateUserFailed,
    updateUserPassword,
    updateUserSuccess,
    verifyRegistration,
    verifyRegistrationFailed,
    verifyRegistrationSucceeded,
};
