//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                       from 'react';
import _                           from 'lodash';
import ApiMode                     from '../../../constants/ApiMode';
import ComponentHelper             from '../../../helper/ComponentHelper';
import CreateOfferOverlayStateless from '../../stateless/CreateOfferOverlay';
import I18n                        from 'i18next';
import PropTypes                   from '../../PropTypes';
import { AlertBoxActions }         from '../../../store/actions/alertBox';
import { bindActionCreators }      from 'redux';
import { connect }                 from 'react-redux';
import { CreateEditOfferActions }  from '../../../store/actions/createEditOffer';

class CreateOfferOverlay extends React.Component {
    addOfferButtonPressed = () => {
        this.props.addOffer();
    };

    getTitle = () => {
        const languageKeyIndex = (
            this.props.mode === ApiMode.create ?
                'createOfferTitle' :
                'editOfferTitle'
        );

        return I18n.t(
            languageKeyIndex,
            {
                projectName: this.props.activeProjectTitle,
            },
        );
    };

    getSubmitButtonText = () => {
        const languageKeyIndex = (
            this.props.mode === ApiMode.create ?
                'offerSubmit' :
                'offerEditSubmit'
        );

        return I18n.t(languageKeyIndex);
    };

    isOfferFileUploading = () => {
        const isUploadingInProgress = _.some(
            this.props.matchFiles,
            {
                state: 'uploading',
            },
        );

        if (isUploadingInProgress) {
            return true;
        }

        for (const offerIndex in this.props.offers) {
            const offer                 = this.props.offers[offerIndex];
            const isUploadingInProgress = _.some(
                offer.files,
                {
                    state: 'uploading',
                },
            );

            if (isUploadingInProgress) {
                return true;
            }
        }

        return false;
    };

    render() {
        return (
            <CreateOfferOverlayStateless
                addOfferButtonPressed={this.addOfferButtonPressed}
                offers={this.props.offers}
                submitButtonPressed={this.submitButtonPressed}
                submitButtonText={this.getSubmitButtonText()}
                title={this.getTitle()}
                submitButtonDisabled={this.isOfferFileUploading()}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    submitButtonPressed = () => {
        this.props.clearAlerts();

        for (const offerIndex in this.props.offers) {
            const offer = this.props.offers[offerIndex];

            if (!offer.title) {
                this.props.showErrorAlertTagged({
                    tag:  'title_' + offerIndex,
                    text: I18n.t('offerTitleErrorEmpty'),
                });
            }

            if (!Object.keys(offer.files).length) {
                this.props.showErrorAlertTagged({
                    tag:  'files_' + offerIndex,
                    text: I18n.t('offerPdfErrorEmpty'),
                });
            }

            if (!offer.netPriceInEuro) {
                this.props.showErrorAlertTagged({
                    tag:  'netPriceInEuro_' + offerIndex,
                    text: I18n.t('offerSumErrorEmpty'),
                });
            }
        }

        requestAnimationFrame(() => {
            if (this.props.alertBoxesTaggedCount === 0) {
                if (this.props.mode === ApiMode.create) {
                    this.props.createOffer();
                } else {
                    this.props.updateOffer();
                }
            }
        });
    };
}

const Component = CreateOfferOverlay;

Component.propTypes = {
    activeProjectTitle:    PropTypes.string,
    alertBoxesTaggedCount: PropTypes.number,
    matchFiles:            PropTypes.array,
    mode:                  PropTypes.string,
    offers:                PropTypes.array,
};

Component.defaultProps = {
    activeProjectTitle:    '',
    alertBoxesTaggedCount: 0,
    matchFiles:            [],
    mode:                  null,
    offers:                [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        AlertBoxActions,
        CreateEditOfferActions,
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        activeProjectTitle:    _.get(state, 'activeProject.activeProject.title'),
        alertBoxesTaggedCount: _.get(state, 'alertBox.alertBoxesTaggedCount', 0),
        matchFiles:            _.get(state, 'createEditOffer.matchFiles', []),
        mode:                  _.get(state, 'createEditOffer.mode'),
        offers:                _.get(state, 'createEditOffer.offers'),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
