//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import Avatar          from '../../connected/Avatar';
import AvatarSize      from '../Avatar/AvatarSize';
import ComponentHelper from '../../../helper/ComponentHelper';
import Icon            from '../Icon';
import IconType        from '../Icon/IconType';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';
import UserMenu        from '../../connected/UserMenu';
import _               from 'lodash';

class HeaderUserMenuButton extends React.Component {
    render() {
        const isLoggedIn = _.get(this.props, 'user.token', null);
        const company    = this.props.company;

        if (isLoggedIn && company) {
            return (
                <div className={styles.wrapper}>
                    <div className={styles.avatarWrapper}>
                        <Avatar
                            company={this.props.company}
                            size={AvatarSize.small}
                            disableOnClick={true}
                        />
                    </div>
                    <div className={styles.iconWrapper}>
                        <Icon iconType={IconType.chevronDown} />
                    </div>
                    <div className={styles.userMenuWrapper}>
                        <UserMenu />
                    </div>
                </div>
            );
        }

        return null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = HeaderUserMenuButton;

Component.propTypes = {
    company: PropTypes.object,
    user:    PropTypes.object,
};

Component.defaultProps = {
    company: null,
    user:    null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
