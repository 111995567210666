//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update               from 'immutability-helper';
import { PlaceOfWorkTypes } from '../actions/placeOfWork';

const initialState = {
    placeOfWorks: [],
};

const fetchPlaceOfWorksSuccess = (action, state) => {
    return update(state, {
        placeOfWorks: {
            $set: action.placeOfWorks,
        },
    });
};

export default function (state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case PlaceOfWorkTypes.FETCH_PLACE_OF_WORK_SUCCESS: return fetchPlaceOfWorksSuccess(action, state);
        default:                                           return state;
        // @formatter:on
    }
}