//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import AlertBoxType    from './AlertBoxType';
import classNames      from 'classnames';
import ComponentHelper from '../../../helper/ComponentHelper';
import Icon            from '../Icon';
import IconType        from '../Icon/IconType';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import SelectionHelper from '../../../helper/SelectionHelper';
import styles          from './styles.module.scss';

class AlertBox extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        return (
            <div
                className={classNames(
                    styles.alertBox,
                    SelectionHelper.get(
                        this.props.type,
                        {
                            [AlertBoxType.error]:   styles.alertBoxError,
                            [AlertBoxType.success]: styles.alertBoxSuccess,
                        },
                    ),
                )}
            >
                {this.renderIconForType()}
                {this.props.text}
                {this.renderCloseButton()}
            </div>
        );
    }

    renderCloseButton = () => {
        if (this.props.onCloseButtonClick) {
            return (
                <span
                    className={styles.alertBoxCloseButtonWrapper}
                    onClick={this.props.onCloseButtonClick}
                >
                    <Icon iconType={IconType.x} />
                </span>
            );
        }

        return null;
    };

    renderIconForType = () => {
        const iconType = SelectionHelper.get(
            this.props.type,
            {
                [AlertBoxType.error]:   IconType.alertOctagon,
                [AlertBoxType.success]: IconType.checkCircle,
            },
        );

        return (
            <div className={styles.alertBoxIcon}>
                <Icon
                    iconType={iconType}
                />
            </div>
        );
    };
}

const Component = AlertBox;

Component.propTypes = {
    onCloseButtonClick: PropTypes.func,
    text:               PropTypes.string,
    type:               PropTypes.oneOf(Object.values(AlertBoxType)),
};

Component.defaultProps = {
    onCloseButtonClick: null,
    text:               null,
    type:               null,
};

Component.renderAffectingProps = [
    'text',
    'type',
];

Component.renderAffectingStates = [];

export default Component;