//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n                       from 'i18next';
import PageTitleHelper            from '../../helper/PageTitle';
import React                      from 'react';
import { Component }              from 'react';
import { Helmet }                 from 'react-helmet';
import MatchSideBar               from '../../components/connected/MatchSideBar';
import OfferFileList              from '../../components/connected/OfferFileList';
import ContentWrapper             from '../../components/stateless/ContentWrapper';
import SubSideBar                 from '../../components/stateless/SubSideBar';
import Message                    from '../../components/stateless/Message';
import MessageType                from '../../components/stateless/Message/MessageType';
import { bindActionCreators }     from 'redux';
import { connect }                from 'react-redux';
import { ActiveProjectActions }   from '../../store/actions/activeProject';
import _                          from 'lodash';
import * as Api                   from '../../api';
import Spacer                     from '../../components/stateless/Spacer';
import MessageComposer            from '../../components/connected/MessageComposer';
import NoDataAvailablePlaceholder from '../../components/stateless/NoDataAvailablePlaceholder';
import moment                     from 'moment';
import CompanyTypes               from '../../constants/CompanyTypes';
import MatchStateTypes            from '../../constants/MatchStateTypes';

class Screen extends Component {
    getMessageAttachments = (message) => {
        const files = [];

        if (message.messageAttachments) {
            for (const messageAttachment of message.messageAttachments) {
                files.push({
                    additionalText: null,
                    downloadUrl:    Api.getFilePath(messageAttachment.path),
                    name:           messageAttachment.name,
                });
            }
        }

        return files;
    };

    getMessageType = (message) => {
        const messageType = (
            _.get(this, 'props.activeMatch.offeringCompany.iri') === _.get(message, 'senderCompany.iri') ?
                MessageType.incoming :
                MessageType.outgoing
        );

        return messageType;
    };

    /**
     * This method will create a single stream that contains
     * messages and offers.
     *
     * @returns {[]}
     */
    getMessagesMergedWithOffers = () => {
        const messageOfferPairs = [];
        const offers            = _.clone(this.props.offers);

        if (this.props.messages && this.props.messages.length) {
            for (const message of this.props.messages) {
                const messageCreatedAt = moment(message.createdAt);

                const messageOfferPair = {
                    message,
                    offers: [],
                };

                if (offers && offers.length) {
                    for (let offerIndex = 0; offerIndex < offers.length; ++offerIndex) {
                        const offer          = offers[offerIndex];
                        const offerCreatedAt = moment(offer.createdAt);

                        if (offerCreatedAt >= messageCreatedAt) {
                            messageOfferPair.offers.push(offer);

                            offers.splice(offerIndex, 1);

                            --offerIndex;
                        }
                    }
                }

                messageOfferPairs.push(messageOfferPair);
            }
        }

        if (offers.length) {
            messageOfferPairs.push({
                message: null,
                offers,
            });
        }

        return messageOfferPairs;
    };

    ownCompanyIsCompany = () => {
        return this.props.companyType === CompanyTypes.company;
    };

    render () {
        if (this.props.matches && this.props.matches.length) {
            if (this.ownCompanyIsCompany()) {
                return (
                    <SubSideBar
                        mainContent={this.renderMainContent()}
                        sideBarContent={this.renderSubSideBarContent()}
                    />
                );
            }

            return this.renderMainContent();
        }

        return (
            <>
                <Spacer height={100} />
                <NoDataAvailablePlaceholder
                    title={I18n.t('noMessagesFoundTitle')}
                    subTitle={I18n.t('noMessagesFound')}
                />
            </>
        );
    }

    renderMainContent = () => {
        return (
            <ContentWrapper>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('messages'))}
                    </title>
                </Helmet>
                <MessageComposer context={_.get(this, 'props.activeMatch.id')} />
                {this.renderMessages()}
            </ContentWrapper>
        );
    };

    renderMessage = (message, index) => {
        const messageAttachments = this.getMessageAttachments(message);

        return (
            <Message
                company={_.get(message, 'senderCompany', null)}
                files={messageAttachments}
                key={'message' + index}
                name={_.get(message, 'senderCompany.name')}
                message={message.message}
                time={message.createdAt}
                type={this.getMessageType(message)}
            />
        );
    };

    renderMessageOfferPair = (messageOfferPair, index) => {
        return (
            <>
                {this.renderMessageOfferPairOffer(messageOfferPair, index)}
                {this.renderMessageOfferPairMessage(messageOfferPair, index)}
            </>
        );
    };

    renderMessageOfferPairMessage = (messageOfferPair, index) => {
        const messageOfferPairMessages = messageOfferPair.message;

        if (messageOfferPairMessages) {
            return this.renderMessage(messageOfferPairMessages, index);
        }

        return null;
    };

    renderMessageOfferPairOffer = (messageOfferPair, index) => {
        const messageOfferPairOffers = messageOfferPair.offers;

        if (messageOfferPairOffers) {
            return this.renderOffers(messageOfferPairOffers, index);
        }

        return null;
    };

    renderMessages = () => {
        const messageOfferPairs = this.getMessagesMergedWithOffers();

        if (messageOfferPairs.length) {
            return messageOfferPairs.map(this.renderMessageOfferPair);
        }

        return null;
    };

    renderOffer = (offer, index) => {
        const isAcceptedOffer  = offer.offerState === MatchStateTypes.accepted;
        const hasAcceptedOffer = _.some(
            this.props.offers,
            {
                offerState: MatchStateTypes.accepted,
            },
        );

        return (
            <Message
                additionalContent={(
                    <OfferFileList
                        offer={offer}
                        isAcceptedOffer={isAcceptedOffer}
                        hasAcceptedOffer={hasAcceptedOffer}
                    />
                )}
                company={_.get(offer, 'offeringCompany', null)}
                key={'offer' + index}
                name={I18n.t(
                    'messageOfferTitle',
                    {
                        companyName: _.get(offer, 'offeringCompany.name'),
                    },
                )
                }
                message={offer.message}
                time={offer.createdAt}
                type={MessageType.incoming}
            />
        );
    };

    renderOffers = (offers, index) => {
        return offers.map(this.renderOffer);
    };

    renderSubSideBarContent = () => {
        return (
            <MatchSideBar
                matches={this.props.matches}
                subTextDateKey={'lastMessageTimestamp'}
                subTextFallbackText={I18n.t('matchNoMessageText')}
            />
        );
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(ActiveProjectActions, dispatch);

const mapStateToProps = state => (
    {
        activeMatch: _.get(state, 'activeProject.activeMatch'),
        companyType: _.get(state, 'company.ownCompany.companyType'),
        messages:    _.get(state, 'activeProject.messages'),
        matches:     _.get(state, 'activeProject.matchesByLastMessageTimestamp'),
        offers:      _.get(state, 'activeProject.offers'),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);
