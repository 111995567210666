//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                        from 'lodash';
import ContentWrapper           from '../../components/stateless/ContentWrapper';
import I18n                     from 'i18next';
import MatchContentItem         from '../../components/connected/MatchContentItem';
import PageTitleHelper          from '../../helper/PageTitle';
import React                    from 'react';
import { ActiveProjectActions } from '../../store/actions/activeProject';
import { bindActionCreators }   from 'redux';
import { Component }            from 'react';
import { connect }              from 'react-redux';
import { Helmet }               from 'react-helmet';

class Screen extends Component {
    matchContentItemClicked = () => {
        if (_.get(this.props, 'match.searchingCompanyInteracted')) {
            return null;
        }

        this.props.setMatchInteracted({
            match: this.props.match,
        });
    };

    render () {
        return (
            <ContentWrapper>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('match'))}
                    </title>
                </Helmet>
                {this.renderContentItem()}
            </ContentWrapper>
        );
    }

    renderContentItem = () => {
        return (
            <MatchContentItem
                match={this.props.match}
                onClick={() => {
                    this.matchContentItemClicked();
                }}
            />
        );
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(ActiveProjectActions, dispatch);

const mapStateToProps = state => (
    {
        match: _.get(state, 'activeProject.matches[0]'),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);
