//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const CompanyPositionTypes = {
    FETCH_COMPANY_POSITIONS:         'Company/FETCH_COMPANY_POSITIONS',
    FETCH_COMPANY_POSITIONS_FAILED:  'Company/FETCH_COMPANY_POSITIONS_FAILED',
    FETCH_COMPANY_POSITIONS_SUCCESS: 'Company/FETCH_COMPANY_POSITIONS_SUCCESS',
};

const fetchCompanyPositions = makeActionCreator(
    CompanyPositionTypes.FETCH_COMPANY_POSITIONS,
);

const fetchCompanyPositionsFailed = makeActionCreator(
    CompanyPositionTypes.FETCH_COMPANY_POSITIONS_FAILED,
);

const fetchCompanyPositionsSuccess = makeActionCreator(
    CompanyPositionTypes.FETCH_COMPANY_POSITIONS_SUCCESS,
    {
        companyPositions: null,
    },
);

export const CompanyPositionActions = {
    fetchCompanyPositions,
    fetchCompanyPositionsFailed,
    fetchCompanyPositionsSuccess,
};
