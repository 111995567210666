//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import ComponentHelper from '../../../helper/ComponentHelper';
import ResetPassword   from '../../connected/ResetPassword';
import Routes          from '../../../constants/Routes';
import SplitPaneLogin  from '../../connected/SplitPaneLogin';
import SplitPaneMenu   from '../../connected/SplitPaneMenu';
import styles          from './styles.module.scss';
import { Route }       from 'react-router';
import { Switch }      from 'react-router';

class LeftPaneRouter extends React.Component {
    render() {
        return (
            <div className={styles.wrapper}>
                <Switch>
                    <Route
                        exact
                        path={Routes.login}
                        component={SplitPaneLogin}
                    />
                    <Route
                        exact
                        path={Routes.verifyRegistration}
                        component={SplitPaneLogin}
                    />
                    <Route
                        exact
                        path={Routes.newPassword}
                        component={ResetPassword}
                    />
                    <Route
                        component={SplitPaneMenu}
                    />
                </Switch>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = LeftPaneRouter;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

export default Component;
