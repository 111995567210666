//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const ActiveProjectTypes = {
    ACCEPT_OFFER:                     'ActiveProject/ACCEPT_OFFER',
    ACCEPT_OFFER_FAILED:              'ActiveProject/ACCEPT_OFFER_FAILED',
    ACCEPT_OFFER_SUCCESS:             'ActiveProject/ACCEPT_OFFER_SUCCESS',
    FETCH_MATCHES:                    'ActiveProject/FETCH_MATCHES',
    FETCH_MATCHES_FAILED:             'ActiveProject/FETCH_MATCHES_FAILED',
    FETCH_MATCHES_FOR_ACTIVE_PROJECT: 'ActiveProject/FETCH_MATCHES_FOR_ACTIVE_PROJECT',
    FETCH_MATCHES_SUCCESS:            'ActiveProject/FETCH_MATCHES_SUCCESS',
    FETCH_MESSAGES:                   'ActiveProject/FETCH_MESSAGES',
    FETCH_MESSAGES_FAILED:            'ActiveProject/FETCH_MESSAGES_FAILED',
    FETCH_MESSAGES_SUCCESS:           'ActiveProject/FETCH_MESSAGES_SUCCESS',
    FETCH_OFFERS:                     'ActiveProject/FETCH_OFFERS',
    FETCH_OFFERS_FAILED:              'ActiveProject/FETCH_OFFERS_FAILED',
    FETCH_OFFERS_SUCCESS:             'ActiveProject/FETCH_OFFERS_SUCCESS',
    OPEN_ACCEPT_OFFER_OVERLAY:        'ActiveProject/OPEN_ACCEPT_OFFER_OVERLAY',
    OPEN_OFFER_ACCEPTED_OVERLAY:      'ActiveProject/OPEN_OFFER_ACCEPTED_OVERLAY',
    SEND_ACTIVE_MESSAGE:              'ActiveProject/SEND_ACTIVE_MESSAGE',
    SEND_ACTIVE_MESSAGE_FAILED:       'ActiveProject/SEND_ACTIVE_MESSAGE_FAILED',
    SEND_ACTIVE_MESSAGE_SUCCESS:      'ActiveProject/SEND_ACTIVE_MESSAGE_SUCCESS',
    SET_ACTIVE_MATCH:                 'ActiveProject/SET_ACTIVE_MATCH',
    SET_ACTIVE_OFFER:                 'ActiveProject/SET_ACTIVE_OFFER',
    SET_ACTIVE_PROJECT:               'ActiveProject/SET_ACTIVE_PROJECT',
    SET_MATCH_INTERACTED:             'ActiveProject/SET_MATCH_INTERACTED',
    SET_MATCH_INTERACTED_FAILED:      'ActiveProject/SET_MATCH_INTERACTED_FAILED',
    SET_MATCH_INTERACTED_SUCCESS:     'ActiveProject/SET_MATCH_INTERACTED_SUCCESS',
    SET_MATCHES_ACTIVE_TAB:           'ActiveProject/SET_MATCHES_ACTIVE_TAB',
};

const acceptOffer = makeActionCreator(
    ActiveProjectTypes.ACCEPT_OFFER,
);

const acceptOfferFailed = makeActionCreator(
    ActiveProjectTypes.ACCEPT_OFFER_FAILED,
);

const acceptOfferSuccess = makeActionCreator(
    ActiveProjectTypes.ACCEPT_OFFER_SUCCESS,
);

const fetchMatches = makeActionCreator(
    ActiveProjectTypes.FETCH_MATCHES, {
        activeMatchIri: null,
        projectId:      null,
        silent:         null,
    },
);

const fetchMatchesFailed = makeActionCreator(
    ActiveProjectTypes.FETCH_MATCHES_FAILED,
    {
        silent: null,
    },
);

const fetchMatchesForActiveProject = makeActionCreator(
    ActiveProjectTypes.FETCH_MATCHES_FOR_ACTIVE_PROJECT,
    {
        silent: null,
    },
);

const fetchMatchesSuccess = makeActionCreator(
    ActiveProjectTypes.FETCH_MATCHES_SUCCESS,
    {
        activeMatchIri: null,
        matches:        null,
        projectId:      null,
        silent:         null,
    },
);

const fetchMessages = makeActionCreator(
    ActiveProjectTypes.FETCH_MESSAGES, {
        matchId: null,
        silent:  null,
    },
);

const fetchMessagesFailed = makeActionCreator(
    ActiveProjectTypes.FETCH_MESSAGES_FAILED,
    {
        silent: null,
    },
);

const fetchMessagesSuccess = makeActionCreator(
    ActiveProjectTypes.FETCH_MESSAGES_SUCCESS,
    {
        messages: null,
        matchId:  null,
        silent:   null,
    },
);

const fetchOffers = makeActionCreator(
    ActiveProjectTypes.FETCH_OFFERS, {
        matchId: null,
        silent:  true,
    },
);

const fetchOffersFailed = makeActionCreator(
    ActiveProjectTypes.FETCH_OFFERS_FAILED,
    {
        silent: true,
    },
);

const fetchOffersSuccess = makeActionCreator(
    ActiveProjectTypes.FETCH_OFFERS_SUCCESS,
    {
        offers:  null,
        matchId: null,
        silent:  true,
    },
);

const openAcceptOfferOverlay = makeActionCreator(
    ActiveProjectTypes.OPEN_ACCEPT_OFFER_OVERLAY,
);

const openOfferAcceptedOverlay = makeActionCreator(
    ActiveProjectTypes.OPEN_OFFER_ACCEPTED_OVERLAY,
);

const sendActiveMessage = makeActionCreator(
    ActiveProjectTypes.SEND_ACTIVE_MESSAGE,
);

const sendActiveMessageFailed = makeActionCreator(
    ActiveProjectTypes.SEND_ACTIVE_MESSAGE_FAILED,
    {
        contextKey: null,
    },
);

const sendActiveMessageSuccess = makeActionCreator(
    ActiveProjectTypes.SEND_ACTIVE_MESSAGE_SUCCESS,
    {
        contextKey: null,
    },
);

const setActiveMatch = makeActionCreator(
    ActiveProjectTypes.SET_ACTIVE_MATCH,
    {
        match: null,
    },
);

const setActiveOffer = makeActionCreator(
    ActiveProjectTypes.SET_ACTIVE_OFFER,
    {
        offer: null,
    },
);

const setActiveProject = makeActionCreator(
    ActiveProjectTypes.SET_ACTIVE_PROJECT,
    {
        activeMatch: null,
        project:     null,
        subMenuType: null,
    },
);

const setMatchesActiveTab = makeActionCreator(
    ActiveProjectTypes.SET_MATCHES_ACTIVE_TAB,
    {
        tabIndex: null,
    },
);

const setMatchInteracted = makeActionCreator(
    ActiveProjectTypes.SET_MATCH_INTERACTED,
    {
        match: null,
    },
);

const setMatchInteractedFailed = makeActionCreator(
    ActiveProjectTypes.SET_MATCH_INTERACTED_FAILED,
);

const setMatchInteractedSuccess = makeActionCreator(
    ActiveProjectTypes.SET_MATCH_INTERACTED_SUCCESS,
);

export const ActiveProjectActions = {
    acceptOffer,
    acceptOfferFailed,
    acceptOfferSuccess,
    fetchMatches,
    fetchMatchesFailed,
    fetchMatchesForActiveProject,
    fetchMatchesSuccess,
    fetchMessages,
    fetchMessagesFailed,
    fetchMessagesSuccess,
    fetchOffers,
    fetchOffersFailed,
    fetchOffersSuccess,
    openAcceptOfferOverlay,
    openOfferAcceptedOverlay,
    sendActiveMessage,
    sendActiveMessageFailed,
    sendActiveMessageSuccess,
    setActiveMatch,
    setActiveOffer,
    setActiveProject,
    setMatchesActiveTab,
    setMatchInteracted,
    setMatchInteractedFailed,
    setMatchInteractedSuccess,
};
