//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                             from 'react';
import styles                            from './styles.module.scss';
import PropTypes                         from '../../PropTypes';
import ComponentHelper                   from '../../../helper/ComponentHelper';
import TextButton                        from '../TextButton';
import LeftRightSplitter                 from '../LeftRightSplitter';
import LeftRightSplitterContentAlignment from '../LeftRightSplitter/LeftRightSplitterContentAlignment';
import HiddenSubmitButton                from '../HiddenSubmitButton';
import IconButton                        from '../IconButton';
import IconButtonTheme                   from '../IconButton/IconButtonTheme';
import Input                             from '../Input';
import Spacer                            from '../Spacer';
import InputType                         from '../Input/InputType';
import I18n                              from 'i18next';
import Environment                       from '../../../helper/Environment';
import _                                 from 'lodash';
import AlertBoxManager                   from '../../connected/AlertBoxManager';

class SplitPaneLogin extends React.Component {
    getDataProtectionUrl = () => {
        if (!Environment.isProduction()) {
            return I18n.t('dataProtectionUrlStaging');
        }

        return I18n.t('dataProtectionUrlProduction');
    };

    getTermsOfServiceUrl = () => {
        if (!Environment.isProduction()) {
            return I18n.t('termsOfUseUrlStaging');
        }

        return I18n.t('termsOfUseUrlProduction');
    };

    loginFormReference = (loginForm) => {
        this.loginForm = loginForm;
    };

    render () {
        return (
            <div className={styles.wrapper}>
                <AlertBoxManager />
                <form
                    onSubmit={this.props.loginButtonPressed}
                    ref={this.loginFormReference}
                >
                    <h3>
                        {I18n.t('login')}
                    </h3>
                    <Input
                        errorText={this.props.eMailError}
                        placeholder={I18n.t('eMailAddress')}
                        onChange={this.props.eMailAddressChanged}
                        type={InputType.email}
                        value={this.props.eMailAddress}
                    />
                    <Input
                        errorText={this.props.passwordError}
                        placeholder={I18n.t('password')}
                        onChange={this.props.passwordChanged}
                        type={InputType.password}
                        value={this.props.password}
                    />
                    <LeftRightSplitter
                        contentAlignment={LeftRightSplitterContentAlignment.center}
                        leftContent={this.renderLostPasswordButton()}
                        rightContent={this.renderButtons()}
                    />
                    <Spacer height={80} />
                    <p
                        className={styles.subText}
                        dangerouslySetInnerHTML={
                            {
                                __html: I18n.t(
                                    'loginText',
                                    {
                                        'dataProtectionUrl': this.getDataProtectionUrl(),
                                        'termsOfServiceUrl': this.getTermsOfServiceUrl(),
                                        interpolation:       {
                                            escapeValue: false,
                                        },
                                    },
                                ),
                            }
                        }
                    />
                    <HiddenSubmitButton />
                </form>
            </div>
        );
    }

    renderLostPasswordButton = () => {
        return (
            <TextButton
                onClick={this.props.lostPasswordButtonPressed}
                text={I18n.t('lostPassword')}
            />
        );
    };

    renderButtons = () => {
        return (
            <div className={styles.buttonContainer}>
                <IconButton
                    onClick={this.register}
                    text={I18n.t('registerAction')}
                    theme={IconButtonTheme.grayGhost}
                />
                <IconButton
                    onClick={this.submitForm}
                    text={I18n.t('loginAction')}
                    theme={IconButtonTheme.turquoiseGhost}
                />
            </div>
        );
    };

    register = () => {
        this.props.registerButtonPressed();
    };

    submitForm = () => {
        this.loginForm.dispatchEvent(new Event('submit'));
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = SplitPaneLogin;

Component.propTypes = {
    eMailAddress:              PropTypes.string,
    eMailAddressChanged:       PropTypes.func,
    eMailError:                PropTypes.string,
    loginButtonPressed:        PropTypes.func,
    lostPasswordButtonPressed: PropTypes.func,
    password:                  PropTypes.string,
    passwordChanged:           PropTypes.func,
    passwordError:             PropTypes.string,
    registerButtonPressed:     PropTypes.func,
};

Component.defaultProps = {
    eMailAddress:              null,
    eMailAddressChanged:       _.noop,
    eMailError:                null,
    loginButtonPressed:        _.noop,
    lostPasswordButtonPressed: _.noop,
    password:                  '',
    passwordChanged:           _.noop,
    passwordError:             null,
    registerButtonPressed:     _.noop,
};

Component.renderAffectingProps = [
    'eMailAddress',
    'eMailError',
    'password',
    'passwordError',
];

Component.renderAffectingStates = [];

export default Component;