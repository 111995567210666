//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import { Component }   from 'react';
import I18n            from 'i18next';
import PageTitleHelper from '../../helper/PageTitle';
import styles          from './styles.module.scss';
import { Helmet }      from 'react-helmet';

class Screen extends Component {
    render () {
        return (
            <div className={styles.wrapper}>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('login'))}
                    </title>
                </Helmet>
                <div className={styles.background}>
                    {this.renderText()}
                </div>
            </div>
        );
    }

    renderText = () => {
        const text      = I18n.t('loginClaim');
        const textParts = text.split('\n');

        return (
            <h3 className={styles.textWrapper}>
                {textParts.map(this.renderTextPart)}
            </h3>
        );
    };

    renderTextPart = (textPart, index) => {
        return (
            <div
                className={styles.text}
                key={'text_' + index}
            >
                {textPart}
            </div>
        );
    };
}

export default Screen;
