//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                          from 'lodash';
import ContentWrapper             from '../../components/stateless/ContentWrapper';
import I18n                       from 'i18next';
import MatchContentItem           from '../../components/connected/MatchContentItem';
import moment                     from 'moment';
import NoDataAvailablePlaceholder from '../../components/stateless/NoDataAvailablePlaceholder';
import PageTitleHelper            from '../../helper/PageTitle';
import React                      from 'react';
import { Component }              from 'react';
import TabBar                     from '../../components/stateless/TabBar';
import { ActiveProjectActions }   from '../../store/actions/activeProject';
import { bindActionCreators }     from 'redux';
import { connect }                from 'react-redux';
import { Helmet }                 from 'react-helmet';

class Screen extends Component {
    matchContentItemClicked = (match) => {
        if (match.searchingCompanyInteracted) {
            return null;
        }

        this.props.setMatchInteracted({ match });
    };

    getMatchesForActiveTab = () => {
        const sortedMatches = _.sortBy(
            this.props.matches,
            [
                (match) => {
                    if (this.props.activeMatchesTabIndex === 0) {
                        return moment(match.updatedAt);
                    }

                    return moment(match.createdAt);
                },
            ],
        ).reverse();

        return sortedMatches;
    };

    onTabChanged = (tabIndex) => {
        this.props.setMatchesActiveTab({
            tabIndex,
        });
    };

    render() {
        return (
            <ContentWrapper>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('matches'))}
                    </title>
                </Helmet>
                <TabBar
                    activeTabIndex={this.props.activeMatchesTabIndex}
                    tabChanged={this.onTabChanged}
                    tabTitles={[
                        I18n.t('matchesAll'),
                        I18n.t('matchesNew'),
                    ]}
                />
                {this.renderMatchesForActiveTab()}
            </ContentWrapper>
        );
    }

    renderMatchesForActiveTab = () => {
        const matchesForActiveTab = this.getMatchesForActiveTab();

        if (matchesForActiveTab.length === 0) {
            return (
                <NoDataAvailablePlaceholder
                    title={I18n.t('noMatches')}
                    subTitle={I18n.t('noMatchesFound')}
                />
            );
        }

        return matchesForActiveTab.map(this.renderContentItem);
    };

    renderContentItem = (match, index) => {
        return (
            <MatchContentItem
                key={index}
                match={match}
                onClick={() => {
                    this.matchContentItemClicked(match);
                }}
            />
        );
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(ActiveProjectActions, dispatch);

const mapStateToProps = state => (
    {
        activeMatchesTabIndex: state.activeProject.activeMatchesTabIndex,
        matches:               state.activeProject.matches,
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);
