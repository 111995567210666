//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                    from 'react';
import _                        from 'lodash';
import CompanyTypes             from '../../../constants/CompanyTypes';
import ComponentHelper          from '../../../helper/ComponentHelper';
import DateTimeHelper           from '../../../helper/DateTime';
import PropTypes                from '../../PropTypes';
import SideBarMenu              from '../../stateless/SideBarMenu';
import SideBarMenuItem          from '../../stateless/SideBarMenuItem';
import { ActiveProjectActions } from '../../../store/actions/activeProject';
import { bindActionCreators }   from 'redux';
import { connect }              from 'react-redux';

class MatchSideBar extends React.Component {
    getSubText = (match) => {
        let lastInteractionTimestamp = DateTimeHelper.getTimeString(
            match[this.props.subTextDateKey],
            null,
        );

        if (!lastInteractionTimestamp) {
            lastInteractionTimestamp = DateTimeHelper.getTimeString(
                match[this.props.subTextDateFallbackKey],
                this.props.subTextFallbackText,
            );
        }

        return lastInteractionTimestamp;
    };

    matchPressed = (match) => {
        this.props.setActiveMatch({
            match,
        });
    };

    ownCompanyIsCompany = () => {
        return this.props.companyType === CompanyTypes.company;
    };

    render () {
        if (this.props.matches) {
            return (
                <SideBarMenu>
                    {this.props.matches.map(this.renderSubSideBarCompany)}
                </SideBarMenu>
            );
        }

        return null;
    }

    renderSubSideBarCompany = (match, index) => {
        const offeringCompany          = match.offeringCompany;
        const matchIsActive            = (
            this.props.activeMatch &&
            this.props.activeMatch.iri === match.iri
        );
        const lastInteractionTimestamp = this.getSubText(match);

        return (
            <SideBarMenuItem
                company={match.offeringCompany}
                isActive={matchIsActive}
                key={index}
                onClick={() => {
                    this.matchPressed(match);
                }}
                showNewContentIndicator={(
                    this.ownCompanyIsCompany() ?
                        match.searchingCompanyHasUnreadMessages :
                        match.offeringCompanyHasUnreadMessages
                )}
                subText={lastInteractionTimestamp}
                text={offeringCompany.name}
            />
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = MatchSideBar;

Component.propTypes = {
    activeMatch:            PropTypes.object,
    matches:                PropTypes.array,
    subTextDateFallbackKey: PropTypes.string,
    subTextDateKey:         PropTypes.string,
    subTextFallbackText:    PropTypes.string,
};

Component.defaultProps = {
    activeMatch:            null,
    matches:                null,
    subTextDateFallbackKey: null,
    subTextDateKey:         null,
    subTextFallbackText:    null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(ActiveProjectActions, dispatch);

const mapStateToProps = state => (
    {
        activeMatch: _.get(state, 'activeProject.activeMatch'),
        companyType: _.get(state, 'company.ownCompany.companyType'),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);