//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import classNames      from 'classnames';
import ComponentHelper from '../../../helper/ComponentHelper';
import Icon            from '../Icon';
import IconType        from '../Icon/IconType';
import Link            from '../Link';
import PropTypes       from '../../PropTypes';
import styles          from './styles.module.scss';

class MainMenuItem extends React.Component {
    render () {
        return (
            <li className={styles.wrapper}>
                {this.renderLink()}
            </li>
        );
    }

    renderLink = () => {
        if (this.props.onClick) {
            return (
                <div
                    className={classNames(
                        styles.link,
                        {
                            [styles.linkActive]:     this.props.isActive,
                            [styles.reducedPadding]: this.props.reducePadding,
                        },
                    )}
                    onClick={this.props.onClick}
                >
                    {this.renderIcon()}
                    {this.props.text}
                </div>
            );
        }

        if (this.props.to) {
            return (
                <Link
                    className={classNames(
                        styles.link,
                        {
                            [styles.linkActive]:     this.props.isActive,
                            [styles.reducedPadding]: this.props.reducePadding,
                        },
                    )}
                    to={this.props.to}
                >
                    {this.renderIcon()}
                    {this.props.text}
                </Link>
            );
        }

        return null;
    };

    renderIcon = () => {
        if (this.props.iconType) {
            return (
                <div className={styles.iconWrapper}>
                    <Icon iconType={this.props.iconType} />
                </div>
            );
        }

        return null;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = MainMenuItem;

Component.propTypes = {
    iconType:      PropTypes.oneOf(Object.values(IconType)),
    isActive:      PropTypes.bool,
    onClick:       PropTypes.func,
    reducePadding: PropTypes.bool,
    text:          PropTypes.string,
    to:            PropTypes.string,
};

Component.defaultProps = {
    iconType:      null,
    isActive:      false,
    onClick:       null,
    reducePadding: false,
    text:          '',
    to:            '',
};

Component.renderAffectingProps = [
    'isActive',
    'reducePadding',
    'iconType',
    'text',
];

Component.renderAffectingStates = [];

export default Component;