//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const TerritoriesTypes = {
    FETCH_TERRITORIES:         'Territory/FETCH_TERRITORIES',
    FETCH_TERRITORIES_FAILED:  'Territory/FETCH_TERRITORIES_FAILED',
    FETCH_TERRITORIES_SUCCESS: 'Territory/FETCH_TERRITORIES_SUCCESS',
};

const fetchTerritories = makeActionCreator(
    TerritoriesTypes.FETCH_TERRITORIES,
);

const fetchTerritoriesFailed = makeActionCreator(
    TerritoriesTypes.FETCH_TERRITORIES_FAILED,
);

const fetchTerritoriesSuccess = makeActionCreator(
    TerritoriesTypes.FETCH_TERRITORIES_SUCCESS,
    {
        territories: null,
    },
);

export const TerritoriesActions = {
    fetchTerritories,
    fetchTerritoriesFailed,
    fetchTerritoriesSuccess,
};