//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                                      from 'lodash';
import CompanyProfileHeaderTitle              from '../CompanyProfileHeaderTitle';
import CompanyProfileHeaderTitleTextAlignment from '../CompanyProfileHeaderTitle/CompanyProfileHeaderTitleTextAlignment';
import ComponentHelper                        from '../../../helper/ComponentHelper';
import I18n                                   from 'i18next';
import PropTypes                              from '../../PropTypes';
import React                                  from 'react';
import styles                                 from './styles.module.scss';
import Icon                                   from '../Icon';
import IconType                               from '../Icon/IconType';
import CompanyTypes                           from '../../../constants/CompanyTypes';

class CompanyProfileHeader extends React.Component {
    getTranslatedCompanyType = (companyType) => {
        if (companyType === CompanyTypes.videoProduction) {
            return I18n.t('videoProduction');
        }

        return I18n.t('company');
    };

    getLinkTargetForPropertyName = (propertyName, value) => {
        switch (propertyName) {
            // @formatter:off
            case 'email': return 'mailto:' + value;
            case 'phone': return 'tel:' + value;
            // @formatter:on
        }

        return value;
    };

    getTitleForPropertyName = (propertyName) => {
        switch (propertyName) {
            // @formatter:off
            case 'email':     return I18n.t('eMailAddress');
            case 'facebook':  return I18n.t('facebook');
            case 'instagram': return I18n.t('instagram');
            case 'phone':     return I18n.t('phoneNumber');
            case 'website':   return I18n.t('website');
            case 'youtube':   return I18n.t('youTube');
            // @formatter:on
        }

        return null;
    };

    render () {
        const company     = _.get(this.props, 'company');
        const companyName = _.get(company, 'name');
        const companyType = _.get(company, 'companyType');

        return (
            <div className={styles.wrapper}>
                <div className={styles.bottomWrapper}>
                    <div className={styles.bottomWrapperInner}>
                        <CompanyProfileHeaderTitle
                            editButtonPressed={this.props.editButtonPressed}
                            showEditButton={this.props.showEditButton}
                            textAlignment={CompanyProfileHeaderTitleTextAlignment.center}
                            title={companyName}
                        />
                        <h3>
                            {this.getTranslatedCompanyType(companyType)}
                        </h3>
                        {this.renderLinkList()}
                    </div>
                </div>
            </div>
        );
    }

    renderLinkList = () => {
        const socialMedias   = _.get(this.props, 'company.socialMedias', []);
        const links          = [];
        // Also see the backend, if this list changes: App\Subscriber\SocialMediaApiSubscriber
        const possibleValues = [
            {
                icon:         IconType.mail,
                propertyName: 'email',
            },
            {
                icon:         IconType.facebook,
                propertyName: 'facebook',
            },
            {
                icon:         IconType.globe,
                propertyName: 'website',
            },
            {
                icon:         IconType.instagram,
                propertyName: 'instagram',
            },
            {
                icon:         IconType.youtube,
                propertyName: 'youtube',
            },
            {
                icon:         IconType.phone,
                propertyName: 'phone',
            },
        ];

        possibleValues.forEach((possibleValue, index) => {
            const value = _.find(socialMedias, { type: possibleValue.propertyName });

            if (value) {
                const linkTarget = this.getLinkTargetForPropertyName(possibleValue.propertyName, value.url);
                const title      = this.getTitleForPropertyName(possibleValue.propertyName);

                links.push(
                    <li
                        className={styles.link}
                        title={title}
                        key={index}
                    >
                        <a
                            href={linkTarget}
                            target={'_blank'}
                        >
                            <Icon iconType={possibleValue.icon} />
                        </a>
                    </li>,
                );
            }
        });

        if (links) {
            return (
                <ul className={styles.linkList}>
                    {links}
                </ul>
            );
        }

        return null;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CompanyProfileHeader;

Component.propTypes = {
    company:        PropTypes.object,
    showEditButton: PropTypes.bool,
};

Component.defaultProps = {
    company:           null,
    editButtonPressed: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;