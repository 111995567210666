//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                                  from 'react';
import * as Api                               from '../../../api';
import _                                      from 'lodash';
import colors                                 from '../../../styles/colors.scss';
import CompanyProfileHeaderTitle              from '../CompanyProfileHeaderTitle';
import CompanyProfileHeaderTitleTextAlignment from '../CompanyProfileHeaderTitle/CompanyProfileHeaderTitleTextAlignment';
import ComponentHelper                        from '../../../helper/ComponentHelper';
import I18n                                   from 'i18next';
import PropTypes                              from '../../PropTypes';
import styles                                 from './styles.module.scss';

class CompanyProfileImages extends React.Component {
    getFilledImages = () => {
        const images = _.cloneDeep(this.props.images);

        for (let i = 0; i < 9; i++) {
            if (!images[i]) {
                images.push(null);
            }
        }

        return images;
    };

    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.wrapperInner}>
                    <CompanyProfileHeaderTitle
                        editButtonPressed={this.props.editButtonPressed}
                        showEditButton={this.props.showEditButton}
                        textAlignment={CompanyProfileHeaderTitleTextAlignment.left}
                        title={I18n.t('profileImages')}
                    />
                    {this.renderImages()}
                </div>
            </div>
        );
    }

    renderImage = (image, index) => {
        const style = (
            image ?
                { backgroundImage: 'url(' + Api.getImagePath(image.path) + ')' } :
                { backgroundColor: colors.colorGrayLighter7 }
        );

        return (
            <div
                className={styles.image}
                key={index}
                style={style}
            />
        );
    };

    renderImages = () => {
        const filledImages = this.getFilledImages();

        return (
            <div className={styles.imageWrapper}>
                {filledImages.map(this.renderImage)}
            </div>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CompanyProfileImages;

Component.propTypes = {
    editButtonPressed: PropTypes.func,
    images:            PropTypes.array,
    showEditButton:    PropTypes.bool,
};

Component.defaultProps = {
    editButtonPressed: _.noop,
    images:            [],
    showEditButton:    false,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
