//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                     from 'react';
import _                         from 'lodash';
import classNames                from 'classnames';
import ComponentHelper           from '../../../helper/ComponentHelper';
import DatePicker                from '../DatePicker';
import DatePickerOverlayPosition from '../DatePicker/DatePickerOverlayPosition';
import I18n                      from 'i18next';
import InputError                from '../InputError';
import InputSize                 from './InputSize';
import InputType                 from './InputType';
import InputUnitPosition         from './InputUnitPosition';
import moment                    from 'moment';
import PropTypes                 from '../../PropTypes';
import styles                    from './styles.module.scss';
import TextHelper                from '../../../helper/Text';
import { DebounceInput }         from 'react-debounce-input';

class Input extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
        };
    }

    getElement = () => {
        if (this.props.size === InputSize.multiLine) {
            return 'textarea';
        }

        return 'input';
    };

    getWrapperClass = (isChildOfOtherComponent, noMarginBottom) => {
        return classNames(
            styles.wrapper,
            {
                [styles.wrapperActive]:         this.state.active,
                [styles.wrapperError]:          this.props.errorText,
                [styles.wrapperHasContent]:     this.props.markWhenHasContent && this.props.value,
                [styles.wrapperIsChild]:        isChildOfOtherComponent,
                [styles.wrapperNoMarginBottom]: noMarginBottom,
            },
        );
    };

    onBlur = () => {
        this.setActive(false);
    };

    onDatePickerInputChange = (event) => {
        // We set Hour 12 here, because 00:00 could result in a different day in the backend, depending on the server timezone.
        const newDate = moment(event.target.value, I18n.t('datePickerDateFormatMoment')).set('hour', 12);

        if (newDate.isValid()) {
            this.props.onChange(newDate.toDate());
        }
    };

    onFocus = () => {
        this.setActive(true);
    };

    render() {
        const isDateInput = this.props.type === InputType.date;
        let input         = null;

        if (isDateInput) {
            const selected        = (
                this.props.value ?
                    new Date(this.props.value) :
                    null
            );
            const DatePickerInput = (data) => {
                return this.renderInput(
                    (
                        data.value !== '01.01.1970' ?
                            data.value :
                            ''
                    ),
                    this.onDatePickerInputChange,
                    data.onClick,
                    1000,
                    data.onClick,
                    true,
                );
            };

            input = (
                <DatePicker
                    key={selected}
                    input={(
                        <DatePickerInput />
                    )}
                    noMarginBottom={this.props.noMarginBottom}
                    onDateChanged={this.props.onChange}
                    overlayPosition={this.props.overlayPosition}
                    selected={selected}
                />
            );
        } else {
            input = this.renderInput(
                TextHelper.cleanup(this.props.value),
                this.props.onChange,
                this.onFocus,
                1000,
                null,
                this.props.noMarginBottom,
            );
        }

        return (
            <div className={styles.errorWrapper}>
                {input}
                {this.renderError()}
            </div>
        );
    }

    renderError = () => {
        if (this.props.errorText) {
            return (
                <InputError
                    marginTop={
                        this.props.noMarginBottom ?
                            0 :
                            -10
                    }
                    text={this.props.errorText}
                />
            );
        }

        return null;
    };

    renderInput = (value, onChange, onFocus, debounceTimeout, onClick, noMarginBottom) => {
        const isDateInput  = this.props.type === InputType.date;
        const wrapperClass = this.getWrapperClass(isDateInput, noMarginBottom);

        return (
            <div
                className={classNames(
                    wrapperClass,
                    {
                        [styles.hidden]:     this.props.type === InputType.hidden,
                        [styles.alignInput]: this.props.paddingRight,
                    },
                )}
                onClick={onClick}
            >
                {this.renderUnitPrefix()}
                <DebounceInput
                    className={classNames(
                        styles.input,
                        {
                            [styles.multiLine]: this.props.size === InputSize.multiLine,
                        },
                    )}
                    debounceTimeout={debounceTimeout}
                    element={this.getElement()}
                    max={this.props.maximumValue} // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-144
                    min={this.props.minimumValue} // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-144
                    minLength={this.props.minLength}
                    onBlur={this.onBlur}
                    onChange={onChange}
                    onFocus={onFocus}
                    placeholder={this.props.placeholder}
                    step={this.props.step} // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-144
                    type={
                        // Fall back to text type since we don't need the html input date features
                        isDateInput ?
                            InputType.text :
                            this.props.type
                    }
                    value={value ? value : ''}
                    autoFocus={true}
                />
                {this.renderUnitPostfix()}
            </div>
        );
    };

    renderUnit = (unitPosition) => {
        return (
            <div
                className={
                    classNames(
                        styles.unit,
                        {
                            [styles.unitPostfix]:   unitPosition === InputUnitPosition.postfix,
                            [styles.unitPrefix]:    unitPosition === InputUnitPosition.prefix,
                            [styles.unitMultiLine]: this.props.size === InputSize.multiLine,
                        },
                    )
                }
            >
                {
                    unitPosition === InputUnitPosition.postfix ?
                        this.props.postfix :
                        ''
                }
                {
                    unitPosition === InputUnitPosition.prefix ?
                        this.props.prefix :
                        ''
                }
            </div>
        );
    };

    renderUnitForUnitPosition = (unitPosition) => {
        if (
            (
                this.props.prefix &&
                unitPosition === InputUnitPosition.prefix
            )
            ||
            (
                this.props.postfix &&
                unitPosition === InputUnitPosition.postfix
            )
        ) {
            return this.renderUnit(unitPosition);
        }

        return null;
    };

    renderUnitPostfix = () => {
        return this.renderUnitForUnitPosition(InputUnitPosition.postfix);
    };

    renderUnitPrefix = () => {
        return this.renderUnitForUnitPosition(InputUnitPosition.prefix);
    };

    setActive = (active) => {
        this.setState({
            active,
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Input;

Component.propTypes = {
    errorText:          PropTypes.string,
    markWhenHasContent: PropTypes.bool,
    maximumValue:       PropTypes.number,
    minimumValue:       PropTypes.number,
    minLength:          PropTypes.number,
    noMarginBottom:     PropTypes.bool,
    onChange:           PropTypes.func,
    overlayPosition:    PropTypes.oneOf(Object.keys(DatePickerOverlayPosition)),
    paddingRight:       PropTypes.bool,
    placeholder:        PropTypes.string,
    postfix:            PropTypes.string,
    prefix:             PropTypes.string,
    size:               PropTypes.oneOf(Object.keys(InputSize)),
    step:               PropTypes.number,
    type:               PropTypes.oneOf(Object.keys(InputType)),
    value:              PropTypes.string,
};

Component.defaultProps = {
    errorText:          null,
    markWhenHasContent: true,
    maximumValue:       null,
    minimumValue:       null,
    minLength:          2,
    noMarginBottom:     false,
    onChange:           _.noop,
    overlayPosition:    DatePickerOverlayPosition.bottom,
    paddingRight:       false,
    placeholder:        '',
    postfix:            null,
    prefix:             null,
    size:               InputSize.singleLine,
    step:               null,
    type:               InputType.text,
    value:              null,
};

Component.renderAffectingProps = [
    'errorText',
    'markWhenHasContent',
    'maximumValue',
    'minimumValue',
    'minLength',
    'noMarginBottom',
    'overlayPosition',
    'paddingRight',
    'placeholder',
    'postfix',
    'prefix',
    'size',
    'step',
    'type',
    'value',
];

Component.renderAffectingStates = [
    'active',
];

export default Component;
