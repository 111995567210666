//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                        from 'react';
import _                            from 'lodash';
import CallbackRequestBox           from '../CallbackRequestBox';
import ComponentHelper              from '../../../helper/ComponentHelper';
import ErrorAwareOverlayPane        from '../../connected/ErrorAwareOverlayPane';
import FormRow                      from '../../stateless/FormRow';
import GA                           from '../../../helper/GA';
import I18n                         from 'i18next';
import OverlayButton                from '../../stateless/OverlayButton';
import OverlayButtonTheme           from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper         from '../../stateless/OverlayButtonWrapper';
import OverlayPaneState             from '../../stateless/OverlayPane/OverlayPaneState';
import PropTypes                    from '../../PropTypes';
import Spacer                       from '../../stateless/Spacer';
import VideoTypeDropDown            from '../VideoTypeDropDown';
import { AlertBoxActions }          from '../../../store/actions/alertBox';
import { bindActionCreators }       from 'redux';
import { CallbackRequestActions }   from '../../../store/actions/callbackRequest';
import { connect }                  from 'react-redux';
import { CreateEditProjectActions } from '../../../store/actions/createEditProject';

class CreateProjectOverlayKeyDataPane extends React.Component {
    eventLabel = 'KeyData';

    continueButtonPressed = () => {
        this.props.setFormIsVisible({
            formIsVisible: false,
        });
        this.props.clearAlerts();

        requestAnimationFrame(() => {
            if (this.props.alertBoxesTaggedCount === 0) {
                GA.sendCreateProjectNextEvent(this.eventLabel);
                this.props.nextPane();
            }
        });
    };

    render() {
        return (
            <ErrorAwareOverlayPane
                content={this.renderContent()}
                footer={this.renderFooter()}
                title={I18n.t('keyData')}
                showErrors={!this.props.callbackRequestFormIsVisible}
                state={this.props.overlayPaneState}
                upcomingDistance={this.props.upcomingDistance}
            />
        );
    }

    renderContent = () => {
        return (
            <>
                {this.renderContentVideoType()}
                {this.renderContentCallbackRequestBox()}
            </>
        );
    };

    renderContentCallbackRequestBox = () => {
        return (
            <>
                <Spacer height={20} />
                <CallbackRequestBox sidePadding={80} />
                {
                    !this.props.callbackRequestFormIsVisible ?
                        <Spacer height={300} /> :
                        null
                }
            </>
        );
    };

    renderContentVideoType = () => {
        return (
            <FormRow
                label={I18n.t('videoTypeSelectionLabel')}
                tooltipText={I18n.t('projectDescriptionTooltipText')}
            >
                <VideoTypeDropDown
                    noMarginBottom={true}
                    onChange={this.videoTypeChanged}
                    onClear={this.videoTypeCleared}
                    selectedValue={_.get(this.props, 'videoType')}
                />
            </FormRow>
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.continueButtonPressed}
                    standAlone={false}
                    text={I18n.t('continue')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    videoTypeChanged = (selectedOption) => {
        this.props.setVideoType({
            videoType: selectedOption.value,
        });
    };

    videoTypeCleared = () => {
        this.props.setVideoType({
            videoType: null,
        });
    };
}

const Component = CreateProjectOverlayKeyDataPane;

Component.propTypes = {
    alertBoxesTaggedCount:        PropTypes.number,
    callbackRequestFormIsVisible: PropTypes.bool,
    overlayPaneState:             PropTypes.oneOf(Object.values(OverlayPaneState)),
    videoType:                    PropTypes.string,
    upcomingDistance:             PropTypes.number,
};

Component.defaultProps = {
    alertBoxesTaggedCount:        0,
    callbackRequestFormIsVisible: false,
    overlayPaneState:             OverlayPaneState.upcoming,
    upcomingDistance:             0,
    videoType:                    {},
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    AlertBoxActions,
    CallbackRequestActions,
    CreateEditProjectActions,
), dispatch);

const mapStateToProps = state => (
    {
        alertBoxesTaggedCount:        _.get(state, 'alertBox.alertBoxesTaggedCount', 0),
        callbackRequestFormIsVisible: _.get(state, 'callbackRequest.formIsVisible'),
        videoType:                    _.get(state, 'createEditProject.videoType'),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
