class Environment {
    static getEnvironment = () => {
        return process.env.NODE_ENV;
    };

    static isDevelopment = () => {
        const environment = Environment.getEnvironment();

        return environment === 'development';
    };

    static isStaging = () => {
        const href = window.location.href;

        return (
            href.indexOf('.test') > -1 ||
            href.indexOf('staging') > -1 ||
            href.indexOf('localhost') > -1
        );
    };

    static isProduction = () => {
        const environment = Environment.getEnvironment();

        return environment === 'production';
    };
}

export default Environment;