//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as Api        from '../../../api';
import _               from 'lodash';
import AvatarSize      from './AvatarSize';
import classNames      from 'classnames';
import ComponentHelper from '../../../helper/ComponentHelper';
import nameInitials    from 'name-initials';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import Routes          from '../../../constants/Routes';
import styles          from './styles.module.scss';
import { Link }        from 'react-router-dom';

class Avatar extends React.Component {
    render () {
        const company = _.get(this.props, 'company', {});

        if (!company) {
            return null;
        }

        const image = _.get(company, 'logo');
        const route = Routes.companyProfilePart + _.get(company, 'id');

        if (image && Object.keys(image).length) {
            const altText = _.get(image, 'altText', _.get(company, 'name', ''));
            const path    = Api.getImagePath(_.get(image, 'path'));

            if (path) {
                if (this.props.disableOnClick) {
                    return this.renderAvatar(path, altText, true);
                } else {

                    return (
                        <Link
                            to={route}
                            className={styles.link}
                        >
                            {this.renderAvatar(path, altText)}
                        </Link>
                    );
                }
            }
        }

        return (
            <Link
                to={route}
                className={styles.link}
            >
                {this.renderFallbackAvatar(_.get(company, 'name', ''))}
            </Link>
        );
    }

    renderAvatar = (path, altText, noHover) => {
        return (
            <img
                alt={altText}
                src={path}
                className={classNames(
                    styles.avatar,
                    styles[this.props.size],
                    {
                        [styles.noHover]: noHover,
                    },
                )}
            />
        );
    };

    renderFallbackAvatar = (companyName, noHover) => {
        return (
            <div
                className={classNames(
                    styles.avatar,
                    styles[this.props.size],
                    styles.fallback,
                    {
                        [styles.noHover]: noHover,
                    },
                )}
            >
                {/*TODO: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-402*/}
                {nameInitials(companyName)}
            </div>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Avatar;

Component.propTypes = {
    company:        PropTypes.object,
    disableOnClick: PropTypes.bool,
    size:           PropTypes.oneOf(Object.keys(AvatarSize)),
};

Component.defaultProps = {
    company:        {},
    disableOnClick: false,
    size:           AvatarSize.default,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;