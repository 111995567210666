//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import Avatar          from '../../connected/Avatar';
import classNames      from 'classnames';
import ComponentHelper from '../../../helper/ComponentHelper';
import Link            from '../Link';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';
import AvatarSize      from '../Avatar/AvatarSize';

class SideBarMenuItem extends React.Component {
    render () {
        return (
            <li className={styles.wrapper}>
                {this.renderContent()}
            </li>
        );
    }

    renderContent = () => {
        const buttonClassNames = classNames(
            styles.link,
            {
                [styles.linkActive]: this.props.isActive,
            },
        );
        const innerContent     = (
            <>
                <div className={styles.avatarWrapper}>
                    <Avatar
                        company={this.props.company}
                        size={AvatarSize.small}
                        disableOnClick={true}
                    />
                </div>
                <div className={styles.textWrapper}>
                    <h5 className={styles.text}>
                        {this.props.text}
                    </h5>
                    <span className={styles.subText}>
                        {this.props.subText}
                    </span>
                </div>
                {this.renderNewContentIndicator()}
            </>
        );

        if (this.props.onClick) {
            return (
                <div
                    className={buttonClassNames}
                    onClick={this.props.onClick}
                >
                    {innerContent}
                </div>
            );
        } else if (this.props.to) {
            return (
                <Link
                    className={buttonClassNames}
                    to={this.props.to}
                >
                    {innerContent}
                </Link>
            );
        }

        return null;
    };

    renderNewContentIndicator = () => {
        if (this.props.showNewContentIndicator) {
            return (
                <div className={styles.newContentIndicator} />
            );
        }

        return null;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = SideBarMenuItem;

Component.propTypes = {
    company:                 PropTypes.object,
    isActive:                PropTypes.bool,
    onClick:                 PropTypes.func,
    showNewContentIndicator: PropTypes.bool,
    subText:                 PropTypes.string,
    text:                    PropTypes.string,
    to:                      PropTypes.string,
};

Component.defaultProps = {
    company:                 null,
    isActive:                false,
    onClick:                 null,
    showNewContentIndicator: false,
    subText:                 '',
    text:                    '',
    to:                      '',
};

Component.renderAffectingProps = [
    'company',
    'isActive',
    'subText',
    'text',
];

Component.renderAffectingStates = [];

export default Component;