//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                        from 'react';
import _                            from 'lodash';
import BranchDropDown               from '../BranchDropDown';
import ChoiceSelector               from '../../stateless/ChoiceSelector';
import ChoiceSelectorMode           from '../../stateless/ChoiceSelector/ChoiceSelectorMode';
import ChoiceWidthMode              from '../../stateless/ChoiceSelector/ChoiceWidthMode';
import ComponentHelper              from '../../../helper/ComponentHelper';
import ErrorAwareOverlayPane        from '../../connected/ErrorAwareOverlayPane';
import FormRow                      from '../../stateless/FormRow';
import GA                           from '../../../helper/GA';
import GoalDropDown                 from '../GoalDropDown';
import I18n                         from 'i18next';
import OverlayButton                from '../../stateless/OverlayButton';
import OverlayButtonTheme           from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper         from '../../stateless/OverlayButtonWrapper';
import OverlayPaneState             from '../../stateless/OverlayPane/OverlayPaneState';
import PropTypes                    from '../../PropTypes';
import RangeInput                   from '../../stateless/RangeInput';
import RangeInputTheme              from '../../stateless/RangeInput/RangeInputTheme';
import RangeInputType               from '../../stateless/RangeInput/RangeInputType';
import Spacer                       from '../../stateless/Spacer';
import TargetGroupTypes             from '../../../constants/TargetGroupTypes';
import Window                       from '../../../helper/Window';
import { AlertBoxActions }          from '../../../store/actions/alertBox';
import { bindActionCreators }       from 'redux';
import { connect }                  from 'react-redux';
import { CreateEditProjectActions } from '../../../store/actions/createEditProject';

class CreateProjectOverlayGoalsPane extends React.Component {
    eventLabel = 'Goals';

    backButtonPressed = () => {
        GA.sendCreateProjectPreviousEvent(this.eventLabel);
        this.props.clearAlerts();
        this.props.previousPane();
    };

    branchChanged = (selectedOption) => {
        this.props.setBranch({
            branch: selectedOption.value,
        });
    };

    branchCleared = () => {
        this.props.setBranch({
            branch: null,
        });
    };

    continueButtonPressed = () => {
        this.props.clearAlerts();

        if (!this.props.targetGroupType && !Window.isMobile()) {
            this.props.showErrorAlertTagged({
                tag:  'targetGroupType',
                text: I18n.t('targetGroupTypeErrorEmpty'),
            });
        }

        requestAnimationFrame(() => {
            if (this.props.alertBoxesTaggedCount === 0) {
                GA.sendCreateProjectNextEvent(this.eventLabel);
                this.props.nextPane();
            }
        });
    };

    goalChanged = (selectedOption) => {
        this.props.setGoal({
            goal: selectedOption.value,
        });
    };

    goalCleared = () => {
        this.props.setGoal({
            goal: null,
        });
    };

    render() {
        return (
            <ErrorAwareOverlayPane
                content={this.renderContent()}
                footer={this.renderFooter()}
                title={I18n.t('goals')}
                showErrors={true}
                state={this.props.overlayPaneState}
                upcomingDistance={this.props.upcomingDistance}
            />
        );
    }

    renderContent = () => {
        return (
            <>
                {this.renderContentGoal()}
                {this.renderContentBranch()}
                {this.renderContentTargetGroupType()}
                {this.renderContentTargetGroupAgeRange()}
            </>
        );
    };

    renderContentBranch = () => {
        return (
            <FormRow
                label={I18n.t('branchLabel')}
                tooltipText={I18n.t('branchTooltipText')}
            >
                <BranchDropDown
                    forceFlagAsFilled={true}
                    noMarginBottom={true}
                    onChange={this.branchChanged}
                    onClear={this.branchCleared}
                    selectedValue={_.get(this.props, 'branch')}
                />
            </FormRow>
        );
    };

    renderContentGoal = () => {
        return (
            <FormRow
                label={I18n.t('goalsLabel')}
                tooltipText={I18n.t('goalsTooltipText')}
            >
                <GoalDropDown
                    noMarginBottom={true}
                    onChange={this.goalChanged}
                    onClear={this.goalCleared}
                    selectedValue={_.get(this.props, 'goal')}
                />
            </FormRow>
        );
    };

    renderContentTargetGroupAgeRange = () => {
        if (!Window.isMobile()) {
            return (
                <FormRow
                    label={I18n.t('targetGroupLabel')}
                    tooltipText={I18n.t('targetGroupTooltipText')}
                >
                    <RangeInput
                        maximumValue={99}
                        minimumValue={1}
                        type={RangeInputType.number}
                        withoutSlider={true}
                        onChange={this.targetGroupAgeRangeChanged}
                        postfix={I18n.t('years')}
                        postfixSingular={I18n.t('year')}
                        step={1}
                        theme={
                            this.props.targetGroupAgeRangeUnknown ?
                                RangeInputTheme.unimportant :
                                RangeInputTheme.default
                        }
                        valueEnd={this.props.targetGroupAgeRangeEnd}
                        valueStart={this.props.targetGroupAgeRangeStart}
                    />
                    <ChoiceSelector
                        choiceClicked={this.targetGroupAgeRangeUnknownClicked}
                        choices={[
                            {
                                selected:  this.props.targetGroupAgeRangeUnknown,
                                text:      I18n.t('fillLaterChoiceText'),
                                widthMode: ChoiceWidthMode.full,
                            },
                        ]}
                        mode={ChoiceSelectorMode.singleChoice}
                    />
                    <Spacer height={20} />
                </FormRow>
            );
        }

        return null;
    };

    renderContentTargetGroupType = () => {
        if (!Window.isMobile()) {
            return (
                <FormRow
                    label={I18n.t('targetGroupTypeLabel')}
                    tooltipText={I18n.t('targetGroupTooltipText')}
                >
                    <ChoiceSelector
                        choiceClicked={this.targetGroupTypeChanged}
                        choices={[
                            {
                                selected:  this.props.targetGroupType === TargetGroupTypes.businessToBusiness,
                                text:      I18n.t('targetGroupTypeBusinessToBusinessShort'),
                                value:     TargetGroupTypes.businessToBusiness,
                                widthMode: ChoiceWidthMode.half,
                            },
                            {
                                selected:  this.props.targetGroupType === TargetGroupTypes.businessToCustomer,
                                text:      I18n.t('targetGroupTypeBusinessToCustomerShort'),
                                value:     TargetGroupTypes.businessToCustomer,
                                widthMode: ChoiceWidthMode.half,
                            },
                        ]}
                        errorText={this.props.targetGroupTypeError}
                        mode={ChoiceSelectorMode.multipleChoice}
                    />
                </FormRow>
            );
        }

        return null;
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.backButtonPressed}
                    standAlone={false}
                    text={I18n.t('back')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.continueButtonPressed}
                    standAlone={false}
                    text={I18n.t('continue')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    targetGroupAgeRangeChanged = (rangeData) => {
        this.props.setTargetGroupAgeRange({
            end:   rangeData.endValue,
            start: rangeData.startValue,
        });
    };

    targetGroupAgeRangeUnknownClicked = () => {
        this.targetGroupAgeRangeChanged({
            endValue:   0,
            startValue: 0,
        });
        this.props.toggleTargetGroupAgeRangeUnknown();
    };

    targetGroupTypeChanged = (choiceData) => {
        this.props.setTargetGroupType({
            targetGroupType: choiceData.value,
        });
    };
}

const Component = CreateProjectOverlayGoalsPane;

Component.propTypes = {
    alertBoxesTaggedCount:      PropTypes.number,
    branch:                     PropTypes.string,
    goal:                       PropTypes.string,
    overlayPaneState:           PropTypes.oneOf(Object.values(OverlayPaneState)),
    targetGroupAgeRangeEnd:     PropTypes.number,
    targetGroupAgeRangeStart:   PropTypes.number,
    targetGroupAgeRangeUnknown: PropTypes.bool,
    targetGroupType:            PropTypes.oneOf(Object.values(TargetGroupTypes)),
    upcomingDistance:           PropTypes.number,
};

Component.defaultProps = {
    alertBoxesTaggedCount:      0,
    branch:                     null,
    goal:                       null,
    overlayPaneState:           OverlayPaneState.upcoming,
    targetGroupAgeRangeEnd:     99,
    targetGroupAgeRangeStart:   1,
    targetGroupAgeRangeUnknown: false,
    targetGroupType:            null,
    upcomingDistance:           0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    AlertBoxActions,
    CreateEditProjectActions,
), dispatch);

const mapStateToProps = state => (
    {
        alertBoxesTaggedCount:      _.get(state, 'alertBox.alertBoxesTaggedCount', 0),
        branch:                     _.get(state, 'createEditProject.branch'),
        goal:                       _.get(state, 'createEditProject.goal'),
        targetGroupAgeRangeEnd:     _.get(state, 'createEditProject.targetGroupAgeRange.end'),
        targetGroupAgeRangeStart:   _.get(state, 'createEditProject.targetGroupAgeRange.start'),
        targetGroupAgeRangeUnknown: _.get(state, 'createEditProject.targetGroupAgeRangeUnknown'),
        targetGroupType:            _.get(state, 'createEditProject.targetGroupType'),
        targetGroupTypeError:       _.get(state, 'alertBox.alertBoxesTagged.targetGroupType'),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
