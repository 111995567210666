//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }                from 'redux-saga/effects';
import { call }               from 'redux-saga/effects';
import * as Api               from '../../api';
import HydraHelper            from '../../helper/Hydra';
import { VideoFormatActions } from '../actions/videoFormat';
import { push }               from 'connected-react-router';
import Routes                 from '../../constants/Routes';

const fetchVideoFormats = function* () {
    const response = yield call(
        Api.fetchVideoFormats,
    );

    if (response.ok) {
        const cleanHydraResponse = HydraHelper.cleanupObject(response.data);
        const videoFormats       = cleanHydraResponse.member;

        yield put(VideoFormatActions.fetchVideoFormatsSuccess({
            videoFormats,
        }));
    } else {
        yield put(VideoFormatActions.fetchVideoFormatsFailed());
    }
};

export default {
    fetchVideoFormats,
};
