//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import ComponentHelper from '../../../helper/ComponentHelper';

class GifWrapper extends React.Component {
    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.imageWrapper}>
                    <img
                        src={this.props.imageSource}
                        draggable={false}
                    />
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = GifWrapper;

Component.propTypes = {
    imageSource: PropTypes.string,
};

Component.defaultProps = {
    imageSource: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
