//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _               from 'lodash';
import Avatar          from '../../connected/Avatar';
import AvatarSize      from '../Avatar/AvatarSize';
import ComponentHelper from '../../../helper/ComponentHelper';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';
import * as Api        from '../../../api';
import IconButton      from '../IconButton';
import IconType        from '../Icon/IconType';
import I18n            from 'i18next';
import IconButtonTheme from '../IconButton/IconButtonTheme';

class CompanyProfileHeader extends React.Component {
    render () {
        const company       = _.get(this.props, 'company');
        let backgroundImage = _.get(company, 'bannerImage.path', null);

        if (backgroundImage) {
            backgroundImage = Api.getImagePath(backgroundImage);
        }

        return (
            <div className={styles.wrapper}>
                <div
                    className={styles.topWrapper}
                    style={{
                        backgroundImage: 'url(' + backgroundImage + ')',
                    }}
                >
                    {this.renderAvatar()}
                </div>
                {this.renderEditButton()}
            </div>
        );
    }

    renderEditButton = () => {
        if (this.props.showEditButton) {
            return (
                <div className={styles.editButtonWrapper}>
                    <IconButton
                        iconType={IconType.edit}
                        onClick={this.props.editButtonPressed}
                        text={I18n.t('edit')}
                        theme={IconButtonTheme.turquoiseGhost}
                    />
                </div>
            );
        }

        return false;
    };

    renderAvatar = () => {
        return (
            <div className={styles.avatarWrapper}>
                <Avatar
                    company={this.props.company}
                    size={AvatarSize.extraLarge}
                />
            </div>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CompanyProfileHeader;

Component.propTypes = {
    company: PropTypes.object,
};

Component.defaultProps = {
    company: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;