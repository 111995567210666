//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import ComponentHelper from '../../../helper/ComponentHelper';
import _               from 'lodash';
import IconType        from '../Icon/IconType';
import Icon            from '../Icon';

class CloseButton extends React.Component {
    getStyle = () => {
        const style = {
            right: this.props.right,
            top:   this.props.top,
        };

        return style;
    };

    render () {
        const style = this.getStyle();

        return (
            <span
                className={styles.closeButton}
                onClick={this.props.onClick}
                style={style}
                title={this.props.title}
            >
                <Icon iconType={IconType.x} />
            </span>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CloseButton;

Component.propTypes = {
    onClick: PropTypes.func,
    right:   PropTypes.number,
    title:   PropTypes.string,
    top:     PropTypes.number,
};

Component.defaultProps = {
    onClick: _.noop,
    right:   0,
    title:   '',
    top:     0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;