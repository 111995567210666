//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                            from 'react';
import _                                from 'lodash';
import CompanyDetailsContentItemContent from '../../stateless/CompanyDetailsContentItemContent';
import CompanyTypes                     from '../../../constants/CompanyTypes';
import ComponentHelper                  from '../../../helper/ComponentHelper';
import ContentItem                      from '../../stateless/ContentItem';
import I18n                             from 'i18next';
import IconButton                       from '../../stateless/IconButton';
import IconButtonTheme                  from '../../stateless/IconButton/IconButtonTheme';
import IconType                         from '../../stateless/Icon/IconType';
import Normalize                        from '../../../helper/Normalize';
import OverlayManager                   from '../OverlayManager';
import Overlays                         from '../../../constants/Overlays';
import ProjectDetailsContentItemContent from '../../stateless/ProjectDetailsContentItemContent';
import ProjectSubMenuTypes              from '../../../constants/ProjectSubMenuTypes';
import PropTypes                        from '../../PropTypes';
import Routes                           from '../../../constants/Routes';
import { ActiveProjectActions }         from '../../../store/actions/activeProject';
import { bindActionCreators }           from 'redux';
import { connect }                      from 'react-redux';

class MatchContentItem extends React.Component {
    briefingButtonPressed = () => {
        this.setActiveProject(ProjectSubMenuTypes.briefing);
    };

    editOfferButtonPressed = () => {
        this.setActiveProject(ProjectSubMenuTypes.offers);
    };

    getCompetences = () => {
        const sortedCompetences = Normalize.competencesToStringArray(
            _.get(this.props, 'match.offeringCompany.coreCompetences'),
        );

        return sortedCompetences;
    };

    getFormats = () => {
        const sortedFormats = Normalize.formatsToStringArray(
            _.get(this.props, 'activeProject.videoFormats'),
        );

        return sortedFormats;
    };

    getLanguages = () => {
        const sortedLanguages = Normalize.languagesToStringArray(
            _.get(this.props, 'match.offeringCompany.languages'),
        );

        return sortedLanguages;
    };

    getLocations = () => {
        const sortedLocations = Normalize.locationsToStringArray(
            _.get(this.props, 'match.offeringCompany.companyLocations'),
        );

        return sortedLocations;
    };

    getPlaceOfWork = () => {
        return _.get(this.props, 'match.offeringCompany.placeOfWork.title', null);
    };

    getProfileRoute = (match) => {
        return Routes.companyProfilePart + match.offeringCompany.id;
    };

    messagesButtonPressed = () => {
        this.setActiveProject(ProjectSubMenuTypes.messages);
    };

    render() {
        switch (this.props.companyType) {
            // @formatter:off
            case CompanyTypes.company:         return this.renderContentItemCompany();
            case CompanyTypes.videoProduction: return this.renderContentItemVideoProduction();
            // @formatter:on
        }

        return null;
    }

    renderContentItemCompany = () => {
        const match = this.props.match;

        return (
            <ContentItem
                company={_.get(match, 'offeringCompany', null)}
                content={
                    <CompanyDetailsContentItemContent
                        competences={this.getCompetences()}
                        languages={this.getLanguages()}
                        locations={this.getLocations()}
                        placeOfWork={this.getPlaceOfWork()}
                        sendMessageButtonPressed={this.sendMessageButtonPressed}
                        viewOfferButtonPressed={this.viewOfferButtonPressed}
                    />
                }
                headerCenterContent={
                    <IconButton
                        key={'profile'}
                        text={I18n.t('viewProfile')}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={this.getProfileRoute(match)}
                    />
                }
                highlighted={!_.get(
                    match,
                    'searchingCompanyInteracted',
                )}
                title={_.get(match, 'offeringCompany.name')}
                subTitle={I18n.t('videoProductionSubTitle')}
                onClick={this.props.onClick}
            />
        );
    };

    renderContentItemVideoProduction = () => {
        const match        = this.props.match;
        const project      = this.props.activeProject;
        const projectState = _.get(project, 'state');
        const matchState   = _.get(match, 'matchState');

        return (
            <ContentItem
                company={_.get(match, 'searchingCompany', null)}
                content={
                    <ProjectDetailsContentItemContent
                        ageRangeFrom={_.get(project, 'targetGroupAgeRange.from')}
                        ageRangeTo={_.get(project, 'targetGroupAgeRange.to')}
                        budgetRangeInThousandsFrom={_.get(project, 'budgetRange.from')}
                        budgetRangeInThousandsTo={_.get(project, 'budgetRange.to')}
                        highlighted={!_.get(
                            match,
                            'offeringCompanyInteracted',
                        )}
                        formats={this.getFormats()}
                        projectState={projectState}
                        matchState={matchState}
                        type={Normalize.videoTypeStringFromProject(project)}
                        companyType={_.get(this.props, 'company.companyType')}
                    />
                }
                headerCenterContent={
                    <IconButton
                        key={'briefing'}
                        iconType={IconType.eye}
                        onClick={this.briefingButtonPressed}
                        text={I18n.t('briefingView')}
                        theme={IconButtonTheme.turquoiseGhost}
                    />
                }
                headerRightContent={
                    <>
                        <IconButton
                            key={'messages'}
                            iconType={IconType.fileText}
                            onClick={this.messagesButtonPressed}
                            text={I18n.t('messages')}
                            theme={IconButtonTheme.turquoiseGhost}
                        />
                        {this.renderContentItemVideoProductionOfferButton()}
                    </>
                }
                highlighted={!_.get(
                    match,
                    'offeringCompanyInteracted',
                )} // TODO: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-261
                title={_.get(match, 'searchingCompany.name')}
                subTitle={I18n.t('videoProductionSubTitle')}
                onClick={this.props.onClick}
            />
        );
    };

    renderContentItemVideoProductionOfferButton = () => {
        if (this.props.offers.length) {
            return (
                <IconButton
                    iconType={IconType.fileText}
                    key={'offerEdit'}
                    onClick={this.editOfferButtonPressed}
                    text={I18n.t('offerEdit')}
                    theme={IconButtonTheme.grayGhost}
                />
            );
        }

        return (
            <IconButton
                iconType={IconType.fileText}
                key={'offerSubmit'}
                text={I18n.t('offerSubmit')}
                theme={IconButtonTheme.turquoiseGhost}
                to={OverlayManager.getPathForOverlayKey(Overlays.createOffer)}
            />
        );
    };

    sendMessageButtonPressed = () => {
        this.setActiveProject(
            ProjectSubMenuTypes.messages,
            this.props.match,
        );
    };

    viewOfferButtonPressed = () => {
        this.setActiveProject(
            ProjectSubMenuTypes.offers,
            this.props.match,
        );
    };

    setActiveProject = (subMenuType, activeMatch) => {
        this.props.setActiveProject({
            activeMatch,
            project: this.props.project,
            subMenuType,
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = MatchContentItem;

Component.propTypes = {
    activeProject: PropTypes.object,
    companyType:   PropTypes.oneOf(Object.values(CompanyTypes)),
    match:         PropTypes.object,
    offers:        PropTypes.array,
    onClick:       PropTypes.func,
};

Component.defaultProps = {
    activeProject: null,
    companyType:   null,
    match:         null,
    offers:        [],
    onClick:       _.noop,
};

Component.renderAffectingProps = [
    'activeProject',
    'companyType',
    'match',
    'offers',
];

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(ActiveProjectActions, dispatch);

const mapStateToProps = state => (
    {
        activeProject: _.get(state, 'activeProject.activeProject'),
        company:       _.get(state, 'company.ownCompany'),
        companyType:   _.get(state, 'company.ownCompany.companyType'),
        offers:        _.get(state, 'activeProject.offers'),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
