//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper from '../../../helper/ComponentHelper';
import I18n            from 'i18next';
import moment          from 'moment';
import packageJson     from '../../../../package.json';
import gitHookCommitId from '../../../constants/Git.js';
import preval          from 'preval.macro';
import React           from 'react';
import styles          from './styles.module.scss';
import _               from 'lodash';

const buildTimestamp   = preval`module.exports = new Date().getTime();`;
const prevalCommitHash = preval`
    const fs = require('fs');
    const rev = fs.readFileSync('.git/HEAD').toString();

    if (rev.indexOf(':') === -1) {
        module.exports = rev;
    } else {
        module.exports = fs.readFileSync('.git/' + rev.substring(5).trim()).toString();
    }
`;

class VersionNumber extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    /**
     * This method tries to get the latest commit hash that was generated when the react application was built.
     * If that failed, it will fallback on the last known commit id generated by the "post merge" commit hook.
     *
     * @returns {string|*}
     */
    getCommitHash = () => {
        if (_.isString(prevalCommitHash)) {
            return prevalCommitHash;
        }

        return gitHookCommitId;
    };

    getDateString = () => {
        const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
        const formattedDate    = lastUpdateMoment.format(I18n.t('versionNumberDateFormat'));

        return formattedDate;
    };

    render () {
        const commitHash          = this.getCommitHash();
        const commitHashHashShort = commitHash.substring(0, 8);

        return (
            <div className={styles.versionNumberWrapper}>
                {I18n.t('versionText')}
                <span className={styles.versionNumber}>
                    {packageJson.version}
                    {'.'}
                    {buildTimestamp}
                </span>
                {' '}
                {'('}
                {this.getDateString()}
                {', '}
                {commitHashHashShort}
                {')'}
            </div>
        );
    }
}

const Component = VersionNumber;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

export default Component;