//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import DateTimeHelper  from '../../../helper/DateTime';
import ComponentHelper from '../../../helper/ComponentHelper';
import classNames      from 'classnames';
import MessageType     from './MessageType';
import FileListMode    from '../FileList/FileListMode';
import FileList        from '../FileList';
import Avatar          from '../../connected/Avatar';
import AvatarSize      from '../Avatar/AvatarSize';

class Message extends React.Component {
    render() {
        const timeString = DateTimeHelper.getTimeString(this.props.time);

        return (
            <div
                className={classNames(
                    styles.wrapper,
                    {
                        [styles.wrapperIncoming]: this.props.type === MessageType.incoming,
                        [styles.wrapperOutgoing]: this.props.type === MessageType.outgoing,
                    },
                )}
            >
                <div className={styles.head}>
                    <div className={styles.headLeft}>
                        {this.renderAvatar()}
                        <h3>
                            {this.props.name}
                        </h3>
                    </div>
                    <div className={styles.headRight}>
                        {timeString}
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.bodyInner}>
                        {this.props.message}
                        {this.renderAttachments()}
                    </div>
                </div>
                {this.renderAdditionalContent()}
            </div>
        );
    }

    renderAdditionalContent = () => {
        if (this.props.additionalContent) {
            return (
                <div className={styles.additionalContent}>
                    {this.props.additionalContent}
                </div>
            );
        }

        return null;
    };

    renderAttachments = () => {
        if (this.props.files && this.props.files.length) {
            return (
                <div className={styles.attachmentWrapper}>
                    <FileList
                        files={this.props.files}
                        mode={FileListMode.downloadSmall}
                    />
                </div>
            );
        }

        return null;
    };

    renderAvatar = () => {
        return (
            <div className={styles.avatarWrapper}>
                <Avatar
                    company={this.props.company}
                    size={AvatarSize.medium}
                />
            </div>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Message;

Component.propTypes = {
    additionalContent: PropTypes.children,
    company:           PropTypes.object,
    files:             PropTypes.arrayOf(PropTypes.fileListEntry),
    message:           PropTypes.string,
    name:              PropTypes.string,
    time:              PropTypes.string,
    type:              PropTypes.oneOf(Object.keys(MessageType)),
};

Component.defaultProps = {
    additionalContent: null,
    company:           null,
    files:             [],
    message:           '',
    name:              '',
    time:              '',
    type:              '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
