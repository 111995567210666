//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                           from 'react';
import { Component }                   from 'react';
import * as Api                        from '../../api';
import _                               from 'lodash';
import AlertBoxManager                 from '../../components/connected/AlertBoxManager';
import Cast                            from '../../helper/Cast';
import CompanyTypes                    from '../../constants/CompanyTypes';
import ContentItem                     from '../../components/stateless/ContentItem';
import ContentWrapper                  from '../../components/stateless/ContentWrapper';
import FileList                        from '../../components/stateless/FileList';
import FileListMode                    from '../../components/stateless/FileList/FileListMode';
import I18n                            from 'i18next';
import IconButton                      from '../../components/stateless/IconButton';
import IconButtonTheme                 from '../../components/stateless/IconButton/IconButtonTheme';
import IconType                        from '../../components/stateless/Icon/IconType';
import MatchSideBar                    from '../../components/connected/MatchSideBar';
import MatchStateTypes                 from '../../constants/MatchStateTypes';
import NoDataAvailablePlaceholder      from '../../components/stateless/NoDataAvailablePlaceholder';
import Normalize                       from '../../helper/Normalize';
import OfferFileList                   from '../../components/connected/OfferFileList';
import OfferOverviewContentItemContent from '../../components/stateless/OfferOverviewContentItemContent';
import OverlayManager                  from '../../components/connected/OverlayManager';
import Overlays                        from '../../constants/Overlays';
import PageTitleHelper                 from '../../helper/PageTitle';
import ProjectSubMenuTypes             from '../../constants/ProjectSubMenuTypes';
import Routes                          from '../../constants/Routes';
import SubSideBar                      from '../../components/stateless/SubSideBar';
import { ActiveProjectActions }        from '../../store/actions/activeProject';
import { bindActionCreators }          from 'redux';
import { connect }                     from 'react-redux';
import { CreateEditOfferActions }      from '../../store/actions/createEditOffer';
import { Helmet }                      from 'react-helmet';

class Screen extends Component {
    briefingButtonPressed = () => {
        this.setActiveProject(ProjectSubMenuTypes.briefing);
    };

    editOfferButtonPressed = () => {
        this.props.editOffer({
            matchAttachments: _.get(this.props.activeMatch, 'matchAttachments', []),
            offers:           this.props.offers,
        });
    };

    getToButton = () => {
        if (_.get(this.props, 'activeMatch.matchState') !== MatchStateTypes.declined) {
            return OverlayManager.getPathForOverlayKey(Overlays.createOffer);
        }

        return null;
    };

    messagesButtonPressed = () => {
        this.setActiveProject(ProjectSubMenuTypes.messages);
    };

    ownCompanyIsCompany = () => {
        return this.props.companyType === CompanyTypes.company;
    };

    render() {
        if (
            this.props.matches &&
            this.props.matches.length &&
            this.ownCompanyIsCompany()
        ) {
            return (
                <SubSideBar
                    mainContent={this.renderMainContent()}
                    sideBarContent={this.renderSubSideBarContent()}
                />
            );
        }

        return this.renderMainContent();
    }

    renderFileList = (hasAcceptedOffer) => {
        const fileList = [];

        if (this.props.offers) {
            for (const offerNumber in this.props.offers) {
                const offerNumberInt  = Cast.int(offerNumber);
                const offer           = this.props.offers[offerNumberInt];
                const isAcceptedOffer = offer.offerState === MatchStateTypes.accepted;

                fileList.push(
                    <OfferFileList
                        editOfferButtonPressed={this.editOfferButtonPressed}
                        offer={offer}
                        offerNumber={offerNumberInt + 1}
                        isAcceptedOffer={isAcceptedOffer}
                        hasAcceptedOffer={hasAcceptedOffer}
                    />,
                );
            }
        }

        const matchAttachments = _.get(this.props.activeMatch, 'matchAttachments', []);

        if (matchAttachments.length) {
            const otherFiles = [];

            for (const matchAttachment of matchAttachments) {
                otherFiles.push({
                    additionalText: null,
                    downloadUrl:    Api.getFilePath(matchAttachment.path),
                    name:           matchAttachment.name,
                });
            }

            fileList.push(
                <FileList
                    key={'other'}
                    files={otherFiles}
                    mode={FileListMode.download}
                    title={I18n.t('otherDocuments')}
                />,
            );
        }

        return fileList;
    };

    renderMainContent = () => {
        const hasAcceptedOffer = _.some(
            this.props.offers,
            {
                offerState: MatchStateTypes.accepted,
            },
        );

        return (
            <ContentWrapper>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('offers'))}
                    </title>
                </Helmet>
                <AlertBoxManager />
                {this.renderOffer(hasAcceptedOffer)}
            </ContentWrapper>
        );
    };

    renderNoOfferPage = () => {
        if (this.ownCompanyIsCompany()) {
            return this.renderNoOfferPageCompany();
        }

        return this.renderNoOfferPageVideoAgency();
    };

    renderNoOfferPageCompany = () => {
        return (
            <NoDataAvailablePlaceholder
                subTitle={I18n.t('offerEmptyTextCompany')}
                title={I18n.t('offerEmptyTitleCompany')}
            />
        );
    };

    renderNoOfferPageVideoAgency = () => {
        return (
            <NoDataAvailablePlaceholder
                buttonText={I18n.t('offerEmptyCallToAction')}
                buttonTo={this.getToButton()}
                subTitle={I18n.t('offerEmptyTextVideoAgency')}
                title={I18n.t('offerEmptyTitleVideoAgency')}
            />
        );
    };

    renderOffer = (hasAcceptedOffer) => {
        if (this.props.offers && this.props.offers.length) {
            return (
                <ContentItem
                    company={_.get(this.props, 'activeMatch.offeringCompany', null)}
                    content={
                        <OfferOverviewContentItemContent
                            fileLists={this.renderFileList(hasAcceptedOffer)}
                        />
                    }
                    headerCenterContent={
                        <IconButton
                            key={'briefing'}
                            onClick={this.briefingButtonPressed}
                            text={I18n.t('briefingView')}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />
                    }
                    headerRightContent={[
                        <IconButton
                            iconType={IconType.messageSquare}
                            onClick={this.messagesButtonPressed}
                            key={'messages'}
                            text={I18n.t('messages')}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />,
                    ]}
                    title={_.get(this.props.activeProject, 'title')}
                    subTitle={Normalize.videoTypeStringFromProject(this.props.activeProject)}
                />
            );
        }

        return this.renderNoOfferPage();
    };

    renderSubSideBarContent = () => {
        return (
            <MatchSideBar
                matches={this.props.matches}
                subTextDateFallbackKey={'lastOfferTimestamp'}
                subTextDateKey={'lastMessageTimestamp'}
                subTextFallbackText={I18n.t('matchNoOfferText')}
            />
        );
    };

    setActiveProject = (subMenuType) => {
        this.props.setActiveProject({
            project: this.props.activeProject,
            subMenuType,
        });
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        ActiveProjectActions,
        CreateEditOfferActions,
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        activeMatch:   _.get(state, 'activeProject.activeMatch'),
        activeProject: _.get(state, 'activeProject.activeProject'),
        companyType:   _.get(state, 'company.ownCompany.companyType'),
        offers:        _.get(state, 'activeProject.offers'),
        matches:       _.get(state, 'activeProject.matchesByLastOfferTimestamp'),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);
