//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                         from 'react';
import { connect }                   from 'react-redux';
import PropTypes                     from '../../PropTypes';
import { bindActionCreators }        from 'redux';
import { CreateEditProjectActions }  from '../../../store/actions/createEditProject';
import CreateProjectOverlayStateless from '../../stateless/CreateProjectOverlay';
import ComponentHelper               from '../../../helper/ComponentHelper';
import ApiMode                       from '../../../constants/ApiMode';

class CreateProjectOverlay extends React.Component {
    render () {
        return (
            <CreateProjectOverlayStateless
                activePaneIndex={this.props.activePaneIndex}
                mode={this.props.mode}
                progressInPercent={this.props.progressInPercent}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CreateProjectOverlay;

Component.propTypes = {
    activePaneIndex:   PropTypes.number,
    mode:              PropTypes.oneOf(Object.keys(ApiMode)),
    progressInPercent: PropTypes.number,
};

Component.defaultProps = {
    activePaneIndex:   0,
    mode:              null,
    progressInPercent: 0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(CreateEditProjectActions, dispatch);

const mapStateToProps = state => (
    {
        activePaneIndex:   state.createEditProject.activePaneIndex,
        mode:              state.createEditProject.mode,
        progressInPercent: state.createEditProject.progressInPercent,
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);