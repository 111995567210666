//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                    from 'react';
import _                        from 'lodash';
import GA                       from '../../helper/GA';
import GifWrapper               from '../../components/stateless/GifWrapper';
import I18n                     from 'i18next';
import IconButton               from '../../components/stateless/IconButton';
import IconButtonTheme          from '../../components/stateless/IconButton/IconButtonTheme';
import Link                     from '../../components/stateless/Link';
import PageTitleHelper          from '../../helper/PageTitle';
import Routes                   from '../../constants/Routes';
import Spacer                   from '../../components/stateless/Spacer';
import styles                   from './styles.module.scss';
import { bindActionCreators }   from 'redux';
import { Component }            from 'react';
import { compose }              from 'redux';
import { ConfigurationActions } from '../../store/actions/configuration';
import { connect }              from 'react-redux';
import { goBack }               from 'connected-react-router';
import { Helmet }               from 'react-helmet';
import { UserActions }          from '../../store/actions/user';
import { withRouter }           from 'react-router-dom';

class Screen extends Component {
    constructor(props) {
        super(props);

        const error = _.get(this.props, 'location.state.error');

        if (error) {
            GA.sendEvent(
                'Error',
                'redirect',
                error,
            );
        }
    }

    componentDidMount() {
        // Reset the location state, to avoid multiple events on page reload
        const { history } = this.props;

        history.replace();
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('pageNotFound'))}
                    </title>
                </Helmet>
                <Spacer height={100} />
                <div className={styles.container}>
                    <GifWrapper imageSource={require('../../assets/images/oops.gif')} />
                    <div className={styles.textWrapper}>
                        <h3>
                            {I18n.t('errorPageTitle')}
                        </h3>
                        <strong>
                            {I18n.t('errorPageSubtitle')}
                        </strong>
                        <p>
                            {I18n.t('errorPageText')}
                        </p>
                    </div>
                    {this.renderButton()}
                </div>
            </>
        );
    }

    renderHomeButton = () => {
        if (this.props.user.token) {
            return (
                <IconButton
                    text={I18n.t('backToHome')}
                    theme={IconButtonTheme.gray}
                    to={Routes.home}
                />
            );
        }

        return null;
    };

    renderReloadButton = () => {
        return (
            <>
                <IconButton
                    text={I18n.t('openContactForm')}
                    theme={IconButtonTheme.turquoiseGhost}
                    to={'https://framebutler.de/jetzt-angebot-einholen#kontakt'}
                    external={true}
                />
                <IconButton
                    text={I18n.t('contactPhoneNumberLabel')}
                    theme={IconButtonTheme.grayGhost}
                    to={I18n.t('contactPhoneNumber')}
                    external={true}
                />
            </>
        );
    };

    renderButton = () => {
        return (
            <div className={styles.buttonWrapper}>
                {this.renderHomeButton()}
                {this.renderReloadButton()}
            </div>
        );
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        ConfigurationActions,
        UserActions,
        {
            goBack,
        },
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        user: state.user.user,
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Screen);
