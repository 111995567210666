//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const CompetenceTypes = {
    FETCH_COMPETENCES:         'Competence/FETCH_COMPETENCES',
    FETCH_COMPETENCES_FAILED:  'Competence/FETCH_COMPETENCES_FAILED',
    FETCH_COMPETENCES_SUCCESS: 'Competence/FETCH_COMPETENCES_SUCCESS',
};

const fetchCompetences = makeActionCreator(
    CompetenceTypes.FETCH_COMPETENCES,
);

const fetchCompetencesFailed = makeActionCreator(
    CompetenceTypes.FETCH_COMPETENCES_FAILED,
);

const fetchCompetencesSuccess = makeActionCreator(
    CompetenceTypes.FETCH_COMPETENCES_SUCCESS,
    {
        competences: null,
    },
);

export const CompetenceActions = {
    fetchCompetences,
    fetchCompetencesFailed,
    fetchCompetencesSuccess,
};
