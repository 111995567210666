//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                                  from 'react';
import styles                                 from './styles.module.scss';
import PropTypes                              from '../../PropTypes';
import ComponentHelper                        from '../../../helper/ComponentHelper';
import _                                      from 'lodash';
import IconType                               from '../Icon/IconType';
import I18n                                   from 'i18next';
import IconButtonTheme                        from '../IconButton/IconButtonTheme';
import IconButton                             from '../IconButton';
import CompanyProfileHeaderTitleTextAlignment from './CompanyProfileHeaderTitleTextAlignment';
import classNames                             from 'classnames';

class CompanyProfileHeaderTitle extends React.Component {
    render() {
        return (
            <div
                className={classNames(
                    styles.wrapper,
                    {
                        [styles.wrapperCenter]:            this.props.textAlignment === CompanyProfileHeaderTitleTextAlignment.center,
                        [styles.wrapperEditButtonVisible]: this.props.showEditButton,
                        [styles.wrapperLeft]:              this.props.textAlignment === CompanyProfileHeaderTitleTextAlignment.left,
                    },
                )}
            >
                <h2 title={this.props.title}>
                    {!this.props.hideTitle ? this.props.title : ''}
                </h2>
                {this.renderEditButton()}
            </div>
        );
    }

    renderEditButton = () => {
        if (this.props.showEditButton) {
            return (
                <div className={styles.editButtonWrapper}>
                    <IconButton
                        iconType={IconType.edit}
                        onClick={this.props.editButtonPressed}
                        text={I18n.t('edit')}
                        theme={IconButtonTheme.turquoiseGhost}
                    />
                </div>
            );
        }

        return false;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CompanyProfileHeaderTitle;

Component.propTypes = {
    editButtonPressed: PropTypes.func,
    hideTitle:         PropTypes.bool,
    showEditButton:    PropTypes.bool,
    textAlignment:     PropTypes.oneOf(Object.keys(CompanyProfileHeaderTitleTextAlignment)),
    title:             PropTypes.string,
};

Component.defaultProps = {
    editButtonPressed: _.noop,
    hideTitle:         false,
    showEditButton:    null,
    textAlignment:     null,
    title:             null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
