//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const CallbackRequestTypes = {
    SEND_CALLBACK_REQUEST:         'CallbackRequest/SEND_CALLBACK_REQUEST',
    SEND_CALLBACK_REQUEST_FAILED:  'CallbackRequest/SEND_CALLBACK_REQUEST_FAILED',
    SEND_CALLBACK_REQUEST_SUCCESS: 'CallbackRequest/SEND_CALLBACK_REQUEST_SUCCESS',
    SET_AVAILABILITY_TYPE:         'CallbackRequest/SET_AVAILABILITY_TYPE',
    SET_E_MAIL_ADDRESS:            'CallbackRequest/SET_E_MAIL_ADDRESS',
    SET_FORM_IS_VISIBLE:           'CallbackRequest/SET_FORM_IS_VISIBLE',
    SET_PHONE_NUMBER:              'CallbackRequest/SET_PHONE_NUMBER',
};

const sendCallbackRequest = makeActionCreator(
    CallbackRequestTypes.SEND_CALLBACK_REQUEST,
);

const sendCallbackRequestFailed = makeActionCreator(
    CallbackRequestTypes.SEND_CALLBACK_REQUEST_FAILED,
);

const sendCallbackRequestSuccess = makeActionCreator(
    CallbackRequestTypes.SEND_CALLBACK_REQUEST_SUCCESS,
);

const setAvailabilityType = makeActionCreator(
    CallbackRequestTypes.SET_AVAILABILITY_TYPE, {
        availabilityType: null,
    },
);

const setEMailAddress = makeActionCreator(
    CallbackRequestTypes.SET_E_MAIL_ADDRESS, {
        eMailAddress: null,
    },
);

const setFormIsVisible = makeActionCreator(
    CallbackRequestTypes.SET_FORM_IS_VISIBLE, {
        formIsVisible: null,
    },
);

const setPhoneNumber = makeActionCreator(
    CallbackRequestTypes.SET_PHONE_NUMBER, {
        phoneNumber: null,
    },
);

export const CallbackRequestActions = {
    sendCallbackRequest,
    sendCallbackRequestFailed,
    sendCallbackRequestSuccess,
    setAvailabilityType,
    setEMailAddress,
    setFormIsVisible,
    setPhoneNumber,
};