//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default class Api {
    static getBackendUrl () {
        const url = window.location.href;

        if (url.indexOf('.test') > -1 || url.indexOf('localhost') > -1) {
            return LOCAL_BACKEND_URL;
        } else if (url.indexOf('staging.') > -1) {
            return STAGING_BACKEND_URL;
        }

        return PRODUCTION_BACKEND_URL;
    };

    static getWebsiteUrl () {
        const url = window.location.href;

        if (url.indexOf('.test') > -1 || url.indexOf('localhost') > -1) {
            return LOCAL_WEBSITE_URL;
        } else if (url.indexOf('staging.') > -1) {
            return STAGING_WEBSITE_URL;
        }

        return PRODUCTION_WEBSITE_URL;
    };

    static getAbsoluteWebsiteUrl (path) {
        const url = this.getWebsiteUrl();

        return {
            pathname: url + path,
        };
    };

    /**
     * @returns {boolean}
     */
    static isStaging () {
        return (
            BACKEND_URL === STAGING_BACKEND_URL
        );
    }
}

export const PRODUCTION_BACKEND_URL         = 'https://api.framebutler.de';
export const STAGING_BACKEND_URL            = 'https://api.staging.framebutler.de';
export const LOCAL_BACKEND_URL              = 'https://framebutler-backend.test';
export const PRODUCTION_WEBSITE_URL         = 'https://framebutler.de';
export const STAGING_WEBSITE_URL            = 'https://staging.framebutler.de';
export const LOCAL_WEBSITE_URL              = 'https://website.framebutler.test';
export const BACKEND_URL                    = Api.getBackendUrl();
export const API_BRANCHES_URL               = 'api/branches';
export const API_CALLBACK_REQUEST_URL       = 'api/callback-requests';
export const API_COMPANY_BANNER_URL         = 'api/company-banner-images';
export const API_COMPANY_IMAGE_URL          = 'api/company-images';
export const API_COMPANY_LOGO_URL           = 'api/company-logos';
export const API_COMPANY_POSITIONS_URL      = 'api/company-positions';
export const API_COMPANY_URL                = 'api/companies';
export const API_COMPETENCES_URL            = 'api/core-competences';
export const API_CONFIGURATION_URL          = 'api/configuration';
export const API_GOAL_URL                   = 'api/goals';
export const API_LANGUAGES_URL              = 'api/languages';
export const API_LICENSE_DURATIONS_URL      = 'api/license-durations';
export const API_LOCATION_URL               = 'api/company-locations';
export const API_MATCH_ATTACHMENTS_URL      = 'api/match-attachments';
export const API_MATCHES_URL                = 'api/matches';
export const API_MESSAGE_ATTACHMENTS_URL    = 'api/message-attachments';
export const API_MESSAGES_URL               = 'api/messages';
export const API_OFFER_ATTACHMENTS_URL      = 'api/offer-attachments';
export const API_OFFERS_URL                 = 'api/offers';
export const API_PLACE_OF_WORK_URL          = 'api/place-of-works';
export const API_PROJECTS_URL               = 'api/projects';
export const API_REFERENCES_URL             = 'api/references';
export const API_SOCIAL_MEDIA_URL           = 'api/social-media';
export const API_TERRITORIES_URL            = 'api/territories';
export const API_TOKENS                     = 'api/tokens';
export const API_USERS_RESET_PASSWORD_URL   = 'api/users/reset-password';
export const API_USERS_VERIFY_REGISTRATION  = 'api/users/verify-registration';
export const API_USERS_SET_NEW_PASSWORD_URL = 'api/users/set-new-password';
export const API_USERS_URL                  = 'api/users';
export const API_VIDEO_FORMATS_URL          = 'api/video-formats';
export const API_VIDEO_TYPES_URL            = 'api/video-types';

if (BACKEND_URL.endsWith('/')) {
    console.error('⚠️⚠️⚠️ Warning: Backend url should not end with a /');
}

if (BACKEND_URL !== PRODUCTION_BACKEND_URL) {
    console.warn('⚠️⚠️⚠️ Warning: App is not in production mode! ⚠️⚠️⚠️');
}

if (BACKEND_URL.indexOf('https://') === -1) {
    console.error('⚠️⚠️⚠️ Warning: Backend url is not secure ⚠️⚠️⚠️');
}
