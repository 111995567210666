//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as Api                 from '../../api';
import ApiMode                  from '../../constants/ApiMode';
import FileStates               from '../../constants/FileStates';
import update                   from 'immutability-helper';
import { CreateEditOfferTypes } from '../actions/createEditOffer';
import { OverlayTypes }         from '../actions/overlay';
import { UserTypes }            from '../actions/user';

const getNewOfferStateInstance = (defaultValues) => {
    const defaultObject = {
        files:          {},
        message:        '',
        netPriceInEuro: 0.0,
        title:          '',
    };

    if (defaultValues) {
        return Object.assign(defaultObject, defaultValues);
    }

    return defaultObject;
};

const initialState = {
    matchFiles: {},
    mode:       ApiMode.create,
    offers:     [
        getNewOfferStateInstance(),
    ],
};

const clearUnfinishedUploads = (action, state) => {
    let fileToDeleteAvailable    = false;
    const stateUpdateDescription = {
        offers: {},
    };

    for (const offerIndex in state.offers) {
        const offer            = state.offers[offerIndex];
        const fileKeysToDelete = [];

        for (const fileIndex in offer.files) {
            const currentFile = offer.files[fileIndex];

            if (currentFile.state === FileStates.uploading) {
                fileKeysToDelete.push(fileIndex);
            }
        }

        if (fileKeysToDelete.length) {
            if (!fileToDeleteAvailable) {
                fileToDeleteAvailable = true;
            }

            stateUpdateDescription.offers[offerIndex].files = {
                $unset: fileKeysToDelete,
            };
        }
    }

    if (fileToDeleteAvailable) {
        return update(state, stateUpdateDescription);
    }

    return state;
};

const addOffer = (action, state) => {
    return update(state, {
        offers: {
            $push: [
                getNewOfferStateInstance(),
            ],
        },
    });
};

const createOfferSuccess = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

const deleteOfferFile = (action, state) => {
    return update(state, {
        offers: {
            [action.offerIndex]: {
                files: {
                    $unset: [action.id],
                },
            },
        },
    });
};

const deleteMatchFile = (action, state) => {
    return update(state, {
        matchFiles: {
            $unset: [action.id],
        },
    });
};

const editOffer = (action, state) => {
    const mappedOffers = [];

    for (const offer of action.offers) {
        mappedOffers.push({
            ...offer,
            files: Api.getFileUploadObjectListFromApiFileObjectList(offer.offerAttachments),
        });
    }

    return update(state, {
        matchFiles: {
            $set: Api.getFileUploadObjectListFromApiFileObjectList(action.matchAttachments),
        },
        mode:       {
            $set: ApiMode.edit,
        },
        offers:     {
            $set: mappedOffers,
        },
    });
};

const logout = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

const overlayClosed = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

const removeOffer = (action, state) => {
    return update(state, {
        offers: {
            $splice: [
                [
                    action.offerIndex,
                    1,
                ],
            ],
        },
    });
};

const setMessage = (action, state) => {
    return update(state, {
        offers: {
            [action.offerIndex]: {
                message: {
                    $set: action.message,
                },
            },
        },
    });
};

const setNetPriceInEuro = (action, state) => {
    return update(state, {
        offers: {
            [action.offerIndex]: {
                netPriceInEuro: {
                    $set: action.netPriceInEuro,
                },
            },
        },
    });
};

const setTitle = (action, state) => {
    return update(state, {
        offers: {
            [action.offerIndex]: {
                title: {
                    $set: action.title,
                },
            },
        },
    });
};

const uploadOfferFile = (action, state) => {
    return update(state, {
        offers: {
            [action.offerIndex]: {
                files: {
                    [action.id]: {
                        $set: {
                            id:       action.id,
                            name:     action.file.name,
                            size:     action.file.size,
                            state:    FileStates.uploaded,
                            progress: 0,
                        },
                    },
                },
            },
        },
    });
};

const updateOfferSuccess = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

const uploadOfferFileFailed = (action, state) => {
    return update(state, {
        offers: {
            [action.offerIndex]: {
                files: {
                    [action.id]: {
                        state: {
                            $set: FileStates.error,
                        },
                    },
                },
            },
        },
    });
};

const uploadOfferFileSucceeded = (action, state) => {
    return update(state, {
        offers: {
            [action.offerIndex]: {
                files: {
                    [action.id]: {
                        iri:   {
                            $set: action.iri,
                        },
                        state: {
                            $set: FileStates.uploaded,
                        },
                    },
                },
            },
        },
    });
};

const updateOfferUploadProgress = (action, state) => {
    return update(state, {
        offers: {
            [action.offerIndex]: {
                files: {
                    [action.id]: {
                        progress: {
                            $set: action.progress,
                        },
                        state:    {
                            $set: FileStates.uploading,
                        },
                    },
                },
            },
        },
    });
};

const uploadMatchFile = (action, state) => {
    return update(state, {
        matchFiles: {
            [action.id]: {
                $set: {
                    id:       action.id,
                    name:     action.file.name,
                    size:     action.file.size,
                    state:    FileStates.uploaded,
                    progress: 0,
                },
            },
        },
    });
};

const uploadMatchFileFailed = (action, state) => {
    return update(state, {
        matchFiles: {
            [action.id]: {
                state: {
                    $set: FileStates.error,
                },
            },
        },
    });
};

const uploadMatchFileSucceeded = (action, state) => {
    return update(state, {
        matchFiles: {
            [action.id]: {
                iri:   {
                    $set: action.iri,
                },
                state: {
                    $set: FileStates.uploaded,
                },
            },
        },
    });
};

const updateMatchUploadProgress = (action, state) => {
    return update(state, {
        matchFiles: {
            [action.id]: {
                progress: {
                    $set: action.progress,
                },
                state:    {
                    $set: FileStates.uploading,
                },
            },
        },
    });
};

export default function (state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case CreateEditOfferTypes.ADD_OFFER:                    return addOffer(action, state);
        case CreateEditOfferTypes.CLEAR_UNFINISHED_UPLOADS:     return clearUnfinishedUploads(action, state);
        case CreateEditOfferTypes.CREATE_OFFER_SUCCESS:         return createOfferSuccess(action, state);
        case CreateEditOfferTypes.DELETE_OFFER_FILE:            return deleteOfferFile(action, state);
        case CreateEditOfferTypes.DELETE_MATCH_FILE:            return deleteMatchFile(action, state);
        case CreateEditOfferTypes.EDIT_OFFER:                   return editOffer(action, state);
        case CreateEditOfferTypes.REMOVE_OFFER:                 return removeOffer(action, state);
        case CreateEditOfferTypes.SET_OFFER_MESSAGE:            return setMessage(action, state);
        case CreateEditOfferTypes.SET_OFFER_NET_PRICE_IN_EURO:  return setNetPriceInEuro(action, state);
        case CreateEditOfferTypes.SET_OFFER_TITLE:              return setTitle(action, state);
        case CreateEditOfferTypes.UPDATE_OFFER_SUCCESS:         return updateOfferSuccess(action, state);
        case CreateEditOfferTypes.UPLOAD_OFFER_FILE:            return uploadOfferFile(action, state);
        case CreateEditOfferTypes.UPLOAD_OFFER_FILE_FAILED:     return uploadOfferFileFailed(action, state);
        case CreateEditOfferTypes.UPLOAD_OFFER_FILE_SUCCEEDED:  return uploadOfferFileSucceeded(action, state);
        case CreateEditOfferTypes.UPDATE_OFFER_UPLOAD_PROGRESS: return updateOfferUploadProgress(action, state);
        case CreateEditOfferTypes.UPLOAD_MATCH_FILE:            return uploadMatchFile(action, state);
        case CreateEditOfferTypes.UPLOAD_MATCH_FILE_FAILED:     return uploadMatchFileFailed(action, state);
        case CreateEditOfferTypes.UPLOAD_MATCH_FILE_SUCCEEDED:  return uploadMatchFileSucceeded(action, state);
        case CreateEditOfferTypes.UPDATE_MATCH_UPLOAD_PROGRESS: return updateMatchUploadProgress(action, state);
        case OverlayTypes.OVERLAY_CLOSED:                       return overlayClosed(action, state);
        case UserTypes.LOGOUT:                                  return logout(action, state);
        default:                                                return state;
        // @formatter:on
    }
}
