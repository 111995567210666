//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import Avatar          from '../../connected/Avatar';
import AvatarSize      from '../Avatar/AvatarSize';
import ComponentHelper from '../../../helper/ComponentHelper';
import I18n            from 'i18next';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';

class UserMenuAvatar extends React.Component {
    render () {
        return (
            <div className={styles.wrapper}>
                <div className={styles.avatarWrapper}>
                    <Avatar
                        company={this.props.company}
                        size={AvatarSize.small}
                    />
                </div>
                <div className={styles.textWrapper}>
                    <h3>
                        {I18n.t('hello')}
                        {' '}
                        {this.props.name}
                    </h3>
                </div>
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = UserMenuAvatar;

Component.propTypes = {
    company: PropTypes.object,
    name:    PropTypes.string,
};

Component.defaultProps = {
    company: null,
    name:    '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
