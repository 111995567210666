//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import styles           from './styles.module.scss';
import PropTypes        from '../../PropTypes';
import ComponentHelper  from '../../../helper/ComponentHelper';
import _                from 'lodash';
import IconType         from '../Icon/IconType';
import IconButtonTheme  from '../IconButton/IconButtonTheme';
import UploadFileButton from '../UploadFileButton';
import FileButton       from '../FileButton';
import InputError       from '../InputError';

class FileManager extends React.Component {
    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.topRow}>
                    <UploadFileButton
                        allowLineBreaks={true}
                        text={this.props.addFileButtonText}
                        theme={IconButtonTheme.gray}
                        uploadFileCallback={this.props.uploadFileCallback}
                    />
                    {this.renderTipText()}
                </div>
                <div className={styles.bottomRow}>
                    {this.renderFiles()}
                    {this.renderError()}
                </div>
            </div>
        );
    }

    renderError = () => {
        if (this.props.errorText) {
            return (
                <div className={styles.errorWrapper}>
                    <InputError
                        marginTop={
                            this.props.files && this.props.files.length > 0 ?
                                0 :
                                -18
                        }
                        text={this.props.errorText}
                    />
                </div>
            );
        }

        return null;
    };

    // TODO: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-302
    renderFile = (file, index) => {
        if (!file.delete) {
            return (
                <FileButton
                    fileName={file.name}
                    key={'file' + file.id}
                    onDeleteButtonClicked={() => {
                        this.props.deleteButtonPressed(index, file);
                    }}
                    progress={file.progress}
                    state={file.state}
                />
            );
        }

        return null;
    };

    renderFiles = () => {
        if (this.props.files && this.props.files.length > 0) {
            return (
                <div className={styles.fileListWrapper}>
                    {this.props.files.map(this.renderFile)}
                </div>
            );
        }

        return null;
    };

    renderTipText = () => {
        if (this.props.tip) {
            return (
                <p className={styles.tip}>
                    {this.props.tip}
                </p>
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = FileManager;

Component.propTypes = {
    addFileButtonIcon:   PropTypes.oneOf(Object.keys(IconType)),
    addFileButtonText:   PropTypes.string,
    deleteButtonPressed: PropTypes.func,
    errorText:           PropTypes.string,
    files:               PropTypes.arrayOf(PropTypes.fileListEntry),
    uploadFileCallback:  PropTypes.func,
};

Component.defaultProps = {
    addFileButtonIcon:   null,
    addFileButtonText:   '',
    deleteButtonPressed: _.noop,
    errorText:           '',
    files:               [],
    uploadFileCallback:  _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
