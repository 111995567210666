//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                      from 'lodash';
import React                  from 'react';
import StatelessResetPassword from '../../stateless/ResetPassword';
import { AlertBoxActions }    from '../../../store/actions/alertBox';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { connect }            from 'react-redux';
import { goBack }             from 'connected-react-router';
import { UserActions }        from '../../../store/actions/user';
import { withRouter }         from 'react-router-dom';
import I18n                   from 'i18next';

class Component extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            password:      null,
            passwordCheck: null,
            token:         new URLSearchParams(this.props.location.search).get('token'),
        };
    }

    render () {
        return (
            <StatelessResetPassword
                onPasswordChange={this.passwordChanged}
                onPasswordCheckChange={this.passwordCheckChanged}
                onSubmit={this.saveButtonPressed}
                resetToken={this.state.token}
                passwordError1={this.props.passwordError1}
                passwordError2={this.props.passwordError2}
            />
        );
    }

    passwordChanged = (event) => {
        this.setState({
            password: event.target.value,
        });
    };

    passwordCheckChanged = (event) => {
        this.setState({
            passwordCheck: event.target.value,
        });
    };

    saveButtonPressed = () => {
        this.props.clearAlerts();

        if (!this.state.password) {
            this.props.showErrorAlertTagged({
                tag:  'password1',
                text: I18n.t('profilePasswordError1'),
            });
        } else if (this.state.password.length < 8) {
            this.props.showErrorAlertTagged({
                tag:  'password1',
                text: I18n.t('profilePasswordError2'),
            });
        } else if (this.state.password !== this.state.passwordCheck) {
            this.props.showErrorAlertTagged({
                tag:  'password2',
                text: I18n.t('profilePasswordError3'),
            });
        } else {
            this.props.setNewPassword({
                password: this.state.password,
                token:    this.state.token,
            });
        }
    };
}

Component.propTypes = {};

Component.defaultProps = {};

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        AlertBoxActions,
        UserActions,
        {
            goBack,
        },
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        passwordError1: _.get(state, 'alertBox.alertBoxesTagged.password1', null),
        passwordError2: _.get(state, 'alertBox.alertBoxesTagged.password2', null),
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Component);