//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                      from 'lodash';
import AlertBoxManager        from '../../components/connected/AlertBoxManager';
import ContentWrapper         from '../../components/stateless/ContentWrapper';
import I18n                   from 'i18next';
import PageTitleHelper        from '../../helper/PageTitle';
import React                  from 'react';
import { Component }          from 'react';
import Spacer                 from '../../components/stateless/Spacer';
import UserProfileData        from '../../components/stateless/UserProfileData';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { connect }            from 'react-redux';
import { Helmet }             from 'react-helmet';
import { UserActions }        from '../../store/actions/user';
import { withRouter }         from 'react-router-dom';

class Screen extends Component {
    editDataButtonPressed = () => {
        this.props.editOwnUserData();
    };

    editPasswordButtonPressed = () => {
        this.props.editOwnUserPassword();
    };

    render() {
        return (
            <ContentWrapper>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('userProfile'))}
                    </title>
                </Helmet>
                <AlertBoxManager />
                <Spacer height={20} />
                {this.renderUserData()}
            </ContentWrapper>
        );
    }

    renderUserData = () => {
        const profileData = _.get(this.props, 'profileUser');

        return (
            <>
                <UserProfileData
                    data={profileData}
                    editButtonPressed={this.editDataButtonPressed}
                    showEditButton={true}
                    group={'default'}
                />
                <UserProfileData
                    data={profileData}
                    editButtonPressed={this.editPasswordButtonPressed}
                    showEditButton={true}
                    title={I18n.t('editPassword')}
                    group={'password'}
                />
            </>
        );
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    UserActions,
), dispatch);

const mapStateToProps = state => (
    {
        profileUser: _.get(state, 'user.profile'),
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Screen);
