//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { MainSplitPaneTypes } from '../actions/mainSplitPane';
import update                 from 'immutability-helper';

const initialLeftPaneSize = 450;
const initialState        = {
    leftPaneVisible: true,
    leftPaneSize:    initialLeftPaneSize,
};

// https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-215

export default function (state = initialState, action) {
    switch (action.type) {
        case MainSplitPaneTypes.LEFT_PANE_SET_VISIBILITY:
            return update(state, {
                leftPaneVisible: {
                    $set: action.visible,
                },
            });

        case MainSplitPaneTypes.LEFT_PANE_SIZE_CHANGED:
            return update(state, {
                leftPaneSize: {
                    $set: action.size,
                },
            });

        case MainSplitPaneTypes.LEFT_PANE_TOGGLE_VISIBILITY:
            return update(state, {
                $toggle: [
                    'leftPaneVisible',
                ],
            });

        default:
            return state;
    }
}
