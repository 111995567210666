//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                         from 'react';
import _                             from 'lodash';
import ChoiceSelector                from '../../stateless/ChoiceSelector';
import ChoiceSelectorMode            from '../../stateless/ChoiceSelector/ChoiceSelectorMode';
import ChoiceWidthMode               from '../../stateless/ChoiceSelector/ChoiceWidthMode';
import ComponentHelper               from '../../../helper/ComponentHelper';
import ErrorAwareOverlayPane         from '../../connected/ErrorAwareOverlayPane';
import FormRow                       from '../../stateless/FormRow';
import GA                            from '../../../helper/GA';
import I18n                          from 'i18next';
import Input                         from '../../stateless/Input';
import InputType                     from '../../stateless/Input/InputType';
import LicenseDurationChoiceSelector from '../LicenseDurationChoiceSelector';
import OverlayButton                 from '../../stateless/OverlayButton';
import OverlayButtonTheme            from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper          from '../../stateless/OverlayButtonWrapper';
import OverlayPaneState              from '../../stateless/OverlayPane/OverlayPaneState';
import PropTypes                     from '../../PropTypes';
import TerritoryChoiceSelector       from '../TerritoryChoiceSelector';
import VideoFormatsChoiceSelector    from '../VideoFormatsChoiceSelector';
import { AlertBoxActions }           from '../../../store/actions/alertBox';
import { bindActionCreators }        from 'redux';
import { connect }                   from 'react-redux';
import { CreateEditProjectActions }  from '../../../store/actions/createEditProject';

class CreateProjectOverlayReachPane extends React.Component {
    eventLabel = 'Reach';

    backButtonPressed = () => {
        GA.sendCreateProjectPreviousEvent(this.eventLabel);
        this.props.clearAlerts();
        this.props.previousPane();
    };

    continueButtonPressed = () => {
        this.props.clearAlerts();

        if (this.props.videoWillBePublished === null) {
            this.props.showErrorAlertTagged({
                tag:  'videoWillBePublished',
                text: I18n.t('willVideoPublishedErrorEmpty'),
            });
        }

        if (!this.props.videoFormats.length) {
            this.props.showErrorAlertTagged({
                tag:  'videoFormats',
                text: I18n.t('videoFormatErrorEmpty'),
            });
        }

        if (
            this.props.licenseDurationIsOther &&
            !this.props.licenseDurationText
        ) {
            this.props.showErrorAlertTagged({
                tag:  'licenseDurationText',
                text: I18n.t('licenseDurationErrorEmpty'),
            });
        }

        if (
            this.props.territoryIsOther &&
            !this.props.territoryText
        ) {
            this.props.showErrorAlertTagged({
                tag:  'territoryText',
                text: I18n.t('videoTerritoryEmptyText'),
            });
        }

        requestAnimationFrame(() => {
            if (this.props.alertBoxesTaggedCount === 0) {
                GA.sendCreateProjectNextEvent(this.eventLabel);
                this.props.nextPane();
            }
        });
    };

    licenseDurationClicked = (choiceData) => {
        this.props.setLicenseDuration({
            licenseDurationIri: choiceData.value,
        });
    };

    licenseDurationOtherChanged = (event) => {
        this.props.setLicenseDurationText({
            licenseDurationText: event.target.value,
        });
    };

    licenseDurationLaterClicked = () => {
        this.props.setLicenseDuration({
            licenseDurationIri: null,
        });
    };

    render() {
        return (
            <ErrorAwareOverlayPane
                content={this.renderContent()}
                footer={this.renderFooter()}
                title={I18n.t('reach')}
                showErrors={true}
                state={this.props.overlayPaneState}
                upcomingDistance={this.props.upcomingDistance}
            />
        );
    }

    renderContent = () => {
        return (
            <>
                {this.renderContentVideoWillBePublished()}
                {this.renderContentVideoFormats()}
                {this.renderContentTerritory()}
                {this.renderContentLicenseDuration()}
            </>
        );
    };

    renderContentLicenseDuration() {
        const otherLicenseDurationSelected = this.props.licenseDurationIsOther;

        return (
            <FormRow
                label={I18n.t('licenseDurationLabel')}
                tooltipText={I18n.t('licenseDurationTooltipText')}
            >
                <LicenseDurationChoiceSelector
                    addUndefinedValue={false}
                    choiceClicked={this.licenseDurationClicked}
                    selectedValues={[
                        this.props.licenseDuration,
                    ]}
                />
                {
                    otherLicenseDurationSelected ?
                        (
                            <Input
                                errorText={this.props.licenseDurationTextError}
                                onChange={this.licenseDurationOtherChanged}
                                placeholder={I18n.t('defaultPlaceHolderTextInput')}
                                type={InputType.url}
                                value={this.props.licenseDurationText}
                            />
                        ) :
                        null
                }
                <ChoiceSelector
                    choiceClicked={this.licenseDurationLaterClicked}
                    choices={[
                        {
                            selected:  (
                                           !otherLicenseDurationSelected &&
                                           !this.props.licenseDuration
                                       ),
                            text:      I18n.t('fillLaterChoiceText'),
                            widthMode: ChoiceWidthMode.full,
                        },
                    ]}
                    mode={ChoiceSelectorMode.singleChoice}
                />
            </FormRow>
        );
    }

    renderContentTerritory = () => {
        const otherTerritorySelected = this.props.territoryIsOther;

        return (
            <FormRow
                label={I18n.t('videoTerritoryLabel')}
                tooltipText={I18n.t('videoTerritoryTooltipText')}
            >
                <TerritoryChoiceSelector
                    addUndefinedValue={false}
                    choiceClicked={this.territoryClicked}
                    selectedValues={[
                        this.props.territory,
                    ]}
                />
                {
                    otherTerritorySelected ?
                        (
                            <Input
                                errorText={this.props.territoryTextError}
                                onChange={this.territoryOtherChanged}
                                placeholder={I18n.t('defaultPlaceHolderTextInput')}
                                type={InputType.url}
                                value={this.props.territoryText}
                            />
                        ) :
                        null
                }
                <ChoiceSelector
                    choiceClicked={this.territoryLaterClicked}
                    choices={[
                        {
                            selected:  (
                                           !otherTerritorySelected &&
                                           !this.props.territory
                                       ),
                            text:      I18n.t('fillLaterChoiceText'),
                            widthMode: ChoiceWidthMode.full,
                        },
                    ]}
                    mode={ChoiceSelectorMode.singleChoice}
                />
            </FormRow>
        );
    };

    renderContentVideoFormats = () => {
        return (
            <FormRow
                label={I18n.t('videoFormatLabel')}
                tooltipText={I18n.t('videoFormatTooltipText')}
            >
                <VideoFormatsChoiceSelector
                    choiceClicked={this.videoFormatClicked}
                    errorText={this.props.videoFormatsError}
                    selectedValues={this.props.videoFormats}
                    paddingRight={false}
                />
            </FormRow>
        );
    };

    renderContentVideoWillBePublished = () => {
        return (
            <FormRow
                label={I18n.t('willVideoPublishedLabel')}
                tooltipText={I18n.t('willVideoPublishedTooltipText')}
                tooltipTitle={I18n.t('willVideoPublishedTooltipTitle')}
            >
                <ChoiceSelector
                    choiceClicked={this.videoWillBePublishedChanged}
                    choices={[
                        {
                            selected:  this.props.videoWillBePublished === true,
                            text:      I18n.t('yes'),
                            value:     true,
                            widthMode: ChoiceWidthMode.half,
                        },
                        {
                            selected:  this.props.videoWillBePublished === false,
                            text:      I18n.t('no'),
                            value:     false,
                            widthMode: ChoiceWidthMode.half,
                        },
                    ]}
                    errorText={this.props.videoWillBePublishedError}
                    mode={ChoiceSelectorMode.multipleChoice}
                />
            </FormRow>
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.backButtonPressed}
                    standAlone={false}
                    text={I18n.t('back')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.continueButtonPressed}
                    standAlone={false}
                    text={I18n.t('continue')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    territoryClicked = (choiceData) => {
        this.props.setTerritory({
            territoryIri: choiceData.value,
        });
    };

    territoryOtherChanged = (event) => {
        this.props.setTerritoryText({
            territoryText: event.target.value,
        });
    };

    territoryLaterClicked = () => {
        this.props.setTerritory({
            territoryIri: null,
        });
    };

    videoFormatClicked = (choiceData) => {
        this.props.toggleVideoFormat({
            videoFormatIri: choiceData.value,
        });
    };

    videoWillBePublishedChanged = (choiceData) => {
        this.props.setVideoWillBePublished({
            videoWillBePublished: choiceData.value,
        });
    };
}

const Component = CreateProjectOverlayReachPane;

Component.propTypes = {
    alertBoxesTaggedCount:     PropTypes.number,
    licenseDuration:           PropTypes.object,
    licenseDurationIsOther:    PropTypes.bool,
    licenseDurationText:       PropTypes.string,
    licenseDurationTextError:  PropTypes.string,
    overlayPaneState:          PropTypes.oneOf(Object.values(OverlayPaneState)),
    territory:                 PropTypes.object,
    territoryIsOther:          PropTypes.bool,
    territoryText:             PropTypes.string,
    territoryTextError:        PropTypes.string,
    upcomingDistance:          PropTypes.number,
    videoFormats:              PropTypes.array,
    videoFormatsError:         PropTypes.string,
    videoWillBePublished:      PropTypes.bool,
    videoWillBePublishedError: PropTypes.string,
};

Component.defaultProps = {
    alertBoxesTaggedCount:     0,
    licenseDuration:           null,
    licenseDurationIsOther:    false,
    licenseDurationText:       '',
    licenseDurationTextError:  '',
    overlayPaneState:          OverlayPaneState.upcoming,
    territory:                 null,
    territoryIsOther:          false,
    territoryText:             '',
    territoryTextError:        '',
    upcomingDistance:          0,
    videoFormats:              [],
    videoFormatsError:         '',
    videoWillBePublished:      null,
    videoWillBePublishedError: '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    AlertBoxActions,
    CreateEditProjectActions,
), dispatch);

const mapStateToProps = state => (
    {
        alertBoxesTaggedCount:     _.get(state, 'alertBox.alertBoxesTaggedCount', 0),
        licenseDuration:           _.get(state, 'createEditProject.licenseDuration'),
        licenseDurationIsOther:    _.get(state, 'createEditProject.licenseDurationIsOther'),
        licenseDurationText:       _.get(state, 'createEditProject.licenseDurationText'),
        licenseDurationTextError:  _.get(state, 'alertBox.alertBoxesTagged.licenseDurationText'),
        territory:                 _.get(state, 'createEditProject.territory'),
        territoryIsOther:          _.get(state, 'createEditProject.territoryIsOther'),
        territoryText:             _.get(state, 'createEditProject.territoryText'),
        territoryTextError:        _.get(state, 'alertBox.alertBoxesTagged.territoryText'),
        videoFormats:              _.get(state, 'createEditProject.videoFormats'),
        videoFormatsError:         _.get(state, 'alertBox.alertBoxesTagged.videoFormats'),
        videoWillBePublished:      _.get(state, 'createEditProject.videoWillBePublished'),
        videoWillBePublishedError: _.get(state, 'alertBox.alertBoxesTagged.videoWillBePublished'),
        licenseDurations:          _.get(state, 'licenseDuration.licenseDurations'),
        territories:               _.get(state, 'territory.territories'),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
