//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper from '../../../helper/ComponentHelper';
import I18n            from 'i18next';
import IconLabel       from '../IconLabel';
import IconLabelTheme  from '../IconLabel/IconLabelTheme';
import IconType        from '../Icon/IconType';
import Normalize       from '../../../helper/Normalize';
import ProjectState    from '../../stateless/ProjectState';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import ShortFactList   from '../ShortFactList';
import styles          from './styles.module.scss';

class ProjectDetailsContentItemContent extends React.Component {
    render () {
        const formats = Normalize.formatsToStringList(this.props.formats, true);

        return (
            <div className={styles.wrapper}>
                <div className={styles.left}>
                    <h4>
                        {I18n.t('projectDetails')}
                    </h4>
                    <ShortFactList itemsPerRow={2}>
                        <IconLabel
                            iconType={IconType.camera}
                            text={this.props.type}
                            theme={IconLabelTheme.bigWhite}
                        />
                        <IconLabel
                            iconType={IconType.users}
                            text={
                                Normalize.toAgeRange(
                                    this.props.ageRangeFrom,
                                    this.props.ageRangeTo,
                                )
                            }
                            theme={IconLabelTheme.bigWhite}
                        />
                        <IconLabel
                            iconType={IconType.euroSign}
                            text={Normalize.toBudgetRange(
                                this.props.budgetRangeInThousandsFrom,
                                this.props.budgetRangeInThousandsTo,
                            )}
                            theme={IconLabelTheme.bigWhite}
                        />
                        <IconLabel
                            iconType={IconType.movie}
                            text={formats.join(', ')}
                            theme={IconLabelTheme.bigWhite}
                        />
                    </ShortFactList>
                </div>
                {this.renderStates()}
            </div>
        );
    }

    renderStates = () => {
        return (
            <div className={styles.right}>
                <h4>
                    {I18n.t('status')}
                </h4>
                <ProjectState
                    projectState={this.props.projectState}
                    matchState={this.props.matchState}
                    companyType={this.props.companyType}
                />
            </div>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ProjectDetailsContentItemContent;

Component.propTypes = {
    ageRangeFrom:               PropTypes.number,
    ageRangeTo:                 PropTypes.number,
    budgetRangeInThousandsFrom: PropTypes.number,
    budgetRangeInThousandsTo:   PropTypes.number,
    companyType:                PropTypes.string,
    formats:                    PropTypes.array,
    matchState:                 PropTypes.string,
    projectState:               PropTypes.string,
    type:                       PropTypes.string,
};

Component.defaultProps = {
    ageRangeFrom:               0,
    ageRangeTo:                 0,
    budgetRangeInThousandsFrom: 0,
    budgetRangeInThousandsTo:   0,
    companyType:                null,
    formats:                    [],
    matchState:                 null,
    projectState:               null,
    type:                       '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;