//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const CompanyTypes = {
    CREATE_COMPANY:                                     'Company/CREATE_COMPANY',
    CREATE_COMPANY_AND_USER:                            'Company/CREATE_COMPANY_AND_USER',
    CREATE_COMPANY_AND_USER_FAILED:                     'Company/CREATE_COMPANY_AND_USER_FAILED',
    CREATE_COMPANY_AND_USER_SUCCESS:                    'Company/CREATE_COMPANY_AND_USER_SUCCESS',
    CREATE_COMPANY_AND_USER_AND_PROJECT:                'Company/CREATE_COMPANY_AND_USER_AND_PROJECT',
    CREATE_COMPANY_AND_USER_AND_PROJECT_FAILED:         'Company/CREATE_COMPANY_AND_USER_AND_PROJECT_FAILED',
    CREATE_COMPANY_AND_USER_AND_PROJECT_SUCCESS:        'Company/CREATE_COMPANY_AND_USER_AND_PROJECT_SUCCESS',
    CREATE_COMPANY_FAILED:                              'Company/CREATE_COMPANY_FAILED',
    CREATE_COMPANY_SUCCESS:                             'Company/CREATE_COMPANY_SUCCESS',
    CREATE_OR_DELETE_COMPANY_IMAGES:                    'Company/CREATE_OR_DELETE_COMPANY_IMAGES',
    CREATE_OR_DELETE_COMPANY_IMAGES_FAILED:             'Company/CREATE_OR_DELETE_COMPANY_IMAGES_FAILED',
    CREATE_OR_DELETE_COMPANY_IMAGES_SUCCESS:            'Company/CREATE_OR_DELETE_COMPANY_IMAGES_SUCCESS',
    CREATE_OR_UPDATE_OWN_COMPANY_LOCATIONS:             'Company/CREATE_OR_UPDATE_OWN_COMPANY_LOCATIONS',
    CREATE_OR_UPDATE_OWN_COMPANY_LOCATIONS_FAILED:      'Company/CREATE_OR_UPDATE_OWN_COMPANY_LOCATIONS_FAILED',
    CREATE_OR_UPDATE_OWN_COMPANY_LOCATIONS_SUCCESS:     'Company/CREATE_OR_UPDATE_OWN_COMPANY_LOCATIONS_SUCCESS',
    CREATE_OR_UPDATE_OWN_COMPANY_REFERENCES:            'Company/CREATE_OR_UPDATE_OWN_COMPANY_REFERENCES',
    CREATE_OR_UPDATE_OWN_COMPANY_REFERENCES_FAILED:     'Company/CREATE_OR_UPDATE_OWN_COMPANY_REFERENCES_FAILED',
    CREATE_OR_UPDATE_OWN_COMPANY_REFERENCES_SUCCESS:    'Company/CREATE_OR_UPDATE_OWN_COMPANY_REFERENCES_SUCCESS',
    CREATE_OR_UPDATE_OWN_COMPANY_SOCIAL_MEDIAS:         'Company/CREATE_OR_UPDATE_OWN_COMPANY_SOCIAL_MEDIAS',
    CREATE_OR_UPDATE_OWN_COMPANY_SOCIAL_MEDIAS_FAILED:  'Company/CREATE_OR_UPDATE_OWN_COMPANY_SOCIAL_MEDIAS_FAILED',
    CREATE_OR_UPDATE_OWN_COMPANY_SOCIAL_MEDIAS_SUCCESS: 'Company/CREATE_OR_UPDATE_OWN_COMPANY_SOCIAL_MEDIAS_SUCCESS',
    CREATE_OWN_COMPANY_REFERENCE:                       'Company/CREATE_OWN_COMPANY_REFERENCE',
    CREATE_OWN_COMPANY_REFERENCE_FAILED:                'Company/CREATE_OWN_COMPANY_REFERENCE_FAILED',
    CREATE_OWN_COMPANY_REFERENCE_SUCCESS:               'Company/CREATE_OWN_COMPANY_REFERENCE_SUCCESS',
    EDIT_OWN_COMPANY_COMPETENCES:                       'Company/EDIT_OWN_COMPANY_COMPETENCES',
    EDIT_OWN_COMPANY_HEADER:                            'Company/EDIT_OWN_COMPANY_HEADER',
    EDIT_OWN_COMPANY_IMAGES:                            'Company/EDIT_OWN_COMPANY_IMAGES',
    EDIT_OWN_COMPANY_INFORMATION:                       'Company/EDIT_OWN_COMPANY_INFORMATION',
    EDIT_OWN_COMPANY_LANGUAGES:                         'Company/EDIT_OWN_COMPANY_LANGUAGES',
    EDIT_OWN_COMPANY_LOCATIONS:                         'Company/EDIT_OWN_COMPANY_LOCATIONS',
    EDIT_OWN_COMPANY_PLACE_OF_WORKS:                    'Company/EDIT_OWN_COMPANY_PLACE_OF_WORKS',
    EDIT_OWN_COMPANY_REFERENCES:                        'Company/EDIT_OWN_COMPANY_REFERENCES',
    EDIT_OWN_COMPANY_SOCIAL_MEDIAS:                     'Company/EDIT_OWN_COMPANY_SOCIAL_MEDIAS',
    FETCH_COMPANY:                                      'Company/FETCH_COMPANY',
    FETCH_COMPANY_FAILED:                               'Company/FETCH_COMPANY_FAILED',
    FETCH_COMPANY_SUCCESS:                              'Company/FETCH_COMPANY_SUCCESS',
    FETCH_OWN_COMPANY:                                  'Company/FETCH_OWN_COMPANY',
    FETCH_OWN_COMPANY_FAILED:                           'Company/FETCH_OWN_COMPANY_FAILED',
    FETCH_OWN_COMPANY_SUCCESS:                          'Company/FETCH_OWN_COMPANY_SUCCESS',
    SET_OWN_COMPANY_ADDRESS_CITY:                       'Company/SET_OWN_COMPANY_ADDRESS_CITY',
    SET_OWN_COMPANY_ADDRESS_HOUSE_NUMBER:               'Company/SET_OWN_COMPANY_ADDRESS_HOUSE_NUMBER',
    SET_OWN_COMPANY_ADDRESS_POSTAL_CODE:                'Company/SET_OWN_COMPANY_ADDRESS_POSTAL_CODE',
    SET_OWN_COMPANY_ADDRESS_STREET:                     'Company/SET_OWN_COMPANY_ADDRESS_STREET',
    SET_OWN_COMPANY_NAME:                               'Company/SET_OWN_COMPANY_NAME',
    SET_OWN_COMPANY_ROLE_IN_COMPANY:                    'Company/SET_OWN_COMPANY_ROLE_IN_COMPANY',
    SET_OWN_COMPANY_WEBSITE:                            'Company/SET_OWN_COMPANY_WEBSITE',
    TEMPORARY_SET_OWN_COMPANY_ADMIN_USER:               'Company/TEMPORARY_SET_OWN_COMPANY_ADMIN_USER',
    TEMPORARY_SET_OWN_COMPANY_ADMIN_USER_FAILED:        'Company/TEMPORARY_SET_OWN_COMPANY_ADMIN_USER_FAILED',
    TEMPORARY_SET_OWN_COMPANY_ADMIN_USER_SUCCESS:       'Company/TEMPORARY_SET_OWN_COMPANY_ADMIN_USER_SUCCESS',
    TEMPORARY_ADD_OWN_COMPANY_COMPETENCE:               'Company/TEMPORARY_ADD_OWN_COMPANY_COMPETENCE',
    TEMPORARY_ADD_OWN_COMPANY_LANGUAGE:                 'Company/TEMPORARY_ADD_OWN_COMPANY_LANGUAGE',
    TEMPORARY_ADD_OWN_COMPANY_LOCATION:                 'Company/TEMPORARY_ADD_OWN_COMPANY_LOCATION',
    TEMPORARY_ADD_OWN_COMPANY_REFERENCE:                'Company/TEMPORARY_ADD_OWN_COMPANY_REFERENCE',
    TEMPORARY_ADD_OWN_COMPANY_SOCIAL_MEDIA:             'Company/TEMPORARY_ADD_OWN_COMPANY_SOCIAL_MEDIA',
    TEMPORARY_REMOVE_OWN_COMPANY_BANNER:                'Company/TEMPORARY_REMOVE_OWN_COMPANY_BANNER',
    TEMPORARY_REMOVE_OWN_COMPANY_COMPETENCE:            'Company/TEMPORARY_REMOVE_OWN_COMPANY_COMPETENCE',
    TEMPORARY_REMOVE_OWN_COMPANY_IMAGE:                 'Company/TEMPORARY_REMOVE_OWN_COMPANY_IMAGE',
    TEMPORARY_REMOVE_OWN_COMPANY_LANGUAGE:              'Company/TEMPORARY_REMOVE_OWN_COMPANY_LANGUAGE',
    TEMPORARY_REMOVE_OWN_COMPANY_LOCATION:              'Company/TEMPORARY_REMOVE_OWN_COMPANY_LOCATION',
    TEMPORARY_REMOVE_OWN_COMPANY_LOGO:                  'Company/TEMPORARY_REMOVE_OWN_COMPANY_LOGO',
    TEMPORARY_REMOVE_OWN_COMPANY_REFERENCE:             'Company/TEMPORARY_REMOVE_OWN_COMPANY_REFERENCE',
    TEMPORARY_REMOVE_OWN_COMPANY_SOCIAL_MEDIA:          'Company/TEMPORARY_REMOVE_OWN_COMPANY_SOCIAL_MEDIA',
    TEMPORARY_SET_OWN_COMPANY_DESCRIPTION:              'Company/TEMPORARY_SET_OWN_COMPANY_DESCRIPTION',
    TEMPORARY_SET_OWN_COMPANY_LOCATION:                 'Company/TEMPORARY_SET_OWN_COMPANY_LOCATION',
    TEMPORARY_SET_OWN_COMPANY_LOGO:                     'Company/TEMPORARY_SET_OWN_COMPANY_LOGO',
    TEMPORARY_SET_OWN_COMPANY_NAME:                     'Company/TEMPORARY_SET_OWN_COMPANY_NAME',
    TEMPORARY_SET_OWN_COMPANY_PHONE_NUMBER:             'Company/TEMPORARY_SET_OWN_COMPANY_PHONE_NUMBER',
    TEMPORARY_SET_OWN_COMPANY_PLACE_OF_WORK:            'Company/TEMPORARY_SET_OWN_COMPANY_PLACE_OF_WORK',
    TEMPORARY_SET_OWN_COMPANY_REFERENCE_VIDEO_URL:      'Company/TEMPORARY_SET_OWN_COMPANY_REFERENCE_VIDEO_URL',
    TEMPORARY_SET_OWN_COMPANY_ROLE_IN_COMPANY:          'Company/TEMPORARY_SET_OWN_COMPANY_ROLE_IN_COMPANY',
    TEMPORARY_SET_OWN_COMPANY_SOCIAL_MEDIA:             'Company/TEMPORARY_SET_OWN_COMPANY_SOCIAL_MEDIA',
    TEMPORARY_SET_OWN_COMPANY_TYPE:                     'Company/TEMPORARY_SET_OWN_COMPANY_TYPE',
    TEMPORARY_SET_OWN_COMPANY_WEBSITE:                  'Company/TEMPORARY_SET_OWN_COMPANY_WEBSITE',
    UPDATE_COMPANY:                                     'Company/UPDATE_COMPANY',
    UPDATE_COMPANY_BANNER:                              'Company/UPDATE_COMPANY_BANNER',
    UPDATE_COMPANY_FAILED:                              'Company/UPDATE_COMPANY_FAILED',
    UPDATE_COMPANY_IMAGE:                               'Company/UPDATE_COMPANY_IMAGE',
    UPDATE_COMPANY_LOGO:                                'Company/UPDATE_COMPANY_LOGO',
    UPDATE_COMPANY_SUCCESS:                             'Company/UPDATE_COMPANY_SUCCESS',
    UPDATE_COMPANY_UPLOAD_PROGRESS:                     'Company/UPDATE_COMPANY_UPLOAD_PROGRESS',
    UPDATE_OWN_COMPANY:                                 'Company/UPDATE_OWN_COMPANY',
    UPDATE_OWN_COMPANY_FAILED:                          'Company/UPDATE_OWN_COMPANY_FAILED',
    UPDATE_OWN_COMPANY_SUCCESS:                         'Company/UPDATE_OWN_COMPANY_SUCCESS',
    UPLOAD_COMPANY_BANNER_FAILED:                       'Company/UPLOAD_COMPANY_BANNER_FAILED',
    UPLOAD_COMPANY_BANNER_SUCCEEDED:                    'Company/UPLOAD_COMPANY_BANNER_SUCCEEDED',
    UPLOAD_COMPANY_IMAGE_FAILED:                        'Company/UPLOAD_COMPANY_IMAGE_FAILED',
    UPLOAD_COMPANY_IMAGE_SUCCEEDED:                     'Company/UPLOAD_COMPANY_IMAGE_SUCCEEDED',
    UPLOAD_COMPANY_LOGO_FAILED:                         'Company/UPLOAD_COMPANY_LOGO_FAILED',
    UPLOAD_COMPANY_LOGO_SUCCEEDED:                      'Company/UPLOAD_COMPANY_LOGO_SUCCEEDED',
};

const createCompany = makeActionCreator(
    CompanyTypes.CREATE_COMPANY,
);

const createCompanyAndUser = makeActionCreator(
    CompanyTypes.CREATE_COMPANY_AND_USER,
);

const createCompanyAndUserFailed = makeActionCreator(
    CompanyTypes.CREATE_COMPANY_AND_USER_FAILED,
);

const createCompanyAndUserSuccess = makeActionCreator(
    CompanyTypes.CREATE_COMPANY_AND_USER_SUCCESS,
);

const createCompanyAndUserAndProject = makeActionCreator(
    CompanyTypes.CREATE_COMPANY_AND_USER_AND_PROJECT,
);

const createCompanyAndUserAndProjectFailed = makeActionCreator(
    CompanyTypes.CREATE_COMPANY_AND_USER_AND_PROJECT_FAILED,
);

const createCompanyAndUserAndProjectSuccess = makeActionCreator(
    CompanyTypes.CREATE_COMPANY_AND_USER_AND_PROJECT_SUCCESS,
);

const createCompanyFailed = makeActionCreator(
    CompanyTypes.CREATE_COMPANY_FAILED,
);

const createCompanySuccess = makeActionCreator(
    CompanyTypes.CREATE_COMPANY_SUCCESS,
    {
        company: null,
    },
);

const createOrDeleteCompanyImages = makeActionCreator(
    CompanyTypes.CREATE_OR_DELETE_COMPANY_IMAGES,
);

const createOrDeleteCompanyImagesFailed = makeActionCreator(
    CompanyTypes.CREATE_OR_DELETE_COMPANY_IMAGES_FAILED,
);

const createOrDeleteCompanyImagesSuccess = makeActionCreator(
    CompanyTypes.CREATE_OR_DELETE_COMPANY_IMAGES_SUCCESS,
);

const createOrUpdateOwnCompanyLocations = makeActionCreator(
    CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_LOCATIONS,
    {
        locations: null,
    },
);

const createOrUpdateOwnCompanyLocationsFailed = makeActionCreator(
    CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_LOCATIONS_FAILED,
);

const createOrUpdateOwnCompanyLocationsSuccess = makeActionCreator(
    CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_LOCATIONS_SUCCESS,
);

const createOrUpdateOwnCompanyReferences = makeActionCreator(
    CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_REFERENCES,
    {
        iri:        null,
        references: null,
    },
);

const createOrUpdateOwnCompanyReferencesFailed = makeActionCreator(
    CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_REFERENCES_FAILED,
    {
        index: null,
    },
);

const createOrUpdateOwnCompanyReferencesSuccess = makeActionCreator(
    CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_REFERENCES_SUCCESS,
);

const createOrUpdateOwnCompanySocialMedias = makeActionCreator(
    CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_SOCIAL_MEDIAS,
    {
        iri:          null,
        socialMedias: null,
    },
);

const createOrUpdateOwnCompanySocialMediasFailed = makeActionCreator(
    CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_SOCIAL_MEDIAS_FAILED,
    {
        index: null,
    },
);

const createOrUpdateOwnCompanySocialMediasSuccess = makeActionCreator(
    CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_SOCIAL_MEDIAS_SUCCESS,
);

const createOwnCompanyReference = makeActionCreator(
    CompanyTypes.CREATE_OWN_COMPANY_REFERENCE,
    {
        iri:       null,
        reference: null,
    },
);

const createOwnCompanyReferenceFailed = makeActionCreator(
    CompanyTypes.CREATE_OWN_COMPANY_REFERENCE_FAILED,
);

const createOwnCompanyReferenceSuccess = makeActionCreator(
    CompanyTypes.CREATE_OWN_COMPANY_REFERENCE_SUCCESS,
);

const editOwnCompanyCompetences = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_COMPETENCES,
);

const editOwnCompanyHeader = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_HEADER,
);

const editOwnCompanyImages = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_IMAGES,
);

const editOwnCompanyInformation = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_INFORMATION,
);

const editOwnCompanyLanguages = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_LANGUAGES,
);

const editOwnCompanyLocations = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_LOCATIONS,
);

const editOwnCompanyPlaceOfWorks = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_PLACE_OF_WORKS,
);

const editOwnCompanyReferences = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_REFERENCES,
);

const editOwnCompanySocialMedias = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_SOCIAL_MEDIAS,
);

const fetchCompany = makeActionCreator(
    CompanyTypes.FETCH_COMPANY,
    {
        companyId: null,
    },
);

const fetchCompanyFailed = makeActionCreator(
    CompanyTypes.FETCH_COMPANY_FAILED,
    {
        companyId: null,
    },
);

const fetchCompanySuccess = makeActionCreator(
    CompanyTypes.FETCH_COMPANY_SUCCESS,
    {
        company: null,
    },
);

const fetchOwnCompany = makeActionCreator(
    CompanyTypes.FETCH_OWN_COMPANY,
    {
        companyId: null,
    },
);

const fetchOwnCompanyFailed = makeActionCreator(
    CompanyTypes.FETCH_OWN_COMPANY_FAILED,
    {
        companyId: null,
    },
);

const fetchOwnCompanySuccess = makeActionCreator(
    CompanyTypes.FETCH_OWN_COMPANY_SUCCESS,
    {
        company: null,
    },
);

const setOwnCompanyAddressCity = makeActionCreator(
    CompanyTypes.SET_OWN_COMPANY_ADDRESS_CITY,
    {
        index: null,
        city:  null,
    },
);

const setOwnCompanyAddressHouseNumber = makeActionCreator(
    CompanyTypes.SET_OWN_COMPANY_ADDRESS_HOUSE_NUMBER,
    {
        index:       null,
        houseNumber: null,
    },
);

const setOwnCompanyAddressPostalCode = makeActionCreator(
    CompanyTypes.SET_OWN_COMPANY_ADDRESS_POSTAL_CODE,
    {
        index:      null,
        postalCode: null,
    },
);

const setOwnCompanyAddressStreet = makeActionCreator(
    CompanyTypes.SET_OWN_COMPANY_ADDRESS_STREET,
    {
        index:  null,
        street: null,
    },
);

const setOwnCompanyName = makeActionCreator(
    CompanyTypes.SET_OWN_COMPANY_NAME,
    {
        name: null,
    },
);

const setOwnCompanyRoleInCompany = makeActionCreator(
    CompanyTypes.SET_OWN_COMPANY_ROLE_IN_COMPANY,
    {
        roleInCompany: null,
    },
);

const setOwnCompanyWebsite = makeActionCreator(
    CompanyTypes.SET_OWN_COMPANY_WEBSITE,
    {
        website: null,
    },
);

const temporaryAddOwnCompanyCompetence = makeActionCreator(
    CompanyTypes.TEMPORARY_ADD_OWN_COMPANY_COMPETENCE,
    {
        competence: null,
    },
);

const temporaryAddOwnCompanyLanguage = makeActionCreator(
    CompanyTypes.TEMPORARY_ADD_OWN_COMPANY_LANGUAGE,
    {
        language: null,
    },
);

const temporaryAddOwnCompanyLocation = makeActionCreator(
    CompanyTypes.TEMPORARY_ADD_OWN_COMPANY_LOCATION,
);

const temporaryAddOwnCompanyReference = makeActionCreator(
    CompanyTypes.TEMPORARY_ADD_OWN_COMPANY_REFERENCE,
);

const temporaryAddOwnCompanySocialMedia = makeActionCreator(
    CompanyTypes.TEMPORARY_ADD_OWN_COMPANY_SOCIAL_MEDIA,
);

const temporaryRemoveOwnCompanyCompetence = makeActionCreator(
    CompanyTypes.TEMPORARY_REMOVE_OWN_COMPANY_COMPETENCE,
    {
        competence: null,
    },
);

const temporaryRemoveOwnCompanyImage = makeActionCreator(
    CompanyTypes.TEMPORARY_REMOVE_OWN_COMPANY_IMAGE,
    {
        index: null,
    },
);

const temporaryRemoveOwnCompanyLanguage = makeActionCreator(
    CompanyTypes.TEMPORARY_REMOVE_OWN_COMPANY_LANGUAGE,
    {
        language: null,
    },
);

const temporaryRemoveOwnCompanyLocation = makeActionCreator(
    CompanyTypes.TEMPORARY_REMOVE_OWN_COMPANY_LOCATION,
    {
        index: null,
    },
);

const temporaryRemoveOwnCompanyBanner = makeActionCreator(
    CompanyTypes.TEMPORARY_REMOVE_OWN_COMPANY_BANNER,
);

const temporaryRemoveOwnCompanyLogo = makeActionCreator(
    CompanyTypes.TEMPORARY_REMOVE_OWN_COMPANY_LOGO,
);

const temporaryRemoveOwnCompanyReference = makeActionCreator(
    CompanyTypes.TEMPORARY_REMOVE_OWN_COMPANY_REFERENCE,
    {
        index: null,
    },
);

const temporaryRemoveOwnCompanySocialMedia = makeActionCreator(
    CompanyTypes.TEMPORARY_REMOVE_OWN_COMPANY_SOCIAL_MEDIA,
    {
        index: null,
    },
);

const temporarySetOwnCompanyAdminUser = makeActionCreator(
    CompanyTypes.TEMPORARY_SET_OWN_COMPANY_ADMIN_USER,
    {
        userIri: null,
    },
);

const temporarySetOwnCompanyAdminUserFailed = makeActionCreator(
    CompanyTypes.TEMPORARY_SET_OWN_COMPANY_ADMIN_USER_FAILED,
);

const temporarySetOwnCompanyAdminUserSuccess = makeActionCreator(
    CompanyTypes.TEMPORARY_SET_OWN_COMPANY_ADMIN_USER_SUCCESS,
);

const temporarySetOwnCompanyDescription = makeActionCreator(
    CompanyTypes.TEMPORARY_SET_OWN_COMPANY_DESCRIPTION,
    {
        description: null,
    },
);

const temporarySetOwnCompanyLocation = makeActionCreator(
    CompanyTypes.TEMPORARY_SET_OWN_COMPANY_LOCATION,
    {
        index:   null,
        address: null,
    },
);

const temporarySetOwnCompanyLogo = makeActionCreator(
    CompanyTypes.TEMPORARY_SET_OWN_COMPANY_LOGO,
    {
        logo: null,
    },
);

const temporarySetOwnCompanyName = makeActionCreator(
    CompanyTypes.TEMPORARY_SET_OWN_COMPANY_NAME,
    {
        name: null,
    },
);

const temporarySetOwnCompanyPhoneNumberCompany = makeActionCreator(
    CompanyTypes.TEMPORARY_SET_OWN_COMPANY_PHONE_NUMBER,
    {
        phoneNumber: null,
    },
);

const temporarySetOwnCompanyPlaceOfWork = makeActionCreator(
    CompanyTypes.TEMPORARY_SET_OWN_COMPANY_PLACE_OF_WORK,
    {
        placeOfWork: null,
    },
);

const temporarySetOwnCompanyReferenceVideoUrl = makeActionCreator(
    CompanyTypes.TEMPORARY_SET_OWN_COMPANY_REFERENCE_VIDEO_URL,
    {
        index:    null,
        videoUrl: null,
    },
);

const temporarySetOwnCompanySocialMedia = makeActionCreator(
    CompanyTypes.TEMPORARY_SET_OWN_COMPANY_SOCIAL_MEDIA,
    {
        index: null,
        url:   null,
    },
);

const temporarySetOwnCompanyType = makeActionCreator(
    CompanyTypes.TEMPORARY_SET_OWN_COMPANY_TYPE,
    {
        companyType: null,
    },
);

const temporarySetOwnCompanyRoleInCompany = makeActionCreator(
    CompanyTypes.TEMPORARY_SET_OWN_COMPANY_ROLE_IN_COMPANY,
    {
        roleInCompany: null,
    },
);

const temporarySetOwnCompanyWebsite = makeActionCreator(
    CompanyTypes.TEMPORARY_SET_OWN_COMPANY_WEBSITE,
    {
        website: null,
    },
);

const updateCompany = makeActionCreator(
    CompanyTypes.UPDATE_COMPANY,
    {
        data: null,
    },
);

const updateCompanyFailed = makeActionCreator(
    CompanyTypes.UPDATE_COMPANY_FAILED,
);

const updateCompanySuccess = makeActionCreator(
    CompanyTypes.UPDATE_COMPANY_SUCCESS,
    {
        company: null,
    },
);

const updateCompanyUploadProgress = makeActionCreator(
    CompanyTypes.UPDATE_COMPANY_UPLOAD_PROGRESS,
    {
        id:       null,
        progress: null,
    },
);

// https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-357
const updateOwnCompany = makeActionCreator(
    CompanyTypes.UPDATE_OWN_COMPANY,
);

const updateOwnCompanyFailed = makeActionCreator(
    CompanyTypes.UPDATE_OWN_COMPANY_FAILED,
);

const updateOwnCompanySuccess = makeActionCreator(
    CompanyTypes.UPDATE_OWN_COMPANY_SUCCESS,
    {
        company: null,
    },
);

const uploadCompanyBanner = makeActionCreator(
    CompanyTypes.UPDATE_COMPANY_BANNER,
    {
        id:   null,
        file: null,
    },
);

const uploadCompanyBannerFailed = makeActionCreator(
    CompanyTypes.UPLOAD_COMPANY_BANNER_FAILED,
    {
        id: null,
    },
);

const uploadCompanyBannerSucceeded = makeActionCreator(
    CompanyTypes.UPLOAD_COMPANY_BANNER_SUCCEEDED,
    {
        id:  null,
        iri: null,
    },
);

const uploadCompanyImage = makeActionCreator(
    CompanyTypes.UPDATE_COMPANY_IMAGE,
    {
        id:   null,
        name: null,
        file: null,
    },
);

const uploadCompanyImageFailed = makeActionCreator(
    CompanyTypes.UPLOAD_COMPANY_IMAGE_FAILED,
    {
        id: null,
    },
);

const uploadCompanyImageSucceeded = makeActionCreator(
    CompanyTypes.UPLOAD_COMPANY_IMAGE_SUCCEEDED,
    {
        id:   null,
        iri:  null,
        name: null,
    },
);

const uploadCompanyLogo = makeActionCreator(
    CompanyTypes.UPDATE_COMPANY_LOGO,
    {
        id:   null,
        file: null,
    },
);

const uploadCompanyLogoFailed = makeActionCreator(
    CompanyTypes.UPLOAD_COMPANY_LOGO_FAILED,
    {
        id: null,
    },
);

const uploadCompanyLogoSucceeded = makeActionCreator(
    CompanyTypes.UPLOAD_COMPANY_LOGO_SUCCEEDED,
    {
        id:  null,
        iri: null,
    },
);

export const CompanyActions = {
    createCompany,
    createCompanyAndUser,
    createCompanyAndUserAndProject,
    createCompanyAndUserAndProjectFailed,
    createCompanyAndUserAndProjectSuccess,
    createCompanyAndUserFailed,
    createCompanyAndUserSuccess,
    createCompanyFailed,
    createCompanySuccess,
    createOrDeleteCompanyImages,
    createOrDeleteCompanyImagesFailed,
    createOrDeleteCompanyImagesSuccess,
    createOrUpdateOwnCompanyLocations,
    createOrUpdateOwnCompanyLocationsFailed,
    createOrUpdateOwnCompanyLocationsSuccess,
    createOrUpdateOwnCompanyReferences,
    createOrUpdateOwnCompanyReferencesFailed,
    createOrUpdateOwnCompanyReferencesSuccess,
    createOrUpdateOwnCompanySocialMedias,
    createOrUpdateOwnCompanySocialMediasFailed,
    createOrUpdateOwnCompanySocialMediasSuccess,
    createOwnCompanyReference,
    createOwnCompanyReferenceFailed,
    createOwnCompanyReferenceSuccess,
    editOwnCompanyCompetences,
    editOwnCompanyHeader,
    editOwnCompanyImages,
    editOwnCompanyInformation,
    editOwnCompanyLanguages,
    editOwnCompanyLocations,
    editOwnCompanyPlaceOfWorks,
    editOwnCompanyReferences,
    editOwnCompanySocialMedias,
    fetchCompany,
    fetchCompanyFailed,
    fetchCompanySuccess,
    fetchOwnCompany,
    fetchOwnCompanyFailed,
    fetchOwnCompanySuccess,
    setOwnCompanyAddressCity,
    setOwnCompanyAddressHouseNumber,
    setOwnCompanyAddressPostalCode,
    setOwnCompanyAddressStreet,
    setOwnCompanyName,
    setOwnCompanyRoleInCompany,
    setOwnCompanyWebsite,
    temporaryAddOwnCompanyCompetence,
    temporaryAddOwnCompanyLanguage,
    temporaryAddOwnCompanyLocation,
    temporaryAddOwnCompanyReference,
    temporaryAddOwnCompanySocialMedia,
    temporaryRemoveOwnCompanyBanner,
    temporaryRemoveOwnCompanyCompetence,
    temporaryRemoveOwnCompanyImage,
    temporaryRemoveOwnCompanyLanguage,
    temporaryRemoveOwnCompanyLocation,
    temporaryRemoveOwnCompanyLogo,
    temporaryRemoveOwnCompanyReference,
    temporaryRemoveOwnCompanySocialMedia,
    temporarySetOwnCompanyAdminUser,
    temporarySetOwnCompanyAdminUserFailed,
    temporarySetOwnCompanyAdminUserSuccess,
    temporarySetOwnCompanyDescription,
    temporarySetOwnCompanyLocation,
    temporarySetOwnCompanyLogo,
    temporarySetOwnCompanyName,
    temporarySetOwnCompanyPhoneNumberCompany,
    temporarySetOwnCompanyPlaceOfWork,
    temporarySetOwnCompanyReferenceVideoUrl,
    temporarySetOwnCompanyRoleInCompany,
    temporarySetOwnCompanySocialMedia,
    temporarySetOwnCompanyType,
    temporarySetOwnCompanyWebsite,
    updateCompany,
    updateCompanyFailed,
    updateCompanySuccess,
    updateCompanyUploadProgress,
    updateOwnCompany,
    updateOwnCompanyFailed,
    updateOwnCompanySuccess,
    uploadCompanyBanner,
    uploadCompanyBannerFailed,
    uploadCompanyBannerSucceeded,
    uploadCompanyImage,
    uploadCompanyImageFailed,
    uploadCompanyImageSucceeded,
    uploadCompanyLogo,
    uploadCompanyLogoFailed,
    uploadCompanyLogoSucceeded,
};
