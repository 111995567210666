//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import ComponentHelper from '../../../helper/ComponentHelper';
import _               from 'lodash';
import IconType        from '../Icon/IconType';
import IconButton      from '../IconButton';
import IconButtonTheme from '../IconButton/IconButtonTheme';

class CallToActionBox extends React.Component {
    getStyle = () => {
        const style = {};

        if (this.props.sidePadding) {
            style.paddingLeft = style.paddingRight = this.props.sidePadding;
        }

        return style;
    };

    render () {
        return (
            <div
                className={styles.wrapper}
                style={this.getStyle()}
            >
                <div className={styles.wrapperInner}>
                    {this.renderTitle()}
                    <p>
                        {this.props.text}
                    </p>
                    {this.renderAdditionalContent()}
                    <div className={styles.buttonWrapper}>
                        <IconButton
                            iconType={this.props.iconType}
                            onClick={this.props.onClick}
                            text={this.props.buttonText}
                            theme={IconButtonTheme.turquoiseGhost}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderAdditionalContent = () => {
        const { additionalContent } = this.props;

        if (additionalContent) {
            return additionalContent;
        }

        return null;
    };

    renderTitle = () => {
        const { rawTitle } = this.props;

        if (rawTitle) {
            return (
                <h3 dangerouslySetInnerHTML={{ __html: rawTitle }} />
            );
        }

        return (
            <h3>
                {this.props.title}
            </h3>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CallToActionBox;

Component.propTypes = {
    additionalContent: PropTypes.children,
    buttonText:        PropTypes.string,
    iconType:          PropTypes.oneOf(Object.keys(IconType)),
    onClick:           PropTypes.func,
    rawTitle:          PropTypes.string,
    sidePadding:       PropTypes.number,
    text:              PropTypes.string,
    title:             PropTypes.string,
};

Component.defaultProps = {
    additionalContent: null,
    buttonText:        '',
    iconType:          null,
    onClick:           _.noop,
    rawTitle:          '',
    sidePadding:       0,
    text:              '',
    title:             '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
