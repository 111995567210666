//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import ComponentHelper from '../../../helper/ComponentHelper';
import _               from 'lodash';
import classNames      from 'classnames';

class TabBar extends React.Component {
    render () {
        return (
            <div
                className={styles.wrapper}
            >
                {this.props.tabTitles.map(this.renderTab)}
            </div>
        );
    }

    renderTab = (tabTitle, tabIndex) => {
        return (
            <div
                className={classNames(
                    styles.tab,
                    {
                        [styles.tabActive]: this.props.activeTabIndex === tabIndex,
                    },
                )}
                key={tabIndex}
                onClick={() => {
                    this.props.tabChanged(tabIndex);
                }}
            >
                {tabTitle}
            </div>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = TabBar;

Component.propTypes = {
    tabChanged: PropTypes.func,
    tabTitles:  PropTypes.arrayOf(PropTypes.string),
};

Component.defaultProps = {
    tabChanged: _.noop,
    tabTitles:  [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;