//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }                  from 'redux-saga/effects';
import { call }                 from 'redux-saga/effects';
import * as Api                 from '../../api';
import { ConfigurationActions } from '../actions/configuration';
import { push }                 from 'connected-react-router';
import Routes                   from '../../constants/Routes';

const fetchConfiguration = function* () {
    const response = yield call(
        Api.fetchConfiguration,
    );

    if (response.ok) {
        const configuration = response.data;

        yield put(ConfigurationActions.fetchConfigurationSuccess({
            configuration,
        }));
    } else {
        yield put(ConfigurationActions.fetchConfigurationFailed());
    }
};

export default {
    fetchConfiguration,
};
