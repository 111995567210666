//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                    from 'lodash';
import Avatar               from '../../connected/Avatar';
import AvatarSize           from '../Avatar/AvatarSize';
import classNames           from 'classnames';
import CompanyTypes         from '../../../constants/CompanyTypes';
import ComponentHelper      from '../../../helper/ComponentHelper';
import DotListMenu          from '../DotListMenu';
import DotListMenuAlignment from '../DotListMenu/DotListMenuAlignment';
import DotListMenuItem      from '../DotListMenuItem';
import DotListMenuTheme     from '../DotListMenu/DotListMenuTheme';
import I18n                 from 'i18next';
import Icon                 from '../Icon';
import IconLabel            from '../IconLabel';
import IconLabelTheme       from '../IconLabel/IconLabelTheme';
import IconType             from '../Icon/IconType';
import Normalize            from '../../../helper/Normalize';
import ProjectMenuSubItem   from '../ProjectMenuSubItem';
import ProjectStateTypes    from '../../../constants/ProjectStateTypes';
import ProjectSubMenuTypes  from '../../../constants/ProjectSubMenuTypes';
import PropTypes            from '../../PropTypes';
import React                from 'react';
import ShortFactList        from '../ShortFactList';
import styles               from './styles.module.scss';

class ProjectMenuItem extends React.Component {
    render() {
        return (
            <div
                className={classNames(
                    styles.wrapper,
                    {
                        [styles.wrapperActive]: this.props.isActive,
                    },
                )}
            >
                {this.renderIncompleteBadge()}
                {this.renderHeader()}
                {this.renderMenu()}
                {this.renderDotListMenu()}
            </div>
        );
    }

    renderAvatar = () => {
        return (
            <div className={styles.avatarWrapper}>
                <Avatar
                    company={this.props.company}
                    size={AvatarSize.medium}
                    disableOnClick={true}
                />
            </div>
        );
    };

    renderDotListMenu = () => {
        const state = _.get(this.props, 'projectState');

        if (
            this.props.companyType === CompanyTypes.company &&
            (
                state !== ProjectStateTypes.commissioned &&
                state !== ProjectStateTypes.finished
            )
        ) {
            return (
                <div className={styles.dotListWrapper}>
                    <DotListMenu
                        alignment={DotListMenuAlignment.right}
                        requireClickToOpen={true}
                        theme={DotListMenuTheme.small}
                    >
                        <DotListMenuItem
                            onClick={this.props.editProjectButtonPressed}
                            text={I18n.t('editProjectShort')}
                            theme={DotListMenuTheme.small}
                        />
                        <DotListMenuItem
                            onClick={this.props.deleteProjectButtonPressed}
                            text={I18n.t('deleteProjectShort')}
                            theme={DotListMenuTheme.small}
                        />
                    </DotListMenu>
                </div>
            );
        }

        return null;
    };

    renderHeader = () => {
        return (
            <div
                className={styles.header}
                onClick={this.props.onHeaderClicked}
            >
                <div
                    className={classNames(
                        styles.openCloseIndicatorWrapper,
                        {
                            [styles.openCloseIndicatorWrapperFlipped]: this.props.isActive,
                        },
                    )}
                >
                    <Icon
                        iconType={IconType.chevronDown}
                    />
                </div>
                {this.renderAvatar()}
                <div className={styles.textWrapper}>
                    <h2>
                        {this.props.title}
                    </h2>
                    <div
                        className={classNames(
                            styles.shortFactsTextWrapper,
                            {
                                [styles.shortFactsTextWrapperIncomplete]: this.props.isIncomplete,
                            },
                        )}
                    >
                        {this.renderShortFacts()}
                    </div>
                </div>
            </div>
        );
    };

    renderIncompleteBadge = () => {
        if (this.props.isIncomplete) {
            return (
                <div className={styles.isIncompleteBadge}>
                    {I18n.t('projectIncompleteHint')}
                </div>
            );
        }

        return null;
    };

    renderMenu = () => {
        return (
            <div
                className={classNames(
                    styles.menu,
                    {
                        [styles.menuIncomplete]:      this.props.isIncomplete,
                        [styles.menuOpened]:          this.props.isActive,
                        [styles.menuVideoProduction]: this.props.companyType === CompanyTypes.videoProduction,
                    },
                )}
            >
                <div className={styles.menuInner}>
                    {this.renderMenuMatches()}
                    <ProjectMenuSubItem
                        badeCount={this.props.messageCount}
                        iconType={IconType.messageSquare}
                        isActive={this.props.activeProjectSubMenuType === ProjectSubMenuTypes.messages}
                        onClick={this.props.messagesButtonPressed}
                        text={I18n.t('messages')}
                    />
                    <ProjectMenuSubItem
                        badeCount={null}
                        iconType={IconType.fileText}
                        isActive={this.props.activeProjectSubMenuType === ProjectSubMenuTypes.offers}
                        onClick={this.props.offersButtonPressed}
                        text={I18n.t('offers')}
                    />
                    <ProjectMenuSubItem
                        badeCount={null}
                        iconType={IconType.file}
                        isActive={this.props.activeProjectSubMenuType === ProjectSubMenuTypes.briefing}
                        onClick={this.props.briefingButtonPressed}
                        text={I18n.t('briefing')}
                    />
                </div>
            </div>
        );
    };

    renderMenuMatches = () => {
        switch (this.props.companyType) {
            // @formatter:off
            case CompanyTypes.company:         return this.renderMenuMatchesCompany();
            case CompanyTypes.videoProduction: return this.renderMenuMatchesVideoProduction();
            // @formatter:on
        }

        return null;
    }

    renderMenuMatchesCompany = () => {
        return (
            <ProjectMenuSubItem
                badeCount={null}
                iconType={IconType.zap}
                isActive={this.props.activeProjectSubMenuType === ProjectSubMenuTypes.matches}
                onClick={this.props.matchesButtonPressed}
                text={I18n.t('matches')}
            />
        );
    };

    renderMenuMatchesVideoProduction = () => {
        if (this.props.companyType !== CompanyTypes.videoProduction) {
            return (
                <ProjectMenuSubItem
                    badeCount={null}
                    iconType={IconType.zap}
                    isActive={this.props.activeProjectSubMenuType === ProjectSubMenuTypes.match}
                    onClick={this.props.matchButtonPressed}
                    text={I18n.t('match')}
                />
            );
        }

        return null;
    };

    renderShortFacts = () => {
        return (
            <ShortFactList itemsPerRow={2}>
                <IconLabel
                    iconType={IconType.camera}
                    text={this.props.type}
                    theme={IconLabelTheme.smallGray}
                />
                <IconLabel
                    iconType={IconType.users}
                    text={this.renderShortFactsAgeRangeText()}
                    theme={IconLabelTheme.smallGray}
                />
                <IconLabel
                    iconType={IconType.euroSign}
                    text={this.renderShortFactsBudgetRangeText()}
                    theme={IconLabelTheme.smallGray}
                />
                <IconLabel
                    iconType={IconType.movie}
                    text={this.renderShortFactsVideoFormats()}
                    theme={IconLabelTheme.smallGray}
                />
            </ShortFactList>
        );
    };

    renderShortFactsAgeRangeText = () => {
        return Normalize.toAgeRange(
            this.props.ageRangeFrom,
            this.props.ageRangeTo,
        );
    };

    renderShortFactsBudgetRangeText = () => {
        return Normalize.toBudgetRange(
            this.props.budgetRangeInThousandsFrom,
            this.props.budgetRangeInThousandsTo,
        );
    };

    renderShortFactsVideoFormats = () => {
        return Normalize.formatsToStringList(this.props.formats).join(', ');
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ProjectMenuItem;

Component.propTypes = {
    activeProjectSubMenuType:   PropTypes.oneOf(Object.keys(ProjectSubMenuTypes)),
    ageRangeFrom:               PropTypes.number,
    ageRangeTo:                 PropTypes.number,
    briefingButtonPressed:      PropTypes.func,
    budgetRangeInThousandsFrom: PropTypes.number,
    budgetRangeInThousandsTo:   PropTypes.number,
    company:                    PropTypes.object,
    companyType:                PropTypes.oneOf(Object.values(CompanyTypes)),
    deleteProjectButtonPressed: PropTypes.func,
    editProjectButtonPressed:   PropTypes.func,
    formats:                    PropTypes.array,
    isActive:                   PropTypes.bool,
    isIncomplete:               PropTypes.bool,
    matchButtonPressed:         PropTypes.func,
    matchesButtonPressed:       PropTypes.func,
    messageCount:               PropTypes.number,
    messagesButtonPressed:      PropTypes.func,
    offersButtonPressed:        PropTypes.func,
    onHeaderClicked:            PropTypes.func,
    projectState:               PropTypes.string,
    title:                      PropTypes.string,
    type:                       PropTypes.string,
};

Component.defaultProps = {
    activeProjectSubMenuType:   ProjectSubMenuTypes.matches,
    ageRangeFrom:               0,
    ageRangeTo:                 0,
    briefingButtonPressed:      _.noop,
    budgetRangeInThousandsFrom: 0,
    budgetRangeInThousandsTo:   0,
    company:                    null,
    companyType:                null,
    deleteProjectButtonPressed: _.noop,
    editProjectButtonPressed:   _.noop,
    formats:                    [],
    isActive:                   false,
    isIncomplete:               false,
    matchButtonPressed:         _.noop,
    matchesButtonPressed:       _.noop,
    messageCount:               0,
    messagesButtonPressed:      _.noop,
    offersButtonPressed:        _.noop,
    onHeaderClicked:            _.noop,
    projectState:               null,
    title:                      '',
    type:                       '',
};

Component.renderAffectingProps = [
    'activeProjectSubMenuType',
    'ageRangeFrom',
    'ageRangeTo',
    'avatarImage',
    'budgetRangeInThousandsFrom',
    'budgetRangeInThousandsTo',
    'companyType',
    'formats',
    'isActive',
    'isIncomplete',
    'messageCount',
    'projectState',
    'title',
    'type',
];

Component.renderAffectingStates = [];

export default Component;
