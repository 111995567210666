//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { TerritoriesActions } from '../actions/territory';
import { put }                from 'redux-saga/effects';
import { call }               from 'redux-saga/effects';
import * as Api               from '../../api';
import HydraHelper            from '../../helper/Hydra';
import { push }               from 'connected-react-router';
import Routes                 from '../../constants/Routes';

const fetchTerritories = function* () {
    const response = yield call(
        Api.fetchTerritories,
    );

    if (response.ok) {
        const cleanHydraResponse = HydraHelper.cleanupObject(response.data);
        const territories        = cleanHydraResponse.member;

        yield put(TerritoriesActions.fetchTerritoriesSuccess({
            territories,
        }));
    } else {
        yield put(TerritoriesActions.fetchTerritoriesFailed());
    }
};

export default {
    fetchTerritories,
};
