//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update                     from 'immutability-helper';
import { ActiveProjectTypes }     from '../actions/activeProject';
import { BranchTypes }            from '../actions/branch';
import { CallbackRequestTypes }   from '../actions/callbackRequest';
import { CompanyPositionTypes }   from '../actions/companyPosition';
import { CompanyTypes }           from '../actions/company';
import { CreateEditOfferTypes }   from '../actions/createEditOffer';
import { CreateEditProjectTypes } from '../actions/createEditProject';
import { GoalTypes }              from '../actions/goal';
import { LicenseDurationTypes }   from '../actions/licenseDuration';
import { LoadingTypes }           from '../actions/loading';
import { ProjectTypes }           from '../actions/project';
import { TerritoriesTypes }       from '../actions/territory';
import { UserTypes }              from '../actions/user';
import { VideoFormatTypes }       from '../actions/videoFormat';
import { VideoTypeTypes }         from '../actions/videoType';
import { PlaceOfWorkTypes }       from '../actions/placeOfWork';
import { LanguageTypes }          from '../actions/language';
import { CompetenceTypes }        from '../actions/competence';

const initialState = {
    level:     0,
    isLoading: false,
};

// https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-215

export default (state = initialState, action) => {
    switch (action.type) {
        case ActiveProjectTypes.ACCEPT_OFFER_FAILED:
        case ActiveProjectTypes.ACCEPT_OFFER_SUCCESS:
        case ActiveProjectTypes.FETCH_MATCHES_FAILED:
        case ActiveProjectTypes.FETCH_MATCHES_SUCCESS:
        case ActiveProjectTypes.FETCH_MESSAGES_FAILED:
        case ActiveProjectTypes.FETCH_MESSAGES_SUCCESS:
        case ActiveProjectTypes.FETCH_OFFERS_FAILED:
        case ActiveProjectTypes.FETCH_OFFERS_SUCCESS:
        case BranchTypes.FETCH_BRANCHES_FAILED:
        case BranchTypes.FETCH_BRANCHES_SUCCESS:
        case CallbackRequestTypes.SEND_CALLBACK_REQUEST_FAILED:
        case CallbackRequestTypes.SEND_CALLBACK_REQUEST_SUCCESS:
        case CompanyPositionTypes.FETCH_COMPANY_POSITIONS_FAILED:
        case CompanyPositionTypes.FETCH_COMPANY_POSITIONS_SUCCESS:
        case CompanyTypes.CREATE_COMPANY_AND_USER_FAILED:
        case CompanyTypes.CREATE_COMPANY_AND_USER_SUCCESS:
        case CompanyTypes.CREATE_COMPANY_AND_USER_AND_PROJECT_FAILED:
        case CompanyTypes.CREATE_COMPANY_AND_USER_AND_PROJECT_SUCCESS:
        case CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_REFERENCES_FAILED:
        case CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_REFERENCES_SUCCESS:
        case CompanyTypes.FETCH_COMPANY_FAILED:
        case CompanyTypes.FETCH_COMPANY_SUCCESS:
        case CompanyTypes.FETCH_OWN_COMPANY_FAILED:
        case CompanyTypes.FETCH_OWN_COMPANY_SUCCESS:
        case CompanyTypes.UPDATE_COMPANY_FAILED:
        case CompanyTypes.UPDATE_COMPANY_SUCCESS:
        case CompanyTypes.UPDATE_OWN_COMPANY_FAILED:
        case CompanyTypes.UPDATE_OWN_COMPANY_SUCCESS:
        case CompetenceTypes.FETCH_COMPETENCES_FAILED:
        case CompetenceTypes.FETCH_COMPETENCES_SUCCESS:
        case CreateEditOfferTypes.CREATE_OFFER_FAILED:
        case CreateEditOfferTypes.CREATE_OFFER_SUCCESS:
        case CreateEditOfferTypes.UPDATE_OFFER_FAILED:
        case CreateEditOfferTypes.UPDATE_OFFER_SUCCESS:
        case CreateEditProjectTypes.CREATE_PROJECT_FAILED:
        case CreateEditProjectTypes.CREATE_PROJECT_SUCCESS:
        case CreateEditProjectTypes.UPDATE_PROJECT_FAILED:
        case CreateEditProjectTypes.UPDATE_PROJECT_SUCCESS:
        case GoalTypes.FETCH_GOALS_FAILED:
        case GoalTypes.FETCH_GOALS_SUCCESS:
        case LanguageTypes.FETCH_LANGUAGES_FAILED:
        case LanguageTypes.FETCH_LANGUAGES_SUCCESS:
        case LicenseDurationTypes.FETCH_LICENSE_DURATIONS_FAILED:
        case LicenseDurationTypes.FETCH_LICENSE_DURATIONS_SUCCESS:
        case PlaceOfWorkTypes.FETCH_PLACE_OF_WORK_FAILED:
        case PlaceOfWorkTypes.FETCH_PLACE_OF_WORK_SUCCESS:
        case ProjectTypes.FETCH_PROJECTS_FAILED:
        case ProjectTypes.FETCH_PROJECTS_SUCCESS:
        case TerritoriesTypes.FETCH_TERRITORIES_FAILED:
        case TerritoriesTypes.FETCH_TERRITORIES_SUCCESS:
        case UserTypes.FETCH_USER_FAILED:
        case UserTypes.FETCH_USER_SUCCESS:
        case UserTypes.LOGIN_FAILED:
        case UserTypes.LOGIN_SUCCEEDED:
        case UserTypes.VERIFY_REGISTRATION_FAILED:
        case UserTypes.VERIFY_REGISTRATION_SUCCEEDED:
        case VideoFormatTypes.FETCH_VIDEO_FORMATS_FAILED:
        case VideoFormatTypes.FETCH_VIDEO_FORMATS_SUCCESS:
        case VideoTypeTypes.FETCH_VIDEO_TYPES_FAILED:
        case VideoTypeTypes.FETCH_VIDEO_TYPES_SUCCESS:
            if (action.silent) {
                return state;
            }

            const level     = Math.max(state.level - 1, 0);
            const isLoading = !(
                level === 0
            );

            return update(state, {
                level:     {
                    $set: level,
                },
                isLoading: {
                    $set: isLoading,
                },
            });

        case ActiveProjectTypes.ACCEPT_OFFER:
        case ActiveProjectTypes.FETCH_MATCHES:
        case ActiveProjectTypes.FETCH_MESSAGES:
        case ActiveProjectTypes.FETCH_OFFERS:
        case BranchTypes.FETCH_BRANCHES:
        case CallbackRequestTypes.SEND_CALLBACK_REQUEST:
        case CompanyPositionTypes.FETCH_COMPANY_POSITIONS:
        case CompanyTypes.CREATE_COMPANY_AND_USER:
        case CompanyTypes.CREATE_COMPANY_AND_USER_AND_PROJECT:
        case CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_REFERENCES:
        case CompanyTypes.FETCH_COMPANY:
        case CompanyTypes.FETCH_OWN_COMPANY:
        case CompanyTypes.UPDATE_COMPANY:
        case CompanyTypes.UPDATE_OWN_COMPANY:
        case CompetenceTypes.FETCH_COMPETENCES:
        case CreateEditOfferTypes.CREATE_OFFER:
        case CreateEditOfferTypes.UPDATE_OFFER:
        case CreateEditProjectTypes.CREATE_PROJECT:
        case CreateEditProjectTypes.UPDATE_PROJECT:
        case GoalTypes.FETCH_GOALS:
        case LanguageTypes.FETCH_LANGUAGES:
        case LicenseDurationTypes.FETCH_LICENSE_DURATIONS:
        case PlaceOfWorkTypes.FETCH_PLACE_OF_WORK:
        case ProjectTypes.FETCH_PROJECTS:
        case TerritoriesTypes.FETCH_TERRITORIES:
        case UserTypes.FETCH_USER:
        case UserTypes.LOGIN:
        case UserTypes.VERIFY_REGISTRATION:
        case VideoFormatTypes.FETCH_VIDEO_FORMATS:
        case VideoTypeTypes.FETCH_VIDEO_TYPES:
            if (action.silent) {
                return state;
            }

            const levelIncrease = 1;

            return update(state, {
                level:     {
                    $set: state.level + levelIncrease,
                },
                isLoading: {
                    $set: true,
                },
                text:      {
                    $set: action.text,
                },
            });

        case LoadingTypes.CLEAR_LOADING:
            return update(state, {
                isLoading: {
                    $set: false,
                },
                level:     {
                    $set: 0,
                },
            });
    }

    return state;
};
