//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _    from 'lodash';
import I18n from 'i18next';

class Cast {
    static array (value) {
        if (!_.isArray(value)) {
            return [value];
        }

        return value;
    }

    static bool (value) {
        const castedValue = !!value;

        return castedValue;
    }

    static float (value) {
        if (_.isString(value)) {
            value = value.replace(/[^0-9.]/g, '.');
        }

        const castedValue = parseFloat(value) || 0;

        return castedValue;
    }

    static int (value) {
        const castedValue = parseInt(value, 10) || 0;

        return castedValue;
    }

    static percentString (value) {
        const castedValue = value + '%';

        return castedValue;
    }

    static pixelString (value) {
        const castedValue = value + 'px';

        return castedValue;
    }

    static string (value) {
        const castedValue = value + '';

        return castedValue;
    }

    static yesNo (value) {
        if (value) {
            return I18n.t('yes');
        }

        return I18n.t('no');
    }
}

export default Cast;
