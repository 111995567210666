//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const VideoTypeTypes = {
    FETCH_VIDEO_TYPES:         'VideoType/FETCH_VIDEO_TYPES',
    FETCH_VIDEO_TYPES_FAILED:  'VideoType/FETCH_VIDEO_TYPES_FAILED',
    FETCH_VIDEO_TYPES_SUCCESS: 'VideoType/FETCH_VIDEO_TYPES_SUCCESS',
};

const fetchVideoTypes = makeActionCreator(
    VideoTypeTypes.FETCH_VIDEO_TYPES,
);

const fetchVideoTypesFailed = makeActionCreator(
    VideoTypeTypes.FETCH_VIDEO_TYPES_FAILED,
);

const fetchVideoTypesSuccess = makeActionCreator(
    VideoTypeTypes.FETCH_VIDEO_TYPES_SUCCESS,
    {
        videoTypes: null,
    },
);

export const VideoTypeActions = {
    fetchVideoTypes,
    fetchVideoTypesFailed,
    fetchVideoTypesSuccess,
};
