//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                from 'lodash';
import update           from 'immutability-helper';
import { ProjectTypes } from '../actions/project';

const initialState = {
    projectIriToDelete: null,
    projects:           [],
};

// https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-215

export default function (state = initialState, action) {
    switch (action.type) {
        case ProjectTypes.DELETE_PROJECT:
            return update(state, {
                projectIriToDelete: {
                    $set: action.projectIri,
                },
            });

        case ProjectTypes.FETCH_PROJECTS_SUCCESS:
            return update(state, {
                projects: {
                    $set: action.projects,
                },
            });

        case ProjectTypes.FETCH_PROJECT_SUCCESS:
            const project       = action.project;
            const stateProjects = state.projects;
            const indexToUpdate = _.findIndex(stateProjects, { 'iri': project.iri });

            return update(state, {
                projects: {
                    [indexToUpdate]: {
                        $set: project,
                    },
                },
            });

        default:
            return state;
    }
}