//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import Cast                   from '../../../helper/Cast';
import ComponentHelper        from '../../../helper/ComponentHelper';
import FormRow                from '../../stateless/FormRow';
import I18n                   from 'i18next';
import Input                  from '../../stateless/Input';
import InputType              from '../../stateless/Input/InputType';
import Overlay                from '../Overlay';
import OverlayButton          from '../../stateless/OverlayButton';
import OverlayButtonTheme     from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper   from '../../stateless/OverlayButtonWrapper';
import OverlayPane            from '../../stateless/OverlayPane';
import OverlayPaneState       from '../../stateless/OverlayPane/OverlayPaneState';
import Overlays               from '../../../constants/Overlays';
import PropTypes              from '../../PropTypes';
import Spacer                 from '../../stateless/Spacer';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { CompanyActions }     from '../../../store/actions/company';
import { connect }            from 'react-redux';
import { goBack }             from 'connected-react-router';
import { withRouter }         from 'react-router-dom';

class Component extends React.Component {
    cancelButtonPressed = () => {
        this.props.goBack();
    };

    componentDidMount() {
        if (!this.hasEmptyReference()) {
            this.props.temporaryAddOwnCompanyReference();
        }
    }

    hasEmptyReference = () => {
        return _.some(this.props.references, this.isReferenceEmpty);
    };

    isReferenceEmpty = (reference) => {
        return !reference.videoUrl || reference.videoUrl === '';
    };

    referenceVideoUrlChanged = (index) => {
        return (event) => {
            const value = event.target.value;

            if (value) {
                this.props.temporarySetOwnCompanyReferenceVideoUrl({
                    index,
                    videoUrl: value,
                });

                if (!this.hasEmptyReference()) {
                    this.props.temporaryAddOwnCompanyReference();
                }
            } else {
                this.props.temporaryRemoveOwnCompanyReference({ index });
            }
        };
    };

    render() {
        return (
            <Overlay
                id={Overlays.editCompanyReferences}
                title={I18n.t('profileDetails')}
            >
                <OverlayPane
                    content={this.renderContent()}
                    footer={this.renderFooter()}
                    title={I18n.t('companyReferences')}
                    showErrors={true}
                    state={OverlayPaneState.open}
                    scrollingAllowed={false}
                />
            </Overlay>
        );
    }

    renderContent = () => {
        const references         = _.orderBy(this.props.references, ['referenceOrder'], ['asc']); // TODO: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-658
        const renderedReferences = references.map(this.renderVideoUrlField);

        return (
            <>
                {renderedReferences}
                <Spacer height={20} />
            </>
        );
    };

    renderVideoUrlField = (reference, index) => {
        const url = _.get(reference, 'videoUrl', '');

        return (
            <FormRow
                key={index}
                label={I18n.t('videoLink', { count: Cast.string(index + 1) })}
                subLabel={I18n.t('vimeoOrYoutube')}
                tooltipText={I18n.t('companyReferenceTipText')}
            >
                <Input
                    placeholder={I18n.t('defaultPlaceHolderVideoUrl')}
                    type={InputType.text}
                    value={url}
                    minLength={1}
                    onChange={this.referenceVideoUrlChanged(index)}
                    noMarginBottom={true}
                />
            </FormRow>
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.cancelButtonPressed}
                    standAlone={false}
                    text={I18n.t('cancel')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.saveButtonPressed}
                    standAlone={false}
                    text={I18n.t('save')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    saveButtonPressed = () => {
        requestAnimationFrame(() => {
            this.props.createOrUpdateOwnCompanyReferences({
                references: this.props.references,
            });

            this.props.goBack();
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {
    company:    PropTypes.object,
    references: PropTypes.array,
};

Component.defaultProps = {
    company:    {},
    references: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        CompanyActions,
        {
            goBack,
        },
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        company:    _.get(state, 'company.ownCompanyEditContext', {}),
        references: _.get(state, 'company.ownCompanyEditContext.references', []),
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Component);
