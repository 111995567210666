//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                          from 'react';
import _                              from 'lodash';
import PropTypes                      from '../../PropTypes';
import StatelessScreenTooSmallOverlay from '../../stateless/ScreenTooSmallOverlay';
import { compose }                    from 'redux';
import { connect }                    from 'react-redux';
import { withRouter }                 from 'react-router-dom';
import ComponentHelper                from '../../../helper/ComponentHelper';

class Component extends React.Component {
    render() {
        return (
            <StatelessScreenTooSmallOverlay
                overlay={this.props.overlay}
                route={this.props.route}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {
    overlay: PropTypes.string,
    route:   PropTypes.string,
};

Component.defaultProps = {
    overlay: null,
    route:   null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        overlay: _.get(state, 'router.location.query.overlay'),
        route:   _.get(state, 'router.location.pathname'),
    }
);

export default compose(
    connect(
        mapStateToProps,
        null,
    ),
    withRouter,
)(Component);
