//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import GifWrapper             from '../../components/stateless/GifWrapper';
import I18n                   from 'i18next';
import IconButton             from '../../components/stateless/IconButton';
import IconButtonTheme        from '../../components/stateless/IconButton/IconButtonTheme';
import PageTitleHelper        from '../../helper/PageTitle';
import Routes                 from '../../constants/Routes';
import Spacer                 from '../../components/stateless/Spacer';
import styles                 from './styles.module.scss';
import { bindActionCreators } from 'redux';
import { Component }          from 'react';
import { compose }            from 'redux';
import { connect }            from 'react-redux';
import { Helmet }             from 'react-helmet';
import { UserActions }        from '../../store/actions/user';
import { withRouter }         from 'react-router-dom';

class Screen extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('error'))}
                    </title>
                </Helmet>
                <Spacer height={100} />
                <div className={styles.container}>
                    <GifWrapper imageSource={require('../../assets/images/oops.gif')} />
                    <div className={styles.textWrapper}>
                        <h1>
                            {I18n.t('error404')}
                        </h1>
                        <p>
                            {I18n.t('pageNotFound')}
                        </p>
                    </div>
                    {this.renderButton()}
                </div>
            </>
        );
    }

    renderHomeButton = () => {
        if (this.props.user.token) {
            return (
                <IconButton
                    text={I18n.t('backToHome')}
                    theme={IconButtonTheme.turquoiseGhost}
                    to={Routes.home}
                />
            );
        }

        return (
            <IconButton
                text={I18n.t('toLogin')}
                theme={IconButtonTheme.turquoiseGhost}
                to={Routes.login}
            />
        );
    };

    renderButton = () => {
        return (
            <div className={styles.buttonWrapper}>
                {this.renderHomeButton()}
            </div>
        );
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    UserActions,
    dispatch,
);

const mapStateToProps = state => (
    {
        user: state.user.user,
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Screen);
