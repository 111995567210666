//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

const cleanupObject = (objectToClean) => {
    const keys = Object.keys(objectToClean || {});

    for (let key of keys) {
        if (!objectToClean.hasOwnProperty(key)) {
            continue;
        }

        key = removeLeadingHydraFromKey(key, objectToClean);

        removeHydraSearch(key, objectToClean);
        cleanupChild(key, objectToClean);
        cleanupIds(key, objectToClean);
    }

    return objectToClean;
};

/**
 * @param key
 * @param objectToClean
 */
const removeLeadingHydraFromKey = (key, objectToClean) => {
    if (key.indexOf('hydra:') === 0) {
        objectToClean[key.substr(6)] = objectToClean[key];

        delete objectToClean[key];

        key = key.substr(6);
    }

    if (typeof objectToClean[key] === 'string' && objectToClean[key].indexOf('hydra:') === 0) {
        objectToClean[key] = objectToClean[key].substr(6);
    }

    return key;
};

/**
 *
 * @param key
 * @param objectToClean
 */
const removeHydraSearch = (key, objectToClean) => {
    if (key === 'search' || key === 'context') {
        delete objectToClean[key];
    }
};

/**
 * @param key
 * @param objectToClean
 */
const cleanupChild = (key, objectToClean) => {
    if (_.isArray(objectToClean[key])) {
        _.each(objectToClean[key], cleanupObject);
    } else if (_.isObject(objectToClean[key])) {
        cleanupObject(objectToClean[key]);
    }
};

/**
 * @param key
 * @param objectToClean
 */
const cleanupIds = (key, objectToClean) => {
    if (key === '@id') {
        objectToClean.iri = objectToClean['@id'];
    }
};

export default {
    /**
     * @param objectToClean
     * @returns {*}
     */
    cleanupObject (objectToClean) {
        return cleanupObject(objectToClean);
    },

    /**
     * @param iri
     */
    getIdFromIri (iri) {
        const splittedIri = iri.split('/');

        return splittedIri[3];
    },

    /**
     * @param objects
     * @returns {[]}
     */
    getIriArrayFromObjects (objects) {
        return _.map(objects, 'iri');
    },
};
