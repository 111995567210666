//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                   from 'react';
import PropTypes               from '../../PropTypes';
import ComponentHelper         from '../../../helper/ComponentHelper';
import { connect }             from 'react-redux';
import SplitPaneLoginStateless from '../../stateless/SplitPaneLogin';
import { bindActionCreators }  from 'redux';
import _                       from 'lodash';
import { AlertBoxActions }     from '../../../store/actions/alertBox';
import { UserActions }         from '../../../store/actions/user';
import I18n                    from 'i18next';

class SplitPaneLogin extends React.Component {
    eMailAddressChanged = (event) => {
        this.props.setEMailAddress({
            eMailAddress: event.target.value,
        });
    };

    loginButtonPressed = (event) => {
        console.log('LoginScreen: loginButtonPressed');

        this.props.clearAlerts();
        event.preventDefault();
        event.target.reportValidity();

        if (!event.target.checkValidity()) {
            return;
        }

        if (!this.props.email) {
            this.props.showErrorAlertTagged({
                tag:  'email',
                text: I18n.t('eMailAddressError1'),
            });
        }

        if (!this.props.password) {
            this.props.showErrorAlertTagged({
                tag:  'password',
                text: I18n.t('passwordError1'),
            });
        }

        requestAnimationFrame(() => {
            if (this.props.alertBoxesTaggedCount === 0) {
                this.props.login({
                    redirect:      true,
                    suppressError: false,
                });
            }
        });
    };

    lostPasswordButtonPressed = () => {
        this.props.clearAlerts();

        if (!this.props.email) {
            this.props.showErrorAlertTagged({
                tag:  'email',
                text: I18n.t('eMailAddressError1'),
            });
        } else {
            this.props.resetPassword({
                email: this.props.email,
            });
        }
    };

    registerButtonPressed = () => {
        this.props.loadNoneSessionDependingData();
        this.props.registerOpen();
    };

    passwordChanged = (event) => {
        this.props.setPassword({
            password: event.target.value,
        });
    };

    render () {
        return (
            <SplitPaneLoginStateless
                eMailAddress={this.props.email}
                eMailAddressChanged={this.eMailAddressChanged}
                eMailError={this.props.emailError}
                password={this.props.password}
                passwordChanged={this.passwordChanged}
                passwordError={this.props.passwordError}
                loginButtonPressed={this.loginButtonPressed}
                lostPasswordButtonPressed={this.lostPasswordButtonPressed}
                registerButtonPressed={this.registerButtonPressed}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = SplitPaneLogin;

Component.propTypes = {
    email:         PropTypes.string,
    emailError:    PropTypes.string,
    password:      PropTypes.string,
    passwordError: PropTypes.string,
    token:         PropTypes.string,
};

Component.defaultProps = {
    email:         null,
    emailError:    null,
    password:      null,
    passwordError: null,
    token:         null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        alertBoxesTagged:      _.get(state, 'alertBox.alertBoxesTagged'),
        alertBoxesTaggedCount: _.get(state, 'alertBox.alertBoxesTaggedCount', 0),
        password:              _.get(state, 'user.user.password'),
        passwordError:         _.get(state, 'alertBox.alertBoxesTagged.password'),
        token:                 _.get(state, 'user.user.token'),
        email:                 _.get(state, 'user.user.email'),
        emailError:            _.get(state, 'alertBox.alertBoxesTagged.email'),
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    AlertBoxActions,
    UserActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);