//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                            from 'react';
import { connect }                      from 'react-redux';
import PropTypes                        from '../../PropTypes';
import _                                from 'lodash';
import { bindActionCreators }           from 'redux';
import { ActiveProjectActions }         from '../../../store/actions/activeProject';
import IconButton                       from '../../stateless/IconButton';
import IconType                         from '../../stateless/Icon/IconType';
import I18n                             from 'i18next';
import IconButtonTheme                  from '../../stateless/IconButton/IconButtonTheme';
import ContentItem                      from '../../stateless/ContentItem';
import ProjectSubMenuTypes              from '../../../constants/ProjectSubMenuTypes';
import ComponentHelper                  from '../../../helper/ComponentHelper';
import Normalize                        from '../../../helper/Normalize';
import CompanyTypes                     from '../../../constants/CompanyTypes';
import { CreateEditProjectActions }     from '../../../store/actions/createEditProject';
import DotListMenu                      from '../../stateless/DotListMenu';
import DotListMenuAlignment             from '../../stateless/DotListMenu/DotListMenuAlignment';
import DotListMenuTheme                 from '../../stateless/DotListMenu/DotListMenuTheme';
import DotListMenuItem                  from '../../stateless/DotListMenuItem';
import ProjectDetailsContentItemContent from '../../stateless/ProjectDetailsContentItemContent';
import ProjectStateTypes                from '../../../constants/ProjectStateTypes';

class ProjectContentItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contentItemOpen: true,
        };
    }

    deleteButtonPressed = () => {
        this.props.deleteProject({
            projectIri: this.props.project.iri,
        });
    };

    editButtonPressed = () => {
        this.props.editProject({
            project: this.props.project,
        });
    };

    briefingButtonPressed = () => {
        this.setActiveProject(ProjectSubMenuTypes.briefing);
    };

    matchesButtonPressed = () => {
        this.setActiveProject(ProjectSubMenuTypes.matches);
    };

    messagesButtonPressed = () => {
        this.setActiveProject(ProjectSubMenuTypes.messages);
    };

    offersButtonPressed = () => {
        this.setActiveProject(ProjectSubMenuTypes.offers);
    };

    render() {
        const project      = this.props.project;
        const ageRange     = _.get(project, 'targetGroupAgeRange', {});
        const budget       = _.get(project, 'budgetRange', {});
        const formats      = _.get(project, 'videoFormats', []);
        const type         = _.get(project, 'videoType.name', '');
        const projectState = _.get(project, 'state');
        const matchState   = _.get(project, 'bestMatchState');
        const isEditable   = projectState !== ProjectStateTypes.finished && projectState !== ProjectStateTypes.commissioned;

        return (
            <ContentItem
                content={
                    <ProjectDetailsContentItemContent
                        ageRangeFrom={ageRange.from}
                        ageRangeTo={ageRange.to}
                        budgetRangeInThousandsFrom={budget.from}
                        budgetRangeInThousandsTo={budget.to}
                        type={type}
                        projectState={projectState}
                        matchState={matchState}
                        formats={formats}
                        companyType={this.props.companyType}
                    />
                }
                company={_.get(project, 'company', null)}
                headerCenterContent={this.renderHeaderCenterContent()}
                headerRightContent={this.renderHeaderRightContent(isEditable)}
                title={project.title}
                subTitle={Normalize.videoTypeStringFromProject(project)}
                toggleContentButtonClicked={() => {
                    this.setState({
                        contentItemOpen: !this.state.contentItemOpen,
                    });
                }}
                toggleContentEnabled={true}
                toggleContentOpened={this.state.contentItemOpen}
            />
        );
    }

    renderDotListMenu = (isEditable) => {
        if (isEditable) {
            return (
                <DotListMenu
                    alignment={DotListMenuAlignment.right}
                    theme={DotListMenuTheme.big}
                >
                    <DotListMenuItem
                        onClick={this.editButtonPressed}
                        text={I18n.t('editProjectShort')}
                        theme={DotListMenuTheme.big}
                    />
                    <DotListMenuItem
                        onClick={this.deleteButtonPressed}
                        text={I18n.t('deleteProjectShort')}
                        theme={DotListMenuTheme.big}
                    />
                </DotListMenu>
            );
        }

        return null;
    };

    renderHeaderCenterContent = () => {
        switch (this.props.companyType) {
            // @formatter:off
            case CompanyTypes.company:         return this.renderHeaderCenterContentCompany();
            case CompanyTypes.videoProduction: return this.renderHeaderCenterContentVideoProduction();
            // @formatter:on
        }

        return null;
    }

    renderHeaderCenterContentCompany = () => {
        return null;
    };

    renderHeaderCenterContentVideoProduction = () => {
        return null;
    };

    renderHeaderRightContent = (isEditable) => {
        switch (this.props.companyType) {
            // @formatter:off
            case CompanyTypes.company:         return this.renderHeaderRightContentCompany(isEditable);
            case CompanyTypes.videoProduction: return this.renderHeaderRightContentVideoProduction(true);
            // @formatter:on
        }

        return null;
    };

    renderHeaderRightContentCompany = (isEditable) => {
        const isComplete = _.get(this.props, 'project.state') === ProjectStateTypes.complete;

        return (
            <>
                {this.renderBriefingButton()}
                {this.renderMatchesButton(isComplete)}
                {this.renderMessagesButton(isComplete)}
                {this.renderOffersButton(isComplete)}
                {this.renderDotListMenu(isEditable)}
            </>
        );
    };

    renderHeaderRightContentVideoProduction = (isComplete) => {
        return (
            <>
                {this.renderMatchButton()}
                {this.renderMessagesButton(isComplete)}
                {this.renderOffersButton(isComplete)}
            </>
        );
    };

    renderBriefingButton = () => {
        return (
            <IconButton
                iconType={IconType.file}
                onClick={this.briefingButtonPressed}
                text={I18n.t('briefing')}
                theme={IconButtonTheme.turquoiseGhost}
            />
        );
    };

    renderMatchButton = () => {
        if (this.props.companyType !== CompanyTypes.videoProduction) {
            return (
                <IconButton
                    iconType={IconType.zap}
                    onClick={this.matchesButtonPressed}
                    text={I18n.t('match')}
                    theme={IconButtonTheme.turquoiseGhost}
                />
            );
        }

        return null;
    };

    renderMessagesButton = (isComplete) => {
        if (isComplete) {
            return (
                <IconButton
                    iconType={IconType.messageSquare}
                    onClick={this.messagesButtonPressed}
                    text={I18n.t('messages')}
                    theme={IconButtonTheme.turquoiseGhost}
                />
            );
        }

        return null;
    };

    renderMatchesButton = (isComplete) => {
        if (isComplete) {
            return (
                <IconButton
                    iconType={IconType.zap}
                    onClick={this.matchesButtonPressed}
                    text={I18n.t('matches')}
                    theme={IconButtonTheme.turquoiseGhost}
                />
            );
        }

        return null;
    };

    renderOffersButton = (isComplete) => {
        if (isComplete) {
            return (
                <IconButton
                    iconType={IconType.fileText}
                    onClick={this.offersButtonPressed}
                    text={I18n.t('offers')}
                    theme={IconButtonTheme.turquoiseGhost}
                />
            );
        }

        return null;
    };

    setActiveProject = (subMenuType) => {
        this.props.setActiveProject({
            project: this.props.project,
            subMenuType,
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ProjectContentItem;

Component.propTypes = {
    companyType: PropTypes.string,
    project:     PropTypes.object,
};

Component.defaultProps = {
    companyType: null,
    project:     null,
};

Component.renderAffectingProps = [
    'project',
];

Component.renderAffectingStates = [
    'contentItemOpen',
];

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        ActiveProjectActions,
        CreateEditProjectActions,
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        companyType: _.get(state, 'company.ownCompany.companyType'),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
