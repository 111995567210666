//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import Icon            from '../Icon';
import PropTypes       from '../../PropTypes';
import ComponentHelper from '../../../helper/ComponentHelper';
import FileHelper      from '../../../helper/File';
import _               from 'lodash';
import IconType        from '../Icon/IconType';
import FileStates      from '../../../constants/FileStates';
import Cast            from '../../../helper/Cast';

class FileListEntry extends React.Component {
    render () {
        const name = _.get(this.props, 'data.name', '');

        return (
            <div
                className={styles.file}
                title={name}
            >
                {this.renderPreview()}
                <div className={styles.fileLeft}>
                    <span>
                        {name}
                    </span>
                </div>
                {this.renderAdditionalText()}
                {this.renderChildren()}
                {this.renderProgressBar()}
            </div>
        );
    }

    renderAdditionalText = () => {
        const additionalText = _.get(this.props, 'data.additionalText');

        if (additionalText) {
            return (
                <div className={styles.fileCenter}>
                    {additionalText}
                </div>
            );
        }

        return null;
    };

    renderChildren = () => {
        if (this.props.children) {
            return (
                <div className={styles.fileRight}>
                    {this.props.children}
                </div>
            );
        }

        return null;
    };

    renderPreview = () => {
        if (this.props.showPreview) {
            return (
                <div className={styles.filePreview}>
                    {this.renderPreviewContent()}
                </div>
            );
        }

        return null;
    };

    renderProgressBar = () => {
        if (this.props.state === FileStates.uploading) {
            return (
                <div className={styles.progressBarWrapper}>
                    <div
                        className={styles.progressBar}
                        style={{
                            width: Cast.percentString(this.props.progress),
                        }}
                    />
                </div>
            );
        }

        return null;
    };

    renderPreviewContent = () => {
        const downloadUrl = _.get(this, 'props.data.downloadUrl', false);

        if (downloadUrl && FileHelper.pathIsImage(downloadUrl)) {
            return this.renderPreviewImage();
        }

        return this.renderPreviewIcon();
    };

    renderPreviewIcon = () => {
        return (
            <Icon iconType={IconType.file} />
        );
    };

    renderPreviewImage = () => {
        const data = this.props.data;

        return (
            <div className={styles.filePreviewImageWrapper}>
                <img
                    alt={data.name}
                    src={data.downloadUrl}
                />
            </div>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = FileListEntry;

Component.propTypes = {
    children:    PropTypes.children,
    data:        PropTypes.fileListEntry,
    showPreview: PropTypes.bool,
};

Component.defaultProps = {
    children:    null,
    data:        null,
    showPreview: true,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;