//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                from 'react';
import ComponentHelper      from '../../../helper/ComponentHelper';
import I18n                 from 'i18next';
import LogoutUserMenuButton from '../../connected/LogoutUserMenuButton';
import PropTypes            from '../../PropTypes';
import styles               from './styles.module.scss';
import UserMenuAvatar       from '../UserMenuAvatar';
import UserMenuButton       from '../UserMenuButton';
import UserMenuButtonTheme  from '../UserMenuButton/UserMenuButtonTheme';
import Api                  from '../../../constants/Api';

class UserMenu extends React.Component {
    render() {
        return (
            <div className={styles.wrapper}>
                <UserMenuAvatar
                    company={this.props.company}
                    name={this.props.name}
                />
                {this.renderEditCompanyProfileButton()}
                {this.renderEditUserProfileButton()}
                <UserMenuButton
                    target={'_blank'}
                    text={I18n.t('help')}
                    theme={UserMenuButtonTheme.default}
                    tip={I18n.t('helpTip')}
                    to={Api.getAbsoluteWebsiteUrl(I18n.t('helpPath'))}
                />
                <LogoutUserMenuButton />
            </div>
        );
    }

    renderEditCompanyProfileButton = () => {
        if (this.props.ownCompanyRoute) {
            return (
                <UserMenuButton
                    text={I18n.t('editCompanyProfile')}
                    theme={UserMenuButtonTheme.default}
                    tip={I18n.t('editCompanyProfileTip')}
                    to={this.props.ownCompanyRoute}
                />
            );
        }

        return this.props.ownCompanyRoute;
    };

    renderEditUserProfileButton = () => {
        if (this.props.ownUserRoute) {
            return (
                <UserMenuButton
                    text={I18n.t('editAccountProfile')}
                    theme={UserMenuButtonTheme.default}
                    tip={I18n.t('editAccountProfileTip')}
                    to={this.props.ownUserRoute}
                />
            );
        }

        return this.props.ownUserRoute;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = UserMenu;

Component.propTypes = {
    company:         PropTypes.object,
    name:            PropTypes.string,
    ownCompanyRoute: PropTypes.string,
    ownUserRoute:    PropTypes.string,
};

Component.defaultProps = {
    company:         null,
    name:            '',
    ownCompanyRoute: '',
    ownUserRoute:    '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
