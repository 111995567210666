//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as Api                   from '../../api';
import _                          from 'lodash';
import ApiMode                    from '../../constants/ApiMode';
import axios                      from 'axios';
import Cast                       from '../../helper/Cast';
import HydraHelper                from '../../helper/Hydra';
import I18n                       from 'i18next';
import OverlayManager             from '../../components/connected/OverlayManager';
import Overlays                   from '../../constants/Overlays';
import ProjectSubMenuTypes        from '../../constants/ProjectSubMenuTypes';
import { ActiveProjectActions }   from '../actions/activeProject';
import { AlertBoxActions }        from '../actions/alertBox';
import { call }                   from 'redux-saga/effects';
import { cancelled }              from 'redux-saga/effects';
import { put }                    from 'redux-saga/effects';
import { select }                 from 'redux-saga/effects';
import { take }                   from 'redux-saga/effects';
import { CreateEditOfferActions } from '../actions/createEditOffer';
import { eventChannel }           from 'redux-saga';
import { push }                   from 'connected-react-router';

const uploadFileCancelToken       = axios.CancelToken;
const uploadFileCancelTokenSource = uploadFileCancelToken.source();

const addOffer = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.createOffer)));
};

const createOffer = function* (action) {
    const errorOccurred = yield call(createOrUpdateOffer, ApiMode.create, action);

    if (!errorOccurred) {
        yield put(CreateEditOfferActions.createOfferSuccess());
    } else {
        yield put(CreateEditOfferActions.createOfferFailed());
    }
};

const createOfferFailed = function* () {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('offerCreateError'),
    }));
};

const createOrUpdateOffer = function* (mode, action) {
    const activeProjectState   = yield select(state => state.activeProject);
    const createEditOfferState = yield select(state => state.createEditOffer);
    const activeProject        = activeProjectState.activeProject;
    const activeMatch          = activeProjectState.activeMatch;
    const offers               = createEditOfferState.offers;
    let errorOccurred          = false;

    if (createEditOfferState.matchFiles) {
        const matchAttachments = Api.getIriListFromUploadedFileList(createEditOfferState.matchFiles);

        const response = yield call(
            Api.updateMatchAttachments,
            activeMatch.iri,
            activeProject.iri,
            matchAttachments,
        );

        if (!response.ok) {
            errorOccurred = true;
        }
    }

    for (const offer of offers) {
        const offerAttachments = Api.getIriListFromUploadedFileList(offer.files);
        const postData         = {
            offerAttachments,
            title:          offer.title,
            message:        offer.message,
            project:        activeProject.iri,
            match:          activeMatch.iri,
            netPriceInEuro: Cast.float(offer.netPriceInEuro),
        };

        const response = (
            mode === ApiMode.create ?
                (
                    yield call(
                        Api.postOffer,
                        postData.title,
                        postData.message,
                        postData.project,
                        postData.match,
                        postData.netPriceInEuro,
                        postData.offerAttachments,
                    )
                ) :
                (
                    yield call(
                        Api.updateOffer,
                        offer.iri,
                        postData.title,
                        postData.message,
                        postData.project,
                        postData.match,
                        postData.netPriceInEuro,
                        postData.offerAttachments,
                    )
                )
        );

        if (!response.ok) {
            errorOccurred = true;
        }
    }

    return errorOccurred;
};

const createOfferSuccess = function* (action) {
    yield call(createOrUpdateOfferSuccess, action, 'createOfferSuccessText');
};

const createOrUpdateOfferSuccess = function* (action, successMessageKey) {
    const activeProjectState = yield select(state => state.activeProject);
    const activeProject      = activeProjectState.activeProject;
    const activeMatch        = activeProjectState.activeMatch;

    yield put(AlertBoxActions.clearAlerts());
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t(successMessageKey),
    }));
    yield put(ActiveProjectActions.setActiveProject({
        activeMatch: activeMatch,
        project:     activeProject,
        subMenuType: ProjectSubMenuTypes.offers,
    }));
};

const editOffer = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.createOffer)));
};

// TODO: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-301
function getOfferUploadFileInternalResponse(action, complete, error, progress, iri) {
    const response = {
        complete,
        error,
        id:         action.id,
        iri,
        offerIndex: action.offerIndex,
        progress,
    };

    return response;
}

// TODO: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-301
function getMatchUploadFileInternalResponse(action, complete, error, progress, iri) {
    const response = {
        complete,
        error,
        id: action.id,
        iri,
        progress,
    };

    return response;
}

const updateOffer = function* (action) {
    const errorOccurred = yield call(createOrUpdateOffer, ApiMode.edit, action);

    if (!errorOccurred) {
        yield put(CreateEditOfferActions.updateOfferSuccess());
    } else {
        yield put(CreateEditOfferActions.updateOfferFailed());
    }
};

const updateOfferFailed = function* () {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('offerUpdateError'),
    }));
};

const updateOfferSuccess = function* (action) {
    yield call(createOrUpdateOfferSuccess, action, 'updateOfferSuccessText');
};

function* uploadOfferFileInternal(action) {
    // We clone the action here to allow parallel file uploads
    const clonedAction = _.cloneDeep(action);

    return eventChannel((emitter) => {
        console.log('uploadFileInternal', clonedAction);

        const uploadPromise = Api.uploadOfferAttachment(
            clonedAction.file,
            (uploadState) => {
                const progress = uploadState.loaded / uploadState.total * 100;

                console.log('uploadFileInternal: uploadState', uploadState);

                emitter(getOfferUploadFileInternalResponse(
                    clonedAction,
                    false,
                    false,
                    progress,
                    null,
                ));
            },
        );

        uploadPromise.then((response) => {
            console.log('uploadFileInternal: uploadPromise: then', response);

            if (response.ok) {
                const cleanHydraResponse = HydraHelper.cleanupObject(response.data);

                emitter(getOfferUploadFileInternalResponse(
                    clonedAction,
                    true,
                    false,
                    100,
                    cleanHydraResponse.iri,
                ));
            } else {
                emitter(getOfferUploadFileInternalResponse(
                    clonedAction,
                    true,
                    true,
                    100,
                    null,
                ));
            }
        });

        return () => {
            uploadFileCancelTokenSource.cancel();
        };
    });
}

function* uploadMatchFileInternal(action) {
    // We clone the action here to allow parallel file uploads
    const clonedAction = _.cloneDeep(action);

    return eventChannel((emitter) => {
        console.log('uploadFileInternal', clonedAction);

        const uploadPromise = Api.uploadMatchAttachment(
            clonedAction.file,
            (uploadState) => {
                const progress = uploadState.loaded / uploadState.total * 100;

                console.log('uploadFileInternal: uploadState', uploadState);

                emitter(getMatchUploadFileInternalResponse(
                    clonedAction,
                    false,
                    false,
                    progress,
                    null,
                ));
            },
        );

        uploadPromise.then((response) => {
            console.log('uploadFileInternal: uploadPromise: then', response);

            if (response.ok) {
                const cleanHydraResponse = HydraHelper.cleanupObject(response.data);

                emitter(getMatchUploadFileInternalResponse(
                    clonedAction,
                    true,
                    false,
                    100,
                    cleanHydraResponse.iri,
                ));
            } else {
                emitter(getMatchUploadFileInternalResponse(
                    clonedAction,
                    true,
                    true,
                    100,
                    null,
                ));
            }
        });

        return () => {
            uploadFileCancelTokenSource.cancel();
        };
    });
}

const rehydrate = function* () {
    yield put(CreateEditOfferActions.clearUnfinishedUploads());
};

const uploadOfferFile = function* (action) {
    console.log('uploadFile: start', action);

    const uploadChannel = yield call(uploadOfferFileInternal, action);

    try {
        while (true) {
            const uploadProgressData = yield take(uploadChannel);

            console.log('uploadProgressData', uploadProgressData);

            yield put(CreateEditOfferActions.updateOfferUploadProgress({
                id:         uploadProgressData.id,
                offerIndex: uploadProgressData.offerIndex,
                progress:   uploadProgressData.progress,
            }));

            if (uploadProgressData.complete) {
                if (uploadProgressData.error) {
                    yield put(CreateEditOfferActions.uploadOfferFileFailed({
                        id:         uploadProgressData.id,
                        offerIndex: uploadProgressData.offerIndex,
                    }));
                } else {
                    yield put(CreateEditOfferActions.uploadOfferFileSucceeded({
                        id:         uploadProgressData.id,
                        iri:        uploadProgressData.iri,
                        offerIndex: uploadProgressData.offerIndex,
                    }));
                }
            }
        }
    } finally {
        if (yield cancelled()) {
            uploadChannel.close();
        }
    }
};

const uploadOfferFileFailed = function* (action) {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('uploadFileError1'),
    }));
};

const uploadMatchFile = function* (action) {
    console.log('uploadFile: start', action);

    const uploadChannel = yield call(uploadMatchFileInternal, action);

    try {
        while (true) {
            const uploadProgressData = yield take(uploadChannel);

            console.log('uploadProgressData', uploadProgressData);

            yield put(CreateEditOfferActions.updateMatchUploadProgress({
                id:       uploadProgressData.id,
                progress: uploadProgressData.progress,
            }));

            if (uploadProgressData.complete) {
                if (uploadProgressData.error) {
                    yield put(CreateEditOfferActions.uploadMatchFileFailed({
                        id: uploadProgressData.id,
                    }));
                } else {
                    yield put(CreateEditOfferActions.uploadMatchFileSucceeded({
                        id:  uploadProgressData.id,
                        iri: uploadProgressData.iri,
                    }));
                }
            }
        }
    } finally {
        if (yield cancelled()) {
            uploadChannel.close();
        }
    }
};

const uploadMatchFileFailed = function* (action) {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('uploadFileError1'),
    }));
};

export default {
    addOffer,
    createOffer,
    createOfferFailed,
    createOfferSuccess,
    editOffer,
    rehydrate,
    updateOffer,
    updateOfferFailed,
    updateOfferSuccess,
    uploadOfferFile,
    uploadOfferFileFailed,
    uploadMatchFile,
    uploadMatchFileFailed,
};
