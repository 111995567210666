//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                      from 'react';
import * as Api                   from '../../../api';
import _                          from 'lodash';
import CompanyTypes               from '../../../constants/CompanyTypes';
import ComponentHelper            from '../../../helper/ComponentHelper';
import FileList                   from '../../stateless/FileList';
import FileListMode               from '../../stateless/FileList/FileListMode';
import I18n                       from 'i18next';
import IconButton                 from '../../stateless/IconButton';
import IconButtonTheme            from '../../stateless/IconButton/IconButtonTheme';
import IconType                   from '../../stateless/Icon/IconType';
import PropTypes                  from '../../PropTypes';
import Routes                     from '../../../constants/Routes';
import { ActiveProjectActions }   from '../../../store/actions/activeProject';
import { bindActionCreators }     from 'redux';
import { connect }                from 'react-redux';
import { CreateEditOfferActions } from '../../../store/actions/createEditOffer';

class Component extends React.Component {
    acceptOfferButtonPressed = () => {
        this.props.setActiveOffer({
            offer: this.props.offer,
        });
        this.props.openAcceptOfferOverlay();
    };

    getOfferFiles = () => {
        const offerFiles       = [];
        const offerAttachments = _.get(this.props.offer, 'offerAttachments', []);

        for (const offerAttachment of offerAttachments) {
            let additionalText = '';

            if (offerFiles.length === 0) {
                // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-216
                // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-219
                additionalText = this.props.offer.netPriceInEuro + ' ' + I18n.t('euroNet');
            }

            offerFiles.push({
                additionalText: additionalText,
                downloadUrl:    Api.getFilePath(offerAttachment.path),
                name:           offerAttachment.name,
            });
        }

        return offerFiles;
    };

    getOfferAcceptButtonText = () => {
        if (!this.props.hasAcceptedOffer) {
            return I18n.t(
                (
                    this.props.offerNumber ?
                        'offerAcceptN' :
                        'offerAccept'
                ),
                {
                    offerNumber: this.props.offerNumber,
                },
            );
        }

        if (this.props.isAcceptedOffer) {
            return I18n.t('offerAccepted');
        }

        return I18n.t('otherOfferAccepted');
    };

    ownCompanyIsCompany = () => {
        return this.props.companyType === CompanyTypes.company;
    };

    render() {
        const offerFiles = this.getOfferFiles();

        return (
            <FileList
                key={'offer_' + this.props.offerNumber}
                files={offerFiles}
                footerContent={this.renderFooterContent()}
                mode={FileListMode.download}
                title={this.props.offer.title}
            />
        );
    }

    renderFooterContent = () => {
        const offerAcceptOfferButtonText = this.getOfferAcceptButtonText();

        if (this.ownCompanyIsCompany() && !this.props.hasAcceptedOffer) {
            return (
                <IconButton
                    onClick={this.acceptOfferButtonPressed}
                    iconType={IconType.check}
                    text={offerAcceptOfferButtonText}
                    theme={IconButtonTheme.turquoiseGhost}
                />
            );
        }

        if (this.props.hasAcceptedOffer) {
            return (
                <IconButton
                    disabled={true}
                    iconType={this.props.isAcceptedOffer ? IconType.check : null}
                    text={offerAcceptOfferButtonText}
                    theme={IconButtonTheme.turquoiseGhost}
                />
            );
        }

        if (this.props.editOfferButtonPressed) {
            return (
                <IconButton
                    iconType={IconType.edit}
                    onClick={() => {
                        this.props.editOfferButtonPressed(this.props.offer);
                    }}
                    text={I18n.t('offerEdit')}
                    theme={IconButtonTheme.turquoiseGhost}
                    to={Routes.screenDesign}
                />
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {
    editOfferButtonPressed: PropTypes.func,
    hasAcceptedOffer:       PropTypes.bool,
    isAcceptedOffer:        PropTypes.bool,
    offer:                  PropTypes.object,
    offerNumber:            PropTypes.number,
};

Component.defaultProps = {
    editOfferButtonPressed: null,
    hasAcceptedOffer:       false,
    isAcceptedOffer:        false,
    offer:                  null,
    offerNumber:            0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        ActiveProjectActions,
        CreateEditOfferActions,
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        companyType: _.get(state, 'company.ownCompany.companyType'),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
