//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update                 from 'immutability-helper';
import { ActiveProjectTypes } from '../actions/activeProject';
import _                      from 'lodash';
import moment                 from 'moment';
import { UserTypes }          from '../actions/user';

const initialState = {
    activeMatch:                   null,
    activeMatchesTabIndex:         0,
    activeProject:                 null,
    matches:                       [],
    matchesByLastMessageTimestamp: [],
    matchesByLastOfferTimestamp:   [],
    messages:                      [],
    offers:                        [],
    subMenuType:                   null,
};

const fetchMatchesSuccess = (action, state) => {
    const matchesByLastMessageTimestamp = _.sortBy(
        action.matches,
        [
            function (match) {
                return moment(match.lastMessageTimestamp);
            },
        ],
    ).reverse();

    const matchesByLastOfferTimestamp = _.sortBy(
        action.matches,
        [
            function (match) {
                return moment(match.lastOfferTimestamp);
            },
        ],
    ).reverse();

    const matchesStateChangeDescription = {
        matches:                       {
            $set: action.matches,
        },
        matchesByLastMessageTimestamp: {
            $set: matchesByLastMessageTimestamp,
        },
        matchesByLastOfferTimestamp:   {
            $set: matchesByLastOfferTimestamp,
        },
    };

    if (matchesByLastMessageTimestamp.length > 0) {
        let matchToSelect = null;

        if (action.activeMatchIri) {
            // Select the latest match
            matchToSelect = _.find(
                matchesByLastMessageTimestamp,
                {
                    iri: action.activeMatchIri,
                },
            );
        }

        if (!matchToSelect) {
            // Select the latest match
            matchToSelect = matchesByLastMessageTimestamp[0];
        }

        if (matchToSelect) {
            matchesStateChangeDescription.activeMatch = {
                $set: matchToSelect,
            };
        }
    }

    return update(state, matchesStateChangeDescription);
};

const fetchMessageSuccess = (action, state) => {
    return update(state, {
        messages: {
            $set: action.messages,
        },
    });
};

const fetchOffersSuccess = (action, state) => {
    return update(state, {
        offers: {
            $set: action.offers,
        },
    });
};

const logout = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

const setActiveMatch = (action, state) => {
    return update(state, {
        activeMatch: {
            $set: action.match,
        },
        messages:    {
            $set: initialState.messages,
        },
        offers:      {
            $set: initialState.offers,
        },
    });
};

const setActiveOffer = (action, state) => {
    return update(state, {
        activeOffer: {
            $set: action.offer,
        },
    });
};

const setActiveProject = (action, state) => {
    return update(state, {
        activeMatch:   {
            $set: initialState.activeMatch,
        },
        activeProject: {
            $set: action.project,
        },
        messages:      {
            $set: initialState.messages,
        },
        offers:        {
            $set: initialState.offers,
        },
        subMenuType:   {
            $set: action.subMenuType,
        },
    });
};

const setMatchesActiveTab = (action, state) => {
    return update(state, {
        activeMatchesTabIndex: {
            $set: action.tabIndex,
        },
    });
};

export default function (state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case ActiveProjectTypes.FETCH_MATCHES_SUCCESS:           return fetchMatchesSuccess(action, state);
        case ActiveProjectTypes.FETCH_MESSAGES_SUCCESS:          return fetchMessageSuccess(action, state);
        case ActiveProjectTypes.FETCH_OFFERS_SUCCESS:            return fetchOffersSuccess(action, state);
        case ActiveProjectTypes.SET_ACTIVE_MATCH:                return setActiveMatch(action, state);
        case ActiveProjectTypes.SET_ACTIVE_OFFER:                return setActiveOffer(action, state);
        case ActiveProjectTypes.SET_ACTIVE_PROJECT:              return setActiveProject(action, state);
        case ActiveProjectTypes.SET_MATCHES_ACTIVE_TAB:          return setMatchesActiveTab(action, state);
        case UserTypes.LOGOUT:                                   return logout(action, state);
        default:                                                 return state;
        // @formatter:on
    }
}