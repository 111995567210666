//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                from 'react';
import Overlay              from '../../connected/Overlay';
import OverlayButton        from '../OverlayButton';
import OverlayButtonTheme   from '../OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper from '../OverlayButtonWrapper';
import OverlayPane          from '../OverlayPane';
import OverlayPaneState     from '../OverlayPane/OverlayPaneState';
import Overlays             from '../../../constants/Overlays';
import { withTranslation }  from 'react-i18next';

class Component extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            debugState: 0,
        };
    }

    backButtonPressed = () => {
        let previousDebugState = this.state.debugState - 1;

        if (previousDebugState < 0) {
            previousDebugState = 0;
        }

        this.setState({
            debugState: previousDebugState,
        });
    };

    continueButtonPressed = () => {
        let nextDebugState = this.state.debugState + 1;

        if (nextDebugState > 3) {
            nextDebugState = 0;
        }

        this.setState({
            debugState: nextDebugState,
        });
    };

    getOverlayPaneStateForIndex = (index) => {
        if (index < this.state.debugState) {
            return OverlayPaneState.done;
        } else if (index === this.state.debugState) {
            return OverlayPaneState.open;
        }

        return OverlayPaneState.upcoming;
    };

    render () {
        return (
            <Overlay
                id={Overlays.screenDesign}
                progress={this.state.debugState * 20}
                showProgressBar={true}
                title={'Screndesign'}
            >
                <OverlayPane
                    content={this.renderOverlay1Content()}
                    footer={this.renderOverlay1Footer()}
                    title={'Test'}
                    scrollingAllowed={true}
                    state={this.getOverlayPaneStateForIndex(0)}
                    upcomingDistance={0 - this.state.debugState}
                />
                <OverlayPane
                    content={this.renderOverlay2Content()}
                    footer={this.renderOverlay2Footer()}
                    title={'Timings'}
                    scrollingAllowed={true}
                    state={this.getOverlayPaneStateForIndex(1)}
                    upcomingDistance={1 - this.state.debugState}
                />
                <OverlayPane
                    content={this.renderOverlay3Content()}
                    footer={this.renderOverlay3Footer()}
                    title={'Registrieren'}
                    scrollingAllowed={true}
                    state={this.getOverlayPaneStateForIndex(2)}
                    upcomingDistance={2 - this.state.debugState}
                />
                <OverlayPane
                    content={this.renderOverlay4Content()}
                    footer={this.renderOverlay4Footer()}
                    title={'Budget'}
                    scrollingAllowed={true}
                    state={this.getOverlayPaneStateForIndex(3)}
                    upcomingDistance={3 - this.state.debugState}
                />
            </Overlay>
        );
    }

    renderOverlay1Content = () => {
        return (
            <div>
                gsdgsd
                <br />
                gsdgsd
            </div>
        );
    };

    renderOverlay1Footer = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.continueButtonPressed}
                    standAlone={false}
                    text={'Weiter'}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    renderOverlay2Content = () => {
        return (
            <div>
                gsdgsd
                <br />
                gsdgsd
                <br />
                gsdgsd
                <br />
                gsdgsd
                <br />
                gsdgsd
                <br />
                gsdgsd
            </div>
        );
    };

    renderOverlay2Footer = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.backButtonPressed}
                    standAlone={false}
                    text={'Zurück'}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.continueButtonPressed}
                    standAlone={false}
                    text={'Weiter'}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    renderOverlay3Content = () => {
        return (
            <div>
                gsdgsd
                <br />
                gsdgsd
                <br />
                gsdgsd
                <br />
                gsdgsd
                <br />
                gsdgsd
                <br />
                gsdgsd
                gsdgsd
                <br />
                gsdgsd
                <br />
                gsdgsd
                <br />
                gsdgsd
            </div>
        );
    };

    renderOverlay3Footer = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.backButtonPressed}
                    standAlone={false}
                    text={'Zurück'}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.continueButtonPressed}
                    standAlone={false}
                    text={'Weiter'}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    renderOverlay4Content = () => {
        return (
            <div>
                gsdgsd
                <br />
                gsdgsd
                <br />
                gsdgsd
                <br />
                gsdgsd
                <br />
                gsdgsd
                <br />
                gsdgsd
            </div>
        );
    };

    renderOverlay4Footer = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.backButtonPressed}
                    standAlone={false}
                    text={'Zurück'}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.continueButtonPressed}
                    standAlone={false}
                    text={'Weiter'}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };
}

Component.propTypes = {};

Component.defaultProps = {};

export default withTranslation()(Component);
