//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as ApiUrls    from '../constants/Api';
import _               from 'lodash';
import FileStates      from '../constants/FileStates';
import Hydra           from '../helper/Hydra';
import MatchStateTypes from '../constants/MatchStateTypes';
import { create }      from 'apisauce';

const contentTypes = {
    applicationJSON:   'application/json',
    applicationLDJSON: 'application/ld+json',
};

const createInstance = (host, apiPath) => {
    const api = create({
        baseURL: `${host}${apiPath}`,
        timeout: 180000,
        headers: {
            'Content-Type': contentTypes.applicationJSON,
            'accept':       contentTypes.applicationLDJSON,
        },
    });

    api.host = host;

    api.addResponseTransform((response) => {
        if (response.ok) {
            response.data = Hydra.cleanupObject(response.data);
        } else {
            console.warn('Api-Warning:', response.problem);
        }

        return response;
    });

    return api;
};

const Api = createInstance(ApiUrls.BACKEND_URL, '/');

const acceptOffer = (offerIri, matchIri) => {
    return Api.put(
        offerIri + '?match=' + matchIri,
        {
            offerState: MatchStateTypes.accepted,
        },
    );
};

const createCompany = (data) => {
    return Api.post(
        ApiUrls.API_COMPANY_URL,
        data,
    );
};

const createUser = (email, firstname, name, plainPassword) => {
    return Api.post(
        ApiUrls.API_USERS_URL,
        {
            email,
            firstname,
            name,
            plainPassword,
        },
    );
};

const deleteCompanyImages = (companyImageIri) => {
    return Api.delete(
        companyImageIri,
    );
};

const deleteLocation = (locationIri) => {
    return Api.delete(
        locationIri,
    );
};

const deleteProject = (projectIri) => {
    return Api.delete(
        projectIri,
    );
};

const deleteReference = (referenceIri) => {
    return Api.delete(
        referenceIri,
    );
};

const deleteSocialMedia = (socialMediaIri) => {
    return Api.delete(
        socialMediaIri,
    );
};

const fetchBranches = () => {
    return Api.get(ApiUrls.API_BRANCHES_URL);
};

const fetchCompany = (companyIri) => {
    const companyIriPrefix = '/api/companies/';

    if (!companyIri.startsWith(companyIriPrefix)) {
        companyIri = companyIriPrefix + companyIri;
    }

    return Api.get(companyIri);
};

const fetchCompanyPositions = () => {
    return Api.get(ApiUrls.API_COMPANY_POSITIONS_URL);
};

const fetchCompetences = () => {
    return Api.get(ApiUrls.API_COMPETENCES_URL);
};

const fetchConfiguration = () => {
    return Api.get(ApiUrls.API_CONFIGURATION_URL);
};

const fetchGoals = () => {
    return Api.get(ApiUrls.API_GOAL_URL);
};

const fetchLanguages = () => {
    return Api.get(ApiUrls.API_LANGUAGES_URL);
};

const fetchLicenseDurations = () => {
    return Api.get(ApiUrls.API_LICENSE_DURATIONS_URL);
};

const fetchMatches = (projectId) => {
    return Api.get(
        ApiUrls.API_MATCHES_URL,
        {
            project: projectId,
        },
    );
};

const fetchMessages = (matchId) => {
    return Api.get(
        ApiUrls.API_MESSAGES_URL,
        {
            match: matchId,
        },
    );
};

const fetchOffers = (matchId) => {
    return Api.get(
        ApiUrls.API_OFFERS_URL,
        {
            match: matchId,
        },
    );
};

const fetchPlaceOfWorks = () => {
    return Api.get(
        ApiUrls.API_PLACE_OF_WORK_URL,
    );
};

const fetchProject = (projectIri) => {
    return Api.get(
        projectIri,
    );
};

const fetchProjects = () => {
    return Api.get(ApiUrls.API_PROJECTS_URL);
};

const fetchTerritories = () => {
    return Api.get(ApiUrls.API_TERRITORIES_URL);
};

const fetchUser = () => {
    return Api.get(ApiUrls.API_USERS_URL);
};

const fetchVideoFormats = () => {
    return Api.get(ApiUrls.API_VIDEO_FORMATS_URL);
};

const fetchVideoTypes = () => {
    return Api.get(ApiUrls.API_VIDEO_TYPES_URL);
};

const getFileUploadObjectFromApiFileObject = (apiObject) => {
    const fileUploadObject = {
        id:       apiObject.id,
        name:     apiObject.name,
        size:     apiObject.size, // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-307
        state:    FileStates.uploaded,
        progress: 100,
        iri:      apiObject.iri,
    };

    return fileUploadObject;
};

const getFileUploadObjectListFromApiFileObjectList = (apiObjectList) => {
    if (apiObjectList && apiObjectList.length) {
        const fileUploadObjectList = {};

        for (const apiObject of apiObjectList) {
            fileUploadObjectList[apiObject.id] = getFileUploadObjectFromApiFileObject(apiObject);
        }

        return fileUploadObjectList;
    }

    return {};
};

const getImagePath = (path) => {
    return getFilePath(path);
};

const getIriListFromUploadedFileList = (uploadedFileList) => {
    if (_.isObject(uploadedFileList)) {
        uploadedFileList = Object.values(uploadedFileList);
    }

    const iriList = [];

    for (const uploadedFile of uploadedFileList) {
        const iri = uploadedFile.iri;

        if (iri) {
            iriList.push(iri);
        }
    }

    return iriList;
};

const getFilePath = (path, fallback) => {
    if (path) {
        if (
            path.indexOf('https://') === -1 &&
            path.indexOf('http://') === -1
        ) {
            return ApiUrls.BACKEND_URL + path;
        }

        return path;
    }

    return fallback;
};

const login = (
    email,
    password,
) => {
    return Api.post(
        ApiUrls.API_TOKENS,
        {
            email,
            password,
        },
    );
};

const postCallbackRequest = (
    companyIri,
    companyName,
    eMailAddress,
    name,
    phoneNumber,
    timeType,
) => {
    return Api.post(
        ApiUrls.API_CALLBACK_REQUEST_URL,
        {
            company: companyIri,
            companyName,
            eMailAddress,
            name,
            phoneNumber,
            timeType,
        },
    );
};

const postLocation = (
    companyIri,
    address,
) => {
    return Api.post(
        ApiUrls.API_LOCATION_URL,
        {
            company: companyIri,
            address: address,
        },
    );
};

const postMessage = (
    message,
    receiverCompanyIri,
    matchIri,
    projectIri,
    messageAttachmentIds,
) => {
    return Api.post(
        ApiUrls.API_MESSAGES_URL + '?project=' + projectIri,
        {
            message,
            receiverCompany:    receiverCompanyIri,
            match:              matchIri,
            messageAttachments: messageAttachmentIds,
        },
    );
};

const postOffer = (
    title,
    message,
    projectIri,
    match,
    netPriceInEuro,
    offerAttachments,
) => {
    return Api.post(
        ApiUrls.API_OFFERS_URL + '?project=' + projectIri,
        {
            title,
            message,
            project: projectIri,
            match,
            netPriceInEuro,
            offerAttachments,
        },
    );
};

// https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-310
const postProject = (
    title,
    description,
    videoType,
    goal,
    goalAlternativeText,
    branch,
    branchAlternativeText,
    targetGroupAgeRangeFrom,
    targetGroupAgeRangeTo,
    targetGroupType,
    videoWillBePublished,
    videoFormats,
    territory,
    territoryAlternativeText,
    licenseDuration,
    licenseDurationAlternativeText,
    budgetRangeFrom,
    budgetRangeTo,
    advertisingBudgetPlanned,
    advertisingBudgetRangeFrom,
    advertisingBudgetRangeTo,
    wishCompletionDate,
    fixedReleaseDate,
    fixedReleaseDateIsSameAsWishCompletionDate,
    wishedProductionPeriodStart,
    wishedProductionPeriodEnd,
) => {
    return Api.post(
        ApiUrls.API_PROJECTS_URL,
        {
            title,
            description,
            videoType,
            goal,
            goalAlternativeText,
            branch,
            branchAlternativeText,
            targetGroupAgeRange:    {
                from: targetGroupAgeRangeFrom,
                to:   targetGroupAgeRangeTo,
            },
            targetGroupType,
            videoWillBePublished,
            videoFormats,
            territory,
            territoryAlternativeText,
            licenseDuration,
            licenseDurationAlternativeText,
            budgetRange:            {
                from: budgetRangeFrom,
                to:   budgetRangeTo,
            },
            advertisingBudgetPlanned,
            advertisingBudgetRange: {
                from: advertisingBudgetRangeFrom,
                to:   advertisingBudgetRangeTo,
            },
            wishCompletionDate,
            fixedReleaseDate,
            fixedReleaseDateIsSameAsWishCompletionDate,
            wishedProductionPeriodStart,
            wishedProductionPeriodEnd,
        },
    );
};

const postReference = (companyIri, videoUrl, index) => {
    return Api.post(
        ApiUrls.API_REFERENCES_URL,
        {
            company:        companyIri,
            videoUrl:       videoUrl,
            referenceOrder: index,
        },
    );
};

const postSocialMedia = (companyIri, url) => {
    return Api.post(
        ApiUrls.API_SOCIAL_MEDIA_URL,
        {
            company: companyIri,
            url:     url,
        },
    );
};

const removeToken = () => {
    delete Api.headers['Authorization'];
};

const resetPassword = (email) => {
    return Api.post(
        ApiUrls.API_USERS_RESET_PASSWORD_URL,
        {
            email,
        },
    );
};

const verifyRegistration = (confirmationToken) => {
    return Api.post(
        ApiUrls.API_USERS_VERIFY_REGISTRATION,
        {
            confirmationToken,
        },
    );
};

const setNewPassword = (password, token) => {
    return Api.post(
        ApiUrls.API_USERS_SET_NEW_PASSWORD_URL,
        {
            password,
            token,
        },
    );
};

const updateCompany = (
    companyIri,
    data,
) => {
    return Api.put(
        companyIri,
        data,
    );
};

const updateCompanyImage = (
    imageIri,
    companyIri,
) => {
    return Api.put(
        imageIri,
        {
            company: companyIri,
        },
    );
};

const updateLocation = (locationIri, location) => {
    return Api.put(
        locationIri,
        location,
    );
};

const updateOffer = (
    offerIri,
    title,
    message,
    projectIri,
    matchIri,
    netPriceInEuro,
    offerAttachments,
) => {
    return Api.put(
        offerIri + '?match=' + matchIri + '&project=' + projectIri,
        {
            title,
            message,
            project: projectIri,
            match:   matchIri,
            netPriceInEuro,
            offerAttachments,
        },
    );
};

// https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-310
const updateProject = (
    projectIri,
    title,
    description,
    videoType,
    goal,
    goalAlternativeText,
    branch,
    branchAlternativeText,
    targetGroupAgeRangeFrom,
    targetGroupAgeRangeTo,
    targetGroupType,
    videoWillBePublished,
    videoFormats,
    territory,
    territoryAlternativeText,
    licenseDuration,
    licenseDurationAlternativeText,
    budgetRangeFrom,
    budgetRangeTo,
    advertisingBudgetPlanned,
    advertisingBudgetRangeFrom,
    advertisingBudgetRangeTo,
    wishCompletionDate,
    fixedReleaseDate,
    fixedReleaseDateIsSameAsWishCompletionDate,
    wishedProductionPeriodStart,
    wishedProductionPeriodEnd,
) => {
    return Api.put(
        projectIri,
        {
            title,
            description,
            videoType,
            goal,
            goalAlternativeText,
            branch,
            branchAlternativeText,
            targetGroupAgeRange:    {
                from: targetGroupAgeRangeFrom,
                to:   targetGroupAgeRangeTo,
            },
            targetGroupType,
            videoWillBePublished,
            videoFormats,
            territory,
            territoryAlternativeText,
            licenseDuration,
            licenseDurationAlternativeText,
            budgetRange:            {
                from: budgetRangeFrom,
                to:   budgetRangeTo,
            },
            advertisingBudgetPlanned,
            advertisingBudgetRange: {
                from: advertisingBudgetRangeFrom,
                to:   advertisingBudgetRangeTo,
            },
            wishCompletionDate,
            fixedReleaseDate,
            fixedReleaseDateIsSameAsWishCompletionDate,
            wishedProductionPeriodStart,
            wishedProductionPeriodEnd,
        },
    );
};

const updateReference = (referenceIri, videoUrl, index) => {
    return Api.put(
        referenceIri,
        {
            videoUrl,
            referenceOrder: index,
        },
    );
};

const updateSocialMedia = (socialMediaIri, socialMedia) => {
    return Api.put(
        socialMediaIri,
        socialMedia,
    );
};

const updateUser = (userIri, email, firstname, name) => {
    return Api.put(
        userIri,
        {
            email,
            firstname,
            name,
        },
    );
};

const updateUserPassword = (userIri, oldPassword, newPassword) => {
    return Api.put(
        userIri + '/password',
        {
            oldPassword,
            newPassword,
        },
    );
};

const uploadCompanyBanner = (file, onUploadProgressCallback) => {
    const formData = new FormData();

    formData.append('file', file);

    const response = Api.post(
        ApiUrls.API_COMPANY_BANNER_URL + '?' + Math.random(),
        formData,
        {
            onUploadProgress: onUploadProgressCallback,
        },
    );

    console.log('uploadCompanyBanner: response', response);

    return response;
};

const uploadCompanyImage = (file, name, onUploadProgressCallback) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('name', name);

    const response = Api.post(
        ApiUrls.API_COMPANY_IMAGE_URL + '?' + Math.random(),
        formData,
        {
            onUploadProgress: onUploadProgressCallback,
        },
    );

    console.log('uploadCompanyImage: response', response);

    return response;
};

const uploadCompanyLogo = (file, onUploadProgressCallback) => {
    const formData = new FormData();

    formData.append('file', file);

    const response = Api.post(
        ApiUrls.API_COMPANY_LOGO_URL + '?' + Math.random(),
        formData,
        {
            onUploadProgress: onUploadProgressCallback,
        },
    );

    console.log('uploadCompanyLogo: response', response);

    return response;
};

const updateMatchAttachments = (
    matchIri,
    projectIri,
    matchAttachments,
) => {
    return Api.put(
        matchIri + '?project=' + projectIri,
        {
            matchAttachments,
        },
    );
};

const updateMatchOfferingCompanyInteracted = (
    matchIri,
    projectIri,
    interacted,
) => {
    return Api.put(
        matchIri + '?project=' + projectIri,
        {
            offeringCompanyInteracted: interacted,
        },
    );
};

const updateMatchSearchingCompanyInteracted = (
    matchIri,
    projectIri,
    interacted,
) => {
    return Api.put(
        matchIri + '?project=' + projectIri,
        {
            searchingCompanyInteracted: interacted,
        },
    );
};

const setToken = (token) => {
    console.log('API: setToken', token);

    if (token) {
        Api.setHeader('Authorization', 'Bearer ' + token);
    } else {
        removeToken();
    }
};

const uploadOfferAttachment = (file, onUploadProgressCallback) => {
    const formData = new FormData();

    formData.append('file', file);

    const response = Api.post(
        ApiUrls.API_OFFER_ATTACHMENTS_URL + '?' + Math.random(),
        formData,
        {
            onUploadProgress: onUploadProgressCallback,
        },
    );

    console.log('uploadOfferAttachment: response', response);

    return response;
};

const uploadMatchAttachment = (file, onUploadProgressCallback) => {
    const formData = new FormData();

    formData.append('file', file);

    const response = Api.post(
        ApiUrls.API_MATCH_ATTACHMENTS_URL + '?' + Math.random(),
        formData,
        {
            onUploadProgress: onUploadProgressCallback,
        },
    );

    console.log('uploadMatchAttachment: response', response);

    return response;
};

const uploadMessageAttachment = (file, onUploadProgressCallback) => {
    const formData = new FormData();

    formData.append('file', file);

    const response = Api.post(
        ApiUrls.API_MESSAGE_ATTACHMENTS_URL + '?' + Math.random(),
        formData,
        {
            onUploadProgress: onUploadProgressCallback,
        },
    );

    console.log('uploadMessageAttachment: response', response);

    return response;
};

export {
    Api,
    acceptOffer,
    createCompany,
    createUser,
    deleteCompanyImages,
    deleteLocation,
    deleteProject,
    deleteReference,
    deleteSocialMedia,
    fetchBranches,
    fetchCompany,
    fetchCompanyPositions,
    fetchCompetences,
    fetchConfiguration,
    fetchGoals,
    fetchLanguages,
    fetchLicenseDurations,
    fetchMatches,
    fetchMessages,
    fetchOffers,
    fetchPlaceOfWorks,
    fetchProject,
    fetchProjects,
    fetchTerritories,
    fetchUser,
    fetchVideoFormats,
    fetchVideoTypes,
    getFilePath,
    getFileUploadObjectFromApiFileObject,
    getFileUploadObjectListFromApiFileObjectList,
    getImagePath,
    getIriListFromUploadedFileList,
    login,
    postCallbackRequest,
    postLocation,
    postMessage,
    postOffer,
    postProject,
    postReference,
    postSocialMedia,
    removeToken,
    resetPassword,
    setNewPassword,
    setToken,
    updateCompany,
    updateCompanyImage,
    updateLocation,
    updateMatchAttachments,
    updateMatchOfferingCompanyInteracted,
    updateMatchSearchingCompanyInteracted,
    updateOffer,
    updateProject,
    updateReference,
    updateSocialMedia,
    updateUser,
    updateUserPassword,
    uploadCompanyBanner,
    uploadCompanyImage,
    uploadCompanyLogo,
    uploadMatchAttachment,
    uploadMessageAttachment,
    uploadOfferAttachment,
    verifyRegistration,
};
