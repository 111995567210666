//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                                 from 'react';
import I18n                                  from 'i18next';
import Cast                                  from '../../../helper/Cast';
import Overlay                               from '../../connected/Overlay';
import PropTypes                             from '../../PropTypes';
import OverlayPane                           from '../OverlayPane';
import Overlays                              from '../../../constants/Overlays';
import { withTranslation }                   from 'react-i18next';
import CallToActionOverlayPane               from '../CallToActionOverlayPane';
import OverlayButton                         from '../OverlayButton';
import OverlayButtonTheme                    from '../OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper                  from '../OverlayButtonWrapper';
import CreateOfferOverlayAdditionalFilesPane from '../../connected/CreateOfferOverlayAdditionalFilesPane';
import CreateOfferOverlayOfferPane           from '../../connected/CreateOfferOverlayOfferPane';
import Spacer                                from '../Spacer';
import IconType                              from '../Icon/IconType';
import _                                     from 'lodash';
import ComponentHelper                       from '../../../helper/ComponentHelper';

class Component extends React.Component {
    render() {
        return (
            <Overlay
                id={Overlays.createOffer}
                title={this.props.title}
                showProgressBar={false}
            >
                {this.renderOffers()}
                {this.renderCallToActionOverlayPane()}
                {this.renderAdditionalFilesPane()}
                {this.renderSubmitPane()}
                <Spacer height={200} />
            </Overlay>
        );
    }

    renderAdditionalFilesPane = () => {
        return (
            <CreateOfferOverlayAdditionalFilesPane />
        );
    };

    renderCallToActionOverlayPane = () => {
        return (
            <CallToActionOverlayPane
                buttonIcon={IconType.plusCircle}
                buttonText={I18n.t('uploadAnotherOffer')}
                onClick={this.props.addOfferButtonPressed}
                tip={I18n.t('uploadAnotherOfferTip')}
            />
        );
    };

    renderOffers = () => {
        const offers     = [];
        const offerCount = this.props.offers.length;

        for (const offerIndex in this.props.offers) {
            const offerIndexInt = Cast.int(offerIndex);

            offers.push(
                <CreateOfferOverlayOfferPane
                    offerCount={offerCount}
                    offerIndex={offerIndexInt}
                />,
            );
        }

        return offers;
    };

    renderSubmitPane = () => {
        return (
            <OverlayPane
                footer={this.renderSubmitPaneFooter()}
                scrollingAllowed={false}
            />
        );
    };

    renderSubmitPaneFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.props.submitButtonPressed}
                    standAlone={true}
                    text={this.props.submitButtonText}
                    theme={OverlayButtonTheme.turquoise}
                    disabled={this.props.submitButtonDisabled}
                />
            </OverlayButtonWrapper>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {
    addOfferButtonPressed: PropTypes.func,
    offers:                PropTypes.array,
    submitButtonPressed:   PropTypes.func,
    submitButtonText:      PropTypes.string,
    title:                 PropTypes.string,
    submitButtonDisabled:  PropTypes.bool,
};

Component.defaultProps = {
    addOfferButtonPressed: _.noop,
    offers:                [],
    submitButtonDisabled:  false,
    submitButtonPressed:   _.noop,
    submitButtonText:      '',
    title:                 '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default withTranslation()(Component);
