//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push }           from 'connected-react-router';
import { put }            from 'redux-saga/effects';
import { select }         from 'redux-saga/effects';
import { OverlayActions } from '../actions/overlay';
import OverlayManager     from '../../components/connected/OverlayManager';
import Overlays           from '../../constants/Overlays';

const closeOverlay = function* () {
    yield put(OverlayActions.closeOverlayCheck());
};

const closeOverlayCheck = function* () {
    const lastOverlay = yield select(state => state.overlay.lastOverlay);

    if (lastOverlay) {
        yield put(push(OverlayManager.getPathForOverlayKey(Overlays.closeOverlayCheck)));
    } else {
        yield put(OverlayActions.overlayClosed());
    }
};

const overlayClosed = function* () {
    let redirect = yield select(state => state.overlay.lastRoute);

    if (!redirect) {
        redirect = yield select(state => state.router.location.pathname);
    }

    yield put(push(redirect));
    yield put(OverlayActions.resetLastRoute());
};

const reopenOverlay = function* () {
    const lastOverlay = yield select(state => state.overlay.lastOverlay);

    yield put(push(OverlayManager.getPathForOverlayKey(Overlays[lastOverlay])));
};

export default {
    closeOverlay,
    closeOverlayCheck,
    overlayClosed,
    reopenOverlay,
};
