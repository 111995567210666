//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as Api                     from '../../api';
import _                            from 'lodash';
import ApiMode                      from '../../constants/ApiMode';
import Cast                         from '../../helper/Cast';
import HydraHelper                  from '../../helper/Hydra';
import I18n                         from 'i18next';
import moment                       from 'moment';
import OverlayManager               from '../../components/connected/OverlayManager';
import Overlays                     from '../../constants/Overlays';
import Routes                       from '../../constants/Routes';
import { AlertBoxActions }          from '../actions/alertBox';
import { call }                     from 'redux-saga/effects';
import { put }                      from 'redux-saga/effects';
import { select }                   from 'redux-saga/effects';
import { CreateEditProjectActions } from '../actions/createEditProject';
import { OverlayActions }           from '../actions/overlay';
import { ProjectActions }           from '../actions/project';
import { push }                     from 'connected-react-router';

const createProject = function* (action) {
    const response = yield call(createOrUpdateProject, ApiMode.create, action);

    if (response.ok) {
        const project = HydraHelper.cleanupObject(response.data);

        yield put(CreateEditProjectActions.createProjectSuccess({
            project,
        }));
    } else {
        yield put(CreateEditProjectActions.createProjectFailed());
    }
};

const createProjectSuccess = function* (action) {
    yield call(createOrUpdateProjectSuccess, action, 'createNewProjectSuccessText');
};

const createProjectFailed = function* () {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('projectCreateError'),
    }));
};

// https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-310
const createOrUpdateProject = function* (mode, action) {
    const createEditProject = yield select(state => state.createEditProject);
    const postData          = {
        title:                                      createEditProject.title,
        description:                                createEditProject.description,
        videoType:                                  createEditProject.videoType,
        goal:                                       createEditProject.goal,
        goalAlternativeText:                        null, // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-273
        branch:                                     createEditProject.branch,
        branchAlternativeText:                      null, // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-274
        targetGroupAgeRangeFrom:                    (
                                                        !createEditProject.targetGroupAgeRangeUnknown ?
                                                            createEditProject.targetGroupAgeRange.start :
                                                            null
                                                    ),
        targetGroupAgeRangeTo:                      (
                                                        !createEditProject.targetGroupAgeRangeUnknown ?
                                                            createEditProject.targetGroupAgeRange.end :
                                                            null
                                                    ),
        targetGroupType:                            createEditProject.targetGroupType,
        videoWillBePublished:                       createEditProject.videoWillBePublished,
        videoFormats:                               createEditProject.videoFormats,
        territory:                                  createEditProject.territory,
        territoryAlternativeText:                   (
                                                        createEditProject.territoryIsOther ?
                                                            createEditProject.territoryText :
                                                            null
                                                    ),
        licenseDuration:                            createEditProject.licenseDuration,
        licenseDurationAlternativeText:             (
                                                        createEditProject.licenseDurationIsOther ?
                                                            createEditProject.licenseDurationText :
                                                            null
                                                    ),
        budgetRangeFrom:                            (
                                                        !createEditProject.budgetRangeUnknown ?
                                                            createEditProject.budgetRange.start :
                                                            null
                                                    ),
        budgetRangeTo:                              (
                                                        !createEditProject.budgetRangeUnknown ?
                                                            createEditProject.budgetRange.end :
                                                            null
                                                    ),
        advertisingBudgetPlanned:                   createEditProject.advertisingBudgedPlanned,
        advertisingBudgetRangeFrom:                 (
                                                        createEditProject.advertisingBudgedPlanned ?
                                                            createEditProject.advertisingBudgetRange.start :
                                                            null
                                                    ),
        advertisingBudgetRangeTo:                   (
                                                        createEditProject.advertisingBudgedPlanned ?
                                                            createEditProject.advertisingBudgetRange.end :
                                                            null
                                                    ),
        wishCompletionDate:                         (
                                                        !createEditProject.wishCompletionDateUnknown ?
                                                            moment(createEditProject.wishCompletionDate).toISOString() :
                                                            null
                                                    ),
        fixedReleaseDate:                           (
                                                        createEditProject.fixedReleaseDateAvailable ?
                                                            moment(createEditProject.fixedReleaseDate).toISOString() :
                                                            null
                                                    ),
        fixedReleaseDateIsSameAsWishCompletionDate: Cast.bool(createEditProject.fixedReleaseDateIsSameAsWishCompletionDate),
        wishedProductionPeriodStart:                (
                                                        !createEditProject.wishProductionPeriodDateUnknown ?
                                                            moment(createEditProject.wishedProductionPeriod.startDate).toISOString() :
                                                            null
                                                    ),
        wishedProductionPeriodEnd:                  (
                                                        !createEditProject.wishProductionPeriodDateUnknown ?
                                                            moment(createEditProject.wishedProductionPeriod.endDate).toISOString() :
                                                            null
                                                    ),
    };

    const response = (
        mode === ApiMode.create ?
            (
                yield call(
                    Api.postProject,
                    postData.title,
                    postData.description,
                    postData.videoType,
                    postData.goal,
                    postData.goalAlternativeText,
                    postData.branch,
                    postData.branchAlternativeText,
                    postData.targetGroupAgeRangeFrom,
                    postData.targetGroupAgeRangeTo,
                    postData.targetGroupType,
                    postData.videoWillBePublished,
                    postData.videoFormats,
                    postData.territory,
                    postData.territoryAlternativeText,
                    postData.licenseDuration,
                    postData.licenseDurationAlternativeText,
                    postData.budgetRangeFrom,
                    postData.budgetRangeTo,
                    postData.advertisingBudgetPlanned,
                    postData.advertisingBudgetRangeFrom,
                    postData.advertisingBudgetRangeTo,
                    postData.wishCompletionDate,
                    postData.fixedReleaseDate,
                    postData.fixedReleaseDateIsSameAsWishCompletionDate,
                    postData.wishedProductionPeriodStart,
                    postData.wishedProductionPeriodEnd,
                )
            ) :
            (
                yield call(
                    Api.updateProject,
                    createEditProject.projectIri,
                    postData.title,
                    postData.description,
                    postData.videoType,
                    postData.goal,
                    postData.goalAlternativeText,
                    postData.branch,
                    postData.branchAlternativeText,
                    postData.targetGroupAgeRangeFrom,
                    postData.targetGroupAgeRangeTo,
                    postData.targetGroupType,
                    postData.videoWillBePublished,
                    postData.videoFormats,
                    postData.territory,
                    postData.territoryAlternativeText,
                    postData.licenseDuration,
                    postData.licenseDurationAlternativeText,
                    postData.budgetRangeFrom,
                    postData.budgetRangeTo,
                    postData.advertisingBudgetPlanned,
                    postData.advertisingBudgetRangeFrom,
                    postData.advertisingBudgetRangeTo,
                    postData.wishCompletionDate,
                    postData.fixedReleaseDate,
                    postData.fixedReleaseDateIsSameAsWishCompletionDate,
                    postData.wishedProductionPeriodStart,
                    postData.wishedProductionPeriodEnd,
                )
            )
    );

    return response;
};

const createOrUpdateProjectSuccess = function* (action, successMessageKey) {
    const pathname = yield select(state => state.router.location.pathname);

    yield put(AlertBoxActions.clearAlerts());
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t(successMessageKey),
    }));
    yield put(OverlayActions.overlayClosed());
    yield put(ProjectActions.fetchProjects());
    yield put(push(Routes.home));
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.createProjectSuccess, pathname)));
};

const editProject = function* () {
    const lastRoute = yield select(state => state.router.location.pathname);

    yield put(OverlayActions.saveLastRoute({
        lastRoute,
    }));

    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.createProject)));
};

const newProjectOpen = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.createProject)));
};

const setLicenseDuration = function* (action) {
    const licenseDurations             = yield select(state => state.licenseDuration.licenseDurations);
    const selectedLicenseDuration      = _.find(
        licenseDurations,
        {
            iri: action.licenseDurationIri,
        },
    );
    const otherLicenseDurationSelected = _.get(selectedLicenseDuration, 'otherLicenseDuration', false);

    yield put(CreateEditProjectActions.setLicenseDurationIsOther({
        isOther: otherLicenseDurationSelected,
    }));
};

const setTerritory = function* (action) {
    const territories            = yield select(state => state.territory.territories);
    const selectedTerritory      = _.find(
        territories,
        {
            iri: action.territoryIri,
        },
    );
    const otherTerritorySelected = _.get(selectedTerritory, 'otherTerritory', false);

    yield put(CreateEditProjectActions.setTerritoryIsOther({
        isOther: otherTerritorySelected,
    }));
};

const updateProject = function* (action) {
    const response = yield call(createOrUpdateProject, ApiMode.edit, action);

    if (response.ok) {
        const project = HydraHelper.cleanupObject(response.data);

        yield put(CreateEditProjectActions.updateProjectSuccess({
            project,
        }));
    } else {
        yield put(CreateEditProjectActions.updateProjectFailed());
    }
};

const updateProjectFailed = function* () {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('projectUpdateError'),
    }));
};

const updateProjectSuccess = function* (action) {
    yield call(createOrUpdateProjectSuccess, action, 'updateProjectSuccessText');

};

export default {
    createProject,
    createProjectFailed,
    createProjectSuccess,
    editProject,
    newProjectOpen,
    setLicenseDuration,
    setTerritory,
    updateProject,
    updateProjectFailed,
    updateProjectSuccess,
};
