//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO: Customer has to check this whole file since the texts from sketch are not final https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-160
const german = {
    acceptedProjects:                          'Angenommene Projekte',
    acceptOffer:                               'Angebot akzeptieren',
    acceptOfferOverlayDescription:             'Möchtest du das Angebot sicher annehmen?',
    acceptOfferReadableName:                   'das "Akzeptieren der Angebote"',
    accountTypeBasic:                          'basic',   // TODO: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-63
    accountTypePremium:                        'premium', // TODO: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-63
    additionalMessage:                         'Ergänzende Nachricht',
    advertisingBudgetLabel:                    'Wie hoch sind die enthaltenen Media Spendings?',
    advertisingBudgetPlannedLabel:             'Sind Media Spendings in das Budget eingerechnet?',
    advertisingBudgetPlannedTooltipText:       'Media Spendings = Werbebudget. Wie viel Budget ist für die Bewerbung bzw. Vermarktung des Videos eingeplant?',
    advertisingBudgetRangeUndefined:           'Nicht definiert',
    advertisingBudgetUndefined:                'Nicht definiert',
    ageRangeUndefined:                         'Nicht definiert',
    alertBoxErrorText:                         'Fehler',
    alertBoxSuccessText:                       'Erfolg',
    and:                                       'und',
    back:                                      'Zurück',
    backToHome:                                'Zurück zur Startseite',
    bannerDimensionError:                      'Das Bild muss mindestens 1024x300 Pixel und darf höchstens 2048x600 Pixel groß sein.',
    becomePremiumMember:                       'Premium-Mitglied werden',
    becomePremiumMemberTip:                    'Jetzt upgraden',
    blog:                                      'Blog',
    blogUrlProduction:                         'https://framebutler.de/blog',
    blogUrlStaging:                            'https://staging.framebutler.de/blog',
    branchLabel:                               'Welche Branche?',
    branchTooltipText:                         'Für manche Jobs ist es wichtig, dass der zu beauftragende Video Creator bereits Vorkenntnisse in deiner Branche hat.',
    briefing:                                  'Projektbeschreibung',
    briefingView:                              'Projektbeschreibung ansehen',
    budget:                                    'Budget',
    budgetLabel:                               'Wie hoch ist das Projektbudget?',
    budgetRangeUndefined:                      'Nicht definiert',
    budgetTooltipText:                         'Die Höhe des Budgets ist wichtig, damit sich die Produktionen hinsichtlich der Planung bereits Gedanken über den Einsatz von Darstellern oder gebuchten Locations machen können.',
    callbackRequest:                           'Rückrufbitte',
    callbackRequestAvailabilityLabel:          'Gewünschte Tageszeit',
    callbackRequestAvailabilityMorning:        'Vormittags',
    callbackRequestAvailabilityNoon:           'Nachmittags',
    callbackRequestAvailabilityQuestion:       'Wann können wir dich am besten erreichen?',
    callbackRequestBoxButtonText:              'Go! Call me!',
    callbackRequestBoxText:                    'Ich möchte lieber zurückgerufen und telefonisch beraten werden:',
    callbackRequestBoxTitle:                   'Alternativ: Rückrufbitte',
    callbackRequestButtonText:                 'Go! Call me',
    callbackRequestEMailAddressPlaceholder:    'E-Mail zur Bestätigung',
    callbackRequestErrorAvailabilityType:      'Bitte gib an wie wir dich am besten erreichen.',
    callbackRequestErrorEMailAddress:          'Bitte gib deine E-Mail-Adresse an.',
    callbackRequestErrorPhoneNumber:           'Bitte gib deine Telefonnummer an.',
    callbackRequestOverlayButtonText:          'Go! Call me',
    callbackRequestOverlayDescription:         'Du hast Fragen oder benötigst Hilfe zu deinem Projekt? Dann hinterlasse uns deine Telefonnummer und ein Berater ruft dich innerhalb von 24 Std. zurück.',
    callbackRequestPhoneNumberPlaceholder:     'Bitte Telefonnummer eingeben',
    callbackRequestReadableName:               'die "Rückrufbitte"',
    callbackRequestSuccessMessage:             'Vielen Dank! Wir rufen dich schnellstmöglich zum ausgewählten Zeitpunkt zurück.',
    callbackRequestText:                       'Ich möchte lieber zurückgerufen und telefonisch beraten werden:',
    callbackRequestTitle:                      'Alternativ: Rückrufbitte',
    cancel:                                    'Abbrechen',
    city:                                      'Ort',
    close:                                     'Schließen',
    closeOverlay:                              'Schließen',
    closeOverlayConfirmText:                   'Möchtest du {{overlayName}} wirklich beenden.',
    closeOverlayConfirmTitle:                  'Wirklich beenden?',
    commissionedProjects:                      'Beauftragte Projekte',
    company:                                   'Unternehmen',
    companyBannerImageInformation:             'JPG, PNG (max. {{humanReadableFileSize}})\nEmpfohlene Auflösung:\n1300 x 300 px',
    companyCompetences:                        'Kompetenzen',
    companyCreateError:                        'Es konnte kein Firmenprofil erstellt werden. Bitte wende dich an den Support oder versuche es in ein paar Minuten erneut.',
    companyData:                               'Firmendaten',
    companyDescription:                        'Beschreibung',
    companyDescriptionErrorEmpty:              'Bitte gib eine Firmenbeschreibung an.',
    companyDescriptionTooltipText:             'Bitte beschreibe in kurzen Sätzen deine Firma.',
    companyEditProfileSuccess:                 'Dein Firmenprofil wurde erfolgreich gespeichert.',
    companyHeader:                             'Profilheader',
    companyImages:                             'Bilder',
    companyLanguages:                          'Firmensprachen',
    companyLanguagesErrorEmpty:                'Bitte wähle mindestens eine Sprache.',
    companyLanguagesTooltipText:               'Bitte wähle die Sprachen, die dir für eine Zusammenarbeit mit Dienstleistern wichtig ist.',
    companyLocation:                           'Firmenstandort',
    companyLocations:                          'Firmenstandorte',
    companyLogo:                               'Firmenlogo',
    companyLogoInformation:                    'JPG, PNG (max. {{humanReadableFileSize}})\nEmpfohlene Auflösung:\n512 x 512 px',
    companyNameErrorEmpty:                     'Bitte gib den Namen deines Unternehmens an.',
    companyNameLabel:                          'Dein Unternehmensname',
    companyNameTooltipText:                    'Mit oder ohne Rechtsform. Egal.',
    companyProfile:                            'Profil',
    companyReferences:                         'Referenzen',
    companyReferenceTipText:                   'Zeig uns und potentiellen Neukunden deine Referenzen.',
    companySocialMedias:                       'Social-Media-Kanäle',
    companySocialMediasTipText:                'Glänzen deine Social Media Kanäle?',
    companyStatusFindingMatches:               'Wir suchen gerade passende Matches',
    companyStatusOfferReceived:                'Angebot(e) erhalten',
    companyStatusOrderFinished:                '100% done.',
    companyStatusOrderPlaced:                  'YEAH! Produktion wird umgesetzt',
    companyStatusProductionsFound:             'Passende Video Creator wurden gefunden',
    companyUpdateError:                        'Das Firmenprofil konnte nicht aktualisiert werden. Bitte wende dich an den Support oder versuche es in ein paar Minuten erneut.',
    competencesLong:                           'Besondere Kompetenzen im Bereich',
    completeProjects:                          'Vollständige Projekte',
    contactPhoneNumber:                        'tel:015904045263',
    contactPhoneNumberLabel:                   'Tel. 01590 - 404 5263',
    continue:                                  'Weiter',
    createAFreeAccount:                        'Erstelle dein kostenloses framebutler Konto:',
    createNewProject:                          'Neues Projekt erstellen',
    createNewProjectOfferTitle:                '{{offerNumber}}. Angebot',
    createNewProjectSuccessText:               'Vielen Dank. Dein Projekt wurde erfolgreich angelegt. Wir informieren dich per Mail, sobald du ein Match hast!',
    createOfferReadableName:                   'das "Erstellen des Angebotes"',
    createOfferSuccessText:                    'Vielen Dank. Dein Angebot wurde erfolgreich angelegt. Wir informieren dich sobald sich der Angebotsstatus geändert hat!',
    createOfferTitle:                          'Angebot für "{{projectName}}" abgeben',
    createProject:                             'Projekt erstellen',
    createProjectReadableName:                 'das "Erstellen des Projektes"',
    createProjectSuccess:                      'Projekt gestartet',
    createProjectSuccessDescription:           'Das Projekt wurde erfolgreich erstellt. Nun werden passende Video Creator gesucht und zugeteilt.',
    createUserProfileDetails:                  'Mein Account',
    dataProtectionUrlProduction:               'https://framebutler.de/datenschutz',
    dataProtectionUrlStaging:                  'https://staging.framebutler.de/datenschutz',
    datePickerDateFormat:                      'dd.MM.yyyy',
    datePickerDateFormatMoment:                'DD.MM.YYYY',
    defaultPlaceHolderSocialMedia:             'URL eintragen',
    defaultPlaceHolderText:                    'Bitte eingeben oder wählen',
    defaultPlaceHolderTextDate:                'TT.MM.JJJJ',
    defaultPlaceHolderTextInput:               'Bitte eingeben',
    defaultPlaceHolderVideoUrl:                'https://...',
    deleteBannerImage:                         'Bannerbild löschen',
    deleteLogo:                                'Profilbild löschen',
    deleteProject:                             'Projekt löschen',
    deleteProjectErrorMessage:                 'Beim Löschen des Projekts ist ein Fehler aufgetreten, bitte versuche es später nochmal.',
    deleteProjectOverlayDescription:           'Möchtest du das Projekt wirklich löschen?',
    deleteProjectReadableName:                 'das "Löschen des Projektes"',
    deleteProjectShort:                        'Löschen',
    deleteProjectSuccessMessage:               'Das Projekt wurde erfolgreich gelöscht.',
    description:                               'Beschreibung',
    downloadFile:                              'Datei downloaden',
    edit:                                      'Bearbeiten',
    editAccountProfile:                        'Mein Account',
    editAccountProfileTip:                     'Passwort & Kontaktdaten',
    editCompanyCompetencesReadableName:        'das "Bearbeiten der Kompetenzen"',
    editCompanyCompetencesTip:                 '?',
    editCompanyDescriptionReadableName:        'das "Bearbeiten der Beschreibung"',
    editCompanyHeaderReadableName:             'das "Bearbeiten der Profildetails"',
    editCompanyImagesReadableName:             'das "Bearbeiten der Profilheader"',
    editCompanyLanguagesReadableName:          'das "Bearbeiten der Sprachen"',
    editCompanyLocationsReadableName:          'das "Bearbeiten der Standorte"',
    editCompanyPlaceOfWorksReadableName:       'das "Bearbeiten der Arbeitsorte"',
    editCompanyPlaceOfWorkTip:                 '?',
    editCompanyProfile:                        'Profil anpassen',
    editCompanyProfileTip:                     'Deine Visitenkarte auf framebutler',
    editCompanyReferences:                     'Firmenreferenzen bearbeiten',
    editCompanyReferencesReadableName:         'das "Bearbeiten der Referenzen"',
    editCompanySocialMediasReadableName:       'das "Bearbeiten der Social-Media-Kanäle""',
    editOfferTitle:                            'Angebot für "{{projectName}}" bearbeiten',
    editPassword:                              'Passwort ändern',
    editProfile:                               'Profil bearbeiten',
    editProject:                               'Projekt bearbeiten',
    editProjectShort:                          'Bearbeiten',
    editProjectSuccess:                        'Projekt bearbeitet',
    editProjectSuccessDescription:             'Deine Projektbeschreibung wurde gespeichert. Nun werden passende Video Creator gesucht und zugeteilt.',
    editUserDataReadableName:                  'das "Bearbeiten des Profils"',
    editUserPasswordReadableName:              'das "Bearbeiten des Passworts"',
    editUserProfileDetails:                    'Meinen Account bearbeiten',
    email:                                     'E-Mail',
    eMailAddress:                              'E-Mail-Adresse',
    eMailAddressError1:                        'Bitte gib deine E-Mail-Adresse ein.',
    emptyCityError:                            'Die Stadt darf nicht leer sein.',
    emptyHouseNumberError:                     'Die Hausnummer darf nicht leer sein.',
    emptyNameError:                            'Bitte gib einen Namen ein',
    emptyPostalCodeError:                      'Die Postleitzahl darf nicht leer sein.',
    emptyStreetError:                          'Die Straße darf nicht leer sein.',
    error404:                                  '404',
    error:                                     'Fehler',
    errorPageSubtitle:                         'Lust auf einen 25 € Amazon Gutschein?',
    errorPageText:                             'Schreib uns wie/warum du auf dieser Fehlerseite gelandet bist. Wenn wir mit deiner Hilfe einen Fehler finden und lösen können, bekommst du als Dank einen 25 €-Amazon-Gutschein.',
    errorPageTitle:                            'Huch!',
    euro:                                      'Euro',
    euroNet:                                   'Euro (netto)',
    euroShort:                                 'EUR',
    facebook:                                  'Facebook',
    facebookUrl:                               'https://www.facebook.com/framebutler/',
    faq:                                       'FAQ',
    faqUrlProduction:                          'https://framebutler.de/{{companyType}}#faq',
    faqUrlStaging:                             'https://staging.framebutler.de/{{companyType}}#faq',
    fileAddButton:                             'Datei\nhinzufügen',
    fileSizeLimitText:                         'Maximal {{humanReadableFileSize}} pro Datei',
    fillLaterChoiceText:                       'Weiß ich nicht (später ausfüllen)',
    finishedProjects:                          'Abgeschlossene Projekte',
    firstname:                                 'Vorname',
    fixedReleaseDateErrorEmpty:                'Bitte gib den gewünschten Veröffentlichungstermin an.',
    fixedReleaseDateFormat:                    'DD.MM.YYYY',
    fixedReleaseDateGiven:                     'Veröffentlichungstermin',
    fixedReleaseDateGivenLabel:                'Gibt es einen Veröffentlichungstermin?',
    fixedReleaseDateGivenTooltipText:          'Veranstaltung, gebuchte Sendeplätze, etc. an dem das Videos fertig sein muss.',
    goals:                                     'Ziele',
    goalsLabel:                                'Was soll mit dem Video erreicht werden?',
    goalsTooltipText:                          'Bitte wähle das oberste Ziel, welches du verfolgen möchtest aus. Es ist wichtig sich auf einen Punkt zu fokussieren, um erfolgreich zu werben. Weitere Ziele können subtil untergebracht werden.',
    goalsUndefined:                            'Nicht definiert',
    hello:                                     'Hallo',
    help:                                      'Hilfe',
    helpPath:                                  '/hilfe',
    helpTip:                                   'Kontakt zu framebutler',
    houseNumber:                               'Hausnummer',
    imageDimensionError:                       'Das beste Ergebnis erreichst du mit 300 Pixel Höhe und 1300 Pixel Breite.',
    imageSizeError:                            'Das Bild ist leider zu groß, bitte lade ein kleineres Bild hoch.',
    imprint:                                   'Impressum',
    imprintPath:                               '/impressum',
    incompleteProjects:                        'Unvollständige Projekte',
    instagram:                                 'Instagram',
    instagramUrl:                              'https://www.instagram.com/framebutler',
    invalidDate:                               'Ungültiges Datum',
    keyData:                                   'Projektbeschreibung/Briefing',
    licenseDurationErrorEmpty:                 'Bitte gib eine gewünschte Nutzungsdauer an.',
    licenseDurationLabel:                      'Nutzungsdauer',
    licenseDurationTooltipText:                'Die Nutzungsdauer bestimmt unter anderem die Höhe der Buyouts für Darsteller. Je länger die Nutzungsrechte, desto teurer das Video. Beispielrechnung bei  Darsteller Tagesgage von 800 EUR.  Nutzung für 1 Jahr: 100 % Buyout + 800 EUR. Nutzung 2 Jahre: 200 % Buyout + 1.600 EUR.',
    licenseDurationUndefined:                  'Nicht definiert',
    loading:                                   'Lade...',
    location:                                  'Standort',
    locationCount:                             'Standort {{location}}',
    locationCreateError:                       'Es konnte kein Firmenstandort erstellt werden. Bitte wende dich an den Support oder versuche es in ein paar Minuten erneut.',
    locationLabel:                             'Dein Standort',
    locations:                                 'Standorte',
    locationTooltipText:                       'Bitte gib die Standorte an',
    login:                                     'Einloggen',
    loginAction:                               'Anmelden',
    loginClaim:                                'Finde die passende\nVideo Creator\nfür dein Projekt',
    loginError:                                'Der Login ist fehlgeschlagen. Bitte überprüfe deine Zugangsdaten und versuche es erneut.',
    loginErrorUserCreate:                      'Es konnte kein Account erstellt werden. Bitte wende dich an den Support oder versuche es in ein paar Minuten erneut.',
    loginText:                                 'Durch das Fortfahren akzeptierst du <a href="{{termsOfServiceUrl}}" target="_blank">framebutlers Servicebedingungen</a> und bestätigst, dass du <a href="{{dataProtectionUrl}}" target="_blank">framebutlers Datenschutzinformation</a> zur Kenntnis genommen hast.',
    logoDimensionError:                        'Das Bild muss mindestens 300 Pixel und höchstens 1024 Pixel hoch und breit sein.',
    logout:                                    'Ausloggen',
    logoutTip:                                 'Endlich Feierabend',
    lostPassword:                              'Passwort vergessen?',
    match:                                     'Match',
    matches:                                   'Matches',
    matchesAll:                                'Alle Matches',
    matchesNew:                                'Neue Matches',
    matchNoMessageText:                        'Keine Nachrichten',
    matchNoOfferText:                          'Keine Angebote',
    maxBudgetErrorText:                        'Das Budget darf nicht höher als 250.000 € sein.',
    messageDateFormat:                         'DD.MM.YYYY',
    messageDateTimeFormat:                     'DD.MM.YYYY HH:mm',
    messageErrorEmptyMessage:                  'Bitte gib eine Nachricht ein.',
    messageErrorSend:                          'Beim Absenden deiner Nachricht ist ein Fehler aufgetreten. Bitte versuche es erneut oder wende dich im Zweifel an unseren Support.',
    messageOfferTitle:                         '{{companyName}} hat ein Angebot abgegeben',
    messages:                                  'Nachrichten',
    messageSubmit:                             'Absenden',
    messageTimeFormat:                         'HH:mm',
    minBudgetErrorText:                        'Das Budget darf nicht niedriger als 1000 € sein.',
    missedProjects:                            'Verpasste Projekte',
    mobileRegisterSuccess:                     'Registrierung erfolgreich',
    mobileRegisterSuccessSocialText:           'Folge uns auf:',
    mobileRegisterSuccessText:                 'Dein Projekt wurde erfolgreich erstellt. \nWir suchen nun nach passenden Video Creator und melden uns bei dir.',
    mobileRegisterSuccessTitle:                'Vielen Dank!',
    more:                                      'Mehr',
    myCompany:                                 'Mein Unternehmen',
    myProfile:                                 'Mein Profil',
    name:                                      'Nachname',
    newAddress:                                'Neue Adresse hinzufügen',
    newProjects:                               'Neue Projekte',
    no:                                        'Nein',
    noMatches:                                 'Keine Matches',
    noMatchesFound:                            'Bisher haben wir noch keine passenden Matches für dich.',
    noMessagesFound:                           'Bisher haben wir noch keine Nachrichten für dich. Sobald dein Projekt ein Match hat, kannst du hier mit dem Video Creator Nachrichten austauschen.',
    noMessagesFoundTitle:                      'Keine Nachrichten',
    noProjectsDashboardCompany:                'Du hast noch kein Projekt angelegt. Klicke jetzt auf "Neues Projekt erstellen" auf der linken Seite um dein erstes Videoprojekt anzulegen.',
    noProjectsDashboardVideoProduction:        'Aktuell hast du noch keine Matches. Wir melden uns bei dir, sobald es Neuigkeiten gibt. Hast du dein Profil schon vervollständigt? Ein aktuelles Profil mit Referenzen überzeugt potentielle Auftraggeber. Unter  "Mein Profil" kannst du es bearbeiten.',
    oclock:                                    'Uhr',
    offerAccept:                               'Angebot annehmen',
    offerAccepted:                             'Angebot angenommen',
    offerAcceptedOverlayDescription:           'Das Angebot wurde erfolgreich angenommen. Viel Spaß und Erfolg bei der Umsetzung.',
    offerAcceptedReadableName:                 'das "Akzeptieren des Angebotes"',
    offerAcceptN:                              '{{offerNumber}}. Angebot annehmen',
    offerCreateError:                          'Es konnte kein Angebot erstellt werden. Bitte wende dich an den Support oder versuche es in ein paar Minuten erneut.',
    offerEdit:                                 'Angebot bearbeiten',
    offerEditSubmit:                           'Änderungen speichern',
    offerEmptyCallToAction:                    'Jetzt Angebot abgeben',
    offerEmptyTextCompany:                     'Bitte hab noch etwas Geduld. Wir informieren dich sobald ein Angebot vorliegt!',
    offerEmptyTextVideoAgency:                 'Du solltest schnellstmöglich ein Angebot abgeben, wenn du diesen Kunden gewinnen möchtest.',
    offerEmptyTitleCompany:                    'Noch kein Angebot erhalten',
    offerEmptyTitleVideoAgency:                'Bisher kein Angebot abgegeben',
    offerPdf:                                  'Angebots (PDF)',
    offerPdfErrorEmpty:                        'Bitte lade mindestens ein PDF-Dokument hoch das dein Angebot enthält.',
    offers:                                    'Angebote',
    offerSubmit:                               'Angebot abgeben',
    offerSum:                                  'Angebotssumme',
    offerSumErrorEmpty:                        'Bitte gib die Angebotssumme als Nettopreis an.',
    offerSumPrefix:                            'Nettopreis in Euro',
    offerTitle:                                'Angebots-Titel',
    offerTitleErrorEmpty:                      'Bitte gib einen Titel für dieses Angebot an.',
    offerUpdateError:                          'Das Angebot konnte nicht aktualisiert werden. Bitte wende dich an den Support oder versuche es in ein paar Minuten erneut.',
    ok:                                        'Ok!',
    okThanks:                                  'Ok, Danke!',
    oldPassword:                               'Altes Passwort',
    openContactForm:                           'Zum Kontaktformular',
    optional:                                  'Optional',
    or:                                        'oder',
    otherDocuments:                            'Sonstige Dokumente',
    otherDocumentsTip:                         'AGB, Firmenvorstellung, etc.',
    otherDocumentsTipText:                     'Hier können Dokumente hinzugefügt werden, die übergreifend über alle Angebote relevant sind.',
    otherOfferAccepted:                        'Anderes Angebot angenommen',
    pageNotFound:                              'Die Seite wurde nicht gefunden.',
    pageTitle:                                 'Framebutler',
    password:                                  'Passwort',
    passwordCheck:                             'Passwort wiederholen',
    passwordError1:                            'Bitte gib dein Passwort ein.',
    passwordPlaceholder:                       '••••••••',
    percentShort:                              '%',
    phoneNumber:                               'Telefonnummer',
    phoneNumberLabel:                          'Telefonnummer',
    phoneNumberPlaceholder:                    'Telefonnummer eingeben',
    phoneNumberTooltipText:                    'Die Telefonnummer wird nicht veröffentlicht.',
    placeOfWork:                               'Arbeitsort',
    placeOfWorks:                              'Arbeitsorte',
    placesOfWork:                              'Arbeitsorte',
    postalCode:                                'PLZ',
    privacy:                                   'Datenschutz',
    privacyPath:                               '/datenschutz',
    profileAbilitiesAndKnowledge:              'Fähigkeiten und Kenntnisse',
    profileAboutHeadline:                      'Über {{companyName}}',
    profileDetails:                            'Profildetails',
    profileImages:                             'Behind the scenes',
    profileLanguages:                          'Sprachen',
    profileLocations:                          'Standorte',
    profilePasswordError1:                     'Das Passwort darf nicht leer sein.',
    profilePasswordError2:                     'Das Passwort muss mindestens 8 Zeichen lang sein.',
    profilePasswordError3:                     'Die Passwörter stimmen nicht überein.',
    profilePasswordError4:                     'Das Passwort ist nicht korrekt.',
    profileReferences:                         'Referenzen',
    projectCreateError:                        'Es konnte kein Projekt erstellt werden. Bitte wende dich an den Support oder versuche es in ein paar Minuten erneut.',
    projectDescription:                        'Ergänzung',
    projectDescriptionPlaceHolder:             'Weitere Informationen zum Projekt',
    projectDescriptionPlaceholderText:         'Beschreibe das Projekt in 3 kurzen Sätzen',
    projectDescriptionTooltipText:             'In welche Kategorie passt dein Vorhaben am besten?',
    projectDetails:                            'Projektdetails',
    projectIncompleteHint:                     'Unvollständig ausgefüllt',
    projectOverview:                           'Projektübersicht',
    projectTitle:                              'Projekt-Titel',
    projectTitleErrorEmpty:                    'Bitte gib einen Projekt-Titel an.',
    projectTitlePlaceHolder:                   'Titel des Projekts',
    projectTitleTooltipText:                   'Bitte gib hier einen individuellen Titel für dein Projekt ein. Beispiel: Imagefilm für Fahrzeugbau-Unternehmen',
    projectUpdateError:                        'Das Projekt konnte nicht aktualisiert werden. Bitte wende dich an den Support oder versuche es in ein paar Minuten erneut.',
    rangeCombinerFrom:                         'ab',
    rangeCombinerShort:                        ' - ',
    rangeCombinerTo:                           'bis',
    rangePostfix:                              'bis',
    rangePrefix:                               'von',
    reach:                                     'Reichweite',
    referenceImageDimensionError:              'Das beste Ergebnis erreichst du mit 600 Pixel Höhe und 600 Pixel Breite.',
    referenceImageInformation:                 'Bitte lade bis zu 9 Bilder im quadratischen Format 600 x 600px hoch. Bilder dürfen nicht größer als (max. {{humanReadableFileSize}}) sein.',
    referencePlay:                             'Play',
    register:                                  'Registrieren',
    registerAction:                            'Registrieren',
    registerCompanyCTAText:                    'Registriere dein Unternehmen, um einen passenden Video Creator für dein Videoprojekt zu finden',
    registerCompanyCTATitle:                   'Als <span>Auftraggeber</span> registrieren',
    registerCompanyInfoText:                   'Dein Projekt wird nur passenden Video Creator angezeigt und nicht veröffentlicht.',
    registerCompanyReadableName:               'das "Registrieren als Auftraggeber"',
    registerReadableName:                      'das "Registieren"',
    registerVideoProductionCTAText:            'Registriere dich als Video Creator, um passende Videoprojekte zu bekommen',
    registerVideoProductionCTATitle:           'Als <span>Video Creator</span> registrieren',
    registerVideoProductionReadableName:       'das "Registrieren als Video Creator"',
    registerVideoProductionSuccessDescription: 'Dein Profil wurde erfolgreich angelegt. Wir informieren dich, sobald wir einen passenden Auftrag für dich haben.',
    registerVideoProductionSuccessTitle:       'Registrierung erfolgreich',
    removeOffer:                               'Angebot entfernen',
    resetPassword:                             'Passwort zurücksetzen',
    resetPasswordError:                        'Da ist etwas schief gelaufen. Versuche es nochmal oder kontaktiere den Support.',
    resetPasswordSuccess:                      'Prüfe dein E-Mail-Postfach, um das Passwort zurück zu setzen.',
    retry:                                     'Erneut versuchen',
    roleInCompany:                             'Position im Unternehmen',
    roleInCompanyErrorEmpty:                   'Bitte gib deine Position im Unternehmen an.',
    roleInCompanyLabel:                        'Deine Position im Unternehmen',
    roleInCompanyTooltipText:                  'Mit wem haben wir es zu tun?',
    sameDateAsAbove:                           'Gleiches Datum wie oben',
    save:                                      'Speichern',
    saveProject:                               'Projekt speichern',
    screenDesign:                              'Screendesign',
    screenTooSmallText:                        'Bitte nutze framebutler am Desktop. Wir arbeiten an der Umsetzung und melden uns gerne, wenn wir auch mobil für dich da sind. Trage dich dazu gerne in den Newsletter ein.',
    screenTooSmallTitle:                       'Die mobile Ansicht wird leider noch nicht unterstützt. 😢',
    selectSearchEmptyMessage:                  'Es gab leider keine Treffer für den eingegebenen Suchbegriff.',
    setNewPassword:                            'Senden',
    setNewPasswordError:                       'Es ist ein Fehler aufgetreten. Bitte versuche es erneut oder kontaktiere den Support.',
    setNewPasswordSuccess:                     'Das Passwort wurde erfolgreich geändert.',
    socialMediaParseError:                     'Wir konnten diesen Link nicht verarbeiten, kopiere im Zweifel den Link aus dem Browser.',
    socialMediaUrl:                            'Social Media URL',
    socialMediaUrlSubtitle:                    '(Facebook, Instagram, E-Mail, etc.)',
    speaks:                                    'Spricht',
    status:                                    'Status',
    street:                                    'Straße',
    targetGroupLabel:                          'Welche Zielgruppe soll erreicht werden?',
    targetGroupTooltipText:                    'Wen möchtest du in erster Linie mit dem Video überzeugen? B2B (Business to Business): Dein Video richtet sich an Geschäftskunden. B2C (Business to Consumer): Dein Video richtet sich an Endkunden. Bitte beachte, dass auch im B2B Marketing einzelne Personen angesprochen werden. In welchem Alter befinden sich diese?',
    targetGroupTypeBusinessToBusinessShort:    'B2B',
    targetGroupTypeBusinessToCustomerShort:    'B2C',
    targetGroupTypeErrorEmpty:                 'Bitte gib an, an wen sich das Video richten soll.',
    targetGroupTypeLabel:                      'An wen richtet sich das Video?',
    targetGroupUndefined:                      'Nicht definiert',
    termsOfUseUrlProduction:                   'https://framebutler.de/bedingungen',
    termsOfUseUrlStaging:                      'https://staging.framebutler.de/bedingungen',
    thousandsShort:                            'k',
    timings:                                   'Timings',
    titleAndDescription:                       'Titel & Beschreibung',
    today:                                     'Heute',
    unknownBranch:                             'Unbekannte Branche',
    unknownVideoType:                          'Unbekannter Video-Typ',
    updateOfferSuccessText:                    'Vielen Dank. Alle Änderungen an deinem Angebot wurden erfolgreich gespeichert!',
    updateProjectSuccessText:                  'Vielen Dank. Die Änderungen an deinem Projekt wurden erfolgreich gespeichert. Wir informieren dich per Mail, sobald du ein Match hast!',
    uploadAnotherOffer:                        'Weiteres Angebot\nhochladen',
    uploadAnotherOfferTip:                     'Hier kannst Du ein weiteres Angebot hochladen, falls Du z.B. eine Alternative anbieten möchtest.',
    uploadFileError1:                          'Beim Hochladen deiner Datei ist ein Fehler aufgetreten, bitte versuche es später erneut.', // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-248
    uploadImage:                               'Bild hochladen',
    uploadNewImage:                            'Neues Bild hochladen',
    uploadOffer:                               'Angebot\nhochladen',
    userData:                                  'Account Daten',
    userEditProfileSuccess:                    'Dein Accountprofil wurde erfolgreich gespeichert.',
    userEmailAlreadyRegisteredError:           'Diese E-Mail-Adresse ist bereits registriert.',
    userEmailError:                            'Bitte gib eine E-Mail-Adresse ein',
    userPassword:                              'Passwort',
    userProfile:                               'Mein Account',
    userProfilePassword:                       'Mein Passwort ändern',
    userUpdateError:                           'Das Benutzerprofil konnte nicht aktualisiert werden. Bitte wende dich an den Support oder versuche es in ein paar Minuten erneut.',
    verifyRegistration:                        'Registrierung bestätigen',
    verifyRegistrationError:                   'E-Mail-Verifizierung bereits erfolgt oder Bestätigungscode ist fehlerhaft.',
    verifyRegistrationSucceeded:               'E-Mail-Verifizierung erfolgreich.',
    versionNumberDateFormat:                   'DD.MM.YYYY HH:mm:ss',
    versionText:                               'Version: ',
    videoFormatErrorEmpty:                     'Bitte wähle mindestens einen Kanal an, auf dem du das Video veröffentlichen möchtest.',
    videoFormatFacebook:                       'Facebook',
    videoFormatFacebookShort:                  'fb',
    videoFormatInstagram:                      'Instagram',
    videoFormatInstagramShort:                 'insta',
    videoFormatLabel:                          'Wo soll das Video gezeigt werden?',
    videoFormatTooltipText:                    'Für die Buyouts bspw. der Darsteller, Sprecher oder Musiker ist es wichtig wo das Video veröffentlicht wird.',
    videoFormatUndefined:                      'Nicht definiert',
    videoFormatWeb:                            'Web',
    videoFormatWebShort:                       'web',
    videoLink:                                 '{{count}}. Videolink',
    videoProduction:                           'Video Creator',
    videoProductionStatusOfferSubmitted:       'Angebot abgegeben',
    videoProductionStatusOrderReceived:        'Auftrag erhalten',
    videoProductionStatusProposed:             'Du passt zu diesem Videoprojekt',
    videoProductionSubTitle:                   'Video Creator',
    videoTerritoryEmptyText:                   'Bitte wähle ein Gebiet/Region aus.',
    videoTerritoryLabel:                       'Gebiet/Region?',
    videoTerritoryTooltipText:                 'Wo möchtest du das Video veröffentlichen? Auch dieser Punkt spielt eine wesentliche Rolle für die Berechnung von Lizenzen und Buyouts.',
    videoTerritoryUndefined:                   'Nicht definiert',
    videoType:                                 'Video-Art',
    videoTypeClassicImageFilm:                 'Klassischer Imagefilm',
    videoTypeErrorEmpty:                       'Bitte wähle einen Video-Typ aus.',
    videoTypeSelectionLabel:                   'Welche Art von Video benötigst du?',
    videoTypeSocialMediaVideos:                'Social-Media-Video(s)',
    videoTypeTooltipText:                      'Bitte wähle für dein Videoprojekt eine Videoart, die am besten für dich passt. Falls du dir nicht sicher bist, beraten wir dich gerne. Klicke in dem Fall einfach auf „Weiß ich nicht“. Ein Berater wird dein Projekt einstufen oder sich bei dir melden.',
    viewProfile:                               'Profil ansehen',
    vimeoOrYoutube:                            '(Vimeo oder Youtube)',
    watchVideo:                                'Video',
    website:                                   'Webseite',
    websiteErrorEmpty:                         'Bitte gib die Webseite deines Unternehmens an.',
    websiteLabel:                              'Deine Website',
    websitePathCompany:                        'unternehmen',
    websitePathVideoProduction:                'video-produktionen',
    websiteTooltipText:                        'Unter welcher Webadresse bist du als Video Creator zu finden?',
    websiteUrlProduction:                      'https://framebutler.de/',
    websiteUrlStaging:                         'https://staging.framebutler.de/',
    willVideoPublishedErrorEmpty:              'Bitte gib an, ob das Video veröffentlicht werden soll.',
    willVideoPublishedLabel:                   'Wird das Video veröffentlicht?',
    willVideoPublishedTooltipText:             'Insbesondere bei Lizenzen und Buyouts für u.a. Darstellern spielt dies eine wichtige Rolle, weil diese je nach Ausstrahlungsort und -dauer höher vergütet werden müssen.',
    willVideoPublishedTooltipTitle:            'Warum Fragen wir das?',
    wishCompletionDateFormat:                  'DD.MM.YYYY',
    wishCompletionDateLabel:                   'Gewünschte Fertigstellung?',
    wishCompletionDateTooltipText:             'Gibt es eine konkrete Deadline?',
    wishCompletionDateUndefined:               'Nicht definiert',
    wishedProductionPeriodErrorDateRange:      'Bitte gib einen gültigen Zeitraum an.',
    wishedProductionPeriodErrorEmptyEnd:       'Bitte gib das Ende des gewünschten Produktionszeitraumes an.',
    wishedProductionPeriodErrorEmptyStart:     'Bitte gib den Start des gewünschten Produktionszeitraumes an.',
    wishedProductionPeriodFormat:              'DD.MM.YYYY',
    wishedProductionPeriodLabel:               'Gewünschter Produktionszeitraum?',
    wishedProductionPeriodTooltipText:         'Ist dir ein bestimmter Produktionszeitraum besonders wichtig? Bspw. während einer Messe-Event-Woche.',
    year:                                      'Jahr',
    years:                                     'Jahre',
    yes:                                       'Ja',
    yesLoud:                                   'Ja!',
    yesterday:                                 'Gestern',
    youTube:                                   'YouTube',
};

module.exports = german;
