//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import { connect }      from 'react-redux';
import PropTypes        from '../../PropTypes';
import ComponentHelper  from '../../../helper/ComponentHelper';
import OverlayPane      from '../../stateless/OverlayPane';
import OverlayPaneState from '../../stateless/OverlayPane/OverlayPaneState';

class ErrorAwareOverlayPane extends React.Component {
    /**
     * This methods adjust the padding bottom the fix the scroll bar position
     * when an alert is visible.
     *
     * @returns {*}
     */
    getHeightToReduce = () => {
        if (this.props.alertBoxes.length) {
            return 69;
        }

        return 0;
    };

    render() {
        return (
            <OverlayPane
                adjustedHeight={this.props.alertBoxes.length}
                content={this.props.content}
                footer={this.props.footer}
                heightToReduce={this.getHeightToReduce()}
                title={this.props.title}
                scrollingAllowed={true}
                showErrors={this.props.showErrors}
                state={this.props.state}
                upcomingDistance={this.props.upcomingDistance}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ErrorAwareOverlayPane;

Component.propTypes = {
    alertBoxes:       PropTypes.array,
    content:          PropTypes.children,
    footer:           PropTypes.children,
    showErrors:       PropTypes.bool,
    state:            PropTypes.oneOf(Object.values(OverlayPaneState)),
    title:            PropTypes.string,
    upcomingDistance: PropTypes.number,
};

Component.defaultProps = {
    alertBoxes:       [],
    content:          null,
    footer:           null,
    showErrors:       true,
    state:            OverlayPaneState.upcoming,
    title:            '',
    upcomingDistance: 0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        alertBoxes: state.alertBox.alertBoxes,
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);
