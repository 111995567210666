//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                        from 'react';
import _                            from 'lodash';
import CompanyTypes                 from '../../../constants/CompanyTypes';
import ComponentHelper              from '../../../helper/ComponentHelper';
import moment                       from 'moment';
import PropTypes                    from '../../PropTypes';
import SplitPaneMenuStateless       from '../../stateless/SplitPaneMenu';
import { ActiveProjectActions }     from '../../../store/actions/activeProject';
import { bindActionCreators }       from 'redux';
import { compose }                  from 'redux';
import { connect }                  from 'react-redux';
import { CreateEditProjectActions } from '../../../store/actions/createEditProject';
import { UserActions }              from '../../../store/actions/user';
import { withRouter }               from 'react-router-dom';

class SplitPaneMenu extends React.Component {
    createNewProject = () => {
        this.props.loadNoneSessionDependingData();
        this.props.newProjectOpen();
    };

    getProjects = () => {
        const sortedProjects = _.sortBy(
            this.props.projects,
            [
                function (project) {
                    return moment(project.updatedAt);
                },
            ],
        ).reverse();

        return sortedProjects;
    };

    render() {
        const projects = this.getProjects();

        return (
            <SplitPaneMenuStateless
                activeRoute={this.props.location.pathname}
                companyId={this.props.companyId}
                companyType={this.props.companyType}
                projects={projects}
                createNewProject={this.createNewProject}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = SplitPaneMenu;

Component.propTypes = {
    companyId:   PropTypes.string,
    companyType: PropTypes.oneOf(Object.values(CompanyTypes)),
    location:    PropTypes.object,
    projects:    PropTypes.array,
};

Component.defaultProps = {
    companyId:   null,
    companyType: null,
    location:    null,
    projects:    [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        ActiveProjectActions,
        CreateEditProjectActions,
        UserActions,
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        companyId:   state.company.ownCompany.id,
        companyType: state.company.ownCompany.companyType,
        projects:    state.project.projects,
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Component);
