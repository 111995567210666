//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import Cast            from '../../../helper/Cast';
import ComponentHelper from '../../../helper/ComponentHelper';
import I18n            from 'i18next';

class ProgressBar extends React.Component {
    getBarStyle = () => {
        const style = {
            width: Cast.percentString(this.props.percent),
        };

        return style;
    };

    render() {
        const barStyle = this.getBarStyle();

        return (
            <div className={styles.wrapper}>
                <h5>
                    {this.props.title}
                    {': '}
                    {this.props.percent}
                    {I18n.t('percentShort')}
                </h5>
                <div className={styles.progressBar}>
                    <div
                        className={styles.progress}
                        style={barStyle}
                    />
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ProgressBar;

Component.propTypes = {
    percent: PropTypes.number,
    title:   PropTypes.string,
};

Component.defaultProps = {
    percent: 0,
    title:   '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
