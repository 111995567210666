//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const LicenseDurationTypes = {
    FETCH_LICENSE_DURATIONS:         'LicenseDuration/FETCH_LICENSE_DURATIONS',
    FETCH_LICENSE_DURATIONS_FAILED:  'LicenseDuration/FETCH_LICENSE_DURATIONS_FAILED',
    FETCH_LICENSE_DURATIONS_SUCCESS: 'LicenseDuration/FETCH_LICENSE_DURATIONS_SUCCESS',
};

const fetchLicenseDurations = makeActionCreator(
    LicenseDurationTypes.FETCH_LICENSE_DURATIONS,
);

const fetchLicenseDurationsFailed = makeActionCreator(
    LicenseDurationTypes.FETCH_LICENSE_DURATIONS_FAILED,
);

const fetchLicenseDurationsSuccess = makeActionCreator(
    LicenseDurationTypes.FETCH_LICENSE_DURATIONS_SUCCESS,
    {
        licenseDurations: null,
    },
);

export const LicenseDurationActions = {
    fetchLicenseDurations,
    fetchLicenseDurationsFailed,
    fetchLicenseDurationsSuccess,
};
