//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React             from 'react';
import _                 from 'lodash';
import Api               from '../../../constants/Api';
import CompanyTypes      from '../../../constants/CompanyTypes';
import ComponentHelper   from '../../../helper/ComponentHelper';
import Environment       from '../../../helper/Environment';
import I18n              from 'i18next';
import IconType          from '../Icon/IconType';
import Link              from '../Link';
import MainMenu          from '../MainMenu';
import MainMenuItem      from '../../connected/MainMenuItem';
import PrettyScrollBar   from '../PrettyScrollBar';
import ProjectMenu       from '../ProjectMenu';
import ProjectMenuItem   from '../../connected/ProjectMenuItem';
import ProjectStateTypes from '../../../constants/ProjectStateTypes';
import PropTypes         from '../../PropTypes';
import Routes            from '../../../constants/Routes';
import styles            from './styles.module.scss';
import VersionNumber     from '../VersionNumber';
import MatchStateTypes   from '../../../constants/MatchStateTypes';

class SplitPaneMenu extends React.Component {
    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.top}>
                    <MainMenu>
                        {this.renderProjectOverviewButton()}
                        {this.renderCreateProjectButton()}
                        {this.renderProfileButton()}
                        {this.renderDebugMenu()}
                    </MainMenu>
                </div>
                <div className={styles.center}>
                    <PrettyScrollBar>
                        <ProjectMenu>
                            {this.renderProjects()}
                        </ProjectMenu>
                    </PrettyScrollBar>
                </div>
                <div className={styles.bottom}>
                    <Link
                        className={styles.link}
                        to={Api.getAbsoluteWebsiteUrl(I18n.t('imprintPath'))}
                        target={'_blank'}
                    >
                        {I18n.t('imprint')}
                    </Link>
                    <Link
                        className={styles.link}
                        to={Api.getAbsoluteWebsiteUrl(I18n.t('privacyPath'))}
                        target={'_blank'}
                    >
                        {I18n.t('privacy')}
                    </Link>
                    <VersionNumber />
                </div>
            </div>
        );
    }

    renderCreateProjectButton = () => {
        if (this.props.companyType === CompanyTypes.company) {
            return (
                <MainMenuItem
                    iconType={IconType.plus}
                    isActive={false}
                    text={I18n.t('createNewProject')}
                    onClick={this.props.createNewProject}
                />
            );
        }

        return null;
    };

    renderProjectOverviewButton = () => {
        return (
            <MainMenuItem
                iconType={IconType.home}
                isActive={this.props.activeRoute === Routes.home}
                text={I18n.t('projectOverview')}
                to={Routes.home}
            />
        );
    };

    renderProfileButton = () => {
        const companyId   = _.get(this.props, 'companyId');
        const route       = Routes.companyProfilePart + companyId;
        const activeRoute = _.get(this.props, 'activeRoute', null);
        const isActive    = activeRoute && activeRoute.startsWith(Routes.companyProfilePart);

        if (companyId) {
            return (
                <MainMenuItem
                    iconType={IconType.user}
                    isActive={isActive}
                    text={I18n.t('myProfile')}
                    to={route}
                />
            );
        }

        return null;
    };

    renderDebugMenu = () => {
        if (!Environment.isProduction()) {
            return (
                <>
                    <MainMenuItem
                        iconType={IconType.code}
                        isActive={this.props.activeRoute === Routes.screenDesign}
                        text={I18n.t('screenDesign')}
                        to={Routes.screenDesign}
                    />
                    <MainMenuItem
                        iconType={IconType.code}
                        text={'Undefined route'}
                        to={'/does-not-exist'}
                    />
                    <MainMenuItem
                        iconType={IconType.code}
                        text={'Error route'}
                        to={'/error'}
                    />
                </>
            );
        }

        return null;
    };

    renderProject = (project, index) => {
        if (
            project.state !== ProjectStateTypes.finished &&
            project.bestMatchState !== MatchStateTypes.declined
        ) {
            return (
                <ProjectMenuItem
                    key={index}
                    project={project}
                />
            );
        }

        return null;
    };

    renderProjects = () => {
        return this.props.projects.map(this.renderProject);
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = SplitPaneMenu;

Component.propTypes = {
    activeRoute:      PropTypes.string,
    companyId:        PropTypes.string,
    companyType:      PropTypes.oneOf(Object.values(CompanyTypes)),
    createNewProject: PropTypes.func,
    projects:         PropTypes.array,
};

Component.defaultProps = {
    activeRoute:      null,
    companyId:        null,
    companyType:      null,
    createNewProject: _.noop,
    projects:         [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
