//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React     from 'react';
import styles    from './styles.module.scss';
import PropTypes from '../../PropTypes';

class Component extends React.Component {
    getStyle = () => {
        const style = {};

        if (this.props.backgroundColor) {
            style.backgroundColor = this.props.backgroundColor;
        }

        return style;
    };

    render () {
        const style = this.getStyle();

        return (
            <div
                className={styles.splitPanePanel}
                style={style}
            >
                {this.props.children}
            </div>
        );
    }
}

Component.propTypes = {
    backgroundColor: PropTypes.string,
    children:        PropTypes.children,
};

Component.defaultProps = {
    backgroundColor: null,
    children:        null,
};

export default Component;
