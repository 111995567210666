//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    acceptOffer:                    'acceptOffer',
    callbackRequest:                'callbackRequest',
    closeOverlayCheck:              'closeOverlayCheck',
    createOffer:                    'createOffer',
    createProject:                  'createProject',
    createProjectSuccess:           'createProjectSuccess',
    deleteProject:                  'deleteProject',
    editCompanyCompetences:         'editCompanyCompetences',
    editCompanyDescription:         'editCompanyDescription',
    editCompanyHeader:              'editCompanyHeader',
    editCompanyImages:              'editCompanyImages',
    editCompanyLanguages:           'editCompanyLanguages',
    editCompanyLocations:           'editCompanyLocations',
    editCompanyPlaceOfWorks:        'editCompanyPlaceOfWorks',
    editCompanyReferences:          'editCompanyReferences',
    editCompanySocialMedias:        'editCompanySocialMedias',
    editUserData:                   'editUserData',
    editUserPassword:               'editUserPassword',
    offerAccepted:                  'offerAccepted',
    register:                       'register',
    registerCompany:                'registerCompany',
    registerVideoProduction:        'registerVideoProduction',
    registerVideoProductionSuccess: 'registerVideoProductionSuccess',
    screenDesign:                   'screenDesign',
    watchVideo:                     'watchVideo',
};
