//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update                 from 'immutability-helper';
import { ConfigurationTypes } from '../actions/configuration';

const initialState = {
    configuration: {},
};

// https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-215

export default function (state = initialState, action) {
    switch (action.type) {
        case ConfigurationTypes.FETCH_CONFIGURATION_SUCCESS:
            return update(state, {
                configuration: {
                    $set: action.configuration,
                },
            });

        default:
            return state;
    }
}
