//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                  from 'lodash';
import ComponentHelper    from '../../../helper/ComponentHelper';
import PropTypes          from '../../PropTypes';
import React              from 'react';
import styles             from './styles.module.scss';
import ChoiceSelectorMode from './ChoiceSelectorMode';
import ChoiceWidthMode    from './ChoiceWidthMode';
import classNames         from 'classnames';
import I18n               from 'i18next';
import InputError         from '../InputError';

class ChoiceSelector extends React.Component {
    static mapHydraDataForValueField = (inputData, valuePropertyName, addUndefinedValue, selectedValues) => {
        const data = [];

        if (inputData) {
            for (const inputDataEntry of inputData) {
                const selected = selectedValues && selectedValues.indexOf(inputDataEntry.iri) > -1;

                data.push({
                    selected,
                    text:      inputDataEntry[valuePropertyName],
                    value:     inputDataEntry.iri,
                    widthMode: ChoiceWidthMode.half,
                });
            }
        }

        if (addUndefinedValue) {
            data.push({
                text:  I18n.t('fillLaterChoiceText'),
                value: null,
            });
        }

        return data;
    };

    render () {
        return (
            <>
                <div
                    className={classNames(
                        styles.wrapper,
                        {
                            [styles.alignSelect]: this.props.paddingRight,
                        },
                    )}
                >
                    {this.props.choices.map(this.renderChoice)}
                </div>
                {this.renderError()}
            </>
        );
    }

    renderChoice = (choiceData, index) => {
        return (
            <div
                className={classNames(
                    styles.choice,
                    {
                        [styles.choiceWidthFull]: choiceData.widthMode === ChoiceWidthMode.full,
                        [styles.choiceWidthHalf]: choiceData.widthMode === ChoiceWidthMode.half,
                        [styles.choiceSelected]:  choiceData.selected,
                    },
                )}
                key={'choice' + choiceData.text}
                onClick={() => {
                    this.props.choiceClicked(choiceData, index);
                }}
            >
                <div
                    className={classNames(
                        styles.choiceInner,
                        {
                            [styles.choiceInnerError]: this.props.errorText,
                        },
                    )}
                >
                    <div className={styles.choiceCheckMark}>
                        <div className={styles.choiceCheckMarkInner1}>
                            <div className={styles.choiceCheckMarkInner2} />
                        </div>
                    </div>
                    {choiceData.text}
                </div>
            </div>
        );
    };

    renderError = () => {
        if (this.props.errorText) {
            return (
                <InputError
                    marginTop={-13}
                    text={this.props.errorText}
                />
            );
        }

        return null;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ChoiceSelector;

Component.propTypes = {
    choiceClicked: PropTypes.func,
    choices:       PropTypes.arrayOf(PropTypes.shape({
        selected:  PropTypes.bool,
        text:      PropTypes.text,
        value:     PropTypes.any,
        widthMode: PropTypes.oneOf(Object.keys(ChoiceWidthMode)),
    })),
    errorText:     PropTypes.string,
    mode:          PropTypes.oneOf(Object.keys(ChoiceSelectorMode)),
    paddingRight:  PropTypes.bool,
};

Component.defaultProps = {
    choiceClicked: _.noop,
    choices:       [],
    errorText:     '',
    mode:          ChoiceSelectorMode.singleChoice,
    paddingRight:  false,
};

Component.renderAffectingProps = [
    'choices',
    'errorText',
    'mode',
];

Component.renderAffectingStates = [];

export default Component;
