//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const ProjectTypes = {
    DELETE_PROJECT:         'Project/DELETE_PROJECT',
    DELETE_PROJECT_CANCEL:  'Project/DELETE_PROJECT_CANCEL',
    DELETE_PROJECT_CONFIRM: 'Project/DELETE_PROJECT_CONFIRM',
    DELETE_PROJECT_FAILED:  'Project/DELETE_PROJECT_FAILED',
    DELETE_PROJECT_SUCCESS: 'Project/DELETE_PROJECT_SUCCESS',
    FETCH_PROJECT:          'Project/FETCH_PROJECT',
    FETCH_PROJECT_FAILED:   'Project/FETCH_PROJECT_FAILED',
    FETCH_PROJECT_SUCCESS:  'Project/FETCH_PROJECT_SUCCESS',
    FETCH_PROJECTS:         'Project/FETCH_PROJECTS',
    FETCH_PROJECTS_FAILED:  'Project/FETCH_PROJECTS_FAILED',
    FETCH_PROJECTS_SUCCESS: 'Project/FETCH_PROJECTS_SUCCESS',
};

const deleteProject = makeActionCreator(
    ProjectTypes.DELETE_PROJECT,
    {
        projectIri: null,
    },
);

const deleteProjectConfirm = makeActionCreator(
    ProjectTypes.DELETE_PROJECT_CONFIRM,
);

const deleteProjectFailed = makeActionCreator(
    ProjectTypes.DELETE_PROJECT_FAILED,
);

const deleteProjectSuccess = makeActionCreator(
    ProjectTypes.DELETE_PROJECT_SUCCESS,
);

const fetchProject = makeActionCreator(
    ProjectTypes.FETCH_PROJECT,
    {
        projectIri: null,
    },
);

const fetchProjectFailed = makeActionCreator(
    ProjectTypes.FETCH_PROJECT_FAILED,
);

const fetchProjectSuccess = makeActionCreator(
    ProjectTypes.FETCH_PROJECT_SUCCESS,
    {
        project: null,
    },
);

const fetchProjects = makeActionCreator(
    ProjectTypes.FETCH_PROJECTS,
);

const fetchProjectsFailed = makeActionCreator(
    ProjectTypes.FETCH_PROJECTS_FAILED,
);

const fetchProjectsSuccess = makeActionCreator(
    ProjectTypes.FETCH_PROJECTS_SUCCESS,
    {
        projects: null,
    },
);

export const ProjectActions = {
    deleteProject,
    deleteProjectConfirm,
    deleteProjectFailed,
    deleteProjectSuccess,
    fetchProject,
    fetchProjectFailed,
    fetchProjectSuccess,
    fetchProjects,
    fetchProjectsFailed,
    fetchProjectsSuccess,
};