//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                          from 'lodash';
import React                      from 'react';
import WatchVideoOverlayStateless from '../../stateless/WatchVideoOverlay';
import { bindActionCreators }     from 'redux';
import { compose }                from 'redux';
import { connect }                from 'react-redux';
import { goBack }                 from 'connected-react-router';
import { OverlayActions }         from '../../../store/actions/overlay';
import { withRouter }             from 'react-router-dom';

class Component extends React.Component {
    closeButtonPressed = () => {
        this.props.closeOverlay();
    };

    render () {
        return (
            <WatchVideoOverlayStateless
                closeButtonPressed={this.closeButtonPressed}
                videoUrl={this.props.videoUrl}
            />
        );
    }
}

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = state => (
    {
        videoUrl: state.video.video,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        OverlayActions,
        {
            goBack,
        },
    ),
    dispatch,
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Component);
