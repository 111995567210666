//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper                from '../../../helper/ComponentHelper';
import PropTypes                      from '../../PropTypes';
import React                          from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';

class Link extends React.Component {
    render() {
        return (
            <ReactRouterDomLink
                className={this.props.className}
                to={this.props.to}
                params={this.props.params}
                target={this.props.target}
            >
                {this.props.children}
            </ReactRouterDomLink>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Link;

Component.propTypes = {
    children:  PropTypes.children,
    className: PropTypes.string,
    params:    PropTypes.object,
    target:    PropTypes.string,
    to:        PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
};

Component.defaultProps = {
    children:  [],
    className: '',
    params:    {},
    target:    null,
    to:        null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
