//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update           from 'immutability-helper';
import { OverlayTypes } from '../actions/overlay';
import Overlays         from '../../constants/Overlays';

const initialState = {
    lastOverlay: null,
    lastRoute:   null,
};

const skipCloseCheckOverlays = [
    Overlays.closeOverlayCheck,
];

const skipCloseCheckOverlaysAndReset = [
    Overlays.acceptOffer,
    Overlays.callbackRequest,
    Overlays.createOffer,
    Overlays.createProjectSuccess,
    Overlays.deleteProject,
    Overlays.editCompanyCompetences,
    Overlays.editCompanyDescription,
    Overlays.editCompanyHeader,
    Overlays.editCompanyImages,
    Overlays.editCompanyLanguages,
    Overlays.editCompanyLocations,
    Overlays.editCompanyPlaceOfWorks,
    Overlays.editCompanyReferences,
    Overlays.editCompanySocialMedias,
    Overlays.editUserData,
    Overlays.editUserPassword,
    Overlays.offerAccepted,
    Overlays.register,
    Overlays.registerVideoProduction,
    Overlays.registerVideoProductionSuccess,
    Overlays.screenDesign,
    Overlays.watchVideo,
];

// https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-215

export default function (state = initialState, action) {
    switch (action.type) {
        case OverlayTypes.RESET_LAST_ROUTE:
            return update(state, {
                lastRoute: {
                    $set: null,
                },
            });

        case OverlayTypes.SAVE_LAST_ROUTE:
            return update(state, {
                lastRoute: {
                    $set: action.lastRoute,
                },
            });

        case OverlayTypes.SET_OPEN_OVERLAY:
            if (skipCloseCheckOverlaysAndReset.includes(action.overlay)) {
                return update(state, {
                    lastOverlay: {
                        $set: null,
                    },
                });
            }

            if (!skipCloseCheckOverlays.includes(action.overlay)) {
                return update(state, {
                    lastOverlay: {
                        $set: action.overlay,
                    },
                });
            }

            return state;

        default:
            return state;
    }
}
