//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { all }        from 'redux-saga/effects';
import { put }        from 'redux-saga/effects';
import { select }     from 'redux-saga/effects';
import { takeEvery }  from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { REHYDRATE }  from 'redux-persist';

// Types
import { ActiveProjectTypes }     from '../actions/activeProject';
import { BranchTypes }            from '../actions/branch';
import { CallbackRequestTypes }   from '../actions/callbackRequest';
import { CompanyPositionTypes }   from '../actions/companyPosition';
import { CompanyTypes }           from '../actions/company';
import { CompetenceTypes }        from '../actions/competence';
import { ConfigurationTypes }     from '../actions/configuration';
import { CreateEditOfferTypes }   from '../actions/createEditOffer';
import { CreateEditProjectTypes } from '../actions/createEditProject';
import { GoalTypes }              from '../actions/goal';
import { LanguageTypes }          from '../actions/language';
import { LicenseDurationTypes }   from '../actions/licenseDuration';
import { MessageComposerTypes }   from '../actions/messageComposer';
import { NavigationTypes }        from '../actions/navigation';
import { OverlayTypes }           from '../actions/overlay';
import { PlaceOfWorkTypes }       from '../actions/placeOfWork';
import { ProjectTypes }           from '../actions/project';
import { TerritoriesTypes }       from '../actions/territory';
import { UserActions }            from '../actions/user';
import { UserTypes }              from '../actions/user';
import { VideoFormatTypes }       from '../actions/videoFormat';
import { VideoTypes }             from '../actions/video';
import { VideoTypeTypes }         from '../actions/videoType';

// Actions
import { AlertBoxActions } from '../actions/alertBox';
import { LoadingActions }  from '../actions/loading';
import { LOCATION_CHANGE } from 'connected-react-router';

// Sagas
import ActiveProjectSagas     from './activeProject';
import BranchSagas            from './branch';
import CallbackRequestSagas   from './callbackRequest';
import CompanyPositionSagas   from './companyPosition';
import CompanySagas           from './company';
import CompetenceSagas        from './competence';
import ConfigurationSagas     from './configuration';
import CreateEditOfferSagas   from './createEditOffer';
import CreateEditProjectSagas from './createEditProject';
import ErrorSaga              from './error';
import GoalSagas              from './goal';
import LanguageSagas          from './language';
import LicenseDurationSagas   from './licenseDuration';
import MessageComposerSagas   from './messageComposer';
import NavigationSagas        from './navigation';
import OverlaySagas           from './overlay';
import PlaceOfWorkSagas       from './placeOfWork';
import ProjectSagas           from './project';
import TerritorySagas         from './territory';
import UserSagas              from './user';
import VideoFormatSagas       from './videoFormat';
import VideoSagas             from './video';
import VideoTypeSagas         from './videoType';

const root = function* () {
    yield all([
        // @formatter:off
        takeLatest(LOCATION_CHANGE,                                                 NavigationSagas.locationChange),

        takeLatest(ActiveProjectTypes.ACCEPT_OFFER,                                 ActiveProjectSagas.acceptOffer),
        takeLatest(ActiveProjectTypes.ACCEPT_OFFER_SUCCESS,                         ActiveProjectSagas.acceptOfferSuccess),
        takeLatest(ActiveProjectTypes.FETCH_MATCHES,                                ActiveProjectSagas.fetchMatches),
        takeLatest(ActiveProjectTypes.FETCH_MATCHES_FOR_ACTIVE_PROJECT,             ActiveProjectSagas.fetchMatchesForActiveProject),
        takeLatest(ActiveProjectTypes.FETCH_MATCHES_SUCCESS,                        ActiveProjectSagas.fetchMatchesSuccess),
        takeLatest(ActiveProjectTypes.FETCH_MESSAGES,                               ActiveProjectSagas.fetchMessages),
        takeLatest(ActiveProjectTypes.FETCH_MESSAGES_SUCCESS,                       ActiveProjectSagas.fetchMessagesSuccess),
        takeLatest(ActiveProjectTypes.FETCH_OFFERS,                                 ActiveProjectSagas.fetchOffers),
        takeLatest(ActiveProjectTypes.OPEN_ACCEPT_OFFER_OVERLAY,                    ActiveProjectSagas.openAcceptOfferOverlay),
        takeLatest(ActiveProjectTypes.OPEN_OFFER_ACCEPTED_OVERLAY,                  ActiveProjectSagas.openOfferAcceptedOverlay),
        takeLatest(ActiveProjectTypes.SEND_ACTIVE_MESSAGE,                          ActiveProjectSagas.sendActiveMessage),
        takeLatest(ActiveProjectTypes.SEND_ACTIVE_MESSAGE_FAILED,                   ActiveProjectSagas.sendActiveMessageFailed),
        takeLatest(ActiveProjectTypes.SEND_ACTIVE_MESSAGE_SUCCESS,                  ActiveProjectSagas.sendActiveMessageSuccess),
        takeLatest(ActiveProjectTypes.SET_ACTIVE_MATCH,                             ActiveProjectSagas.setActiveMatch),
        takeLatest(ActiveProjectTypes.SET_MATCH_INTERACTED,                         ActiveProjectSagas.setMatchInteracted),
        takeLatest(ActiveProjectTypes.SET_MATCH_INTERACTED_SUCCESS,                 ActiveProjectSagas.setMatchInteractedSuccess),
        takeLatest(ActiveProjectTypes.SET_ACTIVE_PROJECT,                           ActiveProjectSagas.setActiveProject),

        takeLatest(BranchTypes.FETCH_BRANCHES,                                      BranchSagas.fetchBranches),

        takeLatest(CallbackRequestTypes.SEND_CALLBACK_REQUEST,                      CallbackRequestSagas.sendCallbackRequest),
        takeLatest(CallbackRequestTypes.SEND_CALLBACK_REQUEST_SUCCESS,              CallbackRequestSagas.sendCallbackRequestSuccess),

        takeEvery(CompanyTypes.UPDATE_COMPANY_IMAGE,                                CompanySagas.uploadCompanyImage),
        takeLatest(CompanyTypes.CREATE_COMPANY,                                     CompanySagas.createCompany),
        takeLatest(CompanyTypes.CREATE_COMPANY_FAILED,                              CompanySagas.createCompanyFailed),
        takeLatest(CompanyTypes.CREATE_COMPANY_AND_USER,                            CompanySagas.createCompanyAndUser),
        takeLatest(CompanyTypes.CREATE_COMPANY_AND_USER_AND_PROJECT,                CompanySagas.createCompanyAndUserAndProject),
        takeLatest(CompanyTypes.CREATE_OR_DELETE_COMPANY_IMAGES,                    CompanySagas.createOrDeleteCompanyImages),
        takeLatest(CompanyTypes.CREATE_OR_DELETE_COMPANY_IMAGES_SUCCESS,            CompanySagas.createOrDeleteCompanyImagesSuccess),
        takeLatest(CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_LOCATIONS,             CompanySagas.createOrUpdateOwnCompanyLocations),
        takeLatest(CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_LOCATIONS_SUCCESS,     CompanySagas.createOrUpdateOwnCompanyLocationsSuccess),
        takeLatest(CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_REFERENCES,            CompanySagas.createOrUpdateOwnCompanyReferences),
        takeLatest(CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_REFERENCES_SUCCESS,    CompanySagas.createOrUpdateOwnCompanyReferencesSuccess),
        takeLatest(CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_SOCIAL_MEDIAS,         CompanySagas.createOrUpdateOwnCompanySocialMedias),
        takeLatest(CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_SOCIAL_MEDIAS_FAILED,  CompanySagas.createOrUpdateOwnCompanySocialMediasFailed),
        takeLatest(CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_SOCIAL_MEDIAS_SUCCESS, CompanySagas.createOrUpdateOwnCompanySocialMediasSuccess),
        takeLatest(CompanyTypes.EDIT_OWN_COMPANY_COMPETENCES,                       CompanySagas.editOwnCompanyCompetences),
        takeLatest(CompanyTypes.EDIT_OWN_COMPANY_HEADER,                            CompanySagas.editOwnCompanyHeader),
        takeLatest(CompanyTypes.EDIT_OWN_COMPANY_IMAGES,                            CompanySagas.editOwnCompanyImages),
        takeLatest(CompanyTypes.EDIT_OWN_COMPANY_INFORMATION,                       CompanySagas.editOwnCompanyInformation),
        takeLatest(CompanyTypes.EDIT_OWN_COMPANY_LANGUAGES,                         CompanySagas.editOwnCompanyLanguages),
        takeLatest(CompanyTypes.EDIT_OWN_COMPANY_LOCATIONS,                         CompanySagas.editOwnCompanyLocations),
        takeLatest(CompanyTypes.EDIT_OWN_COMPANY_PLACE_OF_WORKS,                    CompanySagas.editOwnCompanyPlaceOfWorks),
        takeLatest(CompanyTypes.EDIT_OWN_COMPANY_REFERENCES,                        CompanySagas.editOwnCompanyReferences),
        takeLatest(CompanyTypes.EDIT_OWN_COMPANY_SOCIAL_MEDIAS,                     CompanySagas.editOwnCompanySocialMedias),
        takeLatest(CompanyTypes.FETCH_COMPANY,                                      CompanySagas.fetchCompany),
        takeLatest(CompanyTypes.FETCH_OWN_COMPANY,                                  CompanySagas.fetchOwnCompany),
        takeLatest(CompanyTypes.UPDATE_COMPANY,                                     CompanySagas.updateCompany),
        takeLatest(CompanyTypes.UPDATE_COMPANY_FAILED,                              CompanySagas.updateCompanyFailed),
        takeLatest(CompanyTypes.UPDATE_COMPANY_BANNER,                              CompanySagas.uploadCompanyBanner),
        takeLatest(CompanyTypes.UPDATE_COMPANY_LOGO,                                CompanySagas.uploadCompanyLogo),
        takeLatest(CompanyTypes.UPDATE_COMPANY_SUCCESS,                             CompanySagas.updateCompanySuccess),
        takeLatest(CompanyTypes.UPDATE_OWN_COMPANY,                                 CompanySagas.updateOwnCompany),
        takeLatest(CompanyTypes.UPDATE_OWN_COMPANY_FAILED,                          CompanySagas.updateOwnCompanyFailed),

        takeLatest(CompanyPositionTypes.FETCH_COMPANY_POSITIONS,                    CompanyPositionSagas.fetchCompanyPositions),

        takeLatest(ConfigurationTypes.FETCH_CONFIGURATION,                          ConfigurationSagas.fetchConfiguration),

        takeLatest(CompetenceTypes.FETCH_COMPETENCES,                               CompetenceSagas.fetchCompetences),

        takeLatest(CreateEditOfferTypes.ADD_OFFER,                                  CreateEditOfferSagas.addOffer),
        takeLatest(CreateEditOfferTypes.CREATE_OFFER,                               CreateEditOfferSagas.createOffer),
        takeLatest(CreateEditOfferTypes.CREATE_OFFER_FAILED,                        CreateEditOfferSagas.createOfferFailed),
        takeLatest(CreateEditOfferTypes.CREATE_OFFER_SUCCESS,                       CreateEditOfferSagas.createOfferSuccess),
        takeLatest(CreateEditOfferTypes.EDIT_OFFER,                                 CreateEditOfferSagas.editOffer),
        takeLatest(CreateEditOfferTypes.UPDATE_OFFER,                               CreateEditOfferSagas.updateOffer),
        takeLatest(CreateEditOfferTypes.UPDATE_OFFER_FAILED,                        CreateEditOfferSagas.updateOfferFailed),
        takeLatest(CreateEditOfferTypes.UPDATE_OFFER_SUCCESS,                       CreateEditOfferSagas.updateOfferSuccess),
        takeEvery(CreateEditOfferTypes.UPLOAD_OFFER_FILE,                           CreateEditOfferSagas.uploadOfferFile),
        takeEvery(CreateEditOfferTypes.UPLOAD_OFFER_FILE_FAILED,                    CreateEditOfferSagas.uploadOfferFileFailed),
        takeEvery(CreateEditOfferTypes.UPLOAD_MATCH_FILE,                           CreateEditOfferSagas.uploadMatchFile),
        takeEvery(CreateEditOfferTypes.UPLOAD_MATCH_FILE_FAILED,                    CreateEditOfferSagas.uploadMatchFileFailed),

        takeLatest(CreateEditProjectTypes.CREATE_PROJECT,                           CreateEditProjectSagas.createProject),
        takeLatest(CreateEditProjectTypes.CREATE_PROJECT_FAILED,                    CreateEditProjectSagas.createProjectFailed),
        takeLatest(CreateEditProjectTypes.CREATE_PROJECT_SUCCESS,                   CreateEditProjectSagas.createProjectSuccess),
        takeLatest(CreateEditProjectTypes.EDIT_PROJECT,                             CreateEditProjectSagas.editProject),
        takeLatest(CreateEditProjectTypes.NEW_PROJECT_OPEN,                         CreateEditProjectSagas.newProjectOpen),
        takeLatest(CreateEditProjectTypes.SET_LICENSE_DURATION,                     CreateEditProjectSagas.setLicenseDuration),
        takeLatest(CreateEditProjectTypes.SET_TERRITORY,                            CreateEditProjectSagas.setTerritory),
        takeLatest(CreateEditProjectTypes.UPDATE_PROJECT,                           CreateEditProjectSagas.updateProject),
        takeLatest(CreateEditProjectTypes.UPDATE_PROJECT_FAILED,                    CreateEditProjectSagas.updateProjectFailed),
        takeLatest(CreateEditProjectTypes.UPDATE_PROJECT_SUCCESS,                   CreateEditProjectSagas.updateProjectSuccess),

        takeLatest(GoalTypes.FETCH_GOALS,                                           GoalSagas.fetchGoals),

        takeLatest(LanguageTypes.FETCH_LANGUAGES,                                   LanguageSagas.fetchLanguages),

        takeLatest(LicenseDurationTypes.FETCH_LICENSE_DURATIONS,                    LicenseDurationSagas.fetchLicenseDurations),

        takeEvery(MessageComposerTypes.UPLOAD_FILE,                                 MessageComposerSagas.uploadFile),
        takeEvery(MessageComposerTypes.UPLOAD_FILE_FAILED,                          MessageComposerSagas.uploadFileFailed),

        takeLatest(NavigationTypes.REDIRECT,                                        NavigationSagas.redirect),

        takeLatest(OverlayTypes.CLOSE_OVERLAY,                                      OverlaySagas.closeOverlay),
        takeLatest(OverlayTypes.CLOSE_OVERLAY_CHECK,                                OverlaySagas.closeOverlayCheck),
        takeLatest(OverlayTypes.OVERLAY_CLOSED,                                     OverlaySagas.overlayClosed),
        takeLatest(OverlayTypes.REOPEN_OVERLAY,                                     OverlaySagas.reopenOverlay),

        takeLatest(PlaceOfWorkTypes.FETCH_PLACE_OF_WORK,                            PlaceOfWorkSagas.fetchPlaceOfWorks),

        takeLatest(ProjectTypes.DELETE_PROJECT,                                     ProjectSagas.deleteProject),
        takeLatest(ProjectTypes.DELETE_PROJECT_CONFIRM,                             ProjectSagas.deleteProjectConfirm),
        takeLatest(ProjectTypes.DELETE_PROJECT_FAILED,                              ProjectSagas.deleteProjectFailed),
        takeLatest(ProjectTypes.DELETE_PROJECT_SUCCESS,                             ProjectSagas.deleteProjectSuccess),
        takeLatest(ProjectTypes.FETCH_PROJECTS,                                     ProjectSagas.fetchProjects),
        takeLatest(ProjectTypes.FETCH_PROJECT,                                      ProjectSagas.fetchProject),

        takeLatest(TerritoriesTypes.FETCH_TERRITORIES,                              TerritorySagas.fetchTerritories),

        takeLatest(UserTypes.CREATE_USER,                                           UserSagas.createUser),
        takeLatest(UserTypes.CREATE_USER_SUCCESS,                                   UserSagas.createUserSuccess),
        takeLatest(UserTypes.EDIT_OWN_USER_DATA,                                    UserSagas.editOwnUserData),
        takeLatest(UserTypes.EDIT_OWN_USER_PASSWORD,                                UserSagas.editOwnUserPassword),
        takeLatest(UserTypes.FETCH_USER,                                            UserSagas.fetchUser),
        takeLatest(UserTypes.LOAD_NONE_SESSION_DEPENDING_DATA,                      UserSagas.loadNoneSessionDependingData),
        takeLatest(UserTypes.LOAD_SESSION_DEPENDING_DATA,                           UserSagas.loadSessionDependingData),
        takeLatest(UserTypes.LOGIN,                                                 UserSagas.login),
        takeLatest(UserTypes.LOGIN_FAILED,                                          UserSagas.loginFailed),
        takeLatest(UserTypes.LOGIN_SUCCEEDED,                                       UserSagas.loginSucceeded),
        takeLatest(UserTypes.LOGOUT,                                                UserSagas.logout),
        takeLatest(UserTypes.REGISTER_OPEN,                                         UserSagas.registerOpen),
        takeLatest(UserTypes.REGISTER_COMPANY,                                      UserSagas.registerCompany),
        takeLatest(UserTypes.REGISTER_VIDEO_PRODUCTION,                             UserSagas.registerVideoProduction),
        takeLatest(UserTypes.REGISTER_VIDEO_PRODUCTION_SUCCESS,                     UserSagas.registerVideoProductionSuccess),
        takeLatest(UserTypes.RESET_PASSWORD,                                        UserSagas.resetPassword),
        takeLatest(UserTypes.RESET_PASSWORD_FAILED,                                 UserSagas.resetPasswordFailed),
        takeLatest(UserTypes.RESET_PASSWORD_SUCCESS,                                UserSagas.resetPasswordSuccess),
        takeLatest(UserTypes.SET_NEW_PASSWORD,                                      UserSagas.setNewPassword),
        takeLatest(UserTypes.SET_NEW_PASSWORD_FAILED,                               UserSagas.setNewPasswordFailed),
        takeLatest(UserTypes.SET_NEW_PASSWORD_SUCCESS,                              UserSagas.setNewPasswordSuccess),
        takeLatest(UserTypes.UPDATE_USER,                                           UserSagas.updateUser),
        takeLatest(UserTypes.UPDATE_USER_FAILED,                                    UserSagas.updateUserFailed),
        takeLatest(UserTypes.UPDATE_USER_PASSWORD,                                  UserSagas.updateUserPassword),
        takeLatest(UserTypes.UPDATE_USER_SUCCESS,                                   UserSagas.updateUserSuccess),
        takeLatest(UserTypes.VERIFY_REGISTRATION,                                   UserSagas.verifyRegistration),
        takeLatest(UserTypes.VERIFY_REGISTRATION_FAILED,                            UserSagas.verifyRegistrationFailed),
        takeLatest(UserTypes.VERIFY_REGISTRATION_SUCCEEDED,                         UserSagas.verifyRegistrationSucceeded),

        takeLatest(VideoFormatTypes.FETCH_VIDEO_FORMATS,                            VideoFormatSagas.fetchVideoFormats),

        takeLatest(VideoTypeTypes.FETCH_VIDEO_TYPES,                                VideoTypeSagas.fetchVideoTypes),

        takeLatest(VideoTypes.OPEN_VIDEO,                                           VideoSagas.openVideo),

        takeLatest([
            ActiveProjectTypes.FETCH_MATCHES_FAILED,
            ActiveProjectTypes.FETCH_MESSAGES_FAILED,
            CompanyTypes.FETCH_COMPANY_FAILED,
            CompanyTypes.FETCH_OWN_COMPANY_FAILED,
            CompetenceTypes.FETCH_COMPETENCES_FAILED,
            LanguageTypes.FETCH_LANGUAGES_FAILED,
            PlaceOfWorkTypes.FETCH_PLACE_OF_WORK_FAILED,
            ProjectTypes.FETCH_PROJECT_FAILED,
            ProjectTypes.FETCH_PROJECTS_FAILED,
            UserTypes.FETCH_USER_FAILED,
        ],                                                                          ErrorSaga.sendGoogleAnalyticsEvent),

        takeLatest(REHYDRATE,                                                       ActiveProjectSagas.updateActiveProjectFromUrl),
        takeLatest(REHYDRATE,                                                       ActiveProjectSagas.updateProjectDataAutomatically),
        takeLatest(REHYDRATE,                                                       CreateEditOfferSagas.rehydrate),
        takeLatest(REHYDRATE,                                                       MessageComposerSagas.rehydrate),
        takeLatest(REHYDRATE,                                                       UserSagas.restoreToken),
        takeLatest(REHYDRATE,                                                       rehydrationDone),
        // @formatter:on
    ]);
};

const rehydrationDone = function* () {
    yield preLoading();
    yield put(LoadingActions.clearLoading());
    yield put(AlertBoxActions.clearAlerts());
};

const preLoading = function* () {
    const token = yield select(state => state.user.user.token);

    yield put(UserActions.loadNoneSessionDependingData());

    if (token) {
        yield put(UserActions.loadSessionDependingData());
    }
};

export default {
    root,
};
