//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as Api                 from '../../api';
import _                        from 'lodash';
import CompanyTypes             from '../../constants/CompanyTypes';
import HydraHelper              from '../../helper/Hydra';
import I18n                     from 'i18next';
import OverlayManager           from '../../components/connected/OverlayManager';
import Overlays                 from '../../constants/Overlays';
import ProjectSubMenuTypes      from '../../constants/ProjectSubMenuTypes';
import Routes                   from '../../constants/Routes';
import { ActiveProjectActions } from '../actions/activeProject';
import { AlertBoxActions }      from '../actions/alertBox';
import { call }                 from 'redux-saga/effects';
import { delay }                from 'redux-saga/effects';
import { put }                  from 'redux-saga/effects';
import { select }               from 'redux-saga/effects';
import { ProjectActions }       from '../actions/project';
import { push }                 from 'connected-react-router';

const acceptOffer = function* () {
    const offer = yield select(state => state.activeProject.activeOffer);
    const match = yield select(state => state.activeProject.activeMatch);

    const response = yield call(
        Api.acceptOffer,
        offer.iri,
        match.iri,
    );

    if (response.ok) {
        yield put(ActiveProjectActions.acceptOfferSuccess());
    } else {
        yield put(ActiveProjectActions.acceptOfferFailed());
    }
};

const acceptOfferSuccess = function* () {
    yield put(ActiveProjectActions.openOfferAcceptedOverlay());
};

const fetchMatches = function* (action) {
    const response = yield call(
        Api.fetchMatches,
        action.projectId,
    );

    if (response.ok) {
        const cleanHydraResponse = HydraHelper.cleanupObject(response.data);
        const matches            = cleanHydraResponse.member;

        yield put(ActiveProjectActions.fetchMatchesSuccess({
            activeMatchIri: action.activeMatchIri,
            matches,
            projectId:      action.projectId,
            silent:         action.silent,
        }));
    } else {
        yield put(ActiveProjectActions.fetchMatchesFailed({
            silent: action.silent,
        }));
    }
};

const fetchMatchesForActiveProject = function* (action) {
    const activeProjectState = yield select(state => state.activeProject);
    const activeMatchIri     = _.get(activeProjectState, 'activeMatch.iri');
    const projectIri         = _.get(activeProjectState, 'activeProject.iri');

    if (projectIri) {
        yield put(ActiveProjectActions.fetchMatches({
            activeMatchIri,
            projectId: projectIri,
            silent:    action.silent,
        }));
    }
};

const fetchMatchesSuccess = function* (action) {
    const activeProjectState = yield select(state => state.activeProject);
    const subMenuType        = activeProjectState.subMenuType;

    const subActionFetchMessages = function* () {
        if (action.activeMatchIri) {
            yield put(ActiveProjectActions.fetchMessages({
                matchId: action.activeMatchIri,
                silent:  true,
            }));
        } else {
            const matchesByLastMessageTimestamp = activeProjectState.matchesByLastMessageTimestamp;

            if (matchesByLastMessageTimestamp.length) {
                const latestMatchByMessageTimestamp = matchesByLastMessageTimestamp[0];

                yield put(ActiveProjectActions.fetchMessages({
                    matchId: latestMatchByMessageTimestamp.iri,
                    silent:  true,
                }));
            }
        }
    };

    const subActionFetchOffers = function* () {
        if (action.activeMatchIri) {
            yield put(ActiveProjectActions.fetchOffers({
                matchId: action.activeMatchIri,
                silent:  true,
            }));
        } else {
            const matchesByLastOfferTimestamp = activeProjectState.matchesByLastOfferTimestamp;

            if (matchesByLastOfferTimestamp.length) {
                const latestMatchByOfferTimestamp = matchesByLastOfferTimestamp[0];

                yield put(ActiveProjectActions.fetchOffers({
                    matchId: latestMatchByOfferTimestamp.iri,
                    silent:  true,
                }));
            }
        }
    };

    switch (subMenuType) {
        case ProjectSubMenuTypes.match:
            const ownCompany = yield select(state => state.company.ownCompany);

            if (ownCompany.companyType === CompanyTypes.videoProduction) {
                const match = activeProjectState.matches[0];

                yield put(ActiveProjectActions.fetchOffers({
                    matchId: match.iri,
                    silent:  true,
                }));
            }

            break;

        case ProjectSubMenuTypes.messages:
            yield call(subActionFetchMessages);
            yield call(subActionFetchOffers);

            break;

        case ProjectSubMenuTypes.offers:
            yield call(subActionFetchOffers);

            break;
    }
};

const fetchMessages = function* ({ matchId }) {
    const response = yield call(
        Api.fetchMessages,
        matchId,
    );

    if (response.ok) {
        const cleanHydraResponse = HydraHelper.cleanupObject(response.data);
        const messages           = cleanHydraResponse.member;

        yield put(ActiveProjectActions.fetchMessagesSuccess({
            messages,
            matchId,
        }));
    } else {
        yield put(ActiveProjectActions.fetchMessagesFailed());
    }
};

const fetchMessagesSuccess = function* () {
    const activeProjectState = yield select(state => state.activeProject);
    const activeProject      = activeProjectState.activeProject;

    yield put(ProjectActions.fetchProject({
        projectIri: activeProject.iri,
    }));
};

const fetchOffers = function* ({ matchId }) {
    const response = yield call(
        Api.fetchOffers,
        matchId,
    );

    if (response.ok) {
        const cleanHydraResponse = HydraHelper.cleanupObject(response.data);
        const offers             = cleanHydraResponse.member;

        yield put(ActiveProjectActions.fetchOffersSuccess({
            offers,
            matchId,
        }));
    } else {
        yield put(ActiveProjectActions.fetchMessagesFailed());
    }
};

const openAcceptOfferOverlay = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.acceptOffer)));
};

const openOfferAcceptedOverlay = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.offerAccepted)));
};

const sendActiveMessage = function* (action) {
    const userCompanyIri        = yield select(state => state.user.user.company);
    const activeProjectState    = yield select(state => state.activeProject);
    const messageComposerState  = yield select(state => state.messageComposer);
    const activeProject         = activeProjectState.activeProject;
    const activeMatch           = activeProjectState.activeMatch;
    const activeMatchId         = activeMatch.id;
    const message               = messageComposerState.messages[activeMatchId];
    const messageAttachments    = Object.values(_.get(
        messageComposerState,
        [
            'files',
            activeMatchId,
        ],
        {},
    ));
    const messageAttachmentIris = Api.getIriListFromUploadedFileList(messageAttachments);
    const receiverCompanyIri    = (
        activeMatch.offeringCompany.iri === userCompanyIri ?
            activeMatch.searchingCompany.iri :
            activeMatch.offeringCompany.iri
    );

    const response = yield call(
        Api.postMessage,
        message,
        receiverCompanyIri,
        activeMatch.iri,
        activeProject.iri,
        messageAttachmentIris,
    );

    if (response.ok) {
        yield put(ActiveProjectActions.sendActiveMessageSuccess({
            contextKey: activeMatchId,
        }));
    } else {
        yield put(ActiveProjectActions.sendActiveMessageFailed({
            contextKey: activeMatchId,
        }));
    }
};

const sendActiveMessageFailed = function* (action) {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('messageErrorSend'),
    }));
};

const sendActiveMessageSuccess = function* (action) {
    yield put(ActiveProjectActions.fetchMessages({
        matchId: action.contextKey,
    }));
};

const setActiveMatch = function* (action) {
    const activeProjectState = yield select(state => state.activeProject);
    const subMenuType        = activeProjectState.subMenuType;

    switch (subMenuType) {
        case ProjectSubMenuTypes.messages:
            yield put(ActiveProjectActions.fetchMessages({
                matchId: action.match.iri,
            }));
            yield put(ActiveProjectActions.fetchOffers({
                matchId: action.match.iri,
            }));

            break;

        case ProjectSubMenuTypes.offers:
            yield put(ActiveProjectActions.fetchOffers({
                matchId: action.match.iri,
            }));

            break;
    }
};

const setActiveProject = function* (action) {
    if (action.project) {
        let targetRoute    = null;
        let activeMatchIri = null;

        if (action.activeMatch) {
            activeMatchIri = action.activeMatch.iri;
        }

        // We always fetch the matches here since
        // all lists in the side menu are built on this
        yield put(ActiveProjectActions.fetchMatches({
            activeMatchIri,
            projectId: action.project.iri,
            silent:    false,
        }));

        switch (action.subMenuType) {
            case ProjectSubMenuTypes.briefing:
                targetRoute = Routes.briefing;

                break;

            case ProjectSubMenuTypes.offers:
                targetRoute = Routes.offers;

                break;

            case ProjectSubMenuTypes.match:
                targetRoute = Routes.match;

                break;

            case ProjectSubMenuTypes.matches:
                targetRoute = Routes.matches;

                break;

            case ProjectSubMenuTypes.messages:
                targetRoute = Routes.messages;

                break;
        }

        if (targetRoute) {
            yield put(push(targetRoute));
        }
    } else {
        yield put(push(Routes.home));
    }
};

const setMatchInteracted = function* (action) {
    const activeProjectState = yield select(state => state.activeProject);
    const company            = yield select(state => state.company);
    const activeMatch        = activeProjectState.activeMatch;

    if (activeMatch) {
        const projectIri = activeProjectState.activeProject.iri;
        const apiMethod  = (
            activeMatch.searchingCompany.iri === company.ownCompany.iri ?
                Api.updateMatchSearchingCompanyInteracted :
                Api.updateMatchOfferingCompanyInteracted
        );

        const response = yield call(
            apiMethod,
            action.match.iri,
            projectIri,
            true,
        );

        if (response.ok) {
            yield put(ActiveProjectActions.setMatchInteractedSuccess());
        } else {
            yield put(ActiveProjectActions.setMatchInteractedFailed()); // TODO: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-262
        }
    }
};

const setMatchInteractedSuccess = function* (action) {
    yield put(ActiveProjectActions.fetchMatchesForActiveProject({
        silent: true,
    }));
};

const updateActiveProjectFromUrl = function* () {
    const projects        = yield select(state => state.project.projects);
    const windowLocation  = window.location;
    const urlSearchParams = new URLSearchParams(windowLocation.search);
    const activeProject   = urlSearchParams.get('projekt');

    if (activeProject) {
        const locationPathname = windowLocation.pathname;
        const project          = _.find(
            projects,
            {
                id: activeProject,
            },
        );
        let subMenuType        = null;

        switch (locationPathname) {
            case Routes.briefing:
                subMenuType = ProjectSubMenuTypes.briefing;

                break;

            case Routes.offers:
                subMenuType = ProjectSubMenuTypes.offers;

                break;

            case Routes.match:
                subMenuType = ProjectSubMenuTypes.match;

                break;

            case Routes.matches:
                subMenuType = ProjectSubMenuTypes.matches;

                break;

            case Routes.messages:
                subMenuType = ProjectSubMenuTypes.messages;

                break;
        }

        yield put(ActiveProjectActions.setActiveProject({
            project,
            subMenuType,
        }));
    }
};

const updateProjectDataAutomatically = function* () {
    yield put(ActiveProjectActions.fetchMatchesForActiveProject({
        silent: true,
    }));

    yield delay(30000);
    yield call(updateProjectDataAutomatically);
};

export default {
    acceptOffer,
    acceptOfferSuccess,
    fetchMatches,
    fetchMatchesForActiveProject,
    fetchMatchesSuccess,
    fetchMessages,
    fetchMessagesSuccess,
    fetchOffers,
    openAcceptOfferOverlay,
    openOfferAcceptedOverlay,
    sendActiveMessage,
    sendActiveMessageFailed,
    sendActiveMessageSuccess,
    setActiveMatch,
    setActiveProject,
    setMatchInteracted,
    setMatchInteractedSuccess,
    updateActiveProjectFromUrl,
    updateProjectDataAutomatically,
};
