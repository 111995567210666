//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                         from 'react';
import DeleteProjectOverlayStateless from '../../stateless/DeleteProjectOverlay';
import { bindActionCreators }        from 'redux';
import _                             from 'lodash';
import { goBack }                    from 'connected-react-router';
import { compose }                   from 'redux';
import { connect }                   from 'react-redux';
import { withRouter }                from 'react-router-dom';
import { ProjectActions }            from '../../../store/actions/project';

class Component extends React.Component {
    noButtonPressed = () => {
        this.props.goBack();
    };

    render () {
        return (
            <DeleteProjectOverlayStateless
                noButtonPressed={this.noButtonPressed}
                yesButtonPressed={this.yesButtonPressed}
            />
        );
    }

    yesButtonPressed = () => {
        this.props.goBack();
        this.props.deleteProjectConfirm();
    };
}

Component.propTypes = {};

Component.defaultProps = {};

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        ProjectActions,
        {
            goBack,
        },
    ),
    dispatch,
);

export default compose(
    connect(
        null,
        mapDispatchToProps,
    ),
    withRouter,
)(Component);