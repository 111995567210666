//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const CreateEditOfferTypes = {
    ADD_OFFER:                    'CreateEditOffer/ADD_OFFER',
    CLEAR_UNFINISHED_UPLOADS:     'CreateEditOffer/CLEAR_UNFINISHED_UPLOADS',
    DELETE_OFFER_FILE:            'CreateEditOffer/DELETE_OFFER_FILE',
    DELETE_MATCH_FILE:            'CreateEditOffer/DELETE_MATCH_FILE',
    CREATE_OFFER:                 'CreateEditOffer/CREATE_OFFER',
    CREATE_OFFER_FAILED:          'CreateEditOffer/CREATE_OFFER_FAILED',
    CREATE_OFFER_SUCCESS:         'CreateEditOffer/CREATE_OFFER_SUCCESS',
    EDIT_OFFER:                   'CreateEditOffer/EDIT_OFFER',
    REMOVE_OFFER:                 'CreateEditOffer/REMOVE_OFFER',
    SET_OFFER_MESSAGE:            'CreateEditOffer/SET_OFFER_MESSAGE',
    SET_OFFER_NET_PRICE_IN_EURO:  'CreateEditOffer/SET_OFFER_NET_PRICE_IN_EURO',
    SET_OFFER_TITLE:              'CreateEditOffer/SET_OFFER_TITLE',
    UPDATE_OFFER:                 'CreateEditOffer/UPDATE_OFFER',
    UPDATE_OFFER_FAILED:          'CreateEditOffer/UPDATE_OFFER_FAILED',
    UPDATE_OFFER_SUCCESS:         'CreateEditOffer/UPDATE_OFFER_SUCCESS',
    UPLOAD_OFFER_FILE:            'CreateEditOffer/UPLOAD_OFFER_FILE',
    UPLOAD_OFFER_FILE_FAILED:     'CreateEditOffer/UPLOAD_OFFER_FILE_FAILED',
    UPLOAD_OFFER_FILE_SUCCEEDED:  'CreateEditOffer/UPLOAD_OFFER_FILE_SUCCEEDED',
    UPDATE_OFFER_UPLOAD_PROGRESS: 'CreateEditOffer/UPDATE_OFFER_UPLOAD_PROGRESS',
    UPLOAD_MATCH_FILE:            'CreateEditOffer/UPLOAD_MATCH_FILE',
    UPLOAD_MATCH_FILE_FAILED:     'CreateEditOffer/UPLOAD_MATCH_FILE_FAILED',
    UPLOAD_MATCH_FILE_SUCCEEDED:  'CreateEditOffer/UPLOAD_MATCH_FILE_SUCCEEDED',
    UPDATE_MATCH_UPLOAD_PROGRESS: 'CreateEditOffer/UPDATE_MATCH_UPLOAD_PROGRESS',
};

const addOffer = makeActionCreator(
    CreateEditOfferTypes.ADD_OFFER,
);

const clearUnfinishedUploads = makeActionCreator(
    CreateEditOfferTypes.CLEAR_UNFINISHED_UPLOADS,
);

const createOffer = makeActionCreator(
    CreateEditOfferTypes.CREATE_OFFER,
);

const createOfferFailed = makeActionCreator(
    CreateEditOfferTypes.CREATE_OFFER_FAILED,
);

const createOfferSuccess = makeActionCreator(
    CreateEditOfferTypes.CREATE_OFFER_SUCCESS,
);

const deleteOfferFile = makeActionCreator(
    CreateEditOfferTypes.DELETE_OFFER_FILE,
    {
        offerIndex: null,
        id:         null,
    },
);

const deleteMatchFile = makeActionCreator(
    CreateEditOfferTypes.DELETE_MATCH_FILE,
    {
        id: null,
    },
);

const editOffer = makeActionCreator(
    CreateEditOfferTypes.EDIT_OFFER,
    {
        matchAttachments: null,
        offers:           null,
    },
);

const removeOffer = makeActionCreator(
    CreateEditOfferTypes.REMOVE_OFFER,
    {
        offerIndex: null,
    },
);

const setMessage = makeActionCreator(
    CreateEditOfferTypes.SET_OFFER_MESSAGE,
    {
        message:    null,
        offerIndex: null,
    },
);

const setNetPriceInEuro = makeActionCreator(
    CreateEditOfferTypes.SET_OFFER_NET_PRICE_IN_EURO,
    {
        netPriceInEuro: null,
        offerIndex:     null,
    },
);

const setTitle = makeActionCreator(
    CreateEditOfferTypes.SET_OFFER_TITLE,
    {
        offerIndex: null,
        title:      null,
    },
);

const updateOffer = makeActionCreator(
    CreateEditOfferTypes.UPDATE_OFFER,
);

const updateOfferFailed = makeActionCreator(
    CreateEditOfferTypes.UPDATE_OFFER_FAILED,
);

const uploadOfferFile = makeActionCreator(
    CreateEditOfferTypes.UPLOAD_OFFER_FILE,
    {
        file:       null,
        offerIndex: null,
        id:         null,
    },
);

const uploadOfferFileFailed = makeActionCreator(
    CreateEditOfferTypes.UPLOAD_OFFER_FILE_FAILED,
    {
        file:       null,
        offerIndex: null,
        id:         null,
    },
);

const uploadOfferFileSucceeded = makeActionCreator(
    CreateEditOfferTypes.UPLOAD_OFFER_FILE_SUCCEEDED,
    {
        id:         null,
        offerIndex: null,
        iri:        null,
    },
);

const updateOfferUploadProgress = makeActionCreator(
    CreateEditOfferTypes.UPDATE_OFFER_UPLOAD_PROGRESS,
    {
        id:         null,
        offerIndex: null,
        progress:   null,
    },
);

const updateOfferSuccess = makeActionCreator(
    CreateEditOfferTypes.UPDATE_OFFER_SUCCESS,
);

const uploadMatchFile = makeActionCreator(
    CreateEditOfferTypes.UPLOAD_MATCH_FILE,
    {
        file: null,
        id:   null,
    },
);

const uploadMatchFileFailed = makeActionCreator(
    CreateEditOfferTypes.UPLOAD_MATCH_FILE_FAILED,
    {
        file: null,
        id:   null,
    },
);

const uploadMatchFileSucceeded = makeActionCreator(
    CreateEditOfferTypes.UPLOAD_MATCH_FILE_SUCCEEDED,
    {
        id:  null,
        iri: null,
    },
);

const updateMatchUploadProgress = makeActionCreator(
    CreateEditOfferTypes.UPDATE_MATCH_UPLOAD_PROGRESS,
    {
        id:       null,
        progress: null,
    },
);

export const CreateEditOfferActions = {
    addOffer,
    clearUnfinishedUploads,
    createOffer,
    createOfferFailed,
    createOfferSuccess,
    deleteOfferFile,
    deleteMatchFile,
    editOffer,
    removeOffer,
    setMessage,
    setNetPriceInEuro,
    setTitle,
    updateOffer,
    updateOfferFailed,
    updateOfferUploadProgress,
    updateOfferSuccess,
    uploadOfferFile,
    uploadOfferFileFailed,
    uploadOfferFileSucceeded,
    uploadMatchFile,
    uploadMatchFileFailed,
    uploadMatchFileSucceeded,
    updateMatchUploadProgress,
};