//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                    from 'lodash';
import CompanyTypes         from '../../../constants/CompanyTypes';
import ComponentHelper      from '../../../helper/ComponentHelper';
import Environment          from '../../../helper/Environment';
import HeaderButton         from '../HeaderButton';
import HeaderUserMenuButton from '../HeaderUserMenuButton';
import I18n                 from 'i18next';
import Link                 from '../Link';
import PropTypes            from '../../PropTypes';
import React                from 'react';
import Routes               from '../../../constants/Routes';
import styles               from './styles.module.scss';
import { withRouter }       from 'react-router';

class Header extends React.Component {
    getBlogUrl = () => {
        if (!Environment.isProduction()) {
            return I18n.t('blogUrlStaging');
        }

        return I18n.t('blogUrlProduction');
    };

    getWebsiteUrl = () => {
        if (!Environment.isProduction()) {
            return I18n.t('websiteUrlStaging');
        }

        return I18n.t('websiteUrlProduction');
    };

    getLogoLink = () => {
        const logoTitle = (
            <h1>
                {I18n.t('pageTitle')}
            </h1>
        );

        if (this.props.user.token) {
            return (
                <Link to={Routes.home}>
                    {logoTitle}
                </Link>
            );
        }

        return (
            <a href={this.getWebsiteUrl()}>
                {logoTitle}
            </a>
        );
    };

    getWebsitePathByCompanyType = () => {
        const companyType = _.get(this.props, 'company.companyType', null);

        if (companyType === CompanyTypes.videoProduction) {
            return I18n.t('websitePathVideoProduction');
        }

        return I18n.t('websitePathCompany');
    };

    getFAQUrl = () => {
        const companyTypeUrl = this.getWebsitePathByCompanyType();

        if (!Environment.isProduction()) {
            return I18n.t(
                'faqUrlStaging',
                {
                    companyType: companyTypeUrl,
                },
            );
        }

        return I18n.t(
            'faqUrlProduction',
            {
                companyType: companyTypeUrl,
            },
        );
    };

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.containerLeft}>
                    {this.getLogoLink()}
                </div>
                <div className={styles.containerRight}>
                    <HeaderButton
                        label={I18n.t('blog')}
                        to={this.getBlogUrl()}
                        target={'_blank'}
                    />
                    <HeaderButton
                        label={I18n.t('faq')}
                        to={this.getFAQUrl()}
                        target={'_blank'}
                    />
                    {this.renderUserMenu()}
                </div>
            </div>
        );
    }

    renderUserMenu = () => {
        if (this.props.company) {
            return (
                <HeaderUserMenuButton
                    company={this.props.company}
                    user={this.props.user}
                />
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Header;

Component.propTypes = {
    company: PropTypes.object,
    user:    PropTypes.object,
};

Component.defaultProps = {
    company: null,
    user:    null,
};

Component.renderAffectingProps = [
    'company',
    'user',
];

Component.renderAffectingStates = [];

export default withRouter(Component);
