//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import GifWrapper             from '../../stateless/GifWrapper';
import I18n                   from 'i18next';
import Overlay                from '../Overlay';
import OverlayButton          from '../../stateless/OverlayButton';
import OverlayButtonTheme     from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper   from '../../stateless/OverlayButtonWrapper';
import OverlayPane            from '../../stateless/OverlayPane';
import Overlays               from '../../../constants/Overlays';
import PropTypes              from '../../PropTypes';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { connect }            from 'react-redux';
import { OverlayActions }     from '../../../store/actions/overlay';
import { withRouter }         from 'react-router-dom';

class Component extends React.Component {
    render() {
        return (
            <Overlay
                id={Overlays.closeOverlayCheck}
                title={I18n.t('closeOverlayConfirmTitle')}
                isCheckOverlay={true}
            >
                <OverlayPane
                    content={this.renderContent()}
                    footer={this.renderFooter()}
                    description={I18n.t(
                        'closeOverlayConfirmText',
                        {
                            overlayName: I18n.t(this.props.lastOverlayName + 'ReadableName'),
                        },
                    )}
                    scrollingAllowed={false}
                />
            </Overlay>
        );
    }

    renderContent = () => {
        return (
            <GifWrapper imageSource={require('../../../assets/images/ok-charlie-day.gif')} />
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.props.overlayClosed}
                    standAlone={false}
                    text={I18n.t('yes')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.props.reopenOverlay}
                    standAlone={false}
                    text={I18n.t('no')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };
}

Component.propTypes = {
    lastOverlayName: PropTypes.string,
};

Component.defaultProps = {
    lastOverlayName: null,
};

const mapStateToProps = state => (
    {
        lastOverlayName: state.overlay.lastOverlay,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        OverlayActions,
    ),
    dispatch,
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Component);
