//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const LanguageTypes = {
    FETCH_LANGUAGES:         'Language/FETCH_LANGUAGES',
    FETCH_LANGUAGES_FAILED:  'Language/FETCH_LANGUAGES_FAILED',
    FETCH_LANGUAGES_SUCCESS: 'Language/FETCH_LANGUAGES_SUCCESS',
};

const fetchLanguages = makeActionCreator(
    LanguageTypes.FETCH_LANGUAGES,
);

const fetchLanguagesFailed = makeActionCreator(
    LanguageTypes.FETCH_LANGUAGES_FAILED,
);

const fetchLanguagesSuccess = makeActionCreator(
    LanguageTypes.FETCH_LANGUAGES_SUCCESS,
    {
        languages: null,
    },
);

export const LanguageActions = {
    fetchLanguages,
    fetchLanguagesFailed,
    fetchLanguagesSuccess,
};
