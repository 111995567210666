//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import ComponentHelper        from '../../../helper/ComponentHelper';
import DropDown               from '../../stateless/DropDown';
import PropTypes              from '../../PropTypes';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { VideoTypeActions }   from '../../../store/actions/videoType';

class VideoTypeDropDown extends React.Component {
    getVideoTypes = () => {
        return DropDown.mapHydraDataForValueField(
            this.props.videoTypes,
            'name',
            true,
        );
    };

    render() {
        return (
            <DropDown
                noMarginBottom={this.props.noMarginBottom}
                onClear={this.props.onClear}
                onChange={this.props.onChange}
                options={this.getVideoTypes()}
                placeholder={this.props.placeholder}
                selectedValue={this.props.selectedValue}
                onRefreshDataClicked={this.props.fetchVideoTypes}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = VideoTypeDropDown;

Component.propTypes = {
    fetchVideoTypes: PropTypes.func,
    noMarginBottom:  PropTypes.bool,
    onChange:        PropTypes.func,
    onClear:         PropTypes.func,
    placeholder:     PropTypes.string,
    selectedValue:   PropTypes.string,
    videoTypes:      PropTypes.array,
};

Component.defaultProps = {
    fetchVideoTypes: _.noop,
    noMarginBottom:  false,
    onChange:        _.noop,
    onClear:         _.noop,
    placeholder:     null,
    selectedValue:   null,
    videoTypes:      [],
};

Component.renderAffectingProps = [
    'noMarginBottom',
    'placeholder',
    'selectedValue',
    'videoTypes',
];

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        videoTypes: state.videoType.videoTypes,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        VideoTypeActions,
    ),
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
