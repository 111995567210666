//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                      from 'react';
import _                          from 'lodash';
import AlertBoxManager            from '../AlertBoxManager';
import CompanyTypes               from '../../../constants/CompanyTypes';
import ComponentHelper            from '../../../helper/ComponentHelper';
import FileStates                 from '../../../constants/FileStates';
import I18n                       from 'i18next';
import MatchStateTypes            from '../../../constants/MatchStateTypes';
import MessageComposerStateless   from '../../stateless/MessageComposer';
import PropTypes                  from '../../PropTypes';
import { ActiveProjectActions }   from '../../../store/actions/activeProject';
import { AlertBoxActions }        from '../../../store/actions/alertBox';
import { bindActionCreators }     from 'redux';
import { connect }                from 'react-redux';
import { CreateEditOfferActions } from '../../../store/actions/createEditOffer';
import { MessageComposerActions } from '../../../store/actions/messageComposer';
import { v4 as uuidv4 }           from 'uuid';

class MessageComposer extends React.Component {
    activeProjectHasOffers = () => {
        const activeProjectHasOffers = this.props.offers.length > 0;

        return activeProjectHasOffers;
    };

    addOfferButtonPressed = () => {
        if (this.activeProjectHasOffers()) {
            this.props.editOffer({
                matchAttachments: _.get(this.props.activeMatch, 'matchAttachments', []),
                offers:           this.props.offers,
            });
        } else {
            // TODO: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-332
            this.props.addOffer();
        }
    };

    fileDeletionButtonPressed = (file) => {
        this.props.deleteFile({
            contextKey: this.props.context,
            id:         file.id,
        });
    };

    getFiles = () => {
        const fileObject = _.get(
            this,
            [
                'props',
                'files',
                this.props.context,
            ],
            {},
        );
        const files      = Object.values(fileObject);

        return files;
    };

    getMessage = () => {
        return _.get(
            this,
            [
                'props',
                'messages',
                this.props.context,
            ],
        );
    };

    oneFileIsCurrentlyUploading = () => {
        const files = this.getFiles();

        for (const file of files) {
            if (file.state === FileStates.uploading) {
                return true;
            }
        }

        return false;
    };

    onMessageChange = (event) => {
        const newMessage = _.get(event, 'target.value', false);

        if (newMessage) {
            this.props.setMessage({
                contextKey: this.props.context,
                message:    newMessage,
            });
        }
    };

    ownCompanyIsCompany = () => {
        return this.props.companyType === CompanyTypes.company;
    };

    render() {
        const files        = this.getFiles();
        const message      = this.getMessage();
        const canSendOffer = this.props.matchState !== MatchStateTypes.declined;

        return (
            <MessageComposerStateless
                additionalHeader={this.renderAdditionalHeader()}
                addOfferButtonPressed={this.addOfferButtonPressed}
                fileDeletionButtonPressed={this.fileDeletionButtonPressed}
                files={files}
                message={message}
                onMessageChange={this.onMessageChange}
                showOfferButton={!this.ownCompanyIsCompany() && canSendOffer}
                showOfferButtonText={
                    !this.activeProjectHasOffers() ?
                        I18n.t('uploadOffer') :
                        I18n.t('offerEdit')
                }
                submitButtonPressed={this.submitButtonPressed}
                submitDisabled={this.oneFileIsCurrentlyUploading()}
                uploadFileCallback={this.uploadFileCallback}
            />
        );
    }

    renderAdditionalHeader = () => {
        return (
            <AlertBoxManager />
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    submitButtonPressed = () => {
        this.props.clearAlerts();

        const message = this.getMessage();

        if (!message) {
            this.props.showErrorAlert({
                text: I18n.t('messageErrorEmptyMessage'),
            });
        } else {
            this.props.sendActiveMessage();
        }
    };

    uploadFileCallback = (file) => {
        const newId = uuidv4();

        this.props.uploadFile({
            contextKey: this.props.context,
            id:         newId,
            file,
        });
    };
}

const Component = MessageComposer;

Component.propTypes = {
    context:    PropTypes.string,
    files:      PropTypes.array,
    matchState: PropTypes.string,
    messages:   PropTypes.array,
};

Component.defaultProps = {
    context:    '',
    files:      [],
    matchState: null,
    messages:   [],
};

Component.renderAffectingProps = [
    'context',
    'files',
    'matchState',
    'messages',
    'offers',
];

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    ActiveProjectActions,
    AlertBoxActions,
    CreateEditOfferActions,
    MessageComposerActions,
), dispatch);

const mapStateToProps = state => (
    {
        activeMatch: _.get(state, 'activeProject.activeMatch'),
        companyType: _.get(state, 'company.ownCompany.companyType'),
        files:       _.get(state, 'messageComposer.files'),
        matchState:  _.get(state, 'activeProject.activeMatch.matchState'),
        messages:    _.get(state, 'messageComposer.messages'),
        offers:      _.get(state, 'activeProject.offers'),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
