//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                       from 'react';
import { connect }                 from 'react-redux';
import PropTypes                   from '../../PropTypes';
import _                           from 'lodash';
import AlertBoxManager             from '../AlertBoxManager';
import CallbackRequestBoxStateless from '../../stateless/CallbackRequestBox';
import ComponentHelper             from '../../../helper/ComponentHelper';
import CallToActionBox             from '../../stateless/CallToActionBox';
import I18n                        from 'i18next';
import AvailabilityTypes           from '../../../constants/AvailabilityTypes';
import { bindActionCreators }      from 'redux';
import { CallbackRequestActions }  from '../../../store/actions/callbackRequest';
import { AlertBoxActions }         from '../../../store/actions/alertBox';
import AlertBoxType                from '../../stateless/AlertBox/AlertBoxType';

class CallbackRequestBox extends React.Component {
    availabilityTypeChoiceClicked = (choice) => {
        this.props.setAvailabilityType({
            availabilityType: choice.value,
        });
    };

    eMailAddressChanged = (event) => {
        this.props.setEMailAddress({
            eMailAddress: event.target.value,
        });
    };

    phoneNumberChanged = (event) => {
        this.props.setPhoneNumber({
            phoneNumber: event.target.value,
        });
    };

    render () {
        if (this.props.formIsVisible) {
            return this.renderForm();
        }

        return this.renderCallToActionBox();
    }

    renderCallToActionBox = () => {
        return (
            <>
                <AlertBoxManager filterByType={AlertBoxType.success} />
                <CallToActionBox
                    buttonText={I18n.t('callbackRequestButtonText')}
                    onClick={this.showFormButtonPressed}
                    text={I18n.t('callbackRequestBoxText')}
                    title={I18n.t('callbackRequestBoxTitle')}
                />
            </>
        );
    };

    renderForm = () => {
        return (
            <>
                <AlertBoxManager />
                <CallbackRequestBoxStateless
                    availabilityType={this.props.availabilityType}
                    availabilityTypeChoiceClicked={this.availabilityTypeChoiceClicked}
                    availabilityTypeError={this.props.availabilityTypeError}
                    eMailAddress={this.props.eMailAddress}
                    eMailAddressChanged={this.eMailAddressChanged}
                    eMailAddressError={this.props.eMailAddressError}
                    phoneNumber={this.props.phoneNumber}
                    phoneNumberChanged={this.phoneNumberChanged}
                    phoneNumberError={this.props.phoneNumberError}
                    onClick={this.submitButtonPressed}
                />
            </>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    showFormButtonPressed = () => {
        this.props.clearAlerts();
        this.props.setFormIsVisible({
            formIsVisible: true,
        });
    };

    submitButtonPressed = () => {
        this.props.clearAlerts();

        if (!this.props.eMailAddress) {
            this.props.showErrorAlertTagged({
                tag:  'eMailAddress',
                text: I18n.t('callbackRequestErrorEMailAddress'),
            });
        }

        if (!this.props.phoneNumber) {
            this.props.showErrorAlertTagged({
                tag:  'phoneNumber',
                text: I18n.t('callbackRequestErrorPhoneNumber'),
            });
        }

        if (!this.props.availabilityType) {
            this.props.showErrorAlertTagged({
                tag:  'availabilityType',
                text: I18n.t('callbackRequestErrorAvailabilityType'),
            });
        }

        requestAnimationFrame(() => {
            if (this.props.alertBoxesTaggedCount === 0) {
                this.props.sendCallbackRequest();
            }
        });
    };
}

const Component = CallbackRequestBox;

Component.propTypes = {
    alertBoxesTaggedCount: PropTypes.number,
    availabilityType:      PropTypes.oneOf(Object.keys(AvailabilityTypes)),
    availabilityTypeError: PropTypes.string,
    eMailAddress:          PropTypes.string,
    eMailAddressError:     PropTypes.string,
    formIsVisible:         PropTypes.bool,
    phoneNumber:           PropTypes.string,
    phoneNumberError:      PropTypes.string,
    sidePadding:           PropTypes.number,
};

Component.defaultProps = {
    alertBoxesTaggedCount: 0,
    availabilityType:      null,
    availabilityTypeError: null,
    eMailAddress:          '',
    eMailAddressError:     '',
    formIsVisible:         false,
    phoneNumber:           '',
    phoneNumberError:      '',
    sidePadding:           0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'formIsVisible',
];

const mapStateToProps = state => (
    {
        alertBoxesTaggedCount: _.get(state, 'alertBox.alertBoxesTaggedCount', 0),
        availabilityType:      _.get(state, 'callbackRequest.availabilityType'),
        availabilityTypeError: _.get(state, 'alertBox.alertBoxesTagged.availabilityType'),
        eMailAddress:          _.get(state, 'callbackRequest.eMailAddress'),
        eMailAddressError:     _.get(state, 'alertBox.alertBoxesTagged.eMailAddress'),
        formIsVisible:         _.get(state, 'callbackRequest.formIsVisible'),
        phoneNumber:           _.get(state, 'callbackRequest.phoneNumber'),
        phoneNumberError:      _.get(state, 'alertBox.alertBoxesTagged.phoneNumber'),
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    AlertBoxActions,
    CallbackRequestActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);