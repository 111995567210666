//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    becomePremiumMember:   '/premium-mitglied-werden', // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-66
    briefing:              '/projekt/briefing',
    companyProfile:        '/firma/profil/:companyId',
    companyProfilePart:    '/firma/profil/',
    error:                 '/error',
    home:                  '/',
    login:                 '/login',
    match:                 '/projekt/match',
    matches:               '/projekt/matches',
    messages:              '/projekt/nachrichten',
    messagesDetails:       '/projekt/nachrichten/verlauf',
    mobileRegisterSuccess: '/mobile-register-success',
    newPassword:           '/neues-passwort',
    offers:                '/projekt/angebote',
    screenDesign:          '/screendesign',
    userProfile:           '/user/profil/:userId',
    userProfilePart:       '/user/profil/',
    verifyRegistration:    '/registrierung-bestaetigen',
};
