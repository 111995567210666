//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                                  from 'react';
import styles                                 from './styles.module.scss';
import PropTypes                              from '../../PropTypes';
import ComponentHelper                        from '../../../helper/ComponentHelper';
import _                                      from 'lodash';
import CompanyProfileGenericItemsMode         from './CompanyProfileGenericItemsMode';
import classNames                             from 'classnames';
import CompanyProfileHeaderTitleTextAlignment from '../CompanyProfileHeaderTitle/CompanyProfileHeaderTitleTextAlignment';
import CompanyProfileHeaderTitle              from '../CompanyProfileHeaderTitle';

class CompanyProfileGenericItems extends React.Component {
    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.wrapperInner}>
                    <CompanyProfileHeaderTitle
                        editButtonPressed={this.props.editButtonPressed}
                        showEditButton={this.props.showEditButton}
                        textAlignment={CompanyProfileHeaderTitleTextAlignment.left}
                        title={this.props.title}
                    />
                    {this.renderItems()}
                </div>
            </div>
        );
    }

    renderItem = (item, index) => {
        if (item) {
            return (
                <div
                    key={index}
                    className={classNames(
                        styles.item,
                        {
                            [styles.itemFullWidth]: this.props.mode === CompanyProfileGenericItemsMode.fullWidth,
                            [styles.itemSmall]:     this.props.mode === CompanyProfileGenericItemsMode.small,
                        },
                    )}
                >
                    {item[this.props.fieldName]}
                </div>
            );
        }

        return null;
    };

    renderItems = () => {
        if (this.props.items) {
            return (
                <div className={styles.itemWrapper}>
                    {this.props.items.map(this.renderItem)}
                </div>
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CompanyProfileGenericItems;

Component.propTypes = {
    companyName:       PropTypes.string,
    editButtonPressed: PropTypes.func,
    fieldName:         PropTypes.string,
    items:             PropTypes.array,
    mode:              PropTypes.oneOf(Object.keys(CompanyProfileGenericItemsMode)),
    showEditButton:    PropTypes.bool,
    title:             PropTypes.string,
};

Component.defaultProps = {
    companyName:       null,
    editButtonPressed: _.noop,
    fieldName:         'name',
    items:             [],
    mode:              CompanyProfileGenericItemsMode.small,
    showEditButton:    false,
    title:             '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
