//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                             from 'react';
import { connect }                       from 'react-redux';
import PropTypes                         from '../../PropTypes';
import _                                 from 'lodash';
import { bindActionCreators }            from 'redux';
import { ActiveProjectActions }          from '../../../store/actions/activeProject';
import I18n                              from 'i18next';
import ComponentHelper                   from '../../../helper/ComponentHelper';
import IconButton                        from '../../stateless/IconButton';
import IconButtonTheme                   from '../../stateless/IconButton/IconButtonTheme';
import ContentItem                       from '../../stateless/ContentItem';
import ProjectSubMenuTypes               from '../../../constants/ProjectSubMenuTypes';
import ProjectBriefingContentItemContent from '../../stateless/ProjectBriefingContentItemContent';
import IconType                          from '../../stateless/Icon/IconType';
import Normalize                         from '../../../helper/Normalize';
import { CreateEditProjectActions }      from '../../../store/actions/createEditProject';
import CompanyTypes                      from '../../../constants/CompanyTypes';
import ProjectStateTypes                 from '../../../constants/ProjectStateTypes';

class ProjectBriefingContentItem extends React.Component {
    editButtonPressed = () => {
        this.props.editProject({
            project: this.props.project,
        });
    };

    messagesButtonPressed = () => {
        this.props.setActiveProject({
            project:     this.props.project,
            subMenuType: ProjectSubMenuTypes.messages,
        });
    };

    render () {
        const project               = this.props.project;
        const projectTitle          = _.get(project, 'title', '');
        const videoType             = Normalize.videoTypeStringFromProject(project);
        const fixedReleaseDate      = _.get(project, 'fixedReleaseDate', null);
        const fixedReleaseDateGiven = fixedReleaseDate || _.get(project, 'fixedReleaseDateIsSameAsWishCompletionDate', false);

        return (
            <ContentItem
                company={_.get(project, 'company', null)}
                content={
                    <ProjectBriefingContentItemContent
                        advertisingBudgetPlanned={_.get(project, 'advertisingBudgetPlanned', false)}
                        advertisingBudgetRangeInThousandsFrom={_.get(project, 'advertisingBudgetRange.from', 0)}
                        advertisingBudgetRangeInThousandsTo={_.get(project, 'advertisingBudgetRange.to', 0)}
                        ageRangeFrom={_.get(project, 'targetGroupAgeRange.from', 0)}
                        ageRangeTo={_.get(project, 'targetGroupAgeRange.to', 0)}
                        branch={_.get(project, 'branch.name', null)}
                        budgetRangeInThousandsFrom={_.get(project, 'budgetRange.from', 0)}
                        budgetRangeInThousandsTo={_.get(project, 'budgetRange.to', 0)}
                        fixedReleaseDate={fixedReleaseDate}
                        fixedReleaseDateGiven={fixedReleaseDateGiven}
                        formats={Normalize.formatsToStringArray(_.get(project, 'videoFormats', []))}
                        goal={_.get(project, 'goalText', '')}
                        licenseDuration={_.get(project, 'licenseDurationText', null)}
                        projectDescription={_.get(project, 'description', null)}
                        projectTitle={projectTitle}
                        targetGroupType={_.get(project, 'targetGroupType', null)}
                        territory={_.get(project, 'territory.name')}
                        videoType={videoType}
                        willVideoPublished={_.get(project, 'videoWillBePublished', true)}
                        wishCompletionDate={_.get(project, 'wishCompletionDate', null)}
                        wishedProductionPeriodFrom={_.get(project, 'wishedProductionPeriodStart', null)}
                        wishedProductionPeriodTo={_.get(project, 'wishedProductionPeriodEnd', null)}
                    />
                }
                headerCenterContent={null}
                headerRightContent={this.renderRightContent()}
                title={projectTitle}
                toggleContentEnabled={false}
                subTitle={videoType}
                subTitleFallback={I18n.t('unknownVideoType')}
            />
        );
    }

    renderRightContent = () => {
        return (
            <>
                {this.renderRightContentEditButton()}
                {this.renderRightContentMessageButton()}
            </>
        );
    };

    renderRightContentEditButton = () => {
        const state = _.get(this.props, 'project.state');

        if (
            this.props.companyType === CompanyTypes.company &&
            (
                state !== ProjectStateTypes.commissioned &&
                state !== ProjectStateTypes.finished
            )
        ) {
            return (
                <IconButton
                    iconType={IconType.messageSquare}
                    onClick={this.editButtonPressed}
                    text={I18n.t('editProject')}
                    theme={IconButtonTheme.grayGhost}
                />
            );
        }
    };

    renderRightContentMessageButton = () => {
        return (
            <IconButton
                iconType={IconType.messageSquare}
                onClick={this.messagesButtonPressed}
                text={I18n.t('messages')}
                theme={IconButtonTheme.turquoiseGhost}
            />
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ProjectBriefingContentItem;

Component.propTypes = {
    project: PropTypes.object,
};

Component.defaultProps = {
    project: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        ActiveProjectActions,
        CreateEditProjectActions,
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        companyType: _.get(state, 'company.ownCompany.companyType'),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
