//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import * as Sentry      from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
    dsn:          'https://7fc2951414514501a6cd31b1fcfb4e60@o683776.ingest.sentry.io/6002186',
    integrations: [
        new Integrations.BrowserTracing(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.25,
});

export default function renderCrashButton() {
    return (
        <button
            onClick={() => {
                const foo = 'foo';

                console.log(foo.this.does.not.exist);
            }}
        >
            Crash
        </button>
    );
}
