//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import ComponentHelper from '../../../helper/ComponentHelper';
import IconButtonTheme from '../IconButton/IconButtonTheme';
import Icon            from '../Icon';
import IconType        from '../Icon/IconType';
import IconButton      from '../IconButton';
import _               from 'lodash';

class CallToActionOverlayPane extends React.Component {
    render () {
        return (
            <div className={styles.wrapper}>
                <div className={styles.wrapperInner}>
                    <div className={styles.content}>
                        <IconButton
                            allowLineBreaks={true}
                            iconType={this.props.buttonIcon}
                            onClick={this.props.onClick}
                            text={this.props.buttonText}
                            theme={IconButtonTheme.grayWithGreenIcon}
                        />
                        {this.renderTip()}
                    </div>
                </div>
            </div>
        );
    }

    renderTip = () => {
        if (this.props.tip) {
            return (
                <div className={styles.tip}>
                    <div className={styles.tipIconWrapper}>
                        <Icon iconType={IconType.infoCircle} />
                    </div>
                    <p>
                        {this.props.tip}
                    </p>
                </div>
            );
        }

        return null;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CallToActionOverlayPane;

Component.propTypes = {
    buttonIcon: PropTypes.oneOf(Object.keys(IconType)),
    buttonText: PropTypes.string,
    onClick:    PropTypes.func,
    standAlone: PropTypes.bool,
    tip:        PropTypes.string,
};

Component.defaultProps = {
    buttonIcon: null,
    buttonText: '',
    onClick:    _.noop,
    standAlone: false,
    tip:        '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;