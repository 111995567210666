//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    alertOctagon:      'alertOctagon',
    camera:            'camera',
    check:             'check',
    checkCircle:       'checkCircle',
    chevronDown:       'chevronDown',
    code:              'code',
    dotListHorizontal: 'dotListHorizontal',
    dotListVertical:   'dotListVertical',
    download:          'download',
    edit:              'edit',
    euroSign:          'euroSign',
    eye:               'eye',
    facebook:          'facebook',
    file:              'file',
    fileText:          'fileText',
    frown:             'frown',
    globe:             'globe',
    home:              'home',
    infoCircle:        'infoCircle',
    instagram:         'instagram',
    mail:              'mail',
    mapPin:            'mapPin',
    messageSquare:     'messageSquare',
    movie:             'movie',
    phone:             'phone',
    plus:              'plus',
    plusCircle:        'plusCircle',
    refresh:           'refresh',
    save:              'save',
    trash:             'trash',
    user:              'user',
    users:             'users',
    x:                 'x',
    xCircle:           'xCircle',
    youtube:           'youtube',
    zap:               'zap',
};
