//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update                   from 'immutability-helper';
import { CallbackRequestTypes } from '../actions/callbackRequest';
import { UserTypes }            from '../actions/user';

const initialState = {
    availabilityType: '',
    eMailAddress:     '',
    formIsVisible:    false,
    phoneNumber:      '',
};

const setAvailabilityType = (action, state) => {
    return update(state, {
        availabilityType: {
            $set: action.availabilityType,
        },
    });
};

const setEMailAddress = (action, state) => {
    return update(state, {
        eMailAddress: {
            $set: action.eMailAddress,
        },
    });
};

const setFormIsVisible = (action, state) => {
    return update(state, {
        formIsVisible: {
            $set: action.formIsVisible,
        },
    });
};

const setPhoneNumber = (action, state) => {
    return update(state, {
        phoneNumber: {
            $set: action.phoneNumber,
        },
    });
};

const sendCallbackRequestSuccess = (action, state) => {
    return update(state, {
        availabilityType: {
            $set: initialState.availabilityType,
        },
        formIsVisible:    {
            $set: initialState.formIsVisible,
        },
    });
};

export default function (state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case CallbackRequestTypes.SET_AVAILABILITY_TYPE:         return setAvailabilityType(action, state);
        case CallbackRequestTypes.SET_E_MAIL_ADDRESS:            return setEMailAddress(action, state);
        case CallbackRequestTypes.SET_FORM_IS_VISIBLE:           return setFormIsVisible(action, state);
        case CallbackRequestTypes.SET_PHONE_NUMBER:              return setPhoneNumber(action, state);
        case CallbackRequestTypes.SEND_CALLBACK_REQUEST_SUCCESS: return sendCallbackRequestSuccess(action, state);
        case UserTypes.SET_E_MAIL_ADDRESS:                       return setEMailAddress(action, state);
        default:                                                 return state;
        // @formatter:on
    }
}