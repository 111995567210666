//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _               from 'lodash';
import ComponentHelper from '../../../helper/ComponentHelper';
import PropTypes       from '../../PropTypes';
import React           from 'react';

class AddressPreviewLabel extends React.Component {
    render() {
        const address      = _.cloneDeep(_.get(this.props, 'address'));
        const city         = _.get(address, 'city');
        const addressParts = [
            '//',
            _.get(address, 'street'),
            _.get(address, 'houseNumber') + ', ',
            _.get(address, 'postalCode'),
            city,
        ];

        return (
            <span>
                <strong>
                    {city}
                </strong>
                {' '}
                {addressParts.join(' ')}
            </span>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = AddressPreviewLabel;

Component.propTypes = {
    address: PropTypes.object,
};

Component.defaultProps = {
    address: {},
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
