//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper     from '../../../helper/ComponentHelper';
import PropTypes           from '../../PropTypes';
import React               from 'react';
import styles              from './styles.module.scss';
import UserMenuButtonTheme from './UserMenuButtonTheme';
import Link                from '../Link';
import classNames          from 'classnames';

class UserMenuButton extends React.Component {
    render() {
        return (
            <div className={styles.wrapper}>
                <div
                    className={classNames(
                        styles.textWrapper,
                        {
                            [styles.textWrapperDefault]:     this.props.theme === UserMenuButtonTheme.default,
                            [styles.textWrapperHighlighted]: this.props.theme === UserMenuButtonTheme.highlighted,
                        },
                    )}
                >
                    {this.renderInner()}
                </div>
            </div>
        );
    }

    renderInner = () => {
        const inner = (
            <>
                <strong>
                    {this.props.text}
                </strong>
                <br />
                {this.props.tip}
            </>
        );

        if (this.props.onClick) {
            return (
                <div
                    className={styles.textWrapperInner}
                    onClick={this.props.onClick}
                >
                    {inner}
                </div>
            );
        }

        return (
            <Link
                className={styles.textWrapperInner}
                target={this.props.target}
                to={this.props.to}
                params={this.props.params}
            >
                {inner}
            </Link>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = UserMenuButton;

Component.propTypes = {
    onClick: PropTypes.func,
    params:  PropTypes.object,
    target:  PropTypes.string,
    text:    PropTypes.string,
    theme:   PropTypes.oneOf(Object.keys(UserMenuButtonTheme)),
    tip:     PropTypes.string,
    to:      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
};

Component.defaultProps = {
    onClick: null,
    params:  {},
    target:  null,
    text:    '',
    theme:   UserMenuButtonTheme.default,
    tip:     '',
    to:      null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
