//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import * as Api        from '../../../api';
import _               from 'lodash';
import classNames      from 'classnames';
import ComponentHelper from '../../../helper/ComponentHelper';
import I18n            from 'i18next';
import IconButton      from '../IconButton';
import IconButtonTheme from '../IconButton/IconButtonTheme';
import IconType        from '../Icon/IconType';
import InputError      from '../InputError';
import nameInitials    from 'name-initials';
import NumberFormat    from '../../../helper/NumberFormat';
import PropTypes       from '../../PropTypes';
import Spacer          from '../../stateless/Spacer';
import styles          from './styles.module.scss';

class ImageUpload extends React.Component {
    uploadInputReference = null;

    constructor (props) {
        super(props);

        this.state = {
            image: null,
        };
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (!this.state.image) {
            this.updateImageState();
        }
    }

    componentDidMount (prevProps, prevState, snapshot) {
        this.updateImageState();
    }

    deleteImageClicked = () => {
        this.setState({
            image: null,
        });
        this.props.deleteImageClicked();
    };

    checkImageDimensions = (file, maxDimensions, minDimensions, callback, errorCallback) => {
        const image  = new Image();
        image.onload = function () {
            if (
                image.width > maxDimensions.imageWidth ||
                image.height > maxDimensions.imageHeight ||
                image.width < minDimensions.imageWidth ||
                image.height < minDimensions.imageHeight
            ) {
                errorCallback();
            } else {
                callback(file, image.src);
            }
        };

        image.src = URL.createObjectURL(file);
    };

    onImageChanged = (event) => {
        const file = _.get(event, 'target.files.0', null);

        if (file.size > this.props.maxUploadSizeInBytes) {
            this.props.onImageSizeError();
        } else {
            this.checkImageDimensions(
                file,
                this.props.maxUploadDimensionsInPixel,
                this.props.minUploadDimensionsInPixel,
                this.onLoadingImageComplete,
                this.props.onImageDimensionError,
            );
        }
    };

    onLoadingImageComplete = (file, image) => {
        this.setState({
            image,
        });
        this.props.onLoadingImageComplete(file);
    };

    uploadImageClicked = () => {
        this.uploadInputReference.click();
    };

    updateImageState = () => {
        const image = _.get(this.props, ['company', _.lowerFirst(this.props.imageName), 'path']);

        if (image) {
            this.setState({
                image,
            });
        }
    };

    render () {
        return (
            <>
                <div className={styles.wrapper}>
                    {this.renderInput()}
                    {this.renderInformation()}
                    {this.renderImage()}
                    {this.renderError()}
                    {this.renderButtons()}
                    <Spacer height={16} />
                </div>
            </>
        );
    }

    renderButtons = () => {
        return (
            <div className={styles.buttonContainer}>
                <IconButton
                    iconType={IconType.trash}
                    onClick={this.deleteImageClicked}
                    text={I18n.t('delete' + this.props.imageName)}
                    theme={IconButtonTheme.grayGhost}
                />
                <IconButton
                    onClick={this.uploadImageClicked}
                    text={I18n.t('uploadNewImage')}
                    theme={IconButtonTheme.turquoiseGhost}
                />
            </div>
        );
    };

    renderError = () => {
        if (this.props.errorText) {
            return (
                <InputError
                    text={this.props.errorText}
                    marginTop={-20}
                    marginBottom={20}
                />
            );
        }

        return (
            <Spacer height={16} />
        );
    };

    renderImage = () => {
        const styling = classNames(
            styles.logo,
            {
                [styles.bannerImage]: this.props.imageName === 'BannerImage',
            },
        );

        if (this.state.image) {
            let image = this.state.image;

            if (!this.state.image.startsWith('data:image')) {
                image = Api.getImagePath(this.state.image);
            }

            return (
                <img
                    alt={I18n.t('company' + this.props.imageName)}
                    src={image}
                    className={styling}
                />
            );
        }

        const companyName = _.get(this.props, 'company.name', '');

        return (
            <div
                className={classNames(
                    styles.fallback,
                    styling,
                )}
            >
                {nameInitials(companyName)}
            </div>
        );
    };

    renderInformation = () => {
        const companyImageInformation = I18n.t(
            'company' + this.props.imageName + 'Information',
            {
                humanReadableFileSize: NumberFormat.toHumanReadableFileSize(this.props.maxUploadSizeInBytes, true),
            },
        );

        if (companyImageInformation) {
            const companyImageInformationParts = companyImageInformation.split('\n');
            
            return (
                <div className={styles.information}>
                    {companyImageInformationParts.map(this.renderTextPart)}
                </div>
            );
        }

        return null;
    };

    renderInput = () => {
        return (
            <input
                className={styles.fileUpload}
                onChange={this.onImageChanged}
                ref={this.setUploadInputReference}
                type={'file'}
                accept={'image/jpeg, image/png'}
            />
        );
    };

    renderTextPart = (textPart, index) => {
        return (
            <div key={'text_' + index}>
                {textPart}
            </div>
        );
    };

    setUploadInputReference = (uploadInputReference) => {
        this.uploadInputReference = uploadInputReference;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ImageUpload;

Component.propTypes = {
    company:                    PropTypes.object,
    deleteImageClicked:         PropTypes.func,
    errorText:                  PropTypes.string,
    imageName:                  PropTypes.string,
    maxUploadDimensionsInPixel: PropTypes.imageDimensions,
    maxUploadSizeInBytes:       PropTypes.number,
    minUploadDimensionsInPixel: PropTypes.imageDimensions,
    onImageDimensionError:      PropTypes.func,
    onImageSizeError:           PropTypes.func,
    onLoadingImageComplete:     PropTypes.func,
};

Component.defaultProps = {
    company:                    {},
    deleteImageClicked:         _.noop,
    errorText:                  null,
    imageName:                  null,
    maxUploadDimensionsInPixel: {
        imageWidth:  0,
        imageHeight: 0,
    },
    maxUploadSizeInBytes:       0,
    minUploadDimensionsInPixel: {
        imageWidth:  0,
        imageHeight: 0,
    },
    onImageDimensionError:      _.noop,
    onImageSizeError:           _.noop,
    onLoadingImageComplete:     _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'image',
];

export default Component;
