//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import Cast            from '../../../helper/Cast';
import ComponentHelper from '../../../helper/ComponentHelper';
import I18n            from 'i18next';
import IconLabel       from '../IconLabel';
import IconLabelTheme  from '../IconLabel/IconLabelTheme';
import IconType        from '../Icon/IconType';
import Normalize       from '../../../helper/Normalize';
import PropTypes       from '../../PropTypes';
import ShortFactList   from '../ShortFactList';
import Spacer          from '../Spacer';
import styles          from './styles.module.scss';
import TextRow         from '../TextRow';
import TextRowGroup    from '../TextRowGroup';

class ProjectBriefingContentItemContent extends React.Component {
    getAdvertisingBudgetString = () => {
        return Normalize.toAdvertisingBudgetRange(
            this.props.advertisingBudgetRangeInThousandsFrom,
            this.props.advertisingBudgetRangeInThousandsTo,
        );
    };

    getAgeRangeString = () => {
        return Normalize.toAgeRange(
            this.props.ageRangeFrom,
            this.props.ageRangeTo,
        );
    };

    getBudgetRangeString = () => {
        return Normalize.toBudgetRange(
            this.props.budgetRangeInThousandsFrom,
            this.props.budgetRangeInThousandsTo,
        );
    };

    getFixedReleaseDateString = () => {
        const fixedReleaseDateString = [
            Cast.yesNo(this.props.fixedReleaseDateGiven),
            this.props.fixedReleaseDate ? (
                Normalize.formatDateWithFallback(
                    'wishCompletionDateFormat',
                    this.props.fixedReleaseDate,
                )
            ) : null,
        ];

        return fixedReleaseDateString.join(' ');
    };

    render() {
        return (
            <>
                <div className={styles.shortFactsWrapper}>
                    <h4>
                        {I18n.t('projectDetails')}
                    </h4>
                    {this.renderShortFactList()}
                </div>
                {this.renderKeyData()}
                {this.renderGoals()}
                {this.renderReach()}
                {this.renderBudget()}
                {this.renderTimings()}
            </>
        );
    }

    renderBudget = () => {
        const budgetString = this.getBudgetRangeString();

        return (
            <div className={styles.textGroupWrapper}>
                <TextRowGroup title={I18n.t('budget')}>
                    <TextRow
                        label={I18n.t('budgetLabel')}
                        text={budgetString}
                        fallbackText={I18n.t('budgetRangeUndefined')}
                    />
                    {this.renderAdvertisingBudgetPlanned()}
                    {this.renderBudgetMediaSpendings()}
                </TextRowGroup>
            </div>
        );
    };

    renderAdvertisingBudgetPlanned = () => {
        if (this.props.advertisingBudgetPlanned === null) {
            return (
                <TextRow
                    label={I18n.t('advertisingBudgetPlannedLabel')}
                    text={null}
                    fallbackText={I18n.t('advertisingBudgetUndefined')}
                />
            );
        }

        return (
            <TextRow
                label={I18n.t('advertisingBudgetPlannedLabel')}
                text={Cast.yesNo(this.props.advertisingBudgetPlanned)}
                fallbackText={I18n.t('advertisingBudgetUndefined')}
            />
        );
    };

    renderBudgetMediaSpendings = () => {
        if (this.props.advertisingBudgetPlanned) {
            const advertisingBudgetString = this.getAdvertisingBudgetString();

            return (
                <TextRow
                    label={I18n.t('advertisingBudgetLabel')}
                    text={advertisingBudgetString}
                />
            );
        }

        return null;
    };

    renderGoals = () => {
        const ageRangeString = this.getAgeRangeString();

        return (
            <div className={styles.textGroupWrapper}>
                <TextRowGroup title={I18n.t('goals')}>
                    <TextRow
                        label={I18n.t('goalsLabel')}
                        text={this.props.goal}
                        fallbackText={I18n.t('goalsUndefined')}
                    />
                    <TextRow
                        label={I18n.t('branchLabel')}
                        text={this.props.branch}
                        fallbackText={I18n.t('unknownBranch')}
                    />
                    <TextRow
                        label={I18n.t('targetGroupLabel')}
                        text={ageRangeString}
                        fallbackText={I18n.t('ageRangeUndefined')}
                    />
                    <TextRow
                        label={I18n.t('targetGroupTypeLabel')}
                        text={this.props.targetGroupType}
                        fallbackText={I18n.t('ageRangeUndefined')}
                    />
                </TextRowGroup>
            </div>
        );
    };

    renderKeyData = () => {
        return (
            <div className={styles.textGroupWrapper}>
                <TextRowGroup title={I18n.t('keyData')}>
                    <TextRow
                        label={I18n.t('projectTitle')}
                        text={this.props.projectTitle}
                    />
                    <TextRow
                        label={I18n.t('projectDescription')}
                        text={this.props.projectDescription}
                    />
                    <TextRow
                        label={I18n.t('videoType')}
                        text={this.props.videoType}
                        fallbackText={I18n.t('unknownVideoType')}
                    />
                </TextRowGroup>
            </div>
        );
    };

    renderReach = () => {
        return (
            <div className={styles.textGroupWrapper}>
                <TextRowGroup title={I18n.t('reach')}>
                    <TextRow
                        label={I18n.t('willVideoPublishedLabel')}
                        text={Cast.yesNo(this.props.willVideoPublished)}
                    />
                    {
                        this.props.willVideoPublished ? (
                            <>
                                <TextRow
                                    label={I18n.t('videoFormatLabel')}
                                    text={this.props.formats.join(', ')}
                                    fallbackText={I18n.t('videoFormatUndefined')}
                                />
                                <TextRow
                                    label={I18n.t('videoTerritoryLabel')}
                                    text={this.props.territory}
                                    fallbackText={I18n.t('videoTerritoryUndefined')}
                                />
                                <TextRow
                                    label={I18n.t('licenseDurationLabel')}
                                    text={this.props.licenseDuration}
                                    fallbackText={I18n.t('licenseDurationUndefined')}
                                />
                            </>
                        ) : null
                    }
                </TextRowGroup>
            </div>
        );
    };

    renderShortFactList = () => {
        const ageRangeString = this.getAgeRangeString();
        const budgetString   = this.getBudgetRangeString();

        return (
            <ShortFactList itemsPerRow={4}>
                <IconLabel
                    iconType={IconType.camera}
                    text={this.props.videoType}
                    theme={IconLabelTheme.bigWhite}
                    fallbackText={I18n.t('unknownVideoType')}
                />
                <IconLabel
                    iconType={IconType.users}
                    text={ageRangeString}
                    theme={IconLabelTheme.bigWhite}
                    fallbackText={I18n.t('ageRangeUndefined')}
                />
                <IconLabel
                    iconType={IconType.euroSign}
                    text={budgetString}
                    theme={IconLabelTheme.bigWhite}
                    fallbackText={I18n.t('budgetRangeUndefined')}
                />
                <IconLabel
                    iconType={IconType.movie}
                    text={this.props.formats.join(', ')}
                    theme={IconLabelTheme.bigWhite}
                    fallbackText={I18n.t('videoFormatUndefined')}
                />
            </ShortFactList>
        );
    };

    renderTimings = () => {
        const fixedReleaseDateString = this.getFixedReleaseDateString();

        return (
            <div className={styles.textGroupWrapper}>
                <TextRowGroup title={I18n.t('timings')}>
                    <TextRow
                        label={I18n.t('wishCompletionDateLabel')}
                        text={Normalize.formatDate(
                            'wishCompletionDateFormat',
                            this.props.wishCompletionDate,
                        )}
                        fallbackText={I18n.t('wishCompletionDateUndefined')}
                    />
                    <TextRow
                        label={I18n.t('fixedReleaseDateGiven')}
                        text={fixedReleaseDateString}
                    />
                    {
                        this.props.wishedProductionPeriodFrom && this.props.wishedProductionPeriodTo ? (
                            <TextRow
                                label={I18n.t('wishedProductionPeriodLabel')}
                                text={Normalize.toFormattedDateRage(
                                    'wishedProductionPeriodFormat',
                                    this.props.wishedProductionPeriodFrom,
                                    this.props.wishedProductionPeriodTo,
                                )}
                            />
                        ) : null
                    }
                    <Spacer height={20} />
                </TextRowGroup>
            </div>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ProjectBriefingContentItemContent;

Component.propTypes = {
    advertisingBudgetPlanned:              PropTypes.bool,
    advertisingBudgetRangeInThousandsFrom: PropTypes.number,
    advertisingBudgetRangeInThousandsTo:   PropTypes.number,
    ageRangeFrom:                          PropTypes.number,
    ageRangeTo:                            PropTypes.number,
    branch:                                PropTypes.string,
    budgetRangeInThousandsFrom:            PropTypes.number,
    budgetRangeInThousandsTo:              PropTypes.number,
    fixedReleaseDate:                      PropTypes.string,
    fixedReleaseDateGiven:                 PropTypes.bool,
    formats:                               PropTypes.array,
    goal:                                  PropTypes.string,
    licenseDuration:                       PropTypes.string,
    projectDescription:                    PropTypes.string,
    projectTitle:                          PropTypes.string,
    targetGroupType:                       PropTypes.string,
    territory:                             PropTypes.string,
    willVideoPublished:                    PropTypes.bool,
    wishCompletionDate:                    PropTypes.string,
    wishedProductionPeriodFrom:            PropTypes.string,
    wishedProductionPeriodTo:              PropTypes.string,
};

Component.defaultProps = {
    advertisingBudgetPlanned:              false,
    advertisingBudgetRangeInThousandsFrom: 0,
    advertisingBudgetRangeInThousandsTo:   0,
    ageRangeFrom:                          0,
    ageRangeTo:                            0,
    branch:                                '',
    budgetRangeInThousandsFrom:            0,
    budgetRangeInThousandsTo:              0,
    fixedReleaseDate:                      '',
    fixedReleaseDateGiven:                 true,
    formats:                               [],
    goal:                                  '',
    licenseDuration:                       '',
    projectDescription:                    '',
    projectTitle:                          '',
    targetGroupType:                       '',
    territory:                             '',
    willVideoPublished:                    false,
    wishCompletionDate:                    '',
    wishedProductionPeriodFrom:            '',
    wishedProductionPeriodTo:              '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
