//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as Api                   from '../../api';
import _                          from 'lodash';
import HydraHelper                from '../../helper/Hydra';
import I18n                       from 'i18next';
import OverlayManager             from '../../components/connected/OverlayManager';
import Overlays                   from '../../constants/Overlays';
import Routes                     from '../../constants/Routes';
import { AlertBoxActions }        from '../actions/alertBox';
import { BranchActions }          from '../actions/branch';
import { call }                   from 'redux-saga/effects';
import { put }                    from 'redux-saga/effects';
import { select }                 from 'redux-saga/effects';
import { CompanyActions }         from '../actions/company';
import { CompanyPositionActions } from '../actions/companyPosition';
import { CompetenceActions }      from '../actions/competence';
import { ConfigurationActions }   from '../actions/configuration';
import { GoalActions }            from '../actions/goal';
import { LanguageActions }        from '../actions/language';
import { LicenseDurationActions } from '../actions/licenseDuration';
import { PlaceOfWorkActions }     from '../actions/placeOfWork';
import { ProjectActions }         from '../actions/project';
import { push }                   from 'connected-react-router';
import { TerritoriesActions }     from '../actions/territory';
import { UserActions }            from '../actions/user';
import { VideoFormatActions }     from '../actions/videoFormat';
import { VideoTypeActions }       from '../actions/videoType';

const afterLoginRoute      = Routes.home;
const routesWithoutSession = [
    Routes.login,
    Routes.error,
    Routes.newPassword,
    Routes.verifyRegistration,
    Routes.screenDesign,
    Routes.mobileRegisterSuccess,
];

const createUser = function* () {
    const user = yield select(state => state.user.ownUserEditContext);

    const response = yield call(
        Api.createUser,
        user.email.value,
        user.firstname.value,
        user.name.value,
        user.password.value,
    );

    if (response.ok) {
        const cleanHydraResponse = HydraHelper.cleanupObject(response.data);

        yield put(UserActions.createUserSuccess({
            user: cleanHydraResponse,
        }));
    } else {
        yield put(UserActions.createUserFailed()); // TODO: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-439
    }
};

const createUserSuccess = function* () {
    yield put(UserActions.setOwnEditUserAsUser());
    yield put(UserActions.login({
        redirect:      false,
        suppressError: true,
    }));
};

const editOwnUserData = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.editUserData)));
};

const editOwnUserPassword = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.editUserPassword)));
};

const fetchUser = function* () {
    yield put(AlertBoxActions.clearAlerts());

    const response = yield call(Api.fetchUser);

    if (response.ok) {
        const cleanHydraResponse = HydraHelper.cleanupObject(response.data);
        const user               = _.get(cleanHydraResponse, 'member.0');
        const id                 = user.id;
        const iri                = user.iri;
        const firstname          = user.firstname;
        const name               = user.name;
        const email              = user.email;

        yield put(UserActions.fetchUserSuccess({
            id,
            iri,
            firstname,
            name,
            email,
        }));
    } else {
        yield put(UserActions.fetchUserFailed());
    }
};

const loadNoneSessionDependingData = function* () {
    yield put(BranchActions.fetchBranches());
    yield put(CompanyPositionActions.fetchCompanyPositions());
    yield put(ConfigurationActions.fetchConfiguration());
    yield put(GoalActions.fetchGoals());
    yield put(VideoFormatActions.fetchVideoFormats());
    yield put(VideoTypeActions.fetchVideoTypes());
    yield put(TerritoriesActions.fetchTerritories());
    yield put(LicenseDurationActions.fetchLicenseDurations());
};

const loadSessionDependingData = function* () {
    yield put(UserActions.fetchUser());
    yield put(CompetenceActions.fetchCompetences());
    yield put(LanguageActions.fetchLanguages());
    yield put(PlaceOfWorkActions.fetchPlaceOfWorks());
    yield put(ProjectActions.fetchProjects());
};

const login = function* (action) {
    yield put(AlertBoxActions.clearAlerts());

    const user     = yield select(state => state.user.user);
    const response = yield call(
        Api.login,
        user.email,
        user.password,
    );

    if (response.ok) {
        const company   = response.data.company;
        const id        = response.data.id;
        const iri       = response.data.userId;
        const firstname = response.data.firstname;
        const name      = response.data.name;
        const token     = response.data.token;
        const redirect  = action.redirect;

        Api.setToken(token);

        yield put(UserActions.loginSucceeded({
            company,
            id,
            iri,
            firstname,
            name,
            token,
            redirect,
        }));
    } else {
        yield put(UserActions.loginFailed({
            suppressError: action.suppressError,
        }));
    }
};

const loginFailed = function* (action) {
    if (!action.suppressError) {
        yield put(AlertBoxActions.showErrorAlert({
            text: I18n.t('loginError'),
        }));
    }
};

const loginSucceeded = function* (action) {
    if (action.company) {
        yield put(CompanyActions.fetchOwnCompany({
            companyId: action.company,
        }));
    }

    yield put(UserActions.loadSessionDependingData());

    if (action.redirect) {
        const userAfterLoginRoute   = yield select(state => state.user.afterLoginRoute);
        const targetAfterLoginRoute = userAfterLoginRoute || afterLoginRoute;

        if (userAfterLoginRoute) {
            yield put(UserActions.clearAfterLoginRoute());
        }

        yield put(push(targetAfterLoginRoute));
    }
};

const logout = function* (action) {
    Api.removeToken();

    const changeRoute = _.get(action, 'changeRoute', true);

    if (changeRoute) {
        yield put(push(Routes.login));
    }
};

const registerOpen = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.register)));
};

const registerCompany = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.registerCompany)));
};

const registerVideoProduction = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.registerVideoProduction)));
};

const registerVideoProductionSuccess = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.registerVideoProductionSuccess)));
};

const resetPassword = function* (action) {
    Api.removeToken();

    const response = yield call(
        Api.resetPassword,
        action.email,
    );

    if (response.ok) {
        yield put(UserActions.resetPasswordSuccess());
    } else {
        yield put(UserActions.resetPasswordFailed());
    }
};

const resetPasswordFailed = function* () {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('resetPasswordError'),
    }));
};

const resetPasswordSuccess = function* () {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('resetPasswordSuccess'),
    }));
};

const verifyRegistration = function* (action) {
    const response = yield call(
        Api.verifyRegistration,
        action.confirmationToken,
    );

    if (response.ok) {
        yield put(UserActions.verifyRegistrationSucceeded());
    } else {
        yield put(UserActions.verifyRegistrationFailed());
    }
};

const verifyRegistrationFailed = function* () {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('verifyRegistrationError'),
    }));
};

const verifyRegistrationSucceeded = function* () {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('verifyRegistrationSucceeded'),
    }));
};

const restoreToken = function* () {
    const pathname        = yield select(state => state.router.location.pathname);
    const browserPathname = window.location.pathname;
    const user            = yield select(state => state.user.user);
    let newPath           = false;

    if (user.token) {
        Api.setToken(user.token);

        if (pathname === Routes.login || browserPathname === Routes.login) {
            newPath = afterLoginRoute;
        }
    } else if (
        routesWithoutSession.indexOf(pathname) === -1 ||
        routesWithoutSession.indexOf(browserPathname) === -1
    ) {
        const { pathname, search } = window.location;
        const afterLoginRoute      = pathname + search;
        newPath                    = Routes.login;

        yield put(UserActions.setAfterLoginRoute({
            afterLoginRoute,
        }));
    }

    if (
        pathname === Routes.verifyRegistration ||
        browserPathname === Routes.verifyRegistration
    ) {
        const confirmationToken = new URLSearchParams(window.location.search).get('confirmation-token');
        newPath                 = Routes.home;

        yield put(UserActions.verifyRegistration({
            confirmationToken,
        }));
    }

    if (newPath) {
        yield put(push(newPath));
    }
};

const setNewPassword = function* (action) {
    const response = yield call(
        Api.setNewPassword,
        action.password,
        action.token,
    );

    if (response.ok) {
        yield put(UserActions.setNewPasswordSuccess());
    } else {
        yield put(UserActions.setNewPasswordFailed());
    }
};

const setNewPasswordFailed = function* () {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('setNewPasswordError'),
    }));
};

const setNewPasswordSuccess = function* () {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('setNewPasswordSuccess'),
    }));

    yield put(push(Routes.login));
};

const updateUser = function* (action) {
    const data = {};
    const user = yield select(state => state.user.ownUserEditContext);

    _.map(action.data, (value, key) => {
        data[key] = value.value;
    });

    const response = yield call(
        Api.updateUser,
        user.iri.value,
        data.email,
        data.firstname,
        data.name,
    );

    if (response.ok) {
        const user      = HydraHelper.cleanupObject(response.data);
        const id        = user.id;
        const iri       = user.iri;
        const firstname = user.firstname;
        const name      = user.name;
        const email     = user.email;

        yield put(UserActions.fetchUserSuccess({
            id,
            iri,
            firstname,
            name,
            email,
        }));

        if (user.shouldLogout) {
            yield put(UserActions.logout());
        }
    } else {
        yield put(UserActions.updateUserFailed());
    }
};

const updateUserFailed = function* () {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('userUpdateError'),
    }));
};

const updateUserPassword = function* (action) {
    const user = yield select(state => state.user.ownUserEditContext);

    const response = yield call(
        Api.updateUserPassword,
        user.iri.value,
        action.oldPassword,
        action.newPassword,
    );

    if (response.ok) {
        yield put(UserActions.logout());
    } else if (response.status === 400) {
        yield put(AlertBoxActions.showErrorAlertTagged({
            tag:  'password3',
            text: I18n.t('profilePasswordError4'),
        }));
        yield put(UserActions.editOwnUserPassword());
    } else {
        yield put(UserActions.updateUserFailed());
    }
};

const updateUserSuccess = function* (action) {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('userEditProfileSuccess'),
    }));
};

export default {
    createUser,
    createUserSuccess,
    editOwnUserData,
    editOwnUserPassword,
    fetchUser,
    loadNoneSessionDependingData,
    loadSessionDependingData,
    login,
    loginFailed,
    loginSucceeded,
    logout,
    registerOpen,
    registerCompany,
    registerVideoProduction,
    registerVideoProductionSuccess,
    resetPassword,
    resetPasswordFailed,
    resetPasswordSuccess,
    restoreToken,
    setNewPassword,
    setNewPasswordFailed,
    setNewPasswordSuccess,
    updateUser,
    updateUserFailed,
    updateUserPassword,
    updateUserSuccess,
    verifyRegistration,
    verifyRegistrationFailed,
    verifyRegistrationSucceeded,
};
