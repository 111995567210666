//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update               from 'immutability-helper';
import { TerritoriesTypes } from '../actions/territory';

const initialState = {
    territories: [],
};

// https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-215

export default function (state = initialState, action) {
    switch (action.type) {
        case TerritoriesTypes.FETCH_TERRITORIES_SUCCESS:
            return update(state, {
                territories: {
                    $set: action.territories,
                },
            });

        default:
            return state;
    }
}