//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                        from 'react';
import _                            from 'lodash';
import ChoiceSelector               from '../../stateless/ChoiceSelector';
import ChoiceSelectorMode           from '../../stateless/ChoiceSelector/ChoiceSelectorMode';
import ChoiceWidthMode              from '../../stateless/ChoiceSelector/ChoiceWidthMode';
import ComponentHelper              from '../../../helper/ComponentHelper';
import ErrorAwareOverlayPane        from '../../connected/ErrorAwareOverlayPane';
import FormRow                      from '../../stateless/FormRow';
import GA                           from '../../../helper/GA';
import I18n                         from 'i18next';
import OverlayButton                from '../../stateless/OverlayButton';
import OverlayButtonTheme           from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper         from '../../stateless/OverlayButtonWrapper';
import OverlayPaneState             from '../../stateless/OverlayPane/OverlayPaneState';
import PropTypes                    from '../../PropTypes';
import RangeInput                   from '../../stateless/RangeInput';
import RangeInputTheme              from '../../stateless/RangeInput/RangeInputTheme';
import RangeInputType               from '../../stateless/RangeInput/RangeInputType';
import Spacer                       from '../../stateless/Spacer';
import { AlertBoxActions }          from '../../../store/actions/alertBox';
import { bindActionCreators }       from 'redux';
import { connect }                  from 'react-redux';
import { CreateEditProjectActions } from '../../../store/actions/createEditProject';
import Window                       from '../../../helper/Window';

class CreateProjectOverlayBudgetPane extends React.Component {
    eventLabel = 'Budget';

    constructor(props) {
        super(props);

        this.state = {
            budgetMaxError: null,
            budgetMinError: null,
        };
    }

    advertisingBudgetPlannedChanged = (choiceData) => {
        this.props.setAdvertisingBudgetPlanned({
            planned: choiceData.value,
        });
    };

    advertisingBudgetRangeChanged = (rangeData) => {
        this.props.setAdvertisingBudgetRange({
            end:   rangeData.endValue,
            start: rangeData.startValue,
        });
    };

    backButtonPressed = () => {
        GA.sendCreateProjectPreviousEvent(this.eventLabel);
        this.props.clearAlerts();
        this.props.previousPane();
    };

    budgetRangeChanged = (rangeData) => {
        if (this.props.budgetRangeEnd !== rangeData.endValue) {
            this.setState({
                budgetMaxError: null,
            });
        }

        if (this.props.budgetRangeStart !== rangeData.startValue) {
            this.setState({
                budgetMinError: null,
            });
        }

        this.props.setBudgetRange({
            end:   rangeData.endValue,
            start: rangeData.startValue,
        });
    };

    budgetRangeUnknownClicked = () => {
        this.budgetRangeChanged({
            endValue:   0,
            startValue: 0,
        });
        this.props.toggleBudgetRangeUnknown();
    };

    continueButtonPressed = () => {
        if (!this.state.budgetMaxError && !this.state.budgetMinError) {
            GA.sendCreateProjectNextEvent(this.eventLabel);
            this.props.clearAlerts();
            this.props.nextPane();
        }
    };

    onMaxBudgetError = () => {
        this.setState({
            budgetMaxError: I18n.t('maxBudgetErrorText'),
        });
    };

    onMinBudgetError = () => {
        this.setState({
            budgetMinError: I18n.t('minBudgetErrorText'),
        });
    };

    render() {
        return (
            <ErrorAwareOverlayPane
                content={this.renderContent()}
                footer={this.renderFooter()}
                title={I18n.t('budget')}
                showErrors={true}
                state={this.props.overlayPaneState}
                upcomingDistance={this.props.upcomingDistance}
            />
        );
    }

    renderContent = () => {
        return (
            <>
                {this.renderContentBudgetRange()}
                {this.renderContentAdvertisingBudgetPlanned()}
                {this.renderContentAdvertisingBudgetRange()}
            </>
        );
    };

    renderContentAdvertisingBudgetPlanned = () => {
        if (!Window.isMobile()) {
            return (
                <FormRow
                    label={I18n.t('advertisingBudgetPlannedLabel')}
                    tooltipText={I18n.t('advertisingBudgetPlannedTooltipText')}
                >
                    <ChoiceSelector
                        choiceClicked={this.advertisingBudgetPlannedChanged}
                        choices={[
                            {
                                selected:  this.props.advertisingBudgedPlanned === true,
                                text:      I18n.t('yes'),
                                value:     true,
                                widthMode: ChoiceWidthMode.half,
                            },
                            {
                                selected:  this.props.advertisingBudgedPlanned === false,
                                text:      I18n.t('no'),
                                value:     false,
                                widthMode: ChoiceWidthMode.half,
                            },
                            {
                                selected:  this.props.advertisingBudgedPlanned === null,
                                text:      I18n.t('fillLaterChoiceText'),
                                value:     null,
                                widthMode: ChoiceWidthMode.full,
                            },
                        ]}
                        mode={ChoiceSelectorMode.singleChoice}
                    />
                </FormRow>
            );
        }

        return null;
    };

    renderContentAdvertisingBudgetRange = () => {
        if (this.props.advertisingBudgedPlanned) {
            return (
                <FormRow
                    label={I18n.t('advertisingBudgetLabel')}
                >
                    <RangeInput
                        maximumValue={100000}
                        minimumValue={0}
                        type={RangeInputType.number}
                        onChange={this.advertisingBudgetRangeChanged}
                        step={100}
                        theme={RangeInputTheme.default}
                        postfix={I18n.t('euro')}
                        valueEnd={this.props.advertisingBudgetRangeEnd}
                        valueStart={this.props.advertisingBudgetRangeStart}
                        paddingRight={true}
                    />
                </FormRow>
            );
        }

        return (
            <Spacer height={20} />
        );
    };

    renderContentBudgetRange = () => {
        return (
            <FormRow
                label={I18n.t('budgetLabel')}
                tooltipText={I18n.t('budgetTooltipText')}
            >
                <RangeInput
                    maximumValue={250000}
                    minimumValue={1000}
                    type={RangeInputType.number}
                    onChange={this.budgetRangeChanged}
                    step={500}
                    postfix={I18n.t('euro')}
                    theme={
                        this.props.budgetRangeUnknown ?
                            RangeInputTheme.unimportant :
                            RangeInputTheme.default
                    }
                    valueEnd={this.props.budgetRangeEnd}
                    valueStart={this.props.budgetRangeStart}
                    withoutSlider={true}
                    onMaxValueError={this.onMaxBudgetError}
                    onMinValueError={this.onMinBudgetError}
                    errorTextEnd={this.state.budgetMaxError}
                    errorTextStart={this.state.budgetMinError}
                />
                {this.renderBudgetFillLaterChoice()}
            </FormRow>
        );
    };

    renderBudgetFillLaterChoice = () => {
        if (!Window.isMobile()) {
            return (
                <ChoiceSelector
                    choiceClicked={this.budgetRangeUnknownClicked}
                    choices={[
                        {
                            selected:  this.props.budgetRangeUnknown,
                            text:      I18n.t('fillLaterChoiceText'),
                            widthMode: ChoiceWidthMode.full,
                        },
                    ]}
                    mode={ChoiceSelectorMode.singleChoice}
                />
            );
        }

        return null;
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.backButtonPressed}
                    standAlone={false}
                    text={I18n.t('back')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.continueButtonPressed}
                    standAlone={false}
                    text={I18n.t('continue')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CreateProjectOverlayBudgetPane;

Component.propTypes = {
    advertisingBudgedPlanned:    PropTypes.bool,
    advertisingBudgetRangeEnd:   PropTypes.number,
    advertisingBudgetRangeStart: PropTypes.number,
    budgetRangeEnd:              PropTypes.number,
    budgetRangeStart:            PropTypes.number,
    budgetRangeUnknown:          PropTypes.bool,
    overlayPaneState:            PropTypes.oneOf(Object.values(OverlayPaneState)),
    upcomingDistance:            PropTypes.number,
};

Component.defaultProps = {
    advertisingBudgedPlanned:    null,
    advertisingBudgetRangeEnd:   99,
    advertisingBudgetRangeStart: 1,
    budgetRangeEnd:              99,
    budgetRangeStart:            1,
    budgetRangeUnknown:          false,
    overlayPaneState:            OverlayPaneState.upcoming,
    upcomingDistance:            0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'budgetMaxError',
    'budgetMinError',
];

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    AlertBoxActions,
    CreateEditProjectActions,
), dispatch);

const mapStateToProps = state => (
    {
        advertisingBudgedPlanned:    state.createEditProject.advertisingBudgedPlanned,
        advertisingBudgetRangeEnd:   state.createEditProject.advertisingBudgetRange.end,
        advertisingBudgetRangeStart: state.createEditProject.advertisingBudgetRange.start,
        budgetRangeEnd:              state.createEditProject.budgetRange.end,
        budgetRangeStart:            state.createEditProject.budgetRange.start,
        budgetRangeUnknown:          state.createEditProject.budgetRangeUnknown,
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
