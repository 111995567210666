//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import FileManager            from '../../stateless/FileManager';
import I18n                   from 'i18next';
import IconType               from '../../stateless/Icon/IconType';
import Overlay                from '../Overlay';
import OverlayButton          from '../../stateless/OverlayButton';
import OverlayButtonTheme     from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper   from '../../stateless/OverlayButtonWrapper';
import OverlayPane            from '../../stateless/OverlayPane';
import OverlayPaneState       from '../../stateless/OverlayPane/OverlayPaneState';
import Overlays               from '../../../constants/Overlays';
import PropTypes              from '../../PropTypes';
import Spacer                 from '../../stateless/Spacer';
import styles                 from '../../stateless/ImageUpload/styles.module.scss';
import { AlertBoxActions }    from '../../../store/actions/alertBox';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { CompanyActions }     from '../../../store/actions/company';
import { connect }            from 'react-redux';
import { goBack }             from 'connected-react-router';
import { v4 as uuidv4 }       from 'uuid';
import { withRouter }         from 'react-router-dom';
import NumberFormat           from '../../../helper/NumberFormat';
import InputError             from '../../stateless/InputError';

class Component extends React.Component {
    maxOtherImageDimensions = {
        imageWidth:  600,
        imageHeight: 600,
    };

    minOtherImageDimensions = {
        imageWidth:  300,
        imageHeight: 300,
    };

    checkImageDimensions = (file, maxDimensions, minDimensions, callback, errorCallback) => {
        const image  = new Image();
        image.onload = function () {
            if (
                image.width > maxDimensions.imageWidth ||
                image.height > maxDimensions.imageHeight ||
                image.width < minDimensions.imageWidth ||
                image.height < minDimensions.imageHeight
            ) {
                errorCallback();
            } else {
                callback(file, image.src);
            }
        };

        image.src = URL.createObjectURL(file);
    };

    cancelButtonPressed = () => {
        this.props.goBack();
    };

    fileDeletionButtonPressed = (index) => {
        this.props.temporaryRemoveOwnCompanyImage({ index });
    };

    getImageDimensions = () => {
        const minImageDimensions = this.minOtherImageDimensions;
        const maxImageDimensions = this.maxOtherImageDimensions;

        return {
            minImageDimensions,
            maxImageDimensions,
        };
    };

    uploadFileCallback = (file) => {
        this.props.clearAlerts();

        if (file.size > this.props.maxUploadSizeInBytes) {
            this.onImageSizeError();
        } else {
            const { minImageDimensions, maxImageDimensions } = this.getImageDimensions();

            this.checkImageDimensions(
                file,
                maxImageDimensions,
                minImageDimensions,
                this.onLoadingImageComplete,
                this.onImageDimensionError,
            );
        }
    };

    onImageDimensionError = () => {
        this.props.showErrorAlertTagged({
            tag:  'image',
            text: I18n.t('referenceImageDimensionError'),
        });
    };

    onImageSizeError = () => {
        this.props.showErrorAlertTagged({
            tag:  'image',
            text: I18n.t('imageSizeError'),
        });
    };

    onLoadingImageComplete = (file) => {
        const newId = uuidv4();

        this.props.uploadCompanyImage({
            id:   newId,
            name: file.name,
            file,
        });
    };

    render() {
        return (
            <Overlay
                id={Overlays.editCompanyImages}
                title={I18n.t('profileDetails')}
            >
                <OverlayPane
                    content={this.renderContent()}
                    footer={this.renderFooter()}
                    title={I18n.t('companyImages')}
                    showErrors={true}
                    state={OverlayPaneState.open}
                    scrollingAllowed={false}
                />
            </Overlay>
        );
    }

    renderContent = () => {
        const files = _.get(this.props, 'company.companyImages');

        return (
            <>
                <div className={styles.information}>
                    {
                        I18n.t(
                            'referenceImageInformation',
                            {
                                humanReadableFileSize: NumberFormat.toHumanReadableFileSize(this.props.maxUploadSizeInBytes, true),
                            },
                        )
                    }
                </div>
                <Spacer height={20} />
                <FileManager
                    addFileButtonIcon={IconType.fileText}
                    addFileButtonText={I18n.t('uploadImage')}
                    deleteButtonPressed={this.fileDeletionButtonPressed}
                    files={files}
                    uploadFileCallback={this.uploadFileCallback}
                />
                <InputError
                    text={this.props.imageError}
                    marginTop={20}
                />
                <Spacer height={40} />
            </>
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.cancelButtonPressed}
                    standAlone={false}
                    text={I18n.t('cancel')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.saveButtonPressed}
                    standAlone={false}
                    text={I18n.t('save')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    saveButtonPressed = () => {
        const companyImages = _.get(this.props, 'company.companyImages');

        requestAnimationFrame(() => {
            this.props.createOrDeleteCompanyImages({
                companyImages,
            });

            this.props.goBack();
        });
    };
}

Component.propTypes = {
    company:              PropTypes.object,
    maxUploadSizeInBytes: PropTypes.number,
};

Component.defaultProps = {
    company:              {},
    maxUploadSizeInBytes: 0,
};

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        AlertBoxActions,
        CompanyActions,
        {
            goBack,
        },
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        company:              _.get(state, 'company.ownCompanyEditContext', {}),
        imageError:           _.get(state, 'alertBox.alertBoxesTagged.image', null),
        maxUploadSizeInBytes: _.get(state, 'configuration.configuration.maxUploadSizeInBytes', 0),
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Component);
