//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import ComponentHelper        from '../../../helper/ComponentHelper';
import FormRow                from '../../stateless/FormRow';
import I18n                   from 'i18next';
import Input                  from '../../stateless/Input';
import InputType              from '../../stateless/Input/InputType';
import Overlay                from '../Overlay';
import OverlayButton          from '../../stateless/OverlayButton';
import OverlayButtonTheme     from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper   from '../../stateless/OverlayButtonWrapper';
import OverlayPane            from '../../stateless/OverlayPane';
import OverlayPaneState       from '../../stateless/OverlayPane/OverlayPaneState';
import Overlays               from '../../../constants/Overlays';
import PropTypes              from '../../PropTypes';
import Spacer                 from '../../stateless/Spacer';
import { AlertBoxActions }    from '../../../store/actions/alertBox';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { CompanyActions }     from '../../../store/actions/company';
import { connect }            from 'react-redux';
import { goBack }             from 'connected-react-router';
import { withRouter }         from 'react-router-dom';

class Component extends React.Component {
    cancelButtonPressed = () => {
        this.props.goBack();
    };

    componentDidMount() {
        if (!this.hasEmptySocialMedias()) {
            this.props.temporaryAddOwnCompanySocialMedia();
        }
    }

    hasEmptySocialMedias = () => {
        return _.some(this.props.socialMedias, this.isSocialMediasEmpty);
    };

    isSocialMediasEmpty = (socialMedia) => {
        return !socialMedia.url || socialMedia.url === '';
    };

    socialMediasChanged = (index) => {
        return (event) => {
            const value = event.target.value;

            this.props.clearAlerts();

            if (value) {
                this.props.temporarySetOwnCompanySocialMedia({
                    index,
                    url: value,
                });

                if (!this.hasEmptySocialMedias()) {
                    this.props.temporaryAddOwnCompanySocialMedia();
                }
            } else {
                this.props.temporaryRemoveOwnCompanySocialMedia({ index });
            }
        };
    };

    render() {
        return (
            <Overlay
                id={Overlays.editCompanySocialMedias}
                title={I18n.t('profileDetails')}
            >
                <OverlayPane
                    content={this.renderContent()}
                    footer={this.renderFooter()}
                    title={I18n.t('companySocialMedias')}
                    showErrors={true}
                    state={OverlayPaneState.open}
                    scrollingAllowed={false}
                />
            </Overlay>
        );
    }

    renderContent = () => {
        return this.props.socialMedias.map(this.renderVideoUrlField);
    };

    renderVideoUrlField = (socialMedia, index) => {
        const url              = _.get(socialMedia, 'url', '');
        const socialMediaError = _.get(this.props, 'socialMediaError');
        const error            = _.get(socialMediaError, 'index') === index ? _.get(socialMediaError, 'social_media') : null;

        return (
            <FormRow
                key={index}
                label={I18n.t('socialMediaUrl')}
                subLabel={I18n.t('socialMediaUrlSubtitle')}
                tooltipText={I18n.t('companySocialMediasTipText')}
            >
                <Input
                    placeholder={I18n.t('defaultPlaceHolderSocialMedia')}
                    type={InputType.text}
                    value={url}
                    minLength={1}
                    onChange={this.socialMediasChanged(index)}
                    noMarginBottom={true}
                    errorText={error}
                />
                <Spacer height={20} />
            </FormRow>
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.cancelButtonPressed}
                    standAlone={false}
                    text={I18n.t('cancel')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.saveButtonPressed}
                    standAlone={false}
                    text={I18n.t('save')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    saveButtonPressed = () => {
        requestAnimationFrame(() => {
            this.props.createOrUpdateOwnCompanySocialMedias({
                socialMedias: this.props.socialMedias,
            });
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {
    company:          PropTypes.object,
    socialMediaError: PropTypes.string,
    socialMedias:     PropTypes.array,
};

Component.defaultProps = {
    company:          {},
    socialMediaError: null,
    socialMedias:     [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        AlertBoxActions,
        CompanyActions,
        {
            goBack,
        },
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        company:          _.get(state, 'company.ownCompanyEditContext', {}),
        socialMedias:     _.get(state, 'company.ownCompanyEditContext.socialMedias', []),
        socialMediaError: _.get(state, 'alertBox.alertBoxesTagged', null),
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Component);
