//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const OverlayTypes = {
    CLOSE_OVERLAY:       'Overlay/CLOSE_OVERLAY',
    CLOSE_OVERLAY_CHECK: 'Overlay/CLOSE_OVERLAY_CHECK',
    OVERLAY_CLOSED:      'Overlay/OVERLAY_CLOSED',
    REOPEN_OVERLAY:      'Overlay/REOPEN_OVERLAY',
    RESET_LAST_ROUTE:    'Overlay/RESET_LAST_ROUTE',
    SAVE_LAST_ROUTE:     'Overlay/SAVE_LAST_ROUTE',
    SET_OPEN_OVERLAY:    'Overlay/SET_OPEN_OVERLAY',
};

const closeOverlay = makeActionCreator(
    OverlayTypes.CLOSE_OVERLAY,
);

const closeOverlayCheck = makeActionCreator(
    OverlayTypes.CLOSE_OVERLAY_CHECK
);

const overlayClosed = makeActionCreator(
    OverlayTypes.OVERLAY_CLOSED,
    {
        lastRoute: null,
    },
);

const reopenOverlay = makeActionCreator(
    OverlayTypes.REOPEN_OVERLAY,
);

const resetLastRoute = makeActionCreator(
    OverlayTypes.RESET_LAST_ROUTE,
);

const saveLastRoute = makeActionCreator(
    OverlayTypes.SAVE_LAST_ROUTE,
    {
        lastRoute: null,
    },
);

const setOpenOverlay = makeActionCreator(
    OverlayTypes.SET_OPEN_OVERLAY,
    {
        overlay: null,
    },
);

export const OverlayActions = {
    closeOverlay,
    closeOverlayCheck,
    overlayClosed,
    reopenOverlay,
    resetLastRoute,
    saveLastRoute,
    setOpenOverlay,
};
