//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import PropTypes       from '../../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';
import classNames      from 'classnames';
import ComponentHelper from '../../../helper/ComponentHelper';

class LoadingOverlay extends React.Component {
    render () {
        return (
            <div
                className={classNames(
                    styles.loadingOverlay,
                    {
                        [styles.loadingOverlayVisible]:               this.props.isLoading,
                        [styles.loadingOverlayAbsolutePosition]:      this.props.shouldPositionAbsolute,
                        [styles.loadingOverlayTransparentBackground]: this.props.withTransparentBackground,
                    },
                )}
            >
                <div
                    className={styles.loadingOverlayPulse}
                >
                    <span></span>
                    <span></span>
                </div>
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = LoadingOverlay;

Component.propTypes = {
    isLoading:                 PropTypes.bool,
    shouldPositionAbsolute:    PropTypes.bool,
    withTransparentBackground: PropTypes.bool,
    text:                      PropTypes.string,
};

Component.defaultProps = {
    isLoading:                 false,
    shouldPositionAbsolute:    false,
    withTransparentBackground: false,
    text:                      '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
