//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update                   from 'immutability-helper';
import { ActiveProjectTypes }   from '../actions/activeProject';
import { MessageComposerTypes } from '../actions/messageComposer';
import FileStates               from '../../constants/FileStates';
import { UserTypes }            from '../actions/user';

const initialState = {
    files:    {},
    messages: {},
};

const clearUnfinishedUploads = (action, state) => {
    let fileToDeleteAvailable    = false;
    const stateUpdateDescription = {
        files: {},
    };

    for (const contextKey in state.files) {
        const filesForCurrentContext = state.files[contextKey];
        const fileKeysToDelete       = [];

        for (const fileIndex in filesForCurrentContext) {
            const currentFile = filesForCurrentContext[fileIndex];

            if (currentFile.state === FileStates.uploading) {
                fileKeysToDelete.push(fileIndex);
            }
        }

        if (fileKeysToDelete.length) {
            if (!fileToDeleteAvailable) {
                fileToDeleteAvailable = true;
            }

            stateUpdateDescription.files[contextKey] = {
                $unset: fileKeysToDelete,
            };
        }
    }

    if (fileToDeleteAvailable) {
        return update(state, stateUpdateDescription);
    }

    return state;
};

const deleteFile = (action, state) => {
    return update(state, {
        files: {
            [action.contextKey]: {
                $unset: [action.id],
            },
        },
    });
};

const logout = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

const sendActiveMessageSuccess = (action, state) => {
    return update(state, {
        files:    {
            [action.contextKey]: {
                $set: [],
            },
        },
        messages: {
            [action.contextKey]: {
                $set: [],
            },
        },
    });
};

const setMessage = (action, state) => {
    return update(state, {
        messages: {
            [action.contextKey]: {
                $set: action.message,
            },
        },
    });
};

const updateUploadProgress = (action, state) => {
    return update(state, {
        files: {
            [action.contextKey]: {
                [action.id]: {
                    progress: {
                        $set: action.progress,
                    },
                    state:    {
                        $set: FileStates.uploading,
                    },
                },
            },
        },
    });
};

const uploadFile = (action, state) => {
    let stateUpdateDescription    = null;
    const newFileStateDescription = {
        id:       action.id,
        name:     action.file.name,
        size:     action.file.size,
        state:    FileStates.uploaded,
        progress: 0,
    };

    if (!state.files[action.contextKey]) {
        stateUpdateDescription = {
            files: {
                [action.contextKey]: {
                    $set: {
                        [action.id]: newFileStateDescription,
                    },
                },
            },
        };
    } else {
        stateUpdateDescription = {
            files: {
                [action.contextKey]: {
                    [action.id]: {
                        $set: newFileStateDescription,
                    },
                },
            },
        };
    }

    return update(state, stateUpdateDescription);
};

const uploadFileFailed = (action, state) => {
    return update(state, {
        files: {
            [action.contextKey]: {
                [action.id]: {
                    state: {
                        $set: FileStates.error,
                    },
                },
            },
        },
    });
};

const uploadFileSucceeded = (action, state) => {
    return update(state, {
        files: {
            [action.contextKey]: {
                [action.id]: {
                    iri:   {
                        $set: action.iri,
                    },
                    state: {
                        $set: FileStates.uploaded,
                    },
                },
            },
        },
    });
};

export default function (state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case ActiveProjectTypes.SEND_ACTIVE_MESSAGE_SUCCESS: return sendActiveMessageSuccess(action, state);
        case MessageComposerTypes.CLEAR_UNFINISHED_UPLOADS:  return clearUnfinishedUploads(action, state);
        case MessageComposerTypes.DELETE_FILE:               return deleteFile(action, state);
        case MessageComposerTypes.SET_MESSAGE:               return setMessage(action, state);
        case MessageComposerTypes.UPDATE_UPLOAD_PROGRESS:    return updateUploadProgress(action, state);
        case MessageComposerTypes.UPLOAD_FILE:               return uploadFile(action, state);
        case MessageComposerTypes.UPLOAD_FILE_FAILED:        return uploadFileFailed(action, state);
        case MessageComposerTypes.UPLOAD_FILE_SUCCEEDED:     return uploadFileSucceeded(action, state);
        case UserTypes.LOGOUT:                               return logout(action, state);
        default:                                             return state;
        // @formatter:on
    }
}