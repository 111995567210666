//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }         from 'redux-saga/effects';
import { call }        from 'redux-saga/effects';
import * as Api        from '../../api';
import HydraHelper     from '../../helper/Hydra';
import { GoalActions } from '../actions/goal';
import { push }        from 'connected-react-router';
import Routes          from '../../constants/Routes';

const fetchGoals = function* () {
    const response = yield call(
        Api.fetchGoals,
    );

    if (response.ok) {
        const cleanHydraResponse = HydraHelper.cleanupObject(response.data);
        const goals              = cleanHydraResponse.member;

        yield put(GoalActions.fetchGoalsSuccess({
            goals,
        }));
    } else {
        yield put(GoalActions.fetchGoalsFailed());
    }
};

export default {
    fetchGoals,
};
