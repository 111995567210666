//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                        from 'react';
import _                            from 'lodash';
import ApiMode                      from '../../../constants/ApiMode';
import ChoiceSelector               from '../../stateless/ChoiceSelector';
import ChoiceSelectorMode           from '../../stateless/ChoiceSelector/ChoiceSelectorMode';
import ChoiceWidthMode              from '../../stateless/ChoiceSelector/ChoiceWidthMode';
import ComponentHelper              from '../../../helper/ComponentHelper';
import DatePickerOverlayPosition    from '../../stateless/DatePicker/DatePickerOverlayPosition';
import ErrorAwareOverlayPane        from '../../connected/ErrorAwareOverlayPane';
import FormRow                      from '../../stateless/FormRow';
import GA                           from '../../../helper/GA';
import I18n                         from 'i18next';
import Input                        from '../../stateless/Input';
import InputType                    from '../../stateless/Input/InputType';
import OverlayButton                from '../../stateless/OverlayButton';
import OverlayButtonTheme           from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper         from '../../stateless/OverlayButtonWrapper';
import OverlayPaneState             from '../../stateless/OverlayPane/OverlayPaneState';
import PropTypes                    from '../../PropTypes';
import Spacer                       from '../../stateless/Spacer';
import RangeInput                   from '../../stateless/RangeInput';
import RangeInputTheme              from '../../stateless/RangeInput/RangeInputTheme';
import RangeInputType               from '../../stateless/RangeInput/RangeInputType';
import { AlertBoxActions }          from '../../../store/actions/alertBox';
import { bindActionCreators }       from 'redux';
import { connect }                  from 'react-redux';
import { CreateEditProjectActions } from '../../../store/actions/createEditProject';

class CreateProjectOverlayTimingsPane extends React.Component {
    eventLabel = 'Timings';

    backButtonPressed = () => {
        GA.sendCreateProjectPreviousEvent(this.eventLabel);
        this.props.clearAlerts();
        this.props.previousPane();
    };

    continueButtonPressed = () => {
        this.props.clearAlerts();

        if (
            this.props.fixedReleaseDateAvailable &&
            !this.props.fixedReleaseDate
        ) {
            this.props.showErrorAlertTagged({
                tag:  'fixedReleaseDate',
                text: I18n.t('fixedReleaseDateErrorEmpty'),
            });
        }

        if (
            !this.props.wishProductionPeriodDateUnknown &&
            (
                new Date(this.props.wishedProductionPeriodStartDate) >=
                new Date(this.props.wishedProductionPeriodEndDate)
            )
        ) {
            this.props.showErrorAlertTagged({
                tag:  'wishedProductionPeriodStartDate',
                text: I18n.t('wishedProductionPeriodErrorDateRange'),
            });
        }

        requestAnimationFrame(() => {
            if (this.props.alertBoxesTaggedCount === 0) {
                GA.sendCreateProjectNextEvent(this.eventLabel);
                if (this.props.mode === ApiMode.create) {
                    this.props.nextPane();
                } else if (this.props.mode === ApiMode.edit) {
                    this.props.updateProject();
                }
            }
        });
    };

    fixedReleaseDateChanged = (date) => {
        this.props.setFixedReleaseDate({
            fixedReleaseDate: date,
        });
    };

    fixedReleaseDateClicked = (choiceData) => {
        if (choiceData.value === null) {
            this.props.setFixedReleaseDateIsSameAsWishCompletionDate({
                fixedReleaseDateIsSameAsWishCompletionDate: true,
            });
        } else {
            this.props.setFixedReleaseDateAvailable({
                fixedReleaseDateAvailable: choiceData.value,
            });
        }
    };

    getContinueButtonText = () => {
        if (this.props.mode === ApiMode.edit) {
            return I18n.t('saveProject');
        }

        return I18n.t('continue');
    };

    getWishedProductionPeriodTheme = () => {
        if (this.props.wishProductionPeriodDateUnknown) {
            return RangeInputTheme.unimportant;
        }

        return RangeInputTheme.default;
    };

    render() {
        return (
            <ErrorAwareOverlayPane
                content={this.renderContent()}
                footer={this.renderFooter()}
                title={I18n.t('timings')}
                showErrors={true}
                state={this.props.overlayPaneState}
                upcomingDistance={this.props.upcomingDistance}
            />
        );
    }

    renderContent = () => {
        return (
            <>
                {this.renderContentWishCompletionDate()}
                {this.renderContentFixedReleaseDate()}
                {this.renderWishedProductionPeriod()}
                {
                    !this.props.fixedReleaseDateAvailable ?
                        (
                            <Spacer height={50} />
                        ) :
                        null
                }
            </>
        );
    };

    renderContentFixedReleaseDate = () => {
        return (
            <>
                <FormRow
                    label={I18n.t('fixedReleaseDateGivenLabel')}
                    tooltipText={I18n.t('fixedReleaseDateGivenTooltipText')}
                >
                    <ChoiceSelector
                        choiceClicked={this.fixedReleaseDateClicked}
                        choices={[
                            {
                                selected:  this.props.fixedReleaseDateAvailable === true && !this.props.fixedReleaseDateIsSameAsWishCompletionDate,
                                text:      I18n.t('yes'),
                                value:     true,
                                widthMode: ChoiceWidthMode.half,
                            },
                            {
                                selected:  this.props.fixedReleaseDateAvailable === false && !this.props.fixedReleaseDateIsSameAsWishCompletionDate,
                                text:      I18n.t('no'),
                                value:     false,
                                widthMode: ChoiceWidthMode.half,
                            },
                            {
                                selected:  this.props.fixedReleaseDateIsSameAsWishCompletionDate === true,
                                text:      I18n.t('sameDateAsAbove'),
                                value:     null,
                                widthMode: ChoiceWidthMode.full,
                            },
                        ]}
                        mode={ChoiceSelectorMode.multipleChoice}
                        errorText={this.props.fixedReleaseDateError}
                    />
                </FormRow>
                {
                    this.props.fixedReleaseDateAvailable === true && !this.props.fixedReleaseDateIsSameAsWishCompletionDate ?
                        (
                            <FormRow
                                label={I18n.t('fixedReleaseDateGiven')}
                                noGapToPreviousFormRow={true}
                            >
                                <Input
                                    key={this.props.fixedReleaseDate}
                                    onChange={this.fixedReleaseDateChanged}
                                    overlayPosition={DatePickerOverlayPosition.top}
                                    noMarginBottom={true}
                                    placeholder={'Datum auswählen'}
                                    type={InputType.date}
                                    value={this.props.fixedReleaseDate}
                                    paddingRight={true}
                                />
                            </FormRow>
                        ) :
                        null
                }
            </>
        );
    };

    renderContentWishCompletionDate = () => {
        const key = (
            this.props.wishCompletionDate +
            (
                this.props.wishCompletionDateUnknown ?
                    '_unknown' :
                    ''
            )
        );

        return (
            <FormRow
                label={I18n.t('wishCompletionDateLabel')}
                tooltipText={I18n.t('wishCompletionDateTooltipText')}
            >
                <Input
                    key={key}
                    markWhenHasContent={!this.props.wishCompletionDateUnknown}
                    onChange={this.wishCompletionDateChanged}
                    placeholder={I18n.t('defaultPlaceHolderTextDate')}
                    type={InputType.date}
                    value={this.props.wishCompletionDate}
                />
                <ChoiceSelector
                    choiceClicked={this.wishCompletionDateUnknownClicked}
                    choices={[
                        {
                            selected:  this.props.wishCompletionDateUnknown,
                            text:      I18n.t('fillLaterChoiceText'),
                            widthMode: ChoiceWidthMode.full,
                        },
                    ]}
                    mode={ChoiceSelectorMode.singleChoice}
                />
            </FormRow>
        );
    };

    renderWishedProductionPeriod = () => {
        return (
            <FormRow
                label={I18n.t('wishedProductionPeriodLabel')}
                tooltipText={I18n.t('wishedProductionPeriodTooltipText')}
            >
                <RangeInput
                    errorTextEnd={this.props.wishedProductionPeriodEndDateError}
                    errorTextStart={this.props.wishedProductionPeriodStartDateError}
                    overlayPosition={DatePickerOverlayPosition.top}
                    type={RangeInputType.date}
                    theme={this.getWishedProductionPeriodTheme()}
                    onChange={this.wishedProductionPeriodChanged}
                    valueEnd={this.props.wishedProductionPeriodEndDate}
                    valueStart={this.props.wishedProductionPeriodStartDate}
                />
                <ChoiceSelector
                    choiceClicked={this.wishProductionPeriodUnknownClicked}
                    choices={[
                        {
                            selected:  this.props.wishProductionPeriodDateUnknown,
                            text:      I18n.t('fillLaterChoiceText'),
                            widthMode: ChoiceWidthMode.full,
                        },
                    ]}
                    mode={ChoiceSelectorMode.singleChoice}
                />
            </FormRow>
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.backButtonPressed}
                    standAlone={false}
                    text={I18n.t('back')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.continueButtonPressed}
                    standAlone={false}
                    text={this.getContinueButtonText()}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    wishCompletionDateChanged = (date) => {
        this.props.setWishCompletionDate({
            wishCompletionDate: date,
        });
    };

    wishCompletionDateUnknownClicked = () => {
        this.props.setWishCompletionDate({
            wishCompletionDate: null,
        });
        this.props.toggleWishCompletionDateUnknown();
    };

    wishProductionPeriodUnknownClicked = () => {
        this.wishedProductionPeriodChanged({
            endValue:   null,
            startValue: null,
        });
        this.props.toggleWishProductionPeriodUnknown();
    };

    wishedProductionPeriodChanged = (period) => {
        this.props.setWishedProductionPeriod({
            endDate:   period.endValue,
            startDate: period.startValue,
        });
    };
}

const Component = CreateProjectOverlayTimingsPane;

Component.propTypes = {
    fixedReleaseDate:                           PropTypes.string,
    fixedReleaseDateAvailable:                  PropTypes.bool,
    fixedReleaseDateError:                      PropTypes.string,
    fixedReleaseDateIsSameAsWishCompletionDate: PropTypes.bool,
    mode:                                       PropTypes.oneOf(Object.keys(ApiMode)),
    overlayPaneState:                           PropTypes.oneOf(Object.values(OverlayPaneState)),
    upcomingDistance:                           PropTypes.number,
    wishCompletionDate:                         PropTypes.string,
    wishCompletionDateUnknown:                  PropTypes.bool,
    wishedProductionPeriodEndDate:              PropTypes.string,
    wishedProductionPeriodEndDateError:         PropTypes.string,
    wishedProductionPeriodStartDate:            PropTypes.string,
    wishedProductionPeriodStartDateError:       PropTypes.string,
    wishProductionPeriodDateUnknown:            PropTypes.bool,
};

Component.defaultProps = {
    fixedReleaseDate:                           null,
    fixedReleaseDateAvailable:                  null,
    fixedReleaseDateError:                      '',
    fixedReleaseDateIsSameAsWishCompletionDate: null,
    mode:                                       null,
    overlayPaneState:                           OverlayPaneState.upcoming,
    upcomingDistance:                           0,
    wishCompletionDate:                         null,
    wishCompletionDateUnknown:                  true,
    wishedProductionPeriodEndDate:              null,
    wishedProductionPeriodEndDateError:         null,
    wishedProductionPeriodStartDate:            null,
    wishedProductionPeriodStartDateError:       null,
    wishProductionPeriodDateUnknown:            true,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    AlertBoxActions,
    CreateEditProjectActions,
), dispatch);

const mapStateToProps = state => (
    {
        // @formatter:off
        alertBoxesTaggedCount:                      _.get(state, 'alertBox.alertBoxesTaggedCount', 0),
        fixedReleaseDate:                           _.get(state, 'createEditProject.fixedReleaseDate'),
        fixedReleaseDateAvailable:                  _.get(state, 'createEditProject.fixedReleaseDateAvailable'),
        fixedReleaseDateError:                      _.get(state, 'alertBox.alertBoxesTagged.fixedReleaseDate'),
        fixedReleaseDateIsSameAsWishCompletionDate: _.get(state, 'createEditProject.fixedReleaseDateIsSameAsWishCompletionDate'),
        mode:                                       _.get(state, 'createEditProject.mode'),
        wishCompletionDate:                         _.get(state, 'createEditProject.wishCompletionDate'),
        wishCompletionDateUnknown:                  _.get(state, 'createEditProject.wishCompletionDateUnknown'),
        wishedProductionPeriodEndDate:              _.get(state, 'createEditProject.wishedProductionPeriod.endDate'),
        wishedProductionPeriodEndDateError:         _.get(state, 'alertBox.alertBoxesTagged.wishedProductionPeriodEndDate'),
        wishedProductionPeriodStartDate:            _.get(state, 'createEditProject.wishedProductionPeriod.startDate'),
        wishedProductionPeriodStartDateError:       _.get(state, 'alertBox.alertBoxesTagged.wishedProductionPeriodStartDate'),
        wishProductionPeriodDateUnknown:            _.get(state, 'createEditProject.wishProductionPeriodDateUnknown'),
        // @formatter:on
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
