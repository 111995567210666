//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                from 'lodash';
import FileStates       from '../../constants/FileStates';
import update           from 'immutability-helper';
import { CompanyTypes } from '../actions/company';
import { UserTypes }    from '../actions/user';

const emptyAddress = {
    street:      null,
    houseNumber: null,
    postalCode:  null,
    city:        null,
};

const initialState = {
    companies:             {},
    ownCompany:            {},
    ownCompanyEditContext: {},
    profile:               {},
};

const addLocationsIfMissing = (state) => {
    return update(state, {
        ownCompany: {
            $set: {
                companyLocations: [],
            },
        },
    });
};

const addAddressIfMissing = (state) => {
    if (!_.get(state, 'ownCompany.companyLocations')) {
        state = addLocationsIfMissing(state);
    }

    return update(state, {
        ownCompany: {
            companyLocations: {
                $push: [
                    {
                        address: emptyAddress,
                    },
                ],
            },
        },
    });
};

const createCompanySuccess = (action, state) => {
    return update(state, {
        ownCompany: {
            $set: action.company,
        },
    });
};

const editOwnCompanyCompetences = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            $set: state.ownCompany,
        },
    });
};

const editOwnCompanyHeader = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            $set: state.ownCompany,
        },
    });
};

const editOwnCompanyImages = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            $set: state.ownCompany,
        },
    });
};

const editOwnCompanyInformation = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            $set: state.ownCompany,
        },
    });
};

const editOwnCompanyLanguages = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            $set: state.ownCompany,
        },
    });
};

const editOwnCompanyPlaceOfWorks = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            $set: state.ownCompany,
        },
    });
};

const editOwnCompanyLocations = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            $set: state.ownCompany,
        },
    });
};

const editOwnCompanyReferences = (action, state) => {
    const ownCompany      = _.cloneDeep(state.ownCompany);
    ownCompany.references = _.orderBy(ownCompany.references, ['referenceOrder'], ['asc']);

    return update(state, {
        ownCompanyEditContext: {
            $set: ownCompany,
        },
    });
};

const editOwnCompanySocialMedias = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            $set: state.ownCompany,
        },
    });
};

const fetchCompanySuccess = (action, state) => {
    const stateChangeDefinition = {
        companies: {
            [action.company.iri]: {
                $set: action.company,
            },
        },
        profile:   {
            $set: action.company,
        },
    };

    if (action.company.iri === state.ownCompany.iri) {
        stateChangeDefinition.ownCompany = {
            $set: action.company,
        };
    }

    return update(state, stateChangeDefinition);
};

const fetchOwnCompanySuccess = (action, state) => {
    return update(state, {
        companies:  {
            [action.company.iri]: {
                $set: action.company,
            },
        },
        ownCompany: {
            $set: action.company,
        },
    });
};

const setOwnCompanyAddressCity = (action, state) => {
    if (!_.get(state, 'ownCompany.companyLocations', []).length) {
        state = addAddressIfMissing(state);
    }

    return update(state, {
        ownCompany: {
            companyLocations: {
                [action.index]: {
                    address: {
                        city: {
                            $set: action.city,
                        },
                    },
                },
            },
        },
    });
};

const setOwnCompanyAddressHouseNumber = (action, state) => {
    if (!_.get(state, 'ownCompany.companyLocations', []).length) {
        state = addAddressIfMissing(state);
    }

    return update(state, {
        ownCompany: {
            companyLocations: {
                [action.index]: {
                    address: {
                        houseNumber: {
                            $set: action.houseNumber,
                        },
                    },
                },
            },
        },
    });
};

const setOwnCompanyAddressPostalCode = (action, state) => {
    if (!_.get(state, 'ownCompany.companyLocations', []).length) {
        state = addAddressIfMissing(state);
    }

    return update(state, {
        ownCompany: {
            companyLocations: {
                [action.index]: {
                    address: {
                        postalCode: {
                            $set: action.postalCode,
                        },
                    },
                },
            },
        },
    });
};

const setOwnCompanyAddressStreet = (action, state) => {
    if (!_.get(state, 'ownCompany.companyLocations', []).length) {
        state = addAddressIfMissing(state);
    }

    return update(state, {
        ownCompany: {
            companyLocations: {
                [action.index]: {
                    address: {
                        street: {
                            $set: action.street,
                        },
                    },
                },
            },
        },
    });
};

const logout = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

const setOwnCompanyName = (action, state) => {
    return update(state, {
        ownCompany: {
            name: {
                $set: action.name,
            },
        },
    });
};

const setOwnCompanyRoleInCompany = (action, state) => {
    return update(state, {
        ownCompany: {
            roleInCompany: {
                $set: action.roleInCompany,
            },
        },
    });
};

const setOwnCompanyWebsite = (action, state) => {
    return update(state, {
        ownCompany: {
            website: {
                $set: action.website,
            },
        },
    });
};

const temporaryAddOwnCompanyCompetence = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            coreCompetences: {
                $push: [action.competence],
            },
        },
    });
};

const temporaryAddOwnCompanyLanguage = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            languages: {
                $push: [action.language],
            },
        },
    });
};

const temporaryAddOwnCompanyLocation = (action, state) => {
    if (state.ownCompanyEditContext.companyLocations) {
        return update(state, {
            ownCompanyEditContext: {
                companyLocations: {
                    $push: [
                        {
                            address: emptyAddress,
                        },
                    ],
                },
            },
        });
    }

    return state;
};

const temporaryAddOwnCompanyReference = (action, state) => {
    if (state.ownCompanyEditContext.references) {
        return update(state, {
            ownCompanyEditContext: {
                references: {
                    $push: [{}],
                },
            },
        });
    }

    return state;
};

const temporaryAddOwnCompanySocialMedia = (action, state) => {
    if (state.ownCompanyEditContext.socialMedias) {
        return update(state, {
            ownCompanyEditContext: {
                socialMedias: {
                    $push: [{}],
                },
            },
        });
    }

    return state;
};

const temporaryRemoveOwnCompanyCompetence = (action, state) => {
    const competenceIndex = _.findIndex(
        state.ownCompanyEditContext.coreCompetences,
        (competence) => {
            return JSON.stringify(competence) === JSON.stringify(action.competence);
        },
    );

    return update(state, {
        ownCompanyEditContext: {
            coreCompetences: {
                $splice: [
                    [
                        competenceIndex,
                        1,
                    ],
                ],
            },
        },
    });
};

const temporaryRemoveOwnCompanyImage = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            companyImages: {
                [action.index]: {
                    delete: {
                        $set: true,
                    },
                },
            },
        },
    });
};

const temporaryRemoveOwnCompanyLanguage = (action, state) => {
    const languageIndex = _.findIndex(
        state.ownCompanyEditContext.languages,
        (language) => JSON.stringify(language) === JSON.stringify(action.language),
    );

    return update(state, {
        ownCompanyEditContext: {
            languages: {
                $splice: [
                    [
                        languageIndex,
                        1,
                    ],
                ],
            },
        },
    });
};

const temporaryRemoveOwnCompanyLocation = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            companyLocations: {
                $splice: [
                    [
                        action.index,
                        1,
                    ],
                ],
            },
        },
    });
};

const temporaryRemoveOwnCompanyBanner = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            bannerImage: {
                $set: null,
            },
        },
    });
};

const temporaryRemoveOwnCompanyLogo = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            logo: {
                $set: null,
            },
        },
    });
};

const temporaryRemoveOwnCompanyReference = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            references: {
                $splice: [
                    [
                        action.index,
                        1,
                    ],
                ],
            },
        },
    });
};

const temporaryRemoveOwnCompanySocialMedia = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            socialMedias: {
                $splice: [
                    [
                        action.index,
                        1,
                    ],
                ],
            },
        },
    });
};

const temporarySetOwnCompanyAdminUser = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            adminUser: {
                $set: action.userIri,
            },
        },
    });
};

const temporarySetOwnCompanyDescription = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            description: {
                $set: action.description,
            },
        },
    });
};

const temporarySetOwnCompanyLocation = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            companyLocations: {
                [action.index]: {
                    address: {
                        $set: action.address,
                    },
                },
            },
        },
    });
};

const temporarySetOwnCompanyLogo = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            logo: {
                $set: action.logo,
            },
        },
    });
};

const temporaryAddOwnCompanyName = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            name: {
                $set: action.name,
            },
        },
    });
};

const temporarySetOwnCompanyPhoneNumber = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            contactData: {
                phoneNumber: {
                    $set: action.phoneNumber,
                },
            },
        },
    });
};

const temporarySetOwnCompanyPlaceOfWork = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            placeOfWork: {
                $set: action.placeOfWork,
            },
        },
    });
};

const temporarySetOwnCompanyReferenceVideoUrl = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            references: {
                [action.index]: {
                    videoUrl: {
                        $set: action.videoUrl,
                    },
                },
            },
        },
    });
};

const temporarySetOwnCompanyRoleInCompany = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            roleInCompany: {
                $set: action.roleInCompany,
            },
        },
    });
};

const temporarySetOwnCompanySocialMedia = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            socialMedias: {
                [action.index]: {
                    url: {
                        $set: action.url,
                    },
                },
            },
        },
    });
};

const temporarySetOwnCompanyType = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            companyType: {
                $set: action.companyType,
            },
        },
    });
};

const temporarySetOwnCompanyWebsite = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            website: {
                $set: action.website,
            },
        },
    });
};

const updateCompanyUploadProgress = (action, state) => {
    return update(state, {
        profile: {
            logo: {
                $set: {
                    progress: {
                        $set: action.progress,
                    },
                    state:    {
                        $set: FileStates.uploading,
                    },
                },
            },
        },
    });
};

const uploadCompanyBannerSucceeded = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            bannerImage: {
                $set: action.iri,
            },
        },
    });
};

const uploadCompanyImageSucceeded = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            companyImages: {
                $push: [
                    {
                        iri:  action.iri,
                        name: action.name,
                    },
                ],
            },
        },
    });
};

const uploadCompanyLogoSucceeded = (action, state) => {
    return update(state, {
        ownCompanyEditContext: {
            logo: {
                $set: action.iri,
            },
        },
    });
};

export default function (state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case CompanyTypes.CREATE_COMPANY_SUCCESS:                        return createCompanySuccess(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_COMPETENCES:                  return editOwnCompanyCompetences(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_HEADER:                       return editOwnCompanyHeader(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_IMAGES:                       return editOwnCompanyImages(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_INFORMATION:                  return editOwnCompanyInformation(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_LANGUAGES:                    return editOwnCompanyLanguages(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_LOCATIONS:                    return editOwnCompanyLocations(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_PLACE_OF_WORKS:               return editOwnCompanyPlaceOfWorks(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_REFERENCES:                   return editOwnCompanyReferences(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_SOCIAL_MEDIAS:                return editOwnCompanySocialMedias(action, state);
        case CompanyTypes.FETCH_COMPANY_SUCCESS:
        case CompanyTypes.UPDATE_COMPANY_SUCCESS:                        return fetchCompanySuccess(action, state);
        case CompanyTypes.FETCH_OWN_COMPANY_SUCCESS:                     return fetchOwnCompanySuccess(action, state);
        case CompanyTypes.SET_OWN_COMPANY_ADDRESS_CITY:                  return setOwnCompanyAddressCity(action, state);
        case CompanyTypes.SET_OWN_COMPANY_ADDRESS_HOUSE_NUMBER:          return setOwnCompanyAddressHouseNumber(action, state);
        case CompanyTypes.SET_OWN_COMPANY_ADDRESS_POSTAL_CODE:           return setOwnCompanyAddressPostalCode(action, state);
        case CompanyTypes.SET_OWN_COMPANY_ADDRESS_STREET:                return setOwnCompanyAddressStreet(action, state);
        case CompanyTypes.SET_OWN_COMPANY_NAME:                          return setOwnCompanyName(action, state);
        case CompanyTypes.SET_OWN_COMPANY_ROLE_IN_COMPANY:               return setOwnCompanyRoleInCompany(action, state);
        case CompanyTypes.SET_OWN_COMPANY_WEBSITE:                       return setOwnCompanyWebsite(action, state);
        case CompanyTypes.TEMPORARY_ADD_OWN_COMPANY_COMPETENCE:          return temporaryAddOwnCompanyCompetence(action, state);
        case CompanyTypes.TEMPORARY_ADD_OWN_COMPANY_LANGUAGE:            return temporaryAddOwnCompanyLanguage(action, state);
        case CompanyTypes.TEMPORARY_ADD_OWN_COMPANY_LOCATION:            return temporaryAddOwnCompanyLocation(action, state);
        case CompanyTypes.TEMPORARY_ADD_OWN_COMPANY_REFERENCE:           return temporaryAddOwnCompanyReference(action, state);
        case CompanyTypes.TEMPORARY_ADD_OWN_COMPANY_SOCIAL_MEDIA:        return temporaryAddOwnCompanySocialMedia(action, state);
        case CompanyTypes.TEMPORARY_REMOVE_OWN_COMPANY_BANNER:           return temporaryRemoveOwnCompanyBanner(action, state);
        case CompanyTypes.TEMPORARY_REMOVE_OWN_COMPANY_COMPETENCE:       return temporaryRemoveOwnCompanyCompetence(action, state);
        case CompanyTypes.TEMPORARY_REMOVE_OWN_COMPANY_IMAGE:            return temporaryRemoveOwnCompanyImage(action, state);
        case CompanyTypes.TEMPORARY_REMOVE_OWN_COMPANY_LANGUAGE:         return temporaryRemoveOwnCompanyLanguage(action, state);
        case CompanyTypes.TEMPORARY_REMOVE_OWN_COMPANY_LOCATION:         return temporaryRemoveOwnCompanyLocation(action, state);
        case CompanyTypes.TEMPORARY_REMOVE_OWN_COMPANY_LOGO:             return temporaryRemoveOwnCompanyLogo(action, state);
        case CompanyTypes.TEMPORARY_REMOVE_OWN_COMPANY_REFERENCE:        return temporaryRemoveOwnCompanyReference(action, state);
        case CompanyTypes.TEMPORARY_REMOVE_OWN_COMPANY_SOCIAL_MEDIA:     return temporaryRemoveOwnCompanySocialMedia(action, state);
        case CompanyTypes.TEMPORARY_SET_OWN_COMPANY_ADMIN_USER:          return temporarySetOwnCompanyAdminUser(action, state);
        case CompanyTypes.TEMPORARY_SET_OWN_COMPANY_DESCRIPTION:         return temporarySetOwnCompanyDescription(action, state);
        case CompanyTypes.TEMPORARY_SET_OWN_COMPANY_LOCATION:            return temporarySetOwnCompanyLocation(action, state);
        case CompanyTypes.TEMPORARY_SET_OWN_COMPANY_LOGO:                return temporarySetOwnCompanyLogo(action, state);
        case CompanyTypes.TEMPORARY_SET_OWN_COMPANY_NAME:                return temporaryAddOwnCompanyName(action, state);
        case CompanyTypes.TEMPORARY_SET_OWN_COMPANY_PHONE_NUMBER:        return temporarySetOwnCompanyPhoneNumber(action, state);
        case CompanyTypes.TEMPORARY_SET_OWN_COMPANY_PLACE_OF_WORK:       return temporarySetOwnCompanyPlaceOfWork(action, state);
        case CompanyTypes.TEMPORARY_SET_OWN_COMPANY_REFERENCE_VIDEO_URL: return temporarySetOwnCompanyReferenceVideoUrl(action, state);
        case CompanyTypes.TEMPORARY_SET_OWN_COMPANY_ROLE_IN_COMPANY:     return temporarySetOwnCompanyRoleInCompany(action, state);
        case CompanyTypes.TEMPORARY_SET_OWN_COMPANY_SOCIAL_MEDIA:        return temporarySetOwnCompanySocialMedia(action, state);
        case CompanyTypes.TEMPORARY_SET_OWN_COMPANY_TYPE:                return temporarySetOwnCompanyType(action, state);
        case CompanyTypes.TEMPORARY_SET_OWN_COMPANY_WEBSITE:             return temporarySetOwnCompanyWebsite(action, state);
        case CompanyTypes.UPDATE_COMPANY_UPLOAD_PROGRESS:                return updateCompanyUploadProgress(action, state);
        case CompanyTypes.UPLOAD_COMPANY_BANNER_SUCCEEDED:               return uploadCompanyBannerSucceeded(action, state);
        case CompanyTypes.UPLOAD_COMPANY_IMAGE_SUCCEEDED:                return uploadCompanyImageSucceeded(action, state);
        case CompanyTypes.UPLOAD_COMPANY_LOGO_SUCCEEDED:                 return uploadCompanyLogoSucceeded(action, state);
        case UserTypes.LOGOUT:                                           return logout(action, state);
        default:                                                         return state;
        // @formatter:on
    }
}
