//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper        from '../../../helper/ComponentHelper';
import React                  from 'react';
import UserMenuButton         from '../../stateless/UserMenuButton';
import UserMenuButtonTheme    from '../../stateless/UserMenuButton/UserMenuButtonTheme';
import I18n                   from 'i18next';
import { bindActionCreators } from 'redux';
import { UserActions }        from '../../../store/actions/user';
import { connect }            from 'react-redux';

class LogoutUserMenuItem extends React.Component {
    logoutButtonPressed = () => {
        this.props.logout();
    };

    render () {
        return (
            <UserMenuButton
                onClick={this.logoutButtonPressed}
                text={I18n.t('logout')}
                theme={UserMenuButtonTheme.default}
                tip={I18n.t('logoutTip')}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = LogoutUserMenuItem;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(UserActions, dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(Component);