//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import AlertBoxType                            from '../../components/stateless/AlertBox/AlertBoxType';
import SelectionHelper                         from '../../helper/SelectionHelper';
import update                                  from 'immutability-helper';
import { AlertBoxTypes as AlertBoxTypesRedux } from '../actions/alertBox';
import { UserTypes }                           from '../actions/user';
import { LOCATION_CHANGE }                     from 'connected-react-router';

const initialState       = {
    alertBoxes:            [],
    alertBoxesTagged:      {},
    alertBoxesTaggedCount: 0,
};
const maximumLifeCounter = 1;

// https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-215

export default function (state = initialState, action) {
    switch (action.type) {
        case AlertBoxTypesRedux.CLEAR:
        case UserTypes.LOGOUT:
            return update(state, {
                $set: initialState,
            });

        case AlertBoxTypesRedux.CLEAR_SINGLE:
            return update(state, {
                alertBoxes: {
                    $splice: [
                        [
                            action.index,
                            1,
                        ],
                    ],
                },
            });

        case AlertBoxTypesRedux.SHOW_ERROR:
        case AlertBoxTypesRedux.SHOW_SUCCESS:
            const type = SelectionHelper.get(
                action.type,
                {
                    [AlertBoxTypesRedux.SHOW_ERROR]:   AlertBoxType.error,
                    [AlertBoxTypesRedux.SHOW_SUCCESS]: AlertBoxType.success,
                },
            );

            return update(state, {
                alertBoxes: {
                    $push: [
                        {
                            lifeCounter: 0,
                            text:        action.text,
                            type,
                        },
                    ],
                },
            });

        case AlertBoxTypesRedux.SHOW_ERROR_TAGGED:
            return update(state, {
                alertBoxesTagged:      {
                    [action.tag]: {
                        $set: action.text,
                    },
                    index:        {
                        $set: action.index,
                    },
                },
                alertBoxesTaggedCount: {
                    $increaseBy: 1,
                },
            });

        case LOCATION_CHANGE:
            const locationChangeUpdateConfiguration = {
                alertBoxes: {},
            };
            const alertBoxesToDelete                = [];

            for (const alertBoxIndex in state.alertBoxes) {
                const alertBox = state.alertBoxes[alertBoxIndex];

                if (alertBox.lifeCounter < maximumLifeCounter) {
                    locationChangeUpdateConfiguration.alertBoxes[alertBoxIndex] = {
                        lifeCounter: {
                            $set: alertBox.lifeCounter + 1,
                        },
                    };
                } else {
                    alertBoxesToDelete.push([
                        alertBoxIndex,
                        1,
                    ]);
                }
            }

            const stateWithIncreasedLifeCounters = update(state, locationChangeUpdateConfiguration);

            alertBoxesToDelete.reverse();

            const stateWithRemovedAlertBoxes = update(stateWithIncreasedLifeCounters, {
                alertBoxes: {
                    $splice: alertBoxesToDelete,
                },
            });

            return stateWithRemovedAlertBoxes;

        default:
            return state;
    }
}
