//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import I18n            from 'i18next';
import Icon            from '../../components/stateless/Icon';
import IconType        from '../../components/stateless/Icon/IconType';
import Link            from '../../components/stateless/Link';
import PageTitleHelper from '../../helper/PageTitle';
import styles          from './styles.module.scss';
import { Component }   from 'react';
import { compose }     from 'redux';
import { connect }     from 'react-redux';
import { Helmet }      from 'react-helmet';
import { withRouter }  from 'react-router-dom';

class Screen extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('mobileRegisterSuccess'))}
                    </title>
                </Helmet>
                <div className={styles.container}>
                    <div className={styles.textWrapper}>
                        <h1>
                            {I18n.t('mobileRegisterSuccessTitle')}
                        </h1>
                        <p>
                            {I18n.t('mobileRegisterSuccessText')}
                        </p>
                        <div className={styles.socialMediaContainer}>
                            {I18n.t('mobileRegisterSuccessSocialText')}
                            {' '}
                            <Link
                                to={{
                                    pathname: I18n.t('instagramUrl'),
                                }}
                                target={'_blank'}
                            >
                                <Icon iconType={IconType.instagram} />
                            </Link>
                            <Link
                                to={{
                                    pathname: I18n.t('facebookUrl'),
                                }}
                                target={'_blank'}
                            >
                                <Icon iconType={IconType.facebook} />
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default compose(
    connect(
        null,
        null,
    ),
    withRouter,
)(Screen);
