//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const GoalTypes = {
    FETCH_GOALS:         'Goal/FETCH_GOALS',
    FETCH_GOALS_FAILED:  'Goal/FETCH_GOALS_FAILED',
    FETCH_GOALS_SUCCESS: 'Goal/FETCH_GOALS_SUCCESS',
};

const fetchGoals = makeActionCreator(
    GoalTypes.FETCH_GOALS,
);

const fetchGoalsFailed = makeActionCreator(
    GoalTypes.FETCH_GOALS_FAILED,
);

const fetchGoalsSuccess = makeActionCreator(
    GoalTypes.FETCH_GOALS_SUCCESS,
    {
        goals: null,
    },
);

export const GoalActions = {
    fetchGoals,
    fetchGoalsFailed,
    fetchGoalsSuccess,
};
