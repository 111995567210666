//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                      from 'react';
import { Component }              from 'react';
import _                          from 'lodash';
import AlertBoxManager            from '../../components/connected/AlertBoxManager';
import CompanyTypes               from '../../constants/CompanyTypes';
import ContentWrapper             from '../../components/stateless/ContentWrapper';
import I18n                       from 'i18next';
import MatchStateTypes            from '../../constants/MatchStateTypes';
import moment                     from 'moment';
import NoDataAvailablePlaceholder from '../../components/stateless/NoDataAvailablePlaceholder';
import PageTitleHelper            from '../../helper/PageTitle';
import ProjectContentItem         from '../../components/connected/ProjectContentItem';
import ProjectStateTypes          from '../../constants/ProjectStateTypes';
import Routes                     from '../../constants/Routes';
import Spacer                     from '../../components/stateless/Spacer';
import TabBar                     from '../../components/stateless/TabBar';
import { bindActionCreators }     from 'redux';
import { connect }                from 'react-redux';
import { DashboardActions }       from '../../store/actions/dashboard';
import { Helmet }                 from 'react-helmet';

const VIDEO_PRODUCTION_NEW_PROJECTS      = 0;
const VIDEO_PRODUCTION_MISSED_PROJECTS   = 1;
const VIDEO_PRODUCTION_ACCEPTED_PROJECTS = 2;
const VIDEO_PRODUCTION_FINISHED_PROJECTS = 3;
const COMPANY_INCOMPLETE_PROJECTS        = 0;
const COMPANY_COMPLETE_PROJECTS          = 1;
const COMPANY_COMMISSIONED_PROJECTS      = 2;
const COMPANY_FINISHED_PROJECTS          = 3;

class Screen extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        const projectsForActiveTab = this.getProjectsForActiveTab();

        if (!projectsForActiveTab.length && prevProps.projects !== this.props.projects) {
            this.setActiveTab(0);
        }
    }

    getProjectsForActiveTab = () => {
        const filteredProjects = _.filter(this.props.projects, this.relevantProjectsForActiveTabCallback);
        const sortedProjects   = _.sortBy(
            filteredProjects,
            [
                function (project) {
                    return moment(project.updatedAt);
                },
            ],
        ).reverse();

        return sortedProjects;
    };

    relevantProjectsForActiveTabCallback = (project) => {
        const activeTabIndex = this.props.activeTabIndex;

        return (
            (
                this.props.companyType === CompanyTypes.company &&
                (
                    (
                        activeTabIndex === COMPANY_INCOMPLETE_PROJECTS &&
                        project.state === ProjectStateTypes.incomplete
                    )
                    ||
                    (
                        activeTabIndex === COMPANY_COMPLETE_PROJECTS &&
                        project.state === ProjectStateTypes.complete
                    )
                    ||
                    (
                        activeTabIndex === COMPANY_COMMISSIONED_PROJECTS &&
                        project.state === ProjectStateTypes.commissioned
                    )
                    ||
                    (
                        activeTabIndex === COMPANY_FINISHED_PROJECTS &&
                        project.state === ProjectStateTypes.finished
                    )
                )
            )
            ||
            (
                this.props.companyType === CompanyTypes.videoProduction &&
                (
                    (
                        activeTabIndex === VIDEO_PRODUCTION_NEW_PROJECTS &&
                        project.bestMatchState === MatchStateTypes.proposed
                    )
                    ||
                    (
                        activeTabIndex === VIDEO_PRODUCTION_MISSED_PROJECTS &&
                        project.bestMatchState === MatchStateTypes.declined &&
                        project.state !== ProjectStateTypes.finished
                    )
                    ||
                    (
                        activeTabIndex === VIDEO_PRODUCTION_ACCEPTED_PROJECTS &&
                        project.bestMatchState === MatchStateTypes.accepted &&
                        project.state !== ProjectStateTypes.finished
                    )
                    ||
                    (
                        activeTabIndex === VIDEO_PRODUCTION_FINISHED_PROJECTS &&
                        project.state === ProjectStateTypes.finished
                    )
                )
            )
        );
    };

    getTitles = () => {
        if (this.props.companyType === CompanyTypes.company) {
            return [
                I18n.t('incompleteProjects'),
                I18n.t('completeProjects'),
                I18n.t('commissionedProjects'),
                I18n.t('finishedProjects'),
            ];
        }

        if (this.props.companyType === CompanyTypes.videoProduction) {
            return [
                I18n.t('newProjects'),
                I18n.t('missedProjects'),
                I18n.t('acceptedProjects'),
                I18n.t('finishedProjects'),
            ];
        }

        return [];
    };

    onTabChanged = (tabIndex) => {
        this.setActiveTab(tabIndex);
    };

    setActiveTab = (tabIndex) => {
        this.props.setActiveTab({
            tabIndex,
        });
    };

    render() {
        if (this.props.projects && this.props.projects.length) {
            return (
                <ContentWrapper>
                    <Helmet>
                        <title>
                            {PageTitleHelper.getPageTitle(I18n.t('projectOverview'))}
                        </title>
                    </Helmet>
                    <AlertBoxManager />
                    <TabBar
                        activeTabIndex={this.props.activeTabIndex}
                        tabChanged={this.onTabChanged}
                        tabTitles={this.getTitles()}
                    />
                    {this.renderProjectsForActiveTab()}
                </ContentWrapper>
            );
        }

        const companyProfileRoute = Routes.companyProfilePart + _.get(this.props, 'companyId');

        return (
            <>
                <Spacer height={100} />
                <NoDataAvailablePlaceholder
                    title={I18n.t('projectOverview')}
                    subTitle={I18n.t(
                        this.props.companyType === CompanyTypes.company ?
                            'noProjectsDashboardCompany' :
                            'noProjectsDashboardVideoProduction',
                    )}
                    buttonText={I18n.t('myProfile')}
                    buttonTo={companyProfileRoute}
                />
            </>
        );
    }

    renderProjectsForActiveTab = () => {
        const projectsForActiveTab = this.getProjectsForActiveTab();

        return projectsForActiveTab.map(this.renderContentItem);
    };

    renderContentItem = (project, index) => {
        return (
            <ProjectContentItem
                key={index}
                index={index}
                project={project}
            />
        );
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(DashboardActions, dispatch);

const mapStateToProps = state => (
    {
        activeTabIndex: state.dashboard.activeTabIndex,
        companyId:      state.company.ownCompany.id,
        companyType:    state.company.ownCompany.companyType,
        projects:       state.project.projects,
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);
