//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const MessageComposerTypes = {
    CLEAR_UNFINISHED_UPLOADS: 'MessageComposer/CLEAR_UNFINISHED_UPLOADS',
    DELETE_FILE:              'MessageComposer/DELETE_FILE',
    SET_MESSAGE:              'MessageComposer/SET_MESSAGE',
    UPDATE_UPLOAD_PROGRESS:   'MessageComposer/UPDATE_UPLOAD_PROGRESS',
    UPLOAD_FILE:              'MessageComposer/UPLOAD_FILE',
    UPLOAD_FILE_FAILED:       'MessageComposer/UPLOAD_FILE_FAILED',
    UPLOAD_FILE_SUCCEEDED:    'MessageComposer/UPLOAD_FILE_SUCCEEDED',
};

const clearUnfinishedUploads = makeActionCreator(
    MessageComposerTypes.CLEAR_UNFINISHED_UPLOADS,
);

const deleteFile = makeActionCreator(
    MessageComposerTypes.DELETE_FILE,
    {
        contextKey: null,
        id:         null,
    },
);

const setMessage = makeActionCreator(
    MessageComposerTypes.SET_MESSAGE,
    {
        contextKey: null,
        message:    null,
    },
);

const updateUploadProgress = makeActionCreator(
    MessageComposerTypes.UPDATE_UPLOAD_PROGRESS,
    {
        contextKey: null,
        id:         null,
        progress:   null,
    },
);

const uploadFile = makeActionCreator(
    MessageComposerTypes.UPLOAD_FILE,
    {
        contextKey: null,
        file:       null,
        id:         null,
    },
);

const uploadFileFailed = makeActionCreator(
    MessageComposerTypes.UPLOAD_FILE_FAILED,
    {
        contextKey: null,
        file:       null,
        id:         null,
    },
);

const uploadFileSucceeded = makeActionCreator(
    MessageComposerTypes.UPLOAD_FILE_SUCCEEDED,
    {
        contextKey: null,
        id:         null,
        iri:        null,
    },
);

export const MessageComposerActions = {
    clearUnfinishedUploads,
    deleteFile,
    setMessage,
    updateUploadProgress,
    uploadFile,
    uploadFileFailed,
    uploadFileSucceeded,
};