//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _      from 'lodash';
import I18n   from 'i18next';
import moment from 'moment';

class Normalize {
    static competencesToStringArray(competences) {
        return Normalize.toStringArray(competences, 'title');
    }

    static competencesToStringList(competences) {
        return Normalize.competencesToStringArray(competences);
    }

    static formatDateWithFallback(languageKey, date) {
        const momentDate = new moment(date);

        if (momentDate.isValid()) {
            return momentDate.format(I18n.t(languageKey));
        }

        return I18n.t('invalidDate');
    }

    static formatDate(languageKey, date) {
        const momentDate = new moment(date);

        if (momentDate.isValid()) {
            return momentDate.format(I18n.t(languageKey));
        }

        return null;
    }

    static formatsToStringArray(formats, fullName) {
        return Normalize.toSortedStringArray(formats, fullName ? 'name' : 'shortName');
    }

    static formatsToStringList(formats, fullName) {
        return Normalize.formatsToStringArray(formats, fullName);
    }

    static languagesToStringArray(languages) {
        return Normalize.toSortedStringArray(languages, 'name');
    }

    static languagesToStringList(languages) {
        return Normalize.languagesToStringArray(languages);
    }

    static locationsToStringArray(locations) {
        return Normalize.toSortedStringArray(locations, 'address.city');
    }

    static locationsToStringList(locations) {
        return Normalize.locationsToStringArray(locations);
    }

    static territoriesToStringArray(territories) {
        return Normalize.toSortedStringArray(territories, 'name');
    }

    static territoriesToStringList(territories) {
        return Normalize.territoriesToStringArray(territories);
    }

    static toStringArray(inputArray, keyToReduceTo) {
        const normalizedData = [];

        if (_.isArray(inputArray)) {
            for (const inputArrayEntry of inputArray) {
                const inputArrayEntryToReduceTo = _.get(inputArrayEntry, keyToReduceTo);

                if (inputArrayEntryToReduceTo) {
                    normalizedData.push(inputArrayEntryToReduceTo);
                }
            }
        }

        return normalizedData;
    }

    static toSortedStringArray(inputArray, keyToReduceTo) {
        const normalizedData       = Normalize.toStringArray(
            inputArray,
            keyToReduceTo,
        );
        const sortedNormalizedData = normalizedData.sort();

        return sortedNormalizedData;
    }

    static toThousandsValues(from, to) {
        const fromThousands    = from / 1000;
        const toThousands      = to / 1000;
        const showAsThousands  = fromThousands > 1 && toThousands > 1;
        const thousandsPostfix = showAsThousands && I18n.t('thousandsShort');
        const fromValue        = showAsThousands ? fromThousands + thousandsPostfix : from;
        const toValue          = showAsThousands ? toThousands + thousandsPostfix : to;

        return {
            from: fromValue,
            to:   toValue,
        };
    }

    static toAdvertisingBudgetRange(from, to) {
        const values        = Normalize.toThousandsValues(from, to);
        const fromThousands = values.from;
        const toThousands   = values.to;

        return Normalize.toRange(
            fromThousands,
            toThousands,
            I18n.t('euroShort'),
            I18n.t('advertisingBudgetRangeUndefined'),
        );
    }

    static toAgeRange(from, to) {
        return Normalize.toRange(
            from,
            to,
            I18n.t('years'),
            null,
        );
    }

    static toBudgetRange(from, to) {
        const values        = Normalize.toThousandsValues(from, to);
        const fromThousands = values.from;
        const toThousands   = values.to;

        return Normalize.toRange(
            fromThousands,
            toThousands,
            I18n.t('euroShort'),
            null,
        );
    }

    static toFormattedDateRage(formatLanguageKey, from, to) {
        const fromDate = Normalize.formatDateWithFallback(
            formatLanguageKey,
            from,
        );
        const toDate   = Normalize.formatDateWithFallback(
            formatLanguageKey,
            to,
        );

        return Normalize.toRange(
            fromDate,
            toDate,
        );
    }

    static toRange(from, to, unit, fallbackText) {
        const unitText = unit ? ' ' + unit : '';

        if (from && to) {
            return (
                from +
                I18n.t('rangeCombinerShort') +
                to +
                unitText
            );
        } else if (from) {
            return (
                I18n.t('rangeCombinerFrom') +
                ' ' +
                from +
                unitText
            );
        } else if (to) {
            return (
                I18n.t('rangeCombinerTo') +
                ' ' +
                to +
                unitText
            );
        }

        return fallbackText;
    }

    static toStringList(inputArray, keyToReduceTo) {
        const normalizedData = Normalize.toStringArray(
            inputArray,
            keyToReduceTo,
        );
        const stringList     = normalizedData.join(', ');

        return stringList;
    }

    static videoTypeStringFromProject(project) {
        const videoTypeString = _.get(
            project,
            'videoType.name',
            null,
        );

        return videoTypeString;
    }
}

export default Normalize;
