//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                      from 'lodash';
import CompanyForm            from '../../stateless/CompanyForm';
import I18n                   from 'i18next';
import Overlay                from '../Overlay';
import OverlayButton          from '../../stateless/OverlayButton';
import OverlayButtonTheme     from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper   from '../../stateless/OverlayButtonWrapper';
import OverlayPane            from '../../stateless/OverlayPane';
import OverlayPaneState       from '../../stateless/OverlayPane/OverlayPaneState';
import Overlays               from '../../../constants/Overlays';
import PropTypes              from '../../PropTypes';
import React                  from 'react';
import { AlertBoxActions }    from '../../../store/actions/alertBox';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { CompanyActions }     from '../../../store/actions/company';
import { connect }            from 'react-redux';
import { goBack }             from 'connected-react-router';
import { withRouter }         from 'react-router-dom';

class Component extends React.Component {
    cancelButtonPressed = () => {
        this.props.goBack();
    };

    nameChanged = (event) => {
        this.props.temporarySetOwnCompanyName({
            name: event.target.value,
        });
    };

    descriptionChanged = (event) => {
        this.props.temporarySetOwnCompanyDescription({
            description: event.target.value,
        });
    };

    websiteChanged = (event) => {
        this.props.temporarySetOwnCompanyWebsite({
            website: event.target.value,
        });
    };

    roleInCompanyChanged = (roleInCompany) => {
        this.props.temporarySetOwnCompanyRoleInCompany({
            roleInCompany: roleInCompany.value,
        });
    };

    phoneNumberChanged = (phoneNumber) => {
        this.props.temporarySetOwnCompanyPhoneNumberCompany({
            phoneNumber: phoneNumber.target.value,
        });
    };

    render() {
        return (
            <Overlay
                id={Overlays.editCompanyDescription}
                title={I18n.t('profileDetails')}
            >
                <OverlayPane
                    content={this.renderContent()}
                    footer={this.renderFooter()}
                    title={I18n.t('myCompany')}
                    showErrors={true}
                    state={OverlayPaneState.open}
                    scrollingAllowed={false}
                />
            </Overlay>
        );
    }

    renderContent = () => {
        return (
            <CompanyForm
                description={this.props.description}
                descriptionChanged={this.descriptionChanged}
                descriptionError={this.props.descriptionError}
                name={this.props.name}
                nameChanged={this.nameChanged}
                nameError={this.props.nameError}
                roleInCompany={this.props.roleInCompany}
                roleInCompanyChanged={this.roleInCompanyChanged}
                website={this.props.website}
                websiteChanged={this.websiteChanged}
                websiteError={this.props.websiteError}
                phoneNumber={this.props.phoneNumber}
                phoneNumberChanged={this.phoneNumberChanged}
            />
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.cancelButtonPressed}
                    standAlone={false}
                    text={I18n.t('cancel')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.saveButtonPressed}
                    standAlone={false}
                    text={I18n.t('save')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    saveButtonPressed = () => {
        this.props.clearAlerts();

        if (!this.props.description) {
            this.props.showErrorAlertTagged({
                tag:  'description',
                text: I18n.t('companyDescriptionErrorEmpty'),
            });
        } else if (!this.props.name) {
            this.props.showErrorAlertTagged({
                tag:  'name',
                text: I18n.t('companyNameErrorEmpty'),
            });
        } else {
            requestAnimationFrame(() => {
                if (this.props.alertBoxesTaggedCount === 0) {
                    this.props.updateCompany({
                        data: {
                            name:          this.props.name,
                            description:   this.props.description,
                            website:       this.props.website,
                            roleInCompany: this.props.roleInCompany,
                            contactData:   {
                                phoneNumber: this.props.phoneNumber,
                            },
                        },
                    });

                    this.props.goBack();
                }
            });
        }
    };
}

Component.propTypes = {
    alertBoxesTaggedCount: PropTypes.number,
    description:           PropTypes.string,
    descriptionError:      PropTypes.string,
    name:                  PropTypes.string,
    nameError:             PropTypes.string,
    phoneNumber:           PropTypes.string,
    roleInCompany:         PropTypes.string,
    website:               PropTypes.string,
    websiteError:          PropTypes.string,
};

Component.defaultProps = {
    alertBoxesTaggedCount: 0,
    description:           '',
    descriptionError:      null,
    name:                  '',
    nameError:             null,
    phoneNumber:           '',
    roleInCompany:         '',
    website:               '',
    websiteError:          null,
};

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        AlertBoxActions,
        CompanyActions,
        {
            goBack,
        },
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        alertBoxesTaggedCount: _.get(state, 'alertBox.alertBoxesTaggedCount', 0),
        description:           _.get(state, 'company.ownCompanyEditContext.description'),
        descriptionError:      _.get(state, 'alertBox.alertBoxesTagged.description', null),
        name:                  _.get(state, 'company.ownCompanyEditContext.name'),
        nameError:             _.get(state, 'alertBox.alertBoxesTagged.name', null),
        phoneNumber:           _.get(state, 'company.ownCompanyEditContext.contactData.phoneNumber'),
        roleInCompany:         _.get(state, 'company.ownCompanyEditContext.roleInCompany'),
        website:               _.get(state, 'company.ownCompanyEditContext.website'),
        websiteError:          _.get(state, 'alertBox.alertBoxesTagged.website', null),
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Component);
