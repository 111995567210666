//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import PrettyScrollBar from '../PrettyScrollBar';
import ComponentHelper from '../../../helper/ComponentHelper';

class SubSideBar extends React.Component {
    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.sidebar}>
                    <PrettyScrollBar>
                        <div className={styles.sidebarInner}>
                            {this.props.sideBarContent}
                        </div>
                    </PrettyScrollBar>
                </div>
                <div className={styles.mainContent}>
                    {this.props.mainContent}
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = SubSideBar;

Component.propTypes = {
    mainContent:    PropTypes.children,
    sideBarContent: PropTypes.children,
};

Component.defaultProps = {
    mainContent:    null,
    sideBarContent: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
