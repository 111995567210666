//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                      from 'react';
import _                          from 'lodash';
import ComponentHelper            from '../../../helper/ComponentHelper';
import DropDown                   from '../../stateless/DropDown';
import DropDownDirection          from '../../stateless/DropDown/DropDownDirection';
import PropTypes                  from '../../PropTypes';
import { bindActionCreators }     from 'redux';
import { CompanyPositionActions } from '../../../store/actions/companyPosition';
import { connect }                from 'react-redux';

class RoleInCompanyDropDown extends React.Component {
    getCompanyPositions = () => {
        return DropDown.mapHydraDataForValueField(
            this.props.companyPositions,
            'name',
        );
    };

    render() {
        return (
            <DropDown
                errorText={this.props.errorText}
                noMarginBottom={this.props.noMarginBottom}
                onClear={this.props.onClear}
                onChange={this.props.onChange}
                options={this.getCompanyPositions()}
                placeholder={this.props.placeholder}
                selectedValue={this.props.selectedValue}
                collapseDirection={this.props.collapseDirection}
                onRefreshDataClicked={this.props.fetchCompanyPositions}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = RoleInCompanyDropDown;

Component.propTypes = {
    collapseDirection:     PropTypes.oneOf(Object.values(DropDownDirection)),
    companyPositions:      PropTypes.array,
    errorText:             PropTypes.string,
    fetchCompanyPositions: PropTypes.func,
    noMarginBottom:        PropTypes.bool,
    onChange:              PropTypes.func,
    onClear:               PropTypes.func,
    placeholder:           PropTypes.string,
    selectedValue:         PropTypes.string,
};

Component.defaultProps = {
    collapseDirection:     DropDownDirection.down,
    companyPositions:      [],
    errorText:             '',
    fetchCompanyPositions: _.noop,
    noMarginBottom:        false,
    onChange:              _.noop,
    onClear:               _.noop,
    placeholder:           null,
    selectedValue:         null,
};

Component.renderAffectingProps = [
    'collapseDirection',
    'companyPositions',
    'errorText',
    'noMarginBottom',
    'placeholder',
    'selectedValue',
];

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        companyPositions: state.companyPosition.companyPositions,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        CompanyPositionActions,
    ),
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
