//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const decodeHtml = (html) => {
    const text     = document.createElement('textarea');
    text.innerHTML = html;

    return text.value;
};

const removeHTML = (text) => {
    return text.replace(/<[^>]*>/g, '');
};

export default {
    /**
     * @param text
     * @returns {*}
     */
    decodeHtml (text) {
        return decodeHtml(text);
    },

    /**
     * @param text
     * @returns {*}
     */
    removeHTML (text) {
        return removeHTML(text);
    },

    /**
     * @param text
     * @returns {*}
     */
    cleanup (text) {
        text = decodeHtml(text);
        text = removeHTML(text);

        return text;
    },
};
