//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import ComponentHelper        from '../../../helper/ComponentHelper';
import GA                     from '../../../helper/GA';
import Overlays               from '../../../constants/Overlays';
import OverlayStateless       from '../../stateless/Overlay';
import PropTypes              from '../../PropTypes';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { connect }            from 'react-redux';
import { goBack }             from 'connected-react-router';
import { OverlayActions }     from '../../../store/actions/overlay';
import { withRouter }         from 'react-router-dom';

class Component extends React.Component {
    closeButtonPressed = () => {
        GA.sendEvent({
            category: 'Overlay',
            action:   'Close',
        });

        if (!this.props.isCheckOverlay) {
            this.props.closeOverlay();
        } else {
            this.props.overlayClosed();
        }
    };

    render() {
        return (
            <OverlayStateless
                closeButtonPressed={this.closeButtonPressed}
                continueButtonPressed={this.props.continueButtonPressed}
                continueButtonTextKey={this.props.continueButtonTextKey}
                continueButtonValidator={this.props.continueButtonValidator}
                id={this.props.id}
                progress={this.props.progress}
                showProgressBar={this.props.showProgressBar}
                title={this.props.title}
            >
                {this.props.children}
            </OverlayStateless>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {
    children:                PropTypes.children,
    continueButtonPressed:   PropTypes.func,
    continueButtonTextKey:   PropTypes.string,
    continueButtonValidator: PropTypes.func,
    id:                      PropTypes.oneOf(Object.values(Overlays)),
    isCheckOverlay:          PropTypes.bool,
    progress:                PropTypes.number,
    showProgressBar:         PropTypes.bool,
    title:                   PropTypes.string,
};

Component.defaultProps = {
    children:                null,
    continueButtonPressed:   _.noop,
    continueButtonTextKey:   '',
    continueButtonValidator: _.noop,
    id:                      '',
    isCheckOverlay:          false,
    progress:                0,
    showProgressBar:         false,
    title:                   '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        OverlayActions,
        {
            goBack,
        },
    ),
    dispatch,
);

export default compose(
    connect(
        null,
        mapDispatchToProps,
    ),
    withRouter,
)(Component);
