//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                        from 'react';
import _                            from 'lodash';
import CompanyTypes                 from '../../../constants/CompanyTypes';
import I18n                         from 'i18next';
import Overlay                      from '../Overlay';
import OverlayPaneState             from '../../stateless/OverlayPane/OverlayPaneState';
import Overlays                     from '../../../constants/Overlays';
import PropTypes                    from '../../PropTypes';
import RegisterProjectPane          from '../../stateless/RegisterProjectPane';
import RegisterUserOverlayPane      from '../RegisterUserOverlayPane';
import Window                       from '../../../helper/Window';
import { AlertBoxActions }          from '../../../store/actions/alertBox';
import { bindActionCreators }       from 'redux';
import { compose }                  from 'redux';
import { CompanyActions }           from '../../../store/actions/company';
import { connect }                  from 'react-redux';
import { CreateEditProjectActions } from '../../../store/actions/createEditProject';
import { goBack }                   from 'connected-react-router';
import { withRouter }               from 'react-router-dom';

class Component extends React.Component {
    constructor(props) {
        super(props);

        this.props.temporarySetOwnCompanyType({
            companyType: CompanyTypes.company,
        });

        this.props.setMaxPaneIndex({
            index: 7,
        });
    }

    createCompanyUserAndProject = () => {
        this.props.createCompanyAndUserAndProject();
    };

    getOverlayPaneStateForIndex = (index) => {
        if (index < this.props.activePaneIndex) {
            return OverlayPaneState.done;
        } else if (index === this.props.activePaneIndex) {
            return OverlayPaneState.open;
        }

        return OverlayPaneState.upcoming;
    };

    render() {
        return (
            <Overlay
                id={Overlays.registerCompany}
                title={I18n.t('createUserProfileDetails')}
            >
                {this.renderPanes()}
            </Overlay>
        );
    }

    renderPanes = () => {
        if (!Window.isMobile()) {
            return (
                <>
                    <RegisterProjectPane
                        stateKeyData={this.getOverlayPaneStateForIndex(0)}
                        stateGoals={this.getOverlayPaneStateForIndex(1)}
                        stateReach={this.getOverlayPaneStateForIndex(2)}
                        stateBudget={this.getOverlayPaneStateForIndex(3)}
                        stateTimings={this.getOverlayPaneStateForIndex(4)}
                        stateAddition={this.getOverlayPaneStateForIndex(5)}
                    />
                    <RegisterUserOverlayPane
                        state={this.getOverlayPaneStateForIndex(6)}
                        onContinue={this.createCompanyUserAndProject}
                    />
                </>
            );
        }

        return (
            <>
                <RegisterProjectPane
                    stateKeyData={this.getOverlayPaneStateForIndex(0)}
                    stateGoals={this.getOverlayPaneStateForIndex(1)}
                    stateBudget={this.getOverlayPaneStateForIndex(2)}
                    stateAddition={this.getOverlayPaneStateForIndex(3)}
                />
                <RegisterUserOverlayPane
                    state={this.getOverlayPaneStateForIndex(4)}
                    onContinue={this.createCompanyUserAndProject}
                />
            </>
        );
    };
}

Component.propTypes = {
    activePaneIndex:             PropTypes.number,
    createCompanyUserAndProject: PropTypes.func,
};

Component.defaultProps = {
    activePaneIndex:             0,
    createCompanyUserAndProject: _.noop,
};

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        AlertBoxActions,
        CompanyActions,
        CreateEditProjectActions,
        {
            goBack,
        },
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        activePaneIndex: _.get(state, 'createEditProject.activePaneIndex', 0),
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Component);
