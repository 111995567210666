//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                from 'react';
import _                    from 'lodash';
import AlertBoxManager      from './../../connected/AlertBoxManager';
import AlertBoxType         from '../AlertBox/AlertBoxType';
import Cast                 from '../../../helper/Cast';
import classNames           from 'classnames';
import CloseButton          from '../CloseButton';
import ComponentHelper      from '../../../helper/ComponentHelper';
import I18n                 from 'i18next';
import OverlayPaneState     from './OverlayPaneState';
import PrettyScrollBar      from '../PrettyScrollBar';
import PropTypes            from '../../PropTypes';
import ReactTooltip         from 'react-tooltip';
import SelectionHelper      from '../../../helper/SelectionHelper';
import styles               from './styles.module.scss';
import withWindowDimensions from './../ResizeListener';

class OverlayPane extends React.Component {
    contentReference = React.createRef();
    wrapperReference = React.createRef();

    constructor (props) {
        super(props);

        this.state = {
            contentHeight:     null,
            scrollBarRequired: false,
            wrapperHeight:     null,
        };
    }

    componentDidMount () {
        if (this.contentReference.current) {
            this.setContentHeight();
        }
    }

    getDistanceStyle = () => {
        const opacity = 100 - (
            this.props.upcomingDistance * 15
        );
        const style   = {
            filter: 'opacity(' + Cast.percentString(opacity) + ')',
        };

        return style;
    };

    render () {
        const style = this.getDistanceStyle();

        if (this.state.wrapperHeight !== null) {
            style.height = Cast.pixelString(
                SelectionHelper.get(
                    this.props.state,
                    {
                        [OverlayPaneState.done]:     0,
                        [OverlayPaneState.open]:     this.state.wrapperHeight,
                        [OverlayPaneState.upcoming]: 106,
                    },
                ),
            );
        }

        return (
            <div
                className={classNames(
                    styles.wrapper,
                    {
                        [styles.wrapperInitialized]:   (
                                                           this.state.contentHeight !== null ||
                                                           !this.props.scrollingAllowed
                                                       ),
                        [styles.wrapperStateDone]:     this.props.state === OverlayPaneState.done,
                        [styles.wrapperStateOpen]:     this.props.state === OverlayPaneState.open,
                        [styles.wrapperStateUpcoming]: this.props.state === OverlayPaneState.upcoming,
                    },
                )}
                ref={this.wrapperReference}
                style={style}
            >
                {this.renderHeader()}
                {this.renderContent()}
                {this.renderCloseButton()}
            </div>
        );
    }

    renderCloseButton = () => {
        if (this.props.showCloseButton) {
            return (
                <CloseButton
                    onClick={this.props.closeButtonPressed}
                    title={I18n.t('removeOffer')}
                    right={20}
                    top={20}
                />
            );
        }

        return null;
    };

    renderContent = () => {
        const contentStyle       = {};
        const contentInnerStyle  = {};
        let contentPaddingBottom = 0;

        if (this.state.contentHeight !== null) {
            let contentHeight = this.state.contentHeight;

            contentInnerStyle.height = Cast.pixelString(
                this.props.state === OverlayPaneState.open ?
                    contentHeight :
                    0,
            );
        }

        if (
            this.props.state === OverlayPaneState.open &&
            this.props.heightToReduce !== null
        ) {
            contentPaddingBottom += this.props.heightToReduce;
        }

        contentStyle.paddingBottom = Cast.pixelString(contentPaddingBottom);

        const contentInner = this.props.content ? (
            <div className={styles.contentInner}>
                {this.props.content}
            </div>
        ) : null;

        return (
            <div
                className={styles.wrapperInner}
                ref={this.contentReference}
                style={contentInnerStyle}
            >
                <div
                    className={classNames(
                        styles.content,
                        {
                            [styles.contentNoFooter]: !this.props.footer,
                        },
                    )}
                    style={contentStyle}
                >
                    {
                        this.props.scrollingAllowed &&
                        this.state.contentHeight &&
                        // We add the scrollbar later since we need the whole content initial
                        // to calculate its "real" height
                        this.state.scrollBarRequired ?
                            (
                                <PrettyScrollBar>
                                    {contentInner}
                                </PrettyScrollBar>
                            ) :
                            contentInner
                    }
                </div>
                {this.renderFooter()}
            </div>
        );
    };

    renderDescription = () => {
        if (this.props.description) {
            return (
                <p
                    className={classNames(
                        styles.description,
                        {
                            [styles.largeDescription]: this.props.largeDescription,
                        },
                    )}
                >
                    {this.props.description}
                </p>
            );
        }

        return null;
    };

    renderAlertBoxManager = () => {
        if (
            this.state.contentHeight &&
            this.props.showErrors &&
            this.props.state === OverlayPaneState.open
        ) {
            return (
                <AlertBoxManager filterByType={AlertBoxType.error} />
            );
        }

        return null;
    };

    renderHeader = () => {
        if (
            this.props.title ||
            this.props.description
        ) {
            return (
                <div className={styles.header}>
                    {this.renderAlertBoxManager()}
                    {this.renderTitle()}
                    {this.renderDescription()}
                    <div className={styles.headerGradient} />
                </div>
            );
        }

        return null;
    };

    renderFooter = () => {
        if (this.props.footer) {
            return (
                <div className={styles.footer}>
                    <div
                        className={classNames(
                            styles.footerGradient,
                            {
                                [styles.noFooterGradient]: this.props.noFooterGradient,
                            },
                        )}
                    />
                    {this.props.footer}
                </div>
            );
        }

        return null;
    };

    renderTitle = () => {
        if (this.props.title) {
            // /‎ is a "hidden space"
            return (
                <h3 style={this.getDistanceStyle()}>
                    {this.props.title.replace('/', '/‎')}
                </h3>
            );
        }

        return null;
    };

    setContentHeight = () => {
        if (this.props.scrollingAllowed) {
            const timeout = (
                this.props.state === OverlayPaneState.open ?
                    0 :
                    500
            );

            // The timeout is required to get the right heights here
            window.setTimeout(this.setContentHeightCallback, timeout);
        }
    };

    setContentHeightCallback = () => {
        if (
            this.contentReference.current &&
            this.wrapperReference.current.clientHeight
        ) {
            const maximumWrapperHeight      = this.props.windowHeight * 0.66;
            let wrapperClientHeight         = Math.max(
                Math.min(
                    maximumWrapperHeight,
                    this.wrapperReference.current.clientHeight,
                ),
                600,
            );
            const wrapperClientHeightFactor = wrapperClientHeight / this.wrapperReference.current.clientHeight;
            const contentClientHeight       = (
                (
                    this.contentReference.current.clientHeight -
                    styles.contentPaddingBottom
                ) *
                wrapperClientHeightFactor
            );
            const scrollBarRequired         = maximumWrapperHeight < this.wrapperReference.current.clientHeight;

            requestAnimationFrame(ReactTooltip.rebuild);

            this.setState({
                contentHeight: contentClientHeight,
                scrollBarRequired,
                wrapperHeight: wrapperClientHeight,
            });
        }
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = OverlayPane;

Component.propTypes = {
    adjustedHeight:     PropTypes.number,
    closeButtonPressed: PropTypes.func,
    content:            PropTypes.children,
    description:        PropTypes.string,
    footer:             PropTypes.children,
    heightToReduce:     PropTypes.number,
    largeDescription:   PropTypes.bool,
    noFooterGradient:   PropTypes.bool,
    scrollingAllowed:   PropTypes.bool,
    showCloseButton:    PropTypes.bool,
    showErrors:         PropTypes.bool,
    state:              PropTypes.oneOf(Object.keys(OverlayPaneState)),
    title:              PropTypes.string,
    upcomingDistance:   PropTypes.number,
    windowHeight:       PropTypes.number,
    windowWidth:        PropTypes.number,
};

Component.defaultProps = {
    adjustedHeight:     0,
    closeButtonPressed: _.noop,
    content:            null,
    description:        null,
    footer:             null,
    heightToReduce:     null,
    largeDescription:   false,
    noFooterGradient:   false,
    scrollingAllowed:   true,
    showCloseButton:    false,
    showErrors:         false,
    state:              OverlayPaneState.open,
    title:              '',
    upcomingDistance:   0,
    windowHeight:       0,
    windowWidth:        0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'contentHeight',
    'scrollBarRequired',
    'wrapperHeight',
];

export default withWindowDimensions(Component);
