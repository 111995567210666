//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                from 'react';
import I18n                 from 'i18next';
import Overlay              from '../../connected/Overlay';
import PropTypes            from '../../PropTypes';
import OverlayPane          from '../OverlayPane';
import Overlays             from '../../../constants/Overlays';
import _                    from 'lodash';
import { withTranslation }  from 'react-i18next';
import Spacer               from '../Spacer';
import OverlayButton        from '../OverlayButton';
import OverlayButtonTheme   from '../OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper from '../OverlayButtonWrapper';

class Component extends React.Component {
    render () {
        return (
            <Overlay
                id={Overlays.deleteProject}
                title={I18n.t('deleteProject')}
            >
                <OverlayPane
                    content={this.renderContent()}
                    footer={this.renderFooter()}
                    description={I18n.t('deleteProjectOverlayDescription')}
                    scrollingAllowed={false}
                />
            </Overlay>
        );
    }

    renderContent = () => {
        return (
            // TODO: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-339
            // TODO: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-340
            <Spacer height={20} />
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.props.noButtonPressed}
                    standAlone={false}
                    text={I18n.t('no')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.props.yesButtonPressed}
                    standAlone={false}
                    text={I18n.t('yesLoud')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };
}

Component.propTypes = {
    noButtonPressed:  PropTypes.func,
    yesButtonPressed: PropTypes.func,
};

Component.defaultProps = {
    noButtonPressed:  _.noop,
    yesButtonPressed: _.noop,
};

export default withTranslation()(Component);