//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const BranchTypes = {
    FETCH_BRANCHES:         'Branch/FETCH_BRANCHES',
    FETCH_BRANCHES_FAILED:  'Branch/FETCH_BRANCHES_FAILED',
    FETCH_BRANCHES_SUCCESS: 'Branch/FETCH_BRANCHES_SUCCESS',
};

const fetchBranches = makeActionCreator(
    BranchTypes.FETCH_BRANCHES,
);

const fetchBranchesFailed = makeActionCreator(
    BranchTypes.FETCH_BRANCHES_FAILED,
);

const fetchBranchesSuccess = makeActionCreator(
    BranchTypes.FETCH_BRANCHES_SUCCESS,
    {
        branches: null,
    },
);

export const BranchActions = {
    fetchBranches,
    fetchBranchesFailed,
    fetchBranchesSuccess,
};
