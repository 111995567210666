//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                        from 'react';
import { connect }                  from 'react-redux';
import PropTypes                    from '../../PropTypes';
import _                            from 'lodash';
import { bindActionCreators }       from 'redux';
import { ActiveProjectActions }     from '../../../store/actions/activeProject';
import ProjectMenuItemStateless     from '../../stateless/ProjectMenuItem';
import ProjectSubMenuTypes          from '../../../constants/ProjectSubMenuTypes';
import ProjectStateTypes            from '../../../constants/ProjectStateTypes';
import ComponentHelper              from '../../../helper/ComponentHelper';
import Normalize                    from '../../../helper/Normalize';
import CompanyTypes                 from '../../../constants/CompanyTypes';
import { CreateEditProjectActions } from '../../../store/actions/createEditProject';
import { ProjectActions }           from '../../../store/actions/project';

class ProjectMenuItem extends React.Component {
    briefingButtonPressed = () => {
        this.setActiveProject(ProjectSubMenuTypes.briefing);
    };

    deleteProjectButtonPressed = () => {
        this.props.deleteProject({
            projectIri: this.props.project.iri,
        });
    };

    editProjectButtonPressed = () => {
        this.props.editProject({
            project: this.props.project,
        });
    };

    // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-333
    getUnreadMessageCount = () => {
        const ownCompanyIsCompany = this.ownCompanyIsCompany();
        const matches             = _.get(this, 'props.matches', []);
        let unreadMessageCount    = 0;

        for (const match of matches) {
            const matchUnreadMessageCount = (
                ownCompanyIsCompany ?
                    match.searchingCompanyUnreadMessageCount :
                    match.offeringCompanyUnreadMessageCount
            );

            unreadMessageCount += matchUnreadMessageCount;
        }

        return unreadMessageCount;
    };

    matchButtonPressed = () => {
        this.setActiveProject(ProjectSubMenuTypes.match);
    };

    matchesButtonPressed = () => {
        this.setActiveProject(ProjectSubMenuTypes.matches);
    };

    messagesButtonPressed = () => {
        this.setActiveProject(ProjectSubMenuTypes.messages);
    };

    offersButtonPressed = () => {
        this.setActiveProject(ProjectSubMenuTypes.offers);
    };

    ownCompanyIsCompany = () => {
        return this.props.companyType === CompanyTypes.company;
    };

    projectHeaderClicked = () => {
        // Just switch the project and keep the active sub menu
        this.setActiveProject(this.props.subMenuType);
    };

    projectIsActive = () => {
        return (
            this.props.activeProject &&
            this.props.project &&
            this.props.activeProject.iri === this.props.project.iri
        );
    };

    projectIsIncomplete = () => {
        return (
            this.props.project &&
            this.props.project.state === ProjectStateTypes.incomplete
        );
    };

    render() {
        const project = this.props.project;

        return (
            <ProjectMenuItemStateless
                activeProjectSubMenuType={this.props.subMenuType}
                ageRangeFrom={_.get(project, 'targetGroupAgeRange.from', null)}
                ageRangeTo={_.get(project, 'targetGroupAgeRange.to', null)}
                company={_.get(project, 'company', null)}
                briefingButtonPressed={this.briefingButtonPressed}
                budgetRangeInThousandsFrom={_.get(project, 'budgetRange.from', null)}
                budgetRangeInThousandsTo={_.get(project, 'budgetRange.to', null)}
                companyType={this.props.companyType}
                deleteProjectButtonPressed={this.deleteProjectButtonPressed}
                editProjectButtonPressed={this.editProjectButtonPressed}
                formats={_.get(project, 'videoFormats', [])}
                isActive={this.projectIsActive()}
                isIncomplete={this.projectIsIncomplete()}
                matchButtonPressed={this.matchButtonPressed}
                matchesButtonPressed={this.matchesButtonPressed}
                messagesButtonPressed={this.messagesButtonPressed}
                messageCount={this.getUnreadMessageCount()}
                offersButtonPressed={this.offersButtonPressed}
                onHeaderClicked={this.projectHeaderClicked}
                type={Normalize.videoTypeStringFromProject(project)}
                title={project.title}
                projectState={_.get(project, 'state')}
            />
        );
    }

    setActiveProject = (subMenuType) => {
        if (
            this.props.activeProject === this.props.project &&
            subMenuType === this.props.subMenuType
        ) {
            this.props.setActiveProject({
                project: null,
            });
        } else {
            this.props.setActiveProject({
                project: this.props.project,
                subMenuType,
            });
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ProjectMenuItem;

Component.propTypes = {
    activeProject: PropTypes.object,
    companyType:   PropTypes.oneOf(Object.values(CompanyTypes)),
    project:       PropTypes.object,
    subMenuType:   PropTypes.oneOf(Object.keys(ProjectSubMenuTypes)),
};

Component.defaultProps = {
    activeProject: null,
    companyType:   null,
    project:       null,
    subMenuType:   null,
};

Component.renderAffectingProps = [
    'activeProject',
    'companyType',
    'project',
    'subMenuType',
];

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        ActiveProjectActions,
        CreateEditProjectActions,
        ProjectActions,
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        activeProject: _.get(state, 'activeProject.activeProject'),
        matches:       _.get(state, 'activeProject.matches'),
        companyType:   _.get(state, 'company.ownCompany.companyType'),
        subMenuType:   _.get(state, 'activeProject.subMenuType'),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
