//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import FileListEntry   from '../FileListEntry';
import Icon            from '../Icon';
import IconButton      from '../IconButton';
import IconType        from '../Icon/IconType';
import ComponentHelper from '../../../helper/ComponentHelper';
import IconButtonTheme from '../IconButton/IconButtonTheme';
import I18n            from 'i18next';
import _               from 'lodash';

class DownloadableFileListEntry extends React.Component {
    downloadButtonPressed = () => {
        this.props.downloadButtonPressed(this.props.data);
    };

    getData = () => {
        if (this.props.small) {
            this.props.data.additionalText = null;
        }

        return this.props.data;
    };

    render () {
        return (
            <FileListEntry
                data={this.getData()}
                showPreview={true}
            >
                {this.renderButton()}
            </FileListEntry>
        );
    }

    renderButton = () => {
        if (this.props.small) {
            return this.renderSmallButton();
        }

        return this.renderBigButton();
    };

    renderBigButton = () => {
        return (
            <IconButton
                iconType={IconType.download}
                onClick={this.downloadButtonPressed}
                text={I18n.t('downloadFile')}
                theme={IconButtonTheme.turquoiseGhostSmall}
            />
        );
    };

    renderSmallButton = () => {
        return (
            <div
                className={styles.smallDownloadButton}
                onClick={this.downloadButtonPressed}
            >
                <Icon iconType={IconType.download} />
            </div>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = DownloadableFileListEntry;

Component.propTypes = {
    data:                  PropTypes.fileListEntry,
    downloadButtonPressed: PropTypes.func,
    small:                 PropTypes.bool,
};

Component.defaultProps = {
    data:                  null,
    downloadButtonPressed: _.noop,
    small:                 false,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;