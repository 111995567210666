//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import FileStates      from '../../../constants/FileStates';
import Cast            from '../../../helper/Cast';
import ComponentHelper from '../../../helper/ComponentHelper';
import _               from 'lodash';
import Icon            from '../Icon';
import IconType        from '../Icon/IconType';
import classNames      from 'classnames';

class FileButton extends React.Component {
    render () {
        return (
            <div
                className={classNames(
                    styles.wrapper,
                    {
                        [styles.wrapperUploading]: this.props.state === FileStates.uploading,
                    },
                )}
            >
                <div
                    className={classNames(
                        styles.iconWrapper,
                        {
                            [styles.iconWrapperError]:     this.props.state === FileStates.error,
                            [styles.iconWrapperUploaded]:  this.props.state === FileStates.uploaded,
                            [styles.iconWrapperUploading]: this.props.state === FileStates.uploading,
                        },
                    )}
                >
                    <Icon iconType={IconType.fileText} />
                </div>
                <strong>
                    {this.props.fileName}
                </strong>
                <div
                    className={styles.deleteOverlay}
                    onClick={this.props.onDeleteButtonClicked}
                >
                    <div className={styles.deleteIconWrapper}>
                        <div className={styles.deleteIconCircle}>
                            <Icon iconType={IconType.trash} />
                        </div>
                    </div>
                    <strong>
                        {this.props.fileName}
                    </strong>
                </div>
                {this.renderProgressBar()}
            </div>
        );
    }

    renderProgressBar = () => {
        if (this.props.state === FileStates.uploading) {
            return (
                <div className={styles.progressBarWrapper}>
                    <div
                        className={styles.progressBar}
                        style={{
                            width: Cast.percentString(this.props.progress),
                        }}
                    />
                </div>
            );
        }

        return null;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = FileButton;

Component.propTypes = {
    fileName:              PropTypes.string,
    onDeleteButtonClicked: PropTypes.func,
    progress:              PropTypes.number,
    state:                 PropTypes.oneOf(Object.keys(FileStates)),
};

Component.defaultProps = {
    fileName:              '',
    onDeleteButtonClicked: _.noop,
    progress:              null,
    state:                 null,
};

Component.renderAffectingProps = [
    'fileName',
    'progress',
    'state',
];

Component.renderAffectingStates = [];

export default Component;