//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import FileListEntry   from '../FileListEntry';
import Icon            from '../Icon';
import IconType        from '../Icon/IconType';
import ComponentHelper from '../../../helper/ComponentHelper';
import _               from 'lodash';

class DeleteableFileListEntry extends React.Component {
    render () {
        return (
            <FileListEntry
                data={this.props.data}
                showPreview={true}
            >
                <div
                    className={styles.deleteButtonWrapper}
                    onClick={this.props.deleteButtonPressed}
                >
                    <Icon iconType={IconType.x} />
                </div>
            </FileListEntry>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = DeleteableFileListEntry;

Component.propTypes = {
    data:                PropTypes.fileListEntry,
    deleteButtonPressed: PropTypes.func,
};

Component.defaultProps = {
    data:                null,
    deleteButtonPressed: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;