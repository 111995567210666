//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper from '../../../helper/ComponentHelper';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';
import { withRouter }  from 'react-router';
import Cast            from '../../../helper/Cast';

class ShortFactList extends React.Component {
    getFactStyle = () => {
        const style = {
            flexBasis: Cast.percentString(100 / this.props.itemsPerRow),
        };

        return style;
    };

    render () {
        return (
            <ul className={styles.shortFactList}>
                {this.props.children.map(this.renderFact)}
            </ul>
        );
    }

    renderFact = (fact, index) => {
        const factStyle = this.getFactStyle();

        return (
            <li
                key={'fact_' + fact}
                style={factStyle}
            >
                {fact}
            </li>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ShortFactList;

Component.propTypes = {
    children:    PropTypes.children,
    itemsPerRow: PropTypes.number,
};

Component.defaultProps = {
    children:    null,
    itemsPerRow: 2,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default withRouter(Component);
