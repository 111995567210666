//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import classNames      from 'classnames';
import Icon            from '../Icon';
import IconButtonTheme from './IconButtonTheme';
import IconType        from '../Icon/IconType';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';
import Link            from '../Link';
import ComponentHelper from '../../../helper/ComponentHelper';

class IconButton extends React.Component {
    getButtonClass = () => {
        const buttonClass = classNames(
            styles.button,
            {
                [styles.buttonDisabled]:                         this.props.disabled,
                [styles.buttonGray]:                             (
                                                                     this.props.theme === IconButtonTheme.gray ||
                                                                     this.props.theme === IconButtonTheme.grayWithGreenIcon
                                                                 ),
                [styles.buttonGrayGhost]:                        this.props.theme === IconButtonTheme.grayGhost,
                [styles.buttonGrayWithGreenIcon]:                this.props.theme === IconButtonTheme.grayWithGreenIcon,
                [styles.buttonTurquoiseGhost]:                   (
                                                                     this.props.theme === IconButtonTheme.turquoiseGhost ||
                                                                     this.props.theme === IconButtonTheme.turquoiseGhostWithDarkBackground
                                                                 ),
                [styles.buttonTurquoiseGhostSmall]:              this.props.theme === IconButtonTheme.turquoiseGhostSmall,
                [styles.buttonTurquoiseGhostWithDarkBackground]: this.props.theme === IconButtonTheme.turquoiseGhostWithDarkBackground,
                [styles.buttonWithWhiteSpaces]:                  this.props.allowLineBreaks,
            },
        );

        return buttonClass;
    };

    getTextClass = () => {
        const textClass = classNames(
            styles.text,
            {
                [styles.textDisabled]:            this.props.disabled,
                [styles.textGray]:                (
                                                      this.props.theme === IconButtonTheme.gray ||
                                                      this.props.theme === IconButtonTheme.grayWithGreenIcon
                                                  ),
                [styles.textGrayGhost]:           this.props.theme === IconButtonTheme.grayGhost,
                [styles.textTurquoiseGhost]:      this.props.theme === IconButtonTheme.turquoiseGhost,
                [styles.textTurquoiseGhostSmall]: this.props.theme === IconButtonTheme.turquoiseGhostSmall,
                [styles.textWithWhiteSpaces]:     this.props.allowLineBreaks,
            },
        );

        return textClass;
    };

    onClick = () => {
        if (!this.props.disabled) {
            this.props.onClick();
        }
    };

    render() {
        const buttonClass = this.getButtonClass();

        if (this.props.external) {
            return (
                <a
                    className={buttonClass}
                    href={this.props.to}
                    target={'_blank'}
                >
                    {this.renderInner()}
                </a>
            );
        }

        if (this.props.onClick) {
            return (
                <div
                    className={buttonClass}
                    onClick={this.props.onClick}
                >
                    {this.renderInner()}
                </div>
            );
        }

        if (this.props.to) {
            return (
                <Link
                    className={buttonClass}
                    to={this.props.to}
                    disabled={this.props.disabled}
                >
                    {this.renderInner()}
                </Link>
            );
        }

        if (this.props.disabled) {
            return (
                <Link
                    className={buttonClass}
                    to={''}
                >
                    {this.renderInner()}
                </Link>
            );
        }

        return null;
    }

    renderIcon = () => {
        if (this.props.iconType) {
            return (
                <span className={styles.icon}>
                    <Icon iconType={this.props.iconType} />
                </span>
            );
        }

        return null;
    };

    renderInner = () => {
        const textClass = this.getTextClass();
        const icon      = this.renderIcon();

        return (
            <>
                {icon}
                <span className={textClass}>
                    {this.props.text}
                </span>
                {this.props.overlay}
            </>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = IconButton;

Component.propTypes = {
    allowLineBreaks: PropTypes.bool,
    disabled:        PropTypes.bool,
    external:        PropTypes.bool,
    iconType:        PropTypes.oneOf(Object.values(IconType)),
    onClick:         PropTypes.func,
    overlay:         PropTypes.children,
    text:            PropTypes.string,
    theme:           PropTypes.oneOf(Object.values(IconButtonTheme)),
    to:              PropTypes.string,
};

Component.defaultProps = {
    allowLineBreaks: false,
    disabled:        false,
    external:        false,
    iconType:        null,
    onClick:         null,
    overlay:         null,
    text:            '',
    theme:           null,
    to:              null,
};

Component.renderAffectingProps = [
    'allowLineBreaks',
    'disabled',
    'external',
    'iconType',
    'overlay',
    'text',
    'theme',
    'to',
];

Component.renderAffectingStates = [];

export default Component;
