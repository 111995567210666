//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                                      from 'lodash';
import CompanyProfileHeaderTitle              from '../CompanyProfileHeaderTitle';
import CompanyProfileHeaderTitleTextAlignment from '../CompanyProfileHeaderTitle/CompanyProfileHeaderTitleTextAlignment';
import ComponentHelper                        from '../../../helper/ComponentHelper';
import I18n                                   from 'i18next';
import PropTypes                              from '../../PropTypes';
import React                                  from 'react';
import Link                                   from '../../stateless/Link';
import styles                                 from './styles.module.scss';
import TextRow                                from '../TextRow';

class CompanyProfileInformation extends React.Component {
    render () {
        const company     = _.get(this.props, 'company');
        const website     = this.renderWebsite(company);
        const description = this.renderDescription(company);

        return (
            <div className={styles.wrapper}>
                <div className={styles.wrapperInner}>
                    <CompanyProfileHeaderTitle
                        editButtonPressed={this.props.editButtonPressed}
                        showEditButton={this.props.showEditButton}
                        textAlignment={CompanyProfileHeaderTitleTextAlignment.left}
                        title={I18n.t(
                            'profileAboutHeadline',
                            {
                                companyName: company.name,
                            },
                        )}
                    />
                    {website}
                    {description}
                </div>
            </div>
        );
    }
    
    renderDescription = (company) => {
        if (company.description) {
            return (
                <TextRow
                    label={I18n.t('description')}
                    text={company.description}
                />
            );
        }

        return null;
    };

    renderWebsite = (company) => {
        const websiteLink = this.renderWebsiteLink(company);

        if (websiteLink) {
            return (
                <TextRow
                    label={I18n.t('website')}
                >
                    {websiteLink}
                </TextRow>
            );
        }

        return null;
    };

    renderWebsiteLink = (company) => {
        if (company.website) {
            return (
                <Link
                    to={{ pathname: company.website }}
                    target={'_blank'}
                >
                    {company.website}
                </Link>
            );
        }

        return null;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CompanyProfileInformation;

Component.propTypes = {
    company:           PropTypes.object,
    companyPositions:  PropTypes.array,
    editButtonPressed: PropTypes.func,
    showEditButton:    PropTypes.bool,
};

Component.defaultProps = {
    company:           {},
    companyPositions:  [],
    editButtonPressed: _.noop,
    showEditButton:    false,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;