//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React              from 'react';
import styles             from './styles.module.scss';
import PropTypes          from '../../PropTypes';
import ComponentHelper    from '../../../helper/ComponentHelper';
import _                  from 'lodash';
import IconType           from '../Icon/IconType';
import CallToActionBox    from '../CallToActionBox';
import ChoiceSelector     from '../ChoiceSelector';
import ChoiceWidthMode    from '../ChoiceSelector/ChoiceWidthMode';
import Spacer             from '../Spacer';
import Input              from '../Input';
import InputType          from '../Input/InputType';
import I18n               from 'i18next';
import AvailabilityTypes  from '../../../constants/AvailabilityTypes';
import HiddenSubmitButton from '../HiddenSubmitButton';

class CallbackRequestBox extends React.Component {
    getButtonText = () => {
        if (this.props.buttonText) {
            return this.props.buttonText;
        }

        return I18n.t('callbackRequestButtonText');
    };

    getText = () => {
        if (this.props.text) {
            return this.props.text;
        }

        return I18n.t('callbackRequestText');
    };

    getTitle = () => {
        if (this.props.title) {
            return this.props.title;
        }

        return I18n.t('callbackRequestTitle');
    };

    onFormSubmit = (event) => {
        event.preventDefault();

        this.props.onClick();
    };

    render() {
        return (
            <CallToActionBox
                additionalContent={this.renderAdditionalContent()}
                buttonText={this.getButtonText()}
                iconType={this.props.iconType}
                onClick={this.props.onClick}
                sidePadding={this.props.sidePadding}
                text={this.getText()}
                title={this.getTitle()}
            />
        );
    }

    renderAdditionalContent = () => {
        return (
            <div className={styles.additionalContentWrapper}>
                <form onSubmit={this.onFormSubmit}>
                    <Input
                        errorText={this.props.phoneNumberError}
                        placeholder={I18n.t('callbackRequestPhoneNumberPlaceholder')}
                        onChange={this.props.phoneNumberChanged}
                        type={InputType.tel}
                        value={this.props.phoneNumber}
                    />
                    <Input
                        errorText={this.props.eMailAddressError}
                        placeholder={I18n.t('callbackRequestEMailAddressPlaceholder')}
                        onChange={this.props.eMailAddressChanged}
                        type={InputType.email}
                        value={this.props.eMailAddress}
                    />
                    <HiddenSubmitButton />
                </form>
                <h4>
                    {I18n.t('callbackRequestAvailabilityQuestion')}
                </h4>
                <ChoiceSelector
                    choices={[
                        {
                            selected:  this.props.availabilityType === AvailabilityTypes.morning,
                            text:      I18n.t('callbackRequestAvailabilityMorning'),
                            value:     AvailabilityTypes.morning,
                            widthMode: ChoiceWidthMode.half,
                        },
                        {
                            selected:  this.props.availabilityType === AvailabilityTypes.noon,
                            text:      I18n.t('callbackRequestAvailabilityNoon'),
                            value:     AvailabilityTypes.noon,
                            widthMode: ChoiceWidthMode.half,
                        },
                    ]}
                    choiceClicked={this.props.availabilityTypeChoiceClicked}
                    errorText={this.props.availabilityTypeError}
                />
                <Spacer height={20} />
            </div>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CallbackRequestBox;

Component.propTypes = {
    availabilityType:              PropTypes.oneOf(Object.keys(AvailabilityTypes)),
    availabilityTypeChoiceClicked: PropTypes.func,
    availabilityTypeError:         PropTypes.string,
    buttonText:                    PropTypes.string,
    eMailAddress:                  PropTypes.string,
    eMailAddressChanged:           PropTypes.func,
    eMailAddressError:             PropTypes.string,
    iconType:                      PropTypes.oneOf(Object.keys(IconType)),
    onClick:                       PropTypes.func,
    phoneNumber:                   PropTypes.string,
    phoneNumberChanged:            PropTypes.func,
    phoneNumberError:              PropTypes.string,
    sidePadding:                   PropTypes.number,
    text:                          PropTypes.string,
    title:                         PropTypes.string,
};

Component.defaultProps = {
    availabilityType:              null,
    availabilityTypeChoiceClicked: _.noop,
    availabilityTypeError:         null,
    buttonText:                    '',
    eMailAddress:                  '',
    eMailAddressChanged:           _.noop,
    eMailAddressError:             null,
    iconType:                      null,
    onClick:                       _.noop,
    phoneNumber:                   '',
    phoneNumberChanged:            _.noop,
    phoneNumberError:              null,
    sidePadding:                   0,
    text:                          '',
    title:                         '',
};

Component.renderAffectingProps = [
    'availabilityType',
    'availabilityTypeError',
    'buttonText',
    'eMailAddress',
    'eMailAddressError',
    'iconType',
    'phoneNumber',
    'phoneNumberError',
    'sidePadding',
    'text',
    'title',
];

Component.renderAffectingStates = [];

export default Component;
