//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import { connect }     from 'react-redux';
import PropTypes       from '../../PropTypes';
import _               from 'lodash';
import HeaderStateless from '../../stateless/Header';
import ComponentHelper from '../../../helper/ComponentHelper';

class Header extends React.Component {
    render() {
        const company = _.get(this.props, 'company', {});
        const user    = _.get(this.props, 'user', {});

        return (
            <HeaderStateless
                company={company}
                user={user}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Header;

Component.propTypes = {
    company: PropTypes.object,
    user:    PropTypes.object,
};

Component.defaultProps = {
    company: null,
    user:    null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        company: state.company.ownCompany,
        user:    state.user.user,
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);
