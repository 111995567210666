//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as ReactGA from 'react-ga';

const CREATE_PROJECT_CATEGORY = 'CreateProjectOverlay';
const NEXT                    = 'Next';
const PREVIOUS                = 'Previous';

export default class GA {
    static sendEvent(category, action, label) {
        ReactGA.event({
            category,
            action,
            label,
        });
    }

    static sendCreateProjectEvent(action, label) {
        this.sendEvent(
            CREATE_PROJECT_CATEGORY,
            action,
            label,
        );
    }

    static sendCreateProjectNextEvent(label) {
        this.sendCreateProjectEvent(
            NEXT,
            label,
        );
    }

    static sendCreateProjectPreviousEvent(label) {
        this.sendCreateProjectEvent(
            PREVIOUS,
            label,
        );
    }
}

