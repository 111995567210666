//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const imageFileExtensions = [
    'gif',
    'jpg',
    'jpeg',
    'png',
    'webp',
];

class File {
    static pathIsImage = (path) => {
        for (const imageFileExtension of imageFileExtensions) {
            const imageFileExtensionLowerCase = imageFileExtension.toLowerCase();

            if (path.indexOf(imageFileExtensionLowerCase) > -1) {
                return true;
            }
        }

        return false;
    };
}

export default File;