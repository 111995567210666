//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import classNames      from 'classnames';
import ComponentHelper from '../../../helper/ComponentHelper';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';

class Spacer extends React.Component {
    getStyle = () => {
        const style = {};

        if (this.props.display) {
            style.display = this.props.display;
        }

        if (this.props.height) {
            style.height = this.props.height;
        }

        if (this.props.width) {
            style.width = this.props.width;
        }

        return style;
    };

    render () {
        const style = this.getStyle();

        return (
            <span
                className={classNames(
                    styles.spacer,
                    {
                        [styles.visible]: this.props.text,
                        [styles.lines]:   this.props.lines,
                    },
                )}
                style={style}
            >
                {this.props.text}
            </span>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Spacer;

Component.propTypes = {
    display: PropTypes.string,
    height:  PropTypes.number,
    lines:   PropTypes.bool,
    text:    PropTypes.string,
    width:   PropTypes.number,
};

Component.defaultProps = {
    display: '',
    height:  0,
    lines:   false,
    text:    null,
    width:   0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;