//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import styles           from './styles.module.scss';
import PropTypes        from '../../PropTypes';
import ComponentHelper  from '../../../helper/ComponentHelper';
import _                from 'lodash';
import Input            from '../Input';
import InputSize        from '../Input/InputSize';
import FileButton       from '../FileButton';
import UploadFileButton from '../UploadFileButton';
import IconButton       from '../IconButton';
import IconButtonTheme  from '../IconButton/IconButtonTheme';
import Routes           from '../../../constants/Routes';
import I18n             from 'i18next';
import IconType         from '../Icon/IconType';

class MessageComposer extends React.Component {
    render() {
        return (
            <div className={styles.wrapper}>
                {this.props.additionalHeader}
                <div className={styles.textInputWrapper}>
                    <Input
                        size={InputSize.multiLine}
                        noMarginBottom={true}
                        onChange={this.props.onMessageChange}
                        value={this.props.message}
                    />
                    {this.renderFiles()}
                </div>
                {this.renderButtons()}
            </div>
        );
    }

    renderAddOfferButton = () => {
        if (this.props.showOfferButton) {
            return (
                <IconButton
                    onClick={this.props.addOfferButtonPressed}
                    iconType={IconType.fileText}
                    text={this.props.showOfferButtonText}
                    theme={IconButtonTheme.grayGhost}
                    to={Routes.screenDesign}
                />
            );
        }

        return null;
    };

    renderButtons = () => {
        return (
            <div className={styles.buttonWrapper}>
                {this.renderAddOfferButton()}
                <UploadFileButton
                    text={I18n.t('fileAddButton')}
                    theme={IconButtonTheme.grayGhost}
                    uploadFileCallback={this.props.uploadFileCallback}
                />
                <IconButton
                    disabled={this.props.submitDisabled}
                    onClick={this.props.submitButtonPressed}
                    text={I18n.t('messageSubmit')}
                    theme={IconButtonTheme.turquoiseGhost}
                    to={Routes.screenDesign}
                />
            </div>
        );
    };

    // TODO: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-302
    renderFile = (file, index) => {
        return (
            <FileButton
                fileName={file.name}
                key={'file' + file.id}
                onDeleteButtonClicked={() => {
                    this.props.fileDeletionButtonPressed(file);
                }}
                progress={file.progress}
                state={file.state}
            />
        );
    };

    renderFiles = () => {
        if (this.props.files && this.props.files.length > 0) {
            return (
                <div className={styles.fileListWrapper}>
                    {this.props.files.map(this.renderFile)}
                </div>
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = MessageComposer;

Component.propTypes = {
    additionalHeader:          PropTypes.children,
    addOfferButtonPressed:     PropTypes.func,
    fileDeletionButtonPressed: PropTypes.func,
    files:                     PropTypes.arrayOf(PropTypes.uploadableFile),
    message:                   PropTypes.string,
    onMessageChange:           PropTypes.func,
    showOfferButton:           PropTypes.bool,
    showOfferButtonText:       PropTypes.string,
    submitButtonPressed:       PropTypes.func,
    submitDisabled:            PropTypes.bool,
    uploadFileCallback:        PropTypes.func,
};

Component.defaultProps = {
    additionalHeader:          null,
    addOfferButtonPressed:     _.noop,
    fileDeletionButtonPressed: _.noop,
    files:                     [],
    message:                   '',
    onMessageChange:           _.noop,
    showOfferButton:           false,
    showOfferButtonText:       '',
    submitButtonPressed:       _.noop,
    submitDisabled:            false,
    uploadFileCallback:        _.noop,
};

Component.renderAffectingProps = [
    'additionalHeader',
    'message',
    'files',
    'showOfferButton',
    'showOfferButtonText',
    'submitDisabled',
];

Component.renderAffectingStates = [];

export default Component;
