//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                from 'react';
import styles               from './styles.module.scss';
import PropTypes            from '../../PropTypes';
import Icon                 from '../Icon';
import IconType             from '../Icon/IconType';
import ComponentHelper      from '../../../helper/ComponentHelper';
import DotListMenuAlignment from './DotListMenuAlignment';
import DotListMenuTheme     from './DotListMenuTheme';
import classNames           from 'classnames';

class DotListMenu extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            clicked: false,
            open:    false,
        };
    }

    buttonClicked = () => {
        this.setState({
            clicked: true,
        });
    };

    mouseLeft = () => {
        this.setState({
            clicked: false,
        });
    };

    render () {
        return (
            <div
                className={classNames(
                    styles.wrapper,
                    {
                        [styles.showMenuOnClick]:   this.state.clicked,
                        [styles.showMenuOnHover]:   !this.props.requireClickToOpen,
                        [styles.wrapperThemeBig]:   this.props.theme === DotListMenuTheme.big,
                        [styles.wrapperThemeSmall]: this.props.theme === DotListMenuTheme.small,
                    },
                )}
                onMouseLeave={this.mouseLeft}
            >
                <button
                    className={classNames(
                        styles.button,
                        {
                            [styles.buttonThemeBig]:   this.props.theme === DotListMenuTheme.big,
                            [styles.buttonThemeSmall]: this.props.theme === DotListMenuTheme.small,
                        },
                    )}
                    onClick={this.buttonClicked}
                >
                    {this.renderIcon()}
                </button>
                <div
                    className={classNames(
                        styles.menu,
                        {
                            [styles.menuAlignmentLeft]:  this.props.alignment === DotListMenuAlignment.left,
                            [styles.menuAlignmentRight]: this.props.alignment === DotListMenuAlignment.right,
                            [styles.menuThemeBig]:       this.props.theme === DotListMenuTheme.big,
                            [styles.menuThemeSmall]:     this.props.theme === DotListMenuTheme.small,
                        },
                    )}
                >
                    <ul>
                        {this.props.children}
                    </ul>
                </div>
            </div>
        );
    }

    renderIcon () {
        if (this.props.theme === DotListMenuTheme.small) {
            return (
                <Icon iconType={IconType.dotListVertical} />
            );
        }

        return (
            <Icon iconType={IconType.dotListHorizontal} />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = DotListMenu;

Component.propTypes = {
    alignment:          PropTypes.oneOf(Object.values(DotListMenuAlignment)),
    children:           PropTypes.children,
    requireClickToOpen: PropTypes.bool,
    theme:              PropTypes.oneOf(Object.values(DotListMenuTheme)),
};

Component.defaultProps = {
    alignment:          DotListMenuAlignment.right,
    children:           null,
    requireClickToOpen: false,
    theme:              DotListMenuTheme.small,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'clicked',
    'open',
];

export default Component;