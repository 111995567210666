//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                      from 'lodash';
import FormRow                from '../../stateless/FormRow';
import I18n                   from 'i18next';
import Input                  from '../../stateless/Input';
import InputType              from '../../stateless/Input/InputType';
import Overlay                from '../Overlay';
import OverlayButton          from '../../stateless/OverlayButton';
import OverlayButtonTheme     from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper   from '../../stateless/OverlayButtonWrapper';
import OverlayPane            from '../../stateless/OverlayPane';
import OverlayPaneState       from '../../stateless/OverlayPane/OverlayPaneState';
import Overlays               from '../../../constants/Overlays';
import PropTypes              from '../../PropTypes';
import React                  from 'react';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { connect }            from 'react-redux';
import { goBack }             from 'connected-react-router';
import { UserActions }        from '../../../store/actions/user';
import { withRouter }         from 'react-router-dom';
import { AlertBoxActions }    from '../../../store/actions/alertBox';

class Component extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            oldPassword:   null,
            password:      null,
            passwordCheck: null,
        };
    }

    cancelButtonPressed = () => {
        this.props.goBack();
    };

    oldPasswordChanged = (event) => {
        this.setState({
            oldPassword: event.target.value,
        });
    };

    passwordChanged = (event) => {
        this.setState({
            password: event.target.value,
        });
    };

    passwordCheckChanged = (event) => {
        this.setState({
            passwordCheck: event.target.value,
        });
    };

    render () {
        return (
            <Overlay
                id={Overlays.editUserPassword}
                title={I18n.t('userProfilePassword')}
            >
                <OverlayPane
                    content={this.renderContent()}
                    footer={this.renderFooter()}
                    title={I18n.t('userPassword')}
                    showErrors={true}
                    state={OverlayPaneState.open}
                    scrollingAllowed={false}
                />
            </Overlay>
        );
    }

    renderContent = () => {
        const dataObject   = Object.keys(this.props.userData);
        const data         = dataObject.map((label) => (
            {
                label,
                value: this.props.userData[label].value,
                group: this.props.userData[label].group,
            }
        ));
        const filteredData = _.filter(data, { group: 'password' });

        return filteredData.map(this.renderRow);
    };

    renderRow = (data, index) => {
        return (
            <>
                <FormRow label={I18n.t('oldPassword')}>
                    <Input
                        errorText={this.props.passwordError3}
                        placeholder={I18n.t('passwordPlaceholder')}
                        type={InputType.password}
                        onChange={this.oldPasswordChanged}
                    />
                </FormRow>
                <FormRow label={I18n.t('password')}>
                    <Input
                        errorText={this.props.passwordError1}
                        placeholder={I18n.t('passwordPlaceholder')}
                        type={InputType.password}
                        onChange={this.passwordChanged}
                    />
                </FormRow>
                <FormRow label={I18n.t('passwordCheck')}>
                    <Input
                        errorText={this.props.passwordError2}
                        placeholder={I18n.t('passwordPlaceholder')}
                        type={InputType.password}
                        onChange={this.passwordCheckChanged}
                    />
                </FormRow>
            </>
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.cancelButtonPressed}
                    standAlone={false}
                    text={I18n.t('cancel')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.saveButtonPressed}
                    standAlone={false}
                    text={I18n.t('save')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    saveButtonPressed = () => {
        this.props.clearAlerts();

        if (!this.state.password) {
            this.props.showErrorAlertTagged({
                tag:  'password1',
                text: I18n.t('profilePasswordError1'),
            });
        } else if (this.state.password.length < 8) {
            this.props.showErrorAlertTagged({
                tag:  'password1',
                text: I18n.t('profilePasswordError2'),
            });
        } else if (this.state.password !== this.state.passwordCheck) {
            this.props.showErrorAlertTagged({
                tag:  'password2',
                text: I18n.t('profilePasswordError3'),
            });
        } else {
            this.props.temporarySetOwnProfilePassword({
                password: this.state.password,
            });

            requestAnimationFrame(() => {
                this.props.updateUserPassword({
                    oldPassword: this.state.oldPassword,
                    newPassword: this.state.password,
                });

                this.props.goBack();
            });
        }
    };
}

Component.propTypes = {
    passwordError:  PropTypes.string,
    userData:       PropTypes.object,
    passwordError1: PropTypes.string,
    passwordError2: PropTypes.string,
    passwordError3: PropTypes.string,
};

Component.defaultProps = {
    passwordError:  '',
    userData:       {},
    passwordError1: '',
    passwordError2: '',
    passwordError3: '',
};

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        AlertBoxActions,
        UserActions,
        {
            goBack,
        },
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        passwordError1: _.get(state, 'alertBox.alertBoxesTagged.password1', null),
        passwordError2: _.get(state, 'alertBox.alertBoxesTagged.password2', null),
        passwordError3: _.get(state, 'alertBox.alertBoxesTagged.password3', null),
        userData:       _.get(state, 'user.ownUserEditContext', {}),
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Component);