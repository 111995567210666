//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }               from 'redux-saga/effects';
import { call }              from 'redux-saga/effects';
import * as Api              from '../../api';
import HydraHelper           from '../../helper/Hydra';
import { CompetenceActions } from '../actions/competence';
import { push }              from 'connected-react-router';
import Routes                from '../../constants/Routes';

const fetchCompetences = function* () {
    const response = yield call(
        Api.fetchCompetences,
    );

    if (response.ok) {
        const cleanHydraResponse = HydraHelper.cleanupObject(response.data);
        const competences        = cleanHydraResponse.member;

        yield put(CompetenceActions.fetchCompetencesSuccess({
            competences,
        }));
    } else {
        yield put(CompetenceActions.fetchCompetencesFailed());
    }
};

export default {
    fetchCompetences,
};
