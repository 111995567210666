//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import IconButtonTheme from '../IconButton/IconButtonTheme';
import IconType        from '../Icon/IconType';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';
import ComponentHelper from '../../../helper/ComponentHelper';
import IconButton      from '../IconButton';
import Routes          from '../../../constants/Routes';
import _               from 'lodash';

class UploadFileButton extends React.Component {
    uploadInputReference = null;

    constructor (props) {
        super(props);

        this.state = {
            onFileChangedEventCount: 0,
        };
    }

    increaseOnFileChangedEventCount = () => {
        const nextOnFileChangedEventCount = this.state.onFileChangedEventCount + 1;

        this.setState({
            onFileChangedEventCount: nextOnFileChangedEventCount,
        });
    };

    onAddFileButtonPressed = () => {
        // We do nothing here since the button is
        // overlayed by the input
    };

    onFileChanged = (event) => {
        const files = _.get(event, 'target.files', []);

        for (const file of files) {
            this.props.uploadFileCallback(file);
        }

        this.increaseOnFileChangedEventCount();

        if (this.uploadInputReference) {
            this.uploadInputReference.value = null;
        }

        event.preventDefault();
    };

    render () {
        return (
            <div className={styles.wrapper}>
                <IconButton
                    allowLineBreaks={this.props.allowLineBreaks}
                    iconType={IconType.save}
                    onClick={this.onAddFileButtonPressed}
                    overlay={this.renderFileUploadOverlay()}
                    text={this.props.text}
                    theme={this.props.theme}
                    to={Routes.screenDesign}
                />
            </div>
        );
    }

    renderFileUploadOverlay = () => {
        const key = 'uploadFile' + this.state.onFileChangedEventCount;

        return (
            <form>
                <input
                    className={styles.fileUploadOverlay}
                    key={key}
                    name={key}
                    multiple={true}
                    onChange={this.onFileChanged}
                    ref={this.setUploadInputReference}
                    type={'file'}
                />
            </form>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    setUploadInputReference = (uploadInputReference) => {
        this.uploadInputReference = uploadInputReference;
    };
}

const Component = UploadFileButton;

Component.propTypes = {
    allowLineBreaks:    PropTypes.bool,
    text:               PropTypes.string,
    theme:              PropTypes.oneOf(Object.keys(IconButtonTheme)),
    uploadFileCallback: PropTypes.func,
};

Component.defaultProps = {
    allowLineBreaks:    false,
    text:               '',
    theme:              '',
    uploadFileCallback: _.noop,
};

Component.renderAffectingProps = [
    'allowLineBreaks',
    'text',
    'theme',
];

Component.renderAffectingStates = [
    'onFileChangedEventCount',
];

export default Component;