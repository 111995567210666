//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                         from 'lodash';
import CompanyProfileHeaderTitle from '../CompanyProfileHeaderTitle';
import CompanyProfileHeaderTitleTextAlignment
                                 from '../CompanyProfileHeaderTitle/CompanyProfileHeaderTitleTextAlignment';
import ComponentHelper           from '../../../helper/ComponentHelper';
import I18n                      from 'i18next';
import PropTypes                 from '../../PropTypes';
import React                     from 'react';
import styles                    from './styles.module.scss';
import TextRow                   from '../TextRow';

class UserProfileData extends React.Component {
    render () {
        const dataObject   = Object.keys(this.props.data);
        const data         = dataObject.map((label) => (
            {
                label,
                value: this.props.data[label].value,
                group: this.props.data[label].group,
            }
        ));
        const filteredData = _.filter(data, { group: this.props.group });

        return (
            <div className={styles.wrapper}>
                <div className={styles.wrapperInner}>
                    <CompanyProfileHeaderTitle
                        editButtonPressed={this.props.editButtonPressed}
                        showEditButton={this.props.showEditButton}
                        textAlignment={CompanyProfileHeaderTitleTextAlignment.left}
                        title={this.props.title ? this.props.title : I18n.t('editProfile')}
                    />
                    {filteredData.map(this.renderRow)}
                </div>
            </div>
        );
    }

    renderRow = (data, index) => {
        const label = data.label;

        return (
            <TextRow
                key={index}
                label={I18n.t(label)}
                text={data.value}
                isPassword={label === 'password'}
            />
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = UserProfileData;

Component.propTypes = {
    data:              PropTypes.object,
    editButtonPressed: PropTypes.func,
    group:             PropTypes.string,
    showEditButton:    PropTypes.bool,
    title:             PropTypes.string,
};

Component.defaultProps = {
    data:              {},
    editButtonPressed: _.noop,
    group:             'default',
    showEditButton:    false,
    title:             null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;