//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const MainSplitPaneTypes = {
    LEFT_PANE_SET_VISIBILITY:    'MainSplitPane/LEFT_PANE_SET_VISIBILITY',
    LEFT_PANE_SIZE_CHANGED:      'MainSplitPane/LEFT_PANE_SIZE_CHANGED',
    LEFT_PANE_TOGGLE_VISIBILITY: 'MainSplitPane/LEFT_PANE_TOGGLE_VISIBILITY',
};

const leftPaneSizeChanged = makeActionCreator(
    MainSplitPaneTypes.LEFT_PANE_SIZE_CHANGED,
    {
        size: null,
    },
);

const leftPaneToggleVisibility = makeActionCreator(
    MainSplitPaneTypes.LEFT_PANE_TOGGLE_VISIBILITY,
);

const setLeftPaneSetVisibility = makeActionCreator(
    MainSplitPaneTypes.LEFT_PANE_SET_VISIBILITY,
    {
        visible: null,
    },
);

export const MainSplitPaneActions = {
    leftPaneSizeChanged,
    leftPaneToggleVisibility,
    setLeftPaneSetVisibility,
};
