//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                 from 'react';
import classNames            from 'classnames';
import ComponentHelper       from '../../../helper/ComponentHelper';
import IconAlertOctagon      from '../../../assets/icons/alert-octagon.svg';
import IconCamera            from '../../../assets/icons/camera.svg';
import IconCheck             from '../../../assets/icons/check.svg';
import IconCheckCircle       from '../../../assets/icons/check-circle.svg';
import IconChevronDown       from '../../../assets/icons/chevron-down.svg';
import IconCode              from '../../../assets/icons/code.svg';
import IconDotListHorizontal from '../../../assets/icons/dot-list-horizontal-2.svg';
import IconDotListVertical   from '../../../assets/icons/dot-list-vertical-2.svg';
import IconDownload          from '../../../assets/icons/download.svg';
import IconEdit              from '../../../assets/icons/edit.svg';
import IconEuroSign          from '../../../assets/icons/euro-sign.svg';
import IconEye               from '../../../assets/icons/eye.svg';
import IconFacebook          from '../../../assets/icons/facebook.svg';
import IconFile              from '../../../assets/icons/file.svg';
import IconFileText          from '../../../assets/icons/file-text.svg';
import IconFrown             from '../../../assets/icons/frown.svg';
import IconGlobe             from '../../../assets/icons/globe.svg';
import IconHome              from '../../../assets/icons/home.svg';
import IconInfoCircle        from '../../../assets/icons/info-circle.svg';
import IconInstagram         from '../../../assets/icons/instagram.svg';
import IconMail              from '../../../assets/icons/mail.svg';
import IconMapPin            from '../../../assets/icons/map-pin.svg';
import IconMessageSquare     from '../../../assets/icons/message-square.svg';
import IconMovie             from '../../../assets/icons/movie.svg';
import IconPhone             from '../../../assets/icons/phone.svg';
import IconPlus              from '../../../assets/icons/plus.svg';
import IconPlusCircle        from '../../../assets/icons/plus-circle.svg';
import IconRefresh           from '../../../assets/icons/refresh.svg';
import IconSave              from '../../../assets/icons/save.svg';
import IconTrash             from '../../../assets/icons/trash.svg';
import IconType              from './IconType';
import IconUser              from '../../../assets/icons/user.svg';
import IconUsers             from '../../../assets/icons/users.svg';
import IconX                 from '../../../assets/icons/x.svg';
import IconXCircle           from '../../../assets/icons/x-circle.svg';
import IconYouTube           from '../../../assets/icons/youtube.svg';
import IconZap               from '../../../assets/icons/zap.svg';
import PropTypes             from '../../PropTypes';
import SelectionHelper       from '../../../helper/SelectionHelper';
import styles                from './styles.module.scss';

class Icon extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render() {
        return (
            <span
                className={classNames(
                    styles.icon,
                    {
                        [styles.iconError]: this.props.iconType === IconType.refresh,
                    },
                )}
                key={this.props.iconType}
            >
                {this.renderSvg()}
            </span>
        );
    }

    renderSvg = () => {
        return SelectionHelper.get(
            this.props.iconType,
            {
                [IconType.alertOctagon]:      <IconAlertOctagon />,
                [IconType.camera]:            <IconCamera />,
                [IconType.check]:             <IconCheck />,
                [IconType.checkCircle]:       <IconCheckCircle />,
                [IconType.chevronDown]:       <IconChevronDown />,
                [IconType.code]:              <IconCode />,
                [IconType.dotListHorizontal]: <IconDotListHorizontal />,
                [IconType.dotListVertical]:   <IconDotListVertical />,
                [IconType.download]:          <IconDownload />,
                [IconType.edit]:              <IconEdit />,
                [IconType.eye]:               <IconEye />,
                [IconType.euroSign]:          <IconEuroSign />,
                [IconType.facebook]:          <IconFacebook />,
                [IconType.file]:              <IconFile />,
                [IconType.fileText]:          <IconFileText />,
                [IconType.frown]:             <IconFrown />,
                [IconType.globe]:             <IconGlobe />,
                [IconType.home]:              <IconHome />,
                [IconType.infoCircle]:        <IconInfoCircle />,
                [IconType.instagram]:         <IconInstagram />,
                [IconType.mail]:              <IconMail />,
                [IconType.mapPin]:            <IconMapPin />,
                [IconType.phone]:             <IconPhone />,
                [IconType.plus]:              <IconPlus />,
                [IconType.plusCircle]:        <IconPlusCircle />,
                [IconType.refresh]:           <IconRefresh />,
                [IconType.messageSquare]:     <IconMessageSquare />,
                [IconType.movie]:             <IconMovie />,
                [IconType.save]:              <IconSave />,
                [IconType.trash]:             <IconTrash />,
                [IconType.user]:              <IconUser />,
                [IconType.users]:             <IconUsers />,
                [IconType.x]:                 <IconX />,
                [IconType.xCircle]:           <IconXCircle />,
                [IconType.youtube]:           <IconYouTube />,
                [IconType.zap]:               <IconZap />,
            },
        );
    };
}

const Component = Icon;

Component.propTypes = {
    iconType: PropTypes.oneOf(Object.keys(IconType)),
};

Component.defaultProps = {
    iconType: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
