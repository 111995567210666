//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                       from 'lodash';
import ComponentHelper         from '../../../helper/ComponentHelper';
import FormRow                 from '../../stateless/FormRow';
import I18n                    from 'i18next';
import Input                   from '../../stateless/Input';
import InputType               from '../../stateless/Input/InputType';
import Overlay                 from '../Overlay';
import OverlayButton           from '../../stateless/OverlayButton';
import OverlayButtonTheme      from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper    from '../../stateless/OverlayButtonWrapper';
import OverlayPane             from '../../stateless/OverlayPane';
import OverlayPaneState        from '../../stateless/OverlayPane/OverlayPaneState';
import Overlays                from '../../../constants/Overlays';
import PropTypes               from '../../PropTypes';
import React                   from 'react';
import { bindActionCreators }  from 'redux';
import { compose }             from 'redux';
import { CompanyActions }      from '../../../store/actions/company';
import { connect }             from 'react-redux';
import { goBack }              from 'connected-react-router';
import { withRouter }          from 'react-router-dom';
import CallToActionOverlayPane from '../../stateless/CallToActionOverlayPane';
import IconType                from '../../stateless/Icon/IconType';
import { AlertBoxActions }     from '../../../store/actions/alertBox';

class Component extends React.Component {
    addLocationPressed = () => {
        this.props.temporaryAddOwnCompanyLocation();
    };

    componentDidMount() {
        if (!this.props.locations.length) {
            this.props.temporaryAddOwnCompanyLocation();
        }
    }

    cancelButtonPressed = () => {
        this.props.goBack();
    };

    changeLocationAddressField = (event, index, field) => {
        const value    = event.target.value;
        const address  = this.getLocationAddressByIndex(index);
        address[field] = value;

        this.props.temporarySetOwnCompanyLocation({
            index,
            address,
        });
    };

    deleteLocationPressed = (index) => {
        this.props.temporaryRemoveOwnCompanyLocation({
            index,
        });
    };

    getLocationAddressByIndex = (index) => {
        return _.cloneDeep(
            _.get(
                this.props,
                [
                    'locations',
                    index,
                    'address',
                ],
            ),
        );
    };

    locationStreetChanged = (index) => {
        return (event) => {
            this.changeLocationAddressField(event, index, 'street');
        };
    };

    locationHouseNumberChanged = (index) => {
        return (event) => {
            this.changeLocationAddressField(event, index, 'houseNumber');
        };
    };

    locationPostalCodeChanged = (index) => {
        return (event) => {
            this.changeLocationAddressField(event, index, 'postalCode');
        };
    };

    locationCityChanged = (index) => {
        return (event) => {
            this.changeLocationAddressField(event, index, 'city');
        };
    };

    render() {
        return (
            <Overlay
                id={Overlays.editCompanyLocations}
                title={I18n.t('companyLocations')}
            >
                {this.renderContent()}
                {this.renderCallToActionOverlayPane()}
                {this.renderFooter()}
            </Overlay>
        );
    }

    renderCallToActionOverlayPane = () => {
        return (
            <CallToActionOverlayPane
                buttonIcon={IconType.plusCircle}
                buttonText={I18n.t('newAddress')}
                onClick={this.addLocationPressed}
            />
        );
    };

    renderContent = () => {
        return this.props.locations.map(this.renderLocations);
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.cancelButtonPressed}
                    standAlone={true}
                    multiple={true}
                    text={I18n.t('cancel')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.saveButtonPressed}
                    standAlone={true}
                    multiple={true}
                    text={I18n.t('save')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    renderLocationFormFields = (address, index) => {
        return (
            <>
                <FormRow label={I18n.t('street')}>
                    <Input
                        placeholder={I18n.t('street')}
                        type={InputType.text}
                        value={address.street}
                        minLength={1}
                        onChange={this.locationStreetChanged(index)}
                        noMarginBottom={true}
                        errorText={!address.street ? this.props.emptyStreetError : null}
                    />
                </FormRow>
                <FormRow label={I18n.t('houseNumber')}>
                    <Input
                        placeholder={I18n.t('houseNumber')}
                        type={InputType.number}
                        value={address.houseNumber}
                        minLength={1}
                        onChange={this.locationHouseNumberChanged(index)}
                        noMarginBottom={true}
                        errorText={!address.houseNumber ? this.props.emptyHouseNumberError : null}
                    />
                </FormRow>
                <FormRow label={I18n.t('postalCode')}>
                    <Input
                        placeholder={I18n.t('postalCode')}
                        type={InputType.text}
                        value={address.postalCode}
                        minLength={1}
                        onChange={this.locationPostalCodeChanged(index)}
                        noMarginBottom={true}
                        errorText={!address.postalCode ? this.props.emptyPostalCodeError : null}
                    />
                </FormRow>
                <FormRow label={I18n.t('city')}>
                    <Input
                        placeholder={I18n.t('city')}
                        type={InputType.text}
                        value={address.city}
                        minLength={1}
                        onChange={this.locationCityChanged(index)}
                        noMarginBottom={true}
                        errorText={!address.city ? this.props.emptyCityError : null}
                    />
                </FormRow>
            </>
        );
    };

    renderLocations = (location, index) => {
        const address = _.get(location, 'address');

        return (
            <OverlayPane
                key={index}
                content={this.renderLocationFormFields(address, index)}
                title={I18n.t('locationCount', { location: address.city })}
                state={OverlayPaneState.open}
                showErrors={true}
                showCloseButton={true}
                scrollingAllowed={false}
                closeButtonPressed={() => this.deleteLocationPressed(index)}
            />
        );
    };

    saveButtonPressed = () => {
        this.props.clearAlerts();

        this.props.locations.forEach((location, index) => {
            const address = location.address;

            if (!address.street) {
                this.props.showErrorAlertTagged({
                    tag:  'emptyStreetError',
                    text: I18n.t('emptyStreetError'),
                });
            }

            if (!address.houseNumber) {
                this.props.showErrorAlertTagged({
                    tag:  'emptyHouseNumberError',
                    text: I18n.t('emptyHouseNumberError'),
                });
            }

            if (!address.postalCode) {
                this.props.showErrorAlertTagged({
                    tag:  'emptyPostalCodeError',
                    text: I18n.t('emptyPostalCodeError'),
                });
            }

            if (!address.city) {
                this.props.showErrorAlertTagged({
                    tag:  'emptyCityError',
                    text: I18n.t('emptyCityError'),
                });
            }
        });

        requestAnimationFrame(() => {
            if (this.props.alertBoxesTaggedCount === 0) {
                this.props.createOrUpdateOwnCompanyLocations({
                    locations: this.props.locations,
                });

                this.props.goBack();
            }
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {
    alertBoxesTaggedCount: PropTypes.number,
    company:               PropTypes.object,
    emptyCityError:        PropTypes.string,
    emptyHouseNumberError: PropTypes.string,
    emptyPostalCodeError:  PropTypes.string,
    emptyStreetError:      PropTypes.string,
    locations:             PropTypes.array,
};

Component.defaultProps = {
    alertBoxesTaggedCount: 0,
    company:               {},
    emptyCityError:        null,
    emptyHouseNumberError: null,
    emptyPostalCodeError:  null,
    emptyStreetError:      null,
    locations:             [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        AlertBoxActions,
        CompanyActions,
        {
            goBack,
        },
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        alertBoxesTaggedCount: _.get(state, 'alertBox.alertBoxesTaggedCount', 0),
        company:               _.get(state, 'company.ownCompanyEditContext', {}),
        emptyCityError:        _.get(state, 'alertBox.alertBoxesTagged.emptyCityError'),
        emptyHouseNumberError: _.get(state, 'alertBox.alertBoxesTagged.emptyHouseNumberError'),
        emptyPostalCodeError:  _.get(state, 'alertBox.alertBoxesTagged.emptyPostalCodeError'),
        emptyStreetError:      _.get(state, 'alertBox.alertBoxesTagged.emptyStreetError'),
        locations:             _.get(state, 'company.ownCompanyEditContext.companyLocations', []),
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Component);
