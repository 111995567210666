//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                        from 'react';
import _                            from 'lodash';
import RegisterOverlayStateless     from '../../stateless/RegisterOverlay';
import { bindActionCreators }       from 'redux';
import { connect }                  from 'react-redux';
import { CreateEditProjectActions } from '../../../store/actions/createEditProject';
import { OverlayActions }           from '../../../store/actions/overlay';
import { UserActions }              from '../../../store/actions/user';

class RegisterOverlay extends React.Component {
    registerCompanyClicked = () => {
        this.props.resetPane();
        this.props.registerCompany();
    };

    registerVideoProductionClicked = () => {
        this.props.resetPane();
        this.props.registerVideoProduction();
    };

    render() {
        return (
            <RegisterOverlayStateless
                registerCompanyClicked={this.registerCompanyClicked}
                registerVideoProductionClicked={this.registerVideoProductionClicked}
            />
        );
    }
}

const Component = RegisterOverlay;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        CreateEditProjectActions,
        OverlayActions,
        UserActions,
    ),
    dispatch,
);

const mapStateToProps = state => (
    {}
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
