//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                     from 'react';
import styles                    from './styles.module.scss';
import PropTypes                 from '../../PropTypes';
import DeleteableFileListEntry   from '../DeleteableFileListEntry';
import DownloadableFileListEntry from '../DownloadableFileListEntry';
import FileListEntry             from '../FileListEntry';
import classNames                from 'classnames';
import ComponentHelper           from '../../../helper/ComponentHelper';
import Url                       from '../../../helper/Url';
import FileListMode              from './FileListMode';
import _                         from 'lodash';

class FileList extends React.Component {
    downloadButtonPressed = (fileData) => {
        Url.openInNewTab(fileData.downloadUrl);
    };

    render () {
        return (
            <div
                className={classNames(
                    styles.wrapper,
                    {
                        [styles.wrapperSmall]: this.props.mode === FileListMode.downloadSmall,
                    },
                )}
            >
                {this.renderTitle()}
                <div className={styles.filesWrapper}>
                    {this.props.files.map(this.renderFile)}
                </div>
                {this.renderFooter()}
            </div>
        );
    }

    renderAdditionalText = (fileData) => {
        if (fileData.additionalText) {
            return (
                <div className={styles.fileCenter}>
                    {fileData.additionalText}
                </div>
            );
        }

        return null;
    };

    renderFile = (fileData, index) => {
        switch (this.props.mode) {
            case FileListMode.download:
            case FileListMode.downloadSmall:
                return (
                    <DownloadableFileListEntry
                        data={fileData}
                        downloadButtonPressed={this.downloadButtonPressed}
                        key={'downloadableFileListEntry' + index}
                        small={this.props.mode === FileListMode.downloadSmall}
                    />
                );

            case FileListMode.manage:
                return (
                    <DeleteableFileListEntry
                        data={fileData}
                        deleteButtonPressed={this.props.deleteButtonPressed}
                        key={'deletableFileListEntry' + index}
                    />
                );
        }

        return (
            <FileListEntry
                data={fileData}
                key={'fileListEntry' + index}
                showPreview={true}
            />
        );
    };

    renderFooter = () => {
        if (this.props.footerContent) {
            return (
                <div className={styles.footer}>
                    <div className={styles.footerContent}>
                        {this.props.footerContent}
                    </div>
                </div>
            );
        }

        return null;
    };

    renderTitle = () => {
        if (this.props.title) {
            return (
                <h4>
                    {this.props.title}
                </h4>
            );
        }

        return null;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = FileList;

Component.propTypes = {
    deleteButtonPressed: PropTypes.func,
    files:               PropTypes.arrayOf(PropTypes.fileListEntry),
    footerContent:       PropTypes.object,
    mode:                PropTypes.oneOf(Object.keys(FileListMode)),
    title:               PropTypes.string,
};

Component.defaultProps = {
    deleteButtonPressed: _.noop,
    files:               [],
    footerContent:       null,
    mode:                FileListMode.default,
    title:               '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;