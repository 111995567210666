//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    accepted:      'MATCH_ACCEPTED',
    declined:      'MATCH_DECLINED',
    interested:    'MATCH_INTERESTED',
    notInterested: 'MATCH_NOT_INTERESTED',
    offered:       'MATCH_OFFERED',
    proposed:      'MATCH_PROPOSED',
};