//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React               from 'react';
import _                   from 'lodash';
import Cast                from '../../../helper/Cast';
import CloseButton         from '../CloseButton';
import I18n                from 'i18next';
import Overlays            from '../../../constants/Overlays';
import PrettyScrollBar     from '../PrettyScrollBar';
import PropTypes           from '../../PropTypes';
import styles              from './styles.module.scss';
import { compose }         from 'redux';
import { connect }         from 'react-redux';
import { goBack }          from 'connected-react-router';
import { Modal }           from 'react-overlays';
import { withRouter }      from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class Component extends React.Component {
    closeButtonPressed = () => {
        this.props.closeButtonPressed();
    };

    constructor (props) {
        super(props);

        this.state = {
            mouseIsDown: false,
        };
    }

    handleContainerMouseDown = () => {
        this.setState({
            mouseIsDown: true,
        });
    };

    handleContainerMouseUp = () => {
        if (this.state.mouseIsDown) {
            this.closeButtonPressed();
        }

        this.setState({
            mouseIsDown: false,
        });
    };

    handleInnerMouseUpDown = (event) => {
        // Prevent the mouse down event to get passed to the overlay
        // to avoid it to get accidentally closed when the user clicks (mouse down) in the input field
        // and releases the mouse key (mouse up) outside of this field.
        // @see handleContainerMouseDown
        // @see handleContainerMouseUp
        event.stopPropagation();

        return false;
    };

    modalContainerClicked = (event) => {
        // Prevent the onClick from modalWrapper to get fired when a child is clicked
        event.stopPropagation();
    };

    okButtonPressed = () => {
        if (
            this.props.continueButtonValidator === _.noop ||
            this.props.continueButtonValidator()
        ) {
            this.props.okButtonPressed();
        }
    };

    render () {
        return (
            <Modal
                onHide={this.close}
                show={true}
                renderDialog={this.renderDialog}
            />
        );
    }

    renderContent = () => {
        return (
            <div className={styles.contentWrapper}>
                {this.props.children}
            </div>
        );
    };

    renderDialog = (dialogProps) => {
        return (
            <div
                {...dialogProps}
                role={null}
                tabIndex={null}
            >
                {this.renderProgressBar()}
                <div
                    className={styles.modalWrapper}
                    onMouseDown={this.handleContainerMouseDown}
                    onMouseUp={this.handleContainerMouseUp}
                >
                    <PrettyScrollBar>
                        <div className={styles.modalWrapperInner}>
                            <div
                                className={styles.modalContainer}
                                onClick={this.modalContainerClicked}
                                onMouseDown={this.handleInnerMouseUpDown}
                                onMouseUp={this.handleInnerMouseUpDown}
                            >
                                {this.renderHeader()}
                                {this.renderContent()}
                            </div>
                        </div>
                    </PrettyScrollBar>
                </div>
            </div>
        );
    };

    renderHeader = () => {
        return (
            <div className={styles.modalHeader}>
                <h2>
                    {this.props.title}
                </h2>
                {this.renderHeaderCloseButton()}
            </div>
        );
    };

    renderHeaderCloseButton = () => {
        return (
            <CloseButton
                onClick={this.closeButtonPressed}
                right={0}
                title={I18n.t('closeOverlay')}
                top={0}
            />
        );
    };

    renderProgressBar = () => {
        if (this.props.showProgressBar) {
            const progressBarStyle = {
                width: Cast.percentString(this.props.progress),
            };

            return (
                <div className={styles.progressBarWrapper}>
                    <div className={styles.progressBarWrapperInner}>
                        <div className={styles.progressBarWrapperInnerLeft}>
                            <div className={styles.progressBar}>
                                <div
                                    className={styles.progressBarProgress}
                                    style={progressBarStyle}
                                />
                            </div>
                        </div>
                        <div className={styles.progressBarWrapperInnerRight}>
                            {this.props.progress.toFixed(0)}
                            {I18n.t('percentShort')}
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    };
}

Component.propTypes = {
    children:                PropTypes.children,
    closeButtonPressed:      PropTypes.func,
    continueButtonPressed:   PropTypes.func,
    continueButtonTextKey:   PropTypes.string,
    continueButtonValidator: PropTypes.func,
    id:                      PropTypes.oneOf(Object.values(Overlays)),
    progress:                PropTypes.number,
    showProgressBar:         PropTypes.bool,
    title:                   PropTypes.string,
};

Component.defaultProps = {
    children:                null,
    closeButtonPressed:      _.noop,
    continueButtonPressed:   _.noop,
    continueButtonTextKey:   '',
    continueButtonValidator: _.noop,
    id:                      '',
    progress:                0,
    showProgressBar:         false,
    title:                   '',
};

export default compose(
    connect(
        null,
        {
            goBack,
        },
    ),
    withRouter,
)(withTranslation()(Component));