//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const VideoFormatTypes = {
    FETCH_VIDEO_FORMATS:         'VideoFormat/FETCH_VIDEO_FORMATS',
    FETCH_VIDEO_FORMATS_FAILED:  'VideoFormat/FETCH_VIDEO_FORMATS_FAILED',
    FETCH_VIDEO_FORMATS_SUCCESS: 'VideoFormat/FETCH_VIDEO_FORMATS_SUCCESS',
};

const fetchVideoFormats = makeActionCreator(
    VideoFormatTypes.FETCH_VIDEO_FORMATS,
);

const fetchVideoFormatsFailed = makeActionCreator(
    VideoFormatTypes.FETCH_VIDEO_FORMATS_FAILED,
);

const fetchVideoFormatsSuccess = makeActionCreator(
    VideoFormatTypes.FETCH_VIDEO_FORMATS_SUCCESS,
    {
        videoFormats: null,
    },
);

export const VideoFormatActions = {
    fetchVideoFormats,
    fetchVideoFormatsFailed,
    fetchVideoFormatsSuccess,
};
