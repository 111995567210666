//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                                 from 'lodash';
import AlertBoxManager                   from '../../connected/AlertBoxManager';
import ComponentHelper                   from '../../../helper/ComponentHelper';
import I18n                              from 'i18next';
import IconButton                        from '../../stateless/IconButton';
import IconButtonTheme                   from '../IconButton/IconButtonTheme';
import Input                             from '../../stateless/Input';
import InputType                         from '../../stateless/Input/InputType';
import PropTypes                         from '../../PropTypes';
import React                             from 'react';
import styles                            from './styles.module.scss';
import LeftRightSplitter                 from '../LeftRightSplitter';
import LeftRightSplitterContentAlignment from '../LeftRightSplitter/LeftRightSplitterContentAlignment';

class Component extends React.Component {
    render () {
        return (
            <div className={styles.wrapper}>
                <AlertBoxManager />
                <h3>
                    {I18n.t('resetPassword')}
                </h3>
                <Input
                    errorText={this.props.passwordError1}
                    placeholder={I18n.t('password')}
                    type={InputType.password}
                    onChange={this.props.onPasswordChange}
                />
                <Input
                    errorText={this.props.passwordError2}
                    placeholder={I18n.t('passwordCheck')}
                    type={InputType.password}
                    onChange={this.props.onPasswordCheckChange}
                />
                <LeftRightSplitter
                    contentAlignment={LeftRightSplitterContentAlignment.center}
                    rightContent={this.renderSubmitButton()}
                />
            </div>
        );
    }

    renderSubmitButton = () => {
        return (
            <IconButton
                text={I18n.t('setNewPassword')}
                theme={IconButtonTheme.turquoiseGhost}
                onClick={this.props.onSubmit}
            />
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {
    onPasswordChange:      PropTypes.func,
    onPasswordCheckChange: PropTypes.func,
    onSubmit:              PropTypes.func,
    passwordError1:        PropTypes.string,
    passwordError2:        PropTypes.string,
    resetToken:            PropTypes.string,
};

Component.defaultProps = {
    onPasswordChange:      _.noop,
    onPasswordCheckChange: _.noop,
    onSubmit:              _.noop,
    passwordError1:        null,
    passwordError2:        null,
    resetToken:            null,
};

Component.renderAffectingProps = [
    'passwordError1',
    'passwordError2',
    'resetToken',
];

Component.renderAffectingStates = [];

export default Component;