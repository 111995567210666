//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }                    from 'redux-saga/effects';
import { cancelled }              from 'redux-saga/effects';
import { call }                   from 'redux-saga/effects';
import { take }                   from 'redux-saga/effects';
import { eventChannel }           from 'redux-saga';
import * as Api                   from '../../api';
import HydraHelper                from '../../helper/Hydra';
import { MessageComposerActions } from '../actions/messageComposer';
import axios                      from 'axios';
import _                          from 'lodash';
import { AlertBoxActions }        from '../actions/alertBox';
import I18n                       from 'i18next';

const uploadFileCancelToken       = axios.CancelToken;
const uploadFileCancelTokenSource = uploadFileCancelToken.source();

// TODO: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-301
function getUploadFileInternalResponse (action, complete, error, progress, iri) {
    const response = {
        complete,
        contextKey: action.contextKey,
        error,
        id:         action.id,
        iri,
        progress,
    };

    return response;
}

function* uploadFileInternal (action) {
    // We clone the action here to allow parallel file uploads
    const clonedAction = _.cloneDeep(action);

    return eventChannel((emitter) => {
        console.log('uploadFileInternal', clonedAction);

        const uploadPromise = Api.uploadMessageAttachment(
            clonedAction.file,
            (uploadState) => {
                const progress = uploadState.loaded / uploadState.total * 100;

                console.log('uploadFileInternal: uploadState', uploadState);

                emitter(getUploadFileInternalResponse(
                    clonedAction,
                    false,
                    false,
                    progress,
                    null,
                ));
            },
        );

        uploadPromise.then((response) => {
            console.log('uploadFileInternal: uploadPromise: then', response);

            if (response.ok) {
                const cleanHydraResponse = HydraHelper.cleanupObject(response.data);

                emitter(getUploadFileInternalResponse(
                    clonedAction,
                    true,
                    false,
                    100,
                    cleanHydraResponse.iri,
                ));
            } else {
                emitter(getUploadFileInternalResponse(
                    clonedAction,
                    true,
                    true,
                    100,
                    null,
                ));
            }
        });

        return () => {
            uploadFileCancelTokenSource.cancel();
        };
    });
}

const rehydrate = function* () {
    yield put(MessageComposerActions.clearUnfinishedUploads());
};

const uploadFile = function* (action) {
    console.log('uploadFile: start', action);

    const uploadChannel = yield call(uploadFileInternal, action);

    try {
        while (true) {
            const uploadProgressData = yield take(uploadChannel);

            console.log('uploadProgressData', uploadProgressData);

            yield put(MessageComposerActions.updateUploadProgress({
                contextKey: uploadProgressData.contextKey,
                id:         uploadProgressData.id,
                progress:   uploadProgressData.progress,
            }));

            if (uploadProgressData.complete) {
                if (uploadProgressData.error) {
                    yield put(MessageComposerActions.uploadFileFailed({
                        contextKey: uploadProgressData.contextKey,
                        id:         uploadProgressData.id,
                    }));
                } else {
                    yield put(MessageComposerActions.uploadFileSucceeded({
                        contextKey: uploadProgressData.contextKey,
                        id:         uploadProgressData.id,
                        iri:        uploadProgressData.iri,
                    }));
                }
            }
        }
    } finally {
        if (yield cancelled()) {
            uploadChannel.close();
        }
    }
};

const uploadFileFailed = function* (action) {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('uploadFileError1'),
    }));
};

export default {
    rehydrate,
    uploadFile,
    uploadFileFailed,
};
