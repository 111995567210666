//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                    from 'react';
import _                        from 'lodash';
import GifWrapper               from '../../stateless/GifWrapper';
import I18n                     from 'i18next';
import Overlay                  from '../../stateless/Overlay';
import OverlayButton            from '../../stateless/OverlayButton';
import OverlayButtonTheme       from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper     from '../../stateless/OverlayButtonWrapper';
import OverlayPane              from '../../stateless/OverlayPane';
import Overlays                 from '../../../constants/Overlays';
import { ActiveProjectActions } from '../../../store/actions/activeProject';
import { bindActionCreators }   from 'redux';
import { connect }              from 'react-redux';
import { goBack }               from 'connected-react-router';

class Component extends React.Component {
    render () {
        return (
            <Overlay
                id={Overlays.acceptOffer}
                title={I18n.t('acceptOffer')}
                closeButtonPressed={this.noButtonPressed}
            >
                <OverlayPane
                    content={this.renderContent()}
                    footer={this.renderFooter()}
                    description={I18n.t('acceptOfferOverlayDescription')}
                    scrollingAllowed={false}
                />
            </Overlay>
        );
    }

    renderContent = () => {
        return (
            <GifWrapper imageSource={require('../../../assets/images/mac-gruber-iam-in.gif')} />
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.noButtonPressed}
                    standAlone={false}
                    text={I18n.t('no')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.yesButtonPressed}
                    standAlone={false}
                    text={I18n.t('yesLoud')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    noButtonPressed = () => {
        this.props.goBack();
    };

    yesButtonPressed = () => {
        this.props.acceptOffer();
    };
}

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        ActiveProjectActions,
        {
            goBack,
        },
    ),
    dispatch,
);

export default connect(
    null,
    mapDispatchToProps,
)(Component);