//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React              from 'react';
import { connect }        from 'react-redux';
import PropTypes          from '../../PropTypes';
import _                  from 'lodash';
import ChoiceSelector     from '../../stateless/ChoiceSelector';
import ChoiceSelectorMode from '../../stateless/ChoiceSelector/ChoiceSelectorMode';
import ComponentHelper    from '../../../helper/ComponentHelper';

class VideoFormatsChoiceSelector extends React.Component {
    getVideoFormats = () => {
        return ChoiceSelector.mapHydraDataForValueField(
            this.props.videoFormats,
            'name',
            false,
            this.props.selectedValues,
        );
    };

    render() {
        return (
            <ChoiceSelector
                choiceClicked={this.props.choiceClicked}
                choices={this.getVideoFormats()}
                errorText={this.props.errorText}
                mode={ChoiceSelectorMode.multipleChoice}
                paddingRight={this.props.paddingRight}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = VideoFormatsChoiceSelector;

Component.propTypes = {
    choiceClicked:  PropTypes.func,
    errorText:      PropTypes.string,
    paddingRight:   PropTypes.bool,
    selectedValues: PropTypes.array,
    videoFormats:   PropTypes.array,
};

Component.defaultProps = {
    choiceClicked:  _.noop,
    errorText:      '',
    paddingRight:   true,
    selectedValues: [],
    videoFormats:   [],
};

Component.renderAffectingProps = [
    'errorText',
    'paddingRight',
    'selectedValues',
    'videoFormats',
];

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        videoFormats: state.videoFormat.videoFormats,
    }
);

export default connect(
    mapStateToProps,
    {},
)(Component);
