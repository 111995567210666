//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import PropTypes              from '../../PropTypes';
import _                      from 'lodash';
import { withRouter }         from 'react-router-dom';
import queryString            from 'query-string';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { OverlayActions }     from '../../../store/actions/overlay';
import { connect }            from 'react-redux';

class Component extends React.Component {
    static getPathForOverlayKey(overlayKey, route) {
        return this.getNextRouteInternal(window.location, overlayKey, undefined, route);
    }

    static getNextRouteInternal(location, overlayKey, parsedQueryString, route) {
        if (!parsedQueryString) {
            parsedQueryString = queryString.parse(location.search);
        }

        const nextParsedQueryString   = _.cloneDeep(parsedQueryString);
        nextParsedQueryString.overlay = overlayKey;
        const nextQueryString         = queryString.stringify(nextParsedQueryString);
        const nextRoute               = (
            (
                route ?
                    route :
                    location.pathname
            ) + (
                nextQueryString.length > 0 ?
                    '?' + nextQueryString :
                    ''
            )
        );

        return nextRoute;
    }

    getNextRoute = (parsedQueryString) => {
        return Component.getNextRouteInternal(this.props.location, undefined, parsedQueryString);
    };

    render() {
        const parsedQueryString = queryString.parse(this.props.location.search);

        console.log('OverlayManager: render: parsedQueryString', parsedQueryString);
        console.log('OverlayManager: render: overlays', this.props.overlays);

        if (parsedQueryString.overlay) {
            for (const currentOverlayKey in this.props.overlays) {
                if (currentOverlayKey === parsedQueryString.overlay) {
                    console.log('OverlayManager: render: showing overlay', currentOverlayKey);

                    const nextRoute       = this.getNextRoute(parsedQueryString);
                    const OverlayInstance = this.props.overlays[parsedQueryString.overlay];

                    this.props.setOpenOverlay({
                        overlay: currentOverlayKey,
                    });

                    return (
                        <OverlayInstance
                            key={currentOverlayKey}
                            nextRoute={nextRoute}
                        />
                    );
                }
            }
        }

        return null;
    }
}

Component.propTypes = {
    overlays: PropTypes.object,
};

Component.defaultProps = {
    overlays: {},
};

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        OverlayActions,
    ),
    dispatch,
);

export default compose(
    connect(
        null,
        mapDispatchToProps,
    ),
    withRouter,
)(Component);
