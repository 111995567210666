//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                            from 'lodash';
import CompanyTypes                 from '../../../constants/CompanyTypes';
import I18n                         from 'i18next';
import Overlay                      from '../Overlay';
import OverlayPaneState             from '../../stateless/OverlayPane/OverlayPaneState';
import Overlays                     from '../../../constants/Overlays';
import PropTypes                    from '../../PropTypes';
import React                        from 'react';
import RegisterCompanyOverlayPane   from '../RegisterCompanyOverlayPane';
import RegisterUserOverlayPane      from '../RegisterUserOverlayPane';
import { AlertBoxActions }          from '../../../store/actions/alertBox';
import { bindActionCreators }       from 'redux';
import { compose }                  from 'redux';
import { CompanyActions }           from '../../../store/actions/company';
import { connect }                  from 'react-redux';
import { CreateEditProjectActions } from '../../../store/actions/createEditProject';
import { goBack }                   from 'connected-react-router';
import { withRouter }               from 'react-router-dom';

class Component extends React.Component {
    constructor(props) {
        super(props);

        this.props.temporarySetOwnCompanyType({
            companyType: CompanyTypes.videoProduction,
        });

        this.props.setMaxPaneIndex({
            index: 2,
        });
    }

    createCompanyAndUser = () => {
        this.props.createCompanyAndUser();
    };

    getOverlayPaneStateForIndex = (index) => {
        if (index < this.props.activePaneIndex) {
            return OverlayPaneState.done;
        } else if (index === this.props.activePaneIndex) {
            return OverlayPaneState.open;
        }

        return OverlayPaneState.upcoming;
    };

    render() {
        return (
            <Overlay
                id={Overlays.registerVideoProduction}
                title={I18n.t('createUserProfileDetails')}
            >
                <RegisterCompanyOverlayPane
                    state={this.getOverlayPaneStateForIndex(0)}
                    isFirst={true}
                />
                <RegisterUserOverlayPane
                    state={this.getOverlayPaneStateForIndex(1)}
                    onContinue={this.createCompanyAndUser}
                    isVideoProduction={true}
                />
            </Overlay>
        );
    }
}

Component.propTypes = {
    activePaneIndex:      PropTypes.number,
    createCompanyAndUser: PropTypes.func,
};

Component.defaultProps = {
    activePaneIndex:      0,
    createCompanyAndUser: _.noop,
};

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        AlertBoxActions,
        CompanyActions,
        CreateEditProjectActions,
        {
            goBack,
        },
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        activePaneIndex: _.get(state, 'createEditProject.activePaneIndex', 0),
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Component);
