//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                    from 'lodash';
import I18n                 from 'i18next';
import Overlay              from '../../connected/Overlay';
import OverlayButton        from '../OverlayButton';
import OverlayButtonTheme   from '../OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper from '../OverlayButtonWrapper';
import OverlayPane          from '../OverlayPane';
import Overlays             from '../../../constants/Overlays';
import PropTypes            from '../../PropTypes';
import React                from 'react';
import ReactPlayer          from 'react-player';
import styles               from './styles.module.scss';
import { withTranslation }  from 'react-i18next';

class Component extends React.Component {
    render () {
        return (
            <Overlay
                id={Overlays.watchVideo}
                title={I18n.t('watchVideo')}
            >
                <OverlayPane
                    content={this.renderContent()}
                    footer={this.renderFooter()}
                    scrollingAllowed={false}
                />
            </Overlay>
        );
    }

    renderContent = () => {
        return (
            <div className={styles.videoContainer}>
                <ReactPlayer
                    className={styles.videoFrame}
                    url={this.props.videoUrl}
                    width={'100%'}
                    height={'100%'}
                    playing={true}
                />
            </div>
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.props.closeButtonPressed}
                    standAlone={false}
                    text={I18n.t('close')}
                    theme={OverlayButtonTheme.gray}
                />
            </OverlayButtonWrapper>
        );
    };
}

Component.propTypes = {
    closeButtonPressed: PropTypes.func,
    videoUrl:           PropTypes.string,
};

Component.defaultProps = {
    closeButtonPressed: _.noop,
    videoUrl:           null,
};

export default withTranslation()(Component);