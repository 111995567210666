//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import PropTypes        from 'prop-types';
import FileStates       from '../constants/FileStates';
import OverlayPaneState from './stateless/OverlayPane/OverlayPaneState';

PropTypes.children = PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
]);

PropTypes.imageDimensions = PropTypes.shape({
    imageWidth:  PropTypes.number,
    imageHeight: PropTypes.number,
});

PropTypes.imageWithAltText = PropTypes.shape({
    altText: PropTypes.string,
    source:  PropTypes.string,
});

PropTypes.fileListEntry = PropTypes.shape({
    additionalText: PropTypes.string,
    downloadUrl:    PropTypes.string,
    name:           PropTypes.string,
});

PropTypes.reference = PropTypes.shape({
    additionalText: PropTypes.string,
    downloadUrl:    PropTypes.string,
    name:           PropTypes.string,
});

PropTypes.uploadableFile = PropTypes.shape({
    name:           PropTypes.string,
    size:           PropTypes.number,
    state:          PropTypes.oneOf(Object.keys(FileStates)),
    uploadProgress: PropTypes.number,
});

PropTypes.overlayPaneState = PropTypes.oneOf(Object.values(OverlayPaneState));

export default PropTypes;
