//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                        from 'react';
import _                            from 'lodash';
import ComponentHelper              from '../../../helper/ComponentHelper';
import ErrorAwareOverlayPane        from '../../connected/ErrorAwareOverlayPane';
import FormRow                      from '../../stateless/FormRow';
import GA                           from '../../../helper/GA';
import I18n                         from 'i18next';
import Input                        from '../../stateless/Input';
import InputSize                    from '../../stateless/Input/InputSize';
import InputType                    from '../../stateless/Input/InputType';
import OverlayButton                from '../../stateless/OverlayButton';
import OverlayButtonTheme           from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper         from '../../stateless/OverlayButtonWrapper';
import OverlayPaneState             from '../../stateless/OverlayPane/OverlayPaneState';
import PropTypes                    from '../../PropTypes';
import { AlertBoxActions }          from '../../../store/actions/alertBox';
import { bindActionCreators }       from 'redux';
import { connect }                  from 'react-redux';
import { CreateEditProjectActions } from '../../../store/actions/createEditProject';

class CreateProjectOverlayAdditionPane extends React.Component {
    eventLabel = 'Addition';

    backButtonPressed = () => {
        GA.sendCreateProjectPreviousEvent(this.eventLabel);
        this.props.clearAlerts();
        this.props.previousPane();
    };

    titleChanged = (event) => {
        this.props.setTitle({
            title: event.target.value,
        });
    };

    descriptionChanged = (event) => {
        this.props.setDescription({
            description: event.target.value,
        });
    };

    continueButtonPressed = () => {
        this.props.clearAlerts();

        if (!this.props.title) {
            this.props.showErrorAlertTagged({
                tag:  'title',
                text: I18n.t('projectTitleErrorEmpty'),
            });
        }

        requestAnimationFrame(() => {
            if (this.props.alertBoxesTaggedCount === 0) {
                GA.sendCreateProjectNextEvent(this.eventLabel);
                this.props.nextPane();
            }
        });
    };

    render() {
        return (
            <ErrorAwareOverlayPane
                content={this.renderContent()}
                footer={this.renderFooter()}
                title={I18n.t('titleAndDescription')}
                showErrors={true}
                state={this.props.overlayPaneState}
                upcomingDistance={this.props.upcomingDistance}
            />
        );
    }

    renderContent = () => {
        return (
            <>
                {this.renderContentTitle()}
                {this.renderContentDescription()}
            </>
        );
    };

    renderContentDescription = () => {
        return (
            <FormRow
                isOptional={true}
                label={I18n.t('projectDescription')}
                tooltipText={I18n.t('projectDescriptionTooltipText')}
            >
                <Input
                    noMarginBottom={true}
                    onChange={this.descriptionChanged}
                    placeholder={I18n.t('projectDescriptionPlaceHolder')}
                    size={InputSize.multiLine}
                    type={InputType.text}
                    value={_.get(this.props, 'description')}
                />
            </FormRow>
        );
    };

    renderContentTitle = () => {
        return (
            <FormRow
                label={I18n.t('projectTitle')}
                tooltipText={I18n.t('projectTitleTooltipText')}
            >
                <Input
                    errorText={this.props.titleError}
                    noMarginBottom={true}
                    onChange={this.titleChanged}
                    placeholder={I18n.t('projectTitlePlaceHolder')}
                    size={InputSize.singleLine}
                    type={InputType.text}
                    value={_.get(this.props, 'title')}
                />
            </FormRow>
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.backButtonPressed}
                    standAlone={false}
                    text={I18n.t('back')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.continueButtonPressed}
                    standAlone={false}
                    text={I18n.t('continue')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CreateProjectOverlayAdditionPane;

Component.propTypes = {
    alertBoxesTaggedCount: PropTypes.number,
    description:           PropTypes.string,
    overlayPaneState:      PropTypes.oneOf(Object.values(OverlayPaneState)),
    title:                 PropTypes.string,
    titleError:            PropTypes.string,
    upcomingDistance:      PropTypes.number,
};

Component.defaultProps = {
    alertBoxesTaggedCount: 0,
    description:           '',
    overlayPaneState:      OverlayPaneState.upcoming,
    title:                 '',
    titleError:            '',
    upcomingDistance:      0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    AlertBoxActions,
    CreateEditProjectActions,
), dispatch);

const mapStateToProps = state => (
    {
        alertBoxesTaggedCount: _.get(state, 'alertBox.alertBoxesTaggedCount', 0),
        description:           _.get(state, 'createEditProject.description'),
        title:                 _.get(state, 'createEditProject.title'),
        titleError:            _.get(state, 'alertBox.alertBoxesTagged.title'),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
