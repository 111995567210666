//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { CompanyPositionActions } from '../actions/companyPosition';
import { put }                    from 'redux-saga/effects';
import { call }                   from 'redux-saga/effects';
import * as Api                   from '../../api';
import HydraHelper                from '../../helper/Hydra';
import { push }                   from 'connected-react-router';
import Routes                     from '../../constants/Routes';

const fetchCompanyPositions = function* () {
    const response = yield call(
        Api.fetchCompanyPositions,
    );

    if (response.ok) {
        const cleanHydraResponse = HydraHelper.cleanupObject(response.data);
        const companyPositions   = cleanHydraResponse.member;

        yield put(CompanyPositionActions.fetchCompanyPositionsSuccess({
            companyPositions,
        }));
    } else {
        yield put(CompanyPositionActions.fetchCompanyPositionsFailed());
    }
};

export default {
    fetchCompanyPositions,
};
