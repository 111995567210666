//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper from '../../../helper/ComponentHelper';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';
import Link            from '../Link';
import classNames      from 'classnames';

class HeaderButton extends React.Component {
    render () {
        if (this.props.to) {
            return (
                <div
                    className={classNames(
                        styles.headerButton,
                        {
                            [styles.headerButtonActive]: this.props.isActive,
                        },
                    )}
                >
                    {this.renderInner()}
                </div>
            );
        }

        return null;
    }

    renderInner = () => {
        if (this.props.onClick) {
            return (
                <div
                    onClick={this.props.onClick}
                >
                    {this.props.text}
                </div>
            );
        }

        if (this.props.to.indexOf('http') > -1) {
            return (
                <a
                    target={this.props.target}
                    href={this.props.to}
                >
                    {this.props.label}
                </a>
            );
        }

        return (
            <Link
                target={this.props.target}
                to={this.props.to}
                params={this.props.params}
            >
                {this.props.label}
            </Link>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = HeaderButton;

Component.propTypes = {
    isActive: PropTypes.bool,
    onClick:  PropTypes.func,
    params:   PropTypes.object,
    target:   PropTypes.string,
    to:       PropTypes.string,
};

Component.defaultProps = {
    isActive: false,
    onClick:  null,
    params:   {},
    target:   null,
    to:       null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
