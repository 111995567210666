//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import ComponentHelper from '../../../helper/ComponentHelper';
import Link            from '../Link';
import _               from 'lodash';

class TextButton extends React.Component {
    render () {
        if (this.props.onClick) {
            return (
                <div
                    className={styles.wrapper}
                    onClick={this.props.onClick}
                >
                    <span className={styles.text}>
                        {this.props.text}
                    </span>
                </div>
            );
        } else if (this.props.to) {
            return (
                <Link
                    className={styles.wrapper}
                    to={this.props.to}
                >
                    <span className={styles.text}>
                        {this.props.text}
                    </span>
                </Link>
            );
        }
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = TextButton;

Component.propTypes = {
    onClick: PropTypes.func,
    text:    PropTypes.string,
    to:      PropTypes.string,
};

Component.defaultProps = {
    onClick: _.noop,
    text:    '',
    to:      null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
