//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                      from 'react';
import { connect }                from 'react-redux';
import PropTypes                  from '../../PropTypes';
import _                          from 'lodash';
import I18n                       from 'i18next';
import { bindActionCreators }     from 'redux';
import { AlertBoxActions }        from '../../../store/actions/alertBox';
import { CreateEditOfferActions } from '../../../store/actions/createEditOffer';
import ComponentHelper            from '../../../helper/ComponentHelper';
import FormRow                    from '../../stateless/FormRow';
import Input                      from '../../stateless/Input';
import InputSize                  from '../../stateless/Input/InputSize';
import InputType                  from '../../stateless/Input/InputType';
import FileManager                from '../../stateless/FileManager';
import IconType                   from '../../stateless/Icon/IconType';
import OverlayPane                from '../../stateless/OverlayPane';
import { v4 as uuidv4 }           from 'uuid';
import NumberFormat               from '../../../helper/NumberFormat';

class CreateOfferOverlayOfferPane extends React.Component {
    fileDeletionButtonPressed = (index, file) => {
        this.props.deleteOfferFile({
            id:         file.id,
            offerIndex: this.props.offerIndex,
        });
    };

    getFiles = () => {
        const files = Object.values(this.props.files);

        return files;
    };

    messageChanged = (event) => {
        this.props.setMessage({
            message:    event.target.value,
            offerIndex: this.props.offerIndex,
        });
    };

    netPriceInEuroChanged = (event) => {
        this.props.setNetPriceInEuro({
            netPriceInEuro: event.target.value,
            offerIndex:     this.props.offerIndex,
        });
    };

    removeOfferButtonPressed = () => {
        this.props.removeOffer({
            offerIndex: this.props.offerIndex,
        });
    };

    render() {
        return (
            <OverlayPane
                closeButtonPressed={this.removeOfferButtonPressed}
                content={this.renderContent()}
                showCloseButton={this.props.offerCount > 1}
                title={I18n.t(
                    'createNewProjectOfferTitle',
                    {
                        offerNumber: this.props.offerIndex + 1,
                    },
                )}
                scrollingAllowed={false}
                showErrors={true}
            />
        );
    }

    renderContent = () => {
        return (
            <>
                {this.renderContentOfferTitle()}
                {this.renderContentOfferMessage()}
                {this.renderContentOfferFiles()}
                {this.renderContentOfferNetPriceInEuro()}
            </>
        );
    };

    renderContentOfferFiles = () => {
        const files = this.getFiles();

        return (
            <FormRow
                label={I18n.t('offerPdf')}
            >
                <FileManager
                    addFileButtonIcon={IconType.fileText}
                    addFileButtonText={I18n.t('uploadOffer')}
                    deleteButtonPressed={this.fileDeletionButtonPressed}
                    errorText={this.props.filesError}
                    files={files}
                    tip={I18n.t(
                        'fileSizeLimitText',
                        {
                            humanReadableFileSize: NumberFormat.toHumanReadableFileSize(this.props.maxUploadSizeInBytes, true),
                        },
                    )}
                    uploadFileCallback={this.uploadFileCallback}
                />
            </FormRow>
        );
    };

    renderContentOfferMessage = () => {
        return (
            <FormRow
                isOptional={true}
                label={I18n.t('additionalMessage')}
            >
                <Input
                    noMarginBottom={true}
                    onChange={this.messageChanged}
                    placeholder={I18n.t('defaultPlaceHolderTextInput')}
                    size={InputSize.multiLine}
                    type={InputType.text}
                    value={this.props.message}
                />
            </FormRow>
        );
    };

    renderContentOfferNetPriceInEuro = () => {
        return (
            <FormRow
                label={I18n.t('offerSum')}
            >
                <Input
                    errorText={this.props.netPriceInEuroError}
                    noMarginBottom={true}
                    onChange={this.netPriceInEuroChanged}
                    placeholder={I18n.t('defaultPlaceHolderTextInput')}
                    size={InputSize.singleLine}
                    type={InputType.number}
                    prefix={I18n.t('offerSumPrefix')}
                    value={this.props.netPriceInEuro}
                />
            </FormRow>
        );
    };

    renderContentOfferTitle = () => {
        return (
            <FormRow label={I18n.t('offerTitle')}>
                <Input
                    errorText={this.props.titleError}
                    noMarginBottom={true}
                    onChange={this.titleChanged}
                    placeholder={I18n.t('defaultPlaceHolderTextInput')}
                    size={InputSize.singleLine}
                    type={InputType.text}
                    value={this.props.title}
                />
            </FormRow>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    titleChanged = (event) => {
        this.props.setTitle({
            offerIndex: this.props.offerIndex,
            title:      event.target.value,
        });
    };

    uploadFileCallback = (file) => {
        const newId = uuidv4();

        this.props.uploadOfferFile({
            file,
            id:         newId,
            offerIndex: this.props.offerIndex,
        });
    };
}

const Component = CreateOfferOverlayOfferPane;

Component.propTypes = {
    files:                PropTypes.object,
    maxUploadSizeInBytes: PropTypes.number,
    message:              PropTypes.string,
    messageError:         PropTypes.string,
    netPriceInEuro:       PropTypes.number,
    netPriceInEuroError:  PropTypes.string,
    offerCount:           PropTypes.number,
    offerIndex:           PropTypes.number,
    title:                PropTypes.string,
    titleError:           PropTypes.string,
};

Component.defaultProps = {
    files:                {},
    filesError:           '',
    maxUploadSizeInBytes: 0,
    message:              '',
    messageError:         '',
    netPriceInEuro:       0,
    netPriceInEuroError:  '',
    offerCount:           0,
    offerIndex:           0,
    title:                '',
    titleError:           '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    AlertBoxActions,
    CreateEditOfferActions,
), dispatch);

const mapStateToProps = (state, ownProps) => {
    return {
        files:                _.get(
            state,
            [
                'createEditOffer',
                'offers',
                ownProps.offerIndex,
                'files',
            ],
            [],
        ),
        filesError:           _.get(state, 'alertBox.alertBoxesTagged.files_' + ownProps.offerIndex),
        maxUploadSizeInBytes: _.get(state, 'configuration.configuration.maxUploadSizeInBytes', 0),
        message:              _.get(
            state,
            [
                'createEditOffer',
                'offers',
                ownProps.offerIndex,
                'message',
            ],
            '',
        ),
        messageError:         _.get(state, 'alertBox.alertBoxesTagged.message_' + ownProps.offerIndex),
        netPriceInEuro:       _.get(
            state,
            [
                'createEditOffer',
                'offers',
                ownProps.offerIndex,
                'netPriceInEuro',
            ],
            0.00,
        ),
        netPriceInEuroError:  _.get(state, 'alertBox.alertBoxesTagged.netPriceInEuro_' + ownProps.offerIndex),
        title:                _.get(
            state,
            [
                'createEditOffer',
                'offers',
                ownProps.offerIndex,
                'title',
            ],
            {},
        ),
        titleError:           _.get(state, 'alertBox.alertBoxesTagged.title_' + ownProps.offerIndex),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
