//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                    from 'react';
import colors                   from '../../../styles/colors.scss';
import ComponentHelper          from '../../../helper/ComponentHelper';
import { connect }              from 'react-redux';
import SplitPane                from 'react-split-pane';
import MainRouter               from '../../stateless/MainRouter';
import LeftPaneRouter           from '../../stateless/LeftPaneRouter';
import SplitPanePanel           from '../../stateless/SplitPanePanel';
import SplitPaneMode            from '../../../constants/SplitPaneMode';
import { MainSplitPaneActions } from '../../../store/actions/mainSplitPane';
import { bindActionCreators }   from 'redux';
import SplitPanePriority        from '../../../constants/SplitPanePriority';

const mainSplitPaneConfiguration = {
    left: {
        minimumSize: 300,
        maximumSize: 600,
    },
};

const resizerStyle = {
    backgroundColor: colors.colorGray,
    width:           1,
    cursor:          'ew-resize',
};

class MainSplitPane extends React.Component {
    getLeftPaneSize() {
        if (this.props.leftPaneVisible) {
            return this.props.leftPaneSize;
        }

        return 0;
    }

    onLeftPaneChanged = (newSize) => {
        this.props.leftPaneSizeChanged({
            size: newSize,
        });
    };

    render() {
        const leftPaneSize = this.getLeftPaneSize();

        return (
            <SplitPane
                split={SplitPaneMode.vertical}
                onDragFinished={this.onLeftPaneChanged}
                minSize={mainSplitPaneConfiguration.left.minimumSize}
                maxSize={mainSplitPaneConfiguration.left.maximumSize}
                defaultSize={leftPaneSize}
                size={leftPaneSize}
                primary={SplitPanePriority.first}
                resizerStyle={resizerStyle}
                allowResize={true}
            >
                <SplitPanePanel backgroundColor={colors.colorGrayLight2}>
                    <LeftPaneRouter />
                </SplitPanePanel>
                <SplitPanePanel backgroundColor={colors.colorGray}>
                    <MainRouter />
                </SplitPanePanel>
            </SplitPane>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = MainSplitPane;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = [
    'leftPaneSize',
    'leftPaneVisible',
];

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        leftPaneSize:    state.mainSplitPane.leftPaneSize,
        leftPaneVisible: state.mainSplitPane.leftPaneVisible,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(
    MainSplitPaneActions,
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
