//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                              from 'lodash';
import AddressPreviewLabel            from '../../components/stateless/AddressPreviewLabel';
import AlertBoxManager                from '../../components/connected/AlertBoxManager';
import CompanyProfileGenericItems     from '../../components/stateless/CompanyProfileGenericItems';
import CompanyProfileGenericItemsMode from '../../components/stateless/CompanyProfileGenericItems/CompanyProfileGenericItemsMode';
import CompanyProfileHeader           from '../../components/stateless/CompanyProfileHeader';
import CompanyProfileImages           from '../../components/stateless/CompanyProfileImages';
import CompanyProfileInformation      from '../../components/stateless/CompanyProfileInformation';
import CompanyProfileReferences       from '../../components/stateless/CompanyProfileReferences';
import CompanyProfileSocialMedia      from '../../components/stateless/CompanyProfileSocialMedia';
import CompanyTypes                   from '../../constants/CompanyTypes';
import ContentWrapper                 from '../../components/stateless/ContentWrapper';
import I18n                           from 'i18next';
import PageTitleHelper                from '../../helper/PageTitle';
import React                          from 'react';
import { Component }                  from 'react';
import Routes                         from '../../constants/Routes';
import Spacer                         from '../../components/stateless/Spacer';
import { bindActionCreators }         from 'redux';
import { compose }                    from 'redux';
import { CompanyActions }             from '../../store/actions/company';
import { connect }                    from 'react-redux';
import { Helmet }                     from 'react-helmet';
import { VideoActions }               from '../../store/actions/video';
import { withRouter }                 from 'react-router-dom';

class Screen extends Component {
    editCompanyHeaderButtonPressed = () => {
        this.props.editOwnCompanyHeader();
    };

    editCompanyImagesButtonPressed = () => {
        this.props.editOwnCompanyImages();
    };

    editCompanyInformationButtonPressed = () => {
        this.props.editOwnCompanyInformation();
    };

    editCompanySocialMediaButtonPressed = () => {
        this.props.editOwnCompanySocialMedias();
    };

    editCompetencesButtonPressed = () => {
        this.props.editOwnCompanyCompetences();
    };

    editLanguagesButtonPressed = () => {
        this.props.editOwnCompanyLanguages();
    };

    editLocationsButtonPressed = () => {
        this.props.editOwnCompanyLocations();
    };

    editPlaceOfWorkButtonPressed = () => {
        this.props.editOwnCompanyPlaceOfWorks();
    };

    editReferencesButtonPressed = () => {
        this.props.editOwnCompanyReferences();
    };

    openVideoClicked = (reference) => {
        this.props.openVideo({
            video: reference.videoUrl,
        });
    };

    render () {
        if (this.props.isLoading) {
            return null;
        }

        const currentCompanyRoute      = _.get(this.props, 'location.pathname', null);
        const company                  = _.get(this.props, 'profileCompany', null);
        const ownCompany               = _.get(this.props, 'ownCompany', null);
        const companyId                = _.get(ownCompany, 'id', null);
        const companyType              = _.get(company, 'companyType', null);
        const isVideoProductionCompany = companyType === CompanyTypes.videoProduction;
        const isOwnCompany             = companyId === currentCompanyRoute.replace(Routes.companyProfilePart, '');

        return (
            <ContentWrapper>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('companyProfile'))}
                    </title>
                </Helmet>
                <AlertBoxManager />
                <Spacer height={20} />
                {this.renderCompanyHeader(isOwnCompany)}
                {this.renderInformation(isOwnCompany)}
                {this.renderReferences(isOwnCompany, isVideoProductionCompany)}
                {this.renderCompetences(isOwnCompany, isVideoProductionCompany)}
                {this.renderLanguages(isOwnCompany)}
                {this.renderPlaceOfWork(isOwnCompany, isVideoProductionCompany)}
                {this.renderLocations(isOwnCompany, isVideoProductionCompany)}
                {this.renderImages(isOwnCompany, isVideoProductionCompany)}
            </ContentWrapper>
        );
    }

    renderCompanyHeader = (isOwnCompany) => {
        return (
            <>
                <CompanyProfileHeader
                    company={_.get(this.props, 'profileCompany')}
                    editButtonPressed={this.editCompanyHeaderButtonPressed}
                    showEditButton={isOwnCompany}
                />
                <CompanyProfileSocialMedia
                    company={_.get(this.props, 'profileCompany')}
                    editButtonPressed={this.editCompanySocialMediaButtonPressed}
                    showEditButton={isOwnCompany}
                />
            </>
        );
    };

    renderCompetences = (isOwnCompany, isVideoProductionCompany) => {
        if (isVideoProductionCompany) {
            return (
                <CompanyProfileGenericItems
                    editButtonPressed={this.editCompetencesButtonPressed}
                    items={_.get(this.props, 'profileCompany.coreCompetences')}
                    mode={CompanyProfileGenericItemsMode.small}
                    showEditButton={isOwnCompany}
                    title={I18n.t('profileAbilitiesAndKnowledge')}
                    fieldName={'title'}
                />
            );
        }

        return null;
    };

    renderInformation = (isOwnCompany) => {
        return (
            <CompanyProfileInformation
                company={_.get(this.props, 'profileCompany')}
                companyPositions={_.get(this.props, 'companyPositions')}
                editButtonPressed={this.editCompanyInformationButtonPressed}
                showEditButton={isOwnCompany}
            />
        );
    };

    renderImages = (isOwnCompany, isVideoProductionCompany) => {
        if (isVideoProductionCompany) {
            return (
                <CompanyProfileImages
                    editButtonPressed={this.editCompanyImagesButtonPressed}
                    images={_.get(this.props, 'profileCompany.companyImages')}
                    showEditButton={isOwnCompany}
                />
            );
        }

        return null;
    };

    renderLanguages = (isOwnCompany) => {
        return (
            <CompanyProfileGenericItems
                editButtonPressed={this.editLanguagesButtonPressed}
                items={_.get(this.props, 'profileCompany.languages')}
                mode={CompanyProfileGenericItemsMode.fullWidth}
                showEditButton={isOwnCompany}
                title={I18n.t('profileLanguages')}
            />
        );
    };

    renderLocations = (isOwnCompany, isVideoProductionCompany) => {
        if (isVideoProductionCompany) {
            const locations = _.cloneDeep(_.get(this.props, 'profileCompany.companyLocations', []));

            locations.map((location) => {
                location.addressPreview = (
                    <AddressPreviewLabel address={location.address} />
                );
            });

            return (
                <CompanyProfileGenericItems
                    editButtonPressed={this.editLocationsButtonPressed}
                    items={locations}
                    mode={CompanyProfileGenericItemsMode.fullWidth}
                    showEditButton={isOwnCompany}
                    title={I18n.t('profileLocations')}
                    fieldName={'addressPreview'}
                />
            );
        }

        return null;
    };

    renderPlaceOfWork = (isOwnCompany, isVideoProductionCompany) => {
        if (isVideoProductionCompany) {
            const placeOfWork = _.get(this.props, 'profileCompany.placeOfWork', {});

            return (
                <CompanyProfileGenericItems
                    editButtonPressed={this.editPlaceOfWorkButtonPressed}
                    items={[placeOfWork]}
                    mode={CompanyProfileGenericItemsMode.fullWidth}
                    showEditButton={isOwnCompany}
                    title={I18n.t('placeOfWork')}
                    fieldName={'title'}
                />
            );
        }

        return null;
    };

    renderReferences = (isOwnCompany, isVideoProductionCompany) => {
        if (isVideoProductionCompany) {
            return (
                <CompanyProfileReferences
                    editButtonPressed={this.editReferencesButtonPressed}
                    playButtonPressed={this.openVideoClicked}
                    references={_.get(this.props, 'profileCompany.references')}
                    showEditButton={isOwnCompany}
                />
            );
        }

        return null;
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    CompanyActions,
    VideoActions,
), dispatch);

const mapStateToProps = state => (
    {
        companyPositions: _.get(state, 'companyPosition.companyPositions'),
        isLoading:        _.get(state, 'loading.isLoading'),
        location:         _.get(state, 'location'),
        ownCompany:       _.get(state, 'company.ownCompany'),
        profileCompany:   _.get(state, 'company.profile'),
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Screen);