//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                                  from 'react';
import styles                                 from './styles.module.scss';
import PropTypes                              from '../../PropTypes';
import ComponentHelper                        from '../../../helper/ComponentHelper';
import _                                      from 'lodash';
import I18n                                   from 'i18next';
import IconButtonTheme                        from '../IconButton/IconButtonTheme';
import IconButton                             from '../IconButton';
import CompanyProfileHeaderTitleTextAlignment from '../CompanyProfileHeaderTitle/CompanyProfileHeaderTitleTextAlignment';
import CompanyProfileHeaderTitle              from '../CompanyProfileHeaderTitle';

class CompanyProfileReferences extends React.Component {
    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.wrapperInner}>
                    <CompanyProfileHeaderTitle
                        editButtonPressed={this.props.editButtonPressed}
                        showEditButton={this.props.showEditButton}
                        textAlignment={CompanyProfileHeaderTitleTextAlignment.left}
                        title={I18n.t('profileReferences')}
                    />
                    {this.renderReferences()}
                </div>
            </div>
        );
    }

    renderReference = (reference, index) => {
        return (
            <div
                className={styles.reference}
                key={index}
                style={{
                    backgroundImage: 'url(' + reference.videoThumbnailUrl + ')',
                }}
            >
                <IconButton
                    onClick={() => {
                        this.props.playButtonPressed(reference, index);
                    }}
                    text={I18n.t('referencePlay')}
                    theme={IconButtonTheme.turquoiseGhostWithDarkBackground}
                />
            </div>
        );
    };

    renderReferences = () => {
        const references = _.orderBy(this.props.references, ['referenceOrder'], ['asc']); // TODO: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-658

        return (
            <div className={styles.referenceWrapper}>
                {references.map(this.renderReference)}
            </div>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CompanyProfileReferences;

Component.propTypes = {
    editButtonPressed: PropTypes.func,
    playButtonPressed: PropTypes.func,
    references:        PropTypes.arrayOf(PropTypes.reference),
    showEditButton:    PropTypes.bool,
};

Component.defaultProps = {
    editButtonPressed: _.noop,
    playButtonPressed: _.noop,
    references:        [],
    showEditButton:    false,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
