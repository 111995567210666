//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }           from 'redux-saga/effects';
import { call }          from 'redux-saga/effects';
import * as Api          from '../../api';
import HydraHelper       from '../../helper/Hydra';
import { BranchActions } from '../actions/branch';
import { push }          from 'connected-react-router';
import Routes            from '../../constants/Routes';

const fetchBranches = function* () {
    const response = yield call(
        Api.fetchBranches,
    );

    if (response.ok) {
        const cleanHydraResponse = HydraHelper.cleanupObject(response.data);
        const branches           = cleanHydraResponse.member;

        yield put(BranchActions.fetchBranchesSuccess({
            branches,
        }));
    } else {
        yield put(BranchActions.fetchBranchesFailed());
    }
};

export default {
    fetchBranches,
};
