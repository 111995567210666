//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React             from 'react';
import _                 from 'lodash';
import ComponentHelper   from '../../../helper/ComponentHelper';
import PropTypes         from '../../PropTypes';
import Routes            from '../../../constants/Routes';
import UserMenuStateless from '../../stateless/UserMenu';
import { connect }       from 'react-redux';

class UserMenu extends React.Component {
    render() {
        const name                    = _.get(this.props, 'user.firstname', '');
        const company                 = _.get(this.props, 'company', {});
        const editCompanyProfileRoute = Routes.companyProfilePart + _.get(this.props, 'company.id');
        const editUserProfileRoute    = Routes.userProfilePart + _.get(this.props, 'user.id');

        return (
            <UserMenuStateless
                company={company}
                name={name}
                ownCompanyRoute={editCompanyProfileRoute}
                ownUserRoute={editUserProfileRoute}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = UserMenu;

Component.propTypes = {
    company: PropTypes.object,
    user:    PropTypes.object,
};

Component.defaultProps = {
    company: null,
    user:    null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        company: state.company.ownCompany,
        user:    state.user.user,
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);
