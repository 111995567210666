//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import _                      from 'lodash';
import { goBack }             from 'connected-react-router';
import { connect }            from 'react-redux';
import { withRouter }         from 'react-router-dom';
import { CompanyActions }     from '../../../store/actions/company';
import { AlertBoxActions }    from '../../../store/actions/alertBox';
import I18n                   from 'i18next';
import ChoiceSelector         from '../../stateless/ChoiceSelector';
import ChoiceSelectorMode     from '../../stateless/ChoiceSelector/ChoiceSelectorMode';
import ChoiceWidthMode        from '../../stateless/ChoiceSelector/ChoiceWidthMode';
import FormRow                from '../../stateless/FormRow';
import Hydra                  from '../../../helper/Hydra';
import Overlay                from '../Overlay';
import OverlayButton          from '../../stateless/OverlayButton';
import OverlayButtonTheme     from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper   from '../../stateless/OverlayButtonWrapper';
import OverlayPane            from '../../stateless/OverlayPane';
import OverlayPaneState       from '../../stateless/OverlayPane/OverlayPaneState';
import Overlays               from '../../../constants/Overlays';
import PropTypes              from '../../PropTypes';

class Component extends React.Component {
    cancelButtonPressed = () => {
        this.props.goBack();
    };

    getLanguagesForChoiceSelector = () => {
        const choiceLanguages = [];

        this.props.languages.forEach((language) => {
            const isSelected     = _.some(_.get(this.props, 'company.languages'), language);
            const choiceLanguage = {
                selected:  isSelected,
                text:      language.name,
                widthMode: ChoiceWidthMode.full,
            };

            choiceLanguages.push(choiceLanguage);
        });

        return choiceLanguages;
    };

    languagesChanged = (data, index) => {
        if (data.selected) {
            this.props.temporaryRemoveOwnCompanyLanguage({
                language: this.props.languages[index],
            });
        } else {
            this.props.temporaryAddOwnCompanyLanguage({
                language: this.props.languages[index],
            });
        }
    };

    render() {
        return (
            <Overlay
                id={Overlays.editCompanyLanguages}
                title={I18n.t('profileDetails')}
            >
                <OverlayPane
                    content={this.renderContent()}
                    footer={this.renderFooter()}
                    title={I18n.t('companyLanguages')}
                    showErrors={true}
                    state={OverlayPaneState.open}
                    scrollingAllowed={false}
                />
            </Overlay>
        );
    }

    renderContent = () => {
        return this.renderContentLanguages();
    };

    renderContentLanguages = () => {
        const languages = this.getLanguagesForChoiceSelector();

        return (
            <FormRow
                label={I18n.t('companyLanguages')}
                tooltipText={I18n.t('companyLanguagesTooltipText')}
            >
                <ChoiceSelector
                    choiceClicked={this.languagesChanged}
                    choices={languages}
                    mode={ChoiceSelectorMode.multipleChoice}
                    errorText={this.props.languagesError}
                />
            </FormRow>
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.cancelButtonPressed}
                    standAlone={false}
                    text={I18n.t('cancel')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.saveButtonPressed}
                    standAlone={false}
                    text={I18n.t('save')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    saveButtonPressed = () => {
        this.props.clearAlerts();

        const companyLanguages = _.get(this.props, 'company.languages');

        if (!companyLanguages.length) {
            this.props.showErrorAlertTagged({
                tag:  'languages',
                text: I18n.t('companyLanguagesErrorEmpty'),
            });
        }

        requestAnimationFrame(() => {
            if (this.props.alertBoxesTaggedCount === 0) {
                this.props.updateCompany({
                    data: {
                        languages: Hydra.getIriArrayFromObjects(companyLanguages),
                    },
                });

                this.props.goBack();
            }
        });
    };
}

Component.propTypes = {
    alertBoxesTaggedCount: PropTypes.number,
    company:               PropTypes.object,
    languages:             PropTypes.array,
    languagesError:        PropTypes.string,
};

Component.defaultProps = {
    alertBoxesTaggedCount: 0,
    company:               {},
    languages:             [],
    languagesError:        '',
};

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        AlertBoxActions,
        CompanyActions,
        {
            goBack,
        },
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        alertBoxesTaggedCount: _.get(state, 'alertBox.alertBoxesTaggedCount', 0),
        company:               _.get(state, 'company.ownCompanyEditContext'),
        languages:             _.get(state, 'languages.languages'),
        languagesError:        _.get(state, 'alertBox.alertBoxesTagged.languages', null),
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Component);
