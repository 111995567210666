//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import styles           from './styles.module.scss';
import PropTypes        from '../../PropTypes';
import ComponentHelper  from '../../../helper/ComponentHelper';
import DotListMenuTheme from '../DotListMenu/DotListMenuTheme';
import classNames       from 'classnames';

class DotListMenuItem extends React.Component {
    render () {
        return (
            <li
                className={classNames(
                    styles.wrapper,
                    {
                        [styles.wrapperThemeBig]:   this.props.theme === DotListMenuTheme.big,
                        [styles.wrapperThemeSmall]: this.props.theme === DotListMenuTheme.small,
                    },
                )}
                onClick={this.props.onClick}
            >
                {this.props.text}
                {this.renderSubTitle()}
            </li>
        );
    }

    renderSubTitle = () => {
        if (
            this.props.theme === DotListMenuTheme.big &&
            this.props.subTitle
        ) {
            return (
                <strong>
                    {this.props.subTitle}
                </strong>
            );
        }

        return null;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = DotListMenuItem;

Component.propTypes = {
    onClick:  PropTypes.func,
    subTitle: PropTypes.string,
    text:     PropTypes.string,
    theme:    PropTypes.oneOf(Object.values(DotListMenuTheme)),
};

Component.defaultProps = {
    onClick:  PropTypes.func,
    subTitle: '',
    text:     '',
    theme:    DotListMenuTheme.small,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;