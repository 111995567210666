//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                            from 'react';
import ApiMode                          from '../../../constants/ApiMode';
import ComponentHelper                  from '../../../helper/ComponentHelper';
import CreateProjectOverlayAdditionPane from '../../connected/CreateProjectOverlayAdditionPane';
import CreateProjectOverlayBudgetPane   from '../../connected/CreateProjectOverlayBudgetPane';
import CreateProjectOverlayCompanyPane  from '../../connected/CreateProjectOverlayCompanyPane';
import CreateProjectOverlayGoalsPane    from '../../connected/CreateProjectOverlayGoalsPane';
import CreateProjectOverlayKeyDataPane  from '../../connected/CreateProjectOverlayKeyDataPane';
import CreateProjectOverlayReachPane    from '../../connected/CreateProjectOverlayReachPane';
import CreateProjectOverlayTimingsPane  from '../../connected/CreateProjectOverlayTimingsPane';
import I18n                             from 'i18next';
import Overlay                          from '../../connected/Overlay';
import OverlayPaneState                 from '../OverlayPane/OverlayPaneState';
import Overlays                         from '../../../constants/Overlays';
import PropTypes                        from '../../PropTypes';
import { withTranslation }              from 'react-i18next';

class Component extends React.Component {
    getOverlayPaneStateForIndex = (index) => {
        if (index < this.props.activePaneIndex) {
            return OverlayPaneState.done;
        } else if (index === this.props.activePaneIndex) {
            return OverlayPaneState.open;
        }

        return OverlayPaneState.upcoming;
    };

    // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-309
    getTitle = () => {
        if (this.props.mode === ApiMode.edit) {
            return I18n.t('editProject');
        }

        return I18n.t('createProject');
    };

    render() {
        return (
            <Overlay
                id={Overlays.createProject}
                title={this.getTitle()}
                showProgressBar={true}
                progress={this.props.progressInPercent}
            >
                <CreateProjectOverlayKeyDataPane
                    overlayPaneState={this.getOverlayPaneStateForIndex(0)}
                    upcomingDistance={0 - this.props.activePaneIndex}
                />
                <CreateProjectOverlayGoalsPane
                    overlayPaneState={this.getOverlayPaneStateForIndex(1)}
                    upcomingDistance={1 - this.props.activePaneIndex}
                />
                <CreateProjectOverlayReachPane
                    overlayPaneState={this.getOverlayPaneStateForIndex(2)}
                    upcomingDistance={2 - this.props.activePaneIndex}
                />
                <CreateProjectOverlayBudgetPane
                    overlayPaneState={this.getOverlayPaneStateForIndex(3)}
                    upcomingDistance={3 - this.props.activePaneIndex}
                />
                <CreateProjectOverlayTimingsPane
                    overlayPaneState={this.getOverlayPaneStateForIndex(4)}
                    upcomingDistance={4 - this.props.activePaneIndex}
                />
                <CreateProjectOverlayAdditionPane
                    overlayPaneState={this.getOverlayPaneStateForIndex(5)}
                    upcomingDistance={5 - this.props.activePaneIndex}
                />
                {this.renderCompanyPane()}
            </Overlay>
        );
    }

    renderCompanyPane = () => {
        if (this.props.mode === ApiMode.create) {
            return (
                <CreateProjectOverlayCompanyPane
                    overlayPaneState={this.getOverlayPaneStateForIndex(6)}
                    upcomingDistance={6 - this.props.activePaneIndex}
                />
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {
    activePaneIndex:   PropTypes.number,
    mode:              PropTypes.oneOf(Object.keys(ApiMode)),
    progressInPercent: PropTypes.number,
};

Component.defaultProps = {
    activePaneIndex:   0,
    mode:              null,
    progressInPercent: 0,
};

Component.renderAffectingProps = [
    'activePaneIndex',
    'mode',
    'progressInPercent',
];

Component.renderAffectingStates = [];

export default withTranslation()(Component);
