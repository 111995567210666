//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _             from 'lodash';
import update        from 'immutability-helper';
import { UserTypes } from '../actions/user';

const initialState = {
    user:               {
        company:   '',
        id:        '',
        iri:       '',
        firstname: '',
        name:      '',
        email:     '',
        password:  '',
        token:     null,
    },
    profile:            {
        iri:       {},
        firstname: {},
        name:      {},
        email:     {},
        password:  {},
    },
    afterLoginRoute:    null,
    ownUserEditContext: {},
};

const addUserDataIfMissing = (state, label) => {
    return update(state, {
        ownUserEditContext: {
            [label]: {
                $set: { value: null },
            },
        },
    });
};

// https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-215

export default function (state = initialState, action) {
    switch (action.type) {
        case UserTypes.EDIT_OWN_USER_DATA:
            return update(state, {
                ownUserEditContext: {
                    $set: state.profile,
                },
            });

        case UserTypes.EDIT_OWN_USER_PASSWORD:
            return update(state, {
                ownUserEditContext: {
                    $set: state.profile,
                },
            });

        case UserTypes.LOGIN_SUCCEEDED:
            return update(state, {
                user: {
                    company:   {
                        $set: action.company,
                    },
                    id:        {
                        $set: action.id,
                    },
                    iri:       {
                        $set: action.iri,
                    },
                    firstname: {
                        $set: action.firstname,
                    },
                    name:      {
                        $set: action.name,
                    },
                    password:  {
                        $set: '',
                    },
                    token:     {
                        $set: action.token,
                    },
                },
            });

        case UserTypes.FETCH_USER_SUCCESS:
        case UserTypes.UPDATE_USER_SUCCESS:
            return update(state, {
                user:    {
                    id:        {
                        $set: action.id,
                    },
                    iri:       {
                        $set: action.iri,
                    },
                    firstname: {
                        $set: action.firstname,
                    },
                    name:      {
                        $set: action.name,
                    },
                    email:     {
                        $set: action.email,
                    },
                    password:  {
                        $set: '',
                    },
                },
                profile: {
                    iri:       {
                        value: {
                            $set: action.iri,
                        },
                        group: {
                            $set: null,
                        },
                    },
                    firstname: {
                        value: {
                            $set: action.firstname,
                        },
                        group: {
                            $set: 'default',
                        },
                    },
                    name:      {
                        value: {
                            $set: action.name,
                        },
                        group: {
                            $set: 'default',
                        },
                    },
                    email:     {
                        value: {
                            $set: action.email,
                        },
                        group: {
                            $set: 'default',
                        },
                    },
                    password:  {
                        value: {
                            $set: null,
                        },
                        group: {
                            $set: 'password',
                        },
                    },
                },
            });

        case UserTypes.LOGOUT:
            return update(state, {
                $set: initialState,
            });

        case UserTypes.CLEAR_AFTER_LOGIN_ROUTE:
            return update(state, {
                afterLoginRoute: {
                    $set: initialState.afterLoginRoute,
                },
            });

        case UserTypes.SET_AFTER_LOGIN_ROUTE:
            return update(state, {
                afterLoginRoute: {
                    $set: action.afterLoginRoute,
                },
            });

        case UserTypes.SET_OWN_EDIT_USER_AS_USER:
            return update(state, {
                user: {
                    email:    {
                        $set: state.ownUserEditContext.email.value,
                    },
                    password: {
                        $set: state.ownUserEditContext.password.value,
                    },
                },
            });

        case UserTypes.REGISTER_VIDEO_PRODUCTION:
            return update(state, {
                ownUserEditContext: {
                    $set: {
                        firstname: {},
                        name:      {},
                        email:     {},
                        password:  {},
                    },
                },
            });

        case UserTypes.SET_PASSWORD:
            return update(state, {
                user: {
                    password: {
                        $set: action.password,
                    },
                },
            });

        case UserTypes.SET_E_MAIL_ADDRESS:
            return update(state, {
                user: {
                    email: {
                        $set: action.eMailAddress,
                    },
                },
            });

        case UserTypes.TEMPORARY_SET_OWN_USER_DATA:
            if (!_.get(state, ['ownUserEditContext', action.data.label], null)) {
                state = addUserDataIfMissing(state, action.data.label);
            }

            return update(state, {
                ownUserEditContext: {
                    [action.data.label]: {
                        value: {
                            $set: action.data.value,
                        },
                    },
                },
            });

        case UserTypes.TEMPORARY_SET_OWN_USER_PASSWORD:
            if (!_.get(state, ['ownUserEditContext.password'], null)) {
                state = addUserDataIfMissing(state, 'password');
            }

            return update(state, {
                ownUserEditContext: {
                    password: {
                        value: {
                            $set: action.password,
                        },
                    },
                },
            });

        default:
            return state;
    }
}
