//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import activeProjectReducer     from './activeProject';
import alertBoxReducer          from './alertBox';
import branchReducer            from './branch';
import callbackRequestReducer   from './callbackRequest';
import companyPositionReducer   from './companyPosition';
import companyReducer           from './company';
import competenceReducer        from './competence';
import configurationReducer     from './configuration';
import createEditOfferReducer   from './createEditOffer';
import createEditProjectReducer from './createEditProject';
import dashboardReducer         from './dashboard';
import goalReducer              from './goal';
import languageReducer          from './language';
import licenseDurationReducer   from './licenseDuration';
import loadingReducer           from './loading';
import mainSplitPaneReducer     from './mainSplitPane';
import messageComposerReducer   from './messageComposer';
import overlayReducer           from './overlay';
import placeOfWorkReducer       from './placeOfWork';
import projectReducer           from './project';
import territoryReducer         from './territory';
import userReducer              from './user';
import videoFormatReducer       from './videoFormat';
import videoReducer             from './video';
import videoTypeReducer         from './videoType';
import { combineReducers }      from 'redux';
import { connectRouter }        from 'connected-react-router';
import { withReduxStateSync }   from 'redux-state-sync';

export default (history) => withReduxStateSync(
    combineReducers(
        {
            activeProject:     activeProjectReducer,
            alertBox:          alertBoxReducer,
            branch:            branchReducer,
            callbackRequest:   callbackRequestReducer,
            company:           companyReducer,
            companyPosition:   companyPositionReducer,
            competences:       competenceReducer,
            configuration:     configurationReducer,
            createEditOffer:   createEditOfferReducer,
            createEditProject: createEditProjectReducer,
            dashboard:         dashboardReducer,
            goal:              goalReducer,
            languages:         languageReducer,
            licenseDuration:   licenseDurationReducer,
            loading:           loadingReducer,
            mainSplitPane:     mainSplitPaneReducer,
            messageComposer:   messageComposerReducer,
            overlay:           overlayReducer,
            placeOfWork:       placeOfWorkReducer,
            project:           projectReducer,
            territory:         territoryReducer,
            user:              userReducer,
            video:             videoReducer,
            videoFormat:       videoFormatReducer,
            videoType:         videoTypeReducer,
            // This key must be "router"
            // @see https://github.com/supasate/connected-react-router
            router: connectRouter(history),
        },
    ),
);
