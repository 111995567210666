//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import ComponentHelper from '../../../helper/ComponentHelper';
import LanguageHelper  from '../../../helper/Language';
import _               from 'lodash';
import IconButtonTheme from '../IconButton/IconButtonTheme';
import IconLabel       from '../IconLabel';
import Spacer          from '../Spacer';
import IconButton      from '../IconButton';
import IconType        from '../Icon/IconType';
import I18n            from 'i18next';
import IconLabelTheme  from '../IconLabel/IconLabelTheme';

class OfferOverviewContentItemContent extends React.Component {
    getLocationsLabel = () => {
        if (this.props.locations.length !== 0) {
            return I18n.t('location');
        }

        return I18n.t('locations');
    };

    render () {
        return (
            <div className={styles.wrapper}>
                <div className={styles.left}>
                    <ul>
                        <li>
                            <IconLabel
                                iconType={IconType.mapPin}
                                text={[
                                    this.renderLocations(),
                                    <br />,
                                    this.renderPlaceOfWork(),
                                ]}
                                theme={IconLabelTheme.hugeGray}
                            />
                        </li>
                        <li>
                            <IconLabel
                                iconType={IconType.globe}
                                text={[
                                    <strong key={'languagesTitle'}>
                                        {I18n.t('speaks') + ': '}
                                    </strong>,
                                    <span key={'languagesValue'}>
                                        {LanguageHelper.andString(this.props.languages)}
                                    </span>,
                                ]}
                                theme={IconLabelTheme.hugeGray}
                            />
                        </li>
                        <li>
                            <IconLabel
                                iconType={IconType.camera}
                                text={[
                                    <strong key={'competencesTitle'}>
                                        {I18n.t('competencesLong') + ': '}
                                    </strong>,
                                    <br />,
                                    <span key={'competencesValue'}>
                                        {LanguageHelper.andString(this.props.competences)}
                                    </span>,
                                ]}
                                theme={IconLabelTheme.hugeGray}
                            />
                        </li>
                    </ul>
                </div>
                <div className={styles.right}>
                    <IconButton
                        key={'message'}
                        iconType={IconType.messageSquare}
                        onClick={this.props.sendMessageButtonPressed}
                        text={'Nachricht schreiben'}
                        theme={IconButtonTheme.turquoiseGhost}
                    />
                    <Spacer height={18} />
                    <IconButton
                        key={'offer'}
                        iconType={IconType.checkCircle}
                        onClick={this.props.viewOfferButtonPressed}
                        text={'Angebot ansehen'}
                        theme={IconButtonTheme.turquoiseGhost}
                    />
                </div>
            </div>
        );
    }

    renderLocations = () => {
        return (
            <>
                <strong>
                    {this.getLocationsLabel()}
                    {': '}
                </strong>
                <span>
                    {LanguageHelper.andString(this.props.locations)}
                </span>
            </>
        );
    };

    renderPlaceOfWork = () => {
        if (this.props.placeOfWork) {
            return (
                <>
                    <strong>
                        {I18n.t('placeOfWork')}
                        {': '}
                    </strong>
                    <span>
                        {this.props.placeOfWork}
                    </span>
                </>
            );
        }

        return null;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = OfferOverviewContentItemContent;

Component.propTypes = {
    competences:              PropTypes.arrayOf(PropTypes.string),
    languages:                PropTypes.arrayOf(PropTypes.string),
    locations:                PropTypes.arrayOf(PropTypes.string),
    placeOfWork:              PropTypes.string,
    sendMessageButtonPressed: PropTypes.func,
    viewOfferButtonPressed:   PropTypes.func,
};

Component.defaultProps = {
    competences:              [],
    languages:                [],
    locations:                [],
    placeOfWork:              null,
    sendMessageButtonPressed: _.noop,
    viewOfferButtonPressed:   _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;