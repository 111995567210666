//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import { connect }            from 'react-redux';
import PropTypes              from '../../PropTypes';
import _                      from 'lodash';
import DropDown               from '../../stateless/DropDown';
import ComponentHelper        from '../../../helper/ComponentHelper';
import { bindActionCreators } from 'redux';
import { BranchActions }      from '../../../store/actions/branch';

class BranchDropDown extends React.Component {
    getBranches = () => {
        return DropDown.mapHydraDataForValueField(
            this.props.branches,
            'name',
            true,
        );
    };

    render() {
        return (
            <DropDown
                forceFlagAsFilled={true}
                noMarginBottom={this.props.noMarginBottom}
                onClear={this.props.onClear}
                onChange={this.props.onChange}
                options={this.getBranches()}
                placeholder={this.props.placeholder}
                selectedValue={this.props.selectedValue}
                onRefreshDataClicked={this.props.fetchBranches}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = BranchDropDown;

Component.propTypes = {
    branches:       PropTypes.array,
    fetchBranches:  PropTypes.func,
    noMarginBottom: PropTypes.bool,
    onChange:       PropTypes.func,
    onClear:        PropTypes.func,
    placeholder:    PropTypes.string,
    selectedValue:  PropTypes.string,
};

Component.defaultProps = {
    branches:       [],
    fetchBranches:  _.noop,
    noMarginBottom: false,
    onChange:       _.noop,
    onClear:        _.noop,
    placeholder:    null,
    selectedValue:  null,
};

Component.renderAffectingProps = [
    'branches',
    'noMarginBottom',
    'placeholder',
    'selectedValue',
];

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        branches: state.branch.branches,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        BranchActions,
    ),
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
