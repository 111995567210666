//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import AlertBoxManager from '../../connected/AlertBoxManager';
import ComponentHelper from '../../../helper/ComponentHelper';
import GifWrapper      from '../GifWrapper';
import IconButton      from '../IconButton';
import IconButtonTheme from '../IconButton/IconButtonTheme';
import PropTypes       from '../../PropTypes';
import styles          from './styles.module.scss';

class NoDataAvailablePlaceholder extends React.Component {
    render() {
        return (
            <div className={styles.container}>
                <div className={styles.containerAlertBox}>
                    <AlertBoxManager />
                </div>
                <GifWrapper imageSource={require('../../../assets/images/confused-vincent-vega.gif')} />
                <div className={styles.textWrapper}>
                    {this.renderTitle()}
                    {this.props.subTitle}
                </div>
                {this.renderButton()}
            </div>
        );
    }

    renderButton = () => {
        if (this.props.buttonText) {
            return (
                <div className={styles.buttonWrapper}>
                    <IconButton
                        onClick={this.props.onButtonClick}
                        text={this.props.buttonText}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={this.props.buttonTo}
                    />
                </div>
            );
        }

        return null;
    };

    renderTitle = () => {
        if (this.props.title) {
            return (
                <h3>
                    {this.props.title}
                </h3>
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = NoDataAvailablePlaceholder;

Component.propTypes = {
    buttonText:    PropTypes.string,
    buttonTo:      PropTypes.string,
    onButtonClick: PropTypes.func,
    title:         PropTypes.string,
    subTitle:      PropTypes.string,
};

Component.defaultProps = {
    buttonText:    null,
    buttonTo:      null,
    onButtonClick: null,
    title:         null,
    subTitle:      '',
};

Component.renderAffectingProps = [
    'buttonText',
    'buttonTo',
    'title',
    'subTitle',
];

Component.renderAffectingStates = [];

export default Component;
