//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                      from 'react';
import { connect }                from 'react-redux';
import PropTypes                  from '../../PropTypes';
import _                          from 'lodash';
import I18n                       from 'i18next';
import { bindActionCreators }     from 'redux';
import { CreateEditOfferActions } from '../../../store/actions/createEditOffer';
import FormRow                    from '../../stateless/FormRow';
import { AlertBoxActions }        from '../../../store/actions/alertBox';
import FileManager                from '../../stateless/FileManager';
import IconType                   from '../../stateless/Icon/IconType';
import OverlayPane                from '../../stateless/OverlayPane';
import { v4 as uuidv4 }           from 'uuid';
import NumberFormat               from '../../../helper/NumberFormat';

class CreateOfferOverlayAdditionalFilesPane extends React.Component {
    fileDeletionButtonPressed = (index, file) => {
        this.props.deleteMatchFile({
            id: file.id,
        });
    };

    getFiles = () => {
        const files = Object.values(this.props.files);

        return files;
    };

    render() {
        return (
            <OverlayPane
                content={this.renderContent()}
                title={I18n.t('otherDocuments')}
                scrollingAllowed={false}
                showErrors={false}
            />
        );
    }

    renderContent = () => {
        return this.renderContentOfferFiles();
    };

    renderContentOfferFiles = () => {
        const files = this.getFiles();

        return (
            <FormRow
                label={I18n.t('otherDocumentsTip')}
                tooltipText={I18n.t('otherDocumentsTipText')}
            >
                <FileManager
                    addFileButtonIcon={IconType.fileText}
                    addFileButtonText={I18n.t('fileAddButton')}
                    deleteButtonPressed={this.fileDeletionButtonPressed}
                    files={files}
                    tip={I18n.t(
                        'fileSizeLimitText',
                        {
                            humanReadableFileSize: NumberFormat.toHumanReadableFileSize(this.props.maxUploadSizeInBytes, true),
                        },
                    )}
                    uploadFileCallback={this.uploadFileCallback}
                />
            </FormRow>
        );
    };

    uploadFileCallback = (file) => {
        const newId = uuidv4();

        this.props.uploadMatchFile({
            file,
            id: newId,
        });
    };
}

const Component = CreateOfferOverlayAdditionalFilesPane;

Component.propTypes = {
    maxUploadSizeInBytes: PropTypes.number,
};

Component.defaultProps = {
    maxUploadSizeInBytes: 0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    AlertBoxActions,
    CreateEditOfferActions,
), dispatch);

const mapStateToProps = (state, ownProps) => {
    return {
        files:                _.get(state, 'createEditOffer.matchFiles', []),
        maxUploadSizeInBytes: _.get(state, 'configuration.configuration.maxUploadSizeInBytes', 0),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
