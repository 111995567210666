//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import classNames      from 'classnames';
import Icon            from '../Icon';
import IconLabelTheme  from './IconLabelTheme';
import IconType        from '../Icon/IconType';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';
import ComponentHelper from '../../../helper/ComponentHelper';

class IconLabel extends React.Component {
    getText = () => {
        if (this.props.text) {
            return this.props.text;
        }

        if (this.props.fallbackText) {
            return this.props.fallbackText;
        }

        return null;
    };

    render() {
        const text = this.getText();

        return (
            <div
                className={classNames(
                    styles.wrapper,
                    {
                        [styles.wrapperDisabled]:  this.props.disabled,
                        [styles.wrapperBigWhite]:  this.props.theme === IconLabelTheme.bigWhite,
                        [styles.wrapperHugeGray]:  this.props.theme === IconLabelTheme.hugeGray,
                        [styles.wrapperSmallGray]: this.props.theme === IconLabelTheme.smallGray,
                    },
                )}
            >
                <span className={styles.iconWrapper}>
                    <Icon iconType={this.props.iconType} />
                </span>
                <span
                    className={classNames(
                        styles.text,
                        {
                            [styles.textUndefined]: !this.props.text && this.props.fallbackText,
                        },
                    )}
                >
                    {text}
                </span>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = IconLabel;

Component.propTypes = {
    disabled:     PropTypes.bool,
    fallbackText: PropTypes.string,
    iconType:     PropTypes.oneOf(Object.values(IconType)),
    text:         PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    theme:        PropTypes.oneOf(Object.values(IconLabelTheme)),
};

Component.defaultProps = {
    disabled:     false,
    fallbackText: '',
    iconType:     null,
    text:         '',
    theme:        null,
};

Component.renderAffectingProps = [
    'disabled',
    'fallbackText',
    'iconType',
    'text',
    'theme',
];

Component.renderAffectingStates = [];

export default Component;
