//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

class Window {
    static getWindowDimensions = () => {
        const width  = window.innerWidth;
        const height = window.innerHeight;

        return {
            width,
            height,
        };
    };

    static isMobile = () => {
        const { width } = Window.getWindowDimensions();

        return width < 1200;
    };
}

export default Window;
