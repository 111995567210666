//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as Api                   from '../../api';
import _                          from 'lodash';
import ApiMode                    from '../../constants/ApiMode';
import Cast                       from '../../helper/Cast';
import update                     from 'immutability-helper';
import { CreateEditProjectTypes } from '../actions/createEditProject';
import { OverlayTypes }           from '../actions/overlay';
import { UserTypes }              from '../actions/user';

const initialState = {
    advertisingBudgedPlanned:                   null,
    advertisingBudgetRange:                     {
        end:   20000,
        start: 10000,
    },
    activePaneIndex:                            0,
    branch:                                     null,
    budgetRange:                                {
        end:   20000,
        start: 1000,
    },
    budgetRangeUnknown:                         true,
    description:                                null,
    fixedReleaseDate:                           null,
    fixedReleaseDateAvailable:                  null,
    fixedReleaseDateIsSameAsWishCompletionDate: null,
    goal:                                       null,
    licenseDuration:                            null,
    licenseDurationIsOther:                     null,
    licenseDurationText:                        null,
    mode:                                       ApiMode.create,
    projectIri:                                 null,
    progressInPercent:                          0,
    targetGroupAgeRange:                        {
        end:   99,
        start: 1,
    },
    targetGroupAgeRangeUnknown:                 true,
    targetGroupType:                            null,
    territory:                                  null,
    territoryIsOther:                           null,
    territoryText:                              null,
    title:                                      null,
    videoFormats:                               [],
    videoType:                                  null,
    videoWillBePublished:                       null,
    wishCompletionDate:                         null,
    wishCompletionDateUnknown:                  true,
    wishedProductionPeriod:                     {
        endDate:   null,
        startDate: null,
    },
    wishProductionPeriodDateUnknown:            true,
};

const initialStateEdit = _.cloneDeep(initialState);
initialStateEdit.mode  = ApiMode.edit;

const calculateProgressInPercentForStage = (state) => {
    let goalsToReach = [];
    let maxPaneIndex = _.get(state, 'maxPaneIndex', 7);

    for (let paneIndex = 0; paneIndex < maxPaneIndex; ++paneIndex) {
        goalsToReach.push({
            goal:   'paneReached' + paneIndex,
            result: paneIndex < state.activePaneIndex,
        });
    }

    calculateProgressInPercentForStageBudget(state, goalsToReach);
    calculateProgressInPercentForStageGoals(state, goalsToReach);
    calculateProgressInPercentForStageKeyData(state, goalsToReach);
    calculateProgressInPercentForStageReach(state, goalsToReach);
    calculateProgressInPercentForStageTimings(state, goalsToReach);

    const goalsReached      = _.filter(
        goalsToReach,
        {
            result: true,
        },
    );
    const goalsReachedCount = goalsReached.length;
    const goalCount         = goalsToReach.length;
    const progressInPercent = goalsReachedCount / goalCount * 100;

    return update(state, {
        progressInPercent: {
            $set: progressInPercent,
        },
    });
};

const calculateProgressInPercentForStageBudget = (state, goalsToReach) => {
    // All fields are optional or prefilled here
};

const calculateProgressInPercentForStageGoals = (state, goalsToReach) => {
    goalsToReach.push({
        goal:   'targetGroupType',
        result: Cast.bool(state.targetGroupType),
    });
};

const calculateProgressInPercentForStageKeyData = (state, goalsToReach) => {
    goalsToReach.push({
        goal:   'title',
        result: Cast.bool(state.title),
    });

    goalsToReach.push({
        goal:   'videoType',
        result: Cast.bool(state.videoType),
    });
};

const calculateProgressInPercentForStageReach = (state, goalsToReach) => {
    goalsToReach.push({
        goal:   'videoWillBePublished',
        result: state.videoWillBePublished !== null,
    });

    goalsToReach.push({
        goal:   'videoFormats',
        result: state.videoFormats.length > 0,
    });

    if (state.territoryIsOther) {
        goalsToReach.push({
            goal:   'territoryText',
            result: Cast.bool(state.territoryText),
        });
    }

    if (state.licenseDurationIsOther) {
        goalsToReach.push({
            goal:   'licenseDurationText',
            result: Cast.bool(state.licenseDurationText),
        });
    }
};

const calculateProgressInPercentForStageTimings = (state, goalsToReach) => {
    if (state.fixedReleaseDateAvailable === true) {
        goalsToReach.push({
            goal:   'fixedReleaseDate',
            result: Cast.bool(state.fixedReleaseDate),
        });
    }
};

const createProjectSuccess = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

const editProject = (action, state) => {
    return calculateProgressInPercentForStage(update(state, {
        activePaneIndex:          {
            $set: initialState.activePaneIndex,
        },
        advertisingBudgedPlanned: {
            $set: _.get(action.project, 'advertisingBudgetPlanned', initialState.advertisingBudgedPlanned),
        },
        advertisingBudgetRange:   {
            end:   {
                $set: _.get(action.project, 'advertisingBudgetRange.to') || initialState.advertisingBudgetRange.end,
            },
            start: {
                $set: _.get(action.project, 'advertisingBudgetRange.from') || initialState.advertisingBudgetRange.start,
            },
        },
        branch:                   {
            $set: _.get(action.project, 'branch.iri', initialState.branch),
        },
        // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-274
        budgetRange:                                {
            end:   {
                $set: _.get(action.project, 'budgetRange.to') || initialState.budgetRange.end,
            },
            start: {
                $set: _.get(action.project, 'budgetRange.from') || initialState.budgetRange.start,
            },
        },
        budgetRangeUnknown:                         {
            $set: (
                      _.get(action.project, 'budgetRange.to', 0) +
                      _.get(action.project, 'budgetRange.from', 0) === 0
                  ),
        },
        description:                                {
            $set: _.get(action.project, 'description', initialState.description),
        },
        fixedReleaseDate:                           {
            $set: _.get(action.project, 'fixedReleaseDate', initialState.fixedReleaseDate),
        },
        fixedReleaseDateAvailable:                  {
            $set: Cast.bool(_.get(action.project, 'fixedReleaseDate', null)),
        },
        fixedReleaseDateIsSameAsWishCompletionDate: {
            $set: _.get(
                action.project,
                'fixedReleaseDateIsSameAsWishCompletionDate',
                initialState.fixedReleaseDateIsSameAsWishCompletionDate,
            ),
        },
        // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-273
        goal:                            {
            $set: _.get(action.project, 'goal.iri', initialState.goal),
        },
        licenseDuration:                 {
            $set: _.get(action.project, 'licenseDuration.iri', initialState.licenseDuration),
        },
        licenseDurationIsOther:          {
            $set: _.get(action.project, 'licenseDuration.otherLicenseDuration', initialState.licenseDurationIsOther),
        },
        licenseDurationText:             {
            $set: _.get(action.project, 'licenseDurationText', initialState.licenseDurationText),
        },
        mode:                            {
            $set: ApiMode.edit,
        },
        projectIri:                      {
            $set: action.project.iri,
        },
        targetGroupAgeRange:             {
            end:   {
                $set: _.get(action.project, 'targetGroupAgeRange.to') || initialState.targetGroupAgeRange.end,
            },
            start: {
                $set: _.get(action.project, 'targetGroupAgeRange.from') || initialState.targetGroupAgeRange.start,
            },
        },
        targetGroupAgeRangeUnknown:      {
            $set: (
                      _.get(action.project, 'targetGroupAgeRange.to', 0) +
                      _.get(action.project, 'targetGroupAgeRange.from', 0) === 0
                  ),
        },
        targetGroupType:                 {
            $set: _.get(action.project, 'targetGroupType', null),
        },
        territory:                       {
            $set: _.get(action.project, 'territory.iri', initialState.territory),
        },
        territoryIsOther:                {
            $set: _.get(action.project, 'territory.otherTerritory', initialState.territoryIsOther),
        },
        territoryText:                   {
            $set: _.get(action.project, 'territoryText', initialState.territoryText),
        },
        title:                           {
            $set: _.get(action.project, 'title', initialState.title),
        },
        videoFormats:                    {
            $set: Api.getIriListFromUploadedFileList(_.get(action.project, 'videoFormats', initialState.videoFormats)),
        },
        videoType:                       {
            $set: _.get(action.project, 'videoType.iri', initialState.videoType),
        },
        videoWillBePublished:            {
            $set: _.get(action.project, 'videoWillBePublished', initialState.videoWillBePublished),
        },
        wishCompletionDate:              {
            $set: _.get(action.project, 'wishCompletionDate', initialState.wishCompletionDate),
        },
        wishCompletionDateUnknown:       {
            $set: _.get(action.project, 'wishCompletionDate', null) === null,
        },
        wishedProductionPeriod:          {
            endDate:   {
                $set: _.get(
                    action.project,
                    'wishedProductionPeriodEnd',
                    initialState.wishedProductionPeriod.endDate,
                ),
            },
            startDate: {
                $set: _.get(
                    action.project,
                    'wishedProductionPeriodStart',
                    initialState.wishedProductionPeriod.startDate,
                ),
            },
        },
        wishProductionPeriodDateUnknown: {
            $set: _.get(action.project, 'wishedProductionPeriod', null) === null,
        },
    }));
};

const createProject = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

const nextPane = (action, state) => {
    let nextPaneIndex = _.get(state, 'activePaneIndex', 0) + 1;
    let maxPaneIndex  = _.get(state, 'maxPaneIndex', 7);

    if (nextPaneIndex < maxPaneIndex) {
        return calculateProgressInPercentForStage(update(state, {
            activePaneIndex: {
                $set: nextPaneIndex,
            },
        }));
    }

    return state;
};

const overlayClosed = (action, state) => {
    // Don't reset the state if the user closed the "create project" overlay
    if (state.mode === ApiMode.edit) {
        return update(state, {
            $set: initialStateEdit,
        });
    }

    return state;
};

const previousPane = (action, state) => {
    let previousPaneIndex = _.get(state, 'activePaneIndex', 0) - 1;

    if (previousPaneIndex >= 0) {
        return calculateProgressInPercentForStage(update(state, {
            activePaneIndex: {
                $set: previousPaneIndex,
            },
        }));
    }

    return state;
};

const resetPane = (action, state) => {
    return calculateProgressInPercentForStage(update(state, {
        activePaneIndex: {
            $set: 0,
        },
    }));
};

const setAdvertisingBudgetPlanned = (action, state) => {
    return update(state, {
        advertisingBudgedPlanned: {
            $set: action.planned,
        },
    });
};

const setAdvertisingBudgetRange = (action, state) => {
    return update(state, {
        advertisingBudgetRange:        {
            end:   {
                $set: action.end,
            },
            start: {
                $set: action.start,
            },
        },
        advertisingBudgetRangeUnknown: {
            $set: false,
        },
    });
};

const setAdvertisingBudgetRangeEnd = (action, state) => {
    return update(state, {
        advertisingBudgetRange:        {
            end: {
                $set: action.end,
            },
        },
        advertisingBudgetRangeUnknown: {
            $set: false,
        },
    });
};

const setAdvertisingBudgetRangeStart = (action, state) => {
    return update(state, {
        advertisingBudgetRange:        {
            start: {
                $set: action.start,
            },
        },
        advertisingBudgetRangeUnknown: {
            $set: false,
        },
    });
};

const setBudgetRange = (action, state) => {
    return update(state, {
        budgetRange:        {
            end:   {
                $set: action.end,
            },
            start: {
                $set: action.start,
            },
        },
        budgetRangeUnknown: {
            $set: false,
        },
    });
};

const setBudgetRangeEnd = (action, state) => {
    return update(state, {
        budgetRange:        {
            end: {
                $set: action.end,
            },
        },
        budgetRangeUnknown: {
            $set: false,
        },
    });
};

const setBudgetRangeStart = (action, state) => {
    return update(state, {
        budgetRange:        {
            start: {
                $set: action.start,
            },
        },
        budgetRangeUnknown: {
            $set: false,
        },
    });
};

const setBudgetRangeUnknown = (action, state) => {
    return update(state, {
        targetGroupAgeRangeUnknown: {
            $set: true,
        },
    });
};

const setBranch = (action, state) => {
    return update(state, {
        branch: {
            $set: action.branch,
        },
    });
};

const setDescription = (action, state) => {
    return update(state, {
        description: {
            $set: action.description,
        },
    });
};

const setFixedReleaseDate = (action, state) => {
    return calculateProgressInPercentForStage(update(state, {
        fixedReleaseDate: {
            $set: action.fixedReleaseDate,
        },
    }));
};

const setFixedReleaseDateAvailable = (action, state) => {
    return calculateProgressInPercentForStage(update(state, {
        fixedReleaseDateAvailable:                  {
            $set: action.fixedReleaseDateAvailable,
        },
        fixedReleaseDateIsSameAsWishCompletionDate: {
            $set: null,
        },
    }));
};

const setFixedReleaseDateIsSameAsWishCompletionDate = (action, state) => {
    return calculateProgressInPercentForStage(update(state, {
        fixedReleaseDateAvailable:                  {
            $set: null,
        },
        fixedReleaseDateIsSameAsWishCompletionDate: {
            $set: action.fixedReleaseDateIsSameAsWishCompletionDate,
        },
    }));
};

const setGoal = (action, state) => {
    return update(state, {
        goal: {
            $set: action.goal,
        },
    });
};

const setLicenseDuration = (action, state) => {
    return calculateProgressInPercentForStage(update(state, {
        licenseDuration: {
            $set: action.licenseDurationIri,
        },
    }));
};

const setLicenseDurationIsOther = (action, state) => {
    return calculateProgressInPercentForStage(update(state, {
        licenseDurationIsOther: {
            $set: action.isOther,
        },
    }));
};

const setLicenseDurationText = (action, state) => {
    return calculateProgressInPercentForStage(update(state, {
        licenseDurationText: {
            $set: action.licenseDurationText,
        },
    }));
};

const setMaxPaneIndex = (action, state) => {
    return update(state, {
        maxPaneIndex: {
            $set: action.index,
        },
    });
};

const setPaneIndex = (action, state) => {
    return update(state, {
        activePaneIndex: {
            $set: action.index,
        },
    });
};

const setTargetGroupAgeRange = (action, state) => {
    return update(state, {
        targetGroupAgeRange:        {
            end:   {
                $set: action.end,
            },
            start: {
                $set: action.start,
            },
        },
        targetGroupAgeRangeUnknown: {
            $set: false,
        },
    });
};

const setTargetGroupAgeRangeEnd = (action, state) => {
    return update(state, {
        targetGroupAgeRange:        {
            end: {
                $set: action.end,
            },
        },
        targetGroupAgeRangeUnknown: {
            $set: false,
        },
    });
};

const setTargetGroupAgeRangeStart = (action, state) => {
    return update(state, {
        targetGroupAgeRange:        {
            start: {
                $set: action.start,
            },
        },
        targetGroupAgeRangeUnknown: {
            $set: false,
        },
    });
};

const setTargetGroupAgeRangeUnknown = (action, state) => {
    return update(state, {
        targetGroupAgeRangeUnknown: {
            $set: true,
        },
    });
};

const setTargetGroupType = (action, state) => {
    return calculateProgressInPercentForStage(update(state, {
        targetGroupType: {
            $set: action.targetGroupType,
        },
    }));
};

const setTerritory = (action, state) => {
    return calculateProgressInPercentForStage(update(state, {
        territory: {
            $set: action.territoryIri,
        },
    }));
};

const setTerritoryIsOther = (action, state) => {
    return calculateProgressInPercentForStage(update(state, {
        territoryIsOther: {
            $set: action.isOther,
        },
    }));
};

const setTerritoryText = (action, state) => {
    return calculateProgressInPercentForStage(update(state, {
        territoryText: {
            $set: action.territoryText,
        },
    }));
};

const setTitle = (action, state) => {
    return calculateProgressInPercentForStage(update(state, {
        title: {
            $set: action.title,
        },
    }));
};

const setVideoType = (action, state) => {
    return calculateProgressInPercentForStage(update(state, {
        videoType: {
            $set: action.videoType,
        },
    }));
};

const setVideoWillBePublished = (action, state) => {
    return calculateProgressInPercentForStage(calculateProgressInPercentForStage(update(state, {
        videoWillBePublished: {
            $set: action.videoWillBePublished,
        },
    })));
};

const setWishCompletionDate = (action, state) => {
    return update(state, {
        wishCompletionDate:        {
            $set: action.wishCompletionDate,
        },
        wishCompletionDateUnknown: {
            $set: false,
        },
    });
};

const setWishCompletionDateUnknown = (action, state) => {
    return update(state, {
        wishCompletionDateUnknown: {
            $set: true,
        },
    });
};

const setWishedProductionPeriod = (action, state) => {
    return update(state, {
        wishedProductionPeriod:          {
            endDate:   {
                $set: action.endDate,
            },
            startDate: {
                $set: action.startDate,
            },
        },
        wishProductionPeriodDateUnknown: {
            $set: false,
        },
    });
};

const setWishedProductionPeriodEnd = (action, state) => {
    return update(state, {
        wishedProductionPeriod:          {
            endDate: {
                $set: action.endDate,
            },
        },
        wishProductionPeriodDateUnknown: {
            $set: false,
        },
    });
};

const setWishedProductionPeriodStart = (action, state) => {
    return update(state, {
        wishedProductionPeriod:          {
            startDate: {
                $set: action.startDate,
            },
        },
        wishProductionPeriodDateUnknown: {
            $set: false,
        },
    });
};

const setWishProductionPeriodDateUnknown = (action, state) => {
    return update(state, {
        wishProductionPeriodDateUnknown: {
            $set: true,
        },
    });
};

const toggleBudgetRangeUnknown = (action, state) => {
    return update(state, {
        $toggle: ['budgetRangeUnknown'],
    });
};

const toggleTargetGroupAgeRangeUnknown = (action, state) => {
    return update(state, {
        $toggle: ['targetGroupAgeRangeUnknown'],
    });
};

const toggleWishCompletionDateUnknown = (action, state) => {
    return update(state, {
        $toggle: ['wishCompletionDateUnknown'],
    });
};

const toggleWishProductionPeriodDateUnknown = (action, state) => {
    return update(state, {
        $toggle: ['wishProductionPeriodDateUnknown'],
    });
};

const toggleVideoFormat = (action, state) => {
    const videoFormatIndex = state.videoFormats.indexOf(action.videoFormatIri);

    if (videoFormatIndex > -1) {
        return calculateProgressInPercentForStage(update(state, {
            videoFormats: {
                $splice: [
                    [
                        videoFormatIndex,
                        1,
                    ],
                ],
            },
        }));
    }

    return calculateProgressInPercentForStage(update(state, {
        videoFormats: {
            $push: [
                action.videoFormatIri,
            ],
        },
    }));
};

const updateProjectSuccess = (action, state) => {
    return update(state, {
        $set: initialStateEdit,
    });
};

export default function (state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case CreateEditProjectTypes.CREATE_PROJECT_SUCCESS:                                 return createProjectSuccess(action, state);
        case CreateEditProjectTypes.EDIT_PROJECT:                                           return editProject(action, state);
        case CreateEditProjectTypes.NEW_PROJECT_OPEN:                                       return createProject(action, state);
        case UserTypes.REGISTER_COMPANY:                                                    return createProject(action, state);
        case CreateEditProjectTypes.NEXT_PANE:                                              return nextPane(action, state);
        case CreateEditProjectTypes.PREVIOUS_PANE:                                          return previousPane(action, state);
        case CreateEditProjectTypes.RESET_PANE:                                             return resetPane(action, state);
        case CreateEditProjectTypes.SET_ADVERTISING_BUDGET_PLANNED:                         return setAdvertisingBudgetPlanned(action, state);
        case CreateEditProjectTypes.SET_ADVERTISING_BUDGET_RANGE:                           return setAdvertisingBudgetRange(action, state);
        case CreateEditProjectTypes.SET_ADVERTISING_BUDGET_RANGE_END:                       return setAdvertisingBudgetRangeEnd(action, state);
        case CreateEditProjectTypes.SET_ADVERTISING_BUDGET_RANGE_START:                     return setAdvertisingBudgetRangeStart(action, state);
        case CreateEditProjectTypes.SET_BRANCH:                                             return setBranch(action, state);
        case CreateEditProjectTypes.SET_BUDGET_RANGE:                                       return setBudgetRange(action, state);
        case CreateEditProjectTypes.SET_BUDGET_RANGE_END:                                   return setBudgetRangeEnd(action, state);
        case CreateEditProjectTypes.SET_BUDGET_RANGE_START:                                 return setBudgetRangeStart(action, state);
        case CreateEditProjectTypes.SET_BUDGET_RANGE_UNKNOWN:                               return setBudgetRangeUnknown(action, state);
        case CreateEditProjectTypes.SET_DESCRIPTION:                                        return setDescription(action, state);
        case CreateEditProjectTypes.SET_FIXED_RELEASE_DATE:                                 return setFixedReleaseDate(action, state);
        case CreateEditProjectTypes.SET_FIXED_RELEASE_DATE_AVAILABLE:                       return setFixedReleaseDateAvailable(action, state);
        case CreateEditProjectTypes.SET_FIXED_RELEASE_DATE_IS_SAME_AS_WISH_COMPLETION_DATE: return setFixedReleaseDateIsSameAsWishCompletionDate(action, state);
        case CreateEditProjectTypes.SET_GOAL:                                               return setGoal(action, state);
        case CreateEditProjectTypes.SET_LICENSE_DURATION:                                   return setLicenseDuration(action, state);
        case CreateEditProjectTypes.SET_LICENSE_DURATION_IS_OTHER:                          return setLicenseDurationIsOther(action, state);
        case CreateEditProjectTypes.SET_LICENSE_DURATION_TEXT:                              return setLicenseDurationText(action, state);
        case CreateEditProjectTypes.SET_MAX_PANE_INDEX:                                     return setMaxPaneIndex(action, state);
        case CreateEditProjectTypes.SET_PANE_INDEX:                                         return setPaneIndex(action, state);
        case CreateEditProjectTypes.SET_TARGET_GROUP_AGE_RANGE:                             return setTargetGroupAgeRange(action, state);
        case CreateEditProjectTypes.SET_TARGET_GROUP_AGE_RANGE_END:                         return setTargetGroupAgeRangeEnd(action, state);
        case CreateEditProjectTypes.SET_TARGET_GROUP_AGE_RANGE_START:                       return setTargetGroupAgeRangeStart(action, state);
        case CreateEditProjectTypes.SET_TARGET_GROUP_AGE_RANGE_UNKNOWN:                     return setTargetGroupAgeRangeUnknown(action, state);
        case CreateEditProjectTypes.SET_TARGET_GROUP_TYPE:                                  return setTargetGroupType(action, state);
        case CreateEditProjectTypes.SET_TERRITORY:                                          return setTerritory(action, state);
        case CreateEditProjectTypes.SET_TERRITORY_IS_OTHER:                                 return setTerritoryIsOther(action, state);
        case CreateEditProjectTypes.SET_TERRITORY_TEXT:                                     return setTerritoryText(action, state);
        case CreateEditProjectTypes.SET_TITLE:                                              return setTitle(action, state);
        case CreateEditProjectTypes.SET_VIDEO_TYPE:                                         return setVideoType(action, state);
        case CreateEditProjectTypes.SET_VIDEO_WILL_BE_PUBLISHED:                            return setVideoWillBePublished(action, state);
        case CreateEditProjectTypes.SET_WISH_COMPLETION_DATE:                               return setWishCompletionDate(action, state);
        case CreateEditProjectTypes.SET_WISH_COMPLETION_DATE_UNKNOWN:                       return setWishCompletionDateUnknown(action, state);
        case CreateEditProjectTypes.SET_WISHED_PRODUCTION_PERIOD:                           return setWishedProductionPeriod(action, state);
        case CreateEditProjectTypes.SET_WISHED_PRODUCTION_PERIOD_END:                       return setWishedProductionPeriodEnd(action, state);
        case CreateEditProjectTypes.SET_WISHED_PRODUCTION_PERIOD_START:                     return setWishedProductionPeriodStart(action, state);
        case CreateEditProjectTypes.SET_WISHED_PRODUCTION_PERIOD_UNKNOWN:                   return setWishProductionPeriodDateUnknown(action, state);
        case CreateEditProjectTypes.TOGGLE_BUDGET_RANGE_UNKNOWN:                            return toggleBudgetRangeUnknown(action, state);
        case CreateEditProjectTypes.TOGGLE_TARGET_GROUP_AGE_RANGE_UNKNOWN:                  return toggleTargetGroupAgeRangeUnknown(action, state);
        case CreateEditProjectTypes.TOGGLE_VIDEO_FORMAT:                                    return toggleVideoFormat(action, state);
        case CreateEditProjectTypes.TOGGLE_WISH_COMPLETION_DATE_UNKNOWN:                    return toggleWishCompletionDateUnknown(action, state);
        case CreateEditProjectTypes.TOGGLE_WISH_PRODUCTION_PERIOD_UNKNOWN:                  return toggleWishProductionPeriodDateUnknown(action, state);
        case CreateEditProjectTypes.UPDATE_PROJECT_SUCCESS:                                 return updateProjectSuccess(action, state);
        case OverlayTypes.OVERLAY_CLOSED:                                                   return overlayClosed(action, state);
        default:                                                                            return state;
        // @formatter:on
    }
}
