//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update               from 'immutability-helper';
import { VideoFormatTypes } from '../actions/videoFormat';

const initialState = {
    videoFormats: [],
};

// https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-215

export default function (state = initialState, action) {
    switch (action.type) {
        case VideoFormatTypes.FETCH_VIDEO_FORMATS_SUCCESS:
            return update(state, {
                videoFormats: {
                    $set: action.videoFormats,
                },
            });

        default:
            return state;
    }
}
