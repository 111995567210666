//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const DashboardTypes = {
    SET_ACTIVE_TAB: 'Dashboard/SET_ACTIVE_TAB',
};

const setActiveTab = makeActionCreator(
    DashboardTypes.SET_ACTIVE_TAB,
    {
        tabIndex: null,
    },
);

export const DashboardActions = {
    setActiveTab,
};