//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n                              from 'i18next';
import React                             from 'react';
import { Component }                     from 'react';
import CallbackRequestBox                from '../../components/stateless/CallbackRequestBox';
import CallbackRequestBoxConnected       from '../../components/connected/CallbackRequestBox';
import CallToActionBox                   from '../../components/stateless/CallToActionBox';
import ChoiceSelector                    from '../../components/stateless/ChoiceSelector';
import ChoiceSelectorMode                from '../../components/stateless/ChoiceSelector/ChoiceSelectorMode';
import ChoiceWidthMode                   from '../../components/stateless/ChoiceSelector/ChoiceWidthMode';
import CompanyDetailsContentItemContent  from '../../components/stateless/CompanyDetailsContentItemContent';
import CompanyProfileGenericItems        from '../../components/stateless/CompanyProfileGenericItems';
import CompanyProfileGenericItemsMode    from '../../components/stateless/CompanyProfileGenericItems/CompanyProfileGenericItemsMode';
import CompanyProfileHeader              from '../../components/stateless/CompanyProfileHeader';
import CompanyProfileImages              from '../../components/stateless/CompanyProfileImages';
import CompanyProfileInformation         from '../../components/stateless/CompanyProfileInformation';
import CompanyProfileReferences          from '../../components/stateless/CompanyProfileReferences';
import CompanyProfileSocialMedia         from '../../components/stateless/CompanyProfileSocialMedia';
import ContentItem                       from '../../components/stateless/ContentItem';
import ContentWrapper                    from '../../components/stateless/ContentWrapper';
import DotListMenu                       from '../../components/stateless/DotListMenu';
import DotListMenuAlignment              from '../../components/stateless/DotListMenu/DotListMenuAlignment';
import DotListMenuItem                   from '../../components/stateless/DotListMenuItem';
import DotListMenuTheme                  from '../../components/stateless/DotListMenu/DotListMenuTheme';
import DropDown                          from '../../components/stateless/DropDown';
import FileList                          from '../../components/stateless/FileList';
import FileListMode                      from '../../components/stateless/FileList/FileListMode';
import FormRow                           from '../../components/stateless/FormRow';
import IconButton                        from '../../components/stateless/IconButton';
import IconButtonTheme                   from '../../components/stateless/IconButton/IconButtonTheme';
import IconLabel                         from '../../components/stateless/IconLabel';
import IconLabelTheme                    from '../../components/stateless/IconLabel/IconLabelTheme';
import IconType                          from '../../components/stateless/Icon/IconType';
import Input                             from '../../components/stateless/Input';
import InputSize                         from '../../components/stateless/Input/InputSize';
import InputType                         from '../../components/stateless/Input/InputType';
import Message                           from '../../components/stateless/Message';
import MessageComposer                   from '../../components/stateless/MessageComposer';
import MessageType                       from '../../components/stateless/Message/MessageType';
import OfferOverviewContentItemContent   from '../../components/stateless/OfferOverviewContentItemContent';
import OverlayButton                     from '../../components/stateless/OverlayButton';
import OverlayButtonTheme                from '../../components/stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper              from '../../components/stateless/OverlayButtonWrapper';
import OverlayManager                    from '../../components/connected/OverlayManager';
import Overlays                          from '../../constants/Overlays';
import ProgressBar                       from '../../components/stateless/ProgressBar';
import ProjectBriefingContentItemContent from '../../components/stateless/ProjectBriefingContentItemContent';
import ProjectDetailsContentItemContent  from '../../components/stateless/ProjectDetailsContentItemContent';
import RangeInput                        from '../../components/stateless/RangeInput';
import RangeInputTheme                   from '../../components/stateless/RangeInput/RangeInputTheme';
import RangeInputType                    from '../../components/stateless/RangeInput/RangeInputType';
import RangeSlider                       from '../../components/stateless/RangeSlider';
import RangeSliderTheme                  from '../../components/stateless/RangeSlider/RangeSliderTheme';
import renderCrashButton                 from '../../sentry';
import Routes                            from '../../constants/Routes';
import SideBarMenu                       from '../../components/stateless/SideBarMenu';
import SideBarMenuItem                   from '../../components/stateless/SideBarMenuItem';
import Spacer                            from '../../components/stateless/Spacer';
import SubSideBar                        from '../../components/stateless/SubSideBar';
import TabBar                            from '../../components/stateless/TabBar';
import Tooltip                           from '../../components/stateless/Tooltip';
import TooltipPosition                   from '../../components/stateless/Tooltip/TooltipPosition';
import TooltipTheme                      from '../../components/stateless/Tooltip/TooltipTheme';

class Screen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            subSideBarVisible: true,
            toggle1Opened:     true,
            toggle2Opened:     false,
            toggle3Opened:     false,
        };
    }

    render() {
        if (this.state.subSideBarVisible) {
            return (
                <SubSideBar
                    mainContent={this.renderMainContent()}
                    sideBarContent={this.renderSubSideBarContent()}
                />
            );
        }

        return this.renderMainContent();
    }

    renderCompanyProfile = () => {
        return (
            <>
                <CompanyProfileHeader
                    company={{
                        bannerImage: require('../../assets/images/login-background.jpg'),
                    }}
                    editButtonPressed={() => {
                        console.log('edit');
                    }}
                />
                <CompanyProfileSocialMedia
                    company={{
                        name:         'Dirty Ronny UG (haftungsbeschränkt)',
                        companyType:  'Videoproduktion',
                        socialMedias: [
                            {
                                type: 'instagram',
                                url:  'https://instagram.com/dirtyronny',
                            },
                        ],
                    }}
                    editButtonPressed={() => {
                        console.log('edit');
                    }}
                />
                <Spacer height={20} />
                <CompanyProfileInformation
                    company={{
                        name:        'Dirty Ronny UG (haftungsbeschränkt)',
                        description: 'Wir sind eine Full-Service Videoproduktion und Werbeagentur für die Medien der Gegenwart & Zukunft. Wir produzieren moderne Videos und vermarkten diese zielorientiert auf sozialen Netzwerken wie bspw. Facebook, Instagram, Youtube oder im Kino. Alles aus einer Hand: Planung, Konzeption, Produktion und Postproduktion.',
                    }}
                    editButtonPressed={() => {
                        console.log('edit');
                    }}
                    showEditButton={true}
                />
                <CompanyProfileReferences
                    editButtonPressed={() => {
                        console.log('edit');
                    }}
                    playButtonPressed={(reference, index) => {
                        console.log('play button pressed', reference, index);
                    }}
                    references={[
                        {
                            videoUrl:          'https://www.youtube.com/watch?v=RnJlpDLG2tM',
                            videoThumbnailUrl: 'http://i3.ytimg.com/vi/RnJlpDLG2tM/maxresdefault.jpg',
                        },
                        {
                            videoUrl:          'https://www.youtube.com/watch?v=RnJlpDLG2tM',
                            videoThumbnailUrl: 'http://i3.ytimg.com/vi/RnJlpDLG2tM/maxresdefault.jpg',
                        },
                        {
                            videoUrl:          'https://www.youtube.com/watch?v=RnJlpDLG2tM',
                            videoThumbnailUrl: 'http://i3.ytimg.com/vi/RnJlpDLG2tM/maxresdefault.jpg',
                        },
                    ]}
                    showEditButton={true}
                />
                <CompanyProfileGenericItems
                    editButtonPressed={() => {
                        console.log('edit');
                    }}
                    items={[
                        'Realfilm',
                        'Brand Move',
                        'Realfilm',
                        'Brand Move',
                        'Realfilm',
                        'Brand Move',
                        'Realfilm',
                        'Brand Move',
                        'Realfilm',
                        'Brand Move',
                    ]}
                    mode={CompanyProfileGenericItemsMode.small}
                    showEditButton={true}
                    title={I18n.t('profileAbilitiesAndKnowledge')}
                />
                <CompanyProfileGenericItems
                    editButtonPressed={() => {
                        console.log('edit');
                    }}
                    items={[
                        'Deutsch',
                        'Englisch',
                    ]}
                    mode={CompanyProfileGenericItemsMode.fullWidth}
                    showEditButton={true}
                    title={I18n.t('profileLanguages')}
                />
                <CompanyProfileGenericItems
                    editButtonPressed={() => {
                        console.log('edit');
                    }}
                    items={[
                        'Ravensburg // Gartenstr. 37, 88212 Ravensburg',
                        'Düsseldorf // Rather Str. 1, 40476 Düsseldorf',
                    ]}
                    mode={CompanyProfileGenericItemsMode.fullWidth}
                    showEditButton={true}
                    title={I18n.t('profileLocations')}
                />
                <CompanyProfileImages
                    editButtonPressed={() => {
                        console.log('edit');
                    }}
                    images={[
                        {
                            path: 'http://i3.ytimg.com/vi/RnJlpDLG2tM/maxresdefault.jpg',
                        },
                        {
                            path: 'http://i3.ytimg.com/vi/RnJlpDLG2tM/maxresdefault.jpg',
                        },
                        {
                            path: 'http://i3.ytimg.com/vi/RnJlpDLG2tM/maxresdefault.jpg',
                        },
                        {
                            path: 'http://i3.ytimg.com/vi/RnJlpDLG2tM/maxresdefault.jpg',
                        },
                    ]}
                    showEditButton={true}
                />
            </>
        );
    };

    renderMainContent = () => {
        return (
            <ContentWrapper>
                <IconButton
                    onClick={() => {
                        this.setState({
                            subSideBarVisible: !this.state.subSideBarVisible,
                        });
                    }}
                    text={'Toggle sidebar'}
                    theme={IconButtonTheme.turquoiseGhost}
                    to={Routes.screenDesign}
                />
                {this.renderCompanyProfile()}
                <DotListMenu
                    alignment={DotListMenuAlignment.left}
                    theme={DotListMenuTheme.small}
                >
                    <DotListMenuItem
                        subTitle={'Briefing anpassen l'}
                        text={'Löschen'}
                        theme={DotListMenuTheme.small}
                    />
                    <DotListMenuItem
                        subTitle={'Briefing anpassen'}
                        text={'Bearbeiten'}
                        theme={DotListMenuTheme.small}
                    />
                </DotListMenu>
                <br />
                <br />
                <br />
                <br />
                <DotListMenu
                    alignment={DotListMenuAlignment.right}
                    theme={DotListMenuTheme.small}
                >
                    <DotListMenuItem
                        subTitle={'Briefing anpassen r'}
                        text={'Löschen'}
                        theme={DotListMenuTheme.small}
                    />
                    <DotListMenuItem
                        subTitle={'Briefing anpassen'}
                        text={'Bearbeiten'}
                        theme={DotListMenuTheme.small}
                    />
                </DotListMenu>
                <br />
                <br />
                <br />
                <br />
                <DotListMenu
                    alignment={DotListMenuAlignment.left}
                    theme={DotListMenuTheme.big}
                >
                    <DotListMenuItem
                        subTitle={'Briefing anpassen l'}
                        text={'Löschen'}
                        theme={DotListMenuTheme.big}
                    />
                    <DotListMenuItem
                        subTitle={'Briefing anpassen'}
                        text={'Bearbeiten'}
                        theme={DotListMenuTheme.big}
                    />
                </DotListMenu>
                <br />
                <br />
                <br />
                <br />
                <DotListMenu
                    alignment={DotListMenuAlignment.right}
                    theme={DotListMenuTheme.big}
                >
                    <DotListMenuItem
                        subTitle={'Briefing anpassen r'}
                        text={'Löschen'}
                        theme={DotListMenuTheme.big}
                    />
                    <DotListMenuItem
                        subTitle={'Briefing anpassen'}
                        text={'Bearbeiten'}
                        theme={DotListMenuTheme.big}
                    />
                </DotListMenu>

                <FileList
                    key={'offer_1'}
                    files={[
                        {
                            additionalText: '10.900 EUR (Netto)',
                            downloadUrl:    null,
                            name:           'A_Angebot_Imagefilm_like-a-bosch_realfilm.pdf',
                        },
                        {
                            additionalText: null,
                            downloadUrl:    'https://lulububu.de/images/who-we-are-background.jpg',
                            name:           'who-we-are-background.jpg',
                        },
                    ]}
                    footerContent={
                        <IconButton
                            text={'1. Angebot annehmen'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />
                    }
                    mode={FileListMode.downloadSmall}
                    title={'1. Angebot - Imagefilm Realfilm'}
                />
                <div
                    style={{
                        justifyContent: 'space-between',
                        flexDirection:  'row',
                        flexWrap:       'wrap',
                        display:        'flex',
                    }}
                >
                    <IconButton
                        text={'Screendesign overlay'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={OverlayManager.getPathForOverlayKey(Overlays.screenDesign)}
                    />
                    <IconButton
                        text={'Projekt löschen overlay'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={OverlayManager.getPathForOverlayKey(Overlays.deleteProject)}
                    />
                    <IconButton
                        text={'Angebot annehmen overlay'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={OverlayManager.getPathForOverlayKey(Overlays.acceptOffer)}
                    />
                    <IconButton
                        text={'Rückrufbitte overlay'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={OverlayManager.getPathForOverlayKey(Overlays.callbackRequest)}
                    />
                    <IconButton
                        text={'Projekt erstellen overlay'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={OverlayManager.getPathForOverlayKey(Overlays.createProject)}
                    />
                    <IconButton
                        text={'Angebot erstellen overlay'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={OverlayManager.getPathForOverlayKey(Overlays.createOffer)}
                    />
                </div>
                <FileList
                    key={'offer_1'}
                    files={[
                        {
                            additionalText: '10.900 EUR (Netto)',
                            downloadUrl:    null,
                            name:           'A_Angebot_Imagefilm_like-a-bosch_realfilm.pdf',
                        },
                        {
                            additionalText: null,
                            downloadUrl:    null,
                            name:           'Treatment - Story in 140 Zeichen.pdf',
                        },
                    ]}
                    footerContent={
                        <IconButton
                            text={'1. Angebot annehmen'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />
                    }
                    mode={FileListMode.manage}
                    title={'1. Angebot - Imagefilm Realfilm'}
                />
                <MessageComposer
                    message={'foobar'}
                    onMessageChange={(event) => {
                        console.log('message', event);
                    }}
                />
                <MessageComposer
                    message={'mit files'}
                    onMessageChange={(event) => {
                        console.log('message', event);
                    }}
                    files={[
                        {
                            name: 'agb.pdf',
                        },
                        {
                            name: '12324.pdf',
                        },
                    ]}
                />
                <CallbackRequestBoxConnected />
                <ContentItem
                    content={
                        <ProjectBriefingContentItemContent
                            advertisingBudgetPlanned={true}
                            advertisingBudgetRangeInThousandsFrom={10}
                            advertisingBudgetRangeInThousandsTo={20}
                            ageRangeFrom={20}
                            ageRangeTo={24}
                            branch={'Beauty'}
                            budgetRangeInThousandsFrom={10}
                            budgetRangeInThousandsTo={20}
                            fixedReleaseDate={'14. November 1989'}
                            formats={['Insta']}
                            goal={'Mitarbeiter finden'}
                            licenseDuration={'1 Jahr'}
                            projectDescription={'Bla Bla Bla… Geben Sie an, wann Sie Ihr Fahrzeug frühestens zulassen möchten. Erst am Tag der tatsächlichen Zulassung beginnt der Vertrag und die Abbuchung der Beiträge.'}
                            projectTitle={'Imagefilm für Crazy Unternehmen'}
                            targetGroupType={'B2B'}
                            territories={[
                                'D',
                                'A',
                                'CH',
                            ]}
                            videoType={'Imagefilm'}
                            willVideoPublished={true}
                            wishCompletionDate={'14. November 1989'}
                            wishedProductionPeriodFrom={'14. November 1989'}
                            wishedProductionPeriodTo={'14. November 2020'}
                        />
                    }
                    headerCenterContent={null}
                    headerRightContent={[
                        <IconButton
                            iconType={IconType.messageSquare}
                            key={'messages'}
                            text={'Nachrichten'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />,
                    ]}
                    title={'Imagefilm für Crazy Unternehmen'}
                    toggleContentEnabled={false}
                    subTitle={'Abgefahrener Imagefilm'}
                />
                <Input
                    placeholder={'DD.MM.YYYY'}
                    type={InputType.text}
                    prefix={'von'}
                    value={'gdsgd'}
                />
                <Input
                    placeholder={'Beschreibung'}
                    type={InputType.text}
                    size={InputSize.multiLine}
                    prefix={'Lieblingsfarbe'}
                    value={'gdsgd'}
                />
                <DropDown
                    noMarginBottom={true}
                    onClear={(event) => {
                        console.log('selection cleared', event);
                    }}
                    onChange={(event) => {
                        console.log('selection changed', event);
                    }}
                    options={[
                        {
                            value: '1',
                            label: 'Imagefilm',
                        },
                        {
                            value: '2',
                            label: 'Webespot',
                        },
                        {
                            value: '3',
                            label: 'Recruting Video',
                        },
                        {
                            value: '4',
                            label: 'Erklärvideo',
                        },
                    ]}
                    selectedOption={true}
                />
                <Spacer height={50} />
                <Input
                    errorText={'Whallah was ist los hier'}
                    placeholder={'DD.MM.YYYY'}
                    type={InputType.text}
                    prefix={'von'}
                />
                <Input
                    errorText={'Bitte geben Sie eine gültige PLZ ein.'}
                    placeholder={'Beschreibung'}
                    type={InputType.text}
                    size={InputSize.multiLine}
                    prefix={'Lieblingsfarbe'}
                />
                <Input
                    errorText={'Whallah was ist los hier'}
                    placeholder={'DD.MM.YYYY'}
                    type={InputType.date}
                    prefix={'von'}
                />
                <Input
                    errorText={'Bitte geben Sie eine gültige PLZ ein.'}
                    placeholder={'Beschreibung'}
                    type={InputType.text}
                    prefix={'Lieblingsfarbe'}
                />
                <div>
                    <ChoiceSelector
                        choiceClicked={(choiceData, index) => {
                            console.log('ChoiceClicked: ', choiceData, index);
                        }}
                        choices={[
                            {
                                selected:  false,
                                text:      'Not an Option (half)',
                                widthMode: ChoiceWidthMode.half,
                            },
                            {
                                selected:  false,
                                text:      'Also not an Option (half)',
                                widthMode: ChoiceWidthMode.half,
                            },
                        ]}
                        mode={ChoiceSelectorMode.multipleChoice}
                        errorText={'Bitte wähle eine Option.'}
                    />
                </div>
                <div>
                    <ChoiceSelector
                        choiceClicked={(choiceData, index) => {
                            console.log('ChoiceClicked: ', choiceData, index);
                        }}
                        choices={[
                            {
                                selected:  false,
                                text:      'Not an Option (full)',
                                widthMode: ChoiceWidthMode.full,
                            },
                            {
                                selected:  false,
                                text:      'Also not an Option (full)',
                                widthMode: ChoiceWidthMode.full,
                            },
                        ]}
                        mode={ChoiceSelectorMode.multipleChoice}
                        errorText={'Bitte wähle eine Option.'}
                    />
                </div>
                <TabBar
                    activeTabIndex={0}
                    tabTitles={[
                        'Alle Matches',
                        'Neue Matches',
                    ]}
                />
                <TabBar
                    activeTabIndex={2}
                    tabTitles={[
                        'Angenommene Projekte (Möglichkeiten)',
                        'Neue Projekte (Möglichkeiten)',
                        'Abgelehnte Projekte (Möglichkeiten)',
                    ]}
                />
                <FormRow
                    label={'Deine Position im Unternehmen?'}
                    isOptional={true}
                    tooltipText={'Test'}
                    tooltipTitle={'Titel'}
                >
                    <DropDown
                        noMarginBottom={true}
                        onClear={(event) => {
                            console.log('selection cleared', event);
                        }}
                        onChange={(event) => {
                            console.log('selection changed', event);
                        }}
                        options={[
                            {
                                value: '1',
                                label: 'Imagefilm',
                            },
                            {
                                value: '2',
                                label: 'Webespot',
                            },
                            {
                                value: '3',
                                label: 'Recruting Video',
                            },
                            {
                                value: '4',
                                label: 'Erklärvideo',
                            },
                        ]}
                    />
                </FormRow>
                <FormRow
                    label={'Nutzungsdauer'}
                    isOptional={true}
                    tooltipText={'Test'}
                    tooltipTitle={'Titel'}
                >
                    <ChoiceSelector
                        choiceClicked={(choiceData, index) => {
                            console.log('ChoiceClicked: ', choiceData, index);
                        }}
                        choices={[
                            {
                                selected:  true,
                                text:      'TV',
                                widthMode: ChoiceWidthMode.half,
                            },
                            {
                                selected:  false,
                                text:      'Online',
                                widthMode: ChoiceWidthMode.half,
                            },
                            {
                                selected:  false,
                                text:      'Kino',
                                widthMode: ChoiceWidthMode.half,
                            },
                            {
                                selected:  true,
                                text:      'Facebook',
                                widthMode: ChoiceWidthMode.half,
                            },
                            {
                                selected:  true,
                                text:      'Weiß ich nicht (später ausfüllen)',
                                widthMode: ChoiceWidthMode.full,
                            },
                        ]}
                        mode={ChoiceSelectorMode.multipleChoice}
                    />
                </FormRow>
                <FormRow
                    label={'Welche Zielgruppe möchten Sie erreichen?'}
                    tooltipText={'Test'}
                    tooltipTitle={'Titel'}
                >
                    <RangeInput
                        maximumValue={1000}
                        minimumValue={0}
                        type={RangeInputType.number}
                        onChange={(value) => {
                            console.log('RangeSlider change', value);
                        }}
                        step={1}
                        postfix={'Euro'}
                        valueEnd={40}
                        valueStart={10}
                    />
                    <ChoiceSelector
                        choiceClicked={(choiceData, index) => {
                            console.log('ChoiceClicked: ', choiceData, index);
                        }}
                        choices={[
                            {
                                selected:  true,
                                text:      'Weiß ich nicht (später ausfüllen)',
                                widthMode: ChoiceWidthMode.full,
                            },
                        ]}
                        mode={ChoiceSelectorMode.multipleChoice}
                    />
                </FormRow>
                <FormRow
                    label={'Projektbeschreibung'}
                    isOptional={true}
                    tooltipText={'Test'}
                    tooltipTitle={'Titel'}
                >
                    <Input
                        noMarginBottom={true}
                        placeholder={'Beschreibung'}
                        prefix={'Lieblingsfarbe'}
                        type={InputType.text}
                        size={InputSize.multiLine}
                    />
                </FormRow>
                <FormRow
                    label={'Projektbeschreibung'}
                    isOptional={true}
                    tooltipText={'Test'}
                    tooltipTitle={'Titel'}
                >
                    <Input
                        noMarginBottom={true}
                        placeholder={'Beschreibung'}
                        prefix={'Lieblingsfarbe'}
                        type={InputType.text}
                        size={InputSize.singleLine}
                    />
                </FormRow>
                <Input
                    placeholder={'Datum auswählen'}
                    type={InputType.date}
                />
                <Spacer height={300} />
                <RangeSlider
                    maximumValue={1000}
                    minimumValue={0}
                    onChange={(value) => {
                        console.log('RangeSlider change', value);
                    }}
                    step={1}
                    theme={RangeSliderTheme.default}
                    valueEnd={40}
                    valueStart={10}
                />
                <RangeSlider
                    maximumValue={1000}
                    minimumValue={0}
                    onChange={(value) => {
                        console.log('RangeSlider change', value);
                    }}
                    step={1}
                    theme={RangeSliderTheme.unimportant}
                    valueEnd={40}
                    valueStart={10}
                />
                <Spacer height={20} />
                <RangeInput
                    maximumValue={1000}
                    minimumValue={0}
                    type={RangeInputType.number}
                    onChange={(value) => {
                        console.log('RangeSlider change', value);
                    }}
                    postfix={'Euro'}
                    step={1}
                    theme={RangeInputTheme.default}
                    valueEnd={40}
                    valueStart={10}
                />
                <RangeInput
                    maximumValue={1000}
                    minimumValue={0}
                    type={RangeInputType.number}
                    onChange={(value) => {
                        console.log('RangeSlider change', value);
                    }}
                    postfix={'Euro'}
                    step={1}
                    theme={RangeInputTheme.unimportant}
                    valueEnd={40}
                    valueStart={10}
                />
                <Spacer height={20} />
                <CallbackRequestBox
                    onClick={() => {
                        console.log('CallbackRequestBox click');
                    }}
                    sidePadding={50}
                />
                <Spacer height={20} />
                <OverlayButtonWrapper>
                    <OverlayButton
                        standAlone={false}
                        text={'Weiter'}
                        theme={OverlayButtonTheme.turquoise}
                    />
                </OverlayButtonWrapper>
                <Spacer height={20} />
                <OverlayButtonWrapper>
                    <OverlayButton
                        standAlone={false}
                        text={'Zurück'}
                        theme={OverlayButtonTheme.gray}
                    />
                    <OverlayButton
                        standAlone={false}
                        text={'Weiter'}
                        theme={OverlayButtonTheme.turquoise}
                    />
                </OverlayButtonWrapper>
                <Spacer height={20} />
                <OverlayButtonWrapper>
                    <OverlayButton
                        standAlone={false}
                        text={'Zurück'}
                        theme={OverlayButtonTheme.gray}
                    />
                    <OverlayButton
                        disabled={true}
                        standAlone={false}
                        text={'Weiter'}
                        theme={OverlayButtonTheme.turquoise}
                    />
                </OverlayButtonWrapper>
                <Spacer height={20} />
                <Tooltip
                    position={TooltipPosition.right}
                    text={'Bitte gib hier einen individuellen Titel für dein Projekt ein.  z.B. Imagefilm für Fahrzeugbau-Unternehmen'}
                    theme={TooltipTheme.white}
                />
                <Tooltip
                    position={TooltipPosition.left}
                    text={'Bitte gib hier einen individuellen Titel für dein Projekt ein.  z.B. Imagefilm für Fahrzeugbau-Unternehmen'}
                    theme={TooltipTheme.white}
                />
                <Input
                    placeholder={'3.000'}
                    type={InputType.text}
                />
                <DropDown
                    onClear={(event) => {
                        console.log('selection cleared', event);
                    }}
                    onChange={(event) => {
                        console.log('selection changed', event);
                    }}
                    options={[
                        {
                            value: '1',
                            label: 'Imagefilm',
                        },
                        {
                            value: '2',
                            label: 'Webespot',
                        },
                        {
                            value: '3',
                            label: 'Recruting Video',
                        },
                        {
                            value: '4',
                            label: 'Erklärvideo',
                        },
                    ]}
                />
                <Input
                    placeholder={'3.000'}
                    type={InputType.text}
                    postfix={'Euro'}
                />
                <CallToActionBox
                    title={'Alternativ: Rückrufbitte'}
                    text={'Ich möchte lieber zurückgerufen und telefonisch beraten werden:'}
                    buttonText={'Go! Call me!'}
                />
                <CallToActionBox
                    title={'Alternativ: Rückrufbitte'}
                    text={'Ich möchte lieber zurückgerufen und telefonisch beraten werden:'}
                    buttonText={'Go! Call me!'}
                    iconType={IconType.globe}
                />
                <CallToActionBox
                    title={'Alternativ: Rückrufbitte'}
                    text={'Ich möchte lieber zurückgerufen und telefonisch beraten werden:'}
                    buttonText={'Go! Call me!'}
                    iconType={IconType.globe}
                    sidePadding={100}
                />
                <Input
                    placeholder={'DD.MM.YYYY'}
                    type={InputType.text}
                    prefix={'von'}
                />
                <Input
                    placeholder={'Beschreibung'}
                    type={InputType.text}
                    size={InputSize.multiLine}
                    prefix={'Lieblingsfarbe'}
                />
                <Input
                    placeholder={'Beschreibung'}
                    type={InputType.text}
                    size={InputSize.multiLine}
                    postfix={'Lieblingsfarbe'}
                />
                <Input
                    placeholder={'Beschreibung'}
                    type={InputType.text}
                    size={InputSize.multiLine}
                />
                <Tooltip
                    position={TooltipPosition.bottom}
                    text={'Bitte gib hier einen individuellen Titel für dein Projekt ein.  z.B. Imagefilm für Fahrzeugbau-Unternehmen'}
                    theme={TooltipTheme.white}
                />
                <Tooltip
                    position={TooltipPosition.top}
                    text={'Bitte gib hier einen individuellen Titel für dein Projekt ein.  z.B. Imagefilm für Fahrzeugbau-Unternehmen'}
                    theme={TooltipTheme.white}
                />
                <Tooltip
                    position={TooltipPosition.bottomRight}
                    text={'Bitte gib hier einen individuellen Titel für dein Projekt ein.  z.B. Imagefilm für Fahrzeugbau-Unternehmen'}
                    theme={TooltipTheme.white}
                />
                <Tooltip
                    position={TooltipPosition.right}
                    text={'Bitte gib hier einen individuellen Titel für dein Projekt ein.  z.B. Imagefilm für Fahrzeugbau-Unternehmen'}
                    theme={TooltipTheme.white}
                    title={'Ich habe einen Titel'}
                />
                <Tooltip
                    position={TooltipPosition.left}
                    text={'Bitte gib hier einen individuellen Titel für dein Projekt ein.  z.B. Imagefilm für Fahrzeugbau-Unternehmen'}
                    theme={TooltipTheme.white}
                    title={'Ich habe einen Titel'}
                />
                <Tooltip
                    position={TooltipPosition.bottom}
                    text={'Bitte gib hier einen individuellen Titel für dein Projekt ein.  z.B. Imagefilm für Fahrzeugbau-Unternehmen'}
                    theme={TooltipTheme.white}
                    title={'Ich habe einen Titel'}
                />
                <Tooltip
                    position={TooltipPosition.top}
                    text={'Bitte gib hier einen individuellen Titel für dein Projekt ein.  z.B. Imagefilm für Fahrzeugbau-Unternehmen'}
                    theme={TooltipTheme.white}
                    title={'Ich habe einen Titel'}
                />
                <Tooltip
                    position={TooltipPosition.bottomRight}
                    text={'Bitte gib hier einen individuellen Titel für dein Projekt ein.  z.B. Imagefilm für Fahrzeugbau-Unternehmen'}
                    theme={TooltipTheme.white}
                    title={'Ich habe einen Titel'}
                />
                <ChoiceSelector
                    choices={[
                        {
                            selected:  true,
                            text:      'Ja',
                            widthMode: ChoiceWidthMode.half,
                        },
                        {
                            selected:  false,
                            text:      'Nein',
                            widthMode: ChoiceWidthMode.half,
                        },
                    ]}
                    mode={ChoiceSelectorMode.singleChoice}
                />
                <ChoiceSelector
                    choiceClicked={(choiceData, index) => {
                        console.log('ChoiceClicked: ', choiceData, index);
                    }}
                    choices={[
                        {
                            selected:  true,
                            text:      'TV',
                            widthMode: ChoiceWidthMode.half,
                        },
                        {
                            selected:  false,
                            text:      'Online',
                            widthMode: ChoiceWidthMode.half,
                        },
                        {
                            selected:  false,
                            text:      'Kino',
                            widthMode: ChoiceWidthMode.half,
                        },
                        {
                            selected:  true,
                            text:      'Facebook',
                            widthMode: ChoiceWidthMode.half,
                        },
                        {
                            selected:  true,
                            text:      'Weiß ich nicht (später ausfüllen)',
                            widthMode: ChoiceWidthMode.full,
                        },
                    ]}
                    mode={ChoiceSelectorMode.multipleChoice}
                />
                <ProgressBar
                    title={'Status'}
                    percent={28}
                />
                <Spacer height={20} />
                <div
                    style={{
                        justifyContent: 'space-between',
                        flexDirection:  'row',
                        flexWrap:       'wrap',
                        display:        'flex',
                    }}
                >
                    <IconButton
                        text={'Anmelden'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        iconType={IconType.edit}
                        text={'Bearbeiten'}
                        theme={IconButtonTheme.grayGhost}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        iconType={IconType.edit}
                        text={'Bearbeiten'}
                        theme={IconButtonTheme.gray}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        iconType={IconType.zap}
                        text={'Matches'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        iconType={IconType.messageSquare}
                        text={'Nachrichten'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        iconType={IconType.fileText}
                        text={'Angebote'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        iconType={IconType.phone}
                        text={'Rückrufbitte'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        iconType={IconType.fileText}
                        text={'Briefing vervollständigen'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        disabled={true}
                        iconType={IconType.phone}
                        text={'Rückrufbitte'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        disabled={true}
                        iconType={IconType.fileText}
                        text={'Briefing vervollständigen'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        iconType={IconType.fileText}
                        text={'Profil ansehen'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        iconType={IconType.messageSquare}
                        text={'Nachricht schreiben'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        iconType={IconType.checkCircle}
                        text={'Angebot ansehen'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        disabled={true}
                        iconType={IconType.xCircle}
                        text={'Kein Angebot erhalten'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        allowLineBreaks={true}
                        iconType={IconType.save}
                        text={'Datei\nhinzufügen'}
                        theme={IconButtonTheme.grayGhost}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        allowLineBreaks={true}
                        iconType={IconType.save}
                        text={'Datei\nhinzufügen'}
                        theme={IconButtonTheme.gray}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        text={'Absenden'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        iconType={IconType.check}
                        text={'1. Angebot annehmen'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        iconType={IconType.download}
                        text={'Datei\ndownloaden'}
                        theme={IconButtonTheme.turquoiseGhostSmall}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        iconType={IconType.eye}
                        text={'Briefing ansehen'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        iconType={IconType.check}
                        text={'Interessiert'}
                        theme={IconButtonTheme.turquoiseGhost}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        allowLineBreaks={true}
                        iconType={IconType.fileText}
                        text={'Angebot\nhochladen'}
                        theme={IconButtonTheme.grayGhost}
                        to={Routes.screenDesign}
                    />
                    <IconButton
                        allowLineBreaks={true}
                        iconType={IconType.fileText}
                        text={'Angebot\nhochladen'}
                        theme={IconButtonTheme.gray}
                        to={Routes.screenDesign}
                    />
                </div>
                <Spacer height={20} />
                <div
                    style={{
                        justifyContent: 'space-between',
                        flexDirection:  'row',
                        flexWrap:       'wrap',
                        display:        'flex',
                    }}
                >
                    <IconLabel
                        iconType={IconType.movie}
                        text={'Klassicher Imagefilm'}
                        theme={IconLabelTheme.smallGray}
                    />
                    <IconLabel
                        disabled={true}
                        iconType={IconType.movie}
                        text={'Klassicher Imagefilm'}
                        theme={IconLabelTheme.smallGray}
                    />
                    <IconLabel
                        iconType={IconType.checkCircle}
                        text={'Klassicher Imagefilm'}
                        theme={IconLabelTheme.bigWhite}
                    />
                    <IconLabel
                        disabled={true}
                        iconType={IconType.checkCircle}
                        text={'Klassicher Imagefilm'}
                        theme={IconLabelTheme.bigWhite}
                    />
                </div>
                <Spacer height={20} />
                <ContentItem
                    content={
                        <CompanyDetailsContentItemContent
                            competences={[
                                'Realfilm',
                                'Commercial',
                                'Recruiting',
                            ]}
                            languages={[
                                'Deutsch',
                                'Englisch',
                            ]}
                            locations={[
                                'Düsseldorf',
                                'Ravensburg',
                                'Los Angeles',
                            ]}
                            placesOfWork={[
                                'International',
                            ]}
                        />
                    }
                    headerCenterContent={
                        <IconButton
                            key={'profile'}
                            text={'Profil ansehen'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />
                    }
                    headerRightContent={' '}
                    title={'Lulububu'}
                    subTitle={'Softwareproduktion'}
                />
                <ContentItem
                    content={
                        <OfferOverviewContentItemContent
                            fileLists={[
                                <FileList
                                    key={'offer_1'}
                                    files={[
                                        {
                                            additionalText: '10.900 EUR (Netto)',
                                            downloadUrl:    null,
                                            name:           'A_Angebot_Imagefilm_like-a-bosch_realfilm.pdf',
                                        },
                                        {
                                            additionalText: null,
                                            downloadUrl:    null,
                                            name:           'Treatment - Story in 140 Zeichen.pdf',
                                        },
                                    ]}
                                    footerContent={
                                        <IconButton
                                            text={'1. Angebot annehmen'}
                                            theme={IconButtonTheme.turquoiseGhost}
                                            to={Routes.screenDesign}
                                        />
                                    }
                                    mode={FileListMode.download}
                                    title={'1. Angebot - Imagefilm Realfilm'}
                                />,
                                <FileList
                                    key={'offer_2'}
                                    files={[
                                        {
                                            additionalText: '12.100 EUR (Netto)',
                                            downloadUrl:    null,
                                            name:           'B_Angebot_Imagefilm_like-a-bosch_animationsfilm.pdf',
                                        },
                                        {
                                            additionalText: null,
                                            downloadUrl:    null,
                                            name:           'Animatics.mp4',
                                        },
                                    ]}
                                    footerContent={
                                        <IconButton
                                            text={'2. Angebot annehmen'}
                                            theme={IconButtonTheme.turquoiseGhost}
                                            to={Routes.screenDesign}
                                        />
                                    }
                                    mode={FileListMode.download}
                                    title={'2. Angebot - Imagefilm Animationsfilm'}
                                />,
                                <FileList
                                    key={'other'}
                                    files={[
                                        {
                                            additionalText: null,
                                            downloadUrl:    null,
                                            name:           'Agenturvorstellung-wir-sind-Dirty-Ronny.pdf',
                                        },
                                        {
                                            additionalText: null,
                                            downloadUrl:    null,
                                            name:           'Preisliste-Dirty-Ronny.pdf',
                                        },
                                        {
                                            additionalText: null,
                                            downloadUrl:    null,
                                            name:           'AGB.pdf',
                                        },
                                    ]}
                                    mode={FileListMode.download}
                                    title={'Sonstige Dokumente'}
                                />,
                            ]}
                        />
                    }
                    headerCenterContent={
                        <IconButton
                            key={'briefing'}
                            text={'Briefing ansehen'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />
                    }
                    headerRightContent={[
                        <IconButton
                            iconType={IconType.messageSquare}
                            key={'messages'}
                            text={'Nachrichten'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />,
                    ]}
                    title={'Imagefilm für Lulububu'}
                    subTitle={'Klassischer Imagefilm'}
                />
                <ContentItem
                    headerCenterContent={
                        <IconButton
                            iconType={IconType.edit}
                            text={'Bearbeiten'}
                            theme={IconButtonTheme.grayGhost}
                            to={Routes.screenDesign}
                        />
                    }
                    headerRightContent={[
                        <IconButton
                            iconType={IconType.zap}
                            key={'machtes'}
                            text={'Matches'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />,
                        <IconButton
                            iconType={IconType.messageSquare}
                            key={'messages'}
                            text={'Nachrichten'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />,
                        <IconButton
                            iconType={IconType.messageSquare}
                            key={'offers'}
                            text={'Angebote'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />,
                    ]}
                    title={'Imagefilm für Immobilien Unternehmen'}
                    subTitle={'Klassischer Imagefilm'}
                />
                <ContentItem
                    headerCenterContent={
                        <IconButton
                            iconType={IconType.edit}
                            text={'Bearbeiten'}
                            theme={IconButtonTheme.grayGhost}
                            to={Routes.screenDesign}
                        />
                    }
                    headerRightContent={[
                        <IconButton
                            iconType={IconType.zap}
                            key={'machtes'}
                            text={'Matches'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />,
                        <IconButton
                            iconType={IconType.messageSquare}
                            key={'messages'}
                            text={'Nachrichten'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />,
                        <IconButton
                            iconType={IconType.messageSquare}
                            key={'offers'}
                            text={'Angebote'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />,
                    ]}
                    title={'Imagefilm für Immobilien Unternehmen'}
                    subTitle={'Klassischer Imagefilm'}
                />
                <ContentItem
                    content={
                        <ProjectDetailsContentItemContent
                            ageRangeFrom={20}
                            ageRangeTo={24}
                            budgetRangeInThousandsFrom={10}
                            budgetRangeInThousandsTo={20}
                            flaggedAsInterested={true}
                            formats={[]}
                            orderReceived={true}
                            offerSubmitted={true}
                            type={'Krasser Actionfilm'}
                        />
                    }
                    headerCenterContent={
                        <IconButton
                            iconType={IconType.eye}
                            text={'Briefing ansehen'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />
                    }
                    headerRightContent={[
                        <IconButton
                            iconType={IconType.messageSquare}
                            key={'messages'}
                            text={'Nachrichten'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />,
                        <IconButton
                            iconType={IconType.fileText}
                            key={'offer'}
                            text={'Angebot abgeben'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />,
                    ]}
                    title={'Imagefilm für Crazy Unternehmen'}
                    toggleContentButtonClicked={() => {
                        this.setState({
                            toggle1Opened: !this.state.toggle1Opened,
                        });
                    }}
                    toggleContentEnabled={true}
                    toggleContentOpened={this.state.toggle1Opened}
                    toggleContentHeight={220}
                    subTitle={'Abgefahrener Imagefilm'}
                />
                <ContentItem
                    content={
                        <ProjectDetailsContentItemContent
                            ageRangeFrom={20}
                            ageRangeTo={24}
                            budgetRangeInThousandsFrom={10}
                            budgetRangeInThousandsTo={20}
                            flaggedAsInterested={true}
                            formats={[]}
                            orderReceived={false}
                            offerSubmitted={false}
                            type={'Imagefilm'}
                        />
                    }
                    headerCenterContent={
                        <IconButton
                            iconType={IconType.eye}
                            text={'Briefing ansehen'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />
                    }
                    headerRightContent={[
                        <IconButton
                            iconType={IconType.messageSquare}
                            key={'messages'}
                            text={'Nachrichten'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />,
                        <IconButton
                            iconType={IconType.fileText}
                            key={'offer'}
                            text={'Angebot bearbeiten'}
                            theme={IconButtonTheme.grayGhost}
                            to={Routes.screenDesign}
                        />,
                    ]}
                    title={'Imagefilm für Crazy Unternehmen'}
                    toggleContentButtonClicked={() => {
                        this.setState({
                            toggle2Opened: !this.state.toggle2Opened,
                        });
                    }}
                    toggleContentEnabled={true}
                    toggleContentOpened={this.state.toggle2Opened}
                    toggleContentHeight={220}
                    subTitle={'Abgefahrener Imagefilm'}
                />
                <ContentItem
                    content={
                        <ProjectDetailsContentItemContent
                            ageRangeFrom={20}
                            ageRangeTo={24}
                            budgetRangeInThousandsFrom={10}
                            budgetRangeInThousandsTo={20}
                            flaggedAsInterested={false}
                            formats={[]}
                            orderReceived={false}
                            offerSubmitted={false}
                            type={'Imagefilm'}
                        />
                    }
                    headerCenterContent={
                        <IconButton
                            iconType={IconType.eye}
                            text={'Briefing ansehen'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />
                    }
                    headerRightContent={[
                        <IconButton
                            iconType={IconType.messageSquare}
                            key={'messages'}
                            text={'Nachrichten'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />,
                        <IconButton
                            iconType={IconType.fileText}
                            key={'offer'}
                            text={'Angebot abgeben'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />,
                    ]}
                    title={'Imagefilm für Crazy Unternehmen'}
                    toggleContentButtonClicked={() => {
                        this.setState({
                            toggle3Opened: !this.state.toggle3Opened,
                        });
                    }}
                    toggleContentEnabled={true}
                    toggleContentOpened={this.state.toggle3Opened}
                    toggleContentHeight={220}
                    subTitle={'Abgefahrener Imagefilm'}
                />
                <ContentItem
                    headerCenterContent={
                        <IconButton
                            iconType={IconType.eye}
                            text={'Briefing ansehen'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />
                    }
                    headerRightContent={[
                        <IconButton
                            iconType={IconType.xCircle}
                            key={'notInterested'}
                            text={'Kein Interesse'}
                            theme={IconButtonTheme.grayGhost}
                            to={Routes.screenDesign}
                        />,
                        <IconButton
                            iconType={IconType.check}
                            key={'interested'}
                            text={'Interessiert'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />,
                    ]}
                    title={'Imagefilm für Pommesbude'}
                    subTitle={'Klassischer Imagefilm'}
                />
                <ContentItem
                    headerCenterContent={
                        <ProgressBar
                            title={'Status'}
                            percent={42}
                        />
                    }
                    headerRightContent={[
                        <IconButton
                            iconType={IconType.phone}
                            key={'callBack'}
                            text={'Rückrufbitte'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />,
                        <IconButton
                            iconType={IconType.fileText}
                            key={'completeBriefing'}
                            text={'Briefing vervollständigen'}
                            theme={IconButtonTheme.turquoiseGhost}
                            to={Routes.screenDesign}
                        />,
                    ]}
                    title={'Imagefilm für Spieleland'}
                    subTitle={'Klassischer Imagefilm'}
                />
                <Spacer height={20} />
                <ProgressBar
                    title={'Test beispiel yo'}
                    percent={100}
                />
                <Spacer height={20} />
                <Message
                    company={{ name: 'Dirty Ronny' }}
                    name={'Dirty Ronny'}
                    message={'Hi Markus,\n\ndanke nochmal für deine Nachricht. Wir sind nun soweit, dass wir den Film gerne tri tra trulla la hier und da machen wollen. 100k gehen heute raus. Danke nochmal für deine Nachricht. Wir sind nun soweit, dass wir den Film gerne tri tra trulla la hier und da machen wollen. 100k gehen heute raus. Danke nochmal für deine Nachricht. Wir sind nun soweit, dass wir den Film gerne tri tra trulla la hier und da machen wollen. 100k gehen heute raus.\n\nViele Grüße'}
                    time={new Date()}
                    type={MessageType.incoming}
                />
                <Message
                    company={{ name: 'Dirty Ronny' }}
                    name={'Dirty Ronny'}
                    message={'Hi Markus,\n\ndanke nochmal für deine Nachricht. Wir sind nun soweit, dass wir den Film gerne tri tra trulla la hier und da machen wollen. 100k gehen heute raus. Danke nochmal für deine Nachricht. Wir sind nun soweit, dass wir den Film gerne tri tra trulla la hier und da machen wollen. 100k gehen heute raus. Danke nochmal für deine Nachricht. Wir sind nun soweit, dass wir den Film gerne tri tra trulla la hier und da machen wollen. 100k gehen heute raus.\n\n\nViele Grüße'}
                    time={new Date().setDate(new Date().getDate() - 1)}
                    type={MessageType.outgoing}
                />
                <Message
                    company={{ name: 'Dirty Ronny' }}
                    name={'Dirty Ronny'}
                    message={'Hi Markus,\n\ndanke nochmal für deine Nachricht. Wir sind nun soweit, dass wir den Film gerne tri tra trulla la hier und da machen wollen. 100k gehen heute raus. Danke nochmal für deine Nachricht. Wir sind nun soweit, dass wir den Film gerne tri tra trulla la hier und da machen wollen. 100k gehen heute raus. Danke nochmal für deine Nachricht. Wir sind nun soweit, dass wir den Film gerne tri tra trulla la hier und da machen wollen. 100k gehen heute raus.\n\nViele Grüße'}
                    time={new Date().setDate(new Date().getDate() - 10)}
                    type={MessageType.incoming}
                />
                <br />
                <br />
                {renderCrashButton()}
            </ContentWrapper>
        );
    };

    renderSubSideBarContent = () => {
        return (
            <SideBarMenu>
                <SideBarMenuItem
                    company={{ name: 'HELGA' }}
                    isActive={false}
                    subText={'14.11.1989'}
                    text={'HELGA'}
                />
                <SideBarMenuItem
                    company={{ name: 'Dirty Ronny' }}
                    isActive={true}
                    subText={'14.11.1989'}
                    text={'Dirty Ronny'}
                />
                <SideBarMenuItem
                    company={{ name: 'Lulububu' }}
                    isActive={false}
                    subText={'14.11.2020'}
                    text={'Lulububu'}
                />
            </SideBarMenu>
        );
    };
}

export default Screen;
