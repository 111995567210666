//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import Routes   from '../../constants/Routes';
import { push } from 'connected-react-router';
import { put }  from 'redux-saga/effects';

const sendGoogleAnalyticsEvent = function* (action) {
    yield put(push({
        pathname: Routes.error,
        state:    {
            error: action.type,
        },
    }));
};

export default {
    sendGoogleAnalyticsEvent,
};
