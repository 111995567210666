//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n                       from 'i18next';
import PageTitleHelper            from '../../helper/PageTitle';
import React                      from 'react';
import { Component }              from 'react';
import { Helmet }                 from 'react-helmet';
import ContentWrapper             from '../../components/stateless/ContentWrapper';
import { bindActionCreators }     from 'redux';
import { ActiveProjectActions }   from '../../store/actions/activeProject';
import { connect }                from 'react-redux';
import ProjectBriefingContentItem from '../../components/connected/ProjectBriefingContentItem';

class Screen extends Component {
    render() {
        return (
            <ContentWrapper>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('offers'))}
                    </title>
                </Helmet>
                <ProjectBriefingContentItem
                    project={this.props.activeProject}
                />
            </ContentWrapper>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(ActiveProjectActions, dispatch);

const mapStateToProps = state => (
    {
        activeProject: state.activeProject.activeProject,
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);
