//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import I18n                   from 'i18next';
import ImageUpload            from '../../stateless/ImageUpload';
import Overlay                from '../Overlay';
import OverlayButton          from '../../stateless/OverlayButton';
import OverlayButtonTheme     from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper   from '../../stateless/OverlayButtonWrapper';
import OverlayPane            from '../../stateless/OverlayPane';
import OverlayPaneState       from '../../stateless/OverlayPane/OverlayPaneState';
import Overlays               from '../../../constants/Overlays';
import PropTypes              from '../../PropTypes';
import Spacer                 from '../../stateless/Spacer';
import { AlertBoxActions }    from '../../../store/actions/alertBox';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { CompanyActions }     from '../../../store/actions/company';
import { connect }            from 'react-redux';
import { goBack }             from 'connected-react-router';
import { v4 as uuidv4 }       from 'uuid';
import { withRouter }         from 'react-router-dom';

class Component extends React.Component {
    // TODO: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-395
    maxLogoDimensions = {
        imageWidth:  2048,
        imageHeight: 2048,
    };

    minLogoDimensions = {
        imageWidth:  300,
        imageHeight: 300,
    };

    maxBannerDimensions = {
        imageWidth:  2048,
        imageHeight: 600,
    };

    minBannerDimensions = {
        imageWidth:  1024,
        imageHeight: 300,
    };

    companyImageTypes = {
        bannerImage: 'BannerImage',
        logo:        'Logo',
    };

    cancelButtonPressed = () => {
        this.props.goBack();
    };

    deleteBannerClicked = () => {
        this.props.temporaryRemoveOwnCompanyBanner();
    };

    deleteLogoClicked = () => {
        this.props.temporaryRemoveOwnCompanyLogo();
    };

    onLoadingBannerCompleted = (file) => {
        const newId = uuidv4();

        this.props.uploadCompanyBanner({
            id: newId,
            file,
        });
    };

    onLoadingLogoCompleted = (file) => {
        const newId = uuidv4();

        this.props.uploadCompanyLogo({
            id: newId,
            file,
        });
    };

    onBannerDimensionError = () => {
        this.props.clearAlerts();
        this.props.showErrorAlertTagged({
            tag:  'banner',
            text: I18n.t('bannerDimensionError'),
        });
    };

    onLogoDimensionError = () => {
        this.props.clearAlerts();
        this.props.showErrorAlertTagged({
            tag:  'logo',
            text: I18n.t('logoDimensionError'),
        });
    };

    onBannerSizeError = () => {
        this.props.clearAlerts();
        this.props.showErrorAlertTagged({
            tag:  'banner',
            text: I18n.t('imageSizeError'),
        });
    };

    onLogoSizeError = () => {
        this.props.clearAlerts();
        this.props.showErrorAlertTagged({
            tag:  'logo',
            text: I18n.t('imageSizeError'),
        });
    };

    render() {
        return (
            <Overlay
                id={Overlays.editCompanyHeader}
                title={I18n.t('profileDetails')}
            >
                <OverlayPane
                    content={this.renderContent()}
                    footer={this.renderFooter()}
                    title={I18n.t('companyHeader')}
                    showErrors={true}
                    state={OverlayPaneState.open}
                    scrollingAllowed={false}
                />
            </Overlay>
        );
    }

    renderContent = () => {
        return (
            <>
                <ImageUpload
                    company={this.props.company}
                    imageName={this.companyImageTypes.bannerImage}
                    errorText={this.props.bannerError}
                    maxUploadSizeInBytes={this.props.maxUploadSizeInBytes}
                    maxUploadDimensionsInPixel={this.maxBannerDimensions}
                    minUploadDimensionsInPixel={this.minBannerDimensions}
                    deleteImageClicked={this.deleteBannerClicked}
                    onLoadingImageComplete={this.onLoadingBannerCompleted}
                    onImageDimensionError={this.onBannerDimensionError}
                    onImageSizeError={this.onBannerSizeError}
                />
                <Spacer />
                <ImageUpload
                    company={this.props.company}
                    imageName={this.companyImageTypes.logo}
                    errorText={this.props.logoError}
                    maxUploadSizeInBytes={this.props.maxUploadSizeInBytes}
                    maxUploadDimensionsInPixel={this.maxLogoDimensions}
                    minUploadDimensionsInPixel={this.minLogoDimensions}
                    deleteImageClicked={this.deleteLogoClicked}
                    onLoadingImageComplete={this.onLoadingLogoCompleted}
                    onImageDimensionError={this.onLogoDimensionError}
                    onImageSizeError={this.onLogoSizeError}
                />
                <Spacer height={20} />
            </>
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.cancelButtonPressed}
                    standAlone={false}
                    text={I18n.t('cancel')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.saveButtonPressed}
                    standAlone={false}
                    text={I18n.t('save')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    saveButtonPressed = () => {
        const data        = {};
        const bannerImage = _.get(this.props, 'company.bannerImage');
        const logo        = _.get(this.props, 'company.logo');

        if (bannerImage === null || typeof bannerImage === 'string') {
            data.bannerImage = bannerImage;
        }

        if (logo === null || typeof logo === 'string') {
            data.logo = logo;
        }

        requestAnimationFrame(() => {
            this.props.updateCompany({
                data,
            });

            this.props.goBack();
        });
    };
}

Component.propTypes = {
    company:              PropTypes.object,
    imageSizeError:       PropTypes.string,
    maxUploadResolution:  PropTypes.number,
    maxUploadSizeInBytes: PropTypes.number,
};

Component.defaultProps = {
    company:              {},
    imageSizeError:       null,
    maxUploadResolution:  1024,
    maxUploadSizeInBytes: 0,
};

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        AlertBoxActions,
        CompanyActions,
        {
            goBack,
        },
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        company:              _.get(state, 'company.ownCompanyEditContext', {}),
        bannerError:          _.get(state, 'alertBox.alertBoxesTagged.banner', null),
        logoError:            _.get(state, 'alertBox.alertBoxesTagged.logo', null),
        maxUploadSizeInBytes: _.get(state, 'configuration.configuration.maxUploadSizeInBytes', 0),
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Component);
