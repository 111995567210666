//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const ConfigurationTypes = {
    FETCH_CONFIGURATION:         'Configuration/FETCH_CONFIGURATION',
    FETCH_CONFIGURATION_FAILED:  'Configuration/FETCH_CONFIGURATION_FAILED',
    FETCH_CONFIGURATION_SUCCESS: 'Configuration/FETCH_CONFIGURATION_SUCCESS',
};

const fetchConfiguration = makeActionCreator(
    ConfigurationTypes.FETCH_CONFIGURATION,
);

const fetchConfigurationFailed = makeActionCreator(
    ConfigurationTypes.FETCH_CONFIGURATION_FAILED,
);

const fetchConfigurationSuccess = makeActionCreator(
    ConfigurationTypes.FETCH_CONFIGURATION_SUCCESS,
    {
        configuration: null,
    },
);

export const ConfigurationActions = {
    fetchConfiguration,
    fetchConfigurationFailed,
    fetchConfigurationSuccess,
};
