//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import ComponentHelper from '../../../helper/ComponentHelper';
import I18n            from 'i18next';
import classNames      from 'classnames';
import Tooltip         from '../Tooltip';
import TooltipPosition from '../Tooltip/TooltipPosition';
import TooltipTheme    from '../Tooltip/TooltipTheme';

class FormRow extends React.Component {
    render() {
        return (
            <div
                className={classNames(
                    styles.wrapper,
                    {
                        [styles.wrapperNoGapToPreviousFormRow]: this.props.noGapToPreviousFormRow,
                    },
                )}
            >
                {this.renderLabel()}
                <div className={styles.formRowInputTooltipWrapper}>
                    {this.renderContent()}
                    {this.renderTooltip()}
                </div>
            </div>
        );
    }

    renderContent = () => {
        return (
            <div className={styles.contentWrapper}>
                {this.props.children}
            </div>
        );
    };

    renderLabel = () => {
        return (
            <div className={styles.labelWrapper}>
                {this.props.label}
                {this.renderSubLabel()}
                {this.renderOptionalLabel()}
            </div>
        );
    };

    renderSubLabel = () => {
        return (
            <div className={styles.subLabelWrapper}>
                {this.props.subLabel}
            </div>
        );
    };

    renderOptionalLabel = () => {
        if (this.props.isOptional) {
            return (
                <span>
                    {'('}
                    {I18n.t('optional')}
                    {')'}
                </span>
            );
        }

        return null;
    };

    renderTooltip = () => {
        if (this.props.tooltipText) {
            return (
                <div className={styles.tooltipWrapper}>
                    <div className={styles.tooltipWrapperInner}>
                        <Tooltip
                            position={TooltipPosition.right}
                            text={this.props.tooltipText}
                            title={this.props.tooltipTitle}
                            theme={TooltipTheme.white}
                        />
                    </div>
                </div>
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = FormRow;

Component.propTypes = {
    children:               PropTypes.children,
    isOptional:             PropTypes.bool,
    label:                  PropTypes.string,
    noGapToPreviousFormRow: PropTypes.bool,
    subLabel:               PropTypes.string,
    tooltipText:            PropTypes.string,
    tooltipTitle:           PropTypes.string,
};

Component.defaultProps = {
    children:               null,
    isOptional:             false,
    label:                  '',
    noGapToPreviousFormRow: false,
    subLabel:               '',
    tooltipText:            '',
    tooltipTitle:           '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
