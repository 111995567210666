//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

class Url {
    static openInNewTab (url) {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');

        if (newWindow) {
            // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-218
        }
    }
}

export default Url;
