//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                            from 'lodash';
import I18n                         from 'i18next';
import OverlayButton                from '../../stateless/OverlayButton';
import OverlayButtonTheme           from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper         from '../../stateless/OverlayButtonWrapper';
import OverlayPane                  from '../../stateless/OverlayPane';
import PropTypes                    from '../../PropTypes';
import React                        from 'react';
import { bindActionCreators }       from 'redux';
import { connect }                  from 'react-redux';
import { CompanyActions }           from '../../../store/actions/company';
import CompanyForm                  from '../../stateless/CompanyForm';
import OverlayPaneState             from '../../stateless/OverlayPane/OverlayPaneState';
import { AlertBoxActions }          from '../../../store/actions/alertBox';
import { CreateEditProjectActions } from '../../../store/actions/createEditProject';
import AlertBoxManager              from '../AlertBoxManager';

class RegisterCompanyOverlayPane extends React.Component {
    cancelButtonPressed = () => {
        this.props.goBack();
    };

    backButtonPressed = () => {
        this.props.previousPane();
    };

    passwordCheckChanged = (event) => {
        this.setState({
            passwordCheck: event.target.value,
        });
    };

    nameChanged = (event) => {
        this.props.temporarySetOwnCompanyName({
            name: event.target.value,
        });
    };

    roleInCompanyChanged = (companyPosition) => {
        this.props.temporarySetOwnCompanyRoleInCompany({
            roleInCompany: companyPosition.value,
        });
    };

    websiteChanged = (event) => {
        this.props.temporarySetOwnCompanyWebsite({
            website: event.target.value,
        });
    };

    render() {
        return (
            <OverlayPane
                content={this.renderContent()}
                footer={this.renderFooter()}
                title={I18n.t('companyData')}
                showErrors={true}
                state={this.props.state}
                scrollingAllowed={true}
            />
        );
    }

    renderContent = () => {
        return (
            <>
                <CompanyForm
                    name={_.get(this.props, 'companyData.name', '')}
                    nameChanged={this.nameChanged}
                    nameError={this.props.companyNameErrorEmpty}
                    roleInCompany={_.get(this.props, 'companyData.roleInCompany', '')}
                    roleInCompanyChanged={this.roleInCompanyChanged}
                    website={_.get(this.props, 'companyData.website', '')}
                    websiteChanged={this.websiteChanged}
                    websiteError={this.props.websiteErrorEmpty}
                />
                <AlertBoxManager />
            </>
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.props.isFirst ? this.cancelButtonPressed : this.backButtonPressed}
                    standAlone={false}
                    text={this.props.isFirst ? I18n.t('cancel') : I18n.t('back')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.continueButtonPressed}
                    standAlone={false}
                    text={I18n.t('continue')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    continueButtonPressed = () => {
        this.props.clearAlerts();

        let isValid = true;

        if (!_.get(this.props, 'companyData.name')) {
            isValid = false;

            this.props.showErrorAlertTagged({
                tag:  'companyNameErrorEmpty',
                text: I18n.t('companyNameErrorEmpty'),
            });
        }

        if (!_.get(this.props, 'companyData.website')) {
            isValid = false;

            this.props.showErrorAlertTagged({
                tag:  'websiteErrorEmpty',
                text: I18n.t('websiteErrorEmpty'),
            });
        }

        if (isValid) {
            requestAnimationFrame(() => {
                this.props.nextPane();
            });
        }
    };
}

const Component = RegisterCompanyOverlayPane;

Component.propTypes = {
    companyData:           PropTypes.object,
    companyNameError:      PropTypes.string,
    companyNameErrorEmpty: PropTypes.string,
    onContinue:            PropTypes.func,
    state:                 PropTypes.oneOf(Object.values(OverlayPaneState)),
};

Component.defaultProps = {
    companyData:           null,
    companyNameError:      null,
    companyNameErrorEmpty: null,
    onContinue:            _.noop,
    state:                 null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        AlertBoxActions,
        CompanyActions,
        CreateEditProjectActions,
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        companyData:           _.get(state, 'company.ownCompanyEditContext', {}),
        companyNameErrorEmpty: _.get(state, 'alertBox.alertBoxesTagged.companyNameErrorEmpty'),
        websiteErrorEmpty:     _.get(state, 'alertBox.alertBoxesTagged.websiteErrorEmpty'),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
