//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import classNames      from 'classnames';
import ComponentHelper from '../../../helper/ComponentHelper';
import I18n            from 'i18next';
import PropTypes       from '../../PropTypes';
import styles          from './styles.module.scss';
import TextHelper      from '../../../helper/Text';

class TextRow extends React.Component {
    getText = () => {
        if (this.props.isPassword) {
            return I18n.t('passwordPlaceholder');
        }

        if (this.props.text) {
            return TextHelper.cleanup(this.props.text);
        }

        if (this.props.fallbackText) {
            return this.props.fallbackText;
        }

        return null;
    };

    render () {
        return (
            <div className={styles.wrapper}>
                {this.renderLabel()}
                {this.renderText()}
                {this.renderChildren()}
            </div>
        );
    }

    renderChildren = () => {
        if (this.props.children) {
            return this.props.children;
        }

        return null;
    };

    renderText = () => {
        const text = this.getText();

        if (text) {
            return (
                <div
                    className={classNames(
                        styles.textWrapper,
                        {
                            [styles.textUndefined]: !this.props.text && this.props.fallbackText,
                        },
                    )}
                >
                    {text}
                </div>
            );
        }

        return null;
    };

    renderLabel = () => {
        return (
            <div
                className={styles.labelWrapper}
            >
                {this.props.label}
            </div>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = TextRow;

Component.propTypes = {
    children:     PropTypes.children,
    fallbackText: PropTypes.string,
    isPassword:   PropTypes.bool,
    label:        PropTypes.string,
    text:         PropTypes.string,
};

Component.defaultProps = {
    children:     null,
    fallbackText: '',
    isPassword:   false,
    label:        '',
    text:         '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;