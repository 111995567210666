//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import styles           from './styles.module.scss';
import PropTypes        from '../../PropTypes';
import Cast             from '../../../helper/Cast';
import ComponentHelper  from '../../../helper/ComponentHelper';
import { Slider }       from 'react-compound-slider';
import { Rail }         from 'react-compound-slider';
import { Handles }      from 'react-compound-slider';
import { Tracks }       from 'react-compound-slider';
import _                from 'lodash';
import RangeSliderTheme from './RangeSliderTheme';
import classNames       from 'classnames';

class RangeSlider extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            domain: this.getDomain(),
        };
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        this.setState({
            domain: this.getDomain(),
        });
    }

    getDomain = () => {
        return [
            this.props.minimumValue,
            this.props.maximumValue,
        ];
    };

    render () {
        return (
            <Slider
                mode={1}
                step={this.props.step}
                className={styles.wrapper}
                domain={this.state.domain}
                reversed={false}
                onChange={this.props.onChange}
                onUpdate={this.props.onChange}
                values={[
                    this.props.valueStart,
                    this.props.valueEnd,
                ]}
            >
                <Rail>
                    {this.renderRail}
                </Rail>
                {this.renderHandles()}
                {this.renderTracks()}
            </Slider>
        );
    }

    renderHandle = (handle, getHandleProps) => {
        const handleStyle = {
            left: Cast.percentString(handle.percent),
        };

        return (
            <div
                key={handle.id}
                style={handleStyle}
                className={classNames(
                    styles.handle,
                    {
                        [styles.handleDefault]:     this.props.theme === RangeSliderTheme.default,
                        [styles.handleUnimportant]: this.props.theme === RangeSliderTheme.unimportant,
                    },
                )}
                {...getHandleProps()}
            />
        );
    };

    renderHandles = () => {
        return (
            <Handles>
                {({ handles, getHandleProps }) => (
                    <div className={styles.handles}>
                        {handles.map(handle => {
                            return this.renderHandle(handle, getHandleProps);
                        })}
                    </div>
                )}
            </Handles>
        );
    };

    renderTrack = (id, source, target, getTrackProps) => {
        const trackStyle = {
            left:  Cast.percentString(source.percent),
            width: Cast.percentString(target.percent - source.percent),
        };

        return (
            <div
                key={id}
                className={classNames(
                    styles.track,
                    {
                        [styles.trackDefault]:     this.props.theme === RangeSliderTheme.default,
                        [styles.trackUnimportant]: this.props.theme === RangeSliderTheme.unimportant,
                    },
                )}
                style={trackStyle}
            />
        );
    };

    renderTracks = () => {
        return (
            <Tracks
                left={false}
                right={false}
            >
                {({ tracks, getTrackProps }) => (
                    <div className={styles.tracks}>
                        {tracks.map(({ id, source, target }) => {
                            return this.renderTrack(
                                id,
                                source,
                                target,
                                getTrackProps,
                            );
                        })}
                    </div>
                )}
            </Tracks>
        );
    };

    renderRail = ({ getRailProps }) => {
        return (
            <div
                className={styles.rail}
                {...getRailProps()}
            >
                <div className={styles.railInner} />
            </div>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = RangeSlider;

Component.propTypes = {
    maximumValue: PropTypes.number,
    minimumValue: PropTypes.number,
    onChange:     PropTypes.func,
    step:         PropTypes.number,
    theme:        PropTypes.oneOf(Object.values(RangeSliderTheme)),
    valueEnd:     PropTypes.number,
    valueStart:   PropTypes.number,
};

Component.defaultProps = {
    maximumValue: 1337,
    minimumValue: 0,
    onChange:     _.noop,
    step:         1,
    theme:        RangeSliderTheme.default,
    valueEnd:     42,
    valueStart:   100,
};

Component.renderAffectingProps = [
    'maximumValue',
    'minimumValue',
    'theme',
    'valueEnd',
    'valueStart',
];

Component.renderAffectingStates = [
    'valueEnd',
    'valueStart',
];

export default Component;