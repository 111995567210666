//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _               from 'lodash';
import Avatar          from '../../connected/Avatar';
import AvatarSize      from '../Avatar/AvatarSize';
import classNames      from 'classnames';
import ComponentHelper from '../../../helper/ComponentHelper';
import Icon            from '../Icon';
import IconType        from '../Icon/IconType';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';

class ContentItem extends React.Component {
    render () {
        return (
            <div
                className={classNames(
                    styles.wrapper,
                    {
                        [styles.wrapperHighlighted]: this.props.highlighted,
                    },
                )}
                onClick={this.props.onClick}
            >
                <div className={styles.header}>
                    <div className={styles.headerLeft}>
                        {this.renderAvatar()}
                        <div className={styles.textWrapper}>
                            <h3>
                                {this.props.title}
                            </h3>
                            {
                                this.props.subTitle ? (
                                    <strong>
                                        {this.props.subTitle}
                                    </strong>
                                ) : (
                                    <strong className={styles.textUndefined}>
                                        {this.props.subTitleFallback}
                                    </strong>
                                )
                            }
                        </div>
                    </div>
                    {this.renderHeaderCenter()}
                    {this.renderHeaderRight()}
                    {this.renderToggle()}
                </div>
                {this.renderContent()}
            </div>
        );
    }

    renderAvatar = () => {
        return (
            <div className={styles.avatarWrapper}>
                <Avatar
                    company={this.props.company}
                    size={AvatarSize.large}
                />
            </div>
        );
    };

    renderContent = () => {
        if (this.props.content) {
            const contentStyle = {};

            if (this.props.toggleContentOpened) {
                contentStyle.minHeight = this.props.toggleContentHeight;
            }

            return (
                <div
                    className={classNames(
                        styles.content,
                        {
                            [styles.contentClosed]: this.props.toggleContentEnabled && !this.props.toggleContentOpened,
                            [styles.contentOpened]: this.props.toggleContentEnabled && this.props.toggleContentOpened,
                        },
                    )}
                    style={contentStyle}
                >
                    <div className={styles.contentInner}>
                        {this.props.content}
                    </div>
                </div>
            );
        }

        return null;
    };

    renderHeaderCenter = () => {
        if (this.props.headerCenterContent) {
            return (
                <div className={styles.headerCenter}>
                    {this.props.headerCenterContent}
                </div>
            );
        }

        return null;
    };

    renderHeaderRight = () => {
        if (this.props.headerRightContent) {
            return (
                <div className={styles.headerRight}>
                    {this.props.headerRightContent}
                </div>
            );
        }

        return null;
    };

    renderToggle = () => {
        if (this.props.toggleContentEnabled) {
            return (
                <div
                    className={classNames(
                        styles.headerToggle,
                        {
                            [styles.headerToggleFlipped]: this.props.toggleContentOpened,
                        },
                    )}
                    onClick={this.props.toggleContentButtonClicked}
                >
                    <Icon iconType={IconType.chevronDown} />
                </div>
            );
        }

        return null;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ContentItem;

Component.propTypes = {
    company:                    PropTypes.object,
    content:                    PropTypes.children,
    headerCenterContent:        PropTypes.children,
    headerRightContent:         PropTypes.children,
    highlighted:                PropTypes.bool,
    onClick:                    PropTypes.func,
    subTitle:                   PropTypes.string,
    subTitleFallback:           PropTypes.string,
    title:                      PropTypes.string,
    toggleContentButtonClicked: PropTypes.func,
    toggleContentEnabled:       PropTypes.bool,
    toggleContentHeight:        PropTypes.number,
    toggleContentOpened:        PropTypes.bool,
};

Component.defaultProps = {
    company:                    null,
    content:                    null,
    headerCenterContent:        null,
    headerRightContent:         null,
    highlighted:                false,
    onClick:                    _.noop,
    subTitle:                   '',
    subTitleFallback:           '',
    title:                      '',
    toggleContentButtonClicked: _.noop,
    toggleContentEnabled:       false,
    toggleContentHeight:        0,
    toggleContentOpened:        false,
};

Component.renderAffectingProps = [
    'company',
    'content',
    'headerCenterContent',
    'headerRightContent',
    'highlighted',
    'subTitle',
    'subTitleFallback',
    'title',
    'toggleContentEnabled',
    'toggleContentOpened',
    'toggleContentHeight',
];

Component.renderAffectingStates = [];

export default Component;