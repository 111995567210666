//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as Api                     from '../../api';
import _                            from 'lodash';
import axios                        from 'axios';
import CompanyTypesConstants        from '../../constants/CompanyTypes';
import HydraHelper                  from '../../helper/Hydra';
import I18n                         from 'i18next';
import OverlayManager               from '../../components/connected/OverlayManager';
import Overlays                     from '../../constants/Overlays';
import Routes                       from '../../constants/Routes';
import { AlertBoxActions }          from '../actions/alertBox';
import { call }                     from 'redux-saga/effects';
import { cancelled }                from 'redux-saga/effects';
import { put }                      from 'redux-saga/effects';
import { race }                     from 'redux-saga/effects';
import { select }                   from 'redux-saga/effects';
import { take }                     from 'redux-saga/effects';
import { CompanyActions }           from '../actions/company';
import { CompanyTypes }             from '../actions/company';
import { CreateEditProjectActions } from '../actions/createEditProject';
import { CreateEditProjectTypes }   from '../actions/createEditProject';
import { eventChannel }             from 'redux-saga';
import { push }                     from 'connected-react-router';
import { UserActions }              from '../actions/user';
import { UserTypes }                from '../actions/user';

const afterLoginRoute             = Routes.home;
const uploadFileCancelToken       = axios.CancelToken;
const uploadFileCancelTokenSource = uploadFileCancelToken.source();

const createCompany = function* () {
    const companyData = yield select(state => state.company.ownCompanyEditContext);
    const response    = yield call(
        Api.createCompany,
        companyData,
    );

    if (response.ok) {
        yield put(CompanyActions.createCompanySuccess({
            company: response.data,
        }));
    } else {
        yield put(CompanyActions.createCompanyFailed());
    }
};

const createCompanyFailed = function* () {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('companyCreateError'),
    }));
};

const createCompanyAndUser = function* () {
    function* createUser() {
        yield put(UserActions.createUser());

        return yield race({
            failure:      take(UserTypes.CREATE_USER_FAILED),
            loginFailure: take(UserTypes.LOGIN_FAILED),
            loginSuccess: take(UserTypes.LOGIN_SUCCEEDED),
        });
    }

    const {
              failure,
              loginFailure,
              loginSuccess,
          } = yield call(createUser);

    if (loginSuccess) {
        function* createCompany() {
            yield put(CompanyActions.createCompany());

            return yield race({
                failure: take(CompanyTypes.CREATE_COMPANY_FAILED),
                success: take(CompanyTypes.CREATE_COMPANY_SUCCESS),
            });
        }

        const user = yield select(state => state.user.user);

        yield put(CompanyActions.temporarySetOwnCompanyAdminUser({
            userIri: user.iri,
        }));

        const { success, failure } = yield call(createCompany);

        if (success) {
            yield put(push(afterLoginRoute));

            const company     = yield select(state => state.company.ownCompany);
            const companyType = _.get(company, 'companyType');

            if (companyType === CompanyTypesConstants.videoProduction) {
                yield put(UserActions.registerVideoProductionSuccess());
            }

            yield put(CompanyActions.createCompanyAndUserSuccess());
        } else if (failure) {
            yield put(CompanyActions.createCompanyAndUserFailed());
            yield put(AlertBoxActions.showErrorAlert({
                text: I18n.t('companyCreateError'),
            }));
        }
    } else if (failure) {
        yield put(CompanyActions.createCompanyAndUserFailed());
        yield put(AlertBoxActions.showErrorAlertTagged({
            tag:  'userEmailError',
            text: I18n.t('userEmailAlreadyRegisteredError'),
        }));
        yield put(CreateEditProjectActions.setPaneIndex({
            index: 0,
        }));
    } else if (loginFailure) {
        yield put(CompanyActions.createCompanyAndUserFailed());
        yield put(AlertBoxActions.showErrorAlert({
            text: I18n.t('loginErrorUserCreate'),
        }));
    }
};

const createCompanyAndUserAndProject = function* () {
    const companyLocations = yield select(state => state.company.ownCompany.companyLocations);

    function* createUser() {
        yield put(UserActions.createUser());

        return yield race({
            failure:      take(UserTypes.CREATE_USER_FAILED),
            loginFailure: take(UserTypes.LOGIN_FAILED),
            loginSuccess: take(UserTypes.LOGIN_SUCCEEDED),
        });
    }

    const {
              failure,
              loginFailure,
              loginSuccess,
          } = yield call(createUser);

    if (loginSuccess) {
        function* createCompany() {
            yield put(CompanyActions.createCompany());

            return yield race({
                failure: take(CompanyTypes.CREATE_COMPANY_FAILED),
                success: take(CompanyTypes.CREATE_COMPANY_SUCCESS),
            });
        }

        const user = yield select(state => state.user.user);

        yield put(CompanyActions.temporarySetOwnCompanyAdminUser({
            userIri: user.iri,
        }));

        const { success, failure } = yield call(createCompany);

        if (success) {
            function* createLocation() {
                yield put(CompanyActions.createOrUpdateOwnCompanyLocations({
                    locations: companyLocations,
                }));

                return yield race({
                    failure: take(CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_LOCATIONS_FAILED),
                    success: take(CompanyTypes.CREATE_OR_UPDATE_OWN_COMPANY_LOCATIONS_SUCCESS),
                });
            }

            const { success, failure } = yield call(createLocation);

            if (success) {
                function* createProject() {
                    yield put(CreateEditProjectActions.createProject());

                    return yield race({
                        failure: take(CreateEditProjectTypes.CREATE_PROJECT_FAILED),
                        success: take(CreateEditProjectTypes.CREATE_PROJECT_SUCCESS),
                    });
                }

                const { success, failure } = yield call(createProject);

                if (success) {
                    yield put(CompanyActions.createCompanyAndUserAndProjectSuccess());
                    yield put(push(afterLoginRoute));
                } else if (failure) {
                    yield put(AlertBoxActions.showErrorAlert({
                        text: I18n.t('projectCreateError'),
                    }));
                }
            } else if (failure) {
                yield put(CompanyActions.createCompanyAndUserAndProjectFailed());
                yield put(AlertBoxActions.showErrorAlert({
                    text: I18n.t('locationCreateError'),
                }));
            }
        } else if (failure) {
            yield put(CompanyActions.createCompanyAndUserAndProjectFailed());
            yield put(AlertBoxActions.showErrorAlert({
                text: I18n.t('companyCreateError'),
            }));
        }
    } else if (failure) {
        yield put(CompanyActions.createCompanyAndUserAndProjectFailed());
        yield put(AlertBoxActions.showErrorAlertTagged({
            tag:  'userEmailError',
            text: I18n.t('userEmailAlreadyRegisteredError'),
        }));
    } else if (loginFailure) {
        yield put(CompanyActions.createCompanyAndUserAndProjectFailed());
        yield put(AlertBoxActions.showErrorAlert({
            text: I18n.t('loginErrorUserCreate'),
        }));
    }
};

const createOrDeleteCompanyImages = function* () {
    const company        = yield select(state => state.company.ownCompanyEditContext);
    const companyImages  = company.companyImages;
    const imagesToDelete = _.filter(companyImages, { delete: true });
    const newImages      = _.reject(companyImages, { delete: true });
    let errorOccurred    = false;

    for (const image of imagesToDelete) {
        const response = yield call(
            Api.deleteCompanyImages,
            image.iri,
        );

        if (!response.ok) {
            errorOccurred = true;
        }
    }

    for (const image of newImages) {
        const response = yield call(
            Api.updateCompanyImage,
            image.iri,
            company.iri,
        );

        if (!response.ok) {
            errorOccurred = true;
        }
    }

    if (!errorOccurred) {
        yield put(CompanyActions.createOrDeleteCompanyImagesSuccess());
    } else {
        yield put(CompanyActions.createOrDeleteCompanyImagesFailed());
    }
};

const createOrDeleteCompanyImagesSuccess = function* () {
    const company = yield select(state => state.company.ownCompany);

    yield put(CompanyActions.fetchCompany({ companyId: company.iri }));
};

const createOrUpdateOwnCompanyLocations = function* (action) {
    let errorOccurred         = false;
    const locations           = _.reject(action.locations, _.isEmpty);
    const ownCompany          = yield select(state => state.company.ownCompany);
    const ownCompanyLocations = _.get(ownCompany, 'companyLocations', []);
    const newLocations        = _.filter(locations, (location) => !location.iri);
    const oldLocations        = _.reject(locations, (location) => !location.iri);
    const removedLocations    = _.reject(ownCompanyLocations, ref1 => locations.some(ref2 => ref1.iri === ref2.iri));

    for (const location of newLocations) {
        const response = yield call(
            Api.postLocation,
            ownCompany.iri,
            location.address,
        );

        if (!response.ok) {
            errorOccurred = true;
        }
    }

    for (const location of oldLocations) {
        const response = yield call(
            Api.updateLocation,
            location.iri,
            location,
        );

        if (!response.ok) {
            errorOccurred = true;
        }
    }

    for (const location of removedLocations) {
        const response = yield call(
            Api.deleteLocation,
            location.iri,
        );

        if (!response.ok) {
            errorOccurred = true;
        }
    }

    if (!errorOccurred) {
        yield put(CompanyActions.createOrUpdateOwnCompanyLocationsSuccess());
    } else {
        yield put(CompanyActions.createOrUpdateOwnCompanyLocationsFailed());
    }
};

const createOrUpdateOwnCompanyLocationsSuccess = function* () {
    const company = yield select(state => state.company.ownCompany);

    yield put(CompanyActions.fetchCompany({ companyId: company.iri }));
};

const createOrUpdateOwnCompanySocialMedias = function* (action) {
    let errorOccurred            = false;
    const socialMedias           = _.reject(action.socialMedias, _.isEmpty);
    const ownCompany             = yield select(state => state.company.ownCompany);
    const ownCompanySocialMedias = _.get(ownCompany, 'socialMedias', []);
    const newSocialMedias        = _.filter(socialMedias, (socialMedia) => !socialMedia.iri);
    const oldSocialMedias        = _.reject(socialMedias, (socialMedia) => !socialMedia.iri);
    const removedSocialMedias    = _.reject(
        ownCompanySocialMedias,
        ownCompanySocialMedia => socialMedias.some(socialMedia => ownCompanySocialMedia.iri === socialMedia.iri),
    );

    for (const socialMedia of newSocialMedias) {
        const response = yield call(
            Api.postSocialMedia,
            ownCompany.iri,
            socialMedia.url,
        );

        if (!response.ok) {
            errorOccurred = _.indexOf(socialMedias, socialMedia);
        }
    }

    for (const socialMedia of oldSocialMedias) {
        const response = yield call(
            Api.updateSocialMedia,
            socialMedia.iri,
            socialMedia,
        );

        if (!response.ok) {
            errorOccurred = _.indexOf(socialMedias, socialMedia);
        }
    }

    for (const socialMedia of removedSocialMedias) {
        const response = yield call(
            Api.deleteSocialMedia,
            socialMedia.iri,
        );

        if (!response.ok) {
            errorOccurred = true;
        }
    }

    if (!errorOccurred) {
        yield put(CompanyActions.createOrUpdateOwnCompanySocialMediasSuccess());
    } else {
        yield put(CompanyActions.createOrUpdateOwnCompanySocialMediasFailed({
            index: errorOccurred,
        }));
    }
};

const createOrUpdateOwnCompanySocialMediasFailed = function* (action) {
    yield put(AlertBoxActions.showErrorAlertTagged({
        tag:   'social_media',
        index: action.index,
        text:  I18n.t('socialMediaParseError'),
    }));
};

const createOrUpdateOwnCompanySocialMediasSuccess = function* () {
    const company = yield select(state => state.company.ownCompany);
    const route   = Routes.companyProfilePart + _.get(company, 'id');

    // We do not fetch the company profile here, because it will be fetched in the 'locationChange' after this.
    yield put(push(route));
};

const createOrUpdateOwnCompanyReferences = function* (action) {
    let errorOccurred          = false;
    const references           = _.reject(action.references, _.isEmpty);
    const ownCompany           = yield select(state => state.company.ownCompany);
    const ownCompanyReferences = _.get(ownCompany, 'references', []);
    const newReferences        = _.filter(references, (reference) => !reference.iri);
    const oldReferences        = _.reject(references, (reference) => !reference.iri);
    const removedReferences    = _.reject(
        ownCompanyReferences,
        ownCompanyReference => references.some(reference => ownCompanyReference.iri === reference.iri),
    );

    for (const reference of removedReferences) {
        const response = yield call(
            Api.deleteReference,
            reference.iri,
        );

        if (!response.ok) {
            errorOccurred = true;
        }
    }

    for (const reference of oldReferences) {
        const response = yield call(
            Api.updateReference,
            reference.iri,
            reference.videoUrl,
            _.indexOf(references, reference),
        );

        if (!response.ok) {
            errorOccurred = true;
        }
    }

    for (const reference of newReferences) {
        const response = yield call(
            Api.postReference,
            ownCompany.iri,
            reference.videoUrl,
            _.indexOf(references, reference),
        );

        if (!response.ok) {
            errorOccurred = true;
        }
    }

    if (!errorOccurred) {
        yield put(CompanyActions.createOrUpdateOwnCompanyReferencesSuccess());
    } else {
        yield put(CompanyActions.createOrUpdateOwnCompanyReferencesFailed());
    }
};

const createOrUpdateOwnCompanyReferencesSuccess = function* () {
    const company = yield select(state => state.company.ownCompany);

    yield put(CompanyActions.fetchCompany({ companyId: company.iri }));
};

const editOwnCompanyCompetences = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.editCompanyCompetences)));
};

const editOwnCompanyHeader = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.editCompanyHeader)));
};

const editOwnCompanyImages = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.editCompanyImages)));
};

const editOwnCompanyInformation = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.editCompanyDescription)));
};

const editOwnCompanyLanguages = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.editCompanyLanguages)));
};

const editOwnCompanyLocations = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.editCompanyLocations)));
};

const editOwnCompanyPlaceOfWorks = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.editCompanyPlaceOfWorks)));
};

const editOwnCompanyReferences = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.editCompanyReferences)));
};

const editOwnCompanySocialMedias = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.editCompanySocialMedias)));
};

const fetchCompany = function* ({ companyId }) {
    const response = yield call(
        Api.fetchCompany,
        companyId,
    );

    if (response.ok) {
        const company = HydraHelper.cleanupObject(response.data);

        yield put(CompanyActions.fetchCompanySuccess({
            company,
        }));
    } else {
        yield put(CompanyActions.fetchCompanyFailed({ companyId }));
    }
};

const fetchOwnCompany = function* ({ companyId }) {
    const response = yield call(
        Api.fetchCompany,
        companyId,
    );

    if (response.ok) {
        const company = HydraHelper.cleanupObject(response.data);

        yield put(CompanyActions.fetchOwnCompanySuccess({
            company,
        }));
    } else {
        yield put(CompanyActions.fetchOwnCompanyFailed());
    }
};

const updateCompany = function* (action) {
    const company  = yield select(state => state.company.ownCompany);
    const response = yield call(
        Api.updateCompany,
        company.iri,
        action.data,
    );

    if (response.ok) {
        const company = HydraHelper.cleanupObject(response.data);

        yield put(CompanyActions.updateCompanySuccess({
            company,
        }));
    } else {
        yield put(CompanyActions.updateCompanyFailed());
    }
};

const updateCompanyFailed = function* () {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('companyUpdateError'),
    }));
};

function* uploadCompanyBannerInternal(action) {
    // We clone the action here to allow parallel file uploads
    const clonedAction = _.cloneDeep(action);

    return eventChannel((emitter) => {
        console.log('uploadFileInternal', clonedAction);

        const uploadPromise = Api.uploadCompanyBanner(
            clonedAction.file,
            (uploadState) => {
                const progress = uploadState.loaded / uploadState.total * 100;

                console.log('uploadFileInternal: uploadState', uploadState);

                emitter(getCompanyImageInternalResponse(
                    clonedAction,
                    false,
                    false,
                    progress,
                    null,
                ));
            },
        );

        uploadPromise.then((response) => {
            console.log('uploadFileInternal: uploadPromise: then', response);

            if (response.ok) {
                const cleanHydraResponse = HydraHelper.cleanupObject(response.data);

                emitter(getCompanyImageInternalResponse(
                    clonedAction,
                    true,
                    false,
                    100,
                    cleanHydraResponse.iri,
                ));
            } else {
                emitter(getCompanyImageInternalResponse(
                    clonedAction,
                    true,
                    true,
                    100,
                    null,
                ));
            }
        });

        return () => {
            uploadFileCancelTokenSource.cancel();
        };
    });
}

function* uploadCompanyImageInternal(action) {
    // We clone the action here to allow parallel file uploads
    const clonedAction = _.cloneDeep(action);

    return eventChannel((emitter) => {
        console.log('uploadFileInternal', clonedAction);

        const uploadPromise = Api.uploadCompanyImage(
            clonedAction.file,
            clonedAction.name,
            (uploadState) => {
                const progress = uploadState.loaded / uploadState.total * 100;

                console.log('uploadFileInternal: uploadState', uploadState);

                emitter(getCompanyImageInternalResponse(
                    clonedAction,
                    false,
                    false,
                    progress,
                    null,
                ));
            },
        );

        uploadPromise.then((response) => {
            console.log('uploadFileInternal: uploadPromise: then', response);

            if (response.ok) {
                const cleanHydraResponse = HydraHelper.cleanupObject(response.data);

                emitter(getCompanyImageInternalResponse(
                    clonedAction,
                    true,
                    false,
                    100,
                    cleanHydraResponse.iri,
                    cleanHydraResponse.name,
                ));
            } else {
                emitter(getCompanyImageInternalResponse(
                    clonedAction,
                    true,
                    true,
                    100,
                    null,
                ));
            }
        });

        return () => {
            uploadFileCancelTokenSource.cancel();
        };
    });
}

function* uploadCompanyLogoInternal(action) {
    // We clone the action here to allow parallel file uploads
    const clonedAction = _.cloneDeep(action);

    return eventChannel((emitter) => {
        console.log('uploadFileInternal', clonedAction);

        const uploadPromise = Api.uploadCompanyLogo(
            clonedAction.file,
            (uploadState) => {
                const progress = uploadState.loaded / uploadState.total * 100;

                console.log('uploadFileInternal: uploadState', uploadState);

                emitter(getCompanyImageInternalResponse(
                    clonedAction,
                    false,
                    false,
                    progress,
                    null,
                ));
            },
        );

        uploadPromise.then((response) => {
            console.log('uploadFileInternal: uploadPromise: then', response);

            if (response.ok) {
                const cleanHydraResponse = HydraHelper.cleanupObject(response.data);

                emitter(getCompanyImageInternalResponse(
                    clonedAction,
                    true,
                    false,
                    100,
                    cleanHydraResponse.iri,
                ));
            } else {
                emitter(getCompanyImageInternalResponse(
                    clonedAction,
                    true,
                    true,
                    100,
                    null,
                ));
            }
        });

        return () => {
            uploadFileCancelTokenSource.cancel();
        };
    });
}

// TODO: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-301
function getCompanyImageInternalResponse(action, complete, error, progress, iri, name) {
    const response = {
        complete,
        error,
        id: action.id,
        iri,
        name,
        progress,
    };

    return response;
}

const updateCompanySuccess = function* (action) {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('companyEditProfileSuccess'),
    }));
};

// TODO: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-357
const updateOwnCompany = function* ({ companyId }) {
    const ownCompany = yield select(state => state.company.ownCompany);

    const response = yield call(
        Api.updateCompany,
        ownCompany.iri,
        {
            name:          ownCompany.name,
            website:       ownCompany.website,
            roleInCompany: ownCompany.roleInCompany,
        },
    );

    if (response.ok) {
        const company = HydraHelper.cleanupObject(response.data);

        yield put(CompanyActions.updateOwnCompanySuccess({
            company,
        }));
    } else {
        yield put(CompanyActions.updateOwnCompanyFailed());
    }
};

const updateOwnCompanyFailed = function* () {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('companyUpdateError'),
    }));
};

const uploadCompanyBanner = function* (action) {
    console.log('uploadFile: start', action);

    const uploadChannel = yield call(uploadCompanyBannerInternal, action);

    try {
        while (true) {
            const uploadProgressData = yield take(uploadChannel);

            console.log('uploadProgressData', uploadProgressData);

            yield put(CompanyActions.updateCompanyUploadProgress({
                id:       uploadProgressData.id,
                progress: uploadProgressData.progress,
            }));

            if (uploadProgressData.complete) {
                if (uploadProgressData.error) {
                    yield put(CompanyActions.uploadCompanyBannerFailed({
                        id: uploadProgressData.id,
                    }));
                } else {
                    yield put(CompanyActions.uploadCompanyBannerSucceeded({
                        id:  uploadProgressData.id,
                        iri: uploadProgressData.iri,
                    }));
                }
            }
        }
    } finally {
        if (yield cancelled()) {
            uploadChannel.close();
        }
    }
};

const uploadCompanyImage = function* (action) {
    console.log('uploadFile: start', action);

    const uploadChannel = yield call(uploadCompanyImageInternal, action);

    try {
        while (true) {
            const uploadProgressData = yield take(uploadChannel);

            console.log('uploadProgressData', uploadProgressData);

            yield put(CompanyActions.updateCompanyUploadProgress({
                id:       uploadProgressData.id,
                progress: uploadProgressData.progress,
            }));

            if (uploadProgressData.complete) {
                if (uploadProgressData.error) {
                    yield put(CompanyActions.uploadCompanyImageFailed({
                        id: uploadProgressData.id,
                    }));
                } else {
                    yield put(CompanyActions.uploadCompanyImageSucceeded({
                        id:   uploadProgressData.id,
                        iri:  uploadProgressData.iri,
                        name: uploadProgressData.name,
                    }));
                }
            }
        }
    } finally {
        if (yield cancelled()) {
            uploadChannel.close();
        }
    }
};

const uploadCompanyLogo = function* (action) {
    console.log('uploadFile: start', action);

    const uploadChannel = yield call(uploadCompanyLogoInternal, action);

    try {
        while (true) {
            const uploadProgressData = yield take(uploadChannel);

            console.log('uploadProgressData', uploadProgressData);

            yield put(CompanyActions.updateCompanyUploadProgress({
                id:       uploadProgressData.id,
                progress: uploadProgressData.progress,
            }));

            if (uploadProgressData.complete) {
                if (uploadProgressData.error) {
                    yield put(CompanyActions.uploadCompanyLogoFailed({
                        id: uploadProgressData.id,
                    }));
                } else {
                    yield put(CompanyActions.uploadCompanyLogoSucceeded({
                        id:  uploadProgressData.id,
                        iri: uploadProgressData.iri,
                    }));
                }
            }
        }
    } finally {
        if (yield cancelled()) {
            uploadChannel.close();
        }
    }
};

export default {
    createCompany,
    createCompanyFailed,
    createCompanyAndUser,
    createCompanyAndUserAndProject,
    createOrDeleteCompanyImages,
    createOrDeleteCompanyImagesSuccess,
    createOrUpdateOwnCompanyLocations,
    createOrUpdateOwnCompanyLocationsSuccess,
    createOrUpdateOwnCompanyReferences,
    createOrUpdateOwnCompanyReferencesSuccess,
    createOrUpdateOwnCompanySocialMedias,
    createOrUpdateOwnCompanySocialMediasFailed,
    createOrUpdateOwnCompanySocialMediasSuccess,
    editOwnCompanyCompetences,
    editOwnCompanyHeader,
    editOwnCompanyImages,
    editOwnCompanyInformation,
    editOwnCompanyLanguages,
    editOwnCompanyLocations,
    editOwnCompanyPlaceOfWorks,
    editOwnCompanyReferences,
    editOwnCompanySocialMedias,
    fetchCompany,
    fetchOwnCompany,
    updateCompany,
    updateCompanyFailed,
    updateCompanySuccess,
    updateOwnCompany,
    updateOwnCompanyFailed,
    uploadCompanyBanner,
    uploadCompanyImage,
    uploadCompanyLogo,
};
