//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                        from 'react';
import _                            from 'lodash';
import ComponentHelper              from '../../../helper/ComponentHelper';
import DropDownDirection            from '../../stateless/DropDown/DropDownDirection';
import ErrorAwareOverlayPane        from '../../connected/ErrorAwareOverlayPane';
import FormRow                      from '../../stateless/FormRow';
import GA                           from '../../../helper/GA';
import I18n                         from 'i18next';
import Input                        from '../../stateless/Input';
import InputSize                    from '../../stateless/Input/InputSize';
import InputType                    from '../../stateless/Input/InputType';
import OverlayButton                from '../../stateless/OverlayButton';
import OverlayButtonTheme           from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper         from '../../stateless/OverlayButtonWrapper';
import OverlayPaneState             from '../../stateless/OverlayPane/OverlayPaneState';
import PropTypes                    from '../../PropTypes';
import RoleInCompanyDropDown        from '../RoleInCompanyDropDown';
import { AlertBoxActions }          from '../../../store/actions/alertBox';
import { bindActionCreators }       from 'redux';
import { CompanyActions }           from '../../../store/actions/company';
import { connect }                  from 'react-redux';
import { CreateEditProjectActions } from '../../../store/actions/createEditProject';

class CreateProjectOverlayCompanyPane extends React.Component {
    eventLabel = 'Company';

    backButtonPressed = () => {
        GA.sendCreateProjectPreviousEvent(this.eventLabel);
        this.props.clearAlerts();
        this.props.previousPane();
    };

    continueButtonPressed = () => {
        this.props.clearAlerts();

        if (!this.props.roleInCompany) {
            this.props.showErrorAlertTagged({
                tag:  'roleInCompany',
                text: I18n.t('roleInCompanyErrorEmpty'),
            });
        }

        if (!this.props.website) {
            this.props.showErrorAlertTagged({
                tag:  'website',
                text: I18n.t('websiteErrorEmpty'),
            });
        }

        if (!this.props.name) {
            this.props.showErrorAlertTagged({
                tag:  'name',
                text: I18n.t('companyNameErrorEmpty'),
            });
        }

        requestAnimationFrame(() => {
            if (this.props.alertBoxesTaggedCount === 0) {
                GA.sendCreateProjectNextEvent(this.eventLabel);
                this.props.updateOwnCompany();
                this.props.createProject();
            }
        });
    };

    nameChanged = (event) => {
        this.props.setOwnCompanyName({
            name: event.target.value,
        });
    };

    render() {
        return (
            <ErrorAwareOverlayPane
                content={this.renderContent()}
                footer={this.renderFooter()}
                title={I18n.t('myCompany')}
                showErrors={true}
                state={this.props.overlayPaneState}
                upcomingDistance={this.props.upcomingDistance}
            />
        );
    }

    renderContent = () => {
        return (
            <>
                {this.renderContentRoleInCompany()}
                {this.renderContentWebsite()}
                {this.renderContentName()}
            </>
        );
    };

    renderContentName = () => {
        return (
            <FormRow
                label={I18n.t('companyNameLabel')}
                tooltipText={I18n.t('companyNameTooltipText')}
            >
                <Input
                    errorText={this.props.nameError}
                    noMarginBottom={true}
                    onChange={this.nameChanged}
                    placeholder={I18n.t('defaultPlaceHolderTextInput')}
                    size={InputSize.singleLine}
                    type={InputType.text}
                    value={this.props.name}
                />
            </FormRow>
        );
    };

    renderContentRoleInCompany = () => {
        return (
            <FormRow
                label={I18n.t('roleInCompanyLabel')}
                tooltipText={I18n.t('roleInCompanyTooltipText')}
            >
                <RoleInCompanyDropDown
                    errorText={this.props.roleInCompanyError}
                    noMarginBottom={true}
                    onChange={this.roleInCompanyChanged}
                    onClear={this.roleInCompanyCleared}
                    selectedValue={_.get(this.props, 'roleInCompany')}
                    collapseDirection={DropDownDirection.down}
                />
            </FormRow>
        );
    };

    renderContentWebsite = () => {
        return (
            <FormRow
                label={I18n.t('websiteLabel')}
                tooltipText={I18n.t('websiteTooltipText')}
            >
                <Input
                    errorText={this.props.websiteError}
                    noMarginBottom={true}
                    onChange={this.websiteChanged}
                    placeholder={I18n.t('defaultPlaceHolderTextInput')}
                    size={InputSize.singleLine}
                    type={InputType.url}
                    value={this.props.website}
                />
            </FormRow>
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.backButtonPressed}
                    standAlone={false}
                    text={I18n.t('back')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.continueButtonPressed}
                    standAlone={false}
                    text={I18n.t('save')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    roleInCompanyChanged = (selectedOption) => {
        this.props.setOwnCompanyRoleInCompany({
            roleInCompany: selectedOption.value,
        });
    };

    roleInCompanyCleared = () => {
        this.props.setOwnCompanyRoleInCompany({
            roleInCompany: null,
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    websiteChanged = (event) => {
        this.props.setOwnCompanyWebsite({
            website: event.target.value,
        });
    };
}

const Component = CreateProjectOverlayCompanyPane;

Component.propTypes = {
    alertBoxesTaggedCount: PropTypes.number,
    name:                  PropTypes.string,
    nameError:             PropTypes.string,
    overlayPaneState:      PropTypes.oneOf(Object.values(OverlayPaneState)),
    roleInCompany:         PropTypes.string,
    roleInCompanyError:    PropTypes.string,
    upcomingDistance:      PropTypes.number,
    website:               PropTypes.string,
    websiteError:          PropTypes.string,
};

Component.defaultProps = {
    alertBoxesTaggedCount: 0,
    name:                  null,
    nameError:             null,
    overlayPaneState:      OverlayPaneState.upcoming,
    roleInCompany:         null,
    roleInCompanyError:    null,
    upcomingDistance:      0,
    website:               null,
    websiteError:          null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    AlertBoxActions,
    CompanyActions,
    CreateEditProjectActions,
), dispatch);

const mapStateToProps = state => (
    {
        alertBoxesTaggedCount: _.get(state, 'alertBox.alertBoxesTaggedCount', 0),
        name:                  _.get(state, 'company.ownCompany.name'),
        nameError:             _.get(state, 'alertBox.alertBoxesTagged.name'),
        roleInCompany:         _.get(state, 'company.ownCompany.roleInCompany'),
        roleInCompanyError:    _.get(state, 'alertBox.alertBoxesTagged.roleInCompany'),
        website:               _.get(state, 'company.ownCompany.website'),
        websiteError:          _.get(state, 'alertBox.alertBoxesTagged.website'),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
