//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React               from 'react';
import AlertBoxManager     from '../../connected/AlertBoxManager';
import Api                 from '../../../constants/Api';
import I18n                from 'i18next';
import Overlays            from '../../../constants/Overlays';
import PropTypes           from '../../../components/PropTypes';
import Routes              from '../../../constants/Routes';
import { withTranslation } from 'react-i18next';
import styles              from './styles.module.scss';

const allowSmallScreensOnRoutes = [
    Routes.error,
    Routes.login,
    Routes.mobileRegisterSuccess,
];

const allowSmallScreensOnOverlay = [
    Overlays.createProjectSuccess,
    Overlays.registerVideoProductionSuccess,
];

class Component extends React.Component {
    render() {
        if (
            !allowSmallScreensOnRoutes.includes(this.props.route) &&
            !allowSmallScreensOnOverlay.includes(this.props.overlay)
        ) {
            return (
                <div
                    aria-hidden={'true'}
                    className={styles.screenTooSmallOverlay}
                >
                    <a href={Api.getWebsiteUrl()}>
                        <span className={styles.screenTooSmallLogo}>
                            {I18n.t('framebutler')}
                        </span>
                    </a>
                    <div className={styles.screenTooSmallTextWrapper}>
                        <AlertBoxManager />
                        <h1>
                            {I18n.t('screenTooSmallTitle')}
                        </h1>
                        <p>
                            {I18n.t('screenTooSmallText')}
                        </p>
                    </div>
                </div>
            );
        }

        return null;
    }
}

Component.propTypes = {
    overlay: PropTypes.string,
    route:   PropTypes.string,
};

Component.defaultProps = {
    overlay: null,
    route:   null,
};

export default withTranslation()(Component);
