//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as Api            from '../../api';
import HydraHelper         from '../../helper/Hydra';
import I18n                from 'i18next';
import OverlayManager      from '../../components/connected/OverlayManager';
import Overlays            from '../../constants/Overlays';
import Routes              from '../../constants/Routes';
import { AlertBoxActions } from '../actions/alertBox';
import { call }            from 'redux-saga/effects';
import { ProjectActions }  from '../actions/project';
import { push }            from 'connected-react-router';
import { put }             from 'redux-saga/effects';
import { select }          from 'redux-saga/effects';

const deleteProject = function* () {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.deleteProject)));
};

const deleteProjectConfirm = function* () {
    const projectState       = yield select(state => state.project);
    const projectIriToDelete = projectState.projectIriToDelete;

    if (projectIriToDelete) {
        const response = yield call(
            Api.deleteProject,
            projectIriToDelete,
        );

        if (response.ok) {
            yield put(ProjectActions.deleteProjectSuccess());
        } else {
            yield put(ProjectActions.deleteProjectFailed());
        }
    }
};

const deleteProjectFailed = function* () {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('deleteProjectErrorMessage'),
    }));
};

const deleteProjectSuccess = function* () {
    yield put(ProjectActions.fetchProjects());
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('deleteProjectSuccessMessage'),
    }));
};

const fetchProjects = function* () {
    const response = yield call(
        Api.fetchProjects,
    );

    if (response.ok) {
        const cleanHydraResponse = HydraHelper.cleanupObject(response.data);
        const projects           = cleanHydraResponse.member;

        yield put(ProjectActions.fetchProjectsSuccess({
            projects,
        }));
    } else {
        yield put(ProjectActions.fetchProjectsFailed());
    }
};

const fetchProject = function* ({ projectIri }) {
    const response = yield call(
        Api.fetchProject,
        projectIri,
    );

    if (response.ok) {
        const project = HydraHelper.cleanupObject(response.data);

        yield put(ProjectActions.fetchProjectSuccess({
            project,
        }));
    } else {
        yield put(ProjectActions.fetchProjectFailed());
    }
};

export default {
    deleteProject,
    deleteProjectConfirm,
    deleteProjectFailed,
    deleteProjectSuccess,
    fetchProjects,
    fetchProject,
};
