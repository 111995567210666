//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import './language/Language';
import '@babel/polyfill';
import React                                 from 'react';
import * as serviceWorker                    from './serviceWorker';
import AcceptOfferOverlay                    from './components/connected/AcceptOfferOverlay';
import CacheInvalidator                      from './components/stateless/CacheInvalidator';
import CallbackRequestOverlay                from './components/stateless/CallbackRequestOverlay';
import CloseOverlayCheckOverlay              from './components/connected/CloseOverlayCheck';
import CreateOfferOverlay                    from './components/connected/CreateOfferOverlay';
import CreateProjectOverlay                  from './components/connected/CreateProjectOverlay';
import CreateProjectSuccessOverlay           from './components/connected/CreateProjectSuccessOverlay';
import DeleteProjectOverlay                  from './components/connected/DeleteProjectOverlay';
import EditCompanyCompetencesOverlay         from './components/connected/EditCompanyCompetencesOverlay';
import EditCompanyDescriptionOverlay         from './components/connected/EditCompanyInformationOverlay';
import EditCompanyHeaderOverlay              from './components/connected/EditCompanyHeaderOverlay';
import EditCompanyImagesOverlay              from './components/connected/EditCompanyImagesOverlay';
import EditCompanyLanguagesOverlay           from './components/connected/EditCompanyLanguagesOverlay';
import EditCompanyLocationsOverlay           from './components/connected/EditCompanyLocationsOverlay';
import EditCompanyPlaceOfWorksOverlay        from './components/connected/EditCompanyPlaceOfWorksOverlay';
import EditCompanyReferencesOverlay          from './components/connected/EditCompanyReferencesOverlay';
import EditCompanySocialMediasOverlay        from './components/connected/EditCompanySocialMediasOverlay';
import EditUserDataOverlay                   from './components/connected/EditUserDataOverlay';
import EditUserPasswordOverlay               from './components/connected/EditUserPasswordOverlay';
import Environment                           from './helper/Environment';
import Header                                from './components/connected/Header';
import LoadingOverlay                        from './components/connected/LoadingOverlay';
import MainSplitPane                         from './components/connected/MainSplitPane';
import OfferAcceptedOverlay                  from './components/connected/OfferAcceptedOverlay';
import OverlayManager                        from './components/connected/OverlayManager';
import Overlays                              from './constants/Overlays';
import ReactDOM                              from 'react-dom';
import ReactGA                               from 'react-ga';
import ReactTooltip                          from 'react-tooltip';
import RegisterCompanyOverlay                from './components/connected/RegisterCompanyOverlay';
import RegisterOverlay                       from './components/connected/RegisterOverlay';
import RegisterVideoProductionOverlay        from './components/connected/RegisterVideoProductionOverlay';
import RegisterVideoProductionSuccessOverlay from './components/connected/RegisterVideoProductionSuccessOverlay';
import ScreenDesignOverlay                   from './components/stateless/ScreenDesignOverlay';
import ScreenTooSmallOverlay                 from './components/connected/ScreenTooSmallOverlay';
import store                                 from './store';
import styles                                from './styles.module.scss';
import TagManager                            from 'react-gtm-module';
import update                                from 'immutability-helper';
import WatchVideoOverlay                     from './components/connected/WatchVideoOverlay';
import { ConnectedRouter }                   from 'connected-react-router';
import { history }                           from './store';
import { Provider }                          from 'react-redux';

import './sentry';

const use = styles; // Make use of the styles, so the linter doesn't remove it.

update.extend('$increaseBy', (amount, value) => {
    return value + amount;
});

update.extend('$decreaseBy', (amount, value) => {
    return value - amount;
});

update.extend('$decreaseByStayPositive', (amount, value) => {
    if (value > 1) {
        return value - amount;
    }

    return value;
});

if (Environment.isProduction()) {
    ReactGA.initialize('UA-170504825-1');
    TagManager.initialize({
        gtmId: 'GTM-K9DXZW8',
    });
}

ReactDOM.render(
    <Provider store={store()}>
        <CacheInvalidator />
        <LoadingOverlay />
        <ReactTooltip
            html={true}
        />
        <ConnectedRouter history={history}>
            <ScreenTooSmallOverlay />
            <OverlayManager
                overlays={{
                    [Overlays.acceptOffer]:                    AcceptOfferOverlay,
                    [Overlays.callbackRequest]:                CallbackRequestOverlay,
                    [Overlays.closeOverlayCheck]:              CloseOverlayCheckOverlay,
                    [Overlays.createOffer]:                    CreateOfferOverlay,
                    [Overlays.createProject]:                  CreateProjectOverlay,
                    [Overlays.createProjectSuccess]:           CreateProjectSuccessOverlay,
                    [Overlays.deleteProject]:                  DeleteProjectOverlay,
                    [Overlays.editUserData]:                   EditUserDataOverlay,
                    [Overlays.editUserPassword]:               EditUserPasswordOverlay,
                    [Overlays.editCompanyCompetences]:         EditCompanyCompetencesOverlay,
                    [Overlays.editCompanyHeader]:              EditCompanyHeaderOverlay,
                    [Overlays.editCompanyImages]:              EditCompanyImagesOverlay,
                    [Overlays.editCompanyDescription]:         EditCompanyDescriptionOverlay,
                    [Overlays.editCompanyLanguages]:           EditCompanyLanguagesOverlay,
                    [Overlays.editCompanyLocations]:           EditCompanyLocationsOverlay,
                    [Overlays.editCompanyPlaceOfWorks]:        EditCompanyPlaceOfWorksOverlay,
                    [Overlays.editCompanyReferences]:          EditCompanyReferencesOverlay,
                    [Overlays.editCompanySocialMedias]:        EditCompanySocialMediasOverlay,
                    [Overlays.offerAccepted]:                  OfferAcceptedOverlay,
                    [Overlays.register]:                       RegisterOverlay,
                    [Overlays.registerCompany]:                RegisterCompanyOverlay,
                    [Overlays.registerVideoProduction]:        RegisterVideoProductionOverlay,
                    [Overlays.registerVideoProductionSuccess]: RegisterVideoProductionSuccessOverlay,
                    [Overlays.screenDesign]:                   ScreenDesignOverlay,
                    [Overlays.watchVideo]:                     WatchVideoOverlay,
                }}
            />
            <Header />
            <MainSplitPane />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
);

serviceWorker.register();
