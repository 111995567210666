//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import _                      from 'lodash';
import { goBack }             from 'connected-react-router';
import { connect }            from 'react-redux';
import { withRouter }         from 'react-router-dom';
import { CompanyActions }     from '../../../store/actions/company';
import I18n                   from 'i18next';
import ChoiceSelector         from '../../stateless/ChoiceSelector';
import ChoiceSelectorMode     from '../../stateless/ChoiceSelector/ChoiceSelectorMode';
import ChoiceWidthMode        from '../../stateless/ChoiceSelector/ChoiceWidthMode';
import FormRow                from '../../stateless/FormRow';
import Overlay                from '../Overlay';
import OverlayButton          from '../../stateless/OverlayButton';
import OverlayButtonTheme     from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper   from '../../stateless/OverlayButtonWrapper';
import OverlayPane            from '../../stateless/OverlayPane';
import OverlayPaneState       from '../../stateless/OverlayPane/OverlayPaneState';
import Overlays               from '../../../constants/Overlays';
import PropTypes              from '../../PropTypes';

class Component extends React.Component {
    cancelButtonPressed = () => {
        this.props.goBack();
    };

    getPlaceOfWorkForChoiceSelector = () => {
        const companyPlaceOfWork = _.get(this.props, 'companyPlaceOfWork');
        const choicePlaceOfWorks = [];

        this.props.placeOfWorks.forEach((placeOfWork) => {
            const isSelected       = JSON.stringify(companyPlaceOfWork) === JSON.stringify(placeOfWork);
            const choiceCompetence = {
                selected:  isSelected,
                text:      placeOfWork.title,
                widthMode: ChoiceWidthMode.full,
            };

            choicePlaceOfWorks.push(choiceCompetence);
        });

        return choicePlaceOfWorks;
    };

    placeOfWorkChanged = (data, index) => {
        this.props.temporarySetOwnCompanyPlaceOfWork(
            {
                placeOfWork: this.props.placeOfWorks[index],
            },
        );
    };

    render () {
        return (
            <Overlay
                id={Overlays.editCompanyPlaceOfWorks}
                title={I18n.t('profileDetails')}
            >
                <OverlayPane
                    content={this.renderContent()}
                    footer={this.renderFooter()}
                    title={I18n.t('placeOfWorks')}
                    showErrors={true}
                    state={OverlayPaneState.open}
                    scrollingAllowed={false}
                />
            </Overlay>
        );
    }

    renderContent = () => {
        const placeOfWorks = this.getPlaceOfWorkForChoiceSelector();

        return (
            <FormRow
                label={I18n.t('placeOfWork')}
                tooltipText={I18n.t('editCompanyPlaceOfWorkTip')}
            >
                <ChoiceSelector
                    choiceClicked={this.placeOfWorkChanged}
                    choices={placeOfWorks}
                    mode={ChoiceSelectorMode.multipleChoice}
                />
            </FormRow>
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.cancelButtonPressed}
                    standAlone={false}
                    text={I18n.t('cancel')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.saveButtonPressed}
                    standAlone={false}
                    text={I18n.t('save')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    saveButtonPressed = () => {
        const companyPlaceOfWork = _.get(this.props, 'companyPlaceOfWork');

        requestAnimationFrame(() => {
            this.props.updateCompany({
                data: {
                    placeOfWork: companyPlaceOfWork.iri,
                },
            });

            this.props.goBack();
        });
    };
}

Component.propTypes = {
    company:            PropTypes.object,
    companyPlaceOfWork: PropTypes.string,
    placeOfWorks:       PropTypes.array,
};

Component.defaultProps = {
    company:            {},
    companyPlaceOfWork: null,
    placeOfWorks:       [],
};

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        CompanyActions,
        {
            goBack,
        },
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        company:            _.get(state, 'company.ownCompanyEditContext', {}),
        companyPlaceOfWork: _.get(state, 'company.ownCompanyEditContext.placeOfWork', ''),
        placeOfWorks:       _.get(state, 'placeOfWork.placeOfWorks', []),
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Component);