//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    button:        'button',
    checkbox:      'checkbox',
    color:         'color',
    date:          'date',
    datetimeLocal: 'datetime-local',
    email:         'email',
    file:          'file',
    hidden:        'hidden',
    image:         'image',
    month:         'month',
    number:        'number',
    password:      'password',
    radio:         'radio',
    range:         'range',
    reset:         'reset',
    search:        'search',
    submit:        'submit',
    tel:           'tel',
    text:          'text',
    time:          'time',
    url:           'url',
    week:          'week',
};