//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _               from 'lodash';
import AlertBox        from '../AlertBox';
import ComponentHelper from '../../../helper/ComponentHelper';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';

class AlertBoxWrapper extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        return (
            <div className={styles.alertBoxWrapper}>
                {this.props.alertBoxes.map(this.renderAlertBox)}
            </div>
        );
    }

    renderAlertBox = (data, index) => {
        if (data) {
            return (
                <AlertBox
                    onCloseButtonClick={this.props.hideButtonPressed}
                    key={'alert-' + index}
                    text={data.text}
                    type={data.type}
                />
            );
        }

        return null;
    };
}

const Component = AlertBoxWrapper;

Component.propTypes = {
    alertBoxes:        PropTypes.array,
    hideButtonPressed: PropTypes.func,
};

Component.defaultProps = {
    alertBoxes:        [],
    hideButtonPressed: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;