//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                from 'react';
import I18n                 from 'i18next';
import Overlay              from '../../connected/Overlay';
import PropTypes            from '../../PropTypes';
import OverlayPane          from '../OverlayPane';
import Overlays             from '../../../constants/Overlays';
import _                    from 'lodash';
import { withTranslation }  from 'react-i18next';
import OverlayButton        from '../OverlayButton';
import OverlayButtonTheme   from '../OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper from '../OverlayButtonWrapper';
import Input                from '../Input';
import InputType            from '../Input/InputType';
import InputSize            from '../Input/InputSize';
import FormRow              from '../FormRow';
import ChoiceWidthMode      from '../ChoiceSelector/ChoiceWidthMode';
import ChoiceSelectorMode   from '../ChoiceSelector/ChoiceSelectorMode';
import ChoiceSelector       from '../ChoiceSelector';
import AvailabilityTypes    from '../../../constants/AvailabilityTypes';

class Component extends React.Component {
    render () {
        return (
            <Overlay
                id={Overlays.callbackRequest}
                title={I18n.t('callbackRequest')}
            >
                <OverlayPane
                    content={this.renderContent()}
                    footer={this.renderFooter()}
                    description={I18n.t('callbackRequestOverlayDescription')}
                    scrollingAllowed={false}
                />
            </Overlay>
        );
    }

    renderContent = () => {
        return (
            <>
                <FormRow
                    label={I18n.t('phoneNumber')}
                >
                    <Input
                        noMarginBottom={true}
                        placeholder={I18n.t('phoneNumberPlaceholder')}
                        type={InputType.tel}
                        size={InputSize.singleLine}
                    />
                </FormRow>
                <FormRow
                    label={I18n.t('callbackRequestAvailabilityLabel')}
                >
                    <ChoiceSelector
                        choiceClicked={this.props.availabilityTypeChanged}
                        choices={[
                            {
                                selected:  this.props.selectedAvailabilityType === AvailabilityTypes.morning,
                                text:      I18n.t('callbackRequestAvailabilityMorning'),
                                widthMode: ChoiceWidthMode.half,
                            },
                            {
                                selected:  this.props.selectedAvailabilityType === AvailabilityTypes.noon,
                                text:      I18n.t('callbackRequestAvailabilityNoon'),
                                widthMode: ChoiceWidthMode.half,
                            },
                        ]}
                        mode={ChoiceSelectorMode.multipleChoice}
                    />
                </FormRow>
            </>
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.props.callMeButtonPressed}
                    standAlone={false}
                    text={I18n.t('callbackRequestOverlayButtonText')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };
}

Component.propTypes = {
    availabilityTypeChanged:  PropTypes.func,
    callMeButtonPressed:      PropTypes.func,
    selectedAvailabilityType: PropTypes.oneOf(Object.keys(AvailabilityTypes)),
};

Component.defaultProps = {
    availabilityTypeChanged:  _.noop,
    callMeButtonPressed:      _.noop,
    selectedAvailabilityType: null,
};

export default withTranslation()(Component);