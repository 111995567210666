//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import AvatarSize      from '../../stateless/Avatar/AvatarSize';
import ComponentHelper from '../../../helper/ComponentHelper';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import StatelessAvatar from '../../stateless/Avatar';
import { compose }     from 'redux';
import { withRouter }  from 'react-router-dom';

class Component extends React.Component {
    render () {
        return (
            <StatelessAvatar
                company={this.props.company}
                disableOnClick={this.props.disableOnClick}
                size={this.props.size}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {
    company:        PropTypes.object,
    disableOnClick: PropTypes.bool,
    size:           PropTypes.oneOf(Object.keys(AvatarSize)),
};

Component.defaultProps = {
    company:        {},
    disableOnClick: false,
    size:           AvatarSize.default,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default compose(
    withRouter,
)(Component);