//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                              from 'lodash';
import ComponentHelper                from '../../../helper/ComponentHelper';
import PropTypes                      from '../../PropTypes';
import DatePickerOverlayPosition      from './DatePickerOverlayPosition';
import React                          from 'react';
import styles                         from './styles.module.scss';
import { default as ReactDatePicker } from 'react-datepicker';
import { registerLocale }             from 'react-datepicker';
import classNames                     from 'classnames';
import I18n                           from 'i18next';
import de                             from 'date-fns/locale/de';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('de', de);

/**
 * @see https://reactdatepicker.com/
 * @see https://github.com/Hacker0x01/react-datepicker/blob/f8fc33c8669dc5b26921a14528154cce948be9dc/docs/datepicker.md
 */
class DatePicker extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            calendarOpen: false,
        };
    }

    getMinDate = () => {
        if (this.props.startFromNow) {
            return new Date();
        }

        return null;
    };

    getPopperPlacement = () => {
        if (this.props.overlayPosition === DatePickerOverlayPosition.top) {
            return 'top-end';
        }

        return 'bottom-start';
    };

    onCalendarClose = () => {
        this.setCalendarOpen(false);
    };

    onCalendarOpen = () => {
        this.setCalendarOpen(true);
    };

    render () {
        const popperPlacement = this.getPopperPlacement();

        return (
            <div
                className={classNames(
                    styles.wrapper,
                    {
                        [styles.wrapperNoMarginBottom]: this.props.noMarginBottom,
                        [styles.wrapperOpen]:           this.state.calendarOpen,
                    },
                )}
            >
                <ReactDatePicker
                    customInput={this.props.input}
                    dateFormat={I18n.t('datePickerDateFormat')}
                    placeholderText={I18n.t('defaultPlaceHolderTextDate')}
                    locale={'de'}
                    minDate={this.getMinDate()}
                    onCalendarClose={this.onCalendarClose}
                    onCalendarOpen={this.onCalendarOpen}
                    onChange={this.props.onDateChanged}
                    selected={this.props.selected}
                    popperPlacement={popperPlacement}
                />
            </div>
        );
    }

    setCalendarOpen = (calendarOpen) => {
        this.setState({
            calendarOpen,
        });
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = DatePicker;

Component.propTypes = {
    input:           PropTypes.children,
    onDateChanged:   PropTypes.func,
    overlayPosition: PropTypes.oneOf(Object.keys(DatePickerOverlayPosition)),
    noMarginBottom:  PropTypes.bool,
    selected:        PropTypes.object,
    startFromNow:    PropTypes.bool,
};

Component.defaultProps = {
    input:           null,
    onDateChanged:   _.noop,
    overlayPosition: DatePickerOverlayPosition.bottom,
    noMarginBottom:  false,
    selected:        null,
    startFromNow:    true,
};

Component.renderAffectingProps = [
    'input',
    'noMarginBottom',
    'startDate',
    'startFromNow',
];

Component.renderAffectingStates = [
    'calendarOpen',
];

export default Component;
