//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                      from 'lodash';
import AlertBoxWrapper        from '../../stateless/AlertBoxWrapper';
import ComponentHelper        from '../../../helper/ComponentHelper';
import React                  from 'react';
import { AlertBoxActions }    from '../../../store/actions/alertBox';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import AlertBoxType           from '../../stateless/AlertBox/AlertBoxType';
import PropTypes              from '../../PropTypes';

class AlertBoxManager extends React.Component {
    getAlertBoxes = () => {
        if (this.props.filterByType) {
            return _.filter(
                this.props.alertBoxes,
                {
                    type: this.props.filterByType,
                },
            );
        }

        return this.props.alertBoxes;
    };

    hideButtonPressed = (index) => {
        this.props.clearSingleAlert({
            index,
        });
    };

    render () {
        return (
            <AlertBoxWrapper
                alertBoxes={this.getAlertBoxes()}
                hideButtonPressed={this.hideButtonPressed}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = AlertBoxManager;

Component.propTypes = {
    alertBoxes:   PropTypes.array,
    filterByType: PropTypes.oneOf(Object.keys(AlertBoxType)),
};

Component.defaultProps = {
    alertBoxes:   [],
    filterByType: null,
};

Component.renderAffectingProps = [
    'alertBoxes',
];

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        alertBoxes: state.alertBox.alertBoxes,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        AlertBoxActions,
    ),
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);