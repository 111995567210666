//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                        from 'lodash';
import Environment              from '../../helper/Environment';
import Overlays                 from '../../constants/Overlays';
import ReactGA                  from 'react-ga';
import ReactTooltip             from 'react-tooltip';
import Routes                   from '../../constants/Routes';
import { CompanyActions }       from '../actions/company';
import { history }              from '../index';
import { MainSplitPaneActions } from '../actions/mainSplitPane';
import { push }                 from 'connected-react-router';
import { put }                  from 'redux-saga/effects';
import { select }               from 'redux-saga/effects';
import { UserActions }          from '../actions/user';

const locationChange = function* (action) {
    requestAnimationFrame(ReactTooltip.rebuild);

    const leftPaneVisible = yield select(state => state.mainSplitPane.leftPaneVisible);
    const location        = _.get(action, 'payload.location');
    const search          = _.get(location, 'search');
    const pathname        = _.get(location, 'pathname');
    const routesArray     = Object.values(Routes);
    const isPartRoute     = Object.entries(Routes).some((route) => {
        return route[0].endsWith('Part') && pathname.startsWith(route[1]);
    });

    ReactGA.pageview(location.pathname + location.search);

    if (pathname.startsWith(Routes.companyProfilePart) && history.action !== 'POP') {
        const companyId = pathname.replace(Routes.companyProfilePart, '');

        yield put(CompanyActions.fetchCompany({
            companyId,
        }));
    }

    if (pathname.startsWith(Routes.userProfilePart) && !search.endsWith(Overlays.editUserPassword)) {
        const userId = pathname.replace(Routes.userProfilePart, '');

        yield put(UserActions.fetchUser({
            userId,
        }));
    }

    // Hide the leftPane on the error & notfound routes
    if (
        pathname === Routes.error ||
        (
            !routesArray.includes(pathname) &&
            !isPartRoute
        )
    ) {
        yield put(MainSplitPaneActions.setLeftPaneSetVisibility({
            visible: false,
        }));
    } else if (!leftPaneVisible) {
        yield put(MainSplitPaneActions.setLeftPaneSetVisibility({
            visible: true,
        }));
    }
};

const redirect = function* (action) {
    yield put(push(action.route));
};

export default {
    locationChange,
    redirect,
};
