//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React               from 'react';
import styles              from './styles.module.scss';
import PropTypes           from '../../PropTypes';
import TooltipPosition     from './TooltipPosition';
import Icon                from '../Icon';
import IconType            from '../Icon/IconType';
import TooltipTheme        from './TooltipTheme';
import classNames          from 'classnames';
import SelectionHelper     from '../../../helper/SelectionHelper';
import { withTranslation } from 'react-i18next';

/**
 * @see https://github.com/wwayne/react-tooltip
 */
class Component extends React.Component {
    getNormalizedPosition = () => {
        return SelectionHelper.get(
            this.props.position,
            {
                [TooltipPosition.bottomRight]: TooltipPosition.bottom,
            },
            this.props.position,
        );
    };

    getOffset = () => {
        return SelectionHelper.get(
            this.props.position,
            {
                [TooltipPosition.bottomRight]: '{"top": 10, "left": 112}',
            },
            '',
        );
    };

    getTooltipClassForPosition = () => {
        return SelectionHelper.get(
            this.props.position,
            {
                [TooltipPosition.bottomRight]: 'bottom-right',
            },
            '',
        );
    };

    render() {
        const defaultClasses = classNames(
            'tooltipStyle',
            'tooltipWrapper',
        );
        const extraClass     = this.getTooltipClassForPosition();
        const offset         = this.getOffset();
        const position       = this.getNormalizedPosition();

        return (
            <span
                className={classNames(
                    styles.tooltip,
                    SelectionHelper.get(
                        this.props.theme,
                        {
                            [TooltipTheme.white]: 'tooltipWrapper tooltipWrapperWhite',
                        },
                    ),
                )}
                data-class={defaultClasses + ' ' + extraClass}
                data-effect={'float'}
                data-offset={offset}
                data-place={position}
                data-tip={`
                    <div class="tooltipContentWrapper">
                        ${this.renderTitle()}
                        <p>
                            ${this.props.text}
                        </p>
                    </div>
                `}
            >
                <Icon iconType={this.props.iconType} />
            </span>
        );
    }

    renderTitle = () => {
        if (this.props.title) {
            // This is a string to get used in data-tip html attribute in render
            return `
                <h5>
                    ${this.props.title}
                </h5>
            `;
        }

        return '';
    };
}

Component.propTypes = {
    iconType: PropTypes.oneOf(Object.values(IconType)),
    position: PropTypes.oneOf(Object.values(TooltipPosition)),
    text:     PropTypes.string,
    theme:    PropTypes.oneOf(Object.values(TooltipTheme)),
    title:    PropTypes.string,
};

Component.defaultProps = {
    iconType: IconType.infoCircle,
    position: TooltipPosition.bottom,
    text:     '',
    theme:    TooltipTheme.white,
    title:    '',
};

export default withTranslation()(Component);
