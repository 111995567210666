//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import moment from 'moment';
import I18n   from 'i18next';

class DateTime {
    static getTimeString = (dateTime, fallbackText) => {
        const messageDate = new moment(dateTime);

        if (messageDate.isValid()) {
            const today           = moment();
            const timeStringParts = [];

            if (messageDate.isSame(today, 'day')) {
                timeStringParts.push(I18n.t('today'));
            } else {
                const yesterday = moment().subtract(1, 'day');

                if (messageDate.isSame(yesterday, 'day')) {
                    timeStringParts.push(I18n.t('yesterday'));
                } else {
                    timeStringParts.push(messageDate.format(I18n.t('messageDateFormat')));
                }
            }

            timeStringParts.push(messageDate.format(I18n.t('messageTimeFormat')));
            timeStringParts.push(I18n.t('oclock'));

            const timeString = timeStringParts.join(' ');

            return timeString;
        }

        return fallbackText;
    };
}

export default DateTime;
