//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const CreateEditProjectTypes = {
    CREATE_PROJECT:                                         'CreateEditProject/CREATE_PROJECT',
    CREATE_PROJECT_FAILED:                                  'CreateEditProject/CREATE_PROJECT_FAILED',
    CREATE_PROJECT_SUCCESS:                                 'CreateEditProject/CREATE_PROJECT_SUCCESS',
    EDIT_PROJECT:                                           'CreateEditProject/EDIT_PROJECT',
    NEW_PROJECT_OPEN:                                       'CreateEditProject/NEW_PROJECT_OPEN',
    NEXT_PANE:                                              'CreateEditProject/NEXT_PANE',
    PREVIOUS_PANE:                                          'CreateEditProject/PREVIOUS_PANE',
    RESET_PANE:                                             'CreateEditProject/RESET_PANE',
    SET_ADVERTISING_BUDGET_PLANNED:                         'CreateEditProject/SET_ADVERTISING_BUDGET_PLANNED',
    SET_ADVERTISING_BUDGET_RANGE:                           'CreateEditProject/SET_ADVERTISING_BUDGET_RANGE',
    SET_ADVERTISING_BUDGET_RANGE_END:                       'CreateEditProject/SET_ADVERTISING_BUDGET_RANGE_END',
    SET_ADVERTISING_BUDGET_RANGE_START:                     'CreateEditProject/SET_ADVERTISING_BUDGET_RANGE_START',
    SET_BRANCH:                                             'CreateEditProject/SET_BRANCH',
    SET_BUDGET_RANGE:                                       'CreateEditProject/SET_BUDGET_RANGE',
    SET_BUDGET_RANGE_END:                                   'CreateEditProject/SET_BUDGET_RANGE_END',
    SET_BUDGET_RANGE_START:                                 'CreateEditProject/SET_BUDGET_RANGE_START',
    SET_BUDGET_RANGE_UNKNOWN:                               'CreateEditProject/SET_BUDGET_RANGE_UNKNOWN',
    SET_DESCRIPTION:                                        'CreateEditProject/SET_DESCRIPTION',
    SET_FIXED_RELEASE_DATE:                                 'CreateEditProject/SET_FIXED_RELEASE_DATE',
    SET_FIXED_RELEASE_DATE_AVAILABLE:                       'CreateEditProject/SET_FIXED_RELEASE_DATE_AVAILABLE',
    SET_FIXED_RELEASE_DATE_IS_SAME_AS_WISH_COMPLETION_DATE: 'CreateEditProject/SET_FIXED_RELEASE_DATE_IS_SAME_AS_WISH_COMPLETION_DATE',
    SET_GOAL:                                               'CreateEditProject/SET_GOAL',
    SET_LICENSE_DURATION:                                   'CreateEditProject/SET_LICENSE_DURATION',
    SET_LICENSE_DURATION_IS_OTHER:                          'CreateEditProject/SET_LICENSE_DURATION_IS_OTHER',
    SET_LICENSE_DURATION_TEXT:                              'CreateEditProject/SET_LICENSE_DURATION_TEXT',
    SET_MAX_PANE_INDEX:                                     'CreateEditProject/SET_MAX_PANE_INDEX',
    SET_PANE_INDEX:                                         'CreateEditProject/SET_PANE_INDEX',
    SET_TARGET_GROUP_AGE_RANGE:                             'CreateEditProject/SET_TARGET_GROUP_AGE_RANGE',
    SET_TARGET_GROUP_AGE_RANGE_END:                         'CreateEditProject/SET_TARGET_GROUP_AGE_RANGE_END',
    SET_TARGET_GROUP_AGE_RANGE_START:                       'CreateEditProject/SET_TARGET_GROUP_AGE_RANGE_START',
    SET_TARGET_GROUP_AGE_RANGE_UNKNOWN:                     'CreateEditProject/SET_TARGET_GROUP_AGE_RANGE_UNKNOWN',
    SET_TARGET_GROUP_TYPE:                                  'CreateEditProject/SET_TARGET_GROUP_TYPE',
    SET_TERRITORY:                                          'CreateEditProject/SET_TERRITORY',
    SET_TERRITORY_IS_OTHER:                                 'CreateEditProject/SET_TERRITORY_IS_OTHER',
    SET_TERRITORY_TEXT:                                     'CreateEditProject/SET_TERRITORY_TEXT',
    SET_TITLE:                                              'CreateEditProject/SET_TITLE',
    SET_VIDEO_TYPE:                                         'CreateEditProject/SET_VIDEO_TYPE',
    SET_VIDEO_WILL_BE_PUBLISHED:                            'CreateEditProject/SET_VIDEO_WILL_BE_PUBLISHED',
    SET_WISH_COMPLETION_DATE:                               'CreateEditProject/SET_WISH_COMPLETION_DATE',
    SET_WISH_COMPLETION_DATE_UNKNOWN:                       'CreateEditProject/SET_WISH_COMPLETION_DATE_UNKNOWN',
    SET_WISHED_PRODUCTION_PERIOD:                           'CreateEditProject/SET_WISHED_PRODUCTION_PERIOD',
    SET_WISHED_PRODUCTION_PERIOD_END:                       'CreateEditProject/SET_WISHED_PRODUCTION_PERIOD_END',
    SET_WISHED_PRODUCTION_PERIOD_START:                     'CreateEditProject/SET_WISHED_PRODUCTION_PERIOD_START',
    SET_WISHED_PRODUCTION_PERIOD_UNKNOWN:                   'CreateEditProject/SET_WISHED_PRODUCTION_PERIOD_UNKNOWN',
    TOGGLE_BUDGET_RANGE_UNKNOWN:                            'CreateEditProject/TOGGLE_BUDGET_RANGE_UNKNOWN',
    TOGGLE_TARGET_GROUP_AGE_RANGE_UNKNOWN:                  'CreateEditProject/TOGGLE_TARGET_GROUP_AGE_RANGE_UNKNOWN',
    TOGGLE_VIDEO_FORMAT:                                    'CreateEditProject/TOGGLE_VIDEO_FORMAT',
    TOGGLE_WISH_COMPLETION_DATE_UNKNOWN:                    'CreateEditProject/TOGGLE_WISH_COMPLETION_DATE_UNKNOWN',
    TOGGLE_WISH_PRODUCTION_PERIOD_UNKNOWN:                  'CreateEditProject/TOGGLE_WISH_PRODUCTION_PERIOD_UNKNOWN',
    UPDATE_PROJECT:                                         'CreateEditProject/UPDATE_PROJECT',
    UPDATE_PROJECT_FAILED:                                  'CreateEditProject/UPDATE_PROJECT_FAILED',
    UPDATE_PROJECT_SUCCESS:                                 'CreateEditProject/UPDATE_PROJECT_SUCCESS',
};

const createProject = makeActionCreator(
    CreateEditProjectTypes.CREATE_PROJECT,
);

const createProjectFailed = makeActionCreator(
    CreateEditProjectTypes.CREATE_PROJECT_FAILED,
);

const createProjectSuccess = makeActionCreator(
    CreateEditProjectTypes.CREATE_PROJECT_SUCCESS,
    {
        project: null,
    },
);

const editProject = makeActionCreator(
    CreateEditProjectTypes.EDIT_PROJECT,
    {
        project: null,
    },
);

const newProjectOpen = makeActionCreator(
    CreateEditProjectTypes.NEW_PROJECT_OPEN,
);

const nextPane = makeActionCreator(
    CreateEditProjectTypes.NEXT_PANE,
);

const previousPane = makeActionCreator(
    CreateEditProjectTypes.PREVIOUS_PANE,
);

const resetPane = makeActionCreator(
    CreateEditProjectTypes.RESET_PANE,
);

const setAdvertisingBudgetPlanned = makeActionCreator(
    CreateEditProjectTypes.SET_ADVERTISING_BUDGET_PLANNED, {
        planned: null,
    },
);

const setAdvertisingBudgetRange = makeActionCreator(
    CreateEditProjectTypes.SET_ADVERTISING_BUDGET_RANGE, {
        end:   null,
        start: null,
    },
);

const setAdvertisingBudgetRangeEnd = makeActionCreator(
    CreateEditProjectTypes.SET_ADVERTISING_BUDGET_RANGE_END, {
        end: null,
    },
);

const setAdvertisingBudgetRangeStart = makeActionCreator(
    CreateEditProjectTypes.SET_ADVERTISING_BUDGET_RANGE_START, {
        start: null,
    },
);

const setBudgetRange = makeActionCreator(
    CreateEditProjectTypes.SET_BUDGET_RANGE, {
        end:   null,
        start: null,
    },
);

const setBudgetRangeEnd = makeActionCreator(
    CreateEditProjectTypes.SET_BUDGET_RANGE_END, {
        end: null,
    },
);

const setBudgetRangeStart = makeActionCreator(
    CreateEditProjectTypes.SET_BUDGET_RANGE_START, {
        start: null,
    },
);

const setBudgetRangeUnknown = makeActionCreator(
    CreateEditProjectTypes.SET_BUDGET_RANGE_UNKNOWN,
);

const setBranch = makeActionCreator(
    CreateEditProjectTypes.SET_BRANCH, {
        branch: null,
    },
);

const setDescription = makeActionCreator(
    CreateEditProjectTypes.SET_DESCRIPTION, {
        description: null,
    },
);

const setGoal = makeActionCreator(
    CreateEditProjectTypes.SET_GOAL, {
        goal: null,
    },
);

const setFixedReleaseDate = makeActionCreator(
    CreateEditProjectTypes.SET_FIXED_RELEASE_DATE, {
        fixedReleaseDate: null,
    },
);

const setFixedReleaseDateAvailable = makeActionCreator(
    CreateEditProjectTypes.SET_FIXED_RELEASE_DATE_AVAILABLE, {
        fixedReleaseDateAvailable: null,
    },
);

const setFixedReleaseDateIsSameAsWishCompletionDate = makeActionCreator(
    CreateEditProjectTypes.SET_FIXED_RELEASE_DATE_IS_SAME_AS_WISH_COMPLETION_DATE, {
        fixedReleaseDateIsSameAsWishCompletionDate: null,
    },
);

const setLicenseDuration = makeActionCreator(
    CreateEditProjectTypes.SET_LICENSE_DURATION, {
        licenseDurationIri: null,
    },
);

const setLicenseDurationIsOther = makeActionCreator(
    CreateEditProjectTypes.SET_LICENSE_DURATION_IS_OTHER, {
        isOther: null,
    },
);

const setLicenseDurationText = makeActionCreator(
    CreateEditProjectTypes.SET_LICENSE_DURATION_TEXT, {
        licenseDurationText: null,
    },
);

const setMaxPaneIndex = makeActionCreator(
    CreateEditProjectTypes.SET_MAX_PANE_INDEX,
    {
        index: null,
    },
);

const setPaneIndex = makeActionCreator(
    CreateEditProjectTypes.SET_PANE_INDEX,
    {
        index: null,
    },
);

const setTerritory = makeActionCreator(
    CreateEditProjectTypes.SET_TERRITORY, {
        territoryIri: null,
    },
);

const setTerritoryIsOther = makeActionCreator(
    CreateEditProjectTypes.SET_TERRITORY_IS_OTHER, {
        isOther: null,
    },
);

const setTerritoryText = makeActionCreator(
    CreateEditProjectTypes.SET_TERRITORY_TEXT, {
        territoryText: null,
    },
);

const setTargetGroupAgeRange = makeActionCreator(
    CreateEditProjectTypes.SET_TARGET_GROUP_AGE_RANGE, {
        end:   null,
        start: null,
    },
);

const setTargetGroupAgeRangeEnd = makeActionCreator(
    CreateEditProjectTypes.SET_TARGET_GROUP_AGE_RANGE_END, {
        end: null,
    },
);

const setTargetGroupAgeRangeStart = makeActionCreator(
    CreateEditProjectTypes.SET_TARGET_GROUP_AGE_RANGE_START, {
        start: null,
    },
);

const setTargetGroupAgeRangeUnknown = makeActionCreator(
    CreateEditProjectTypes.SET_TARGET_GROUP_AGE_RANGE_UNKNOWN,
);

const setTargetGroupType = makeActionCreator(
    CreateEditProjectTypes.SET_TARGET_GROUP_TYPE, {
        targetGroupType: null,
    },
);

const setTitle = makeActionCreator(
    CreateEditProjectTypes.SET_TITLE, {
        title: null,
    },
);

const setVideoType = makeActionCreator(
    CreateEditProjectTypes.SET_VIDEO_TYPE, {
        videoType: null,
    },
);

const setVideoWillBePublished = makeActionCreator(
    CreateEditProjectTypes.SET_VIDEO_WILL_BE_PUBLISHED, {
        videoWillBePublished: null,
    },
);

const setWishCompletionDate = makeActionCreator(
    CreateEditProjectTypes.SET_WISH_COMPLETION_DATE, {
        wishCompletionDate: null,
    },
);

const setWishCompletionDateUnknown = makeActionCreator(
    CreateEditProjectTypes.SET_WISH_COMPLETION_DATE_UNKNOWN,
);

const setWishedProductionPeriod = makeActionCreator(
    CreateEditProjectTypes.SET_WISHED_PRODUCTION_PERIOD, {
        endDate:   null,
        startDate: null,
    },
);

const setWishedProductionPeriodEnd = makeActionCreator(
    CreateEditProjectTypes.SET_WISHED_PRODUCTION_PERIOD_END, {
        endDate: null,
    },
);

const setWishedProductionPeriodStart = makeActionCreator(
    CreateEditProjectTypes.SET_WISHED_PRODUCTION_PERIOD_START, {
        startDate: null,
    },
);

const setWishedProductionPeriodUnknown = makeActionCreator(
    CreateEditProjectTypes.SET_WISHED_PRODUCTION_PERIOD_UNKNOWN,
);

const toggleBudgetRangeUnknown = makeActionCreator(
    CreateEditProjectTypes.TOGGLE_BUDGET_RANGE_UNKNOWN,
);

const toggleTargetGroupAgeRangeUnknown = makeActionCreator(
    CreateEditProjectTypes.TOGGLE_TARGET_GROUP_AGE_RANGE_UNKNOWN,
);

const toggleWishCompletionDateUnknown = makeActionCreator(
    CreateEditProjectTypes.TOGGLE_WISH_COMPLETION_DATE_UNKNOWN,
);

const toggleWishProductionPeriodUnknown = makeActionCreator(
    CreateEditProjectTypes.TOGGLE_WISH_PRODUCTION_PERIOD_UNKNOWN,
);

const toggleVideoFormat = makeActionCreator(
    CreateEditProjectTypes.TOGGLE_VIDEO_FORMAT,
    {
        videoFormatIri: null,
    },
);

const updateProject = makeActionCreator(
    CreateEditProjectTypes.UPDATE_PROJECT,
);

const updateProjectFailed = makeActionCreator(
    CreateEditProjectTypes.UPDATE_PROJECT_FAILED,
);

const updateProjectSuccess = makeActionCreator(
    CreateEditProjectTypes.UPDATE_PROJECT_SUCCESS,
);

export const CreateEditProjectActions = {
    createProject,
    createProjectFailed,
    createProjectSuccess,
    editProject,
    newProjectOpen,
    nextPane,
    previousPane,
    resetPane,
    setAdvertisingBudgetPlanned,
    setAdvertisingBudgetRange,
    setAdvertisingBudgetRangeEnd,
    setAdvertisingBudgetRangeStart,
    setBranch,
    setBudgetRange,
    setBudgetRangeEnd,
    setBudgetRangeStart,
    setBudgetRangeUnknown,
    setDescription,
    setFixedReleaseDate,
    setFixedReleaseDateAvailable,
    setFixedReleaseDateIsSameAsWishCompletionDate,
    setGoal,
    setLicenseDuration,
    setLicenseDurationIsOther,
    setLicenseDurationText,
    setMaxPaneIndex,
    setPaneIndex,
    setTargetGroupAgeRange,
    setTargetGroupAgeRangeEnd,
    setTargetGroupAgeRangeStart,
    setTargetGroupAgeRangeUnknown,
    setTargetGroupType,
    setTerritory,
    setTerritoryIsOther,
    setTerritoryText,
    setTitle,
    setVideoType,
    setVideoWillBePublished,
    setWishCompletionDate,
    setWishCompletionDateUnknown,
    setWishedProductionPeriod,
    setWishedProductionPeriodEnd,
    setWishedProductionPeriodStart,
    setWishedProductionPeriodUnknown,
    toggleBudgetRangeUnknown,
    toggleTargetGroupAgeRangeUnknown,
    toggleVideoFormat,
    toggleWishCompletionDateUnknown,
    toggleWishProductionPeriodUnknown,
    updateProject,
    updateProjectFailed,
    updateProjectSuccess,
};
