//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update            from 'immutability-helper';
import { LanguageTypes } from '../actions/language';

const initialState = {
    languages: [],
};

const fetchLanguagesSuccess = (action, state) => {
    return update(state, {
        languages: {
            $set: action.languages,
        },
    });
};

export default function (state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case LanguageTypes.FETCH_LANGUAGES_SUCCESS: return fetchLanguagesSuccess(action, state);
        default:                                    return state;
        // @formatter:on
    }
}