//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper   from '../../../helper/ComponentHelper';
import PropTypes         from 'prop-types';
import React             from 'react';
import styles            from './styles.module.scss';
import IconLabel         from '../IconLabel';
import IconType          from '../Icon/IconType';
import I18n              from 'i18next';
import IconLabelTheme    from '../IconLabel/IconLabelTheme';
import ProjectStateTypes from '../../../constants/ProjectStateTypes';
import MatchStateTypes   from '../../../constants/MatchStateTypes';
import CompanyTypes      from '../../../constants/CompanyTypes';

class ProjectState extends React.Component {
    getStates = () => {
        const states = {
            findingMatches:   false,
            productionsFound: false,
            offerSubmitted:   false,
            orderProposed:    false,
            orderReceived:    false,
            orderFinished:    false,
        };

        switch (this.props.projectState) {
            // @formatter:off
            case ProjectStateTypes.finished:     states.orderFinished    = true;
            case ProjectStateTypes.commissioned: states.offerSubmitted   = true;
                                                 states.productionsFound = true;
            case ProjectStateTypes.complete:     states.findingMatches   = true;
            // @formatter:on
        }

        switch (this.props.matchState) {
            // @formatter:off
            case MatchStateTypes.accepted: states.orderReceived    = true;
            case MatchStateTypes.offered:  states.offerSubmitted   = true;
            case MatchStateTypes.proposed: states.orderProposed    = true;
                                           states.productionsFound = true;
            // @formatter:on
        }

        return states;
    };

    render () {
        const isVideoProduction = this.props.companyType === CompanyTypes.videoProduction;
        const states            = this.getStates();

        return (
            <ul className={styles.statusList}>
                {
                    isVideoProduction ?
                        this.renderVideoProductionStates(states) :
                        this.renderCompanyStates(states)
                }
            </ul>
        );
    }

    renderCompanyState = (text, disabled) => {
        return (
            <li>
                <IconLabel
                    disabled={disabled}
                    iconType={IconType.checkCircle}
                    text={text}
                    theme={IconLabelTheme.bigWhite}
                />
            </li>
        );
    };

    renderCompanyStates = (states) => {
        return (
            <>
                {
                    this.renderCompanyState(
                        I18n.t('companyStatusFindingMatches'),
                        !states.findingMatches,
                    )
                }
                {
                    this.renderCompanyState(
                        I18n.t('companyStatusProductionsFound'),
                        !states.productionsFound,
                    )
                }
                {
                    this.renderCompanyState(
                        I18n.t('companyStatusOfferReceived'),
                        !states.offerSubmitted,
                    )
                }
                {
                    this.renderCompanyState(
                        I18n.t('companyStatusOrderPlaced'),
                        !states.orderReceived,
                    )
                }
                {
                    this.renderCompanyState(
                        I18n.t('companyStatusOrderFinished'),
                        !states.orderFinished,
                    )
                }
            </>
        );
    };

    renderVideoProductionStates = (states) => {
        return (
            <>
                {
                    this.renderCompanyState(
                        I18n.t('videoProductionStatusProposed'),
                        !states.orderProposed,
                    )
                }
                {
                    this.renderCompanyState(
                        I18n.t('videoProductionStatusOfferSubmitted'),
                        !states.offerSubmitted,
                    )
                }
                {
                    this.renderCompanyState(
                        I18n.t('videoProductionStatusOrderReceived'),
                        !states.orderReceived,
                    )
                }
            </>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ProjectState;

Component.propTypes = {
    companyType:  PropTypes.string,
    matchState:   PropTypes.oneOf(Object.values(MatchStateTypes)),
    projectState: PropTypes.oneOf(Object.values(ProjectStateTypes)),
};

Component.defaultProps = {
    companyType:  null,
    matchState:   null,
    projectState: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
