//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React              from 'react';
import { connect }        from 'react-redux';
import PropTypes          from '../../PropTypes';
import _                  from 'lodash';
import ChoiceSelectorMode from '../../stateless/ChoiceSelector/ChoiceSelectorMode';
import ChoiceSelector     from '../../stateless/ChoiceSelector';
import ComponentHelper    from '../../../helper/ComponentHelper';

class LicenseDurationChoiceSelector extends React.Component {
    getLicenseDurations = () => {
        return ChoiceSelector.mapHydraDataForValueField(
            this.props.licenseDurations,
            'name',
            this.props.addUndefinedValue,
            this.props.selectedValues,
        );
    };

    render () {
        return (
            <ChoiceSelector
                choiceClicked={this.props.choiceClicked}
                choices={this.getLicenseDurations()}
                mode={ChoiceSelectorMode.multipleChoice}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = LicenseDurationChoiceSelector;

Component.propTypes = {
    addUndefinedValue: PropTypes.bool,
    choiceClicked:     PropTypes.func,
    selectedValues:    PropTypes.array,
};

Component.defaultProps = {
    addUndefinedValue: false,
    choiceClicked:     _.noop,
    selectedValues:    [],
};

Component.renderAffectingProps = [
    'licenseDurations',
    'selectedValues',
];

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        licenseDurations: state.licenseDuration.licenseDurations,
    }
);

export default connect(
    mapStateToProps,
    {},
)(Component);