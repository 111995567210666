//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React              from 'react';
import styles             from './styles.module.scss';
import PropTypes          from '../../PropTypes';
import ComponentHelper    from '../../../helper/ComponentHelper';
import OverlayButtonTheme from './OverlayButtonTheme';
import _                  from 'lodash';
import classNames         from 'classnames';

class OverlayButton extends React.Component {
    render () {
        return (
            <div
                className={classNames(
                    styles.wrapper,
                    {
                        [styles.wrapperDisabled]:           this.props.disabled,
                        [styles.wrapperGray]:               this.props.theme === OverlayButtonTheme.gray,
                        [styles.wrapperStandAlone]:         this.props.standAlone,
                        [styles.wrapperMultipleStandAlone]: this.props.standAlone && this.props.multiple,
                        [styles.wrapperTurquoise]:          this.props.theme === OverlayButtonTheme.turquoise,
                    },
                )}
                onClick={this.props.onClick}
            >
                {this.props.text}
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = OverlayButton;

Component.propTypes = {
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    onClick:  PropTypes.func,
    text:     PropTypes.string,
    theme:    PropTypes.oneOf(Object.keys(OverlayButtonTheme)),
};

Component.defaultProps = {
    children: null,
    disabled: false,
    multiple: false,
    onClick:  _.noop,
    theme:    OverlayButtonTheme.turquoise,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
