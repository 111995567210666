//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as Api                   from '../../api';
import _                          from 'lodash';
import I18n                       from 'i18next';
import { AlertBoxActions }        from '../actions/alertBox';
import { call }                   from 'redux-saga/effects';
import { put }                    from 'redux-saga/effects';
import { select }                 from 'redux-saga/effects';
import { CallbackRequestActions } from '../actions/callbackRequest';

const sendCallbackRequest = function* ({ projectId }) {
    const callbackRequest = yield select(state => state.callbackRequest);
    const user            = yield select(state => state.user.user);
    const company         = yield select(state => state.company);
    const ownCompany      = _.get(company, 'ownCompany');

    const postData = {
        company:      callbackRequest.company,
        companyName:  '', // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-231
        eMailAddress: callbackRequest.eMailAddress,
        phoneNumber:  callbackRequest.phoneNumber,
        timeType:     callbackRequest.availabilityType.toUpperCase(),
    };

    if (user) {
        postData.name = user.name;
    }

    if (company && ownCompany) {
        postData.company = ownCompany.iri;
    }

    const response = yield call(
        Api.postCallbackRequest,
        postData.company,
        postData.companyName,
        postData.eMailAddress,
        postData.name,
        postData.phoneNumber,
        postData.timeType,
    );

    if (response.ok) {
        yield put(CallbackRequestActions.sendCallbackRequestSuccess());
    } else {
        yield put(CallbackRequestActions.sendCallbackRequestFailed());
    }
};

const sendCallbackRequestSuccess = function* ({ projectId }) {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('callbackRequestSuccessMessage'),
    }));
};

export default {
    sendCallbackRequest,
    sendCallbackRequestSuccess,
};
