//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                            from 'react';
import CreateProjectOverlayAdditionPane from '../../connected/CreateProjectOverlayAdditionPane';
import CreateProjectOverlayBudgetPane   from '../../connected/CreateProjectOverlayBudgetPane';
import CreateProjectOverlayGoalsPane    from '../../connected/CreateProjectOverlayGoalsPane';
import CreateProjectOverlayKeyDataPane  from '../../connected/CreateProjectOverlayKeyDataPane';
import CreateProjectOverlayReachPane    from '../../connected/CreateProjectOverlayReachPane';
import CreateProjectOverlayTimingsPane  from '../../connected/CreateProjectOverlayTimingsPane';
import PropTypes                        from '../../PropTypes';
import Window                           from '../../../helper/Window';

class RegisterProjectPane extends React.Component {
    backButtonPressed = () => {
        this.props.clearAlerts();
    };

    cancelButtonPressed = () => {
        this.props.goBack();
    };

    render () {
        return (
            <>
                <CreateProjectOverlayKeyDataPane overlayPaneState={this.props.stateKeyData} />
                <CreateProjectOverlayGoalsPane overlayPaneState={this.props.stateGoals} />
                {this.renderReachPane()}
                <CreateProjectOverlayBudgetPane overlayPaneState={this.props.stateBudget} />
                {this.renderTimingsPane()}
                <CreateProjectOverlayAdditionPane overlayPaneState={this.props.stateAddition} />
            </>
        );
    }

    renderReachPane = () => {
        if (!Window.isMobile()) {
            return (
                <CreateProjectOverlayReachPane overlayPaneState={this.props.stateReach} />
            );
        }

        return null;
    };

    renderTimingsPane = () => {
        if (!Window.isMobile()) {
            return (
                <CreateProjectOverlayTimingsPane overlayPaneState={this.props.stateTimings} />
            );
        }

        return null;
    };
}

const Component = RegisterProjectPane;

Component.propTypes = {
    stateAddition: PropTypes.overlayPaneState,
    stateBudget:   PropTypes.overlayPaneState,
    stateGoals:    PropTypes.overlayPaneState,
    stateKeyData:  PropTypes.overlayPaneState,
    stateReach:    PropTypes.overlayPaneState,
    stateTimings:  PropTypes.overlayPaneState,
};

Component.defaultProps = {
    stateAddition: null,
    stateBudget:   null,
    stateGoals:    null,
    stateKeyData:  null,
    stateReach:    null,
    stateTimings:  null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
