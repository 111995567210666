//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import ComponentHelper from '../../../helper/ComponentHelper';

class OfferOverviewContentItemContent extends React.Component {
    render () {
        return (
            <div className={styles.wrapper}>
                {this.props.fileLists.map(this.renderFileList)}
            </div>
        );
    }

    renderFileList (fileList, index) {
        return (
            <div
                className={styles.fileListWrapper}
                key={'fileList' + index}
            >
                {fileList}
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = OfferOverviewContentItemContent;

Component.propTypes = {
    fileLists: PropTypes.array,
};

Component.defaultProps = {
    fileLists: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;