//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                     from 'react';
import _                         from 'lodash';
import Cast                      from '../../../helper/Cast';
import classNames                from 'classnames';
import ComponentHelper           from '../../../helper/ComponentHelper';
import DatePickerOverlayPosition from '../DatePicker/DatePickerOverlayPosition';
import I18n                      from 'i18next';
import Input                     from '../Input';
import InputType                 from '../Input/InputType';
import PropTypes                 from '../../PropTypes';
import RangeInputTheme           from './RangeInputTheme';
import RangeInputType            from './RangeInputType';
import RangeSlider               from '../RangeSlider';
import RangeSliderTheme          from '../RangeSlider/RangeSliderTheme';
import styles                    from './styles.module.scss';

class RangeInput extends React.Component {
    callOnChange = (start, end) => {
        this.props.onChange({
            endValue:   end,
            startValue: start,
        });
    };

    getInputPlaceholder = () => {
        if (this.props.type === RangeInputType.date) {
            return I18n.t('defaultPlaceHolderTextDate');
        }

        return null;
    };

    getInputType = () => {
        if (this.props.type === RangeInputType.date) {
            return InputType.date;
        }

        return InputType.number;
    };

    getPostfix = (value) => {
        if (this.props.postfix) {
            if (
                this.props.postfixSingular &&
                value === 1
            ) {
                return this.props.postfixSingular;
            }

            return this.props.postfix;
        }

        return null;
    };

    getRangeSliderTheme = () => {
        if (this.props.theme === RangeInputTheme.unimportant) {
            return RangeSliderTheme.unimportant;
        }

        return RangeSliderTheme.default;
    };

    maximumTextChanged = (eventOrDateString) => {
        // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-264
        if (eventOrDateString.target) {
            if (this.props.type === RangeInputType.number) {
                const number = Cast.int(eventOrDateString.target.value);

                // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-144
                this.callOnChange(
                    this.props.valueStart,
                    number,
                );

                if (number > this.props.maximumValue) {
                    this.props.onMaxValueError();
                }
            }
        } else {
            this.callOnChange(
                this.props.valueStart,
                eventOrDateString,
            );
        }
    };

    minimumTextChanged = (eventOrDateString) => {
        // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-264
        if (eventOrDateString.target) {

            if (this.props.type === RangeInputType.number) {
                const number = Cast.int(eventOrDateString.target.value);

                // https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-144
                this.callOnChange(
                    number,
                    this.props.valueEnd,
                );

                if (number < this.props.minimumValue) {
                    this.props.onMinValueError();
                }
            }
        } else {
            this.callOnChange(
                eventOrDateString,
                this.props.valueEnd,
            );
        }
    };

    sliderChanged = (values) => {
        if (values.length === 2) {
            const [start, end] = values;

            this.callOnChange(start, end);
        }
    };

    render() {
        const inputType          = this.getInputType();
        const placeholder        = this.getInputPlaceholder();
        const markWhenHasContent = this.props.theme === RangeInputTheme.default;

        return (
            <div
                className={classNames(
                    styles.wrapper,
                    {
                        [styles.alignInput]: this.props.paddingRight,
                    },
                )}
            >
                <div className={styles.inputWrapper}>
                    <Input
                        errorText={this.props.errorTextStart}
                        key={'from' + this.props.valueStart}
                        markWhenHasContent={markWhenHasContent}
                        maximumValue={this.props.maximumValue}
                        minimumValue={this.props.minimumValue}
                        onChange={this.minimumTextChanged}
                        overlayPosition={this.props.overlayPosition}
                        placeholder={placeholder}
                        prefix={I18n.t('rangePrefix')}
                        postfix={this.getPostfix(this.props.valueStart)}
                        step={this.props.step}
                        type={inputType}
                        value={this.props.valueStart}
                    />
                    <Input
                        errorText={this.props.errorTextEnd}
                        key={'to' + this.props.valueEnd}
                        markWhenHasContent={markWhenHasContent}
                        maximumValue={this.props.maximumValue}
                        minimumValue={this.props.minimumValue}
                        onChange={this.maximumTextChanged}
                        overlayPosition={this.props.overlayPosition}
                        placeholder={placeholder}
                        prefix={I18n.t('rangePostfix')}
                        postfix={this.getPostfix(this.props.valueEnd)}
                        step={this.props.step}
                        type={inputType}
                        value={this.props.valueEnd}
                    />
                </div>
                {this.renderRangeSlider()}
            </div>
        );
    }

    renderRangeSlider = () => {
        if (this.props.type === RangeInputType.number && !this.props.withoutSlider) {
            return (
                <div className={styles.sliderWrapper}>
                    <RangeSlider
                        maximumValue={this.props.maximumValue}
                        minimumValue={this.props.minimumValue}
                        onChange={this.sliderChanged}
                        step={this.props.step}
                        theme={this.getRangeSliderTheme()}
                        valueEnd={this.props.valueEnd}
                        valueStart={this.props.valueStart}
                    />
                </div>
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = RangeInput;

Component.propTypes = {
    errorTextEnd:    PropTypes.string,
    errorTextStart:  PropTypes.string,
    maximumValue:    PropTypes.oneOf([
        PropTypes.number,
        PropTypes.object,
    ]),
    minimumValue:    PropTypes.oneOf([
        PropTypes.number,
        PropTypes.object,
    ]),
    onChange:        PropTypes.func,
    onMaxValueError: PropTypes.func,
    onMinValueError: PropTypes.func,
    overlayPosition: PropTypes.oneOf(Object.keys(DatePickerOverlayPosition)),
    paddingRight:    PropTypes.bool,
    postfix:         PropTypes.string,
    postfixSingular: PropTypes.string,
    step:            PropTypes.number,
    theme:           PropTypes.oneOf(Object.keys(RangeInputTheme)),
    type:            PropTypes.oneOf(Object.keys(RangeInputType)),
    valueEnd:        PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.object,
    ]),
    valueStart:      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.object,
    ]),
    withoutSlider:   PropTypes.bool,
};

Component.defaultProps = {
    errorTextEnd:    '',
    errorTextStart:  '',
    maximumValue:    1337,
    minimumValue:    0,
    onChange:        _.noop,
    onMaxValueError: _.noop,
    onMinValueError: _.noop,
    overlayPosition: DatePickerOverlayPosition.bottom,
    paddingRight:    false,
    postfix:         null,
    postfixSingular: null,
    step:            1,
    theme:           RangeInputTheme.default,
    type:            RangeInputType.number,
    valueEnd:        42,
    valueStart:      100,
    withoutSlider:   false,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
