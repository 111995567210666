//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import Briefing              from '../../../screens/Briefing';
import CompanyProfile        from '../../../screens/CompanyProfile';
import ComponentHelper       from '../../../helper/ComponentHelper';
import Error                 from '../../../screens/Error';
import Home                  from '../../../screens/Home';
import Login                 from '../../../screens/Login';
import Match                 from '../../../screens/Match';
import Matches               from '../../../screens/Matches';
import Messages              from '../../../screens/Messages';
import MobileRegisterSuccess from '../../../screens/MobileRegisterSuccess';
import NotFound              from '../../../screens/NotFound';
import Offers                from '../../../screens/Offers';
import React                 from 'react';
import Routes                from '../../../constants/Routes';
import ScreenDesign          from '../../../screens/ScreenDesign';
import styles                from './styles.module.scss';
import UserProfile           from '../../../screens/UserProfile';
import { Route }             from 'react-router';
import { Switch }            from 'react-router';

class MainRouter extends React.Component {
    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.wrapperInner}>
                    <Switch>
                        <Route
                            exact
                            path={Routes.briefing}
                            component={Briefing}
                        />
                        <Route
                            exact
                            path={Routes.companyProfile}
                            component={CompanyProfile}
                        />
                        <Route
                            exact
                            path={Routes.userProfile}
                            component={UserProfile}
                        />
                        <Route
                            exact
                            path={Routes.login}
                            component={Login}
                        />
                        <Route
                            exact
                            path={Routes.newPassword}
                            component={Login}
                        />
                        <Route
                            exact
                            path={Routes.verifyRegistration}
                            component={Login}
                        />
                        <Route
                            exact
                            path={Routes.home}
                            component={Home}
                        />
                        <Route
                            exact
                            path={Routes.match}
                            component={Match}
                        />
                        <Route
                            exact
                            path={Routes.matches}
                            component={Matches}
                        />
                        <Route
                            exact
                            path={Routes.messages}
                            component={Messages}
                        />
                        <Route
                            exact
                            path={Routes.offers}
                            component={Offers}
                        />
                        <Route
                            exact
                            path={Routes.mobileRegisterSuccess}
                            component={MobileRegisterSuccess}
                        />
                        <Route
                            exact
                            path={Routes.error}
                            component={Error}
                        />
                        <Route
                            exact
                            path={Routes.screenDesign}
                            component={ScreenDesign}
                        />
                        <Route component={NotFound} />
                    </Switch>
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = MainRouter;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
