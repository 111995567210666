//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import ComponentHelper from '../../../helper/ComponentHelper';
import Icon            from '../Icon';
import IconType        from '../Icon/IconType';
import Link            from '../Link';
import classNames      from 'classnames';

class ProjectMenuSubItem extends React.Component {
    render () {
        const mainClass = classNames(
            styles.link,
            {
                [styles.linkActive]: this.props.isActive,
            },
        );

        const inner = (
            <>
                {this.renderIcon()}
                {this.props.text}
                {this.renderBadge()}
            </>
        );

        if (this.props.onClick) {
            return (
                <div
                    className={mainClass}
                    onClick={this.props.onClick}
                >
                    {inner}
                </div>
            );
        } else if (this.props.to) {
            return (
                <Link
                    className={mainClass}
                    to={this.props.to}
                >
                    {inner}
                </Link>
            );
        }

        return null;
    }

    renderBadge = () => {
        if (this.props.badeCount) {
            return (
                <span className={styles.badge}>
                    {this.props.badeCount}
                </span>
            );
        }

        return null;
    };

    renderIcon = () => {
        if (this.props.iconType) {
            return (
                <div className={styles.iconWrapper}>
                    <Icon iconType={this.props.iconType} />
                </div>
            );
        }

        return null;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ProjectMenuSubItem;

Component.propTypes = {
    badeCount: PropTypes.number,
    iconType:  PropTypes.oneOf(Object.values(IconType)),
    isActive:  PropTypes.bool,
    onClick:   PropTypes.func,
    text:      PropTypes.string,
    to:        PropTypes.string,
};

Component.defaultProps = {
    badeCount: null,
    iconType:  null,
    isActive:  false,
    onClick:   null,
    text:      '',
    to:        '',
};

Component.renderAffectingProps = [
    'badeCount',
    'iconType',
    'isActive',
    'text',
];

Component.renderAffectingStates = [];

export default Component;