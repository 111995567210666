//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                     from 'lodash';
import ComponentHelper       from '../../../helper/ComponentHelper';
import IconType              from '../../stateless/Icon/IconType';
import PropTypes             from '../../PropTypes';
import React                 from 'react';
import StatelessMainMenuItem from '../../stateless/MainMenuItem';
import { connect }           from 'react-redux';

class MainMenuItem extends React.Component {
    render () {
        return (
            <StatelessMainMenuItem
                iconType={this.props.iconType}
                isActive={this.props.isActive}
                text={this.props.text}
                to={this.props.to}
                onClick={this.props.onClick}
                reducePadding={this.props.leftPaneSize < 400}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = MainMenuItem;

Component.propTypes = {
    iconType:     PropTypes.oneOf(Object.values(IconType)),
    isActive:     PropTypes.bool,
    leftPaneSize: PropTypes.number,
    onClick:      PropTypes.func,
    text:         PropTypes.string,
    to:           PropTypes.string,
};

Component.defaultProps = {
    iconType:     null,
    isActive:     false,
    leftPaneSize: 0,
    onClick:      null,
    text:         '',
    to:           '',
};

Component.renderAffectingProps = [
    'isActive',
    'iconType',
    'leftPaneSize',
    'text',
];

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        leftPaneSize: _.get(state, 'mainSplitPane.leftPaneSize'),
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);