//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                            from 'lodash';
import appStyles                    from '../../../styles.module.scss';
import FormRow                      from '../../stateless/FormRow';
import I18n                         from 'i18next';
import Input                        from '../../stateless/Input';
import InputType                    from '../../stateless/Input/InputType';
import OverlayButton                from '../../stateless/OverlayButton';
import OverlayButtonTheme           from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper         from '../../stateless/OverlayButtonWrapper';
import OverlayPane                  from '../../stateless/OverlayPane';
import OverlayPaneState             from '../../stateless/OverlayPane/OverlayPaneState';
import PropTypes                    from '../../PropTypes';
import React                        from 'react';
import Spacer                       from '../../stateless/Spacer';
import { AlertBoxActions }          from '../../../store/actions/alertBox';
import { bindActionCreators }       from 'redux';
import { connect }                  from 'react-redux';
import { CreateEditProjectActions } from '../../../store/actions/createEditProject';
import { UserActions }              from '../../../store/actions/user';

class RegisterUserOverlayPane extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password:      null,
            passwordCheck: null,
        };
    }

    backButtonPressed = () => {
        this.props.clearAlerts();
        this.props.previousPane();
    };

    cancelButtonPressed = () => {
        this.props.goBack();
    };

    saveButtonPressed = () => {
        this.props.clearAlerts();

        let isValid = true;

        if (!_.get(this.props, 'companyName') && !this.props.isVideoProduction) {
            isValid = false;

            this.props.showErrorAlertTagged({
                tag:  'companyNameErrorEmpty',
                text: I18n.t('companyNameErrorEmpty'),
            });
        }

        if (!_.get(this.props, 'userData.firstname.value')) {
            isValid = false;

            this.props.showErrorAlertTagged({
                tag:  'userFirstnameError',
                text: I18n.t('emptyFirstnameError'),
            });
        }

        if (!_.get(this.props, 'userData.name.value')) {
            isValid = false;

            this.props.showErrorAlertTagged({
                tag:  'userNameError',
                text: I18n.t('emptyNameError'),
            });
        }

        if (!_.get(this.props, 'userData.email.value')) {
            isValid = false;

            this.props.showErrorAlertTagged({
                tag:  'userEmailError',
                text: I18n.t('userEmailError'),
            });
        }

        if (!this.state.password) {
            isValid = false;

            this.props.showErrorAlertTagged({
                tag:  'password1',
                text: I18n.t('profilePasswordError1'),
            });
        } else if (this.state.password.length < 8) {
            isValid = false;

            this.props.showErrorAlertTagged({
                tag:  'password1',
                text: I18n.t('profilePasswordError2'),
            });
        } else if (this.state.password !== this.state.passwordCheck) {
            isValid = false;

            this.props.showErrorAlertTagged({
                tag:  'password2',
                text: I18n.t('profilePasswordError3'),
            });
        } else {
            this.props.temporarySetOwnProfilePassword({
                password: this.state.password,
            });
        }

        if (isValid) {
            this.props.onContinue();
        }
    };

    userFieldChanged = (label) => {
        return (event) => {
            this.props.clearAlerts();
            this.props.temporarySetOwnProfileData({
                data: {
                    label,
                    value: event.target.value,
                },
            });
        };
    };

    passwordChanged = (event) => {
        this.setState({
            password: event.target.value,
        });
    };

    passwordCheckChanged = (event) => {
        this.setState({
            passwordCheck: event.target.value,
        });
    };

    companyNameChanged = (event) => {
        this.props.temporarySetOwnCompanyName({
            name: event.target.value,
        });
    };

    render() {
        return (
            <OverlayPane
                content={this.renderContent()}
                footer={this.renderFooter()}
                title={I18n.t('userData')}
                showErrors={true}
                state={this.props.state}
                scrollingAllowed={false}
            />
        );
    }

    renderCompanyNameField = () => {
        if (!this.props.isVideoProduction) {
            return (
                <FormRow
                    label={I18n.t('companyNameLabel')}
                    tooltipText={I18n.t('companyNameTooltipText')}
                >
                    <Input
                        placeholder={I18n.t('defaultPlaceHolderTextInput')}
                        type={InputType.text}
                        value={_.get(this.props, 'companyName')}
                        onChange={this.companyNameChanged}
                        errorText={this.props.companyNameError}
                    />
                </FormRow>
            );
        }

        return null;
    };

    renderContent = () => {
        const userData = this.props.userData;

        return (
            <>
                {this.renderCompanyNameField()}
                <FormRow label={I18n.t('firstname')}>
                    <Input
                        placeholder={I18n.t('firstname')}
                        type={InputType.text}
                        value={_.get(userData, 'firstname.value')}
                        onChange={this.userFieldChanged('firstname')}
                        errorText={this.props.userFirstnameError}
                    />
                </FormRow>
                <FormRow label={I18n.t('name')}>
                    <Input
                        placeholder={I18n.t('name')}
                        type={InputType.text}
                        value={_.get(userData, 'name.value')}
                        onChange={this.userFieldChanged('name')}
                        errorText={this.props.userNameError}
                    />
                </FormRow>
                <FormRow label={I18n.t('email')}>
                    <Input
                        placeholder={I18n.t('email')}
                        type={InputType.text}
                        value={_.get(userData, 'email.value')}
                        onChange={this.userFieldChanged('email')}
                        errorText={this.props.userEmailError}
                    />
                </FormRow>
                <FormRow label={I18n.t('password')}>
                    <Input
                        placeholder={I18n.t('password')}
                        type={InputType.password}
                        onChange={this.passwordChanged}
                        errorText={this.props.passwordError1}
                    />
                </FormRow>
                <FormRow label={I18n.t('passwordCheck')}>
                    <Input
                        placeholder={I18n.t('passwordCheck')}
                        type={InputType.password}
                        onChange={this.passwordCheckChanged}
                        errorText={this.props.passwordError2}
                    />
                </FormRow>
                {this.renderHelp()}
            </>
        );
    };

    renderHelp = () => {
        if (!this.props.isVideoProduction) {
            return (
                <>
                    <p className={appStyles.helpText}>
                        {I18n.t('registerCompanyInfoText')}
                    </p>
                    <Spacer height={40} />
                </>
            );
        }

        return null;
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.props.isFirst ? this.cancelButtonPressed : this.backButtonPressed}
                    standAlone={false}
                    text={this.props.isFirst ? I18n.t('cancel') : I18n.t('back')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.saveButtonPressed}
                    standAlone={false}
                    text={I18n.t('save')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };
}

const Component = RegisterUserOverlayPane;

Component.propTypes = {
    companyName:            PropTypes.string,
    companyNameError:       PropTypes.string,
    isFirst:                PropTypes.bool,
    isVideoProduction:      PropTypes.bool,
    state:                  PropTypes.oneOf(Object.values(OverlayPaneState)),
    userData:               PropTypes.object,
    userEmailError:         PropTypes.string,
    userFirstnameError:     PropTypes.string,
    userNameError:          PropTypes.string,
    userPasswordCheckError: PropTypes.string,
    userPasswordError:      PropTypes.string,
};

Component.defaultProps = {
    companyName:            '',
    companyNameError:       null,
    isFirst:                false,
    isVideoProduction:      false,
    state:                  null,
    userData:               {},
    userEmailError:         null,
    userFirstnameError:     null,
    userNameError:          null,
    userPasswordCheckError: null,
    userPasswordError:      null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        AlertBoxActions,
        CreateEditProjectActions,
        UserActions,
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        companyName:        _.get(state, 'company.ownCompanyEditContext.name', null),
        companyNameError:   _.get(state, 'alertBox.alertBoxesTagged.companyNameErrorEmpty'),
        passwordError1:     _.get(state, 'alertBox.alertBoxesTagged.password1', null),
        passwordError2:     _.get(state, 'alertBox.alertBoxesTagged.password2', null),
        passwordError3:     _.get(state, 'alertBox.alertBoxesTagged.password3', null),
        userData:           _.get(state, 'user.ownUserEditContext', {}),
        userEmailError:     _.get(state, 'alertBox.alertBoxesTagged.userEmailError'),
        userFirstnameError: _.get(state, 'alertBox.alertBoxesTagged.userFirstnameError'),
        userNameError:      _.get(state, 'alertBox.alertBoxesTagged.userNameError'),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
