//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                      from 'lodash';
import FormRow                from '../../stateless/FormRow';
import I18n                   from 'i18next';
import Input                  from '../../stateless/Input';
import InputType              from '../../stateless/Input/InputType';
import Overlay                from '../Overlay';
import OverlayButton          from '../../stateless/OverlayButton';
import OverlayButtonTheme     from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper   from '../../stateless/OverlayButtonWrapper';
import OverlayPane            from '../../stateless/OverlayPane';
import OverlayPaneState       from '../../stateless/OverlayPane/OverlayPaneState';
import Overlays               from '../../../constants/Overlays';
import PropTypes              from '../../PropTypes';
import React                  from 'react';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { connect }            from 'react-redux';
import { goBack }             from 'connected-react-router';
import { UserActions }        from '../../../store/actions/user';
import { withRouter }         from 'react-router-dom';

class Component extends React.Component {
    cancelButtonPressed = () => {
        this.props.goBack();
    };

    fieldChanged = (label) => {
        return (event) => {
            this.props.temporarySetOwnProfileData({
                data: {
                    label,
                    value: event.target.value,
                },
            });
        };
    };

    render() {
        return (
            <Overlay
                id={Overlays.editUserData}
                title={I18n.t('editUserProfileDetails')}
            >
                <OverlayPane
                    content={this.renderContent()}
                    footer={this.renderFooter()}
                    title={I18n.t('userData')}
                    showErrors={true}
                    state={OverlayPaneState.open}
                    scrollingAllowed={false}
                />
            </Overlay>
        );
    }

    renderContent = () => {
        const dataObject   = Object.keys(this.props.userData);
        const data         = dataObject.map((label) => (
            {
                label,
                value: this.props.userData[label].value,
                group: this.props.userData[label].group,
            }
        ));
        const filteredData = _.filter(data, { group: 'default' });

        return filteredData.map(this.renderRow);
    };

    renderRow = (data, index) => {
        const label = I18n.t(data.label);

        return (
            <FormRow
                label={label}
                key={index}
            >
                <Input
                    placeholder={label}
                    type={InputType.text}
                    value={data.value}
                    onChange={this.fieldChanged(data.label)}
                />
            </FormRow>
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.cancelButtonPressed}
                    standAlone={false}
                    text={I18n.t('cancel')}
                    theme={OverlayButtonTheme.gray}
                />
                <OverlayButton
                    onClick={this.saveButtonPressed}
                    standAlone={false}
                    text={I18n.t('save')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    saveButtonPressed = () => {
        const userData = _.get(this.props, 'userData');

        requestAnimationFrame(() => {
            this.props.updateUser({
                data: userData,
            });

            this.props.goBack();
        });
    };
}

Component.propTypes = {
    userData: PropTypes.object,
};

Component.defaultProps = {
    userData: {},
};

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        UserActions,
        {
            goBack,
        },
    ),
    dispatch,
);

const mapStateToProps = state => (
    {
        userData: _.get(state, 'user.ownUserEditContext', {}),
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Component);
