//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React             from 'react';
import _                 from 'lodash';
import classNames        from 'classnames';
import colors            from '../../../styles/colors.scss';
import ComponentHelper   from '../../../helper/ComponentHelper';
import DropDownDirection from './DropDownDirection';
import I18n              from 'i18next';
import Icon              from '../Icon';
import IconType          from '../Icon/IconType';
import InputError        from '../InputError';
import PropTypes         from '../../PropTypes';
import Select            from 'react-select';
import styles            from './styles.module.scss';
import Window            from '../../../helper/Window';
import zIndexes          from '../../../styles/zIndexes.scss';

class DropDown extends React.Component {
    clearButtonPressed = (event) => {
        this.props.onClear();

        event.preventDefault();
        event.stopPropagation();

        return false;
    };

    getCustomComponents = () => {
        const components = {};

        components.ClearIndicator = (innerRef, innerProps) => {
            return (
                <div
                    {...innerProps}
                    className={styles.clearIndicator}
                    onClick={this.clearButtonPressed}
                >
                    <Icon iconType={IconType.x} />
                </div>
            );
        };

        components.DropdownIndicator = (innerRef, innerProps) => {
            if (this.props.options.length <= 1) {
                return (
                    <div
                        {...innerProps}
                        className={classNames(
                            styles.dropdownIndicator,
                        )}
                        onClick={this.props.onRefreshDataClicked}
                    >
                        <Icon iconType={IconType.refresh} />
                    </div>
                );
            }

            return (
                <div
                    {...innerProps}
                    className={classNames(
                        styles.dropdownIndicator,
                        {
                            [styles.dropdownIndicatorOpened]: _.get(innerRef, 'selectProps.menuIsOpen', false),
                        },
                    )}
                >
                    <Icon iconType={IconType.chevronDown} />
                </div>
            );
        };

        components.IndicatorSeparator = (innerRef, innerProps) => {
            return null;
        };

        components.NoOptionsMessage = (innerRef, innerProps) => {
            return (
                <div className={styles.noOptionsMessage}>
                    <Icon iconType={IconType.frown} />
                    {I18n.t('selectSearchEmptyMessage')}
                </div>
            );
        };

        return components;
    };

    getNoOptionsMessage = () => {
        return I18n.t('selectSearchEmptyMessage');
    };

    getPlaceHolder = () => {
        if (this.props.placeholder) {
            return this.props.placeholder;
        }

        return I18n.t('defaultPlaceHolderText');
    };

    // @see https://react-select.com/styles#style-object
    getSelectStyle = () => {
        return {
            container:           (provided, state) => (
                {
                    ...provided,
                    height:       50,
                    border:       0,
                    cursor:       'pointer',
                    marginBottom: (
                                      this.props.noMarginBottom ?
                                          0 :
                                          20
                                  ),
                }
            ),
            control:             (provided, state) => {
                const style = {
                    ...provided,
                    height:          50,
                    backgroundColor: colors.colorGrayLighter7,
                    border:          0,
                    outline:         'none',
                    boxShadow:       'none',
                    cursor:          'pointer',
                    borderRadius:    (
                                         state.menuIsOpen ?
                                             '3px 3px 0 0' :
                                             '3px'
                                     ),
                };

                if (this.props.errorText) {
                    style.borderWidth = '1px';
                    style.borderStyle = 'solid';
                    style.borderColor = colors.colorRed;

                    delete style.border;
                } else if (
                    this.props.selectedValue ||
                    this.props.forceFlagAsFilled
                ) {
                    style.borderRightColor = (
                        state.menuIsOpen ?
                            colors.colorGrayLighter7 :
                            colors.colorTurquoise
                    ) + ' !important';
                    style.borderRightWidth = 3;
                    style.borderRightStyle = 'solid';
                }

                return style;
            },
            indicatorsContainer: (provided, state) => {
                return {
                    ...provided,
                    paddingRight: 15,
                };
            },
            input:               (provided, state) => {
                return {
                    ...provided,
                    color: colors.colorGrayLighter3,
                };
            },
            option:              (provided, state) => (
                {
                    ...provided,
                    color:           (
                                         state.isSelected || state.isFocused ?
                                             colors.colorGrayLighter7 :
                                             colors.colorGrayLighter3
                                     ),
                    backgroundColor: (
                                         state.isSelected || state.isFocused ?
                                             colors.colorTurquoise :
                                             colors.colorGrayLighter7
                                     ) + ' !important',
                    cursor:          'pointer',
                    height:          40,
                    alignItems:      'center',
                    display:         'flex',
                    paddingLeft:     16,
                    paddingRight:    15,
                }
            ),
            menu:                (provided, state) => (
                {
                    ...provided,
                    backgroundColor: colors.colorGrayLighter7,
                    borderRadius:    '0 0 3px 3px',
                    margin:          0,
                    overflow:        'hidden',
                    zIndex:          zIndexes.zIndexDropDownMenu,
                }
            ),
            menuList:            (provided, state) => {
                const style = {
                    ...provided,
                    padding: 0,
                };

                if (Window.isMobile()) {
                    style.maxHeight = 200;
                }

                return style;
            },
            noOptionsMessage:    (provided, state) => (
                {
                    ...provided,
                    color:   colors.colorGrayLighter3,
                    padding: 50,
                }
            ),
            placeholder:         (provided, state) => (
                {
                    ...provided,
                    color:    colors.colorGrayLighter3,
                    fontSize: 15,
                    margin:   0,
                }
            ),
            singleValue:         (provided, state) => (
                {
                    ...provided,
                    color:       colors.colorGrayLighter3,
                    marginLeft:  0,
                    marginRight: 0,
                }
            ),
            valueContainer:      (provided, state) => (
                {
                    ...provided,
                    paddingLeft:  15,
                    paddingRight: 15,
                }
            ),
        };
    };

    static mapHydraDataForValueField = (inputData, valuePropertyName, addUndefinedValue = false) => {
        const data = [];

        if (inputData) {
            for (const inputDataEntry of inputData) {
                data.push({
                    label: inputDataEntry[valuePropertyName],
                    value: inputDataEntry.iri,
                });
            }
        }

        if (addUndefinedValue) {
            data.push({
                label: I18n.t('fillLaterChoiceText'),
                value: null,
            });
        }

        return DropDown.sortDataByLabel(data);
    };

    onChange = (selectedOption) => {
        if (selectedOption) {
            this.props.onChange(selectedOption);
        }
    };

    render() {
        const customComponents = this.getCustomComponents(this.props.options);
        const selectedOption   = _.find(
            this.props.options,
            {
                value: this.props.selectedValue,
            },
        );

        return (
            <div className={styles.wrapper}>
                <Select
                    components={customComponents}
                    isClearable={true}
                    isDisabled={false}
                    isLoading={false}
                    isRtl={false}
                    key={selectedOption} // This fixes/workarounds: https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-252
                    noOptionsMessage={this.getNoOptionsMessage}
                    onChange={this.onChange}
                    options={this.props.options}
                    placeholder={this.getPlaceHolder()}
                    styles={this.getSelectStyle()}
                    value={selectedOption}
                    menuPlacement={this.props.collapseDirection}
                    isSearchable={!Window.isMobile()}
                />
                {this.renderError()}
            </div>
        );
    }

    renderError = () => {
        if (this.props.errorText) {
            return (
                <InputError
                    marginTop={
                        this.props.noMarginBottom ?
                            0 :
                            -20
                    }
                    text={this.props.errorText}
                />
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    static sortDataByLabel = (data) => {
        const sortedVideoTypes = _.sortBy(data, 'label');

        return sortedVideoTypes;
    };
}

const Component = DropDown;

Component.propTypes = {
    collapseDirection:    PropTypes.oneOf(Object.values(DropDownDirection)),
    errorText:            PropTypes.string,
    forceFlagAsFilled:    PropTypes.bool,
    noMarginBottom:       PropTypes.bool,
    onChange:             PropTypes.func,
    onClear:              PropTypes.func,
    onRefreshDataClicked: PropTypes.func,
    options:              PropTypes.array,
    placeholder:          PropTypes.string,
    selectedValue:        PropTypes.string,
};

Component.defaultProps = {
    collapseDirection:    DropDownDirection.down,
    errorText:            null,
    forceFlagAsFilled:    false,
    noMarginBottom:       false,
    onChange:             _.noop,
    onClear:              _.noop,
    onRefreshDataClicked: _.noop,
    options:              [],
    placeholder:          null,
    selectedValue:        null,
};

Component.renderAffectingProps = [
    'collapseDirection',
    'errorText',
    'noMarginBottom',
    'options',
    'placeholder',
    'selectedValue',
];

Component.renderAffectingStates = [];

export default Component;
