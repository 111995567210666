//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const AlertBoxTypes = {
    CLEAR:             'AlertBox/CLEAR',
    CLEAR_SINGLE:      'AlertBox/CLEAR_SINGLE',
    SHOW_ERROR:        'AlertBox/SHOW_ERROR',
    SHOW_ERROR_TAGGED: 'AlertBox/SHOW_ERROR_TAGGED',
    SHOW_SUCCESS:      'AlertBox/SHOW_SUCCESS',
};

const clearAlerts = makeActionCreator(
    AlertBoxTypes.CLEAR,
);

const clearSingleAlert = makeActionCreator(
    AlertBoxTypes.CLEAR_SINGLE,
    {
        index: null,
    },
);

const showErrorAlert = makeActionCreator(
    AlertBoxTypes.SHOW_ERROR,
    {
        text: null,
    },
);

const showErrorAlertTagged = makeActionCreator(
    AlertBoxTypes.SHOW_ERROR_TAGGED,
    {
        tag:   null,
        text:  null,
        index: null,
    },
);

const showSuccessAlert = makeActionCreator(
    AlertBoxTypes.SHOW_SUCCESS,
    {
        text: null,
    },
);

export const AlertBoxActions = {
    clearAlerts,
    clearSingleAlert,
    showErrorAlert,
    showErrorAlertTagged,
    showSuccessAlert,
};
