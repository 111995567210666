//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                 from 'react';
import _                     from 'lodash';
import ComponentHelper       from '../../../helper/ComponentHelper';
import DropDownDirection     from '../DropDown/DropDownDirection';
import FormRow               from '../FormRow';
import I18n                  from 'i18next';
import Input                 from '../Input';
import InputSize             from '../Input/InputSize';
import InputType             from '../Input/InputType';
import PropTypes             from '../../PropTypes';
import RoleInCompanyDropDown from '../../connected/RoleInCompanyDropDown';
import Spacer                from '../../stateless/Spacer';

class CompanyForm extends React.Component {
    render() {
        return (
            <>
                {this.renderContentCompanyName()}
                {this.renderContentDescription()}
                {this.renderContentWebsite()}
                {this.renderContentRoleInCompany()}
                {this.renderContentPhoneNumber()}
            </>
        );
    }

    renderContentCompanyName = () => {
        return (
            <FormRow
                label={I18n.t('companyNameLabel')}
                tooltipText={I18n.t('companyNameTooltipText')}
            >
                <Input
                    errorText={this.props.nameError}
                    noMarginBottom={true}
                    onChange={this.props.nameChanged}
                    placeholder={I18n.t('defaultPlaceHolderTextInput')}
                    size={InputSize.singleLine}
                    type={InputType.text}
                    value={_.get(this.props, 'name')}
                />
            </FormRow>
        );
    };

    renderContentDescription = () => {
        if (this.props.descriptionChanged) {
            return (
                <FormRow
                    label={I18n.t('companyDescription')}
                    tooltipText={I18n.t('companyDescriptionTooltipText')}
                >
                    <Input
                        errorText={this.props.descriptionError}
                        noMarginBottom={true}
                        onChange={this.props.descriptionChanged}
                        placeholder={I18n.t('defaultPlaceHolderTextInput')}
                        size={InputSize.multiLine}
                        type={InputType.text}
                        value={_.get(this.props, 'description')}
                    />
                </FormRow>
            );
        }

        return null;
    };

    renderContentWebsite = () => {
        return (
            <FormRow
                label={I18n.t('websiteLabel')}
                tooltipText={I18n.t('websiteTooltipText')}
            >
                <Input
                    errorText={this.props.websiteError}
                    noMarginBottom={true}
                    onChange={this.props.websiteChanged}
                    placeholder={I18n.t('defaultPlaceHolderTextInput')}
                    size={InputSize.singleLine}
                    type={InputType.url}
                    value={_.get(this.props, 'website')}
                />
            </FormRow>
        );
    };

    renderContentRoleInCompany = () => {
        return (
            <FormRow
                label={I18n.t('roleInCompanyLabel')}
                tooltipText={I18n.t('roleInCompanyTooltipText')}
            >
                <RoleInCompanyDropDown
                    noMarginBottom={true}
                    onChange={this.props.roleInCompanyChanged}
                    selectedValue={_.get(this.props, 'roleInCompany')}
                    collapseDirection={DropDownDirection.up}
                />
            </FormRow>
        );
    };

    renderContentPhoneNumber = () => {
        if (this.props.phoneNumberChanged) {
            return (
                <FormRow
                    label={I18n.t('phoneNumberLabel')}
                    tooltipText={I18n.t('phoneNumberTooltipText')}
                >
                    <Input
                        noMarginBottom={true}
                        onChange={this.props.phoneNumberChanged}
                        placeholder={I18n.t('phoneNumberPlaceholder')}
                        size={InputSize.singleLine}
                        type={InputType.tel}
                        value={_.get(this.props, 'phoneNumber')}
                    />
                    <Spacer height={20} />
                </FormRow>
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CompanyForm;

Component.propTypes = {
    description:          PropTypes.string,
    descriptionChanged:   PropTypes.func,
    descriptionError:     PropTypes.string,
    name:                 PropTypes.string,
    nameChanged:          PropTypes.func,
    nameError:            PropTypes.string,
    phoneNumber:          PropTypes.string,
    phoneNumberChanged:   PropTypes.func,
    roleInCompany:        PropTypes.string,
    roleInCompanyChanged: PropTypes.func,
    website:              PropTypes.string,
    websiteChanged:       PropTypes.func,
    websiteError:         PropTypes.string,
};

Component.defaultProps = {
    description:          '',
    descriptionChanged:   null,
    descriptionError:     null,
    name:                 '',
    nameChanged:          _.noop,
    nameError:            null,
    phoneNumber:          '',
    phoneNumberChanged:   null,
    roleInCompany:        '',
    roleInCompanyChanged: _.noop,
    website:              '',
    websiteChanged:       _.noop,
    websiteError:         null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
