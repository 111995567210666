//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import PropTypes                         from '../../PropTypes';
import React                             from 'react';
import styles                            from './styles.module.scss';
import LeftRightSplitterContentAlignment from './LeftRightSplitterContentAlignment';
import classNames                        from 'classnames';
import SelectionHelper                   from '../../../helper/SelectionHelper';
import ComponentHelper                   from '../../../helper/ComponentHelper';

class LeftRightSplitter extends React.Component {
    getSplitterAlignmentClass = () => {
        return SelectionHelper.get(
            this.props.contentAlignment,
            {
                [LeftRightSplitterContentAlignment.center]:  styles.leftRightSplitterAlignCenter,
                [LeftRightSplitterContentAlignment.default]: styles.leftRightSplitterAlignDefault,
            },
        );
    };

    getStyle = (flex) => {
        if (flex) {
            return {
                flex,
            };
        }

        return {};
    };

    getStyleLeft = () => {
        return this.getStyle(this.props.flexLeft);
    };

    getStyleRight = () => {
        return this.getStyle(this.props.flexRight);
    };

    render () {
        const splitterAlignmentClass = this.getSplitterAlignmentClass();

        return (
            <div className={styles.leftRightSplitter}>
                <div
                    className={classNames(
                        styles.leftRightSplitterLeft,
                        splitterAlignmentClass,
                    )}
                    style={this.getStyleLeft()}
                >
                    {this.props.leftContent}
                </div>
                <div
                    className={classNames(
                        styles.leftRightSplitterRight,
                        splitterAlignmentClass,
                    )}
                    style={this.getStyleRight()}
                >
                    {this.props.rightContent}
                </div>
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = LeftRightSplitter;

Component.propTypes = {
    contentAlignment: PropTypes.oneOf(Object.values(LeftRightSplitterContentAlignment)),
    flexLeft:         PropTypes.number,
    flexRight:        PropTypes.number,
    leftContent:      PropTypes.object,
    rightContent:     PropTypes.object,
};

Component.defaultProps = {
    contentAlignment: LeftRightSplitterContentAlignment.default,
    flexLeft:         1,
    flexRight:        1,
    leftContent:      null,
    rightContent:     null,
};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

export default Component;
