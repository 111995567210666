//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }                    from 'redux-saga/effects';
import { call }                   from 'redux-saga/effects';
import * as Api                   from '../../api';
import HydraHelper                from '../../helper/Hydra';
import { LicenseDurationActions } from '../actions/licenseDuration';

const fetchLicenseDurations = function* () {
    const response = yield call(
        Api.fetchLicenseDurations,
    );

    if (response.ok) {
        const cleanHydraResponse = HydraHelper.cleanupObject(response.data);
        const licenseDurations   = cleanHydraResponse.member;

        yield put(LicenseDurationActions.fetchLicenseDurationsSuccess({
            licenseDurations,
        }));
    } else {
        yield put(LicenseDurationActions.fetchLicenseDurationsFailed());
    }
};

export default {
    fetchLicenseDurations,
};
