//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _               from 'lodash';
import CallToActionBox from '../CallToActionBox';
import ComponentHelper from '../../../helper/ComponentHelper';
import I18n            from 'i18next';
import Overlay         from '../../connected/Overlay';
import OverlayPane     from '../OverlayPane';
import Overlays        from '../../../constants/Overlays';
import PropTypes       from 'prop-types';
import React           from 'react';
import Spacer          from '../Spacer';
import styles          from './styles.module.scss';

class Component extends React.Component {
    render () {
        return (
            <Overlay
                id={Overlays.register}
                title={I18n.t('register')}
            >
                <OverlayPane
                    content={this.renderContent()}
                    scrollingAllowed={false}
                    description={I18n.t('createAFreeAccount')}
                    largeDescription={true}
                />
            </Overlay>
        );
    }

    renderContent = () => {
        return (
            <div className={styles.wrapper}>
                <CallToActionBox
                    rawTitle={I18n.t('registerCompanyCTATitle')}
                    text={I18n.t('registerCompanyCTAText')}
                    buttonText={I18n.t('register')}
                    onClick={this.props.registerCompanyClicked}
                />
                <Spacer
                    display={'flex'}
                    text={I18n.t('or')}
                    lines={true}
                    height={60}
                />
                <CallToActionBox
                    rawTitle={I18n.t('registerVideoProductionCTATitle')}
                    text={I18n.t('registerVideoProductionCTAText')}
                    buttonText={I18n.t('register')}
                    onClick={this.props.registerVideoProductionClicked}
                />
            </div>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {
    registerCompanyClicked:         PropTypes.func,
    registerVideoProductionClicked: PropTypes.func,
};

Component.defaultProps = {
    registerCompanyClicked:         _.noop,
    registerVideoProductionClicked: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;