//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

/**
 *
 * @param type
 * @param props
 * @returns {function(...[*]): {type: *}}
 */
export const makeActionCreator = (type, props = {}) => {
    const action = { type, ...props };

    return function (args) {
        if (args) {
            _.forOwn(props, (value, key) => {
                if (args[key] !== undefined) {
                    action[key] = args[key];
                }
            });
        }

        return action;
    };
};
