//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '../../helper/Store';

export const PlaceOfWorkTypes = {
    FETCH_PLACE_OF_WORK:         'PlaceOfWork/FETCH_PLACE_OF_WORK',
    FETCH_PLACE_OF_WORK_FAILED:  'PlaceOfWork/FETCH_PLACE_OF_WORK_FAILED',
    FETCH_PLACE_OF_WORK_SUCCESS: 'PlaceOfWork/FETCH_PLACE_OF_WORK_SUCCESS',
};

const fetchPlaceOfWorks = makeActionCreator(
    PlaceOfWorkTypes.FETCH_PLACE_OF_WORK,
);

const fetchPlaceOfWorksFailed = makeActionCreator(
    PlaceOfWorkTypes.FETCH_PLACE_OF_WORK_FAILED,
);

const fetchPlaceOfWorksSuccess = makeActionCreator(
    PlaceOfWorkTypes.FETCH_PLACE_OF_WORK_SUCCESS,
    {
        placeOfWorks: null,
    },
);

export const PlaceOfWorkActions = {
    fetchPlaceOfWorks,
    fetchPlaceOfWorksFailed,
    fetchPlaceOfWorksSuccess,
};
