//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import PropTypes               from '../../PropTypes';
import React                   from 'react';
import { connect }             from 'react-redux';
import ComponentHelper         from '../../../helper/ComponentHelper';
import StatelessLoadingOverlay from '../../stateless/LoadingOverlay';

class LoadingOverlay extends React.Component {
    render () {
        return (
            <StatelessLoadingOverlay
                isLoading={this.props.isLoading}
                text={this.props.text}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = LoadingOverlay;

Component.propTypes = {
    isLoading: PropTypes.bool,
    text:      PropTypes.string,
};

Component.defaultProps = {
    isLoading: false,
    text:      '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        isLoading: state.loading.isLoading,
        text:      state.loading.text,
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);
