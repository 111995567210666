//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                                from 'react';
import _                                    from 'lodash';
import ApiMode                              from '../../../constants/ApiMode';
import ComponentHelper                      from '../../../helper/ComponentHelper';
import CreateProjectSuccessOverlayStateless from '../../stateless/CreateProjectSuccessOverlay';
import I18n                                 from 'i18next';
import Overlay                              from '../Overlay';
import OverlayButton                        from '../../stateless/OverlayButton';
import OverlayButtonTheme                   from '../../stateless/OverlayButton/OverlayButtonTheme';
import OverlayButtonWrapper                 from '../../stateless/OverlayButtonWrapper';
import OverlayPane                          from '../../stateless/OverlayPane';
import Overlays                             from '../../../constants/Overlays';
import PropTypes                            from 'prop-types';
import Routes                               from '../../../constants/Routes';
import Window                               from '../../../helper/Window';
import { bindActionCreators }               from 'redux';
import { connect }                          from 'react-redux';
import { goBack }                           from 'connected-react-router';
import { NavigationActions }                from '../../../store/actions/navigation';
import { OverlayActions }                   from '../../../store/actions/overlay';

class CreateProjectSuccessOverlay extends React.Component {
    getDescription = () => {
        if (this.props.mode === ApiMode.create) {
            return I18n.t('createProjectSuccessDescription');
        }

        return I18n.t('editProjectSuccessDescription');
    };

    getTitle = () => {
        if (this.props.mode === ApiMode.create) {
            return I18n.t('createProjectSuccess');
        }

        return I18n.t('editProjectSuccess');
    };

    okButtonPressed = () => {
        if (Window.isMobile()) {
            this.props.redirect({
                route: Routes.mobileRegisterSuccess,
            });
        } else {
            this.props.saveLastRoute({
                lastRoute: Routes.home,
            })
            this.props.closeOverlay();
        }
    };

    render() {
        return (
            <Overlay
                id={Overlays.createProjectSuccess}
                title={this.getTitle()}
            >
                <OverlayPane
                    content={this.renderContent()}
                    footer={this.renderFooter()}
                    description={this.getDescription()}
                    scrollingAllowed={false}
                />
            </Overlay>
        );
    }

    renderContent = () => {
        return (
            <CreateProjectSuccessOverlayStateless />
        );
    };

    renderFooter = () => {
        return (
            <OverlayButtonWrapper>
                <OverlayButton
                    onClick={this.okButtonPressed}
                    standAlone={true}
                    text={I18n.t('okThanks')}
                    theme={OverlayButtonTheme.turquoise}
                />
            </OverlayButtonWrapper>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CreateProjectSuccessOverlay;

Component.propTypes = {
    mode: PropTypes.oneOf(Object.values(ApiMode)),
};

Component.defaultProps = {
    mode: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        mode: _.get(state, 'createEditProject.mode'),
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(
    _.assign(
        OverlayActions,
        NavigationActions,
        {
            goBack,
        },
    ),
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
